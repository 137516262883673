import React, { useEffect, Fragment } from "react";
import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import PatientsDetailsHeaderTriage from "./PatientsDetailsHeaderTriage";
import PatientDetailsProfileTriage from "./PatientDetailsProfileTriage";
import TabPanel from "./../../utils/TabPanel";
import { Folder, User } from "react-feather";
import PatientDetailsContactTracing from "./PatientDetailsContactTracing";

const PatientDetailsTriage = ({
  patient_id,
  getDoctorsPatient,
  setSelectedPatient,
}) => {
  const [category, setCategory] = React.useState(0);
  const [info, setInfo] = React.useState({
    data: null,
    ready: false,
  });

  const getPatientInformation = () => {
    var params = { patient_id: patient_id };
    Axios.get("admission/patient-triage/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setInfo({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        console.log("err: ", error);
        // Notify.requestError(error);
      });
  };

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  useEffect(() => {
    getPatientInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      {info.ready ? (
        <Fragment>
          <PatientsDetailsHeaderTriage patient_id={patient_id} info={info} />

          <Box mt={2} m={0} p={0}>
            <Box component={Paper} elevation={1} mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab wrapped label="Profile" icon={<User />} />
                <Tab wrapped label="Contact Tracing" icon={<Folder />} />
              </Tabs>
            </Box>

            <TabPanel value={category} index={0}>
              <PatientDetailsProfileTriage
                patient_id={patient_id}
                getDoctorsPatient={() => getDoctorsPatient()}
                getPatientInformation={getPatientInformation}
                info={info}
              />
            </TabPanel>

            <TabPanel value={category} index={1}>
              <PatientDetailsContactTracing
                patient_id={patient_id}
                getDoctorsPatient={() => getDoctorsPatient()}
                getPatientInformation={getPatientInformation}
                info={info}
                setSelectedPatient={() => setSelectedPatient()}
              />
              {/* <PatientDetailsCaseInvestigation getAllPatientCaseInvestigation={() => getAllPatientCaseInvestigation()} setCategory={() => setCategory(0)} patient_id={patient_id} cCSameCity={cCSameCity} cCDiffCity={cCDiffCity} caseInvestigation={patientCaseInvestigation} /> */}
            </TabPanel>
          </Box>
        </Fragment>
      ) : (
        Notify.loading()
      )}
    </>
  );
};

export default PatientDetailsTriage;
