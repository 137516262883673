import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Box,
  Button,
  FormHelperText,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import { CheckCircle, XCircle } from "react-feather";
import IsMountedRef from "src/utils/IsMountedRef";
import Select from "react-select";
// import SelectStyle from "src/utils/SelectStyle";

const CreateOrder = ({
  patient_id,
  getPatientBillingDetails,
  getOtherOrderUnpaidList,
}) => {
  const { users } = React.useContext(UsersData);
  const [list, setList] = useState([]);
  const mounted = IsMountedRef();
  // const selectStyle = SelectStyle();
  const theme = useTheme();

  const getOtherTestList = React.useCallback(async () => {
    let response = await axios.get("cashier/add-on/othertest-list", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    if (mounted.current) {
      setList(response.data);
    }
  }, [mounted, users]);

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  useEffect(() => {
    getOtherTestList();
  }, [getOtherTestList]);

  return (
    <Formik
      initialValues={{
        username: users.username,
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
        user_id: users.user_id,
        patient_id: patient_id,
        department: "",
        order_name: "",
        order_amount: "",
        order_id: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        order_name: Yup.string().required(),
        department: Yup.string().required(),
        order_amount: Yup.number().min(1).required(),
        password: Yup.string().required(),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          const request = await axios.post(
            "cashier/other/order/new-othertest",
            getFormData(values)
          );
          if (request.data.message === "pass-invalid") {
            setErrors({ password: "Password is invalid." });
          }
          if (request.data.message === "success") {
            Notify.successRequest("new test");
            getPatientBillingDetails();
            getOtherOrderUnpaidList();
            resetForm();
            setSubmitting(true);
          }
        } catch (error) {
          const message = error.message || "Something went wrong";
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        resetForm,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Select Order" />
            <CardContent>
              <Box mb={2}>
                <Select
                  options={list}
                  onChange={(data) => {
                    setFieldValue("order_name", data.order_name);
                    setFieldValue("department", data.department);
                    setFieldValue("order_amount", data.order_amount);
                    setFieldValue("order_id", data.order_id);
                  }}
                  // styles={selectStyle}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                      color: theme.palette.type === "dark" ? "#fff" : "#fff",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  label="Order Amount"
                  fullWidth
                  variant="outlined"
                  error={Boolean(touched.order_amount && errors.order_amount)}
                  helperText={touched.order_amount && errors.order_amount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.order_amount}
                  name="order_amount"
                  InputProps={{ readOnly: true }}
                />
              </Box>

              <Box>
                <TextField
                  fullWidth
                  required
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>

              {errors.submit && (
                <Box mt={2}>
                  <FormHelperText error> {errors.submit} </FormHelperText>
                </Box>
              )}
            </CardContent>

            <CardContent>
              <Box display="flex">
                <Box mr={2}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size={23} color="inherit" />
                      ) : (
                        <CheckCircle />
                      )
                    }
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>

                <Button
                  size="large"
                  variant="contained"
                  color="default"
                  onClick={resetForm}
                  startIcon={<XCircle />}
                >
                  No
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default CreateOrder;
