import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  Dialog,
  Box,
  Paper,
  Tab,
  Tabs,
  Badge,
  TextField,
  CardMedia,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Edit } from "react-feather";
import { BranchListContext, UsersData, UsersHeader } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import TabPanel from "src/utils/TabPanel";
import ProcessEditResult from "./ProcessEditResult";
import ProcessEditResultLocal from "./ProcessEditResultLocal";

const ToEdit = () => {
  const [selectedResult, setSelectedResult] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogLocal, setEditDialogLocal] = useState(false);
  const [category, setCategory] = useState("telerad");
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [selectedMgmtId, setSelectedMgmtId] = useState(null);
  const [patientOnline, setPatientOnline] = useState({
    data: [],
    ready: false,
  });

  const {
    queue: { patientLocal, getPatientsOnQueueLocal },
  } = React.useContext(UsersHeader);

  const { branchList } = React.useContext(BranchListContext);

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  const getDocumentationQueueOnline = React.useCallback(
    async (data) => {
      var params = {
        user_id: users.user_id,
        management_id: data,
      };
      axios
        .get("documentation/get/online/result-to-edit", { params })
        .then((response) => {
          const data = response.data;
          if (mounted.current) {
            setPatientOnline({ data, ready: true });
          }
        });
    },
    [users, mounted]
  );

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "To Edit Result",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>to edit result</Box>
          <Box maxWidth={450}>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                {/* <Tab
                  value="inhouse"
                  label={
                    <Badge
                      color="secondary"
                      badgeContent={patientLocal.data.length}
                    >
                      In House
                    </Badge>
                  }
                /> */}
                <Tab
                  value="telerad"
                  label={
                    <Badge
                      color="secondary"
                      badgeContent={patientOnline.data.length}
                    >
                      Telerad
                    </Badge>
                  }
                />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TabPanel value={category} index="inhouse">
            <Card>
              <CardHeader title="Doctor's reading" />
              <CardContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Patient</TableCell>
                        <TableCell>Result</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patientLocal.ready ? (
                        patientLocal.data.length > 0 ? (
                          patientLocal.data.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {Notify.createdAt(data.created_at)}
                              </TableCell>
                              <TableCell>
                                {data.lname}, {data.fname}
                              </TableCell>
                              <TableCell> {data.imaging_result} </TableCell>
                              <TableCell>
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setSelectedResult(data);
                                    setEditDialogLocal(true);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <Typography color="secondary">
                                No result to edit.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <Typography color="primary">
                              Loading, please wait...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={category} index="telerad">
            <Card>
              <CardHeader
                title={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box flexGrow={1} />
                    <Box>
                      <TextField
                        fullWidth
                        required
                        name="branch"
                        label="Branch"
                        variant="outlined"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          getDocumentationQueueOnline(e.target.value);
                          setSelectedMgmtId(e.target.value);
                        }}
                      >
                        <option value=""> Select </option>
                        {branchList.data.length &&
                          branchList.data.map((data, index) => (
                            <option key={index} value={data.value}>
                              {data.name}
                            </option>
                          ))}
                      </TextField>
                    </Box>
                  </Box>
                }
              />
              {patientOnline.ready ? (
                <CardContent>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Patient</TableCell>
                          <TableCell>Result</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {patientOnline.data.length > 0 ? (
                          patientOnline.data.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {Notify.createdAt(data.created_at)}
                              </TableCell>
                              <TableCell>
                                {data.lname}, {data.fname}
                              </TableCell>
                              <TableCell> {data.imaging_result} </TableCell>
                              <TableCell>
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setSelectedResult(data);
                                    setEditDialog(true);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <Typography color="secondary">
                                No result to edit.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              ) : (
                <RenderSelectBranch />
              )}
            </Card>
          </TabPanel>
        </Grid>
      </Grid>

      <Dialog
        open={editDialogLocal}
        onClose={() => {
          setEditDialogLocal(false);
          setSelectedResult(null);
        }}
        fullWidth
        maxWidth="xs"
      >
        <ProcessEditResultLocal
          selectedResult={selectedResult}
          getAllPatientAlreadyRead={() => getPatientsOnQueueLocal()}
          close={() => {
            setSelectedResult(null);
            setEditDialogLocal(false);
          }}
        />
      </Dialog>

      <Dialog
        open={editDialog}
        onClose={() => {
          setEditDialog(false);
          setSelectedResult(null);
        }}
        fullWidth
        maxWidth="xs"
      >
        <ProcessEditResult
          selectedMgmtId={selectedMgmtId}
          selectedResult={selectedResult}
          getAllPatientAlreadyRead={() => getDocumentationQueueOnline()}
          close={() => {
            setSelectedResult(null);
            setEditDialog(false);
          }}
        />
      </Dialog>
    </Container>
  );
};

export default ToEdit;

const RenderSelectBranch = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />

    <Typography color="secondary" variant="h3">
      NO BRANCH SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select branch in the list to view details.
    </Typography>
  </Box>
);
