import React from "react";
import axios from "axios";
import {
  Box,
  Dialog,
  DialogContent,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { CheckCircle, XCircle } from "react-feather";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";

const DetailsLocalSetAsComplete = ({ open, close, appid }) => {
  const [isProcess, setIsProcess] = React.useState(false);
  const { users } = React.useContext(UsersData);

  const completeAppSave = async () => {
    try {
      setIsProcess(true);
      var formdata = new FormData();
      formdata.set("user_id", users.user_id);
      formdata.set("appid", appid);

      const response = await axios.post(
        "doctors/appointment/local/set-complete",
        formdata
      );
      if (response.data === "success") {
        setIsProcess(false);
        Notify.successRequest("Appointment Completed");
        close();
      }
    } catch (error) {
      Notify.requestError(error);
    }
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth={`xs`}>
      <DialogContent>
        <Typography>
          {" "}
          Are you sure to set this appointment as complete?
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Box flexGrow={1} />
          <Box display="flex">
            <Box mr={1}>
              <Button
                startIcon={<XCircle />}
                variant="contained"
                color="default"
                onClick={close}
              >
                {" "}
                No{" "}
              </Button>
            </Box>

            <Button
              onClick={completeAppSave}
              disabled={isProcess}
              startIcon={
                isProcess ? (
                  <CircularProgress size={15} color="inherit" />
                ) : (
                  <CheckCircle />
                )
              }
              variant="contained"
              color="primary"
            >
              {" "}
              Save{" "}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DetailsLocalSetAsComplete;
