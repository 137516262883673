import React from "react";
import {
  Button,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  Grid,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/HighlightOff";
import Notify from "src/notification/Notify";
import Label from "src/utils/Label";

function AppointmentDetails({ app, close }) {
  return (
    <>
      <DialogContent dividers>
        <Box className={`gtc-textfield-noborder`}>
          <Grid container spacing={2}>
            <Box display={"flex"}>
              <Label color={Boolean(app.is_complete) ? "success" : "error"}>
                STATUS : {Boolean(app.is_complete) ? "Complete" : "Incomplete"}
              </Label>
              <Box ml={3}>
                <Label
                  color={Boolean(app.is_reschedule) ? "success" : "primary"}
                >
                  RESCHEDULE : {Boolean(app.is_reschedule) ? "Yes" : "No"}
                </Label>
              </Box>
            </Box>

            <Grid item xs={12} sm={12} md={Boolean(app.is_reschedule) ? 6 : 12}>
              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Doctor Name"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  defaultValue={app.name}
                  multiline
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Date and Time"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  defaultValue={Notify.dateTimeConvert(app.app_date)}
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Complaint"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  defaultValue={app.app_reason}
                  multiline
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Service"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  defaultValue={app.services}
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Rate"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  defaultValue={app.amount}
                />
              </Box>
            </Grid>
            {Boolean(app.is_reschedule) && (
              <Grid item xs={12} sm={12} md={6}>
                <Box mt={2}>
                  <TextField
                    fullWidth
                    label={"Reschedule Date"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    defaultValue={Notify.dateTimeConvert(
                      app.is_reschedule_date
                    )}
                  />
                </Box>

                <Box mt={2}>
                  <TextField
                    fullWidth
                    label={"Reschedule Reason"}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    defaultValue={app.is_reschedule_reason}
                    multiline
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="default"
          onClick={close}
          startIcon={<ClearIcon />}
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
}

export default AppointmentDetails;
