import React, { Component, useEffect } from "react";
import axios from "axios";
import { ThemeContext, UsersData } from "./ContextAPI";
import Cookies from "universal-cookie";
import {
  BlueTheme,
  DarkTheme,
  GreenTheme,
  PurpleTheme,
  RedTheme,
  IndigoTheme,
  TealTheme,
} from "./HMISThemes";
import { ThemeProvider, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AccountTypeChecker from "./auth/AccountTypeChecker";
import CheckAuth from "./CheckAuth";
import RouteHomepage from "./route/RouteHomepage";
import RouteHMIS from "./route/RouteHMIS";
import RoutePharmacy from "./route/RoutePharmacy";
import RouteCashier from "./route/RouteCashier";
import RouteLaboratory from "./route/RouteLaboratory";
import RouteDoctor from "./route/RouteDoctor";
import RouteImaging from "./route/RouteImaging";
import { ToastContainer, Slide } from "react-toastify";
import RouteAdmission from "./route/RouteAdmission";
import RouteRadiologist from "./route/RouteRadiologist";
import RouteAccounting from "./route/RouteAccounting";
import RouteBilling from "./route/RouteBilling";
import RouteTriage from "./route/RouteTriage";
import RouteWarehouse from "./route/RouteWarehouse";
import RouteIshihara from "./route/RouteIshihara";
// import SyncDataBMCDC from "./SyncDataBMCDC";
import RouteStockRoom from "./route/RouteStockRoom";
import RouteHaptech from "./route/RouteHaptech";
import RouteHR from "./route/RouteHR";
import RoutePsychology from "./route/RoutePsychology";
import RouteDocumentation from "./route/RouteDocumentation";
import RouteOM from "./route/RouteOM";
import RouteOthers from "./route/RouteOthers";
import RouteNurse from "./route/RouteNurse";
import RouteEndorsement from "./route/RouteEndorsement";
import RouteReceiving from "./route/RouteReceiving";
import RouteVanEndorsement from "./route/RouteVanEndorsement";

const cookies = new Cookies();

require("dotenv").config();

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers[`Authorization`] = `Bearer ${localStorage.getItem(
  "hmis_token"
)}`;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("hmis_token"),

      userInfo: {
        email: null,
        management_id: null,
        main_mgmt_id: null,
        ready: false,
        type: null,
        user_id: null,
        username: null,
        management_name: null,
      },

      darkmode: cookies.get("gtcTheme") === "dark" ? true : false,
      sidebarOpen: window.innerWidth > 900 ? true : false,
      gtctheme: BlueTheme,
    };
  }

  componentDidMount() {
    this.updateGTCTheme(cookies.get("gtctheme_mode"));
  }

  handleToggleSidebar = (state) => {
    this.setState({ sidebarOpen: state });
  };

  updateGTCTheme = (data) => {
    switch (data) {
      case "red":
        cookies.set("gtctheme_mode", data);
        this.setState({ gtctheme: RedTheme });
        return;
      case "purple":
        cookies.set("gtctheme_mode", data);
        this.setState({ gtctheme: PurpleTheme });
        return;
      case "green":
        cookies.set("gtctheme_mode", data);
        this.setState({ gtctheme: GreenTheme });
        return;
      case "indigo":
        cookies.set("gtctheme_mode", data);
        this.setState({ gtctheme: IndigoTheme });
        return;
      case "teal":
        cookies.set("gtctheme_mode", data);
        this.setState({ gtctheme: TealTheme });
        return;
      case "dark":
        cookies.set("gtctheme_mode", data);
        this.setState({ gtctheme: DarkTheme });
        return;
      default:
        cookies.set("gtctheme_mode", data);
        this.setState({ gtctheme: BlueTheme });
        return;
    }
  };

  handleUsersUpdate = (data) => {
    this.setState({
      userInfo: {
        ...this.state.userInfo,
        email: data.email,
        management_id: data.management_id,
        main_mgmt_id: data.main_mgmt_id,
        ready: data.ready,
        type: data.type,
        user_id: data.user_id,
        username: data.username,
        management_name: data.management_name,
      },
    });
  };

  render() {
    return (
      <>
        <ThemeContext.Provider
          value={{
            gtcThemeDark: this.state.darkmode,
            updategtcTheme: this.updateGTCTheme,
            sidebar: {
              open: this.state.sidebarOpen,
              toggle: this.handleToggleSidebar,
            },
          }}
        >
          <ThemeProvider theme={this.state.gtctheme}>
            <UsersData.Provider
              value={{
                users: this.state.userInfo,
                updateUsers: this.handleUsersUpdate,
              }}
            >
              <CssBaseline />
              {this.state.token ? (
                <CheckAuth>
                  {this.state.userInfo.ready && (
                    <CheckAcountRole role={this.state.userInfo.type} />
                  )}
                </CheckAuth>
              ) : (
                <RouteHomepage />
              )}

              {/* toastr container */}
              <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                transition={Slide}
              />
            </UsersData.Provider>
          </ThemeProvider>
        </ThemeContext.Provider>

        {/* enable sync */}
        {/* {window.location.href.includes("http://localhost/bmcdc/app") && (
          <SyncDataBMCDC />
        )} */}
      </>
    );
  }
}

const CheckAcountRole = ({ role }) => {
  const theme = useTheme();

  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute(
        "content",
        `${
          theme.palette.type === "dark" ? "#525252" : theme.palette.primary.main
        }`
      );

    // eslint-disable-next-line
  }, []);

  if (role === "HMIS") return <RouteHMIS />;
  if (role === "HIS-Pharmacy") return <RoutePharmacy />;
  if (role === "HIS-Laboratory") return <RouteLaboratory />;
  if (role === "HIS-OM") return <RouteOM />;
  if (role === "HIS-Doctor") return <RouteDoctor />;
  if (role === "HIS-Documentation") return <RouteDocumentation />;
  if (role === "HIS-Registration") return <RouteAdmission />;
  if (role === "HIS-Imaging") return <RouteImaging />;
  if (role === "HIS-Radiologist") return <RouteRadiologist />;
  if (role === "HIS-Cashier") return <RouteCashier />;
  if (role === "HIS-Accounting") return <RouteAccounting />;
  if (role === "HIS-Billing") return <RouteBilling />;
  if (role === "HIS-Triage") return <RouteTriage />;
  if (role === "HIS-Warehouse") return <RouteWarehouse />;
  if (role === "HIS-Ishihara") return <RouteIshihara />;
  if (role === "HIS-Stockroom") return <RouteStockRoom />;
  if (role === "HIS-Haptech") return <RouteHaptech />;
  if (role === "HIS-Hr") return <RouteHR />;
  if (role === "HIS-Psychology") return <RoutePsychology />;
  if (role === "HIS-Others") return <RouteOthers />;
  if (role === "HIS-Nurse") return <RouteNurse />;
  if (role === "HIS-Endorsement") return <RouteEndorsement />;
  if (role === "HIS-Receiving") return <RouteReceiving />;
  if (role === "Van-Endorsement") return <RouteVanEndorsement />;
  return <AccountTypeChecker />;
};
