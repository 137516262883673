import React, { Fragment, useEffect } from "react";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const Logout = () => {
  const logout = async () => {
    try {
      await axios.post("auth/logout");
      localStorage.clear();
      window.location.href = "/bmcdc/app";
    } catch (error) {
      localStorage.clear();
      window.location.href = "/bmcdc/app";
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <Fragment>
      <Backdrop open={true} style={{ zIndex: 9999999 }}>
        <Box align="center" color="white">
          <CircularProgress color="inherit" size={35} />
          <Typography> Signing Out... </Typography>
        </Box>
      </Backdrop>
    </Fragment>
  );
};

export default Logout;
