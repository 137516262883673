import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  Table,
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  TablePagination,
} from "@material-ui/core";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import { Search } from "@material-ui/icons";

const AddOnSales = () => {
  const mounted = IsMountedRef();
  const { users } = useContext(UsersData);
  const [test, setTest] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [total, setTotal] = useState(0);
  var totalVar = 0;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const calculateTotalSales = (data) => {
    for (let i = 0; i < data.length; i++) {
      totalVar += parseFloat(data[i].bill_amount);
    }
    return totalVar;
  };

  const getTestPerson = async () => {
    let response = await axios.get("accounting/other/test-person-list", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setTest({ data: response.data, ready: true });
      setTotal(calculateTotalSales(response.data));
    }
  };

  const calculateTotal = (amount) => {
    totalVar += parseFloat(amount);
    return Notify.convertToNumber(amount);
  };

  const handleSelectedDate = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);

    let response = await axios.get("accounting/other/sales/report-bydate", {
      params: {
        from: formdata.get("from"),
        to: formdata.get("to"),
        management_id: users.management_id,
      },
    });

    setTest({ data: response.data, ready: true });
  };

  useEffect(() => {
    getTestPerson();
    // eslint-disable-next-line
  }, []);

  const searchable = test.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim().toLowerCase()) !==
        -1 ||
      data.lastname.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    );
  });

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography style={{ marginTop: 20 }} variant="h6">
            <strong>Overall Sales: {Notify.convertToNumber(total)}</strong>
          </Typography>
        </Box>
        <Box>
          <TextField
            label="Search patient"
            variant="outlined"
            margin="dense"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Search color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>

      <Card>
        <CardHeader
          title={
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h5">Other Test Sales</Typography>
              </Box>
              <Box>
                <form onSubmit={handleSelectedDate}>
                  <Box display="flex">
                    <Box>
                      <TextField
                        required
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        variant="outlined"
                        label="From"
                        name="from"
                      />
                    </Box>

                    <Box mx={1}>
                      <TextField
                        required
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        variant="outlined"
                        label="To"
                        name="to"
                      />
                    </Box>
                    <Box mt={1.2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<Search />}
                      >
                        Go
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          }
        />

        <CardMedia>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell> Date </TableCell>
                  <TableCell> Patient </TableCell>
                  <TableCell> Order </TableCell>
                  <TableCell> Rate </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {test.ready ? (
                  searchable.length > 0 ? (
                    searchable
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell> {index++ + 1} </TableCell>
                            <TableCell>
                              {Notify.dateTimeConvert(data.created_at)}
                            </TableCell>
                            <TableCell>
                              {`${data.lastname}, ${data.firstname}`}
                            </TableCell>
                            <TableCell> {data.bill_name} </TableCell>
                            <TableCell align="right">
                              {calculateTotal(data.bill_amount)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      <Typography color="primary">Please wait...</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[10, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </CardMedia>
      </Card>
    </>
  );
};

export default AddOnSales;
