import { Grid, Box, Paper, Tabs, Tab } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import TabPanel from "src/utils/TabPanel";
// import CreateLaboratoryOrder from "./CreateLaboratoryOrder";
// import ListofTempOrder from "./ListofTempOrder";
import LabOrderTest from "./LabOrderTest";
import IsMountedRef from "src/utils/IsMountedRef";

const LaboratoryCreateOrder = () => {
  // const [tempList, setTempList] = useState({
  //   data: [],
  //   ready: false,
  // });
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();

  const [category, setCategory] = useState("order");

  const [labOrderList, setLabOrderList] = useState({
    data: [],
    ready: false,
  });

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  // const getTempOrderList = React.useCallback(async () => {
  //   let response = await axios.get(
  //     "laboratory/items/laborder/list-tempnoitem",
  //     {
  //       params: {
  //         management_id: users.management_id,
  //         user_id: users.user_id,
  //       },
  //     }
  //   );

  //   if (mounted.current) {
  //     setTempList({ data: response.data, ready: true });
  //   }
  // }, [mounted, users]);

  const getLaboratoryOrderList = React.useCallback(async () => {
    let response = await axios.get("laboratory/items/laborder/list-available", {
      params: {
        management_id: users.management_id,
        user_id: users.user_id,
      },
    });

    if (mounted.current) {
      setLabOrderList({ data: response.data, ready: true });
    }
  }, [mounted, users]);

  useEffect(() => {
    // getTempOrderList();
    getLaboratoryOrderList();
  }, [
    // getTempOrderList,
    getLaboratoryOrderList,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Tabs
            value={category}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="List Of Order" value="order" />
            {/* <Tab label="Temp. Order" value="temp" /> */}
          </Tabs>
        </Paper>

        <Box mt={2}>
          <TabPanel value={category} index="order">
            <LabOrderTest labOrderList={labOrderList} />
          </TabPanel>
          {/* <TabPanel value={category} index="temp">
            <ListofTempOrder
              tempList={tempList}
              getTempOrderList={() => getTempOrderList()}
            />
          </TabPanel> */}
        </Box>
      </Grid>
      {/* <Grid item xs={12} sm={4}>
        <CreateLaboratoryOrder getTempOrderList={() => getTempOrderList()} />
      </Grid> */}
    </Grid>
  );
};

export default LaboratoryCreateOrder;
