import { Grid, Tabs, Tab, Box, Paper } from "@material-ui/core";
import React from "react";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import CompanyList from "./CompanyList";
import PatientList from "./PatientList";

const BillingSOA = () => {
  const [category, setCategory] = React.useState("patient");

  const handleChange = (event, x) => {
    setCategory(x);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "soa",
        items: [{ name: "dashboard", path: "/bmcdc/app/billing/soa" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Statement Of Account</Box>
          <Box>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab value="patient" label="Patient" />
                <Tab value="company" label="Company" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TabPanel value={category} index="patient">
            <PatientList />
          </TabPanel>
          <TabPanel value={category} index="company">
            <CompanyList />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BillingSOA;
