import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  Table,
  Typography,
  TableFooter,
  TablePagination,
  Box,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import { Search } from "@material-ui/icons";

const TestPerson = () => {
  const mounted = IsMountedRef();
  const { users } = useContext(UsersData);
  const [test, setTest] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let total = 0;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getTestPerson = React.useCallback(async () => {
    let response = await axios.get("accounting/imaging/test-person-list", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setTest({ data: response.data, ready: true });
    }
  }, [users, mounted]);

  const calculateTotal = (amount) => {
    total += parseFloat(amount);
    return Notify.convertToNumber(amount);
  };

  useEffect(() => {
    getTestPerson();
  }, [getTestPerson]);

  const searchable = test.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim().toLowerCase()) !==
        -1 ||
      data.lastname.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    );
  });

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h5"> Patient List Tested </Typography>
            </Box>
            <Box>
              <TextField
                label="Search patient"
                variant="outlined"
                margin="dense"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Search color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        }
      />

      <CardMedia>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell> Date </TableCell>
                <TableCell> Patient </TableCell>
                <TableCell> Address </TableCell>
                <TableCell> Gender </TableCell>
                <TableCell> Age </TableCell>
                <TableCell> Order </TableCell>
                <TableCell> Rate </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {test.ready ? (
                searchable.length > 0 ? (
                  searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell> {index++ + 1} </TableCell>
                          <TableCell>
                            {Notify.dateTimeConvert(data.created_at)}
                          </TableCell>
                          <TableCell>
                            {`${data.lastname}, ${data.firstname}`}
                          </TableCell>
                          <TableCell>
                            {`${data.street}, ${data.barangay}, ${data.city}`}
                          </TableCell>
                          <TableCell> Gender </TableCell>
                          <TableCell>
                            {" "}
                            {Notify.calculateAge(data.birthday)}{" "}
                          </TableCell>
                          <TableCell> {data.bill_name} </TableCell>
                          <TableCell align="right">
                            {calculateTotal(data.bill_amount)}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Typography color="primary">please wait...</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6} />
                <TableCell align="center"> Total </TableCell>
                <TableCell align="right">
                  {Notify.convertToNumber(total)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[10, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </TableContainer>
      </CardMedia>
    </Card>
  );
};

export default TestPerson;
