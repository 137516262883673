import React, { Fragment, useCallback } from "react";
import {
  Grid,
  // Box,
  // Paper,
  // FormHelperText,
  // Divider,
  // Button,
  // TextField,
  // Typography,
} from "@material-ui/core";
import axios from "axios";
// import * as Yup from "yup";
// import { Formik } from "formik";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
// import CheckIcon from "@material-ui/icons/CheckCircleOutline";
// import ClearIcon from "@material-ui/icons/HighlightOff";
import ImagingTestList from "./ImagingTestList";

const ImagingTest = () => {
  const { users } = React.useContext(UsersData);

  const [testList, setTestList] = React.useState({
    data: [],
    ready: false,
  });

  // const getFormData = (object) => {
  //   const formData = new FormData();

  //   Object.keys(object).forEach((key) => formData.append(key, object[key]));
  //   return formData;
  // };

  const getImagingTest = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const request = await axios.get("imaging/test/getAllTest", { params });
      setTestList({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      // console.log("error : ", error);
      Notify.requestError(error);
    }
  }, [users]);

  React.useEffect(() => {
    getImagingTest();
  }, [getImagingTest]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ImagingTestList list={testList} getImagingTest={getImagingTest} />
        </Grid>

        {/* <Grid item xs={12} sm={5} md={4}>
          <Box component={Paper} variant="outlined" p={2}>
            <Formik
              initialValues={{
                username: users.username,
                user_id: users.user_id,
                management_id: users.management_id,
                order: "",
                shots: "",
                rate: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                shots: Yup.number().min(1).required(),
                order: Yup.string().trim().required(),
                rate: Yup.number().min(1).required(),
                password: Yup.string().required(),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting, resetForm }
              ) => {
                try {
                  const request = await axios.post(
                    "imaging/test/newtest-save",
                    getFormData(values)
                  );
                  if (request.data === "pass-invalid") {
                    setErrors({ password: "Password is invalid." });
                    Notify.fieldInvalid("password");
                  }
                  if (request.data === "success") {
                    Notify.successRequest("add new imaging test");
                    resetForm();
                    setSubmitting(true);
                    getImagingTest();
                  }
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Box mb={2}>
                    <Typography color="textPrimary" variant="subtitle2">
                      NEW IMAGING TEST
                    </Typography>
                  </Box>

                  <Divider />

                  <Box my={2} className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.order}
                      fullWidth
                      required
                      name="order"
                      label="Order Description"
                      variant="outlined"
                      margin="dense"
                    />
                  </Box>

                  <Box my={2} className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.shots && errors.shots)}
                      helperText={touched.shots && errors.shots}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.shots}
                      fullWidth
                      required
                      name="shots"
                      label="Number of shots"
                      variant="outlined"
                      type="number"
                      margin="dense"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 100,
                          step: 1,
                        },
                      }}
                    />
                  </Box>

                  <Box mb={2} className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.rate && errors.rate)}
                      helperText={touched.rate && errors.rate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.rate}
                      fullWidth
                      required
                      name="rate"
                      label="Order Rate"
                      variant="outlined"
                      type="number"
                      margin="dense"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 100000,
                          step: 0.01,
                        },
                      }}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      fullWidth
                      required
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      label="Enter your password"
                      variant="outlined"
                      type="password"
                      margin="dense"
                    />
                  </Box>

                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <Divider />

                  <Box my={2} display="flex">
                    <Box flexGrow={1} />

                    <Button
                      variant="contained"
                      color="default"
                      type="reset"
                      startIcon={<ClearIcon />}
                    >
                      Clear
                    </Button>
                    <Box ml={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<CheckIcon />}
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Grid> */}
      </Grid>
    </Fragment>
  );
};

export default ImagingTest;
