import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
import RefOralGlucose from "src/laboratory/references/Ref_OralGlucose";
import Footer from "src/laboratory/print/Footer";

const OralGlucoseOrder = ({ order_id, patient_id, formheader }) => {
  const { users } = React.useContext(UsersData);
  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_oral_glucose");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_oral_glucose table error", error);
    }
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box my={2} p={2}>
            <Box>
              <Typography variant="h6" align="center">
                <b> ORAL GLUCOSE TOLERANCE </b>
              </Typography>
              <Typography variant="subtitle1" align="center">
                75 GRAMS GLUCOLA
              </Typography>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Test </TableCell>
                    <TableCell> Result </TableCell>
                    <TableCell> Unit </TableCell>
                    <TableCell> Remarks </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {details.length > 0 ? (
                    details.map((data, index) => (
                      <Fragment key={index}>
                        <TableRow hidden={data.baseline === null}>
                          <TableCell> Baseline </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.baseline)}
                            </Typography>
                          </TableCell>
                          <TableCell> {RefOralGlucose.baseline()} </TableCell>
                          <TableCell> {data.baseline_remarks} </TableCell>
                        </TableRow>

                        <TableRow hidden={data.first_hour === null}>
                          <TableCell> First Hour </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.first_hour)}
                            </Typography>
                          </TableCell>
                          <TableCell>{RefOralGlucose.firstHour()}</TableCell>
                          <TableCell> {data.first_hour_remarks} </TableCell>
                        </TableRow>

                        <TableRow hidden={data.second_hour === null}>
                          <TableCell> Second Hour </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.second_hour)}
                            </Typography>
                          </TableCell>
                          <TableCell>{RefOralGlucose.secondHour()}</TableCell>
                          <TableCell> {data.second_hour_remarks} </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography> No record found. </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Footer formheader={formheader} />
        </>
      )}
    </>
  );
};

export default OralGlucoseOrder;
