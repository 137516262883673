import {
  Box,
  Paper,
  Card,
  CardHeader,
  Typography,
  TextField,
  InputAdornment,
  CardContent,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
  Tooltip,
  makeStyles,
  Collapse,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  Fragment,
} from "react";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import { useHistory } from "react-router-dom";
import { ArrowRight } from "react-feather";
import Notify from "src/notification/Notify";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const CompanySoa = () => {
  const { users } = useContext(UsersData);
  const [list, setList] = useState({ data: [], ready: false });
  const history = useHistory();
  const classes = useRowStyles();

  const [selectedCompany, setSelectedCompany] = useState("");
  const [hmo, setHMO] = useState([]);

  const getManagementPatientList = useCallback(async () => {
    let response = await axios.get("billing/soa/get-management-companies", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    setList({ data: response.data, ready: true });
  }, [users]);

  const getCompanyHMO = (data) => {
    if (selectedCompany && selectedCompany.company_id === data.company_id) {
      console.log("company currently selected");
      return;
    }

    axios
      .get("endorsement/company/hmo-list", {
        params: {
          management_id: users.management_id,
          company_id: data.company_id,
        },
      })
      .then((response) => {
        setHMO(response.data);
      })
      .finally(() => setSelectedCompany(data))
      .catch((error) => console.log("request: ", error));
  };

  useEffect(() => {
    getManagementPatientList();
  }, [getManagementPatientList]);

  return (
    <Paper>
      <Card>
        <CardHeader
          title={
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h5" color="textSecondary">
                  Company
                </Typography>
              </Box>
              <Box>
                <TextField
                  label="Search name"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <Search color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          }
        />

        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b> Company </b>
                  </TableCell>
                  <TableCell>
                    <b> Address </b>
                  </TableCell>
                  <TableCell>
                    <b> Contact </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Action </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.ready ? (
                  list.data.length > 0 ? (
                    list.data.map((data, index) => (
                      <Fragment key={index}>
                        <TableRow className={classes.root}>
                          <TableCell> {data.company} </TableCell>
                          <TableCell> {data.address} </TableCell>
                          <TableCell> {data.contact_person} </TableCell>
                          <TableCell align="center">
                            <Tooltip
                              title="View HMO"
                              arrow
                              onClick={() => getCompanyHMO(data)}
                            >
                              <IconButton color="primary">
                                {selectedCompany &&
                                selectedCompany.company_id ===
                                  data.company_id ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowRightIcon />
                                )}
                              </IconButton>
                            </Tooltip>

                            <Tooltip arrow title="Company SOA">
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  history.push(
                                    `/bmcdc/app/billing/soa/company/${data.company_id}`
                                  )
                                }
                              >
                                <ArrowRight />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            colSpan={4}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            {selectedCompany &&
                              selectedCompany.company_id ===
                                data.company_id && (
                                <Collapse
                                  in={
                                    selectedCompany &&
                                    selectedCompany.company_id ===
                                      data.company_id
                                  }
                                  unmountOnExit
                                >
                                  <Box>
                                    <Box>
                                      <Typography color="textSecondary">
                                        HMO List
                                      </Typography>
                                    </Box>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell> DATE </TableCell>
                                          <TableCell> HMO </TableCell>
                                          <TableCell> ACTION </TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {hmo.length > 0 ? (
                                          hmo.map((data, index) => (
                                            <TableRow key={index}>
                                              <TableCell>
                                                {Notify.createdAt(
                                                  data.created_at
                                                )}
                                              </TableCell>
                                              <TableCell>{data.hmo}</TableCell>
                                              <TableCell>
                                                <Tooltip
                                                  title={`${data.hmo} SOA`}
                                                  arrow
                                                >
                                                  <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                      history.push(
                                                        `/bmcdc/app/billing/soa/hmo/${data.mach_id}`
                                                      );
                                                    }}
                                                  >
                                                    <ArrowRight />
                                                  </IconButton>
                                                </Tooltip>
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        ) : (
                                          <TableRow>
                                            <TableCell colSpan={3}>
                                              <Typography>
                                                No record found.
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              )}
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="primary">
                        loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default CompanySoa;
