import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { grey } from "@material-ui/core/colors";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { toast } from "react-toastify";
import UploadIcon from "@material-ui/icons/CloudUpload";
import { UsersData } from "src/ContextAPI";

const radiologistType = [
  {
    value: "In-House",
    label: "In-House",
  },
  {
    value: "Telerad",
    label: "Telerad",
  },
];

// new abcde
const ImagingOrderVirtualDetails = ({
  doctorID,
  patientId,
  imagingOrderID,
  resetDisplay,
  getImagingVirtualOrder,
}) => {
  const { users } = React.useContext(UsersData);
  const [patientInfo, setPatientInfo] = useState({ data: null, ready: false });
  const [orderInfo, setOrderInfo] = useState({ data: null, ready: false });
  const [isProcess, setIsProcess] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [radiologist, setRadiologist] = useState({ data: [], ready: false });
  const [teleradiologist, setTeleRadiologist] = useState({
    data: [],
    ready: false,
  });
  const [selectedRadiologist, setSelectedRadiologist] = useState(null);
  const [selectedTeleRadiologist, setSelectedTeleRadiologist] = useState(null);
  const [selectedRadType, setSelectedRadType] = useState(null);
  const imageInputRef = useRef(null);

  const getPatientInformation = () => {
    var params = { patient_id: patientId };
    Axios.get("imaging/virtual/get/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setPatientInfo({ data, ready: true });
      })
      .catch((error) => Notify.requestError(error));
  };

  const getImagingOrderInfo = () => {
    var params = { imaging_center_id: imagingOrderID };
    Axios.get("imaging/virtual/get/order/order-information", { params })
      .then((response) => {
        const data = response.data;
        setOrderInfo({ data, ready: true });
      })
      .catch((error) => Notify.requestError(error));
  };

  const getLocalRadiologistList = () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    Axios.get("imaging/get/radiologist/list", { params })
      .then((response) => {
        const data = response.data;
        setRadiologist({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getTeleRadiologistList = () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      connection: "online",
    };
    Axios.get("imaging/virtual/get/tele-radiologist/list", { params })
      .then((response) => {
        const data = response.data;
        setTeleRadiologist({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleSelectImages = (e) => {
    if (e.currentTarget.files.length > 5) {
      toast.error("Too many attachments select only five images.");
      setSelectedFile([]);
      return;
    }

    var attachment = [];
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      if (e.currentTarget.files[i].size > 5145728) {
        toast.error("File size must be less than 5mb.");
        setSelectedFile([]);
      } else if (
        e.currentTarget.files[i].type !== "image/jpeg" &&
        e.currentTarget.files[i].type !== "image/png" &&
        e.currentTarget.files[i].type !== "image/jpg"
      ) {
        toast.error("Unable to upload attachment, Invalid format.");
        setSelectedFile([]);
      } else {
        attachment.push(e.currentTarget.files[i]);
      }
    }
    setSelectedFile(attachment);
  };

  const handleSaveAttachment = () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.append("patient_id", patientId);
    formdata.append("imaging_center_id", imagingOrderID);
    formdata.append("radiologistType", selectedRadType);
    formdata.append("radiologist", selectedRadiologist);
    formdata.append("teleradiologist", selectedTeleRadiologist);
    formdata.append(
      "imaging_order",
      orderInfo.data && orderInfo.data.imaging_order
    );
    formdata.append("case_file", orderInfo.data && orderInfo.data.case_file);
    formdata.append("doctors_id", orderInfo.data && orderInfo.data.doctors_id);

    for (let i = 0; i < selectedFile.length; i++) {
      formdata.append("attachment[]", selectedFile[i]);
    }

    var error = [];

    if (orderInfo.data && orderInfo.data.imaging_order === null) {
      error = "error";
      Notify.fieldRequired("imaging order");
    }

    if (selectedRadType === null || selectedRadType.trim() === "") {
      error = "error";
      Notify.fieldRequired("type");
    }
    if (selectedRadType === "In-House") {
      if (selectedRadiologist === null || selectedRadiologist.trim() === "") {
        error = "error";
        Notify.fieldRequired("radiologist");
      }
    }
    if (selectedRadType === "Telerad") {
      if (
        selectedTeleRadiologist === null ||
        selectedTeleRadiologist.trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("teleradiologist");
      }
    }

    if (selectedFile.length === 0) {
      error = "error";
      Notify.fieldRequired("attachments");
    }

    if (error.length > 0) {
      console.log("unable to process request form has an error.");
    } else {
      setIsProcess(true);
      Axios.post("imaging/virtual/order/addresults", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getImagingVirtualOrder();
            resetDisplay();
            Notify.successRequest("order process");
          }
        })
        .catch((error) => Notify.requestError(error))
        .finally(() => setIsProcess(false));
    }
  };

  useEffect(() => {
    getLocalRadiologistList();
    getTeleRadiologistList();
    getPatientInformation();
    getImagingOrderInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagingOrderID]);

  return (
    <Fragment>
      <Paper variant={"outlined"} component={Box} p={2}>
        {patientInfo.ready ? (
          Object.values(patientInfo.data).length > 0 ? (
            <Box>
              <Box my={2}>
                <Typography variant="subtitle2" color="primary">
                  PATIENT INFORMATION
                </Typography>
              </Box>

              <Box my={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={`${patientInfo.data.firstname} ${
                          patientInfo.data.middle === null
                            ? ""
                            : patientInfo.data.middle
                        } ${patientInfo.data.lastname}`}
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box>
                      <TextField
                        label="Mobile"
                        variant="outlined"
                        fullWidth
                        value={
                          patientInfo.data.mobile === null
                            ? "none"
                            : patientInfo.data.mobile
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box>
                      <TextField
                        label="Telephone"
                        variant="outlined"
                        fullWidth
                        value={
                          patientInfo.data.telephone === null
                            ? "none"
                            : patientInfo.data.telephone
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box>
                      <TextField
                        label="Gender"
                        variant="outlined"
                        fullWidth
                        value={
                          patientInfo.data.gender === null
                            ? "none"
                            : patientInfo.data.gender
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <TextField
                        label="Age"
                        variant="outlined"
                        fullWidth
                        value={
                          patientInfo.data.birthday === null
                            ? "none"
                            : Notify.calculateAge(patientInfo.data.birthday)
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        label="Address"
                        variant="outlined"
                        fullWidth
                        value={`${patientInfo.data.street} ${patientInfo.data.barangay} ${patientInfo.data.city}`}
                        InputProps={{ readOnly: true }}
                        multiline
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* order information */}
              <Box my={2}>
                <Typography variant="subtitle2" color="primary">
                  ORDER INFORMATION
                </Typography>
              </Box>

              <Box my={2}>
                {orderInfo.ready ? (
                  Object.values(orderInfo.data).length > 0 ? (
                    <>
                      <Box
                        border={1}
                        borderColor={grey[400]}
                        p={2}
                        borderRadius={4}
                      >
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Orders
                          </Typography>
                        </Box>

                        {orderInfo.data.imaging_order
                          .split(",")
                          .map((data, index) => (
                            <Box mx={2} key={index}>
                              <Typography variant="subtitle2">
                                &bull; {data}
                              </Typography>
                            </Box>
                          ))}
                      </Box>

                      <Box my={3}>
                        <TextField
                          label="Order Remarks"
                          variant="outlined"
                          fullWidth
                          value={`${
                            orderInfo.data.imaging_remarks === null
                              ? ""
                              : orderInfo.data.imaging_remarks
                          }`}
                          InputProps={{ readOnly: true }}
                          multiline
                        />
                      </Box>

                      <Box my={3}>
                        <TextField
                          label="Requested By Doctor"
                          variant="outlined"
                          fullWidth
                          multiline
                          InputProps={{ readOnly: true }}
                          value={`${
                            orderInfo.data.doctor_name === null
                              ? ""
                              : orderInfo.data.doctor_name
                          }`}
                        />
                      </Box>

                      <Box>
                        <Box my={2}>
                          <Typography variant="subtitle2" color="primary">
                            RESULTS INFORMATION
                          </Typography>
                        </Box>

                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box mb={1}>
                                <TextField
                                  label="Type"
                                  variant="outlined"
                                  fullWidth
                                  select
                                  onChange={(e) =>
                                    setSelectedRadType(e.target.value)
                                  }
                                  SelectProps={{ native: true }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                >
                                  <option value=""> -- Select -- </option>
                                  {radiologistType.map((data, index) => (
                                    <option key={index} value={data.value}>
                                      {" "}
                                      {data.label}{" "}
                                    </option>
                                  ))}
                                </TextField>
                              </Box>
                            </Grid>

                            {selectedRadType === "In-House" && (
                              <Grid item xs={12}>
                                <Box mb={1}>
                                  <TextField
                                    label="Radiologist"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    onChange={(e) =>
                                      setSelectedRadiologist(e.target.value)
                                    }
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  >
                                    <option value=""> -- Select -- </option>
                                    {radiologist.ready ? (
                                      radiologist.data.length > 0 ? (
                                        radiologist.data.map((data, index) => (
                                          <option
                                            key={index}
                                            value={data.radiologist_id}
                                          >
                                            {" "}
                                            {data.name}{" "}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="" disabled>
                                          {" "}
                                          No radiologist found.{" "}
                                        </option>
                                      )
                                    ) : (
                                      <option value="" disabled>
                                        {" "}
                                        Please wait...{" "}
                                      </option>
                                    )}
                                  </TextField>
                                </Box>
                              </Grid>
                            )}

                            {selectedRadType === "Telerad" && (
                              <Grid item xs={12}>
                                <Box mb={1}>
                                  <TextField
                                    label="Teleradiologist"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    onChange={(e) =>
                                      setSelectedTeleRadiologist(e.target.value)
                                    }
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  >
                                    <option value=""> -- Select -- </option>
                                    {teleradiologist.ready ? (
                                      teleradiologist.data.length > 0 ? (
                                        teleradiologist.data.map(
                                          (data, index) => (
                                            <option
                                              key={index}
                                              value={data.telerad_id}
                                            >
                                              {" "}
                                              {data.name}{" "}
                                            </option>
                                          )
                                        )
                                      ) : (
                                        <option value="" disabled>
                                          {" "}
                                          No teleradiologist found.{" "}
                                        </option>
                                      )
                                    ) : (
                                      <option value="" disabled>
                                        {" "}
                                        Please wait...{" "}
                                      </option>
                                    )}
                                  </TextField>
                                </Box>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <Box mb={1}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  label={"Attachment"}
                                  value={`${selectedFile.length} Selected Attachment`}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip title={"Upload Attachments"}>
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            color="primary"
                                            edge="end"
                                            onClick={() =>
                                              imageInputRef.current.click()
                                            }
                                          >
                                            <UploadIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <input
                                  onChange={handleSelectImages}
                                  type="file"
                                  ref={imageInputRef}
                                  multiple
                                  hidden
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              {selectedFile.map((data, key) => (
                                <Box
                                  key={key}
                                  mb={1}
                                  border={1}
                                  borderColor={grey[500]}
                                  padding={2}
                                  borderRadius={4}
                                >
                                  <Tooltip title={data.name}>
                                    <Typography noWrap variant="subtitle2">
                                      {" "}
                                      {data.name.toUpperCase()}{" "}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              ))}
                            </Grid>
                          </Grid>
                        </Box>

                        <Box display="flex">
                          <Box flexGrow={1} />
                          <Box display="flex">
                            <Box mr={2}>
                              <Button
                                variant="contained"
                                color="default"
                                onClick={resetDisplay}
                                startIcon={<HighlightOffIcon />}
                              >
                                No
                              </Button>
                            </Box>

                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleSaveAttachment}
                              startIcon={
                                isProcess ? (
                                  <CircularProgress size={20} color="inherit" />
                                ) : (
                                  <CheckCircleOutlineIcon />
                                )
                              }
                              disabled={isProcess}
                            >
                              Process
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    Notify.noRecord()
                  )
                ) : (
                  Notify.loading()
                )}
              </Box>
            </Box>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Paper>
    </Fragment>
  );
};

export default ImagingOrderVirtualDetails;
