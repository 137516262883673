import React, { Fragment, useContext } from "react";
import {
  Box,
  Paper,
  FormHelperText,
  Divider,
  Button,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const HospitalAddAccount = ({ getAllUsersAccount, branchList }) => {
  const { users } = useContext(UsersData);

  const accounts = [
    { value: "accounting", label: "Accounting" }, //okay
    { value: "billing", label: "Billing" }, //okay
    { value: "cashier", label: "Cashier" }, //okay
    { value: "doctor", label: "Doctor" }, //okay
    { value: "encoder", label: "Encoder/Documentation" }, //okay
    { value: "endorsement", label: "Endorsement" },
    { value: "warehouse", label: "Haptech" },
    { value: "hr", label: "Human Resources" },
    { value: "imaging", label: "Imaging" },
    { value: "laboratory", label: "Laboratory" },
    // { value: "pharmacy", label: "Pharmacy" },
    { value: "om", label: "Operation Manager" },
    { value: "nurse", label: "Nurse Station" },
    // { value: "psychologist", label: "Psychologist" },
    { value: "psychology", label: "Audiometrician/Psychometrician/etc" },
    { value: "radiologist", label: "Radiologist" },
    { value: "receiving", label: "Receiving/Extraction" },
    { value: "registration", label: "Registration/Triage" },
    // { value: "stockroom", label: "Stockroom" },
    // { value: "triage", label: "Triage" },
    { value: "others", label: "Others" },
    // { value: "warehouse", label: "haptech" },
  ];

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Fragment>
      <Box component={Paper} variant="outlined" p={2}>
        <Formik
          initialValues={{
            username: users.username,
            user_id: users.user_id,
            management_id: users.management_id,
            main_mgmt_id: users.main_mgmt_id,
            generateuser_id: "u-" + Date.now(),
            branch: "",
            department: "",
            date_started: "",
            date_birth: "",
            status: "",
            civil_status: "",
            contact: "",
            email: "",
            fullname: "",
            address: "",
            gender: "",
            rate_classification: "",
            position: "",
            shared: "",
            sick_leave: 0,
            vacation_leave: 0,
            hazard_start_15: 0,
            hazard_16_end: 0,
            daily_salary: "",
            monthly_salary: "",
            user_username: "",
            user_pass: "",
            password: "",
          }}
          validationSchema={() =>
            Yup.lazy((values) =>
              values.department !== "doctor" &&
              values.department !== "radiologist"
                ? Yup.object().shape({
                    branch: Yup.string().trim().required("Branch is required"),
                    department: Yup.string()
                      .trim()
                      .required("Department is required"),
                    date_started: Yup.date().required(
                      "Date started  is required."
                    ),
                    date_birth: Yup.date().required("Birth date is required."),
                    status: Yup.string()
                      .trim()
                      .required("Employee status is required"),
                    civil_status: Yup.string()
                      .trim()
                      .required("Civil status is required"),
                    contact: Yup.string()
                      .trim()
                      .required("Contact # is required"),
                    email: Yup.string().trim().required("Email is required"),
                    fullname: Yup.string()
                      .trim()
                      .required("Fullname is required"),
                    address: Yup.string()
                      .trim()
                      .required("Address is required"),
                    gender: Yup.string().trim().required("Gender is required"),
                    rate_classification: Yup.string()
                      .trim()
                      .required("Rate classification is required"),
                    position: Yup.string()
                      .trim()
                      .required("Position - location is required"),
                    shared: Yup.string()
                      .trim()
                      .required("Shared salary is required"),
                    sick_leave: Yup.string()
                      .trim()
                      .required("sick leave credit"),
                    vacation_leave: Yup.string()
                      .trim()
                      .required("vacation leave credit"),
                    hazard_start_15: Yup.string()
                      .trim()
                      .required("Hazard pay 1-15 is required"),
                    hazard_16_end: Yup.string()
                      .trim()
                      .required("Hazard pay 16-28/29/30/31 is required"),
                    user_username: Yup.string()
                      .trim()
                      .required("User's username is required"),
                    user_pass: Yup.string()
                      .trim()
                      .required("User's password is required"),
                    password: Yup.string().required(
                      "Your password is required"
                    ),
                  })
                : Yup.object().shape({
                    branch: Yup.string().trim().required("Branch is required"),
                    department: Yup.string()
                      .trim()
                      .required("Department is required"),
                    date_birth: Yup.date().required("Birth date is required."),
                    contact: Yup.string()
                      .trim()
                      .required("Contact # is required"),
                    email: Yup.string().trim().required("Email is required"),
                    fullname: Yup.string()
                      .trim()
                      .required("Fullname is required"),
                    address: Yup.string()
                      .trim()
                      .required("Address is required"),
                    gender: Yup.string().trim().required("Gender is required"),
                    user_username: Yup.string()
                      .trim()
                      .required("User's username is required"),
                    user_pass: Yup.string()
                      .trim()
                      .required("User's password is required"),
                    password: Yup.string().required(
                      "Your password is required"
                    ),
                  })
            )
          }
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            var error = [];
            if (
              values.rate_classification === "Daily" &&
              values.daily_salary.length === 0
            ) {
              error = "error";
              setErrors({ daily_salary: "Daily Salary is required." });
            }
            if (
              values.rate_classification === "Monthly" &&
              values.monthly_salary.length === 0
            ) {
              error = "error";
              setErrors({ monthly_salary: "Monthly Salary is required." });
            }
            if (error.length > 0) {
              Notify.consoleLog("form error.");
            } else {
              try {
                const request = await axios.post(
                  "hims/add/new-account",
                  getFormData(values)
                );
                if (request.data === "pass-invalid") {
                  setErrors({ password: "Password is invalid." });
                  Notify.fieldInvalid("password");
                }
                if (request.data === "success") {
                  Notify.successRequest("add new imaging test");
                  resetForm();
                  setSubmitting(true);
                  getAllUsersAccount();
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box mb={2}>
                <Typography color="textPrimary" variant="subtitle2">
                  NEW ACCOUNT
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.branch && errors.branch)}
                      helperText={touched.branch && errors.branch}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.branch}
                      fullWidth
                      required
                      name="branch"
                      label="Branch"
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <option value={""}>Select</option>
                      {branchList.data.length > 0 &&
                        branchList.data.map((data, index) => (
                          <option key={index} value={data.management_id}>
                            {data.name}
                          </option>
                        ))}
                    </TextField>
                  </Box>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.department && errors.department)}
                      helperText={touched.department && errors.department}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.department}
                      fullWidth
                      required
                      name="department"
                      label="Department"
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <option value={""}>Select</option>
                      {accounts.length > 0 &&
                        accounts.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.label}
                          </option>
                        ))}
                    </TextField>
                  </Box>
                </Grid>

                {values.department !== "doctor" &&
                values.department !== "radiologist" ? (
                  <Grid item xs={6} sm={6}>
                    <Box className={`labselect2`}>
                      <TextField
                        error={Boolean(
                          touched.date_started && errors.date_started
                        )}
                        helperText={touched.date_started && errors.date_started}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.date_started}
                        fullWidth
                        required
                        name="date_started"
                        label="Date Started"
                        variant="outlined"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  </Grid>
                ) : null}

                <Grid item xs={6} sm={6}>
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.date_birth && errors.date_birth)}
                      helperText={touched.date_birth && errors.date_birth}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.date_birth}
                      fullWidth
                      required
                      name="date_birth"
                      label="Birth Date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </Grid>

                {values.department !== "doctor" &&
                values.department !== "radiologist" ? (
                  <Grid item xs={6} sm={6}>
                    <Box className={`labselect2`}>
                      <TextField
                        error={Boolean(touched.status && errors.status)}
                        helperText={touched.status && errors.status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.status}
                        fullWidth
                        required
                        name="status"
                        label="Employee status"
                        variant="outlined"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"Regular"}>Regular</option>
                        <option value={"Probationary"}>Probationary</option>
                      </TextField>
                    </Box>
                  </Grid>
                ) : null}

                {values.department !== "doctor" &&
                values.department !== "radiologist" ? (
                  <Grid item xs={6} sm={6}>
                    <Box className={`labselect2`}>
                      <TextField
                        error={Boolean(
                          touched.civil_status && errors.civil_status
                        )}
                        helperText={touched.civil_status && errors.civil_status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.civil_status}
                        fullWidth
                        required
                        name="civil_status"
                        label="Civil Status"
                        variant="outlined"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"Single"}>Single</option>
                        <option value={"Married"}>Married</option>
                        <option value={"Divorced"}>Divorced</option>
                      </TextField>
                    </Box>
                  </Grid>
                ) : null}

                <Grid item xs={6} sm={6}>
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.contact && errors.contact)}
                      helperText={touched.contact && errors.contact}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.contact}
                      fullWidth
                      required
                      name="contact"
                      label="Contact #"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={values.department === "doctor" ? 12 : 6}
                  sm={
                    values.department === "doctor" ||
                    values.department === "radiologist"
                      ? 12
                      : 6
                  }
                >
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      fullWidth
                      required
                      name="email"
                      label="Email"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.fullname && errors.fullname)}
                      helperText={touched.fullname && errors.fullname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fullname}
                      fullWidth
                      required
                      name="fullname"
                      label="Fullname"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      fullWidth
                      required
                      name="address"
                      label="Address"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={
                    values.department === "doctor" ||
                    values.department === "radiologist"
                      ? 12
                      : 6
                  }
                  sm={
                    values.department === "doctor" ||
                    values.department === "radiologist"
                      ? 12
                      : 6
                  }
                >
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.gender && errors.gender)}
                      helperText={touched.gender && errors.gender}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.gender}
                      fullWidth
                      required
                      name="gender"
                      label="Gender"
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <option value={""}>Select</option>
                      <option value={"Male"}>Male</option>
                      <option value={"Female"}>Female</option>
                    </TextField>
                  </Box>
                </Grid>

                {values.department !== "doctor" &&
                values.department !== "radiologist" ? (
                  <Grid item xs={6} sm={6}>
                    <Box className={`labselect2`}>
                      <TextField
                        error={Boolean(
                          touched.rate_classification &&
                            errors.rate_classification
                        )}
                        helperText={
                          touched.rate_classification &&
                          errors.rate_classification
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rate_classification}
                        fullWidth
                        required
                        name="rate_classification"
                        label="Rate Classification"
                        variant="outlined"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"Daily"}>Daily</option>
                        <option value={"Monthly"}>Monthly</option>
                      </TextField>
                    </Box>
                  </Grid>
                ) : null}

                {values.rate_classification === "Daily" &&
                values.department !== "doctor" &&
                values.department !== "radiologist" ? (
                  <Grid item xs={12} sm={12}>
                    <Box className={`labselect2`}>
                      <TextField
                        error={Boolean(
                          touched.daily_salary && errors.daily_salary
                        )}
                        helperText={touched.daily_salary && errors.daily_salary}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.daily_salary}
                        fullWidth
                        required
                        name="daily_salary"
                        label="Daily Salary"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 100000000000,
                            step: 0.01,
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                ) : null}

                {values.rate_classification === "Monthly" &&
                values.department !== "doctor" &&
                values.department !== "radiologist" ? (
                  <Grid item xs={12} sm={12}>
                    <Box className={`labselect2`}>
                      <TextField
                        error={Boolean(
                          touched.monthly_salary && errors.monthly_salary
                        )}
                        helperText={
                          touched.monthly_salary && errors.monthly_salary
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.monthly_salary}
                        fullWidth
                        required
                        name="monthly_salary"
                        label="Monthly Salary"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 100000000000,
                            step: 0.01,
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                ) : null}

                {values.department !== "doctor" &&
                values.department !== "radiologist" ? (
                  <Grid item xs={6} sm={6}>
                    <Box className={`labselect2`}>
                      <TextField
                        error={Boolean(touched.position && errors.position)}
                        helperText={touched.position && errors.position}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.position}
                        fullWidth
                        required
                        name="position"
                        label="Position - location"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                ) : null}

                {values.department !== "doctor" &&
                values.department !== "radiologist" ? (
                  <Grid item xs={6} sm={6}>
                    <Box className={`labselect2`}>
                      <TextField
                        error={Boolean(touched.shared && errors.shared)}
                        helperText={touched.shared && errors.shared}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.shared}
                        fullWidth
                        required
                        name="shared"
                        label="Shared salary"
                        variant="outlined"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </TextField>
                    </Box>
                  </Grid>
                ) : null}

                {values.department !== "doctor" &&
                values.department !== "radiologist" ? (
                  <>
                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(
                            touched.sick_leave && errors.sick_leave
                          )}
                          helperText={touched.sick_leave && errors.sick_leave}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.sick_leave}
                          fullWidth
                          required
                          name="sick_leave"
                          label="Sick Leave Credit"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(
                            touched.vacation_leave && errors.vacation_leave
                          )}
                          helperText={
                            touched.vacation_leave && errors.vacation_leave
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.vacation_leave}
                          fullWidth
                          required
                          name="vacation_leave"
                          label="Vacation Leave Credit"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(
                            touched.hazard_start_15 && errors.hazard_start_15
                          )}
                          helperText={
                            touched.hazard_start_15 && errors.hazard_start_15
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.hazard_start_15}
                          fullWidth
                          required
                          name="hazard_start_15"
                          label="Hazard pay start-15"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(
                            touched.hazard_16_end && errors.hazard_16_end
                          )}
                          helperText={
                            touched.hazard_16_end && errors.hazard_16_end
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.hazard_16_end}
                          fullWidth
                          required
                          name="hazard_16_end"
                          label="Hazard pay 16-end"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12} sm={12}>
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(
                        touched.user_username && errors.user_username
                      )}
                      helperText={touched.user_username && errors.user_username}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.user_username}
                      fullWidth
                      required
                      name="user_username"
                      label="User's username"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.user_pass && errors.user_pass)}
                      helperText={touched.user_pass && errors.user_pass}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.user_pass}
                      fullWidth
                      required
                      name="user_pass"
                      label="User's password"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box>
                    <TextField
                      fullWidth
                      required
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      label="Enter your password"
                      variant="outlined"
                      type="password"
                    />
                  </Box>
                </Grid>
              </Grid>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Divider />

              <Box my={2} display="flex">
                <Box flexGrow={1} />

                <Button
                  variant="contained"
                  color="default"
                  type="reset"
                  startIcon={<ClearIcon />}
                >
                  Clear
                </Button>

                <Box ml={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Fragment>
  );
};

export default HospitalAddAccount;
