import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardHeader, Typography, useTheme } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const GrandCardSales = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [grandSales, setGrandSales] = useState(0);
  const theme = useTheme();

  const getAllSales = React.useCallback(async () => {
    let response = await axios.get("accounting/grand/sales-totalamount", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      if (response.data.length > 0) {
        calculateTotal(response.data);
      }
    }
  }, [mounted, users]);

  const calculateTotal = (data) => {
    let xxx = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i].length > 0) {
        var data2 = data[i];
        for (let j = 0; j < data2.length; j++) {
          xxx += parseFloat(data2[j].bill_amount) * 1;
        }
      }
    }
    setGrandSales(xxx);
  };

  useEffect(() => {
    getAllSales();
  }, [getAllSales]);

  return (
    <Card elevation={5}>
      <CardHeader
        avatar={
          <FontAwesomeIcon
            icon={faCubes}
            size={"5x"}
            style={{ color: `${theme.palette.primary.main}` }}
          />
        }
        title={
          <Typography variant="h4" color="textSecondary">
            Sales
          </Typography>
        }
        // subheader={
        //   <Typography variant="h5">
        //     <strong> &#8369; {Notify.convertToNumber(grandSales)} </strong>
        //   </Typography>
        // }
      />

      <Box mb={2}>
        <Typography variant="h4" align="center">
          <strong> &#8369; {Notify.convertToNumber(grandSales)} </strong>
        </Typography>
      </Box>
    </Card>
  );
};

export default GrandCardSales;
