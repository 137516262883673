import React, { useContext, useState, useEffect, useCallback } from "react";
import Container from "src/layout/Container";
import {
  Grid,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";
import Notify from "src/notification/Notify";
import GrandCard from "./GrandCard";
import BranchCard from "./BranchCard";

const AccountingManagement = () => {
  const mounted = IsMountedRef();
  const { users, updateUsers } = useContext(UsersData);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("hq-management");

  const handleMenuItemClick = (event, data) => {
    setSelectedBranch(data);

    // setSelectedBranch(data.management_id);
    // setSelectedBranchName(data.name);
    updateUsers({
      ...users,
      management_id: data.management_id,
      management_name: data.name,
    });
  };

  const getBranchesByMainMngtId = useCallback(async () => {
    var params = { main_management_id: users.main_mgmt_id };
    let response = await axios.get("general/management/get-branches", {
      params,
    });
    if (mounted.current) {
      setBranches(response.data);
    }
  }, [users, mounted]);

  useEffect(() => {
    getBranchesByMainMngtId();
  }, [getBranchesByMainMngtId]);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Management",
          items: [{ name: "dashboard", path: "/bmcdc/app" }],
        }}
        title={"Accounting Management"}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <Box component={Paper} borderRadius={4} p={2}>
              <Box mb={2}>
                <Typography color="textSecondary" variant="h6">
                  Branches
                </Typography>
              </Box>

              <List component="div">
                {branches.length > 0
                  ? branches.map((data, index) => (
                      <ListItem
                        key={index}
                        button
                        className="mb-2"
                        // onClick={(e) => handleMenuItemClick(e, data)}
                        // selected={
                        //   selectedBranch &&
                        //   selectedBranch.management_id === data.management_id
                        // }
                        onClick={(e) => handleMenuItemClick(e, data)}
                        selected={
                          selectedBranch &&
                          selectedBranch.management_id === data.management_id
                        }
                      >
                        <ListItemText
                          className={`gtc-capitalize`}
                          primary={`${data.name}`}
                          primaryTypographyProps={{
                            style: {
                              textTransform: "uppercase",
                            },
                          }}
                        />
                      </ListItem>
                    ))
                  : Notify.noRecord()}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            {selectedBranch === "hq-management" && <GrandCard />}
            {selectedBranch !== "hq-management" && (
              <BranchCard selectedBranch={selectedBranch} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AccountingManagement;
