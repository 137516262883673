import React, { Fragment, useContext, useState } from "react";
import Container from "src/layout/Container";
import {
  Grid,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Chip,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  Button,
  FormHelperText,
  Divider,
  DialogContent,
  Zoom,
  CardMedia,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import axios from "axios";
import { BranchListContext, UsersData } from "src/ContextAPI";
import HospitalAddAccount from "./HospitalAddAccount";
import { CancelOutlined, Search, TvRounded } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Formik } from "formik";
import * as Yup from "yup";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import EditEmployeeInformation from "./dialog/EditEmployeeInformation";

const HRHospAccounts = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const { branchList } = useContext(BranchListContext);
  const { users } = React.useContext(UsersData);
  const [userList, setUserList] = useState({ data: [], ready: false });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDailyRate, setSelectedDailyRate] = useState(null);
  const [selectedMonthRate, setSelectedMonthRate] = useState(null);
  const [selectedUserManagement, setSelectedUserManagement] = useState(null);
  const [selectedDepartmentType, setSelectedDepartmentType] = useState(null);

  const [editRateDiag, setEditRateDiag] = useState(false);
  const [viewRateDiag, setViewRateDiag] = useState(false);
  const [employeeInfoDiag, setEmployeeInfoDiag] = useState(false);
  const [disableUserDialog, setDisableUserDialog] = useState(false);
  const [selectedResignType, setSelectedResignType] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [isSubmitting, setIsSubmitting] = useState();

  const handleSelectedAction = (event, data) => {
    console.log("value", data);
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(data.user_id);
    setSelectedStatus(data.status);
    setSelectedCategory(data.employee_class);
    setSelectedDailyRate(data.daily_salary);
    setSelectedMonthRate(data.monthly_salary);
    setSelectedUserManagement(data.manage_by);

    setSelectedDepartmentType(data.type);
  };

  const handleCloseAction = () => {
    setAnchorEl(null);
    setSelectedEmployee(null);
    setSelectedStatus(null);
    setSelectedCategory(null);
    setSelectedDailyRate(null);
    setSelectedMonthRate(null);
    setSelectedUserManagement(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getAllUsersAccount = React.useCallback(() => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      branch: selectedBranch,
      main_management_id: users.main_mgmt_id,
    };
    axios
      .get("hims/get/all-users-account", { params })
      .then((res) => {
        const data = res.data;
        setUserList({ data: data, ready: true });
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  }, [users, selectedBranch]);

  const handleInactiveAccount = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("users_id", selectedEmployee);
    formdata.set("status", selectedStatus);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("username", users.username);
    formdata.set("user_old_management", selectedUserManagement);
    var error = [];
    if (
      formdata.get("disable_type").trim() === "" ||
      formdata.get("disable_type").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("disable type");
    }
    if (formdata.get("disable_type") === "Lateral") {
      if (
        formdata.get("branch").length === 0 ||
        formdata.get("branch").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("branch");
      }
    }
    if (formdata.get("disable_type") === "Resign") {
      if (
        formdata.get("date_effect").length === 0 ||
        formdata.get("date_effect").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("date effectivity");
      }
    }
    if (
      formdata.get("password").trim() === "" ||
      formdata.get("password").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsSubmitting(TvRounded);
      axios
        .post("hmis/update/account-inactive", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getAllUsersAccount();
            Notify.successRequest("update");
            handleCloseAction();
            setDisableUserDialog(false);
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  React.useEffect(() => {
    getAllUsersAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllUsersAccount]);

  const searchable = userList.data.filter((data) => {
    return data._account_name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "User's Accounts",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="User's Accounts"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Box component={Paper} variant="outlined">
              <Box display="flex" m={2} className="d-print-none">
                <Box flexGrow={1} className="d-print-none">
                  <Box>
                    <TextField
                      label="Search name"
                      variant="outlined"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      margin="dense"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
                <Box className="d-print-none">
                  <TextField
                    required
                    name="branch"
                    label="Branch"
                    variant="outlined"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    onClick={(e) => setSelectedBranch(e.target.value)}
                  >
                    <option value="All">All</option>
                    {branchList.data.length > 0 &&
                      branchList.data.map((data, index) => (
                        <option key={index} value={data.management_id}>
                          {data.name}
                        </option>
                      ))}
                  </TextField>
                </Box>
              </Box>

              {userList.ready ? (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "bolder" }}
                          >
                            Name
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontWeight: "bolder" }}
                          >
                            Department
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontWeight: "bolder" }}
                            hidden={selectedBranch !== "All"}
                          >
                            Branch
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontWeight: "bolder" }}
                          >
                            Status
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontWeight: "bolder" }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {searchable.length > 0 ? (
                          searchable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <TableRow key={index}>
                                <TableCell>{data._account_name}</TableCell>
                                <TableCell align="center">
                                  {data.type === "HIS-Registration"
                                    ? "Registration/Triage"
                                    : data.type === "HIS-Nurse"
                                    ? "Nurse Station"
                                    : data.type === "HIS-Endorsement"
                                    ? "Endorsement"
                                    : data.type === "HIS-Cashier"
                                    ? "Cashier"
                                    : data.type === "HIS-Accounting"
                                    ? "Accounting"
                                    : data.type === "HIS-Hr"
                                    ? "HR"
                                    : data.type === "HIS-Billing"
                                    ? "Billing"
                                    : data.type === "HIS-Warehouse"
                                    ? "Haptech"
                                    : data.type === "HIS-Doctor"
                                    ? "Doctor"
                                    : data.type === "HIS-Documentation"
                                    ? "Documentation"
                                    : data.type === "HIS-Imaging"
                                    ? "X-ray"
                                    : data.type === "HIS-Laboratory"
                                    ? "Laboratory"
                                    : data.type === "HIS-OM"
                                    ? "Operation Manager"
                                    : data.type === "HIS-Psychology"
                                    ? "Psychology"
                                    : data.type === "HIS-Radiologist"
                                    ? "Radiologist"
                                    : data.type === "HIS-Receiving"
                                    ? "Receiving/Extraction"
                                    : data.type === "HIS-Others"
                                    ? "Other Department"
                                    : data.type}
                                </TableCell>

                                <TableCell hidden={selectedBranch !== "All"}>
                                  {data.branch_type}
                                </TableCell>

                                <TableCell align="center">
                                  <Chip
                                    size="small"
                                    label={
                                      parseFloat(data.status) === 1
                                        ? "Active"
                                        : "Inactive"
                                    }
                                    style={{
                                      backgroundColor:
                                        parseFloat(data.status) === 1
                                          ? "green"
                                          : "red",
                                      color: "#fff",
                                      cursor: "pointer",
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    onClick={(e) =>
                                      handleSelectedAction(e, data)
                                    }
                                  >
                                    <MoreVertIcon fontSize="small" />
                                  </IconButton>
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseAction}
                                  >
                                    <MenuItem
                                      onClick={() => setDisableUserDialog(true)}
                                    >
                                      Disable/Enable
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => setEditRateDiag(true)}
                                    >
                                      Edit Rate
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => setEmployeeInfoDiag(true)}
                                      hidden={
                                        data.type === "HIS-Doctor" &&
                                        data.type === "HIS-Radiologist"
                                      }
                                    >
                                      Edit Employee Info
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => setViewRateDiag(true)}
                                    >
                                      Rate Info
                                    </MenuItem>
                                  </Menu>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Typography variant="subtitle2" color="secondary">
                                No accounts added
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component={"div"}
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={searchable.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <RenderSelectBranch />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <HospitalAddAccount
              getAllUsersAccount={() => {
                getAllUsersAccount();
                // getAllUsersAccount()
              }}
              branchList={branchList}
            />
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={editRateDiag}
        onClose={() => setEditRateDiag(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
      >
        <DialogContent>
          <Formik
            initialValues={{
              username: users.username,
              user_id: users.user_id,
              management_id: users.management_id,
              rate_classification: selectedCategory,
              monthly_salary: selectedMonthRate,
              daily_salary: selectedDailyRate,
              users_user_id: selectedEmployee,
              password: "",
            }}
            validationSchema={Yup.object().shape({
              rate_classification: Yup.string()
                .trim()
                .required("Rate classification is required"),

              password: Yup.string().required("Your password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm }
            ) => {
              var error = [];
              if (
                values.rate_classification === "Daily" &&
                values.daily_salary.length === 0
              ) {
                error = "error";
                setErrors({ daily_salary: "Daily Salary is required." });
              }
              if (
                values.rate_classification === "Monthly" &&
                values.monthly_salary.length === 0
              ) {
                error = "error";
                setErrors({ monthly_salary: "Monthly Salary is required." });
              }
              if (error.length > 0) {
                Notify.consoleLog("form error.");
              } else {
                try {
                  const request = await axios.post(
                    "hmis/update/account-rate-class",
                    getFormData(values)
                  );
                  if (request.data === "pass-invalid") {
                    setErrors({ password: "Password is invalid." });
                    Notify.fieldInvalid("password");
                  }
                  if (request.data === "success") {
                    Notify.successRequest("add new imaging test");
                    resetForm();
                    setSubmitting(true);
                    getAllUsersAccount();
                    // getAllUsersAccount();
                    setEditRateDiag(false);
                  }
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box mb={2}>
                  <Typography color="textPrimary" variant="subtitle1">
                    Edit Rate Classification
                  </Typography>
                </Box>

                <Divider />

                <Box mb={2} className={`labselect2`}>
                  <TextField
                    error={Boolean(
                      touched.rate_classification && errors.rate_classification
                    )}
                    helperText={
                      touched.rate_classification && errors.rate_classification
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.rate_classification}
                    fullWidth
                    required
                    name="rate_classification"
                    label="Rate Classification"
                    variant="outlined"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value={""}>Select</option>
                    <option value={"Daily"}>Daily</option>
                    <option value={"Monthly"}>Monthly</option>
                  </TextField>
                </Box>

                {values.rate_classification === "Daily" && (
                  <Box mb={2} className={`labselect2`}>
                    <TextField
                      error={Boolean(
                        touched.daily_salary && errors.daily_salary
                      )}
                      helperText={touched.daily_salary && errors.daily_salary}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.daily_salary}
                      fullWidth
                      required
                      name="daily_salary"
                      label="Daily Salary"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 100000000000,
                          step: 0.01,
                        },
                      }}
                    />
                  </Box>
                )}

                {values.rate_classification === "Monthly" && (
                  <Box mb={2} className={`labselect2`}>
                    <TextField
                      error={Boolean(
                        touched.monthly_salary && errors.monthly_salary
                      )}
                      helperText={
                        touched.monthly_salary && errors.monthly_salary
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.monthly_salary}
                      fullWidth
                      required
                      name="monthly_salary"
                      label="Monthly Salary"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 100000000000,
                          step: 0.01,
                        },
                      }}
                    />
                  </Box>
                )}

                <Box mb={2}>
                  <TextField
                    fullWidth
                    required
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    label="Enter your password"
                    variant="outlined"
                    type="password"
                  />
                </Box>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Divider />

                <Box my={2} display="flex">
                  <Box flexGrow={1} />

                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<ClearIcon />}
                    onClick={() => setEditRateDiag(false)}
                  >
                    Close
                  </Button>

                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<CheckIcon />}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Dialog
        open={disableUserDialog}
        onClose={() => setDisableUserDialog(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Box mb={2}>
            <Typography color="textPrimary" variant="subtitle1">
              Disable/Transfer this Account
            </Typography>
          </Box>
          <form onSubmit={handleInactiveAccount}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box className={`labselect2`}>
                  <TextField
                    fullWidth
                    required
                    name="disable_type"
                    label="Disable type"
                    variant="outlined"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setSelectedResignType(e.target.value)}
                  >
                    <option value={""}>Select</option>
                    <option value={"Lateral"}>Lateral</option>
                    <option value={"AWOL"}>AWOL</option>
                    <option value={"Resign"}>Resign</option>
                  </TextField>
                </Box>
              </Grid>

              {selectedResignType === "Lateral" && (
                <Grid item xs={12} sm={12}>
                  <Box className={`labselect2`}>
                    <TextField
                      fullWidth
                      required
                      name="branch"
                      label="Branch"
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <option value={""}>Select</option>
                      {branchList.data.length > 0 &&
                        branchList.data.map((data, index) => (
                          <option key={index} value={data.management_id}>
                            {data.name}
                          </option>
                        ))}
                    </TextField>
                  </Box>
                </Grid>
              )}

              {selectedResignType === "Resign" && (
                <Grid item xs={12} sm={12}>
                  <Box className={`labselect2`}>
                    <TextField
                      fullWidth
                      required
                      name="date_effect"
                      label="Date Effectivity"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    fullWidth
                    required
                    name="password"
                    label="Enter your password"
                    variant="outlined"
                    type="password"
                  />
                </Box>
              </Grid>

              <Divider />

              <Box my={2} display="flex">
                <Box flexGrow={1} />

                <Button
                  variant="contained"
                  color="default"
                  startIcon={<CancelOutlined />}
                  onClick={() => setDisableUserDialog(false)}
                >
                  Close
                </Button>

                <Box ml={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={viewRateDiag}
        onClose={() => setViewRateDiag(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
      >
        <DialogContent>
          <Box mb={2}>
            <Typography color="textPrimary" variant="subtitle1">
              Current Rate Info
            </Typography>
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              value={selectedCategory}
              label=""
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          {selectedCategory === "Monthly" ? (
            <Box mb={2}>
              <TextField
                fullWidth
                value={Notify.convertToNumber(selectedMonthRate)}
                label=""
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          ) : (
            <Box mb={2}>
              <TextField
                fullWidth
                value={Notify.convertToNumber(selectedDailyRate)}
                label=""
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={employeeInfoDiag}
        onClose={() => setEmployeeInfoDiag(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
      >
        <DialogContent>
          <Box mb={2}>
            <Typography color="textPrimary" variant="subtitle1">
              Edit Employee Information
            </Typography>
          </Box>
          <EditEmployeeInformation
            close={() => setEmployeeInfoDiag(false)}
            selectedEmployee={selectedEmployee}
            selectedDepartmentType={selectedDepartmentType}
            getAllUsersAccount={() => getAllUsersAccount()}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default HRHospAccounts;

const RenderSelectBranch = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />

    <Typography color="secondary" variant="h3">
      NO BRANCH SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select branch in the list to view details.
    </Typography>
  </Box>
);
