import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from "@date-io/date-fns";
import Notify from "src/notification/Notify";
import axios from "axios";
import { UsersData } from "src/ContextAPI";

const LocalPrescriptionReport = () => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { users } = React.useContext(UsersData);

  const [prescription, setPrescription] = useState({
    data: [],
    ready: false,
  });

  var totalPurchased = 0;

  const getPrescriptionRecords = () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("connection", "offline");
    formdata.set("prescription_type", "local");

    axios
      .post("doctor/billing/records/prescription/list-bydate", formdata)
      .then((response) => {
        const data = response.data;
        setPrescription({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const calculateTotalPruchased = (amount, qty) => {
    var subtotal = amount === null ? 0 : parseFloat(amount) * parseInt(qty);
    totalPurchased += subtotal;
    return Notify.convertToNumber(subtotal);
  };

  const getLocalPrescriptionByDate = () => {
    var formdata = new FormData();
    formdata.set("management_id", users.management_id);
    formdata.set("user_id", users.user_id);
    formdata.set("date_from", new Date(dateFrom).toLocaleString());
    formdata.set("date_to", new Date(dateTo).toLocaleString());
    formdata.set("connection", "offline");
    formdata.set("prescription_type", "local");

    var error = [];

    if (
      parseInt(new Date(dateTo).getTime()) <
      parseInt(new Date(dateFrom).getTime())
    ) {
      error = "error";
      Notify.customToast(
        "Invalid Date To",
        "Date To must be greather than Date From"
      );
    }
    if (error.length > 0) {
      console.log("Error is sample only..");
    } else {
      setIsSubmitting(true);
      axios
        .post("doctor/billing/records/presc-by-date", formdata)
        .then((response) => {
          const data = response.data;
          setPrescription({ data: data, ready: true });
          setIsSubmitting(false);
        })
        .catch((error) => {
          setIsSubmitting(false);
          Notify.requestError(error);
        });
    }
  };

  useEffect(() => {
    getPrescriptionRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box p={2}>
        <Box mb={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Box my={2}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className="gtc-uppercase"
                >
                  Local Prescription Record
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box display="flex">
                  <Box flexGrow={1} />
                  <Box ml={2}>
                    <DatePicker
                      fullWidth
                      margin="dense"
                      label="From"
                      format="MM/dd/yyyy"
                      inputVariant="outlined"
                      value={dateFrom}
                      onChange={(date) => setDateFrom(date)}
                    />
                  </Box>

                  <Box ml={2}>
                    <DatePicker
                      fullWidth
                      margin="dense"
                      label="To"
                      format="MM/dd/yyyy"
                      inputVariant="outlined"
                      value={dateTo}
                      onChange={(date) => setDateTo(date)}
                    />
                  </Box>

                  <Box mt={1.1} ml={1}>
                    <Button
                      onClick={() => {
                        getLocalPrescriptionByDate();
                      }}
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <SearchIcon />
                        )
                      }
                    >
                      Search
                    </Button>
                  </Box>
                </Box>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {" "}
                  <b> Date </b>{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <b> Patient </b>{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <b> Amount </b>{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <b> Order Qty </b>{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <b> Purchased Qty </b>{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <b> Rem. Qty </b>{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <b> Total Purchased </b>{" "}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {prescription.ready ? (
                prescription.data.length > 0 ? (
                  prescription.data.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="right">
                          {" "}
                          {Notify.dateTimeConvert(data.created_at)}{" "}
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {`${data.firstname} ${data.firstname}`}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          {data.product_amount}{" "}
                        </TableCell>
                        <TableCell align="right"> {data.quantity} </TableCell>
                        <TableCell align="right">
                          {" "}
                          {data.quantity_claimed}{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          {parseInt(data.quantity) -
                            parseInt(data.quantity_claimed)}{" "}
                        </TableCell>
                        <TableCell align="right">
                          {calculateTotalPruchased(
                            data.product_amount,
                            data.quantity_claimed
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography color="secondary">
                        {" "}
                        No record found.{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography color="primary"> Please wait... </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6} align="right">
                  <b> Total Purchased </b>
                </TableCell>
                <TableCell align="right">
                  <b> &#8369; {Notify.convertToNumber(totalPurchased)} </b>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default LocalPrescriptionReport;
