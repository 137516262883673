import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  // IconButton,
  Paper,
  TextField,
  Typography,
  Zoom,
  Dialog,
  Avatar,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import { UsersData, UsersHeader } from "src/ContextAPI";
import { isMobile } from "react-device-detect";
import {
  // CameraAlt
  Send,
} from "@material-ui/icons";
import Cropper from "react-cropper";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";

const NewPatient = ({ getDoctorsPatient }) => {
  const { users } = React.useContext(UsersData);
  const { sidebarHeader } = React.useContext(UsersHeader);
  const cropperRef = useRef();
  // var profileimage = useRef();

  const [imgUploadTemp, setImgUploadTemp] = useState(null);
  const [image, setImage] = useState(null);
  const [openCropImage, setOpenCropImage] = useState(false);

  const [accreditedCompany, setAccreditedCompany] = useState([]);
  const mounted = IsMountedRef();

  const sickness = [
    "Dry cough",
    "Fever",
    "Shortness of breath",
    "Difficulty of breathing",
    "Sore throat",
    "Headache",
    "Nasal congestions",
    "Diarrhea",
    "Colds",
    "Fatigue or body malaise",
  ];

  const handleChangeSickness = (e, setFieldValue, values) => {
    let test = values.sickness;
    if (test.includes(e.target.value)) {
      let testx = test.filter((item) => item !== e.target.value);
      setFieldValue("sickness", testx);
    } else {
      setFieldValue("sickness", test.concat(e.target.value));
    }
  };

  const getFormData = (object) => {
    const formData = new FormData();
    // formData.append("image", image);
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  // const handleUploadImage = (event) => {
  //   var file = event.target.files[0];
  //   if (file !== undefined) {
  //     var reader = new FileReader();
  //     reader.onloadend = function () {
  //       var b64 = reader.result;
  //       setImgUploadTemp(b64);
  //     };
  //     reader.readAsDataURL(file);
  //     setOpenCropImage(true);
  //   }
  // };

  const getAccreditedCompanyList = React.useCallback(async () => {
    let response = await axios.get("admission/accredited/company/get-list", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    if (mounted.current) {
      setAccreditedCompany(response.data);
    }
  }, [mounted, users]);

  const handleChangeCompany = (e, setFieldValue) => {
    setFieldValue("company", e.target.value);
    setFieldValue("transaction_type", "");
  };

  useEffect(() => {
    getAccreditedCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccreditedCompanyList]);

  return (
    <Paper component={Box} p={2}>
      <Formik
        initialValues={{
          username: users.username,
          user_id: users.user_id,
          management_id: users.management_id,
          main_mgmt_id: users.main_mgmt_id,
          // password: "",
          lastname: "",
          firstname: "",
          middlename: "",
          gender: "",
          birthday: "",
          birthplace: "",
          street: "",
          barangay: "",
          city: "",
          occupation: "",
          civil_status: "",
          religion: "",
          mobile: "",
          telephone: "",
          email: "",
          philhealth: "",
          company: "",
          temp: "",
          sickness: [],

          purpose_ofvisit: "",
          allergies: "",
          last_xray_check: "",
          last_xray_result: "",
          history_oftravel_date: "",
          history_oftravel_days: "",
          history_oftravel_place: "",
          contact_withpuipum: "",
          contact_withpositive: "",
          crt_purpose: "",
          requested_by: "",
          complete_company: "",
          contact_information: "",
          quarantine_ifpositive: "",
          triage_staff: sidebarHeader.name,
          transaction_type: "",
        }}
        validationSchema={Yup.object().shape({
          // password: Yup.string().trim().required("Password is required."),
          lastname: Yup.string().trim().required("Lastname is required."),
          firstname: Yup.string().trim().required("Firstname is required."),
          gender: Yup.string().trim().required("Gender is required."),
          birthday: Yup.date().required("Birth date is required."),
          street: Yup.string().trim().required("Street is required."),
          barangay: Yup.string().trim().required("Barangay is required."),
          city: Yup.string().trim().required("City is required."),
          occupation: Yup.string().trim().required("Occupation is required."),
          civil_status: Yup.string()
            .trim()
            .required("Civil status is required."),
          religion: Yup.string().trim().required("Religion is required."),
          mobile: Yup.string().trim().required("Mobile is required."),
          temp: Yup.string().trim().required("Temperature is required."),

          purpose_ofvisit: Yup.string().trim().required("Purpose is required."),
          transaction_type: Yup.string()
            .trim()
            .required("Transaction type is required."),
        })}
        onSubmit={async (
          values,
          { setErrors, setSubmitting, resetForm, setFieldValue }
        ) => {
          var errored = [];

          // if (image === null) {
          //   Notify.fieldRequired("image required");
          //   errored = "error";
          //   return;
          // }

          if (errored.length > 0) {
            console.log("form error.");
          } else {
            try {
              const request = await axios.post(
                "admission/patients/newpatient-save",
                getFormData(values)
              );

              // if (request.data === "pass-invalid") {
              //   setErrors({ password: "Password is invalid." });
              //   Notify.fieldInvalid("password");
              // }
              if (request.data === "email-exist") {
                setErrors({ email: "Email is not available." });
                Notify.fieldInvalid("email");
              }

              if (request.data === "success") {
                Notify.successRequest("new patient");
                resetForm();
                setFieldValue("sickness", []);
                setImage(null);
                setSubmitting(true);
                getDoctorsPatient();
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box mb={2}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <Typography color="primary" variant="subtitle2">
                    NEW PATIENT
                  </Typography>
                </Box>
                {/* <Box>
                  {isMobile ? (
                    <IconButton
                      color="primary"
                      onClick={() => profileimage.click()}
                    >
                      <CameraAlt />
                    </IconButton>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CameraAlt />}
                      onClick={() => profileimage.click()}
                    >
                      Take Photo
                    </Button>
                  )}
                </Box>
                <input
                  type="file"
                  id="image"
                  onChange={handleUploadImage}
                  className="d-none"
                  ref={(ref) => (profileimage = ref)}
                /> */}
              </Box>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid
                  component={Box}
                  hidden={image === null}
                  item
                  xs={12}
                  sm={4}
                >
                  <Box
                    width={"100%"}
                    height={
                      isMobile
                        ? 200
                        : window.innerWidth <= 959
                        ? 200
                        : window.innerWidth <= 898
                        ? 200
                        : 194
                    }
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {image !== null && (
                      <Avatar
                        style={{
                          width: isMobile
                            ? 180
                            : window.innerWidth <= 959
                            ? 180
                            : window.innerWidth <= 898
                            ? 180
                            : 160,
                          height: isMobile
                            ? 180
                            : window.innerWidth <= 959
                            ? 180
                            : window.innerWidth <= 898
                            ? 180
                            : 160,
                        }}
                        alt="profile-pic"
                        src={image}
                      />
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={image === null ? 12 : 8}>
                  <Box my={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.lastname && errors.lastname)}
                            helperText={touched.lastname && errors.lastname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastname}
                            fullWidth
                            required
                            name="lastname"
                            label="Lastname"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.firstname && errors.firstname
                            )}
                            helperText={touched.firstname && errors.firstname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstname}
                            fullWidth
                            required
                            name="firstname"
                            label="Firstname"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.middlename && errors.middlename
                            )}
                            helperText={touched.middlename && errors.middlename}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.middlename}
                            fullWidth
                            name="middlename"
                            label="Middle Name"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box my={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.gender && errors.gender)}
                            helperText={touched.gender && errors.gender}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.gender}
                            fullWidth
                            required
                            name="gender"
                            label="Gender"
                            variant="outlined"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <option value={""}>Select</option>
                            <option value={"Male"}>Male</option>
                            <option value={"Female"}>Female</option>
                          </TextField>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.birthday && errors.birthday)}
                            helperText={touched.birthday && errors.birthday}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.birthday}
                            fullWidth
                            required
                            name="birthday"
                            label="Birthday"
                            variant="outlined"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.birthplace && errors.birthplace
                            )}
                            helperText={touched.birthplace && errors.birthplace}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.birthplace}
                            fullWidth
                            required
                            name="birthplace"
                            label="Birth Place"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box my={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.street && errors.street)}
                            helperText={touched.street && errors.street}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.street}
                            fullWidth
                            required
                            name="street"
                            label="Street"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.barangay && errors.barangay)}
                            helperText={touched.barangay && errors.barangay}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.barangay}
                            fullWidth
                            required
                            name="barangay"
                            label="Barangay"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.city && errors.city)}
                            helperText={touched.city && errors.city}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            fullWidth
                            required
                            name="city"
                            label="City"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.occupation && errors.occupation)}
                      helperText={touched.occupation && errors.occupation}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.occupation}
                      fullWidth
                      required
                      name="occupation"
                      label="Occupation"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.civil_status && errors.civil_status
                      )}
                      helperText={touched.civil_status && errors.civil_status}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.civil_status}
                      fullWidth
                      required
                      name="civil_status"
                      label="Civil Status"
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <option value={""}>Select</option>
                      <option value={"Single"}>Single</option>
                      <option value={"Married"}>Married</option>
                      <option value={"Divorced"}>Divorced</option>
                    </TextField>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.religion && errors.religion)}
                      helperText={touched.religion && errors.religion}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.religion}
                      fullWidth
                      required
                      name="religion"
                      label="Religion"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.mobile && errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile}
                      fullWidth
                      required
                      name="mobile"
                      label="Mobile"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.telephone && errors.telephone)}
                      helperText={touched.telephone && errors.telephone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.telephone}
                      fullWidth
                      name="telephone"
                      label="Telephone"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      fullWidth
                      required
                      name="email"
                      label="Email"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.philhealth && errors.philhealth)}
                      helperText={touched.philhealth && errors.philhealth}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.philhealth}
                      fullWidth
                      name="philhealth"
                      label="Philhealth"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(touched.company && errors.company)}
                      helperText={touched.company && errors.company}
                      onBlur={handleBlur}
                      onChange={(e) => handleChangeCompany(e, setFieldValue)}
                      value={values.company}
                      fullWidth
                      required
                      name="company"
                      label="Company"
                      variant="outlined"
                    >
                      <option value=""> Select </option>
                      {accreditedCompany.length > 0 ? (
                        accreditedCompany.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.label}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>
                          No company found.
                        </option>
                      )}
                    </TextField>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.temp && errors.temp)}
                      helperText={touched.temp && errors.temp}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.temp}
                      fullWidth
                      required
                      name="temp"
                      label="Temperature"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.01,
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box my={3}>
                <Box mb={2}>
                  <Typography variant="h6" color="textSecondary">
                    Contact Tracing Form
                  </Typography>
                </Box>

                <Grid container spacing={1}>
                  {sickness.map((data, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4}>
                      <Box>
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="gilad"
                                  value={data}
                                  onChange={(e) =>
                                    handleChangeSickness(
                                      e,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                />
                              }
                              label={data}
                            />
                          </FormGroup>
                        </FormControl>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            <Box mb={2}>
              <TextField
                label={"Purpose of Visit/Medical Concerns/issues"}
                variant="outlined"
                fullWidth
                error={Boolean(
                  touched.purpose_ofvisit && errors.purpose_ofvisit
                )}
                helperText={touched.purpose_ofvisit && errors.purpose_ofvisit}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.purpose_ofvisit}
                name="purpose_ofvisit"
              />
            </Box>

            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <Box>
                    <TextField
                      label={"Any Allergies"}
                      fullWidth
                      variant="outlined"
                      error={Boolean(touched.allergies && errors.allergies)}
                      helperText={touched.allergies && errors.allergies}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.allergies}
                      name="allergies"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Box>
                    <TextField
                      label={"Last Check Xray Taken"}
                      fullWidth
                      variant="outlined"
                      error={Boolean(
                        touched.last_xray_check && errors.last_xray_check
                      )}
                      helperText={
                        touched.last_xray_check && errors.last_xray_check
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_xray_check}
                      name="last_xray_check"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Box>
                    <TextField
                      label={"Result of Cxray"}
                      fullWidth
                      variant="outlined"
                      error={Boolean(
                        touched.last_xray_result && errors.last_xray_result
                      )}
                      helperText={
                        touched.last_xray_result && errors.last_xray_result
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_xray_result}
                      name="last_xray_result"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box mb={2}>
              <Box mb={1}>
                <Typography>History of Travel: When and where</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.history_oftravel_date &&
                          errors.history_oftravel_date
                      )}
                      helperText={
                        touched.history_oftravel_date &&
                        errors.history_oftravel_date
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.history_oftravel_date}
                      fullWidth
                      name="history_oftravel_date"
                      label="Date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.history_oftravel_days &&
                          errors.history_oftravel_days
                      )}
                      helperText={
                        touched.history_oftravel_days &&
                        errors.history_oftravel_days
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.history_oftravel_days}
                      fullWidth
                      name="history_oftravel_days"
                      label="Days"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 1,
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Box>
                    <TextField
                      label={"Place"}
                      fullWidth
                      variant="outlined"
                      multiline
                      error={Boolean(
                        touched.history_oftravel_place &&
                          errors.history_oftravel_place
                      )}
                      helperText={
                        touched.history_oftravel_place &&
                        errors.history_oftravel_place
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.history_oftravel_place}
                      name="history_oftravel_place"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <TextField
                      label={"Any contact with PUI to PUM"}
                      fullWidth
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(
                        touched.contact_withpuipum && errors.contact_withpuipum
                      )}
                      helperText={
                        touched.contact_withpuipum && errors.contact_withpuipum
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.contact_withpuipum}
                      name="contact_withpuipum"
                    >
                      <option value=""> SELECT </option>
                      <option value="No"> No </option>
                      <option value="Yes"> Yes </option>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <TextField
                      label={"Any contact with positive covid patient"}
                      fullWidth
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(
                        touched.contact_withpositive &&
                          errors.contact_withpositive
                      )}
                      helperText={
                        touched.contact_withpositive &&
                        errors.contact_withpositive
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.contact_withpositive}
                      name="contact_withpositive"
                    >
                      <option value=""> SELECT </option>
                      <option value="No"> No </option>
                      <option value="Yes"> Yes </option>
                    </TextField>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Typography>FOR CRT Clients: </Typography>
            </Box>

            <Box my={2}>
              <TextField
                label={"Purpose of your request for CRT."}
                fullWidth
                variant="outlined"
                multiline
                error={Boolean(touched.crt_purpose && errors.crt_purpose)}
                helperText={touched.crt_purpose && errors.crt_purpose}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.crt_purpose}
                name="crt_purpose"
              />
            </Box>

            <Box my={2}>
              <TextField
                label={"Requested by"}
                fullWidth
                variant="outlined"
                multiline
                error={Boolean(touched.requested_by && errors.requested_by)}
                helperText={touched.requested_by && errors.requested_by}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.requested_by}
                name="requested_by"
              />
            </Box>

            <Box my={2}>
              <TextField
                label={"If for return to work, complete company name"}
                fullWidth
                variant="outlined"
                multiline
                error={Boolean(
                  touched.complete_company && errors.complete_company
                )}
                helperText={touched.complete_company && errors.complete_company}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.complete_company}
                name="complete_company"
              />
            </Box>

            <Box my={2}>
              <TextField
                label={"Complete contact person, designation & numbers"}
                fullWidth
                variant="outlined"
                multiline
                error={Boolean(
                  touched.contact_information && errors.contact_information
                )}
                helperText={
                  touched.contact_information && errors.contact_information
                }
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contact_information}
                name="contact_information"
              />
            </Box>

            <Box>
              <Typography>
                If found to be positive of either IgG or IgM, are you willing to
                undergo quarantine or Swab testing testing (RT-PCR)?
              </Typography>
            </Box>

            <Box my={2}>
              <TextField
                label={"Select"}
                fullWidth
                variant="outlined"
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                error={Boolean(
                  touched.quarantine_ifpositive && errors.quarantine_ifpositive
                )}
                helperText={
                  touched.quarantine_ifpositive && errors.quarantine_ifpositive
                }
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.quarantine_ifpositive}
                name="quarantine_ifpositive"
              >
                <option value=""> SELECT </option>
                <option value="No"> No </option>
                <option value="Yes"> Yes </option>
              </TextField>
            </Box>

            <Box my={3}>
              <Typography color="textSecondary">
                I hereby authorize BMCDC/BMCDC Health Care Inc./BMCDC Medical
                Station Co. Inc. to collect and process the data indicated
                herein for the purpose of effecting conroil of the COVID 19
                infection. I understand that my personal information is
                protected by RA 10173, Data PrivacyAct of 2012 and that I am
                required by RA 11469 Bayanihan to Heal as One Act. to provide
                truthful information.
              </Typography>
            </Box>

            {/* <Box my={2}>
              <TextField
                label={"Triage staff"}
                fullWidth
                variant="outlined"
                multiline
                error={Boolean(touched.triage_staff && errors.triage_staff)}
                helperText={touched.triage_staff && errors.triage_staff}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.triage_staff}
                name="triage_staff"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box> */}

            <Box my={2}>
              <TextField
                label={"Transaction Type"}
                fullWidth
                variant="outlined"
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                error={Boolean(
                  touched.transaction_type && errors.transaction_type
                )}
                helperText={touched.transaction_type && errors.transaction_type}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.transaction_type}
                name="transaction_type"
              >
                <option value=""> SELECT </option>
                <option
                  value="reg-endorse"
                  disabled={values.company === "" ? true : false}
                >
                  Corporate
                </option>
                <option value="reg-cashier">Walk-In(lab/x-ray)</option>
                <option value="reg-cashier-hmo">Walk-In HMO</option>
                <option value="reg-doctor"> Walk-In Appointment </option>
              </TextField>
            </Box>

            {/* <Box my={2}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                fullWidth
                required
                name="password"
                type="password"
                label="Password"
                variant="outlined"
              />
            </Box> */}

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box mb={2} display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                onClick={resetForm}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>
              <Box ml={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <CheckIcon />
                    )
                  }
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
      <Dialog
        open={openCropImage}
        fullScreen
        onClose={() => setOpenCropImage(false)}
        TransitionComponent={Zoom}
        transitionDuration={900}
      >
        <Box
          display="flex"
          width="100%"
          height="80vh"
          justifyContent="center"
          alignItems="center"
        >
          <Cropper
            style={{ height: "100%", width: "100%" }}
            zoomTo={0.5}
            aspectRatio={1}
            src={imgUploadTemp}
            viewMode={1}
            guides={false}
            background={false}
            responsive={true}
            dragMode={"move"}
            cropBoxResizable={true}
            checkOrientation={false}
            rotatable
            scalable
            ref={cropperRef}
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          height="20vh"
          justifyContent="center"
          alignItems="center"
          border={1}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<Send />}
            onClick={() => {
              const imageElement = cropperRef.current;
              const cropper = imageElement.cropper;
              setImage(cropper.getCroppedCanvas().toDataURL());
              setImgUploadTemp(null);
              setOpenCropImage(false);
            }}
          >
            Confirm
          </Button>
        </Box>
      </Dialog>
    </Paper>
  );
};

export default NewPatient;
