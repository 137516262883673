import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  //   IconButton,
  //   Divider,
  //   Zoom,
  //   Dialog,
  //   DialogContent,
  //   DialogTitle,
  //   Button,
  //   FormHelperText,
  //   Grid,
} from "@material-ui/core";
// import Notify from "src/notification/Notify";
import { Search } from "@material-ui/icons";
import axios from "axios";
import { UsersData } from "src/ContextAPI";

const LaboratoryInventoryList = () => {
  const [search, setSearch] = useState("");
  //   const [total, setTotal] = useState(0);
  const { users } = useContext(UsersData);
  const [inventory, setInventory] = useState({ data: [], ready: false });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getInventory = useCallback(async () => {
    let response = await axios.get(
      "accounting/test/items/inventory/list-inventory",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
        },
      }
    );
    setInventory({ data: response.data, ready: true });
  }, [users]);

  const searchable = inventory.data.filter((data) => {
    return data.item.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
  });

  useEffect(() => {
    getInventory();
  }, [getInventory]);

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1} />
        <Box mb={1}>
          <TextField
            label="Search item"
            variant="outlined"
            margin="dense"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Paper>
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"> ITEM </TableCell>
                <TableCell align="center"> DESC </TableCell>
                <TableCell align="center"> SUPPLIER </TableCell>
                <TableCell align="center"> MSRP </TableCell>
                <TableCell align="center"> IN QTY </TableCell>
                <TableCell align="center"> OUT QTY </TableCell>
                <TableCell align="center"> REM. QTY </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventory.ready ? (
                searchable.length > 0 ? (
                  searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow key={index}>
                        <TableCell> {data.item} </TableCell>
                        <TableCell style={{ maxWidth: 150 }}>
                          {data.description}
                        </TableCell>
                        <TableCell> {data.supplier} </TableCell>
                        <TableCell align="right"> {data.msrp} </TableCell>
                        <TableCell align="right">
                          {data._total_qty_in}
                        </TableCell>
                        <TableCell align="right">
                          {data._total_qty_out}
                        </TableCell>
                        <TableCell align="right">
                          {data._total_qty_in - data._total_qty_out}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography color="primary">
                      Loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[10, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

export default LaboratoryInventoryList;
