import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  Suspense,
} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import {
  faAddressCard,
  faHome,
  faStore,
  faUserLock,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingScreen from "src/utils/LoadingScreen";
import IsMountedRef from "src/utils/IsMountedRef";
import { Badge } from "@material-ui/core";
import LeaveForm from "src/endorsement/leave/LeaveForm";

const EndorsementDashboard = React.lazy(() => import("src/endorsement"));
const EndorsementPatient = React.lazy(() => import("src/endorsement/patient"));
const Account = React.lazy(() => import("src/endorsement/account/Account"));
const Company = React.lazy(() => import("src/endorsement/company"));

const RouteEndorsement = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const [patient, setPatients] = useState([]);
  const userContext = useContext(UsersData);
  const mounted = IsMountedRef();
  var interval = null;

  const getSidebarHeaderInfo = React.useCallback(async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get(
        "endorsement/sidebar/header-infomartion",
        {
          params,
        }
      );
      if (mounted.current) {
        setSidebarHeader(response.data);
      }
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  }, [userContext, mounted]);

  const getPatientsOnQueue = React.useCallback(async () => {
    let response = await axios.get("endorsement/patient/queue-list", {
      params: {
        management_id: userContext.users.management_id,
        type: "endorsement",
      },
    });

    if (mounted.current) {
      setPatients(response.data);
    }
  }, [mounted, userContext]);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faHome} color="white" style={{ fontSize: 20 }} />
      ),
      path: "/bmcdc/app/endorsement",
      subitem: [],
    },
    {
      name: "Patients",
      show: true,
      icon: (
        <Badge color="error" badgeContent={patient.length}>
          <FontAwesomeIcon
            icon={faUsers}
            color="white"
            style={{ fontSize: 20 }}
          />
        </Badge>
      ),
      path: "/bmcdc/app/endorsement/patient",
      subitem: [],
    },
    {
      name: "Company",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faStore}
          color="white"
          style={{ fontSize: 20 }}
        />
      ),
      path: "/bmcdc/app/endorsement/company",
      subitem: [],
    },
    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/bmcdc/app/endorsement/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faUserLock}
          color="white"
          style={{ fontSize: 20 }}
        />
      ),
      path: "/bmcdc/app/endorsement/account",
      subitem: [],
    },
  ];

  const initializeInterval = () => {
    interval = setInterval(() => {
      getPatientsOnQueue();
    }, 180000); // 3mins interval
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getPatientsOnQueue();
  }, [getSidebarHeaderInfo, getPatientsOnQueue]);

  useEffect(() => {
    initializeInterval();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Suspense fallback={<LoadingScreen />}>
        <UsersHeader.Provider
          value={{
            sidebarHeader: sidebarHeader,
            handleRenderInfo: () => getSidebarHeaderInfo(),
            queue: {
              patient: patient,
              getPatientsOnQueue: () => getPatientsOnQueue(),
            },
          }}
        >
          <BrowserRouter>
            <Sidebar
              notification={{
                enable: true,
                owner: "endorsement",
              }}
              header={sidebarHeader}
              routes={sidebarRoute}
              module={"endorsement"}
            />

            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/bmcdc/app/endorsement" />}
              />

              <Route
                exact
                path="/bmcdc"
                component={() => <Redirect to="/bmcdc/app/endorsement" />}
              />

              <Route
                exact
                path="/bmcdc/app"
                component={() => <Redirect to="/bmcdc/app/endorsement" />}
              />

              <Route
                exact
                path="/bmcdc/app/endorsement"
                component={EndorsementDashboard}
              />

              <Route
                exact
                path="/bmcdc/app/endorsement/patient"
                component={EndorsementPatient}
              />

              <Route
                exact
                path="/bmcdc/app/endorsement/company"
                component={Company}
              />

              <Route
                exact
                path="/bmcdc/app/endorsement/leave-application"
                component={LeaveForm}
              />

              <Route
                exact
                path="/bmcdc/app/endorsement/account"
                component={Account}
              />

              <Route exact path="/bmcdc/app/logout" component={Logout} />
              <Route render={() => <PageNotFound title="Page not found" />} />
            </Switch>
          </BrowserRouter>
        </UsersHeader.Provider>
      </Suspense>
    </Fragment>
  );
};

export default RouteEndorsement;
