import { Box, Paper } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import ClinicalChemistryEditForm from "./editforms/ClinicalChemistryEditForm";
import ClinicalMicroscopyEditForm from "./editforms/ClinicalMicroscopyEditForm";
import Covid19EditForm from "./editforms/Covid19EditForm";
import DrugTestEditForm from "./editforms/DrugTestEditForm";
import HemaCBCEditForm from "./editforms/HemaCBCEditForm";
import HemaEditForm from "./editforms/HemaEditForm";
import HepatitisEditForm from "./editforms/HepatitisEditForm";
import MiscellaneousEditForm from "./editforms/MiscellaneousEditForm";
import SeroEditForm from "./editforms/SeroEditForm";
import StoolTestEditForm from "./editforms/StoolTestEditForm";
import ThyroidEditForm from "./editforms/ThyroidEditForm";
import TumorMakerEditForm from "./editforms/TumorMakerEditForm";
import UrinalysisEditForm from "./editforms/UrinalysisEditForm";

const ResultToEditIndex = ({ selectedSpecTest, close }) => {
  const { users } = useContext(UsersData);
  const [info, setInfo] = useState({ data: [], ready: false });

  const getCBCResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_cbc",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getHemaResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_hematology",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getSeroResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_sorology",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getClinicalMicroResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_microscopy",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getClinicalChemistryResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_chemistry",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getStoolTestResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_stooltest",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getUrinalysisResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_urinalysis",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getThyroidResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_thyroid_profile",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getMiscellaneousResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_miscellaneous",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getHepatitisProfResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_hepatitis_profile",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getCovid19Result = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_covid19_test",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getTumorMakerResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_tumor_maker",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  const getDrugTestResult = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      database: "laboratory_drug_test",
      order_id: selectedSpecTest.order_id,
      trace_number: selectedSpecTest.trace_number,
    };
    let response = await axios.get("laboratory/get/to-edit-result", {
      params,
    });
    const data = response.data;
    setInfo({ data, ready: true });
    // console.log("data", data);
  };

  useEffect(() => {
    if (selectedSpecTest.bill_department === "hemathology") {
      if (
        selectedSpecTest.bill_name === "cbc platelet" ||
        selectedSpecTest.bill_name === "cbc"
      ) {
        getCBCResult();
      } else {
        getHemaResult();
      }
    }
    if (selectedSpecTest.bill_department === "serology") {
      getSeroResult();
    }
    if (selectedSpecTest.bill_department === "clinical-microscopy") {
      getClinicalMicroResult();
    }
    if (selectedSpecTest.bill_department === "clinical-chemistry") {
      getClinicalChemistryResult();
    }
    if (selectedSpecTest.bill_department === "stool-test") {
      getStoolTestResult();
    }
    if (selectedSpecTest.bill_department === "urinalysis") {
      getUrinalysisResult();
    }
    if (selectedSpecTest.bill_department === "thyroid-profile") {
      getThyroidResult();
    }
    if (selectedSpecTest.bill_department === "miscellaneous") {
      getMiscellaneousResult();
    }
    if (selectedSpecTest.bill_department === "hepatitis-profile") {
      getHepatitisProfResult();
    }
    if (selectedSpecTest.bill_department === "covid-19") {
      getCovid19Result();
    }
    if (selectedSpecTest.bill_department === "Tumor Maker") {
      getTumorMakerResult();
    }
    if (selectedSpecTest.bill_department === "Drug Test") {
      getDrugTestResult();
    }
    // eslint-disable-next-line
  }, [selectedSpecTest]);

  return (
    <>
      <Box component={Paper} variant="outlined" p={2}>
        {selectedSpecTest.bill_department === "hemathology" ? (
          selectedSpecTest.bill_name === "cbc platelet" ||
          selectedSpecTest.bill_name === "cbc" ? (
            <HemaCBCEditForm info={info} close={() => close()} /> //OKAY
          ) : (
            <HemaEditForm info={info} close={() => close()} /> //OKAY
          )
        ) : selectedSpecTest.bill_department === "serology" ? (
          <SeroEditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "clinical-microscopy" ? (
          <ClinicalMicroscopyEditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "clinical-chemistry" ? (
          <ClinicalChemistryEditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "stool-test" ? (
          <StoolTestEditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "urinalysis" ? (
          <UrinalysisEditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "thyroid-profile" ? (
          <ThyroidEditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "miscellaneous" ? (
          <MiscellaneousEditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "hepatitis-profile" ? (
          <HepatitisEditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "covid-19" ? (
          <Covid19EditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "Tumor Maker" ? (
          <TumorMakerEditForm info={info} close={() => close()} /> //OKAY
        ) : selectedSpecTest.bill_department === "Drug Test" ? (
          <DrugTestEditForm info={info} close={() => close()} />
        ) : null}
      </Box>
    </>
  );
};

export default ResultToEditIndex;
