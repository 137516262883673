import React, { Fragment, useEffect, useState } from 'react';   
import BloodPressure from './vitalsgraph/BloodPressure'; 
import Temperature from './vitalsgraph/Temperature'; 
import Glucose from './vitalsgraph/Glucose'; 
import Uricacid from './vitalsgraph/Uricacid'; 
import Pulse from './vitalsgraph/Pulse'; 
import Cholesterol from './vitalsgraph/Cholesterol'; 
import Respiratory from './vitalsgraph/Respiratory'; 
import Weight from './vitalsgraph/Weight';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab'; 
import TabPanel from '../../utils/TabPanel';
import { Grid, makeStyles, Paper } from '@material-ui/core';  
import BodyPain from './vitalsgraph/BodyPain'; 

const useStyle = makeStyles({
    indicatorLeft: {
      left: "0px"
    }
}); 

const Vitals = ({ patient_id, connection }) =>{

    const classes = useStyle()
    const [category, setCategory] = useState(0)

    const handleCategory = (event, newCategory) => {
        event.persist()
        setCategory(newCategory) 
    } 

    useEffect(() =>{ },[patient_id])

    return(
        <Fragment>    
            <Grid container spacing={2}>
                <Box clone order={{ xs: 2, sm: 2, md: 1 }}> 
                    <Grid item xs={12} sm={12} md={10}>
                        <Box
                            component={Paper}
                            elevation={2}
                            p={2}
                        >
                            <Box>
                                <TabPanel value={ category } index={0}>
                                    <BodyPain patient_id = { patient_id } connection ={ connection } />
                                </TabPanel>

                                <TabPanel value={ category } index={1}>
                                    <Temperature patient_id = { patient_id } connection ={ connection } />
                                </TabPanel>

                                <TabPanel value={ category } index={2}> 
                                    <BloodPressure patient_id = { patient_id } connection ={ connection } />
                                </TabPanel>

                                <TabPanel value={ category } index={3}>
                                    <Pulse patient_id = { patient_id } connection ={ connection } />
                                </TabPanel>

                                <TabPanel value={ category } index={4}>
                                    <Respiratory patient_id = { patient_id } connection ={ connection } /> 
                                </TabPanel>

                                <TabPanel value={ category } index={5}>
                                    <Weight patient_id = { patient_id } connection ={ connection } /> 
                                </TabPanel>

                                <TabPanel value={ category } index={6}>
                                    <Glucose patient_id = { patient_id } connection ={ connection } /> 
                                </TabPanel>

                                <TabPanel value={ category } index={7}>
                                    <Uricacid patient_id = { patient_id } connection ={ connection } /> 
                                </TabPanel>

                                <TabPanel value={ category } index={8}>
                                    <Cholesterol patient_id = { patient_id } connection ={ connection } /> 
                                </TabPanel>
                            </Box>   
                        </Box>  
                    </Grid>
                </Box>
                <Box clone order={{ xs: 1, sm: 1, md: 2 }}>  
                    <Grid item xs={12} sm={12} md={2}> 
                        <Paper variant={'outlined'} component={Box} p={1}>
                            <Tabs  
                                value={  category  }
                                onChange={ handleCategory }
                                indicatorColor="primary"
                                textColor="primary" 
                                scrollButtons="on"  
                                variant="scrollable"
                                orientation={window.innerWidth > 959 ? "vertical":"horizontal"}
                                classes={{
                                    indicator: classes.indicatorLeft
                                }}
                            >
                                <Tab wrapped label="Pain" />  
                                <Tab wrapped label="Temp" />  
                                <Tab wrapped label="Blood Pressure" />
                                <Tab wrapped label="Pulse" />
                                <Tab wrapped label="Respiratory" />
                                <Tab wrapped label="Weight" /> 
                                <Tab wrapped label="Glucose" /> 
                                <Tab wrapped label="Uric Acid" /> 
                                <Tab wrapped label="Cholesterol" /> 
                            </Tabs>   
                        </Paper>
                    </Grid>
                </Box> 
            </Grid> 
        </Fragment>
    )
}

export default Vitals; 