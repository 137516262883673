import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Button,
  TableBody,
  Menu,
  Zoom,
  Dialog,
} from "@material-ui/core";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import { Search } from "react-feather";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import { FilterList, Print } from "@material-ui/icons";
import PrintReport from "./PrintReport";

const ReportCashier = () => {
  const [report, setReport] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { users } = useContext(UsersData);
  const [total, setTotal] = useState(0);
  const [selectedData, setSelectedData] = useState({
    data: null,
    category: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [printDialog, setPrintDialog] = useState(false);

  const getAllReport = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("cashier/get/all-report", { params });
      const data = response.data;
      setReport({ data, ready: true });
      setTotal(calculateAmount(data));
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users]);

  const handleReportByReport = async (e) => {
    e.preventDefault();
    e.persist();

    const formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("management_id", users.management_id);
    const dateFrom = formdata.get("date_from");
    const dateTo = formdata.get("date_to");

    var error = [];
    if (
      formdata.get("category").length === 0 ||
      formdata.get("category").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("category");
    }
    if (
      formdata.get("cash_charge").length === 0 ||
      formdata.get("cash_charge").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("cash or charge");
    }
    if (
      formdata.get("date_from").length === 0 ||
      formdata.get("date_from").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("date from");
    }
    if (
      formdata.get("date_to").length === 0 ||
      formdata.get("date_to").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("date to");
    }
    if (
      parseInt(new Date(dateFrom).getTime()) >
      parseInt(new Date(dateTo).getTime())
    ) {
      error = "error";
      Notify.fieldInvalid("date is invalid");
    }
    if (error.length > 0) {
      console.warn("Form has an error.");
    } else {
      setIsSubmitting(true);
      axios
        .post("cashier/get/filter-by-date/report", formdata)
        .then((res) => {
          const data = res.data;
          setReport({ data, ready: true });
          setTotal(calculateAmount(data));
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const calculateAmount = (data) => {
    var total = 0;
    for (let x = 0; x < data.length; x++) {
      total += parseFloat(data[x].bill_total) * 1;
    }
    return total;
  };

  useEffect(() => {
    getAllReport();
  }, [getAllReport]);

  const searchable = report.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Report",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title={
          <Box display={"flex"}>
            <Box flexGrow={1}>
              <Typography variant="h4">Report</Typography>
            </Box>
            <Box
              component={Paper}
              display="flex"
              pl={2}
              pr={2}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant={"h6"}
                style={{ fontWeight: "bolder" }}
                color={"primary"}
              >
                TOTAL : ₱{Notify.convertToNumber(total)}
              </Typography>
            </Box>
          </Box>
        }
      >
        <Box component={Paper} p={2}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Box maxWidth={230}>
                <TextField
                  fullWidth
                  label="Search Patient"
                  variant="outlined"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  margin="dense"
                />
              </Box>
            </Box>

            <Box>
              <Box mt={1}>
                <Box display="flex">
                  <Box>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      type="submit"
                      startIcon={<FilterList />}
                      style={{ height: 38 }}
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                      FILTER
                    </Button>
                  </Box>
                  <Box ml={1}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      type="submit"
                      startIcon={<Print />}
                      style={{ height: 38 }}
                      onClick={() => setPrintDialog(true)}
                    >
                      PRINT
                    </Button>
                  </Box>
                </Box>

                <Menu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={(e) => setAnchorEl(null)}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  getContentAnchorEl={null}
                >
                  <form onSubmit={handleReportByReport}>
                    <Box m={2}>
                      <Box mb={1}>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          style={{ fontWeight: "bolder" }}
                        >
                          Search Filter
                        </Typography>
                      </Box>
                      <Box mb={1}>
                        <TextField
                          label="Category"
                          variant="outlined"
                          fullWidth
                          select
                          SelectProps={{ native: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="category"
                          margin="dense"
                          onChange={(e) =>
                            setSelectedData({
                              ...selectedData,
                              category: e.target.value,
                            })
                          }
                        >
                          <option value=""> -- Select -- </option>
                          <option value="all"> All </option>
                          <option value="walkin">Walk-In</option>
                          <option value="corporate">Coporate</option>
                        </TextField>
                      </Box>

                      <Box mb={1}>
                        <TextField
                          label="Cash/Charge"
                          variant="outlined"
                          fullWidth
                          select
                          SelectProps={{ native: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="cash_charge"
                          margin="dense"
                        >
                          <option value=""> -- Select -- </option>
                          <option
                            value="all"
                            hidden={selectedData.category === "walkin"}
                          >
                            All
                          </option>
                          <option value="cash">Cash</option>
                          <option
                            value="charge"
                            hidden={selectedData.category === "walkin"}
                          >
                            Charge
                          </option>
                        </TextField>
                      </Box>

                      <Box mb={1}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="date"
                          variant="outlined"
                          label="Date From"
                          name="date_from"
                          fullWidth
                          margin="dense"
                        />
                      </Box>

                      <Box mb={1}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="date"
                          variant="outlined"
                          label="Date To"
                          name="date_to"
                          fullWidth
                          margin="dense"
                        />
                      </Box>

                      <Box mb={1} display="flex">
                        <Box flexGrow={1} />
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<Search />}
                          disabled={isSubmitting}
                          type="submit"
                        >
                          Search
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </Menu>
              </Box>
            </Box>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b> Date </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Patient </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Category </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Amount </b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {report.ready ? (
                  report.data.length > 0 ? (
                    (limit > 0
                      ? searchable.slice(page * limit, page * limit + limit)
                      : searchable
                    ).map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {Notify.createdAt(data.created_at)}
                        </TableCell>
                        <TableCell>
                          {data.lastname}, {data.firstname}
                        </TableCell>
                        <TableCell>
                          {/* {data.charge_type === 'charge' ? data. : 'Cash'} */}
                          {data.is_charged !== 0
                            ? data.hmo_used !== null
                              ? `Charge(HMO) - ${data.hmo_complete_name}`
                              : `Charge(Corporate) - ${data.company_complete_name}`
                            : "Cash"}
                        </TableCell>
                        <TableCell align="right">
                          {Notify.convertToNumber(data.bill_total)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>{Notify.noRecord()}</TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>{Notify.loading()}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component={Box}
              count={searchable.length}
              labelRowsPerPage="List"
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        </Box>
        <Dialog
          open={printDialog}
          onClose={() => setPrintDialog(false)}
          fullScreen
          TransitionComponent={Zoom}
          transitionDuration={800}
        >
          <PrintReport
            report={report}
            close={() => setPrintDialog(false)}
            total={total}
          />
        </Dialog>
      </Container>
    </>
  );
};

export default ReportCashier;
