import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

class hemathologyRef {
  hemoglobin = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          MALE: 140 - 170 g/L
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          FEMALE: 120 - 150 g/L
        </Typography>
      </Box>
    </Box>
  );

  hematocrit = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          MALE: 0.50 - 0.50 g/L
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          FEMALE: 0.38 - 0.48 g/L
        </Typography>
      </Box>
    </Box>
  );

  rbc = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          MALE: 4.5 - 6.0 x 10<sup> 12 </sup> /L
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          FEMALE: 4.0 - 5.5 x 10<sup> 12 </sup> /L
        </Typography>
      </Box>
    </Box>
  );

  wbc = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          5.0 - 10.0 x 10 <sup> 9 </sup> /L
        </Typography>
      </Box>
    </Box>
  );

  plateletCount = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          150 - 400 x 10 <sup> 9 </sup> /L
        </Typography>
      </Box>
    </Box>
  );

  // differentialCount = () => ( ) no references

  neutrophil = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          0.50 - 0.65
        </Typography>
      </Box>
    </Box>
  );

  lymphocyte = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          0.25 - 0.35
        </Typography>
      </Box>
    </Box>
  );

  monocyte = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          0.03 - 0.06
        </Typography>
      </Box>
    </Box>
  );

  eosinophil = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          0 - 0.07
        </Typography>
      </Box>
    </Box>
  );

  basophil = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          0 - 0.1
        </Typography>
      </Box>
    </Box>
  );

  bands = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          0 - 0.70
        </Typography>
      </Box>
    </Box>
  );

  // abo_blood_type_and_rh_type = () =>( ) no reference values

  bleedingTime = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          2 - 7 minutes
        </Typography>
      </Box>
    </Box>
  );

  clottingTime = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          2 - 7 minutes
        </Typography>
      </Box>
    </Box>
  );

  afp = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          10 ng/ml - 20 ng/ml (adults normal)
        </Typography>
      </Box>
    </Box>
  );

  albumin = () => (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          3.4 g/dl - 5.4 g/dl
        </Typography>
      </Box>
    </Box>
  );

  alkalinePhosphate = () => {
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          {" "}
          not set{" "}
        </Typography>
      </Box>
    </Box>;
  };

  antiHav = () => {
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          {" "}
          not set{" "}
        </Typography>
      </Box>
    </Box>;
  };

  aptt = () => {
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bolder", color: "#000" }}
        >
          {" "}
          not set{" "}
        </Typography>
      </Box>
    </Box>;
  };
}

const HemathologyRef = new hemathologyRef();
export default HemathologyRef;
