import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const ClinicalMicroscopyEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/clinical-micro/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {info.ready && (
              <>
                <Box mb={2}>
                  <Box
                    hidden={
                      Boolean(parseInt(info.data.chemical_test)) ? false : true
                    }
                  >
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> CHEMICAL TEST </b>
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      ml={2}
                      hidden={
                        Boolean(parseInt(info.data.is_processed)) ? false : true
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4}>
                          <Box display="flex">
                            <Box mt={2} mr={1} flexGrow={1} align="right">
                              <Typography variant="caption">
                                <b> COLOR: </b>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                label="Result"
                                name="chemecal_test"
                                margin="dense"
                                variant="outlined"
                                hidden
                                value={"chemical_order"}
                              />
                              <TextField
                                fullWidth
                                label="Result"
                                name="color"
                                margin="dense"
                                variant="outlined"
                                defaultValue={
                                  info.data.chemical_test_color === null
                                    ? ""
                                    : info.data.chemical_test_color
                                }
                              />
                            </Box>
                          </Box>
                          <Box display="flex">
                            <Box mt={2} mr={1} flexGrow={1} align="right">
                              <Typography variant="caption">
                                <b> TRANPARENCY: </b>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                label="Result"
                                name="transparency"
                                margin="dense"
                                variant="outlined"
                                defaultValue={
                                  info.data.chemical_test_transparency === null
                                    ? ""
                                    : info.data.chemical_test_transparency
                                }
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <Box display="flex">
                            <Box mt={2} mr={1} flexGrow={1} align="right">
                              <Typography variant="caption">
                                <b> PH: </b>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                label="Result"
                                name="ph"
                                margin="dense"
                                variant="outlined"
                                defaultValue={
                                  info.data.chemical_test_ph === null
                                    ? ""
                                    : info.data.chemical_test_ph
                                }
                              />
                            </Box>
                          </Box>
                          <Box display="flex">
                            <Box mt={2} mr={1} flexGrow={1} align="right">
                              <Typography variant="caption">
                                <b> SPECIFIC GRAVITY: </b>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                label="Result"
                                name="specific_gravity"
                                margin="dense"
                                variant="outlined"
                                defaultValue={
                                  info.data.chemical_test_spicific_gravity ===
                                  null
                                    ? ""
                                    : info.data.chemical_test_spicific_gravity
                                }
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <Box display="flex">
                            <Box mt={2} mr={1} flexGrow={1} align="right">
                              <Typography variant="caption">
                                <b> GLUCOSE: </b>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                label="Result"
                                name="glucose"
                                margin="dense"
                                variant="outlined"
                                defaultValue={
                                  info.data.chemical_test_glucose === null
                                    ? ""
                                    : info.data.chemical_test_glucose
                                }
                              />
                            </Box>
                          </Box>
                          <Box display="flex">
                            <Box mt={2} mr={1} flexGrow={1} align="right">
                              <Typography variant="caption">
                                <b> ALBUMIN: </b>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                label="Result"
                                name="albumin"
                                margin="dense"
                                variant="outlined"
                                defaultValue={
                                  info.data.chemical_test_albumin === null
                                    ? ""
                                    : info.data.chemical_test_albumin
                                }
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>

                <Box mb={2}>
                  <Box
                    hidden={
                      Boolean(parseInt(info.data.microscopic_test))
                        ? false
                        : true
                    }
                  >
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> MICROSCOPIC TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      ml={2}
                      hidden={
                        Boolean(parseInt(info.data.is_processed)) ? false : true
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                          <>
                            <Box mt={2}>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                CELLS
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Box mr={1} flexGrow={1} align="right" mt={2}>
                                <Typography variant="caption">
                                  <b> SQUAMOUS CELLS: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Enable microscopic test"
                                  name="microscopic_test"
                                  margin="dense"
                                  variant="outlined"
                                  hidden
                                  value={"microscopic_order"}
                                />
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="squamous"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data.microscopic_test_squamous === null
                                      ? ""
                                      : info.data.microscopic_test_squamous
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> PUS CELLS: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="pus"
                                  margin="dense"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        /HPF
                                      </InputAdornment>
                                    ),
                                  }}
                                  defaultValue={
                                    info.data.microscopic_test_pus === null
                                      ? ""
                                      : info.data.microscopic_test_pus
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> RED BLOOD CELLS: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="redblood"
                                  margin="dense"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        /HPF
                                      </InputAdornment>
                                    ),
                                  }}
                                  defaultValue={
                                    info.data.microscopic_test_redblood === null
                                      ? ""
                                      : info.data.microscopic_test_redblood
                                  }
                                />
                              </Box>
                            </Box>
                          </>

                          <>
                            <Box mt={2}>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                CASTS
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Box mr={1} flexGrow={1} align="right" mt={2}>
                                <Typography
                                  variant="caption"
                                  className={`gtc-uppercase`}
                                >
                                  <b> Hyaline Cast: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="hyaline"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data.microscopic_test_hyaline === null
                                      ? ""
                                      : info.data.microscopic_test_hyaline
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography
                                  variant="caption"
                                  className={`gtc-uppercase`}
                                >
                                  <b> WBC Cast: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="wbc_cast"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data.microscopic_test_wbc === null
                                      ? ""
                                      : info.data.microscopic_test_wbc
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography
                                  variant="caption"
                                  className={`gtc-uppercase`}
                                >
                                  <b> RBC Cast: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="rbc_cast"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data.microscopic_test_rbc === null
                                      ? ""
                                      : info.data.microscopic_test_rbc
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography
                                  variant="caption"
                                  className={`gtc-uppercase`}
                                >
                                  <b> Fine Granualar Cast: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="fine_granualar"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data.microscopic_test_fine_granular ===
                                    null
                                      ? ""
                                      : info.data.microscopic_test_fine_granular
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography
                                  variant="caption"
                                  className={`gtc-uppercase`}
                                >
                                  <b>Coarse Granualar Cast:</b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="coarse_granualar"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data
                                      .microscopic_test_coarse_granular === null
                                      ? ""
                                      : info.data
                                          .microscopic_test_coarse_granular
                                  }
                                />
                              </Box>
                            </Box>
                          </>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <>
                            <Box mt={2}>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                CRYSTALS
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Box mr={1} flexGrow={1} align="right" mt={2}>
                                <Typography variant="caption">
                                  <b> CALCIUM OXALATE: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="crystal_oxalate"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data
                                      .microscopic_test_calcium_oxalate === null
                                      ? ""
                                      : info.data
                                          .microscopic_test_calcium_oxalate
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> TRIPLE PHOSPHATE: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="triple_phosphate"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data
                                      .microscopic_test_triple_phospahte ===
                                    null
                                      ? ""
                                      : info.data
                                          .microscopic_test_triple_phospahte
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> LEUCINE/TYROCINE: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="leucine_tyrocine"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data
                                      .microscopic_test_leucine_tyrosine ===
                                    null
                                      ? ""
                                      : info.data
                                          .microscopic_test_leucine_tyrosine
                                  }
                                />
                              </Box>
                            </Box>

                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> AMMONIUME BIURATE: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="ammoniume"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data
                                      .microscopic_test_ammonium_biurate ===
                                    null
                                      ? ""
                                      : info.data
                                          .microscopic_test_ammonium_biurate
                                  }
                                />
                              </Box>
                            </Box>

                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> AMORPHOUS URATES: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="amorphous_urates"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data
                                      .microscopic_test_amorphous_urates ===
                                    null
                                      ? ""
                                      : info.data
                                          .microscopic_test_amorphous_urates
                                  }
                                />
                              </Box>
                            </Box>

                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b>AMORPHOUS PHOSPHATES:</b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="amorphous_phosphate"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data
                                      .microscopic_test_amorphous_phosphates ===
                                    null
                                      ? ""
                                      : info.data
                                          .microscopic_test_amorphous_phosphates
                                  }
                                />
                              </Box>
                            </Box>

                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> URIC ACID: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="uric_acid"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data.microscopic_test_uricacid === null
                                      ? ""
                                      : info.data.microscopic_test_uricacid
                                  }
                                />
                              </Box>
                            </Box>
                          </>
                          <>
                            <Box mt={2}>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                OTHERS
                              </Typography>
                            </Box>

                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> MUCUS THREAD: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="mucus_thread"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data.microscopic_test_mucus_thread ===
                                    null
                                      ? ""
                                      : info.data.microscopic_test_mucus_thread
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> BACTERIA: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="bacteria"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data.microscopic_test_bacteria === null
                                      ? ""
                                      : info.data.microscopic_test_bacteria
                                  }
                                />
                              </Box>
                            </Box>

                            <Box display="flex">
                              <Box mt={2} mr={1} flexGrow={1} align="right">
                                <Typography variant="caption">
                                  <b> YEAST: </b>
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  fullWidth
                                  label="Result"
                                  name="yeast"
                                  margin="dense"
                                  variant="outlined"
                                  defaultValue={
                                    info.data.microscopic_test_yeast === null
                                      ? ""
                                      : info.data.microscopic_test_yeast
                                  }
                                />
                              </Box>
                            </Box>
                          </>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>

                <Box mb={2}>
                  <>
                    <Box
                      hidden={
                        Boolean(parseInt(info.data.pregnancy_test_hcg))
                          ? false
                          : true
                      }
                    >
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography variant="subtitle1">
                            <b> PREGNANCY TEST (HCG) </b>
                          </Typography>
                        </Box>
                      </Box>
                      {Boolean(parseInt(info.data.is_processed)) && (
                        <Box>
                          <TextField
                            fullWidth
                            label="Enable pregnancy test"
                            name="pregnancy_test_enable"
                            margin="dense"
                            variant="outlined"
                            hidden
                            value={"pregnancy_order"}
                          />
                          <TextField
                            fullWidth
                            label="Result"
                            name="pregnancy_test"
                            margin="dense"
                            variant="outlined"
                            multiline
                            rows={3}
                            defaultValue={
                              info.data.pregnancy_test_hcg_result === null
                                ? ""
                                : info.data.pregnancy_test_hcg_result
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  </>
                </Box>

                <Box mb={2}>
                  <Box hidden={!Boolean(parseInt(info.data.micral_test))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> MICRAL TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(info.data.is_processed)) && (
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          label="Result"
                          name="micral_test_result"
                          variant="outlined"
                          multiline
                          defaultValue={
                            info.data.micral_test_result === null
                              ? ""
                              : info.data.micral_test_result
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box mb={2}>
                  <Box hidden={!Boolean(parseInt(info.data.occult_blood_test))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> OCCULT BLOOD </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(info.data.is_processed)) && (
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          label="Result"
                          name="occult_blood_test_result"
                          variant="outlined"
                          multiline
                          defaultValue={
                            info.data.occult_blood_test_result === null
                              ? ""
                              : info.data.occult_blood_test_result
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box mb={2}>
                  <Box hidden={!Boolean(parseInt(info.data.seminalysis_test))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> SEMINALYSIS TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(info.data.is_processed)) && (
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          label="Result"
                          name="seminalysis_result"
                          variant="outlined"
                          multiline
                          defaultValue={
                            info.data.seminalysis_result === null
                              ? ""
                              : info.data.seminalysis_result
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            )}

            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default ClinicalMicroscopyEditForm;
