import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import RefOralGlucose from "../references/Ref_OralGlucose";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const OralGlucoseOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bolder", color: "#000" }}
            >
              ORAL GLUCOSE TOLERANCE
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ fontWeight: "bolder", color: "#000" }}
            >
              75 GRAMS GLUCOLA
            </Typography>
          </Box>

          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      Test
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      Result
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      Unit
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      Remarks
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orderDetails.ready ? (
                  orderDetails.data.length > 0 ? (
                    orderDetails.data.map((data, index) => (
                      <Fragment key={index}>
                        <TableRow hidden={data.baseline === null}>
                          <TableCell>
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Baseline
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.baseline}
                            </Typography>
                          </TableCell>
                          <TableCell> {RefOralGlucose.baseline()} </TableCell>
                          <TableCell>
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.baseline_remarks}
                            </span>
                          </TableCell>
                        </TableRow>

                        <TableRow hidden={data.first_hour === null}>
                          <TableCell>
                            {" "}
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              First Hour
                            </span>{" "}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {" "}
                              {data.first_hour}{" "}
                            </Typography>
                          </TableCell>
                          <TableCell>{RefOralGlucose.firstHour()}</TableCell>
                          <TableCell>
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.first_hour_remarks}
                            </span>
                          </TableCell>
                        </TableRow>

                        <TableRow hidden={data.second_hour === null}>
                          <TableCell>
                            {" "}
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Second Hour
                            </span>{" "}
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {" "}
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.second_hour}
                              </span>{" "}
                            </Typography>
                          </TableCell>
                          <TableCell>{RefOralGlucose.secondHour()}</TableCell>
                          <TableCell>
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.second_hour_remarks}
                            </span>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography> No record found. </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography> Loading, Please wait... </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box my={3} align="center">
              <Footer formheader={formheader} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default OralGlucoseOrder;
