import { Grid, Typography, Box } from "@material-ui/core";
import React, { useContext } from "react";
import { UsersHeader } from "src/ContextAPI";
import Container from "src/layout/Container";
import GTCNewsFull from "src/GTCNewsFull";

const PsychologyDashboard = () => {
  const { sidebarHeader } = useContext(UsersHeader);
  return (
    <Container
      breadcrumbs={{
        enable: false,
        current: "eryery",
        items: [],
      }}
      title={
        <>
          <Typography variant={"h4"}>Dashboard</Typography>
          <Typography variant="subtitle1">
            Welcome back, {sidebarHeader.name} 👋👋👋
          </Typography>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <GTCNewsFull />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PsychologyDashboard;
