// 11-19-2021-updates-jhomar

import { Box, TextField, Button, FormHelperText } from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useCallback, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { DropzoneArea } from "material-ui-dropzone";
import * as Yup from "yup";
import { Formik } from "formik";
import { CheckSquare, XCircle } from "react-feather";

const DocuPatientDetailsXrayDetails = ({
  patient_id,
  getPEOrderToProcess,
  details,
}) => {
  const { users } = React.useContext(UsersData);
  const [selectedImagingOrder, setSelectedImagingOrder] = React.useState(null);
  const [countOfOrderDetails, setCountOfOrderDetails] = useState(0);

  const [image, setImage] = React.useState([]);

  const getPatientInformation = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
        patient_id: patient_id,
        type: "xray",
      };
      const response = await axios.get("imaging/patients/information", {
        params,
      });
      setCountOfOrderDetails(response.data.length);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, patient_id]);

  const convertToFormdata = (object) => {
    const formData = new FormData();
    formData.append("user_id", users.user_id);
    formData.append("management_id", users.management_id);
    formData.append("username", users.username);
    formData.append("patient_id", patient_id);
    formData.append("imaging_center_id", details.imaging_center_id);
    formData.append("order_count", countOfOrderDetails);

    for (let i = 0; i < image.length; i++) {
      formData.append("image[]", image[i]);
    }

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleImageChangeNew = (files) => {
    setImage(files);
  };

  const getBackToDefault = () => {
    setSelectedImagingOrder(null);
    setImage([]);
  };

  React.useEffect(() => {
    getPatientInformation();
  }, [getPatientInformation]);

  return (
    <Fragment>
      <Formik
        initialValues={{
          imaging_order: selectedImagingOrder,
          radiologist_type: "emailed_mode",
          radiologist: "email_mode",
          result: "",
          impressions: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          result: Yup.string().trim().required(),
          impressions: Yup.string().trim().required(),
          password: Yup.string().required(),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
          if (image.length < 1) {
            setErrors({ submit: "Image attachment is required" });
          } else {
            try {
              const request = await axios.post(
                "documentation/van/patient/imaging/order/addresult",
                convertToFormdata(values)
              );
              if (request.data === "pass-invalid") {
                setErrors({ submit: "Password is invalid." });
                Notify.fieldInvalid("password");
              }
              if (request.data === "success") {
                getPEOrderToProcess();
                setSubmitting(true);
                getBackToDefault();
                resetForm();
                Notify.successRequest("new attachment");
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box mb={2}>
              <DropzoneArea
                onChange={handleImageChangeNew}
                acceptedFiles={["image/jpeg", "image/png"]}
                dropzoneText={selectedImagingOrder}
                maxFileSize={5000000}
                filesLimit={5}
              />
            </Box>

            <Box mb={2}>
              <TextField
                multiline
                fullWidth
                required
                error={Boolean(touched.result && errors.result)}
                helperText={touched.result && errors.result}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.result}
                name="result"
                label="Result"
                variant="outlined"
              />
            </Box>

            <Box mb={2}>
              <TextField
                multiline
                fullWidth
                required
                error={Boolean(touched.impressions && errors.impressions)}
                helperText={touched.impressions && errors.impressions}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.impressions}
                name="impressions"
                label="impressions"
                variant="outlined"
              />
            </Box>

            <Box mb={2}>
              <TextField
                fullWidth
                required
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                label="Password"
                variant="outlined"
                type="password"
              />
            </Box>

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box display="flex">
              <Box mr={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckSquare />}
                  size="large"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>

              <Button
                variant="contained"
                color="default"
                startIcon={<XCircle />}
                size="large"
                onClick={(e) => {
                  resetForm(e);
                }}
              >
                No
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default DocuPatientDetailsXrayDetails;
