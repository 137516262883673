import { faHandPointUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";

const DoctorsSalaryReceiveStatus = () => {
  const { users } = useContext(UsersData);
  const [docSalary, setDocSalary] = useState({ data: [], ready: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const getAllSalaryRecord = useCallback(async () => {
    var params = {
      management_id: users.management_id,
      user_id: users.user_id,
      main_mgmt_id: users.main_mgmt_id,
    };

    let response = await axios.get("doctor/get/all/salary-record", { params });
    const data = response.data;
    setDocSalary({ data, ready: true });
  }, [users]);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleReceiveSalary = (data) => {
    var formdata = new FormData();
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("dsr_id", data.dsr_id);
    axios
      .post("doctor/update/to-received/salary-record", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          getAllSalaryRecord();
          Notify.successRequest("confirm success");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getAllSalaryRecord();
  }, [getAllSalaryRecord]);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "salary record",
          items: [{ name: "dashboard", path: "/bmcdc/app/doctor" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Salary Record</Box>
          </Box>
        }
      >
        <Box>
          <Box component={Paper} elevation={1} p={2}>
            <Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"> Date </TableCell>
                      <TableCell align="center"> Type </TableCell>
                      <TableCell align="center"> My Share </TableCell>
                      <TableCell align="center"> Action </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {docSalary.ready ? (
                      docSalary.data.length > 0 ? (
                        docSalary.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              From: {Notify.createdAt(data.date_from)} <br />
                              To: {Notify.createdAt(data.date_to)}
                            </TableCell>
                            <TableCell>
                              <Typography className="text-capitalize">
                                {data.type}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              {data.doctor_share}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                startIcon={
                                  Boolean(
                                    parseFloat(data.is_received)
                                  ) ? null : (
                                    <FontAwesomeIcon icon={faHandPointUp} />
                                  )
                                }
                                onClick={() => handleReceiveSalary(data)}
                                variant="contained"
                                color={"primary"}
                                disabled={Boolean(parseFloat(data.is_received))}
                              >
                                {Boolean(parseFloat(data.is_received))
                                  ? "Received"
                                  : "Receive"}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography color="primary">
                            Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <TablePagination
              labelRowsPerPage="List"
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={docSalary.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default DoctorsSalaryReceiveStatus;
