import {
  Typography,
  Grid,
  Box,
  Paper,
  List,
  Divider,
  TextField,
  InputAdornment,
  ListItem,
  ListItemText,
  Avatar,
  TablePagination,
  ListItemIcon,
  makeStyles,
  CardMedia,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { UsersHeader } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import Container from "src/layout/Container";
import PatientQueueDetails from "./PatientQueueDetails";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const PatientQueu = () => {
  const classes = useStyles();
  const {
    queue: { patient, getPatientsOnQueue },
  } = useContext(UsersHeader);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState("new-patient");
  const [search, setSearch] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const searchable = patient.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Patient Queue",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={"Patient Queue"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={3}>
          <Box component={Paper} variant="outlined" borderRadius={4} p={2}>
            <Box mb={2}>
              <Typography color="primary" variant="subtitle2">
                PATIENT ON QUEUE
              </Typography>
            </Box>

            <Divider />

            <List component="div">
              <Box mb={1}>
                <TextField
                  label="Search for patient"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {patient.length > 0
                ? searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <ListItem
                        key={index}
                        button
                        className="mb-2"
                        onClick={() => setSelectedPatient(data.patient_id)}
                        selected={selectedPatient === data.patient_id}
                      >
                        <ListItemIcon>
                          {data.image === null ? (
                            <Avatar className={classes.primary}>
                              {data.lastname.charAt().toUpperCase()}
                            </Avatar>
                          ) : (
                            <Avatar
                              className={classes.primary}
                              src={`${imageLocation}patients/${data.image}`}
                              alt=""
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          className={`gtc-capitalize`}
                          primary={`${data.lastname}, ${data.firstname} ${
                            Boolean(data.middle) ? data.middle : ``
                          } `}
                        />
                      </ListItem>
                    ))
                : Notify.noRecord()}
            </List>
            <TablePagination
              component="div"
              count={patient.length}
              rowsPerPageOptions={[10, 50, 100, { label: "All", value: -1 }]}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="List"
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} lg={9}>
          {selectedPatient === "new-patient" && <RenderSelectPatient />}
          {selectedPatient !== "new-patient" && (
            <PatientQueueDetails
              patient_id={selectedPatient}
              getNurseOnQueue={() => getPatientsOnQueue()}
              onClose={() => setSelectedPatient("new-patient")}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PatientQueu;

const RenderSelectPatient = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 250 }}
    />
    <Typography color="secondary" variant="h3">
      NO PATIENT SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select patient in the list to view details.
    </Typography>
  </Box>
);
