import React, { useState } from "react";
import { Box, CardMedia, Grid, Typography } from "@material-ui/core";
import RadiologistPatientForReview from "./RadiologistPatientForReview";
import RadiologistPatientDetails from "./RadiologistPatientDetails";
import Container from "src/layout/Container";
import { UsersHeader } from "src/ContextAPI";

const RadiologistPatients = () => {
  const {
    queue: { patient, getPatientsOnQueue },
  } = React.useContext(UsersHeader);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const updateSelectedPatient = (data) => {
    setSelectedPatient(data);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "PATIENTS",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={"Patients List"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <Box>
            <RadiologistPatientForReview
              patients={patient}
              selectedPatient={selectedPatient}
              updateSelected={updateSelectedPatient}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} md={9}>
          <Box>
            {selectedPatient === null && <RenderSelectPatient />}
            {selectedPatient && (
              <RadiologistPatientDetails
                selectedPatient={selectedPatient}
                resetDisplay={() => {
                  setSelectedPatient(null);
                  getPatientsOnQueue();
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RadiologistPatients;

const RenderSelectPatient = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />
    <Typography color="secondary" variant="h3">
      NO PATIENT SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select patient in the list to view details.
    </Typography>
  </Box>
);
