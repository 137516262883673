import {
  Box,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState, useContext, useCallback } from "react";
import Container from "src/layout/Container";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import axios from "axios";
import { BranchListContext, UsersData } from "src/ContextAPI";
import SaveAndFinalExpense from "./SaveAndFinalExpense";
import Notify from "src/notification/Notify";

const AddExpense = () => {
  const { users } = useContext(UsersData);
  const { branches } = useContext(BranchListContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tempExpense, setTempExpense] = useState({ data: [], ready: false });

  const getTemporaryAddExpense = useCallback(async () => {
    var params = { main_management_id: users.main_mgmt_id };
    try {
      let response = await axios.get("accounting/get/temp-save-expense", {
        params,
      });
      const data = response.data;
      setTempExpense({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  const handleAddExpense = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);

    var error = [];

    if (
      formdata.get("description").length === 0 ||
      formdata.get("description").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Description");
    }
    if (
      formdata.get("quantity").length === 0 ||
      formdata.get("quantity").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Quantity");
    }

    if (
      formdata.get("unit_price").length === 0 ||
      formdata.get("unit_price").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Unit price");
    }
    if (formdata.get("vat").length === 0 || formdata.get("vat").trim() === "") {
      error = "error";
      Notify.fieldRequired("vat");
    }
    if (
      formdata.get("expense_item").length === 0 ||
      formdata.get("expense_item").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Expense item");
    }
    if (
      formdata.get("branch").length === 0 ||
      formdata.get("branch").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Branch");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/add/new-temp-expense", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getTemporaryAddExpense();
            e.target.reset();
            Notify.successRequest("add expense");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    getTemporaryAddExpense();
  }, [getTemporaryAddExpense]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Add Expense",
        items: [
          { name: "Dashboard", path: "/bmcdc/app/accounting" },
          { name: "Expense", path: "/bmcdc/app/accounting/expense" },
        ],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Add Expense</Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Paper component={Box}>
            <Box m={2}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={1}
              >
                <Box flexGrow={1} mt={2}>
                  <Typography
                    color="primary"
                    variant={"subtitle1"}
                    style={{ fontWeight: "bolder" }}
                  >
                    ADD EXPENSE
                  </Typography>
                </Box>
              </Box>
              <form onSubmit={handleAddExpense}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <TextField
                        required
                        fullWidth
                        name="description"
                        label="Description"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="quantity"
                        label="Quantity"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        required
                        fullWidth
                        name="unit_price"
                        label="Unit Price"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        required
                        label="Non-Vat/Vat"
                        variant="outlined"
                        fullWidth
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="vat"
                      >
                        <option value=""> -- Select -- </option>
                        <option value="Non-Vat">Non-Vat</option>
                        <option value="Vat">Vat</option>
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="tin"
                        label="TIN Number"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        required
                        label="Expense Item"
                        variant="outlined"
                        fullWidth
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="expense_item"
                      >
                        <option value=""> -- Select -- </option>
                        <option value="BIR/Taxes/SSS/PHIC/PAG-IBIG">
                          BIR/Taxes/SSS/PHIC/PAG-IBIG
                        </option>
                        <option value="Communication/Internet">
                          Communication/Internet
                        </option>
                        <option value="Construction/Office Materials">
                          Construction/Office Materials
                        </option>
                        <option value="Entertainment/Representation">
                          Entertainment/Representation
                        </option>
                        <option value="Gasoline/Repair/Maintenance">
                          Gasoline/Repair/Maintenance
                        </option>
                        <option value="Hotel"> Hotel </option>
                        <option value="Incentives/Rebate">
                          Incentives/Rebate
                        </option>
                        <option value="Insurance/Credit Card/Loan">
                          Insurance/Credit Card/Loan
                        </option>
                        <option value="Meals"> Meals </option>
                        <option value="Office Supply"> Office Supply </option>
                        <option value="Others"> Others </option>
                        <option value="Retainer's Fee"> Retainer's Fee </option>
                        <option value="Salary"> Salary </option>
                        <option value="Solicitation"> Solicitation </option>
                        <option value="Transportation"> Transportation </option>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        required
                        label="Branch"
                        variant="outlined"
                        fullWidth
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="branch"
                      >
                        <option value=""> -- Select -- </option>
                        {branches.length &&
                          branches.map((data, index) => (
                            <option key={index} value={data.management_id}>
                              {data.name}
                            </option>
                          ))}
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box display="flex" mb={2}>
                      <Box flexGrow={1} />
                      <Button
                        variant="contained"
                        color="default"
                        type="reset"
                        startIcon={<HighlightOff />}
                      >
                        Reset
                      </Button>

                      <Box ml={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              <CheckCircleOutline />
                            )
                          }
                          disabled={isSubmitting}
                        >
                          Add
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8}>
          <SaveAndFinalExpense
            tempExpense={tempExpense}
            getTemporaryAddExpense={() => getTemporaryAddExpense()}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddExpense;
