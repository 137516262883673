import React, { Fragment, useState, useEffect, useCallback } from "react";
import Axios from "axios";
import Notify from "src/notification/Notify";
import {
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  Dialog,
  DialogContent,
  CircularProgress,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import Container from "src/layout/Container";
import Label from "src/utils/Label";
import { ThumbsDown, ThumbsUp, XCircle } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import IsMountedRef from "src/utils/IsMountedRef";

function CommentsList(props) {
  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [username] = useState(localStorage.getItem("username"));

  const [forappcomments, setforappcomments] = useState([]);
  const [approvedList, setApprovedList] = useState({ data: [], ready: false });

  const [approveddialog, setapproveddialog] = useState(false);

  const [isappprocess, setisappprocess] = useState(false);

  const [selectedid, setselectedid] = useState("");

  const [removedialog, setremovedialog] = useState(false);
  const ismounted = IsMountedRef();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const getApprovedComments = useCallback(() => {
    const params = {
      token: token,
      user_id: user_id,
      connection: "online",
      comment_status: "approved",
    };

    Axios.get("doctor/comments/forapproval-list", { params })
      .then((response) => {
        const data = response.data;
        if (ismounted.current) {
          setApprovedList({ data: data, ready: true });
        }
      })
      .catch((error) => {
        console.log(error);
        // Notify.requestError(error);
      });
  }, [token, user_id, ismounted]);

  const getForApprovalComments = useCallback(() => {
    const params = {
      token: token,
      user_id: user_id,
      connection: "online",
      comment_status: "pending",
    };

    Axios.get("doctor/comments/forapproval-list", { params })
      .then((response) => {
        const data = response.data;
        if (ismounted.current) {
          setforappcomments(data);
        }
      })
      .catch((error) => {
        console.log(error);
        // Notify.requestError(error);
      });
  }, [token, user_id, ismounted]);

  const handleApprovedAction = (e) => {
    setapproveddialog(true);
    setselectedid(e.currentTarget.id);
  };

  const handleRemoveAction = (e) => {
    setremovedialog(true);
    setselectedid(e.currentTarget.id);
  };

  const handleApproveComment = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("connection", "online");

    setisappprocess(true);
    Axios.post("doctor/comments/approvedcomment-save", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getForApprovalComments();
          getApprovedComments();
          setapproveddialog(false);
          setselectedid("");
          Notify.successRequest("approved comment");
        }
        if (data === "db-error") {
          Notify.warnRequest("approved comment");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setisappprocess(false);
      });
  };

  const handleRemoveComment = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("connection", "online");
    var error = [];

    if (error.length > 0) {
      console.warn("form has an error. observe.");
    } else {
      setisappprocess(true);
      Axios.post("doctor/comments/forapprovedcomment-delete", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getForApprovalComments();
            setremovedialog(false);
            setselectedid("");
            Notify.successRequest("remove comment");
          }
          if (data === "db-error") {
            Notify.warnRequest("remove comment");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setisappprocess(false);
        });
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  useEffect(() => {
    getApprovedComments();
    getForApprovalComments();
  }, [getForApprovalComments, getApprovedComments]);

  const paginateComments = Notify.applyPagination(
    approvedList.data,
    page,
    limit
  );

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Patient Comment",
          items: [
            { name: "Dashboard", path: "/bmcdc/app/doctor" },
            { name: "Patients", path: "/bmcdc/app/doctor/patient/list" },
          ],
        }}
        title="Patient Comments"
      >
        <Grid container spacing={2}>
          <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
            <Grid item xs={12} sm={12} md={forappcomments.length > 0 ? 8 : 12}>
              <Paper elevation={2} component={Box}>
                <Box p={2}>
                  <Typography variant="h6" color="textSecondary">
                    Comment Approved List
                  </Typography>
                </Box>

                <TableContainer>
                  <PerfectScrollbar>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell> DATE </TableCell>
                          <TableCell> PATIENT </TableCell>
                          <TableCell> COMMENT </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {approvedList.ready ? (
                          paginateComments.length > 0 ? (
                            paginateComments.map((data, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {" "}
                                  {Notify.dateTimeConvert(data.created_at)}{" "}
                                </TableCell>
                                <TableCell> {data.patient_name} </TableCell>
                                <TableCell> {data.comment} </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>
                                <Typography color="error">
                                  No comment recorded
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Typography color="primary">
                                please wait...
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </PerfectScrollbar>
                  <TablePagination
                    component={Box}
                    count={approvedList.data.length}
                    labelRowsPerPage="List"
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                  />
                </TableContainer>
              </Paper>
            </Grid>
          </Box>

          <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
            <Grid item xs={12} sm={12} md={4}>
              {forappcomments.length > 0 &&
                forappcomments.map((data, index) => (
                  <Paper key={index} elevation={2} component={Box} mb={2} p={2}>
                    <Typography variant="subtitle2" className="text-uppercase">
                      <b> {data.patient_name} </b> requesting for comment to
                      approve on{" "}
                      <b> {Notify.dateTimeConvert(data.created_at)} </b>
                    </Typography>

                    <Box my={1}>
                      <Typography variant="subtitle1" noWrap>
                        {data.comment}
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      overflow="hidden"
                    >
                      <Button
                        variant="contained"
                        id={data.id}
                        color="primary"
                        appaction="approved"
                        onClick={handleApprovedAction}
                        startIcon={<ThumbsUp />}
                      >
                        Approve
                      </Button>
                      <Box ml={1}>
                        <Button
                          id={data.id}
                          appaction="disapproved"
                          variant="contained"
                          color="secondary"
                          onClick={handleRemoveAction}
                          startIcon={<ThumbsDown />}
                        >
                          Disapprove
                        </Button>
                      </Box>
                    </Box>
                  </Paper>
                ))}
            </Grid>
          </Box>
        </Grid>
      </Container>

      {/* approved appointment */}
      <Dialog open={approveddialog} onClose={() => setapproveddialog(false)}>
        <form onSubmit={handleApproveComment}>
          <DialogContent dividers>
            <TextField readOnly name="comment_id" value={selectedid} hidden />

            <Typography variant="subtitle1">
              Are you sure to <Label color="primary"> approved </Label> this
              comment?
            </Typography>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Box mr={1}>
                <Button
                  startIcon={<XCircle />}
                  variant="contained"
                  color="default"
                  onClick={() => setapproveddialog(false)}
                >
                  No
                </Button>
              </Box>
              <Box>
                <Button
                  startIcon={
                    isappprocess ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : (
                      <ThumbsUp />
                    )
                  }
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isappprocess}
                >
                  Approved
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </form>
      </Dialog>

      {/* disapproved appointment */}
      <Dialog open={removedialog} onClose={() => setremovedialog(false)}>
        <form onSubmit={handleRemoveComment}>
          <DialogContent dividers>
            <TextField readOnly name="comment_id" value={selectedid} hidden />

            <Typography variant="subtitle1">
              Are you sure to <Label color="error"> disapproved </Label> this
              comment?
            </Typography>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Box mr={1}>
                <Button
                  startIcon={<XCircle />}
                  variant="contained"
                  color="default"
                  onClick={() => setremovedialog(false)}
                >
                  No
                </Button>
              </Box>
              <Box>
                <Button
                  startIcon={
                    isappprocess ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : (
                      <ThumbsDown />
                    )
                  }
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isappprocess}
                >
                  Disapproved
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </Fragment>
  );
}

export default CommentsList;
