import React, { Component, Fragment } from 'react';  
import { Modal } from 'react-bootstrap';

export default class StreamError extends Component{

    constructor() {
		super();
		this.state = {
			token: localStorage.getItem('token'),
			is_logged: localStorage.getItem('is_login'),
			role: localStorage.getItem('role'), 
        }; 
	} 
    
    render(){
        return(
            <Fragment>
                <Modal backdropClassName="pagenotfound" size="sm" centered show={true} keyboard={false} backdrop="static">
                    <Modal.Body>
                        <b> Configuration Error </b><br />
                        <small style={{
                            fontSize: '.7rem'
                        }}> Your camera and microphone is not detected, fix to continue. </small> <br />
                        <small className="text-info pointer" onClick={() => window.location.href="/"}> Back to our site </small> 
                    </Modal.Body>
                </Modal>
            </Fragment>     
        );
    }
}