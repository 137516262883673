import {
  Grid,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  TablePagination,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import React, { useContext, useEffect, useCallback, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const WarehouseMonitoring = () => {
  const [list, setList] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();
  const { users } = useContext(UsersData);

  const [search, setSearch] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getProductMonitoring = useCallback(async () => {
    let response = await axios.get(
      "accounting/warehouse/inventory/monitoring-list",
      {
        params: {
          management_id: users.management_id,
        },
      }
    );

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [users, mounted]);

  useEffect(() => {
    getProductMonitoring();
  }, [getProductMonitoring]);

  const searchable = list.data.filter((data) => {
    return (
      data.product_name.toLowerCase().indexOf(search.trim().toLowerCase()) !==
        -1 ||
      data.product_generic
        .toLowerCase()
        .indexOf(search.trim().toLowerCase()) !== -1
    );
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Monitoring",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={"Inventory Monitoring"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Paper>
            <Box p={2}>
              <Box display="flex">
                <Box flexGrow={1} />
                <Box>
                  <TextField
                    label="Search product"
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> Id </TableCell>
                      <TableCell> Date </TableCell>
                      <TableCell> Product </TableCell>
                      <TableCell> Generic </TableCell>
                      <TableCell> Unit </TableCell>
                      <TableCell> Batch Number </TableCell>
                      <TableCell> Invoice </TableCell>
                      <TableCell> Dr Number </TableCell>
                      <TableCell> Type </TableCell>
                      <TableCell> Qty </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.ready ? (
                      searchable.length > 0 ? (
                        searchable
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data, index) => (
                            <TableRow key={index}>
                              <TableCell> {index++ + 1} </TableCell>
                              <TableCell>
                                {Notify.dateTimeConvert(data.created_at)}
                              </TableCell>
                              <TableCell> {data.product_name} </TableCell>
                              <TableCell> {data.product_generic} </TableCell>
                              <TableCell> {data.unit} </TableCell>
                              <TableCell> {data.batch_number} </TableCell>
                              <TableCell> {data.invoice_number} </TableCell>
                              <TableCell> {data.dr_number} </TableCell>
                              <TableCell> {data.type} </TableCell>
                              <TableCell> {data.qty} </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Typography color="primary">
                            loading, please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <TablePagination
                  component="div"
                  count={searchable.length}
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </TableContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WarehouseMonitoring;
