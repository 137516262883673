import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { FormInformation, UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { CancelOutlined, SaveAlt } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  inputComponent: {
    height: "30px",
    width: "350px",
  },
  inputComponentLeft: {
    height: "30px",
    width: "450px",
  },
  inputComponentCenter: {
    height: "30px",
    width: "250px",
  },
  inputComponentRight: {
    height: "30px",
    width: "250px",
  },
}));

const PrintExpenseLayout = ({ close }) => {
  const { users } = useContext(UsersData);
  const { formInfo, renderFormInfo } = useContext(FormInformation);
  const [data, setData] = useState({});
  const classes = useStyles();

  const handleFromEdit = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);

    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    let response = await axios.post(
      "accounting/edit/expense-print-info",
      formdata
    );

    if (response.data === "success") {
      e.target.reset();
      renderFormInfo();
      Notify.successRequest("edit form info");
    }
  };

  const onInputchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const renderB = React.useCallback(() => {
    setTimeout(() => {
      setData(formInfo);
    }, 2000);
  }, [formInfo]);

  useEffect(() => {
    renderB();
  }, [renderB]);
  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form onSubmit={handleFromEdit} noValidate>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box display="flex" mt={2} width={"100%"}>
                    <Box flexGrow={1} />
                    <Box width={250}>
                      <Box>
                        <TextField
                          required
                          name="company_name"
                          value={data.company_name}
                          inputProps={{
                            style: { textAlign: "right" },
                          }}
                          className={classes.inputComponentCenter}
                          onChange={onInputchange}
                          autoComplete="off"
                        />
                      </Box>
                      <Box hidden>
                        <TextField
                          required
                          name="ffhi_id"
                          value={
                            data.ffhi_id !== null || data.ffhi_id !== undefined
                              ? data.ffhi_id
                              : ""
                          }
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          onChange={onInputchange}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box display="flex" width={"100%"}>
                    <Box flexGrow={1} />
                    <Typography> VOUCHER OF THE EXPENSE HERE </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography> To: </Typography>
                  <Box width={"100%"}>
                    <Box>
                      <TextField
                        required
                        name="to_company"
                        value={data.to_company}
                        inputProps={{
                          style: { textAlign: "left" },
                        }}
                        className={classes.inputComponentLeft}
                        onChange={onInputchange}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>

                  <Box width={"100%"}>
                    <Box>
                      <TextField
                        required
                        name="to_attention"
                        value={data.to_attention}
                        inputProps={{
                          style: { textAlign: "left" },
                        }}
                        className={classes.inputComponentLeft}
                        onChange={onInputchange}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>

                  <Box width={"100%"}>
                    <Box>
                      <TextField
                        required
                        name="to_address"
                        value={data.to_address}
                        inputProps={{
                          style: { textAlign: "left" },
                        }}
                        className={classes.inputComponentLeft}
                        onChange={onInputchange}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>

                  <Box width={"100%"}>
                    <Box>
                      <TextField
                        required
                        name="to_full_address"
                        value={data.to_full_address}
                        inputProps={{
                          style: { textAlign: "left" },
                        }}
                        className={classes.inputComponentLeft}
                        onChange={onInputchange}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography>Invoice Date</Typography>
                  <Box width={"100%"}>
                    <Box mt={1}>
                      <Typography>INVOICE DATE HERE</Typography>
                    </Box>
                  </Box>
                  <Box mt={5}>
                    <Typography>Reference Number</Typography>
                  </Box>
                  <Box width={"100%"}>
                    <Box>
                      <TextField
                        required
                        name="ref_number"
                        value={data.ref_number}
                        inputProps={{
                          style: { textAlign: "left" },
                        }}
                        className={classes.inputComponentCenter}
                        onChange={onInputchange}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Box mt={2.6} width={"50%"}>
                    <Box>
                      <TextField
                        required
                        name="expense_category"
                        value={data.expense_category}
                        inputProps={{
                          style: { textAlign: "left" },
                        }}
                        className={classes.inputComponentRight}
                        onChange={onInputchange}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box mt={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={100}
                borderColor={"#AEAEAE"}
                borderRadius={4}
                border={1}
              >
                <Typography variant="h3"> CONTENT </Typography>
              </Box>
              <Box my={2} mt={5}>
                <Box display="flex">
                  <Box flexGrow={1} width={"50%"}>
                    <Typography> Prepared by: </Typography>
                    <Box align="center">
                      <Box>
                        <TextField
                          required
                          name="prepared_by"
                          value={data.prepared_by}
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          className={classes.inputComponent}
                          onChange={onInputchange}
                        />
                      </Box>
                      <Box maxWidth={350} />
                    </Box>
                  </Box>
                  <Box width={"50%"}>
                    <Typography> Approved by: </Typography>
                    <Box align="center">
                      <Box>
                        <TextField
                          required
                          name="approved_by"
                          value={data.approved_by}
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          className={classes.inputComponent}
                          onChange={onInputchange}
                        />
                      </Box>
                      <Box maxWidth={350} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box display="flex">
                  <Box flexGrow={1} width={"50%"}>
                    <Typography> Reviewed by: </Typography>
                    <Box align="center">
                      <Box>
                        <TextField
                          required
                          name="reviewed_by"
                          value={data.reviewed_by}
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          className={classes.inputComponent}
                          onChange={onInputchange}
                        />
                      </Box>
                      <Box maxWidth={350} />
                    </Box>
                  </Box>

                  <Box width={"50%"}>
                    <Typography> Received by: </Typography>
                    <Box align="center">
                      <Box>
                        <TextField
                          required
                          name="received_by"
                          value={data.received_by}
                          inputProps={{
                            readOnly: true,
                          }}
                          className={classes.inputComponent}
                          onChange={onInputchange}
                        />
                      </Box>
                      <Box maxWidth={350} />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" mt={2}>
                <Box flexGrow={1} />
                <Box mr={2}>
                  <Button
                    className={"d-print-none"}
                    variant="contained"
                    color="default"
                    startIcon={<CancelOutlined />}
                    onClick={() => close()}
                  >
                    Close
                  </Button>
                </Box>
                <Box>
                  <Button
                    className={"d-print-none"}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveAlt />}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrintExpenseLayout;
