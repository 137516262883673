import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardHeader,
  CardContent,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const ProductMonitoring = () => {
  const mounted = IsMountedRef();
  const [list, setList] = useState({ data: [], ready: false });
  const { product_id } = useParams();

  const getProductMonitoring = React.useCallback(async () => {
    let response = await axios.get("warehouse/products/product-monitoring", {
      params: {
        product_id,
      },
    });

    const data = response.data;

    if (mounted.current) {
      setList({ data, ready: true });
    }
  }, [mounted, product_id]);

  useEffect(() => {
    getProductMonitoring();
  }, [getProductMonitoring]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Monitoring",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={`Brand Monitoring`}
    >
      <Card>
        <CardHeader
          title={`${list.data.length > 0 ? list.data[0].product_name : ""}`}
        />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Date </TableCell>
                  <TableCell> Generic </TableCell>
                  <TableCell> Dr Number </TableCell>
                  <TableCell> Dr Account </TableCell>
                  <TableCell> Invoice Number </TableCell>
                  <TableCell> Batch Number </TableCell>
                  <TableCell> Type </TableCell>
                  <TableCell> Qty </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.ready ? (
                  list.data.length > 0 ? (
                    list.data.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {data.type === "IN"
                            ? Notify.createdAt(data.created_at)
                            : Notify.createdAt(data.dr_date)}
                        </TableCell>
                        <TableCell> {data.product_generic} </TableCell>
                        <TableCell> {data.dr_number} </TableCell>
                        <TableCell> {data.dr_accountname} </TableCell>
                        <TableCell> {data.invoice_number} </TableCell>
                        <TableCell> {data.batch_number} </TableCell>
                        <TableCell align="center"> {data.type} </TableCell>
                        <TableCell align="right"> {data.qty} </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Typography color="primary">
                        loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ProductMonitoring;
