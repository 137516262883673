import React, { useEffect, Fragment } from "react";
import Notify from "src/notification/Notify";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "src/utils/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper, faVials, faWalking, faXRay } from "@fortawesome/free-solid-svg-icons";
import Laboratory from "src/documentation/patientqueuemobile/tabpanel/Laboratory";
import DocuPatientDetailsPE from "./DocuPatientDetailsPE";
import DocuPatientDetailsXray from "./DocuPatientDetailsXray";
import DocuPatientDetailsMedCert from "./DocuPatientDetailsMedCert";

// import { UsersData } from "src/ContextAPI";

const DocuPatientDetails = ({
  patient,
  updatePatientList,
  selectedCompany,
  selectedBranch,
  patient_id,
}) => {
  //   const { users } = useContext(UsersData);
  const [category, setCategory] = React.useState(0);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      {patient.ready && patient.data.length > 0 ? (
        <Fragment>
          <Box m={0} p={0}>
            <Box component={Paper} elevation={1} mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab
                  //   hidden={
                  //     parseInt(patient.data[0].count_laboratory) > 0
                  //       ? false
                  //       : true
                  //   }
                  value="urinestool"
                  wrapped
                  label="Urine/Stool"
                  icon={<FontAwesomeIcon icon={faVials} size={"2x"} />}
                />

                <Tab
                  //   hidden={
                  //     parseFloat(patient.data[0].count_xray) > 0 ? false : true
                  //   }
                  value="xray"
                  wrapped
                  label="Xray"
                  icon={<FontAwesomeIcon icon={faXRay} size={"2x"} />}
                />

                <Tab
                  //   hidden={
                  //     parseFloat(patient.data[0].count_doctor) > 0 ? false : true
                  //   }
                  value="physicalexam"
                  wrapped
                  label="P.E"
                  icon={<FontAwesomeIcon icon={faWalking} size={"2x"} />}
                />


<Tab
                  value="medcert"
                  wrapped
                  label="MedCert"
                  icon={<FontAwesomeIcon icon={faNewspaper} size={"2x"} />}
                />
              </Tabs>
            </Box>

            <TabPanel value={category} index={"urinestool"}>
              <Laboratory
                patient_id={patient_id}
                selectedBranch={selectedBranch}
                updatePatientList={() => updatePatientList()}
              />

              {/* <VanPatientProfile
                accreditedCompany={accreditedCompany}
                patient_id={patient_id}
                getDoctorsPatient={() => getDoctorsPatient()}
                getPatientInformation={getPatientInformation}
                info={info}
              /> */}
            </TabPanel>

            <TabPanel value={category} index={"xray"}>
              {/* <VanPatientVitals
                accreditedCompany={accreditedCompany}
                patient_id={patient_id}
                getDoctorsPatient={() => getDoctorsPatient()}
                getPatientInformation={getPatientInformation}
                info={info}
              /> */}
              <DocuPatientDetailsXray patient_id={patient_id} />
            </TabPanel>

            <TabPanel value={category} index={"physicalexam"}>
              {/* <VanPatientCreateOrder
                branchList={branchList}
                getAllBranches={() => getAllBranches()}
                accreditedCompany={accreditedCompany}
                patient_id={patient_id}
                getDoctorsPatient={() => getDoctorsPatient()}
                getPatientInformation={getPatientInformation}
                info={info}
              /> */}
              <DocuPatientDetailsPE patient_id={patient_id} />
            </TabPanel>

            <TabPanel value={category} index={"medcert"}>
              <DocuPatientDetailsMedCert patient_id={patient_id} />
            </TabPanel>
          </Box>
        </Fragment>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={200}
        >
          {Notify.loading()}
        </Box>
      )}
    </>
  );
};

export default DocuPatientDetails;
