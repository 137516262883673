import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import IshiharaDashboard from "src/ishihara";
import IshiharaPatients from "src/ishihara/patients";
import IshiharaTest from "src/ishihara/test";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faHome,
  faUserInjured,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import Account from "src/ishihara/account/Account";

const RouteIshihara = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("ishihara/sidebar/header-infomartion", {
        params,
      });
      console.log("response.data: ", response.data);
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/bmcdc/app/isihihara",
      subitem: [],
    },
    {
      name: "Patients",
      show: true,
      icon: <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
      path: "/bmcdc/app/isihihara/patients",
      subitem: [],
    },
    {
      name: "Ishihara Test",
      show: true,
      icon: <FontAwesomeIcon icon={faEye} color="white" size={"2x"} />,
      path: "/bmcdc/app/isihihara/test",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/isihihara/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "isihihara",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"isihihara"}
          />
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/bmcdc/app/isihihara" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/bmcdc/app/isihihara" />}
            />

            <Route
              exact
              path="/bmcdc/app"
              component={() => <Redirect to="/bmcdc/app/isihihara" />}
            />

            <Route
              exact
              path="/bmcdc/app/isihihara"
              component={IshiharaDashboard}
            />

            <Route
              exact
              path="/bmcdc/app/isihihara/patients"
              component={IshiharaPatients}
            />

            <Route
              exact
              path="/bmcdc/app/isihihara/test"
              component={IshiharaTest}
            />

            <Route
              exact
              path="/bmcdc/app/isihihara/account"
              component={Account}
            />

            <Route exact path="/bmcdc/app/logout" component={Logout} />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteIshihara;
