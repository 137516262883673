
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { Button, DialogActions, DialogContent, Box, Grid, Table, TableHead, TableRow, TableBody, TableCell, Typography, Divider } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import Print from '@material-ui/icons/Print';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrescription } from '@fortawesome/free-solid-svg-icons';
import Notify from '../../../notification/Notify';

const VirtualPrintRx = ({ close, claim_id, patient_id, doctors_id }) => {
    const [doctorsInfo, setDoctorsInfo] = useState([])
    const [doctorsInfoRd, setDoctorsInfoRd] = useState(false)
    const [prescDetails, setPrescDetails] = useState([])
    const [prescDetailsRd, setPrescDetailsRd] = useState(false)
    const [patientInfo, setPatientInfo] = useState([])
    const [patientInfoRd, setPatientInfoRd] = useState(false)

    const getDoctorsInfo = useCallback(() => {
        var params = { doctors_id }
        Axios.get('pharmacy/rx/doctorsdetails', { params })
            .then((response) => {
                const data = response.data
                setDoctorsInfo(data)
                setDoctorsInfoRd(true)
            }).catch((error) => {
                Notify.requestError(error)
            })
    }, [doctors_id]);

    const getPatientInfo = useCallback(() => {
        const params = { patient_id }
        Axios.get('pharmacy/patient-information', { params })
            .then((response) => {
                const data = response.data
                setPatientInfo(data)
                setPatientInfoRd(true)
            }).catch((error) => {
                Notify.requestError(error)
            })
    }, [patient_id]);

    const getPrescriptionDetails = useCallback(() => {
        var params = { claim_id: claim_id, patient_id: patient_id, doctors_id: doctors_id }
        Axios.get('pharmacy/rx/prescriptiondetails', { params })
            .then((response) => {
                const data = response.data
                setPrescDetails(data)
                setPrescDetailsRd(true)
            }).catch((error) => {
                Notify.requestError(error)
            })
    }, [claim_id, patient_id, doctors_id]);

    useEffect(() => {
        getDoctorsInfo()
        getPrescriptionDetails()
        getPatientInfo()
    }, [getDoctorsInfo, getPrescriptionDetails, getPatientInfo])


    return (
        <Fragment>
            <DialogContent >
                <Box className="gtc-printable-landscape">
                    {/* doctors information */}
                    {
                        doctorsInfoRd ?
                            doctorsInfo.length > 0 ?
                                <Box align="center" mb={1}>
                                    <Box>
                                        <Typography variant="h6">
                                            <b> {`DANIEL T. DANASCO, JR., MD., PAFP, PCOM`} </b>
                                        </Typography>
                                    </Box>

                                    <Box>
                                        <Typography variant="subtitle1" color="textPrimary">
                                            {`Family Physician & Occupational Health Physician`}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {`Papaya St., General Santos City`}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {`Tel. No. (083) 554 - 2734`}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {`Monday to Saturday, 9:00 AM to 5:00 PM`}
                                        </Typography>
                                    </Box>
                                </Box>
                                : <Box>
                                    {Notify.noRecord()}
                                    <Typography>
                                        Doctors Information Not Found.
                                    </Typography>
                                </Box>
                            : Notify.loading()
                    }

                    <Divider />
                    <Divider />

                    <Box mt={1}>
                        {
                            patientInfoRd ?
                                patientInfo.length > 0 ?
                                    <>
                                        <Box mb={1}>
                                            <b> Name: </b> <span className="text-capitalize"> {patientInfo[0].firstname} {patientInfo[0].middle} {patientInfo[0].firstname} </span>
                                        </Box>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Box>
                                                    <b> Gender: </b> {patientInfo[0].gender}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box>
                                                    <b> Age: </b> {Notify.calculateAge(patientInfo[0].birthday)}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box mt={1}>
                                                    <b> Date: </b> {prescDetails.length > 0 ? Notify.dateTimeConvert(prescDetails[0].created_at) : 'No date found.'}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                    : Notify.noRecord()
                                : Notify.loading()
                        }
                        <Box mt={2}>
                            <FontAwesomeIcon icon={faPrescription} size="5x" />
                        </Box>

                        <Table className="gtc-table-borderless" padding="checkbox">
                            <TableHead>
                                <TableRow>
                                    <TableCell> <b> Products </b> </TableCell>
                                    <TableCell> <b> Quantity </b> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    prescDetailsRd ?
                                        prescDetails.length > 0 ?
                                            prescDetails.map((data, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <TableRow >
                                                            <TableCell>
                                                                <Box>
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        noWrap={false}
                                                                    >
                                                                        <span className="text-uppercase"> {data.product_name} {data.type} {data.dosage}  </span>
                                                                    </Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        color="textSecondary"
                                                                        noWrap={false}
                                                                    >
                                                                        Sig. {data.remarks}
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Box>
                                                                    {data.quantity}
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Fragment>
                                                )
                                            })
                                            : <TableRow>
                                                <TableCell align="center" colSpan={2}>
                                                    <Typography color="secondary">
                                                        No record found.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        : <TableRow>
                                            <TableCell align="center" colSpan={2}>
                                                <Typography color="primary">
                                                    Please wait...
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    {
                        doctorsInfoRd ?
                            doctorsInfo.length > 0 ?
                                <Box display="flex">
                                    <Box flexGrow={1} />
                                    <Box>
                                        <Box>
                                            <Box align="left">
                                                {/* <img src="/sample-sign.png" alt="" width="120" style={{position: 'relative', top: 140}}/> */}
                                                <img
                                                    src={'/app/icons/rx/doc_donasco_sign.png'}
                                                    alt=""
                                                    width="120"
                                                    style={{ position: 'relative', top: 30 }}
                                                />
                                            </Box>

                                            <Typography variant="subtitle1">
                                                <b className="text-capitalize"> {`DANIEL T. DONASCO, JR., M.D`} </b>
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="subtitle2">
                                                PRC No. 00057656
                                                </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle2">
                                                PTR No.
                                                </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle2">
                                                TIN No.
                                                </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle2">
                                                S2 No.
                                                </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                : Notify.noRecord()
                            : Notify.loading()
                    }
                </Box>
            </DialogContent>
            <DialogActions className="d-print-none">
                <Button
                    variant="contained"
                    color="default"
                    startIcon={<Cancel />}
                    onClick={close}
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Print />}
                    onClick={() => window.print()}
                >
                    Print
                </Button>
            </DialogActions>
        </Fragment>
    )
}

export default VirtualPrintRx;
