import React, { useContext, useEffect, useState } from "react";
import {
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  TableFooter,
  DialogActions,
  makeStyles,
  CircularProgress,
  useTheme,
  Typography,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import { Search, AddCircle, Cancel } from "@material-ui/icons";
import Select from "react-select";
import Notify from "src/notification/Notify";
import { Fragment } from "react";
import SelectStyle from "src/utils/SelectStyle";
import Print from "@material-ui/icons/Print";
import Backdrop from "@material-ui/core/Backdrop";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PayrollSummary = ({ close, selectedBranch }) => {
  const classes = useStyles();
  const { users } = useContext(UsersData);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [employeeAddDialog, setEmployeeAddDialog] = useState(false);
  const [isLoadPayrollContent, setIsLoadPayrollContent] = useState(false);

  const [tableCellHeaderAmount, setTableCellHeaderAmount] = useState(false);

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedHeaderCat, setSelectedHeaderCat] = useState(null);
  const selectStyle = SelectStyle();
  const [isPorcess, setIsProcess] = useState(false);

  const theme = useTheme();

  const [headerList, setHeaderList] = useState({
    data: [],
    ready: false,
  });
  const [employee, setEmployee] = useState({
    data: [],
    ready: false,
  });
  const [summary, setSummary] = useState({
    data: [],
    ready: false,
  });

  // const [generatePayroll, setGeneratePayroll] = useState([]);
  const mounted = IsMountedRef();

  var total_earning = 0;
  var total_deduction = 0;
  var total_total = 0;

  const getPayrollSummary = () => {
    var params = {
      date_from: dateFrom,
      date_to: dateTo,
      user_id: users.user_id,
      management_id: users.management_id,
    };

    axios
      .get("hr/employee/get-summary", { params })
      .then((res) => {
        const data = res.data;
        if (mounted.current) {
          setSummary({ data: data, ready: true });
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const getAllEmployee = () => {
    var params = {
      date_from: dateFrom,
      date_to: dateTo,
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      branch: selectedBranch.id,
    };

    axios
      .get("hmis/filter-with-date/get-all-employee", { params })
      .then((res) => {
        const data = res.data;
        if (mounted.current) {
          setEmployee({ data: data, ready: true });
        }
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  };

  const CalculateEarningTotalSum = (total) => {
    total_earning += parseFloat(total);
    return total;
  };

  const CalculateDeductionTotalSum = (total) => {
    total_deduction += parseFloat(total);
    return total;
  };

  const CalculateTotalTotalSum = (total) => {
    total_total += parseFloat(total);
    return total;
  };

  const getAllPayrollHeader = () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
    };
    axios
      .get("hr/payroll/header-list-bracket", { params })
      .then((res) => {
        const data = res.data;
        if (mounted.current) {
          setHeaderList({ data, ready: true });
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const checkidssss = (user_id) => {
    setTimeout(() => {
      let usersrow = document.querySelector(`.userrow-${user_id}`);

      for (let io = 0; io < headerList.data.length; io++) {
        if (usersrow) {
          let headerCellBox = document.querySelector(
            `.headerCellBox-${headerList.data[io].phi_id}-${user_id}-${headerList.data[io].category}`
          );

          if (headerCellBox) {
          } else {
            let x = document.createElement("TD");
            x.className = `MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight headerCellBox-${headerList.data[io].phi_id}-${user_id}-${headerList.data[io].category}`;
            usersrow.appendChild(x);
          }
        }
      }

      for (let i = 0; i < summary.data.length; i++) {
        if (usersrow) {
          let headerCellBox = document.querySelector(
            `.headerCellBox-${summary.data[i].header_id}-${summary.data[i].user_id}-${summary.data[i].header_cat}`
          );

          if (headerCellBox) {
            // headerCellBox.innerText = `${summary.data[i].amount} - ${summary.data[i]._x_looter}`;
            headerCellBox.innerText = `${summary.data[i].amount}`;
          }
        }
      }

      setTimeout(() => {
        setTableCellHeaderAmount(false);
        setIsLoadPayrollContent(true);
      }, 2000);
    }, 5000);
  };

  const handleSelectedDate = () => {
    var error = [];
    if (dateFrom === null) {
      error = "error";
      Notify.fieldRequired("date from");
    }
    if (dateTo === null) {
      error = "error";
      Notify.fieldRequired("date to");
    }
    if (
      parseInt(new Date(dateFrom).getTime()) >
      parseInt(new Date(dateTo).getTime())
    ) {
      error = "error";
      Notify.fieldInvalid("date from");
    }
    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setTableCellHeaderAmount(true);
      getAllEmployee();
      getPayrollSummary();
    }
  };

  const handleAddPaymentDeduction = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);

    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("employee_id", selectedEmployeeId);
    formdata.append("header_cat", selectedHeaderCat);
    formdata.append("covered_period_end", new Date(dateTo).toLocaleString());
    formdata.append(
      "covered_period_start",
      new Date(dateFrom).toLocaleString()
    );

    var error = [];

    if (
      formdata.get("header").trim() === "" ||
      formdata.get("header").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("header name");
    }

    if (
      formdata.get("amount").trim() === "" ||
      formdata.get("amount").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("amount");
    }

    if (error.length > 0) {
      console.log("There is something wrong with the form");
    } else {
      setIsProcess(true);
      axios
        .post("hr/payroll/payslip/report/add", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("Payment added.");
            setEmployeeAddDialog(false);
            getPayrollSummary();
            getAllEmployee();
          }
          if (data === "db-error") {
            Notify.warnRequest("Payment Not Added.");
          }
        })
        .catch((error) => Notify.requestError(error))
        .finally(() => setIsProcess(false));
    }
  };

  useEffect(() => {
    getAllPayrollHeader();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DialogContent>
        <Box display="flex" className="d-print-none">
          <Box flexGrow={1}>
            <Typography variant="h5">{selectedBranch.name}</Typography>
          </Box>
          <Box ml={1}>
            <TextField
              fullWidth
              required
              label="Date From"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setDateFrom(e.target.value)}
              margin="dense"
            />
          </Box>
          <Box ml={1}>
            <TextField
              fullWidth
              required
              label="Date To"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setDateTo(e.target.value);
              }}
              margin="dense"
            />
          </Box>
          <Box
            display="flex"
            ml={1}
            justifyContent="center"
            alignItems="center"
          >
            <button
              className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorPrimary MuiButton-contained MuiButton-containedPrimary"
              onClick={() => handleSelectedDate()}
            >
              <Search />
            </button>
          </Box>

          <Box
            display="flex"
            ml={1}
            justifyContent="center"
            alignItems="center"
          >
            <button
              className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorPrimary MuiButton-contained MuiButton-containedPrimary"
              onClick={() => window.print()}
            >
              <Print />
            </button>
          </Box>

          <Box ml={1}>
            <IconButton
              color="secondary"
              style={{
                backgroundColor: theme.palette.secondary.main,
                color: "#fff",
              }}
              onClick={() => close()}
            >
              <Cancel />
            </IconButton>
          </Box>

          <Box ml={5} mt={0.8}>
            <Button
              component={ReactHtmlTableToExcel}
              table="payroll-report"
              filename={`Payroll Report - ${dateFrom} - ${dateTo}`}
              sheet={`Payroll Report - ${dateFrom} - ${dateTo}`}
              buttonText="Download"
              variant="contained"
              color="primary"
              size="large"
            >
              Download
            </Button>
          </Box>
        </Box>

        <Backdrop className={classes.backdrop} open={tableCellHeaderAmount}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <TableContainer>
          <Table id="payroll-report">
            <TableHead>
              <TableRow>
                <TableCell className="d-print-none"> Action </TableCell>
                <TableCell> Name </TableCell>
                <TableCell> Basic Pay </TableCell>
                {headerList.data.map((data) => (
                  <TableCell key={data.id}> {data.header} </TableCell>
                ))}
                <TableCell> Total Earnings </TableCell>
                <TableCell> Total Deduction </TableCell>
                <TableCell> Net Pay </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {employee.data.map((data, index) => (
                <TableRow key={index} className={`userrow-${data.user_id}`}>
                  <TableCell className="d-print-none">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setSelectedEmployeeId(data.user_id);
                        setEmployeeAddDialog(true);
                      }}
                    >
                      <AddCircle fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {data._account_name}
                  </TableCell>
                  {/* <TableCell>{data.rateClassification}</TableCell> */}

                  <TableCell align="right">
                    {Notify.convertToNumber(
                      data.rateClassification === "Daily"
                        ? parseFloat(data.dailySalary) *
                            parseFloat(data._totalDays)
                        : parseFloat(data.monthlySalary) / 2
                    )}
                  </TableCell>

                  {checkidssss(data.user_id)}
                  {isLoadPayrollContent && (
                    <Fragment>
                      <TableCell align="right">
                        {Notify.convertToNumber(
                          CalculateEarningTotalSum(
                            parseFloat(data._totalEarning) +
                              parseFloat(
                                data.rateClassification === "Daily"
                                  ? parseFloat(data.dailySalary) *
                                      parseFloat(data._totalDays)
                                  : parseFloat(data.monthlySalary) / 2
                              )
                          )
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {Notify.convertToNumber(
                          CalculateDeductionTotalSum(
                            parseFloat(data._totalDeduction)
                          )
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {Notify.convertToNumber(
                          CalculateTotalTotalSum(
                            parseFloat(data._totalEarning) +
                              parseFloat(
                                data.rateClassification === "Daily"
                                  ? parseFloat(data.dailySalary) *
                                      parseFloat(data._totalDays)
                                  : parseFloat(data.monthlySalary) / 2
                              ) -
                              parseFloat(data._totalDeduction)
                          )
                        )}
                      </TableCell>
                    </Fragment>
                  )}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className="d-print-none" />
                <TableCell colSpan={headerList.data.length + 2} align="right">
                  Grand Total:
                </TableCell>
                <TableCell align="right">
                  {Notify.convertToNumber(total_earning)}
                </TableCell>
                <TableCell align="right">
                  {Notify.convertToNumber(total_deduction)}
                </TableCell>
                <TableCell align="right">
                  {Notify.convertToNumber(total_total)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Dialog
          open={employeeAddDialog}
          onClose={() => setEmployeeAddDialog(false)}
        >
          <DialogTitle className="d-print-none"> New Record</DialogTitle>
          <form onSubmit={handleAddPaymentDeduction}>
            <DialogContent dividers>
              <Box mb={2}>
                <Select
                  options={headerList.data}
                  name="header"
                  onChange={(data) => {
                    setSelectedHeaderCat(data.category);
                  }}
                  menuPortalTarget={document.body}
                  styles={selectStyle}
                />
              </Box>

              <Box>
                <TextField
                  label="Amount"
                  name="amount"
                  fullWidth
                  variant="outlined"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setEmployeeAddDialog(false)}
                variant="contained"
                color={"default"}
                className="d-print-none"
                startIcon={<Cancel />}
              >
                No
              </Button>
              <Button
                variant="contained"
                color={"primary"}
                type="submit"
                className="d-print-none"
                disabled={isPorcess}
                startIcon={
                  isPorcess ? (
                    <CircularProgress size={23} color="inherit" />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </DialogContent>
    </>
  );
};

export default PayrollSummary;
