import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useCallback, useState, Fragment } from "react";
import Notify from "../notification/Notify";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";
import { UsersData } from "../ContextAPI";
import Container from "../layout/Container";
import { Search } from "react-feather";

const Report = () => {
  const { users } = React.useContext(UsersData);
  const [order, setOrder] = useState([]);

  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const themes = useTheme();
  const [pageLoaded, setPageLoaded] = useState(false);

  const getAudiometryOrder = useCallback(() => {
    var params = { user_id: users.user_id, table: "psychology_audiometry" };
    Axios.get("psychology/order/completed/report", { params })
      .then((response) => {
        const data = response.data;
        setOrder((order) => order.concat(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users]);

  const getIshiharaOrder = useCallback(() => {
    var params = { user_id: users.user_id, table: "psychology_ishihara" };
    Axios.get("psychology/order/completed/report", { params })
      .then((response) => {
        const data = response.data;
        setOrder((order) => order.concat(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() =>
        setTimeout(() => {
          setPageLoaded(true);
        }, 3000)
      );
  }, [users]);

  const getNeurologyOrder = useCallback(() => {
    var params = { user_id: users.user_id, table: "psychology_neuroexam" };
    Axios.get("psychology/order/completed/report", { params })
      .then((response) => {
        const data = response.data;
        setOrder((order) => order.concat(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() =>
        setTimeout(() => {
          setPageLoaded(true);
        }, 3000)
      );
  }, [users]);

  const getAudiometryOrderByReport = useCallback(
    (dateFrom, dateTo) => {
      var params = {
        user_id: users.user_id,
        date_from: dateFrom,
        date_to: dateTo,
        table: "psychology_audiometry",
      };
      Axios.get("psychology/order/completed/report", { params })
        .then((response) => {
          const data = response.data;
          setOrder((order) => order.concat(data));
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    },
    [users]
  );

  const getIshiharaOrderByReport = useCallback(
    (dateFrom, dateTo) => {
      var params = {
        user_id: users.user_id,
        date_from: dateFrom,
        date_to: dateTo,
        table: "psychology_ishihara",
      };
      Axios.get("psychology/order/completed/report", { params })
        .then((response) => {
          const data = response.data;
          setOrder((order) => order.concat(data));
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    },
    [users]
  );

  const getNeurologyOrderByReport = useCallback(
    (dateFrom, dateTo) => {
      var params = {
        user_id: users.user_id,
        date_from: dateFrom,
        date_to: dateTo,
        table: "psychology_neuroexam",
      };
      Axios.get("psychology/order/completed/report", { params })
        .then((response) => {
          const data = response.data;
          setOrder((order) => order.concat(data));
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    },
    [users]
  );

  useEffect(() => {
    getAudiometryOrder();
    getIshiharaOrder();
    getNeurologyOrder();
  }, [getAudiometryOrder, getIshiharaOrder, getNeurologyOrder]);

  const handleSearch = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const applyPagination = (doctors, page, limit) => {
    return doctors.slice(page * limit, page * limit + limit);
  };

  const searchOrder = (searchOrder, query) => {
    return searchOrder.filter((data) => {
      let matches = true;

      if (query) {
        let containsQuery = false;

        if (
          data.fname.toLowerCase().includes(query.toLowerCase()) ||
          data.lname.toLowerCase().includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }

        if (!containsQuery) {
          matches = false;
        }
      }
      return matches;
    });
  };

  const handleReportByReport = (e) => {
    e.preventDefault();
    e.persist();

    const formdata = new FormData(e.target);
    const dateFrom = formdata.get("date_from");
    const dateTo = formdata.get("date_to");

    if (
      formdata.get("date_from").trim() === "" ||
      formdata.get("date_to").trim() === ""
    ) {
      Notify.customToast("Date Invalid", "Date is a required.");
      return false;
    }

    setOrder([]);
    getAudiometryOrderByReport(dateFrom, dateTo);
    getIshiharaOrderByReport(dateFrom, dateTo);
    getNeurologyOrderByReport(dateFrom, dateTo);
  };

  const filteredOrder = searchOrder(order, query);
  const paginateOrder = applyPagination(filteredOrder, page, limit);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "report",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="Reports"
      >
        <Box component={Paper} p={2}>
          <Box my={1} display="flex">
            <Box flexGrow={1}>
              <Box maxWidth={300}>
                <TextField
                  fullWidth
                  label="Search Patient"
                  variant="outlined"
                  onChange={handleSearch}
                  value={query}
                />
              </Box>
            </Box>

            <Box>
              <form onSubmit={handleReportByReport}>
                <Box display="flex">
                  <Box>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="From"
                      name="date_from"
                    />
                  </Box>

                  <Box mx={2}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="To"
                      name="date_to"
                    />
                  </Box>
                  <Box>
                    <IconButton
                      type="submit"
                      style={{
                        backgroundColor: themes.palette.primary.main,
                        color: "#fff",
                      }}
                    >
                      <Search />
                    </IconButton>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b> Date </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Patient </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Order Id </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Actions </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageLoaded ? (
                  order.length > 0 && <RenderTable data={paginateOrder} />
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="primary">
                        Loading, Please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component={Box}
              count={filteredOrder.length}
              labelRowsPerPage="List"
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        </Box>
      </Container>
    </Fragment>
  );
};

export default Report;

const RenderTable = ({ data }) => {
  const history = useHistory();
  return (
    <>
      {data
        .sort((a, b) => {
          return (
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          );
        })
        .map((data, index) => (
          <TableRow key={index}>
            <TableCell>{Notify.dateTimeConvert(data.created_at)} </TableCell>
            <TableCell align="center" className={`gtc-capitalize`}>
              {`${data.fname} ${data.lname}`}
            </TableCell>
            <TableCell align="center">{data.trace_number}</TableCell>
            <TableCell align="center">
              <IconButton
                onClick={() =>
                  history.push(
                    `/bmcdc/app/psychology/record/print/order/${data.trace_number}`
                  )
                }
                color="primary"
              >
                <ArrowForward />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};
