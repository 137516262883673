import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import axios from "axios";
import {
  KeyboardBackspace,
  // SaveAltSharp,
  AddCircleOutline,
} from "@material-ui/icons";
import { UsersData, UsersHeader } from "src/ContextAPI";
import * as Yup from "yup";
import { Formik } from "formik";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const PatientRegNewCT = ({
  patient_id,
  getDoctorsPatient,
  getPatientInformation,
  info,
}) => {
  // const [sicknessArray, setSicknessArray] = useState([]);
  // const [tempNew, setTempNew] = useState(null);
  const [addCTracing, setAddCTracing] = useState("latest-record");
  // const [processSave, setProcessSave] = useState(false);
  const { users } = useContext(UsersData);
  const { sidebarHeader } = React.useContext(UsersHeader);

  const sickness = [
    "Dry cough",
    "Fever",
    "Shortness of breath",
    "Difficulty of breathing",
    "Sore throat",
    "Headache",
    "Nasal congestions",
    "Diarrhea",
    "Colds",
    "Fatigue or body malaise",
  ];

  // const handleChangeSickness = (e) => {
  //   if (sicknessArray.includes(e.target.value)) {
  //     let removeOneArray = sicknessArray.filter(
  //       (item) => item !== e.target.value
  //     );
  //     setSicknessArray(removeOneArray);
  //   } else {
  //     setSicknessArray(sicknessArray.concat(e.target.value));
  //   }
  // };

  const handleChangeSickness = (e, setFieldValue, values) => {
    let test = values.sickness;

    if (test.includes(e.target.value)) {
      let testx = test.filter((item) => item !== e.target.value);
      setFieldValue("sickness", testx);
    } else {
      setFieldValue("sickness", test.concat(e.target.value));
    }
  };

  // const handleSaveNewCTracingForm = (e) => {
  //   e.preventDefault();
  //   e.persist();
  //   var error = [];
  //   var formdata = new FormData();
  //   formdata.set("patient_id", patient_id);
  //   formdata.append("sickness", sicknessArray);
  //   formdata.append("new_temp", tempNew);

  //   if (tempNew.length === 0) {
  //     error = "error";
  //     Notify.fieldRequired("temperature");
  //   }
  //   if (error.length > 0) {
  //     Notify.consoleLog("contract tracing form");
  //   } else {
  //     setProcessSave(true);
  //     axios
  //       .post("triage/add/contact-tracing/reviews", formdata)
  //       .then((response) => {
  //         const data = response.data;
  //         setProcessSave(false);
  //         if (data === "success") {
  //           setSicknessArray([]);
  //           setTempNew(null);
  //           setAddCTracing("latest-record");
  //           getPatientInformation();
  //           Notify.successRequest("add new contact tracing");
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //         setProcessSave(false);
  //       });
  //   }
  // };

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Box component={Paper} variant="outlined" mt={2} p={2}>
      <Box>
        {info.ready ? (
          info.data ? (
            <Box>
              <Box>
                <Box mb={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box flexGrow={1}>
                      <Typography variant="h6" color="textSecondary">
                        Contact Tracing Form
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        startIcon={
                          addCTracing === "latest-record" ? (
                            <AddCircleOutline />
                          ) : (
                            <KeyboardBackspace />
                          )
                        }
                        color="primary"
                        variant="contained"
                        // disabled={info.data.latestCCRecord !== null}
                        onClick={() => {
                          setAddCTracing(
                            addCTracing === "add-ctracing"
                              ? "latest-record"
                              : "add-ctracing"
                          );
                          // setSicknessArray([]);
                        }}
                      >
                        {addCTracing === "add-ctracing" ? "Back" : "New"}
                      </Button>
                    </Box>
                  </Box>
                </Box>

                {addCTracing === "latest-record" ? (
                  <Fragment>
                    {info.data.latestCCRecord !== null && (
                      <Box mb={1}>
                        <Typography variant="subtitle2" color="secondary">
                          This is the latest contact tracing recorded last{" "}
                          {Notify.createdAt(info.data.latestCCRecord)}
                        </Typography>
                      </Box>
                    )}
                    <Grid container spacing={1}>
                      {info.data.sickness !== null &&
                        sickness.map((data, index) =>
                          info.data.sickness.split(",").map(
                            (data2, index2) =>
                              Boolean(data2 === data) && (
                                <Grid key={index2} item xs={12} sm={6} md={4}>
                                  <Box>
                                    <FormControl component="fieldset">
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="gilad"
                                              defaultChecked={true}
                                            />
                                          }
                                          label={data}
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              )
                          )
                        )}
                      {info.data.temperature !== null && (
                        <Grid item xs={12} sm={12}>
                          <Box>
                            <TextField
                              value={
                                info.data.temperature !== null
                                  ? info.data.temperature
                                  : ""
                              }
                              fullWidth
                              required
                              name="temp"
                              label="Temperature"
                              variant="outlined"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 10000,
                                  step: 0.01,
                                },
                                readOnly: true,
                              }}
                            />
                          </Box>
                        </Grid>
                      )}
                      {info.data.temperature === null &&
                        info.data.sickness === null && (
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {Notify.noRecord()}
                            </Box>
                          </Grid>
                        )}
                    </Grid>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Formik
                      initialValues={{
                        username: users.username,
                        user_id: users.user_id,
                        management_id: users.management_id,
                        main_mgmt_id: users.main_mgmt_id,
                        patient_id: patient_id,
                        // password: "",
                        temp: "",
                        sickness: [],
                        purpose_ofvisit: "",
                        allergies: "",
                        last_xray_check: "",
                        last_xray_result: "",
                        history_oftravel_date: "",
                        history_oftravel_days: "",
                        history_oftravel_place: "",
                        contact_withpuipum: "",
                        contact_withpositive: "",
                        crt_purpose: "",
                        requested_by: "",
                        complete_company: "",
                        contact_information: "",
                        quarantine_ifpositive: "",
                        triage_staff: sidebarHeader.name,
                        transaction_type: "",
                        company: info.data && info.data.company,
                      }}
                      validationSchema={Yup.object().shape({
                        // password: Yup.string().trim().required("Password is required."),
                        temp: Yup.string()
                          .trim()
                          .required("Temperature is required."),
                        purpose_ofvisit: Yup.string()
                          .trim()
                          .required("Purpose is required."),
                        transaction_type: Yup.string()
                          .trim()
                          .required("Transaction type is required."),
                      })}
                      onSubmit={async (
                        values,
                        { setErrors, setSubmitting, resetForm, setFieldValue }
                      ) => {
                        try {
                          const request = await axios.post(
                            "admission/patients/newcensus-save",
                            getFormData(values)
                          );

                          // if (request.data === "pass-invalid") {
                          //   setErrors({ password: "Password is invalid." });
                          //   Notify.fieldInvalid("password");
                          // }

                          if (request.data === "success") {
                            Notify.successRequest("new patient");
                            resetForm();
                            setFieldValue("sickness", []);
                            setSubmitting(true);
                            getDoctorsPatient();
                          }
                        } catch (error) {
                          const message =
                            error.message || "Something went wrong";
                          setErrors({ submit: message });
                          setSubmitting(false);
                        }
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        resetForm,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box>
                            <Box my={3}>
                              <Grid container spacing={1}>
                                {sickness.map((data, index) => (
                                  <Grid key={index} item xs={12} sm={6} md={4}>
                                    <Box>
                                      <FormControl component="fieldset">
                                        <FormGroup>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                name="gilad"
                                                value={data}
                                                onChange={(e) =>
                                                  handleChangeSickness(
                                                    e,
                                                    setFieldValue,
                                                    values
                                                  )
                                                }
                                              />
                                            }
                                            label={data}
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </Box>

                          <Box mb={2}>
                            <TextField
                              error={Boolean(touched.temp && errors.temp)}
                              helperText={touched.temp && errors.temp}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.temp}
                              name="temp"
                              fullWidth
                              required
                              label="Temperature"
                              variant="outlined"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 10000,
                                  step: 0.01,
                                },
                              }}
                            />
                          </Box>

                          <Box mb={2}>
                            <TextField
                              label={"Purpose of Visit/Medical Concerns/issues"}
                              variant="outlined"
                              fullWidth
                              error={Boolean(
                                touched.purpose_ofvisit &&
                                  errors.purpose_ofvisit
                              )}
                              helperText={
                                touched.purpose_ofvisit &&
                                errors.purpose_ofvisit
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.purpose_ofvisit}
                              name="purpose_ofvisit"
                            />
                          </Box>

                          <Box mb={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4} md={4}>
                                <Box>
                                  <TextField
                                    label={"Any Allergies"}
                                    fullWidth
                                    variant="outlined"
                                    error={Boolean(
                                      touched.allergies && errors.allergies
                                    )}
                                    helperText={
                                      touched.allergies && errors.allergies
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.allergies}
                                    name="allergies"
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <Box>
                                  <TextField
                                    label={"Last Check Xray Taken"}
                                    fullWidth
                                    variant="outlined"
                                    error={Boolean(
                                      touched.last_xray_check &&
                                        errors.last_xray_check
                                    )}
                                    helperText={
                                      touched.last_xray_check &&
                                      errors.last_xray_check
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.last_xray_check}
                                    name="last_xray_check"
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <Box>
                                  <TextField
                                    label={"Result of Cxray"}
                                    fullWidth
                                    variant="outlined"
                                    error={Boolean(
                                      touched.last_xray_result &&
                                        errors.last_xray_result
                                    )}
                                    helperText={
                                      touched.last_xray_result &&
                                      errors.last_xray_result
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.last_xray_result}
                                    name="last_xray_result"
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box mb={2}>
                            <Box mb={1}>
                              <Typography>
                                History of Travel: When and where
                              </Typography>
                            </Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4} md={4}>
                                <Box>
                                  <TextField
                                    error={Boolean(
                                      touched.history_oftravel_date &&
                                        errors.history_oftravel_date
                                    )}
                                    helperText={
                                      touched.history_oftravel_date &&
                                      errors.history_oftravel_date
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.history_oftravel_date}
                                    fullWidth
                                    name="history_oftravel_date"
                                    label="Date"
                                    variant="outlined"
                                    type="date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <Box>
                                  <TextField
                                    error={Boolean(
                                      touched.history_oftravel_days &&
                                        errors.history_oftravel_days
                                    )}
                                    helperText={
                                      touched.history_oftravel_days &&
                                      errors.history_oftravel_days
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.history_oftravel_days}
                                    fullWidth
                                    name="history_oftravel_days"
                                    label="Days"
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                      inputProps: {
                                        min: 1,
                                        max: 10000,
                                        step: 1,
                                      },
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <Box>
                                  <TextField
                                    label={"Place"}
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    error={Boolean(
                                      touched.history_oftravel_place &&
                                        errors.history_oftravel_place
                                    )}
                                    helperText={
                                      touched.history_oftravel_place &&
                                      errors.history_oftravel_place
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.history_oftravel_place}
                                    name="history_oftravel_place"
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box mb={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6}>
                                <Box>
                                  <TextField
                                    label={"Any contact with PUI to PUM"}
                                    fullWidth
                                    variant="outlined"
                                    select
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    error={Boolean(
                                      touched.contact_withpuipum &&
                                        errors.contact_withpuipum
                                    )}
                                    helperText={
                                      touched.contact_withpuipum &&
                                      errors.contact_withpuipum
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.contact_withpuipum}
                                    name="contact_withpuipum"
                                  >
                                    <option value=""> SELECT </option>
                                    <option value="No"> No </option>
                                    <option value="Yes"> Yes </option>
                                  </TextField>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Box>
                                  <TextField
                                    label={
                                      "Any contact with positive covid patient"
                                    }
                                    fullWidth
                                    variant="outlined"
                                    select
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    error={Boolean(
                                      touched.contact_withpositive &&
                                        errors.contact_withpositive
                                    )}
                                    helperText={
                                      touched.contact_withpositive &&
                                      errors.contact_withpositive
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.contact_withpositive}
                                    name="contact_withpositive"
                                  >
                                    <option value=""> SELECT </option>
                                    <option value="No"> No </option>
                                    <option value="Yes"> Yes </option>
                                  </TextField>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box>
                            <Typography>FOR CRT Clients: </Typography>
                          </Box>

                          <Box my={2}>
                            <TextField
                              label={"Purpose of your request for CRT."}
                              fullWidth
                              variant="outlined"
                              multiline
                              error={Boolean(
                                touched.crt_purpose && errors.crt_purpose
                              )}
                              helperText={
                                touched.crt_purpose && errors.crt_purpose
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.crt_purpose}
                              name="crt_purpose"
                            />
                          </Box>

                          <Box my={2}>
                            <TextField
                              label={"Requested by"}
                              fullWidth
                              variant="outlined"
                              multiline
                              error={Boolean(
                                touched.requested_by && errors.requested_by
                              )}
                              helperText={
                                touched.requested_by && errors.requested_by
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.requested_by}
                              name="requested_by"
                            />
                          </Box>

                          <Box my={2}>
                            <TextField
                              label={
                                "If for return to work, complete company name"
                              }
                              fullWidth
                              variant="outlined"
                              multiline
                              error={Boolean(
                                touched.complete_company &&
                                  errors.complete_company
                              )}
                              helperText={
                                touched.complete_company &&
                                errors.complete_company
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.complete_company}
                              name="complete_company"
                            />
                          </Box>

                          <Box my={2}>
                            <TextField
                              label={
                                "Complete contact person, designation & numbers"
                              }
                              fullWidth
                              variant="outlined"
                              multiline
                              error={Boolean(
                                touched.contact_information &&
                                  errors.contact_information
                              )}
                              helperText={
                                touched.contact_information &&
                                errors.contact_information
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.contact_information}
                              name="contact_information"
                            />
                          </Box>

                          <Box>
                            <Typography>
                              If found to be positive of either IgG or IgM, are
                              you willing to undergo quarantine or Swab testing
                              testing (RT-PCR)?
                            </Typography>
                          </Box>

                          <Box my={2}>
                            <TextField
                              label={"Select"}
                              fullWidth
                              variant="outlined"
                              select
                              SelectProps={{ native: true }}
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(
                                touched.quarantine_ifpositive &&
                                  errors.quarantine_ifpositive
                              )}
                              helperText={
                                touched.quarantine_ifpositive &&
                                errors.quarantine_ifpositive
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.quarantine_ifpositive}
                              name="quarantine_ifpositive"
                            >
                              <option value=""> SELECT </option>
                              <option value="No"> No </option>
                              <option value="Yes"> Yes </option>
                            </TextField>
                          </Box>

                          <Box my={3}>
                            <Typography color="textSecondary">
                              I hereby authorize BMCDC/BMCDC Health Care
                              Inc./BMCDC Medical Station Co. Inc. to collect and
                              process the data indicated herein for the purpose
                              of effecting conroil of the COVID 19 infection. I
                              understand that my personal information is
                              protected by RA 10173, Data PrivacyAct of 2012 and
                              that I am required by RA 11469 Bayanihan to Heal
                              as One Act. to provide truthful information.
                            </Typography>
                          </Box>

                          <Box my={2}>
                            <TextField
                              label={"Triage staff"}
                              fullWidth
                              variant="outlined"
                              multiline
                              error={Boolean(
                                touched.triage_staff && errors.triage_staff
                              )}
                              helperText={
                                touched.triage_staff && errors.triage_staff
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.triage_staff}
                              name="triage_staff"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Box>

                          <Box my={2}>
                            <TextField
                              label={"Transaction Type"}
                              fullWidth
                              variant="outlined"
                              select
                              SelectProps={{ native: true }}
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(
                                touched.transaction_type &&
                                  errors.transaction_type
                              )}
                              helperText={
                                touched.transaction_type &&
                                errors.transaction_type
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.transaction_type}
                              name="transaction_type"
                            >
                              <option value=""> SELECT </option>
                              <option
                                value="reg-endorse"
                                disabled={
                                  values.company === null ? true : false
                                }
                              >
                                Corporate
                              </option>
                              <option value="reg-cashier">
                                Walk-In(lab/x-ray)
                              </option>
                              <option value="reg-cashier-hmo">
                                Walk-In HMO
                              </option>
                              <option value="reg-doctor">
                                Walk-In Appointment
                              </option>
                              {/* <option value="reg-doctor">
                                {" "}
                                Walk-in Appointment{" "}
                              </option> */}
                            </TextField>
                          </Box>

                          {errors.submit && (
                            <Box mt={3}>
                              <FormHelperText error>
                                {errors.submit}
                              </FormHelperText>
                            </Box>
                          )}

                          <Box mb={2} display="flex">
                            <Box flexGrow={1} />

                            <Button
                              variant="contained"
                              color="default"
                              onClick={resetForm}
                              startIcon={<ClearIcon />}
                            >
                              Clear
                            </Button>
                            <Box ml={2}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={
                                  isSubmitting ? (
                                    <CircularProgress
                                      size={20}
                                      color="inherit"
                                    />
                                  ) : (
                                    <CheckIcon />
                                  )
                                }
                                disabled={isSubmitting}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                        </form>
                      )}
                    </Formik>

                    {/* <Grid container spacing={1}>
                      {sickness.map((data, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4}>
                          <Box>
                            <FormControl component="fieldset">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="gilad"
                                      value={data}
                                      onChange={(e) => handleChangeSickness(e)}
                                    />
                                  }
                                  label={data}
                                />
                              </FormGroup>
                            </FormControl>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        required
                        name="temp"
                        label="Temperature"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 10000,
                            step: 0.01,
                          },
                        }}
                        onChange={(e) =>
                          setTempNew(
                            e.target.value === "" ? null : e.currentTarget.value
                          )
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <Box display="flex">
                        <Box flexGrow={1} />
                        <Box ml={1} hidden={addCTracing === "latest-record"}>
                          <Button
                            startIcon={<SaveAltSharp />}
                            color="primary"
                            variant="contained"
                            onClick={handleSaveNewCTracingForm}
                            disabled={tempNew === null || processSave}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box> */}
                  </Fragment>
                )}
              </Box>
            </Box>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>
    </Box>
  );
};

export default PatientRegNewCT;
