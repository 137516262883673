import {
  Box,
  Grid,
  List,
  Typography,
  Paper,
  ListItem,
  ListItemText,
  makeStyles,
  Avatar,
  ListItemIcon,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import React, { Fragment, useContext, useState } from "react";
import { UsersHeader } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import BillingDetails from "../billing/BillingDetails";
import Report from "../billing/Report";
import SearchIcon from "@material-ui/icons/Search";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const PatientQueue = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [stateOpenDetails, setStateOpenDetails] = useState("all-orders");

  const {
    queue: { patient, getPatientsOnQueue },
  } = useContext(UsersHeader);

  const [selectedUnpaid, setSelectedUnpaid] = useState({
    data: null,
    ready: false,
  });

  const searchable = patient.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Patient on Queue",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title={"Patient on Queue"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Box component={Paper} variant="outlined" p={2}>
              <Box mb={2}>
                <Typography
                  color="primary"
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                >
                  Unpaid Bill
                </Typography>
              </Box>

              <Box>
                <TextField
                  label="Search for patient"
                  variant="outlined"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <List component="div">
                  {searchable.length > 0
                    ? searchable.map((data, index) => (
                        <ListItem
                          button
                          key={index}
                          className="mb-2"
                          selected={
                            selectedUnpaid.data &&
                            selectedUnpaid.data.patient_id === data.patient_id
                          }
                          onClick={() => {
                            setStateOpenDetails("patient-order");
                            setSelectedUnpaid({
                              data: data,
                              ready: true,
                            });
                          }}
                        >
                          <ListItemIcon>
                            {data.image === null ? (
                              <Avatar className={classes.primary}>
                                {data.lastname.charAt().toUpperCase()}
                              </Avatar>
                            ) : (
                              <Avatar
                                className={classes.primary}
                                src={`${imageLocation}patients/${data.image}`}
                                alt=""
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            className={`gtc-capitalize`}
                            primary={`${data.lastname}, ${data.firstname}`}
                            secondary={Notify.dateTimeConvert(data.created_at)}
                          />
                        </ListItem>
                      ))
                    : Notify.noRecord()}
                </List>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={9}>
            {stateOpenDetails === "all-orders" && <Report />}
            {stateOpenDetails === "patient-order" && (
              <BillingDetails
                details={selectedUnpaid.data}
                getAllCashierOnQueue={getPatientsOnQueue}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default PatientQueue;
