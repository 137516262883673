import { Box, Paper, TextField, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import ChartistGraph from "react-chartist";
import axios from "axios";
import Notify from "../../notification/Notify";
import IsMountedRef from "../../utils/IsMountedRef";
import { UsersData } from "../../ContextAPI";

const IncomeGraph = () => {
  const ismounted = IsMountedRef();
  const [year, setYear] = useState([new Date().getFullYear()]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [series, setSeries] = useState({ data: [], ready: false });
  const { users } = useContext(UsersData);

  const getIncomeReport = async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
        year: selectedYear,
      };
      const income = await axios.get("hmis/income/report/bymonth", { params });
      const data = income.data;
      if (ismounted.current) {
        if (data.length > 0) {
          setSeries({
            data: [
              data[0].jan_income,
              data[0].feb_income,
              data[0].mar_income,
              data[0].apr_income,
              data[0].may_income,
              data[0].jun_income,
              data[0].jul_income,
              data[0].aug_income,
              data[0].sep_income,
              data[0].oct_income,
              data[0].nov_income,
              data[0].dec_income,
            ],
            ready: true,
          });
        } else {
          setSeries({
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            ready: true,
          });
        }
      }
    } catch (error) {
      console.log(error);
      // Notify.requestError(error);
    }
  };

  const label = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const mobileLabel = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const data = {
    labels: window.innerWidth > 600 ? label : mobileLabel,
    series: [series],
  };

  const options = {
    fullWidth: true,
    showArea: true,
    height: window.innerWidth > 600 ? 500 : 200,
  };

  const type = "Line";

  const generateYear = () => {
    if (ismounted.current) {
      for (let i = 2020; i <= year; i++) {
        setYear((year) => year.concat(i++));
      }
    }
  };

  React.useEffect(() => {
    getIncomeReport();

    setTimeout(() => {
      generateYear();
    }, 2000);

    return () => setSeries({ data: [], ready: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, ismounted, users]);

  return (
    <Box>
      <Paper component={Box} mt={2} p={1} elevation={2}>
        <Box display="flex" m={1}>
          <Box flexGrow={1}>
            <Typography variant="h6" color="textSecondary">
              Income Monitoring
            </Typography>
          </Box>

          <Box>
            <TextField
              select
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              name="gender"
              label="Year"
              variant="outlined"
              margin="dense"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              {year.map((data, index) => (
                <option key={index} value={data}>
                  {" "}
                  {data}{" "}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>

        {series.ready ? (
          <ChartistGraph data={data} options={options} type={type} />
        ) : (
          Notify.loading()
        )}
      </Paper>
    </Box>
  );
};

export default IncomeGraph;
