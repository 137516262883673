import { Grid } from "@material-ui/core";
import React from "react";
import BranchGrandInventory from "./branches-card/BranchGrandInventory";
import BranchCardReceivable from "./branches-card/BranchCardReceivable";
import BranchCardSales from "./branches-card/BranchCardSales";
import BranchCardCollection from "./branches-card/BranchCardCollection";
import BranchCardExpense from "./branches-card/BranchCardExpense";

const BranchCard = ({ selectedBranch }) => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12}>
        <BranchGrandInventory selectedBranch={selectedBranch} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <BranchCardSales selectedBranch={selectedBranch} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <BranchCardReceivable selectedBranch={selectedBranch} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <BranchCardCollection selectedBranch={selectedBranch} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <BranchCardExpense selectedBranch={selectedBranch} />
      </Grid>
    </Grid>
  );
};

export default BranchCard;
