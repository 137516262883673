import React, { useState, Fragment } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  IconButton,
  CardMedia,
  Grid,
  TextField,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Hidden,
  Tooltip,
  Divider,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import PerfectScrollbar from "react-perfect-scrollbar";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PostAddIcon from "@material-ui/icons/PostAdd";
import DraggableDialog from "src/utils/DraggableDialog";
import RadiologistPatientForReviewFindings from "./RadiologistPatientForReviewFindings";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const RadiologistPatientDetailsOrder = ({ details, resetDisplay }) => {
  return (
    <Box className={`gtc-textfield-noborder`}>
      <Box mb={2}>
        <Typography variant="subtitle2" className={`gtc-uppercase`}>
          Order Details
        </Typography>
      </Box>
      <Box>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Patient"
            variant="outlined"
            value={details.patient_name}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            label="Gender"
            variant="outlined"
            value={
              details.patient_gender === null ? "None" : details.patient_gender
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            label="Birthday"
            variant="outlined"
            value={
              details.patient_birthday === null
                ? "None"
                : Notify.birthday(details.patient_birthday)
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            label="Address"
            variant="outlined"
            value={
              details.patient_address === null
                ? "None"
                : details.patient_address
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            label="Imaging Order"
            variant="outlined"
            value={details.imaging_order}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box>
          <Box mb={2}>
            <Typography variant="subtitle2" className={`gtc-uppercase`}>
              Attachments
            </Typography>
          </Box>

          <Box mb={2}>
            {details.imaging_result_attachment === null && (
              <Typography color="error"> No attachment found. </Typography>
            )}
            {details.imaging_result_attachment !== null && (
              <RenderAttachment
                details={details}
                attachment={details.imaging_result_attachment}
                resetDisplay={resetDisplay}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RadiologistPatientDetailsOrder;

const RenderAttachment = ({ details, attachment, resetDisplay }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [compareFstImg, setCompareFstImg] = useState(null);
  const [compareSndImg, setCompareSndImg] = useState(null);
  const [resultDialog, setResultDialog] = useState(false);

  const handlCompare = (e) => {
    var img = e.currentTarget.getAttribute("image");
    if (compareFstImg === null) {
      setCompareFstImg(img);
      Notify.customToast("First Image", "First image is ready");
    } else {
      Notify.customToast("Compare Image", "Compare image is ready.");
      setCompareSndImg(img);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {attachment.split(",").map((image, index) => {
          return (
            <Grid key={index} item xs={6} sm={6} md={4} lg={4}>
              <Card>
                <Box>
                  <CardMedia
                    component="img"
                    height={"150px"}
                    src={`${imageLocation}imaging/${image}`}
                    alt={image}
                  />
                </Box>
                <Box align="center" mt={1}>
                  <ButtonGroup fullWidth size="small">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setSelectedImage(image)}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      image={image}
                      onClick={handlCompare}
                    >
                      compare
                    </Button>
                  </ButtonGroup>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* dialog view */}

      <Dialog
        open={selectedImage !== null}
        onClose={() => setSelectedImage(null)}
        fullScreen
        disableEscapeKeyDown
        disableBackdropClick
      >
        <Box className="gtc-viewimage">
          <TransformWrapper defaultScale={1}>
            {({ zoomIn, zoomOut }) => (
              <>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <DialogTitle disableTypography>
                      <Typography
                        color="primary"
                        variant="h6"
                        className={`gtc-uppercase`}
                      >
                        {`Imaging Order Attachment`}
                      </Typography>
                    </DialogTitle>
                  </Box>
                  <Box>
                    <Hidden smUp>
                      <Tooltip title="Add Finding" arrow>
                        <IconButton
                          color="primary"
                          onClick={() => setResultDialog(true)}
                        >
                          <PostAddIcon />
                        </IconButton>
                      </Tooltip>
                    </Hidden>

                    <IconButton color="primary" onClick={zoomIn}>
                      <ZoomInIcon />
                    </IconButton>
                    <IconButton color="primary" onClick={zoomOut}>
                      <ZoomOutIcon />
                    </IconButton>

                    <IconButton
                      color="secondary"
                      onClick={() => setSelectedImage(null)}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </Box>

                <Divider />

                <DialogContent>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8} md={9}>
                        <Box>
                          <TransformComponent>
                            <img
                              src={
                                selectedImage !== null
                                  ? `${imageLocation}imaging/${selectedImage}`
                                  : ""
                              }
                              alt=""
                            />
                          </TransformComponent>
                        </Box>
                      </Grid>

                      <Grid item sm={4} md={3}>
                        <Box>
                          <Box mb={2}>
                            <Typography variant="subtitle1" color="textPrimary">
                              ADD FINDINGS
                            </Typography>
                          </Box>

                          <RadiologistPatientForReviewFindings
                            details={details}
                            close={() => console.log("close result dialg")}
                            resetDisplay={resetDisplay}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
              </>
            )}
          </TransformWrapper>
        </Box>
      </Dialog>

      {/* dialog compare test */}
      {compareFstImg && compareSndImg && (
        <Dialog
          open={compareFstImg && compareSndImg ? true : false}
          onClose={() => {
            setCompareFstImg(null);
            setCompareSndImg(null);
          }}
          fullScreen
        >
          <PerfectScrollbar>
            <Box className="gtc-viewimage">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TransformWrapper>
                    {({ zoomIn, zoomOut }) => (
                      <Fragment>
                        <div className="clearfix pl-1">
                          <div className="float-left m-2 mt-3">
                            <Typography color="primary" variant="subtitle2">
                              FIRST IMAGE
                            </Typography>
                          </div>
                          <div className="float-right">
                            <IconButton
                              color="primary"
                              component="span"
                              onClick={zoomIn}
                            >
                              <AddToQueueIcon />
                            </IconButton>
                            <IconButton
                              color="primary"
                              component="span"
                              onClick={zoomOut}
                            >
                              <RemoveFromQueueIcon />
                            </IconButton>
                          </div>
                        </div>
                        <TransformComponent>
                          <Box>
                            <img
                              src={imageLocation + "imaging/" + compareFstImg}
                              alt={compareFstImg}
                            />
                          </Box>
                        </TransformComponent>
                      </Fragment>
                    )}
                  </TransformWrapper>
                </Grid>
                <Grid item xs={6}>
                  <TransformWrapper>
                    {({ zoomIn, zoomOut }) => (
                      <Fragment>
                        <div className="clearfix pl-1">
                          <div className="float-left m-2 mt-3">
                            <Typography color="primary" variant="subtitle2">
                              SECOND IMAGE
                            </Typography>
                          </div>
                          <div className="float-right">
                            <Tooltip title="Add Finding" arrow>
                              <IconButton
                                color="primary"
                                onClick={() => setResultDialog(true)}
                              >
                                <PostAddIcon />
                              </IconButton>
                            </Tooltip>

                            <IconButton
                              color="primary"
                              component="span"
                              onClick={zoomIn}
                            >
                              <AddToQueueIcon />
                            </IconButton>
                            <IconButton
                              color="primary"
                              component="span"
                              onClick={zoomOut}
                            >
                              <RemoveFromQueueIcon />
                            </IconButton>
                            <IconButton
                              color="primary"
                              component="span"
                              onClick={() => {
                                setCompareFstImg(null);
                                setCompareSndImg(null);
                              }}
                            >
                              <CancelPresentationIcon />
                            </IconButton>
                          </div>
                        </div>
                        <TransformComponent>
                          <Box>
                            <img
                              src={imageLocation + "imaging/" + compareSndImg}
                              alt={compareSndImg}
                            />
                          </Box>
                        </TransformComponent>
                      </Fragment>
                    )}
                  </TransformWrapper>
                </Grid>
              </Grid>
            </Box>
          </PerfectScrollbar>
        </Dialog>
      )}

      {/* result dialog */}
      <Dialog
        open={resultDialog}
        onClose={() => setResultDialog(false)}
        disableEscapeKeyDown
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">ADD FINDINGS</DialogTitle>
        <DialogContent>
          <RadiologistPatientForReviewFindings
            details={details}
            close={() => setResultDialog(false)}
            resetDisplay={resetDisplay}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
