
import React, { Fragment, useState, useEffect, useCallback } from 'react'; 
import { Box, Paper, 
    // Typography, CircularProgress, Button, TextField, makeStyles
 } from '@material-ui/core';  
import Notify from '../../notification/Notify';
import Axios from 'axios'; 
import BMIComputation from 'src/utils/BMIComputation';
import { UsersData } from 'src/ContextAPI';


 


const HealthBMI = (props) => {  

    const [info, setInfo] = useState({ data:[] , ready: false })

    const { users } = React.useContext(UsersData)

    const getAllergies = useCallback(() =>{ 
        var formdata = new FormData()
            formdata.set('user_id', users.user_id) 
            formdata.set('patient_id', props.patient_id)
            formdata.set('connection', props.connection)

        Axios.post('doctors/patient/information/getpatient-information', formdata)
        .then((response) =>{
            const data = response.data
            setInfo({ data, ready: true }) 
        }).catch((error)=>{
            Notify.requestError(error)
        })
    },[users, props.patient_id, props.connection])
 

    useEffect(()=>{

        getAllergies()

    },[getAllergies])

    return (
        <Fragment>  
            <Box 
                component={Paper}
                elevation={2}
                p={2}
            >    
                { info.ready ? (
                    <BMIComputation 
                        weight = { 
                            info.data.length > 0 ? 
                                Boolean(parseInt(info.data[0].weight)) ? info.data[0].weight : 0
                            : 0  
                        }
                        height ={ 
                            info.data.length > 0 ? 
                                Boolean(parseInt(info.data[0].height)) ? info.data[0].height : 0
                            : 0  
                        } 
                    /> 
                ) : Notify.loading() } 
            </Box> 
        </Fragment>
    )
}

export default HealthBMI;
