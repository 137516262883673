import React, { useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import Notify from "src/notification/Notify";
import {
  Box,
  Card,
  TextField,
  Button,
  FormHelperText,
  CardHeader,
  CardContent,
  CircularProgress,
  IconButton,
  Dialog,
  useTheme,
} from "@material-ui/core";
import IsMountedRef from "src/utils/IsMountedRef";
import Select from "react-select";
import { UsersData } from "src/ContextAPI";
import SelectStyle from "src/utils/SelectStyle";
import { PlusCircle } from "react-feather";
import AddBrandDialog from "../purchaseorder/AddBrandDialog";

const AddAnotherProdAsCopyInvoice = ({
  getAllProductsByPONumber,
  po_number,
}) => {
  const mounted = IsMountedRef();
  const [product, setProduct] = useState();
  const [brand, setBrand] = useState();
  const [description, setDescription] = useState();
  const { users } = React.useContext(UsersData);
  const selectStyle = SelectStyle();
  const [selectedSort, setSelectedSort] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [addBrandDialog, setAddBrandDialog] = useState(false);
  const theme = useTheme();

  const getProductList = async () => {
    let response = await Axios.get(
      "pharmacy/warehouse/new-invoice/get-productlist",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
        },
      }
    );
    if (mounted.current) {
      setProduct(response.data);
    }
  };

  const getBrandList = async () => {
    let response = await Axios.get(
      "pharmacy/warehouse/new-invoice/get-brandlist",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
        },
      }
    );
    if (mounted.current) {
      setBrand(response.data);
    }
  };

  const handlvalueGetAllDescByBrand = async (data) => {
    let response = await Axios.get(
      "pharmacy/warehouse/new-invoice/get-desclist",
      {
        params: {
          management_id: users.management_id,
          brand: data,
        },
      }
    );
    setDescription(response.data);
  };

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  useEffect(() => {
    getProductList();
    getBrandList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [po_number]);

  return (
    <Card>
      <CardHeader title="Select item to PO" />
      <Formik
        enableReinitialize
        initialValues={{
          username: users.username,
          user_id: users.user_id,
          management_id: users.management_id,
          type: selectedType !== null ? selectedType : "",
          sort: selectedSort !== null ? selectedSort : "",
          po_number: po_number,
          product_name: "",
          selectedGeneric: "",
          selectedUnit: "",
          product_id: "",
          product_quantity: "",
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string().required("Type is a required field"),
          product_name: Yup.string().required(
            "Product name is a required field"
          ),
          selectedGeneric: Yup.string().required(
            "Product description is a required field"
          ),
          selectedUnit: Yup.string().required(
            "Product unit is a required field"
          ),
          product_quantity: Yup.string().required(
            "Product quantity is a required field"
          ),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await Axios.post(
              "pharmacy/warehouse/inventory/complete/new-po-savetotemp",
              getFormData(values)
            );
            if (request.data.message === "product-exist") {
              Notify.fieldInvalid("Product already existed");
            }
            if (request.data.message === "success") {
              Notify.successRequest("po added");
              getAllProductsByPONumber();
              resetForm();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <TextField
                  required
                  fullWidth
                  value={values.type}
                  name="type"
                  label="Select"
                  variant="outlined"
                  select
                  onChange={(e) => {
                    setFieldValue("product_name", "");
                    setFieldValue("selectedGeneric", "");
                    setFieldValue("selectedUnit", "");
                    setFieldValue("product_id", "");
                    setSelectedType(e.target.value);
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value="">Select</option>
                  <option value="existing">Existing Product</option>
                  <option value="not-existing">New Product</option>
                </TextField>
              </Box>

              {selectedType === "existing" && (
                <>
                  <Box mb={3}>
                    <TextField
                      required
                      fullWidth
                      value={values.sort}
                      name="sort"
                      label="Sort"
                      variant="outlined"
                      select
                      onChange={(e) => {
                        setFieldValue("product_name", "");
                        setFieldValue("selectedGeneric", "");
                        setFieldValue("selectedUnit", "");
                        setFieldValue("product_id", "");
                        setSelectedSort(e.target.value);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <option value="">Select</option>
                      <option value="code">Code</option>
                      <option value="brand">Brand & Description</option>
                    </TextField>
                  </Box>

                  {selectedSort === "code" && (
                    <>
                      <Box mb={3}>
                        <Select
                          options={product}
                          onChange={(value) => {
                            console.log("value", value);
                            setFieldValue("product_name", value.brandName);
                            setFieldValue(
                              "selectedGeneric",
                              value.product_generic
                            );
                            setFieldValue("selectedUnit", value.unit);
                            setFieldValue("product_id", value.product_id);
                          }}
                          placeholder="Select Product Code"
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 99999,
                              color: "#000",
                            }),
                            control: (base) => ({
                              ...base,
                              minHeight: 55,
                              backgroundColor: "transparent",
                            }),
                            singleValue: () => ({
                              color:
                                theme.palette.type === "dark" ? "#fff" : "#000",
                            }),
                          }}
                        />
                      </Box>

                      <Box mb={3}>
                        <TextField
                          value={values.product_name}
                          fullWidth
                          required
                          variant="outlined"
                          InputProps={{ readOnly: true }}
                          label="Brand"
                        />
                      </Box>

                      <Box mb={3}>
                        <TextField
                          value={values.selectedGeneric}
                          fullWidth
                          required
                          variant="outlined"
                          InputProps={{ readOnly: true }}
                          label="Description"
                        />
                      </Box>
                    </>
                  )}
                  {selectedSort === "brand" && (
                    <>
                      <Box mb={3}>
                        <Select
                          options={brand}
                          onChange={(value) => {
                            handlvalueGetAllDescByBrand(value.label);
                          }}
                          placeholder="Select Brand"
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 99999,
                              color: "#000",
                            }),
                            control: (base) => ({
                              ...base,
                              minHeight: 55,
                              backgroundColor: "transparent",
                            }),
                            singleValue: () => ({
                              color:
                                theme.palette.type === "dark" ? "#fff" : "#000",
                            }),
                          }}
                        />
                      </Box>

                      <Box mb={3}>
                        <Select
                          options={description}
                          onChange={(value) => {
                            setFieldValue("product_name", value.brandName);
                            setFieldValue(
                              "selectedGeneric",
                              value.product_generic
                            );
                            setFieldValue("selectedUnit", value.unit);
                            setFieldValue("product_id", value.product_id);
                          }}
                          placeholder="Select Description"
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 99999,
                              color: "#000",
                            }),
                            control: (base) => ({
                              ...base,
                              minHeight: 55,
                              backgroundColor: "transparent",
                            }),
                            singleValue: () => ({
                              color:
                                theme.palette.type === "dark" ? "#fff" : "#000",
                            }),
                          }}
                        />
                      </Box>
                    </>
                  )}

                  <Box my={3}>
                    <TextField
                      value={values.selectedUnit}
                      fullWidth
                      required
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                      label="Unit"
                    />
                  </Box>

                  <Box my={3}>
                    <TextField
                      label="Quantity"
                      fullWidth
                      variant="outlined"
                      type="number"
                      value={values.product_quantity}
                      name="product_quantity"
                      error={Boolean(
                        touched.product_quantity && errors.product_quantity
                      )}
                      helperText={
                        touched.product_quantity && errors.product_quantity
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Box>
                </>
              )}

              {selectedType === "not-existing" && (
                <>
                  <Box mb={2}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box flexGrow={1}>
                        <Select
                          options={brand}
                          onChange={(value) => {
                            setFieldValue("product_name", value.label);
                          }}
                          placeholder="Select Brand"
                          styles={selectStyle}
                        />
                      </Box>
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={() => setAddBrandDialog(true)}
                        >
                          <PlusCircle />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>

                  <Box mb={2}>
                    <TextField
                      error={Boolean(
                        touched.selectedGeneric && errors.selectedGeneric
                      )}
                      helperText={
                        touched.selectedGeneric && errors.selectedGeneric
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.selectedGeneric}
                      fullWidth
                      required
                      name="selectedGeneric"
                      label="Description"
                      variant="outlined"
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      error={Boolean(
                        touched.selectedUnit && errors.selectedUnit
                      )}
                      helperText={touched.selectedUnit && errors.selectedUnit}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.selectedUnit}
                      fullWidth
                      required
                      name="selectedUnit"
                      label="Unit"
                      variant="outlined"
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      error={Boolean(
                        touched.product_quantity && errors.product_quantity
                      )}
                      helperText={
                        touched.product_quantity && errors.product_quantity
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.product_quantity}
                      fullWidth
                      required
                      name="product_quantity"
                      label="Quantity"
                      variant="outlined"
                    />
                  </Box>
                </>
              )}

              {errors.submit && (
                <Box my={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box display="flex">
                <Box flexGrow={1} />
                <Button
                  size="large"
                  variant="contained"
                  color="default"
                  type="reset"
                  startIcon={<ClearIcon />}
                >
                  Clear
                </Button>
                <Box ml={2}>
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size={23} color="inherit" />
                      ) : (
                        <CheckIcon />
                      )
                    }
                    disabled={isSubmitting}
                  >
                    ADD
                  </Button>
                </Box>
              </Box>
            </form>
          </CardContent>
        )}
      </Formik>
      <Dialog open={addBrandDialog}>
        <AddBrandDialog
          getBrandList={() => getBrandList()}
          close={() => setAddBrandDialog(false)}
        />
      </Dialog>
    </Card>
  );
};

export default AddAnotherProdAsCopyInvoice;
