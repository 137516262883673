import {
  Box,
  Typography,
  CardHeader,
  Card,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const Medical = ({ patient_id }) => {
  const mounted = IsMountedRef();
  const { users } = useContext(UsersData);
  const [list, setList] = useState({ data: [], ready: false });

  const getMedicalCertificate = React.useCallback(async () => {
    var params = { user_id: users.user_id, patient_id: patient_id };
    let response = await axios.get(
      "doctors/patient/certificates/medical/list-ofmedcert",
      {
        params,
      }
    );

    if (mounted.current) {
      const data = response.data;
      setList({ data: data, ready: true });
    }
  }, [users, mounted, patient_id]);

  useEffect(() => {
    getMedicalCertificate();
  }, [getMedicalCertificate]);

  return (
    <Box>
      <Card>
        <CardHeader />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Issued At </TableCell>
                  <TableCell> Diagnosis </TableCell>
                  <TableCell> Recommendation </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {list.ready ? (
                  list.data.length > 0 ? (
                    list.data.map((data, key) => (
                      <TableRow key={key}>
                        <TableCell>
                          {Notify.createdAt(data.issued_at)}
                        </TableCell>
                        <TableCell> {data.diagnosis_findings} </TableCell>
                        <TableCell>
                          {data.recommendation === null
                            ? "None"
                            : data.recommendation}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <Typography color="primary">
                        Loading, Please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Medical;
