import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import PharmacyDashboard from "../pharmacy/PharmacyDashboard";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersData, PharmacyRoleAndId, UsersHeader } from "../ContextAPI";
import {
  Home,
  Users,
  Monitor,
  DollarSign,
  FileText,
  Archive,
  GitPullRequest,
} from "react-feather";
import Receipt from "../pharmacy/receipt/Receipt";
import ReceiptPrint from "../pharmacy/receipt/ReceiptPrint";
import Stock from "../pharmacy/stock/Stock";
import Sales from "../pharmacy/sales/Sales";
import Inventory from "../pharmacy/inventory/Inventory";
import Logs from "../pharmacy/logs/Logs";
import Account from "../pharmacy/account/Account";
import BasicInfo from "../pharmacy/account/BasicInfo";
import PharmacyQRCode from "../pharmacy/account/PharmacyQRCode";
import LoginInfo from "../pharmacy/account/LoginInfo";
import Virtual from "../pharmacy/virtual/Virtual";
import CheckInternet from "../utils/CheckInternet";
import LocalOrder from "src/pharmacy/localorder";
import { ContactMail } from "@material-ui/icons";
import LeaveForm from "src/pharmacy/leave/LeaveForm";

var interval = null;

const RoutePharmacy = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);
  const [badgeData, setBadgeData] = useState([]);

  const [userRoleAndId, setUserRoleAndId] = useState({
    role: null,
    pharmacy_id: null,
    pharmacy_category: null,
    ready: false,
  });

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("pharmacy/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const fetchRole = async () => {
    try {
      var params = {
        user_id: userContext.users.user_id,
        management_id: userContext.users.management_id,
      };
      const response = await axios.get("pharmacy/get-spec-role", { params });
      setUserRoleAndId({
        ...userRoleAndId,
        role: response.data.role,
        pharmacy_id: response.data.pharmacy_id,
        pharmacy_category: response.data.pharmacy_category,
        ready: true,
      });

      checkinternet(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const fetchPrescription = async (pharmacyDetails) => {
    try {
      var params = {
        user_id: userContext.users.user_id,
        management_id: userContext.users.management_id,
        pharmacy_id: pharmacyDetails.pharmacy_id,
        connection: "online",
      };
      const response = await axios.get("pharmacy/get-prescription-list", {
        params,
      });
      setBadgeData(response.data);
    } catch (error) {
      console.log("Unable to get badge for virtual.", error);
    }
  };

  const checkinternet = (pharmacyDetails) => {
    CheckInternet.online()
      .then(() => {
        interval = setInterval(() => {
          fetchPrescription(pharmacyDetails);
        }, 10000);
      })
      .catch(() => {
        clearInterval(interval);
      });
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <Home color="white" />,
      path: "/bmcdc/app/pharmacy",
      subitem: [],
    },
    {
      name: "Inventory",
      show: true,
      icon: <Archive color="white" />,
      path: "/bmcdc/app/pharmacy/inventory",
      subitem: [],
    },
    {
      name: "Receipt",
      show: true,
      icon: <FileText color="white" />,
      path: "/bmcdc/app/pharmacy/receipt",
      subitem: [],
    },
    {
      name: "Stock",
      show: true,
      icon: <Monitor color="white" />,
      path: "/bmcdc/app/pharmacy/stock",
      subitem: [],
    },
    {
      name: "Logs",
      show: true,
      icon: <GitPullRequest color="white" />,
      path: "/bmcdc/app/pharmacy/logs",
      subitem: [],
    },
    {
      name: "Sales",
      show: true,
      icon: <DollarSign color="white" />,
      path: "/bmcdc/app/pharmacy/sales",
      subitem: [],
    },
    {
      name: "Leave Application",
      show: true,
      icon: <ContactMail color="white" />,
      path: "/bmcdc/app/pharmacy/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <Users color="white" />,
      path: "/bmcdc/app/pharmacy/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    fetchRole();

    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <PharmacyRoleAndId.Provider
        value={{
          userRoleAndId: userRoleAndId,
          badgeCount: badgeData,
        }}
      >
        <UsersHeader.Provider
          value={{
            sidebarHeader: sidebarHeader,
            renderPharmacyInfo: handleRenderInfo,
          }}
        >
          {userRoleAndId.ready && (
            <BrowserRouter>
              <Sidebar
                notification={{
                  enable: true,
                  owner: "pharmacy",
                }}
                header={sidebarHeader}
                routes={sidebarRoute}
                module={"pharmacy"}
              />

              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => <Redirect to="/bmcdc/app/pharmacy" />}
                />

                <Route
                  exact
                  path="/bmcdc"
                  component={() => <Redirect to="/bmcdc/app/pharmacy" />}
                />

                <Route
                  exact
                  path="/bmcdc/app"
                  component={() => <Redirect to="/bmcdc/app/pharmacy" />}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy"
                  component={PharmacyDashboard}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/inventory"
                  component={Inventory}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/receipt"
                  component={Receipt}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/receipt/:receipt_number"
                  component={ReceiptPrint}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/stock"
                  component={Stock}
                />

                <Route exact path="/bmcdc/app/pharmacy/logs" component={Logs} />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/sales"
                  component={Sales}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/account"
                  component={Account}
                />

                {/* Virtual */}
                <Route
                  exact
                  path="/bmcdc/app/pharmacy/virtual"
                  component={Virtual}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/local"
                  component={LocalOrder}
                />

                {/* account Redirect */}
                <Route
                  exact
                  path="/bmcdc/app/pharmacy/account/basic-info"
                  component={BasicInfo}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/account/login-info"
                  component={LoginInfo}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/leave-application"
                  component={LeaveForm}
                />

                <Route
                  exact
                  path="/bmcdc/app/pharmacy/account/pharmacy-qr"
                  component={PharmacyQRCode}
                />

                <Route exact path="/bmcdc/app/logout" component={Logout} />

                <Route render={() => <PageNotFound title="Page not found" />} />
              </Switch>
            </BrowserRouter>
          )}
        </UsersHeader.Provider>
      </PharmacyRoleAndId.Provider>
    </Fragment>
  );
};

export default RoutePharmacy;
