import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  Box,
  Paper,
  Tab,
  Tabs,
  Typography,
  Collapse,
  TablePagination,
  IconButton,
  Grid,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import axios from "axios";
import ChevronRight from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import TabPanel from "src/utils/TabPanel";

const FollowUpCheckTabs = ({ patient_id, getDoctorsPatient, onClose }) => {
  const [category, setCategory] = useState("notes");
  const { users } = useContext(UsersData);
  const [notes, setNotes] = useState({ data: [], ready: false });
  const [treatment, setTreatment] = useState({ data: [], ready: false });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedId, setSelectedId] = useState(null);

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getNotes = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      patient_id: patient_id,
    };
    try {
      let response = await axios.get("nurse/patient/notes/notes-list", {
        params,
      });
      const data = response.data;
      setNotes({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, patient_id]);

  const getTreatmentPlan = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      patient_id: patient_id,
      type: "text",
    };
    try {
      let response = await axios.get("nurse/patient/patient-treatmentplan", {
        params,
      });
      const data = response.data;
      setTreatment({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, patient_id]);

  useEffect(() => {
    getNotes();
    getTreatmentPlan();
  }, [getNotes, getTreatmentPlan]);

  return (
    <Box>
      <Paper>
        <Tabs
          value={category}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab value="notes" label={"Notes"} />
          <Tab value="treatment" label={"Treatment Plan"} />
        </Tabs>
      </Paper>
      <TabPanel value={category} index="notes">
        <Box p={2} mt={2} component={Paper} elevation={2}>
          <Box>
            {notes.ready
              ? notes.data.length > 0
                ? (rowsPerPage > 0
                    ? notes.data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : notes.data
                  ).map((data, index) => (
                    <Box p={1} key={index}>
                      <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <IconButton
                            color="primary"
                            onClick={() =>
                              setSelectedId(
                                selectedId === data.id ? null : data.id
                              )
                            }
                          >
                            {selectedId === data.id ? (
                              <KeyboardArrowDownIcon fontSize="large" />
                            ) : (
                              <ChevronRight fontSize="large" />
                            )}
                          </IconButton>
                        </Box>
                        <Box width="100%">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                              <Box />
                              <Typography
                                variant="subtitle1"
                                style={{
                                  fontWeight: "bolder",
                                  textTransform: "capitalize",
                                }}
                              >
                                From : {data.doctors_name}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  fontWeight: "bolder",
                                  textTransform: "capitalize",
                                }}
                              >
                                Diagnosis : {data.initial_diagnosis}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Typography variant="subtitle2">
                            {Notify.dateTimeConvert(data.created_at)}
                          </Typography>
                        </Box>
                      </Box>

                      <Collapse in={selectedId === data.id ? true : false}>
                        <Box my={3}>
                          <div
                            className="py-2"
                            dangerouslySetInnerHTML={{ __html: data.notes }}
                          />
                        </Box>
                      </Collapse>
                    </Box>
                  ))
                : Notify.noRecord()
              : Notify.loading()}
          </Box>
          <TablePagination
            labelRowsPerPage="List"
            rowsPerPageOptions={[5, 20, 50, 100]}
            component="div"
            count={notes.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </TabPanel>

      <TabPanel value={category} index="treatment">
        <Box p={2} mt={2} component={Paper} elevation={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Schedule </TableCell>
                  <TableCell> Treatment Plan </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {treatment.data.length > 0 ? (
                  (rowsPerPage > 0
                    ? treatment.data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : treatment.data
                  ).map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {data.date === null
                            ? Notify.dateTimeConvert(data.created_at)
                            : Notify.dateTimeConvert(data.date)}
                        </TableCell>
                        <TableCell>
                          <Typography
                            className="pointer"
                            style={{ width: "20vw" }}
                            noWrap
                          >
                            {data.treatment_plan}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} className="text-danger text-center">
                      <Typography color="error">
                        No treatment plan created.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 20, 50, 100]}
              component="div"
              count={treatment.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </TabPanel>
    </Box>
  );
};

export default FollowUpCheckTabs;
