import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardHeader, Typography, useTheme } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const BranchCardSales = ({ selectedBranch }) => {
  const mounted = IsMountedRef();
  const [grandSales, setGrandSales] = useState(0);
  const theme = useTheme();

  const getAllSales = React.useCallback(async () => {
    let response = await axios.get("accounting/branch/sales-totalamount", {
      params: {
        management_id: selectedBranch.management_id,
      },
    });

    if (mounted.current) {
      if (response.data.length > 0) {
        calculateTotal(response.data);
      } else {
        setGrandSales(0);
      }
    }
  }, [mounted, selectedBranch]);

  const calculateTotal = (data) => {
    let x = 0;

    data.forEach((element) => {
      x += parseFloat(element.bill_amount);
    });

    setGrandSales(x);
  };

  useEffect(() => {
    getAllSales();
  }, [getAllSales]);

  return (
    <Card elevation={5}>
      <CardHeader
        avatar={
          <FontAwesomeIcon
            icon={faCubes}
            size={"5x"}
            style={{ color: `${theme.palette.primary.main}` }}
          />
        }
        title={
          <Typography variant="h4" color="textSecondary">
            Sales
          </Typography>
        }
        subheader={
          <Box>
            <Typography variant="h4" color="textPrimary">
              <strong> &#8369; {Notify.convertToNumber(grandSales)} </strong>
            </Typography>
          </Box>
        }
      />
    </Card>
  );
};

export default BranchCardSales;
