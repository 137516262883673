import { faPrescription } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import OrderDetails from "./OrderDetails";

const LocalOrder = () => {
  const userContext = React.useContext(UsersData);
  const [list, setList] = React.useState({ data: [], ready: false });
  const [selectedClaimId, setSelectedClaimId] = React.useState(null);
  const [selectedName, setSelectedName] = React.useState(null);

  const getLocalOrder = React.useCallback(async () => {
    var params = {
      user_id: userContext.users.user_id,
      management_id: userContext.users.management_id,
      connection: "local",
    };

    const response = await axios.get("pharmacy/get/all-unclaimed-pres", {
      params,
    });
    const data = response.data;
    setList({ data: data, ready: true });
  }, [userContext]);

  useEffect(() => {
    getLocalOrder();
  }, [getLocalOrder]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "local prescription",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title="Local Prescription"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" color="textSecondary">
                {" "}
                New Local Order{" "}
              </Typography>
              <TextField label="search" variant="outlined" fullWidth />
            </Box>

            <Box px={2}>
              <List component="div">
                {list.ready
                  ? list.data.length > 0
                    ? list.data.map((data, index) => (
                        <ListItem
                          key={index}
                          button
                          onClick={() => {
                            setSelectedClaimId(data.claim_id);
                            setSelectedName(data.patient_name);
                          }}
                        >
                          <ListItemText
                            primary={data.patient_name}
                            secondary={Notify.dateTimeConvert(data.created_at)}
                          />
                        </ListItem>
                      ))
                    : Notify.noRecord()
                  : Notify.loading()}
              </List>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Paper>
            <Box p={2}>
              {selectedClaimId ? (
                <OrderDetails
                  getLocalOrder={() => getLocalOrder()}
                  claim_id={selectedClaimId}
                  patient_name={selectedName}
                />
              ) : (
                <Box align="center">
                  <Typography color="primary">
                    <FontAwesomeIcon icon={faPrescription} size={"8x"} />
                  </Typography>

                  <Typography variant="h3" color="primary">
                    SELECT ORDER TO VIEW
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LocalOrder;
