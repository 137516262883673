import {
  Box,
  DialogContent,
  Divider,
  makeStyles,
  Typography,
  Fab,
  CardMedia,
  createStyles,
  Dialog,
} from "@material-ui/core";
import React, { Fragment } from "react";
import QRCode from "qrcode.react";
import { Close, Print } from "@material-ui/icons";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const QrDialog = ({ open, close, qrDialog, setQrDialog }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Dialog open={open} onClose={close} fullScreen>
        <DialogContent>
          <Box
            display="flex"
            className={classes.body}
            justifyContent="center"
            alignItems="center"
          >
            <Box className={classes.idprint} border={2}>
              <Box display="flex" justifyContent="center">
                <Typography
                  className={classes.titleCity}
                  variant="h6"
                  style={{ color: "#17a2b8", fontWeight: "bolder" }}
                >
                  HIS ID
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <QRCode
                  value={qrDialog.data.user_id}
                  level="H"
                  size={130}
                  className={classes.qrSize}
                />
              </Box>
              <Box m={1}>
                <Divider />
              </Box>
              <Box display="flex" justifyContent="center">
                <Box className={classes.picturepatient} border={2}>
                  <CardMedia
                    component={"img"}
                    alt="gtc-news"
                    src={
                      qrDialog.data.image !== null
                        ? `${imageLocation}${qrDialog.type}/${qrDialog.data.image}`
                        : ``
                    }
                    className={classes.thumbnail}
                    height={106}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  className={classes.infopatient}
                  variant="caption"
                  style={{
                    textTransform: "uppercase",
                    color: "#17a2b8",
                    fontWeight: "bolder",
                  }}
                >
                  {qrDialog.data.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="d-print-none">
            <Fab
              aria-label={"Print"}
              className={classes.fab2}
              color={"secondary"}
              onClick={() => close()}
            >
              <Close />
            </Fab>
            <Fab
              aria-label={"Print"}
              className={classes.fab}
              color={"primary"}
              onClick={() => window.print()}
            >
              <Print />
            </Fab>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default QrDialog;

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      width: "calc(100vw - 50px)",
      height: "calc(100vh - 90px)",
      justifyContent: "center",
      alignItems: "center",
    },
    idprint: {
      display: "block",
      width: "234px",
      height: "auto",
    },
    fab2: {
      position: "absolute",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },

    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    picturepatient: {
      width: 110,
      height: 110,
    },
    thumbnail: {
      height: 106,
    },
    infopatient: {
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
    },
    [`@media print`]: {
      body: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
      },
      idprint: {
        width: 275,
        height: 410,
      },
      titleCity: {
        fontSize: 30,
        fontWeight: "bolder",
      },
      qrSize: {
        width: 150,
        height: 150,
      },
      picturepatient: {
        width: 120,
        height: 120,
      },
      thumbnail: {
        height: 116,
      },
      infopatient: {
        padding: 0,
        fontSize: 14,
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      },
      infopatient2: {
        fontSize: 11,
      },
    },
  })
);
