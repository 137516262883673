import React, { useEffect, Fragment } from "react";
import PatientDetailsHeader from "./PatientDetailsHeader";
import PatientDetailsProfile from "./PatientDetailsProfile";
import Axios from "axios";
import Notify from "src/notification/Notify";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "src/utils/TabPanel";
import WalkInTab from "./walkin/WalkInTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faUserShield,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";
import PatientRegNewCT from "./PatientRegNewCT";

const PatientDetails = ({ patient_id, getDoctorsPatient }) => {
  const [category, setCategory] = React.useState(0);
  const [info, setInfo] = React.useState({
    data: null,
    ready: false,
  });

  const getPatientInformation = () => {
    setInfo({ data: [], ready: false });
    var params = { patient_id: patient_id };
    Axios.get("admission/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setInfo({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  useEffect(() => {
    getPatientInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      {info.ready ? (
        <Fragment>
          <PatientDetailsHeader patient_id={patient_id} info={info} />

          <Box mt={2} m={0} p={0}>
            <Box component={Paper} elevation={1} mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab
                  wrapped
                  label="Profile"
                  icon={<FontAwesomeIcon icon={faUserShield} size={"2x"} />}
                />

                <Tab
                  wrapped
                  label="Contact Tracing"
                  icon={<FontAwesomeIcon icon={faFolder} size={"2x"} />}
                />

                {/* <Tab
                  wrapped
                  label="Appointment"
                  icon={<FontAwesomeIcon icon={faCalendarCheck} size={"2x"} />}
                /> */}

                <Tab
                  wrapped
                  label="Walk in Additional"
                  icon={<FontAwesomeIcon icon={faWalking} size={"2x"} />}
                />
              </Tabs>
            </Box>

            <TabPanel value={category} index={0}>
              <PatientDetailsProfile
                patient_id={patient_id}
                getDoctorsPatient={() => getDoctorsPatient()}
                getPatientInformation={getPatientInformation}
                info={info}
              />
            </TabPanel>

            <TabPanel value={category} index={1}>
              <PatientRegNewCT
                patient_id={patient_id}
                getDoctorsPatient={() => getDoctorsPatient()}
                getPatientInformation={getPatientInformation}
                info={info}
              />
            </TabPanel>

            {/* <TabPanel value={category} index={1}>
              <AppointmentList patient_id={patient_id} /> */}
            {/* <PatientDetailsContactTracing
                patient_id={patient_id}
                getDoctorsPatient={() => getDoctorsPatient()}
                getPatientInformation={getPatientInformation}
                info={info}
                setSelectedPatient={() => setSelectedPatient()}
              /> */}
            {/* <PatientDetailsCaseInvestigation getAllPatientCaseInvestigation={() => getAllPatientCaseInvestigation()} setCategory={() => setCategory(0)} patient_id={patient_id} cCSameCity={cCSameCity} cCDiffCity={cCDiffCity} caseInvestigation={patientCaseInvestigation} /> */}
            {/* </TabPanel> */}

            <TabPanel value={category} index={2}>
              <WalkInTab
                patient_id={patient_id}
                info={info}
                close={() => setCategory(0)}
              />
            </TabPanel>
          </Box>
        </Fragment>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={200}
        >
          {Notify.loading()}
        </Box>
      )}
    </>
  );
};

export default PatientDetails;
