import React, { Fragment, useContext, useEffect, useState } from 'react';   
import { Form, FormGroup, FormControl, Row, Col } from 'react-bootstrap'; 
import Axios from 'axios';
import Notify from '../../../notification/Notify';
import Select from 'react-select' 
import { Typography, Box, IconButton, Badge, Button, TextField } from '@material-ui/core';
import { CheckCircleOutline, HighlightOff, Keyboard, ListAlt } from '@material-ui/icons';
import { UsersData } from 'src/ContextAPI';
import IsMountedRef from 'src/utils/IsMountedRef';
 

const PrescriptionLocal =  ({ patient_id, displayUnsave, getUnsavePrescription }) => { 

    const [pharmacy, setPharmacy] = useState({ data:[], ready: false })
    const [product, setProduct] = useState({ data:[], ready: false })
  
    const [selectedProdName, setSelectedProdName] = useState(0)
    const [selectedProdType, setSelectedProdType] = useState(0)
    const [selectedProdQty, setSelectedProdQty] = useState(0)
    const [selectedProdAmount, setSelectedProdAmount]  = useState(0)
    const [unsaveProductCount, setUnsaveProductCount]  = useState(0)
    const [isProcess, setIsProcess] = useState(false)
    const [isSearchable, setIsSearchable] = useState(false) 
    const { users } = useContext(UsersData)
    const mounted = IsMountedRef()


    const unsavePrescriptionCount = () =>{
        var formdata = new FormData();   
        formdata.set('user_id', users.user_id);    
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', 'local')
        
        Axios.post('prescription/local/product-unsave', formdata )
        .then((response)=>{ 
            const data = response.data;   
            if(mounted.current){  
                setUnsaveProductCount(data.length)
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const  getPharmacy = () => {
        var formdata = new FormData();   
        formdata.set('user_id', users.user_id);    
        formdata.set('management_id', users.management_id);    
        
        Axios.post('prescription/local/getLocalPharmacy', formdata )
        .then( (response) => { 
            const data = response.data;   
            if(mounted.current){  
                setPharmacy({ data, ready:true }) 
            } 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const getProducts = () => {
        var formdata = new FormData();  
        formdata.set('user_id', users.user_id);    
        formdata.set('management_id', users.management_id);      
        
        Axios.post('prescription/local/product-list', formdata )
        .then( (response) => { 
            const data = response.data;   
            if(mounted.current){
                setProduct({ data: data, ready: true })   
            } 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const handleSelectedProd = (e) => { 
        var formdata = new FormData();   
        formdata.set('user_id', users.user_id);    
        formdata.set('product_id', e.value); 
        
        Axios.post('prescription/local/product-details', formdata )
        .then((response) => { 
            const data = response.data;    
            if(data.length > 0){
                setSelectedProdQty(data[0].available_qty)
                setSelectedProdType(data[0].unit)
                setSelectedProdName(data[0].product_name)
                setSelectedProdAmount(data[0].product_amount) 
            } 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const addToPrecription = e => {
        e.preventDefault();
        e.persist();
        var formdata = new FormData(e.target);
        formdata.set('user_id', users.user_id);    
        formdata.set('management_id', users.management_id);      
        formdata.set('patient_id', patient_id);    
        formdata.set('product_id', e.value); 
        formdata.set('prescription_type', 'clinic');
        formdata.set('type', selectedProdType)
        
        var error = [];   

        if(formdata.get('prescription').length === 0 || formdata.get('prescription').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('prescription'); 
        }

        if(formdata.get('order_qty').length === 0 || formdata.get('order_qty').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('order qty'); 
        }else{
            if(parseInt(formdata.get('order_qty')) > parseInt(formdata.get('available_qty'))){
                error = 'error';
                Notify.fieldInvalid('order qty')
            }
        }

        if(formdata.get('dosage').length === 0 || formdata.get('dosage').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('dosage'); 
        }

        if(formdata.get('remarks').length === 0 || formdata.get('remarks').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('remarks'); 
        } 
        
        if(error.length > 0){
            Notify.consoleLog('add product')
        }else{
            setIsProcess(true)
            Axios.post('prescription/local/product-add', formdata )
            .then( (response) => { 
                const data = response.data;     
                    if(data === 'success'){
                        e.target.reset(); 
                        getUnsavePrescription()
                        unsavePrescriptionCount()
                        Notify.successRequest('add product');
                    }else{
                        Notify.warnRequest('add product');
                    } 
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(() => setIsProcess(false))
        }
    }

    useEffect(() =>{

        getPharmacy()
        getProducts() 
        unsavePrescriptionCount()

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[patient_id])

    return(
        <Fragment> 
            <Box mb={1}>
                <Typography variant="h6" color="textSecondary"> Create Clinic Prescription </Typography>
            </Box>
            {
                pharmacy.ready ?
                    pharmacy.data.length > 0 ?
                        <Form onSubmit={ addToPrecription } >
                            <Row> 
                                <Col sm={12}>
                                    <FormGroup>
                                        <span className="gtc-small text-uppercase text-muted"> Pharmacy </span>
                                        <FormControl type="text" name="pharmacy_id" defaultValue={ pharmacy.data[0].value } hidden/>
                                        <FormControl isValid={true} defaultValue={ pharmacy.data[0].label } readOnly/>
                                    </FormGroup>
                                </Col> 

                                <Col sm={12}>
                                    <Box display="flex">
                                        <Box flexGrow={1}>
                                            <Typography variant="caption" color="textSecondary"> PRESCRIPTION </Typography>
                                        </Box>

                                        <Box>  
                                            <IconButton 
                                                onClick={ () => {   
                                                    setIsSearchable(!isSearchable)
                                                }} 
                                                size="small" 
                                                color={ isSearchable ? "primary" : "default"}
                                            >
                                                <Keyboard />
                                            </IconButton>
                                        </Box>
                                    </Box> 
                                    
                                    <Box my={1}> 
                                        <Select
                                            name="prescription"
                                            options={ product.data } 
                                            onChange ={ handleSelectedProd } 
                                            isSearchable={ isSearchable }
                                            styles={{ 
                                                menu: provided => ({ 
                                                    ...provided, zIndex: 9999, 
                                                    color: 'black'
                                                }),
                                                control: (base) =>({
                                                    ...base,
                                                    minHeight: 55
                                                })
                                            }}
                                        /> 
                                    </Box>
                                </Col> 
                            </Row> 

                            <Box mt={1}>
                                <Row>
                                    <Col sm={4}>
                                        {/* <FormGroup>
                                            <span className="gtc-small text-uppercase text-muted"> order qty </span>
                                            <FormControl type="number" min="1" name="order_qty"/>
                                        </FormGroup> */}
                                        <Box>
                                            <TextField 
                                                label="Order Qty"
                                                name="order_qty"
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 9999,
                                                        step: 0.01
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Col>
                                    <Col sm={4}>
                                        <Box>
                                            <TextField 
                                                label="Available Qty" 
                                                type="number"
                                                fullWidth
                                                value={selectedProdQty} 
                                                variant="outlined"
                                                name="available_qty"
                                                disabled
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 9999,
                                                        step: 0.01
                                                    }
                                                }}
                                            />
                                        </Box> 
                                    </Col>
                                    <Col sm={4}>
                                        <Box>
                                            <TextField 
                                                label="Type"
                                                name="type" 
                                                fullWidth
                                                value={selectedProdType} 
                                                variant="outlined" 
                                                disabled 
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Box> 
                                        <FormGroup>  
                                            <FormControl name="product_name" value={ selectedProdName } readOnly hidden/>
                                            <FormControl name="product_amount" value={ selectedProdAmount } readOnly hidden/>
                                        </FormGroup>
                                    </Col> 
                                </Row>
                            </Box>

                            <Row>
                                <Col sm={12}>
                                    <Box>
                                        <TextField 
                                            label="Dosage"
                                            name="dosage" 
                                            fullWidth 
                                            variant="outlined"
                                        />
                                    </Box> 
                                </Col>
                                <Col sm={4} hidden>
                                    <FormGroup>
                                        <span className="gtc-small text-uppercase text-muted"> Take Every </span>
                                        <FormControl name="take_every"/>
                                    </FormGroup>
                                </Col>
                                <Col sm={4} hidden>
                                    <FormGroup>
                                        <span className="gtc-small text-uppercase text-muted"> Times </span>
                                        <FormControl name="take_times"/>
                                    </FormGroup>
                                </Col>
                            </Row>
        
                            <Box my={2}>
                                <Box>
                                    <TextField 
                                        label="Sig."
                                        name="remarks" 
                                        fullWidth 
                                        variant="outlined" 
                                    />
                                </Box> 
                            </Box>
        
                            {/* <FormGroup>
                                <span className="gtc-small text-uppercase text-muted"> brand </span>
                                <FormControl name="brand" as="select">
                                    <option value=""> --Select-- </option>
                                    <option value="FK">FK</option>
                                    <option value="BBRAUN">BRAUN</option>
                                </FormControl>
                            </FormGroup> */}
        
                            <p className="m-0 mb-2 text-muted"> Are you sure to continue adding this item? </p>
                            <Button 
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled = { isProcess }
                                startIcon={ <CheckCircleOutline /> }
                            > 
                                Add 
                            </Button>

                            <Button 
                                type="reset" 
                                variant="contained"
                                component={Box}
                                mx={2}
                                startIcon={ <HighlightOff /> }
                            > No </Button>

                            <Badge badgeContent={ unsaveProductCount } color="error">
                                <Button  
                                    type="button" 
                                    variant="contained"
                                    color="secondary"
                                    onClick = { () => displayUnsave() }
                                    startIcon={ <ListAlt /> }
                                >   Unsave Prescription </Button> 
                            </Badge>
                        </Form> 
                    : <div className="mt-3">
                        { Notify.noRecord()}
                        <p className="text-danger text-center"> No pharmacy available </p>
                    </div>
                : Notify.loading()
            }
        </Fragment>
    )
}

export default PrescriptionLocal;