import {
  Typography,
  Box,
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const UrinalysisOrder = ({ formheader, urinalysicOrderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={urinalysicOrderDetails} />
        </CardContent>

        {/* // 11-19-2021-updates-jhomar */}
        <CardContent className="darken-inputvalue">
          <Box>
            <Typography variant="h6" align="center">
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
                style={{ fontWeight: "bold", color: "#000" }}
              >
                URINALYSIS
              </span>
            </Typography>
          </Box>

          {urinalysicOrderDetails.data.map((data, index) => (
            <Grid container spacing={3} key={index}>
              <Grid item xs={12} sm={6}>
                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      color :
                    </Typography>
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      required
                      margin="dense"
                      defaultValue={data.color}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      reaction :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      required
                      name="reaction[]"
                      margin="dense"
                      defaultValue={data.reaction}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      transparency :
                    </Typography>
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      name="transparency[]"
                      margin="dense"
                      defaultValue={data.transparency}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      <span className="text-capitalize">sp gravity :</span>
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="sp_gravity[]"
                      margin="dense"
                      defaultValue={data.sp_gravity}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      albumin (negative) :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="albumin[]"
                      margin="dense"
                      defaultValue={data.albumin}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      sugar (negative) :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="sugar[]"
                      margin="dense"
                      defaultValue={data.sugar}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      pus cells :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="pus_cell[]"
                      margin="dense"
                      defaultValue={data.pus_cell}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      R.B.C:
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="rbc[]"
                      margin="dense"
                      defaultValue={data.rbc}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      epithelial cells :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="epithelial_cell[]"
                      margin="dense"
                      defaultValue={data.epithelial_cell}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      mucus threads :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="mucus_threads[]"
                      margin="dense"
                      defaultValue={data.mucus_threads}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Color :renal cells :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="renal_cell[]"
                      margin="dense"
                      defaultValue={data.renal_cell}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Color :yeast cells :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="yeast_cell[]"
                      margin="dense"
                      defaultValue={data.yeast_cell}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      hyaline :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="hyaline[]"
                      margin="dense"
                      defaultValue={data.hyaline}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      rbc cast :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="rbc_cast[]"
                      margin="dense"
                      defaultValue={data.rbc_cast}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      wbc cast :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="wbc_cast[]"
                      margin="dense"
                      defaultValue={data.wbc_cast}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      coarse granular cast :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="coarse_granular_cast[]"
                      margin="dense"
                      defaultValue={data.coarse_granular_cast}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      fine granular cast :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="fine_granular_cast[]"
                      margin="dense"
                      defaultValue={data.fine_granular_cast}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      pus in clumps :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="pus_in_clumps[]"
                      margin="dense"
                      defaultValue={data.pus_in_clumps}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      rbc in clumps :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="rbc_in_clumps[]"
                      margin="dense"
                      defaultValue={data.rbc_in_clumps}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      calcium oxalate :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="calcium_oxalate[]"
                      margin="dense"
                      defaultValue={data.calcium_oxalate}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      uric acid :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      margin="dense"
                      defaultValue={data.uricacid}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      amorphous urate :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="amorphous_urate[]"
                      margin="dense"
                      defaultValue={data.amorphous_urate}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      amorphous phosphate :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="amorphous_phosphate[]"
                      margin="dense"
                      defaultValue={data.amorphous_phosphate}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      calcium carbonate :
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="calcium_carbonate[]"
                      margin="dense"
                      defaultValue={data.calcium_carbonate}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      ammonium biurate:
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="ammonium_biurate[]"
                      margin="dense"
                      defaultValue={data.ammonium_biurate}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      triple phosphate:
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="triple_phosphate[]"
                      margin="dense"
                      defaultValue={data.triple_phosphate}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      spermatozoa:
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="spermatozoa[]"
                      margin="dense"
                      defaultValue={data.spermatozoa}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      trichomonas vaginalis:
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="trichomonas_vaginalis[]"
                      margin="dense"
                      defaultValue={data.trichomonas_vaginalis}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      micral test:
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="micral_test[]"
                      margin="dense"
                      defaultValue={data.micral_test}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box mt={1.5} mr={1} flexGrow={1}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      urine ketone:
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      name="urine_ketone[]"
                      margin="dense"
                      defaultValue={data.urine_ketone}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex">
                  <TextField
                    fullWidth
                    label="Others"
                    margin="dense"
                    defaultValue={data.others}
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                <Box>
                  <TextField
                    fullWidth
                    label="Remarks"
                    margin="dense"
                    multiline
                    defaultValue={data.order_remarks}
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}

          <Box my={3} align="center">
            <Footer formheader={formheader} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default UrinalysisOrder;
