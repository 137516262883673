import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  InputAdornment,
  Box,
  CircularProgress,
  makeStyles,
  withStyles,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import { Alert } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";
import Notify from "../notification/Notify";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    padding: theme.spacing(1.5),
    fontSize: "15px",
    background: "#007bff",
    "&:hover": {
      background: "#007bff",
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& label": {
      color: "#007bff",
    },
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#007bff",
      },
      "&:hover fieldset": {
        borderColor: "#007bff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderLeftWidth: 6,
      },
    },
  },
})(TextField);

const Login = () => {
  const classes = useStyles();
  const [isprocess, setIsProcess] = useState(false);
  const [accountInvalid, setAccountInvalid] = useState(null);
  const history = useHistory();

  const handleLogin = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    var error = [];

    if (
      formdata.get("username").length === 0 ||
      formdata.get("username").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("username");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      console.log("Unable to process login, Form has an error");
    } else {
      setIsProcess(true);
      axios
        .post("login", formdata)
        .then((response) => {
          if (response.data.msg === "account-invalid") {
            setAccountInvalid("Credentials Invalid.");
            Notify.customToast(
              "Credentials Invalid",
              "Check your username and password."
            );
            setIsProcess(false);
          } else {
            localStorage.setItem("hmis_token", response.data[0].token);
            localStorage.setItem("hmis_page", "Dashboard");
            history.go();
          }
        })
        .catch((error) => {
          console.log(error);
          Notify.requestError(error);
        });
    }
  };

  useEffect(() => {
    localStorage.removeItem("hmis_token");
  }, []);

  return (
    <Fragment>
      <form onSubmit={handleLogin}>
        {accountInvalid && (
          <Box mb={2}>
            <Alert severity="error" color="error">
              {accountInvalid}
            </Alert>
          </Box>
        )}

        <Box my={3}>
          <ValidationTextField
            name="username"
            label={` Username `}
            autoComplete="off"
            variant="outlined"
            placeholder="Enter your username"
            fullWidth
            InputProps={{
              style: { color: grey[800] },
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle style={{ color: "007bff" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box mb={3}>
          <ValidationTextField
            name="password"
            label={` Password `}
            variant="outlined"
            type="password"
            placeholder="Enter your password"
            fullWidth
            InputProps={{
              style: { color: grey[800] },
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon style={{ color: "007bff" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            disabled={isprocess}
            startIcon={
              isprocess && <CircularProgress color="inherit" size={30} />
            }
            type="submit"
            size="large"
            fullWidth
            className={classes.button}
          >
            login
          </Button>
        </Box>
      </form>
      <Typography variant="caption" color="textSecondary">
        POWERED BY GTC
      </Typography>
    </Fragment>
  );
};

export default Login;
