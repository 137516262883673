import React, { useState } from "react";
import {
  Avatar,
  //   Badge,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  makeStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const RadiologistPatientForReview = ({
  patients,
  updateSelected,
  selectedPatient,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  const searchable = patients.data.filter((data) => {
    return (
      data.patient_name.toLowerCase().indexOf(search.trim().toLowerCase()) !==
      -1
    );
  });

  return (
    <Paper component={Box}>
      <Box p={1} borderRadius={4}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h5" color="textSecondary">
              {/* <Badge badgeContent={patients.data.length} color="secondary">Patient List</Badge> */}
              Patient List
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box p={1}>
        {patients.ready ? (
          patients.data.length > 0 ? (
            <List component="div">
              <Box mb={1}>
                <TextField
                  label="Search patient"
                  variant="outlined"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <>
                {searchable.map((data, index) => {
                  return (
                    <ListItem
                      button
                      key={index}
                      selected={data === selectedPatient}
                      onClick={() => updateSelected(data)}
                    >
                      <ListItemIcon>
                        {data.image === null ? (
                          <Avatar className={classes.primary}>
                            {data.lastname.charAt().toUpperCase()}
                          </Avatar>
                        ) : (
                          <Avatar
                            className={classes.primary}
                            src={`${imageLocation}patients/${data.patient_image}`}
                            alt=""
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={data.patient_name} />
                    </ListItem>
                  );
                })}
              </>
            </List>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>
    </Paper>
  );
};

export default RadiologistPatientForReview;
