import axios from "axios";
import React, { useEffect, useState, Fragment, useContext } from "react";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  CardContent,
  Card,
  CardActions,
  //   CircularProgress,
  IconButton,
} from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { UsersData } from "src/ContextAPI";

const Laboratory = ({ patient_id, selectedBranch, updatePatientList }) => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [stoolTest, setStoolTest] = useState({ data: [], ready: false });
  const [urineTest, setUrineTest] = useState({ data: [], ready: false });
  //   const [resultSubmitting, setResultSubmitting] = useState(false);
  const [savebtnUrineDisabled, setSavebtnUrineDisabled] = useState(true);
  const [savebtnStoolDisabled, setSavebtnStoolDisabled] = useState(true);
  const [microscopic, setMicroscopy] = useState({
    dfs: false,
    kt: false,
    kk: false,
  });

  const getStoolTest = React.useCallback(async () => {
    let response = await axios.get("van/order/stool-test", {
      params: {
        patient_id,
        management_id: selectedBranch,
      },
    });

    if (mounted.current) {
      setStoolTest({ data: response.data, ready: true });
    }
    checkIfSaveBtnIsEnabled(response.data, "stool");
  }, [patient_id, selectedBranch, mounted]);

  const getUrinalysis = React.useCallback(async () => {
    let response = await axios.get("van/order/urine-test", {
      params: {
        patient_id,
        management_id: selectedBranch,
      },
    });

    if (mounted.current) {
      setUrineTest({ data: response.data, ready: true });
    }
    checkIfSaveBtnIsEnabled(response.data, "urine");
  }, [patient_id, selectedBranch, mounted]);

  const checkIfSaveBtnIsEnabled = (data, cat) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        if (cat === "urine") {
          setSavebtnUrineDisabled(false);
        }
        if (cat === "stool") {
          setSavebtnStoolDisabled(false);
        }
      }
    }
  };

  const handleProcessUrineOrder = async (data) => {
    var formdata = new FormData();
    formdata.set("patient_id", data.patient_id);
    formdata.set("management_id", selectedBranch);
    try {
      let response = await axios.post("van/process-order/urine-test", formdata);
      const data = response.data;
      if (data === "success") {
        getUrinalysis();
        Notify.successRequest("update success");
      }
    } catch (error) {
      console.log("can't process, try again", error);
    }
  };

  const handleSaveUrineResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    var error = [];
    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      //   setResultSubmitting(true);
      axios
        .post("van/order/ordernew-urinalysis/save-process-result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getUrinalysis();
            updatePatientList();
            setSavebtnUrineDisabled(true);
            Notify.successRequest("order result added.");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }
  };

  const handleProcessStoolOrder = async (data) => {
    var formdata = new FormData();
    formdata.set("patient_id", data.patient_id);
    formdata.set("management_id", selectedBranch);
    try {
      let response = await axios.post("van/process-order/stool-test", formdata);
      const data = response.data;
      if (data === "success") {
        getStoolTest();
        Notify.successRequest("update success");
      }
    } catch (error) {
      console.log("can't process, try again", error);
    }
  };

  const handleSaveStoolResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("dfs", microscopic.dfs ? 1 : 0);
    formdata.set("kt", microscopic.kt ? 1 : 0);
    formdata.set("kk", microscopic.kk ? 1 : 0);

    var error = [];
    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      //   setResultSubmitting(true);
      axios
        .post("van/order/ordernew-stool/save-process-result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getStoolTest();
            updatePatientList();
            setSavebtnStoolDisabled(true);
            Notify.successRequest("order result added.");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }
  };

  useEffect(() => {
    getStoolTest();
    getUrinalysis();
  }, [getStoolTest, getUrinalysis]);
  return (
    <>
      <form onSubmit={handleSaveUrineResult}>
        <Card elevation={0}>
          {urineTest.ready &&
            urineTest.data.length > 0 &&
            urineTest.data.map((data, index) => (
              <Fragment key={index}>
                <CardContent>
                  <Box hidden={!Boolean(parseInt(data.urinalysis))}>
                    <Box mb={2} display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="h6" align="center">
                          <b> Urinalysis </b>
                        </Typography>
                      </Box>
                      <Box>
                        <Button
                          disabled={Boolean(parseInt(data.is_processed))}
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleProcessUrineOrder(data);
                          }}
                        >
                          Process
                        </Button>
                      </Box>
                    </Box>

                    <Box hidden={!Boolean(parseInt(data.is_processed))}>
                      {/* hidden inputs */}
                      <Box>
                        <TextField
                          margin="dense"
                          fullWidth
                          name="order_id[]"
                          label="order_id"
                          value={data.order_id}
                          hidden
                        />

                        <TextField
                          margin="dense"
                          fullWidth
                          name="patient_id"
                          label="patient_id"
                          value={data.patient_id}
                          hidden
                        />

                        <TextField
                          margin="dense"
                          fullWidth
                          name="doctors_id"
                          label="doctor_id"
                          value={data.doctor_id === null ? "" : data.doctor_id}
                          hidden
                        />
                      </Box>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">color :</span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                required
                                name="color[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  reaction :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                required
                                name="reaction[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  transparency :
                                </span>
                              </Typography>
                            </Box>

                            <Box>
                              <TextField
                                fullWidth
                                name="transparency[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  sp gravity :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="sp_gravity[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  albumin (negative) :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="albumin[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  sugar (negative):
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="sugar[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  pus cells:
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="pus_cell[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">R.B.C:</span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="rbc[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  epithelial cells :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="epithelial_cell[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  mucus threads :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="mucus_threads[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  renal cells :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="renal_cell[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  yeast cells :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="yeast_cell[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  hyaline :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="hyaline[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  rbc cast :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="rbc_cast[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  wbc cast :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="wbc_cast[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  coarse granular cast :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="coarse_granular_cast[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  fine granular cast :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="fine_granular_cast[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  pus in clumps :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="pus_in_clumps[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  rbc in clumps :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="rbc_in_clumps[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  calcium oxalate :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="calcium_oxalate[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  uric acid :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="uric_acid[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  amorphous urate :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="amorphous_urate[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  amorphous phosphate :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="amorphous_phosphate[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  calcium carbonate :
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="calcium_carbonate[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  ammonium biurate:
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="ammonium_biurate[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  triple phosphate:
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="triple_phosphate[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  spermatozoa:
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="spermatozoa[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  trichomonas vaginalis:
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="trichomonas_vaginalis[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  micral test:
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="micral_test[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">
                                  urine ketone:
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="urine_ketone[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box mt={1.5} mr={1} flexGrow={1}>
                              <Typography>
                                <span className="text-capitalize">others:</span>
                              </Typography>
                            </Box>
                            <Box>
                              <TextField
                                fullWidth
                                name="others[]"
                                margin="dense"
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

                      <Box mt={2}>
                        <TextField
                          fullWidth
                          label="Remarks"
                          name="remarks[]"
                          margin="dense"
                          multiline
                        />
                      </Box>
                    </Box>
                  </Box>
                </CardContent>

                <Box display="flex" m={1}>
                  <Box flexGrow={1} />
                  <Box>
                    <CardActions>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        disabled={savebtnUrineDisabled}
                      >
                        Save Result
                      </Button>
                    </CardActions>
                  </Box>
                </Box>
              </Fragment>
            ))}
        </Card>
      </form>
      <form onSubmit={handleSaveStoolResult}>
        <Card elevation={0}>
          {stoolTest.ready &&
            stoolTest.data.length > 0 &&
            stoolTest.data.map((data, index) => (
              <Fragment key={index}>
                <CardContent>
                  <Box hidden={!Boolean(parseInt(data.fecalysis))}>
                    <Box mb={2} display="flex">
                      <Box flexGrow={1}>
                        <Box align="center">
                          <Typography variant="h6">
                            <b> FECALYSIS </b>
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Button
                          disabled={Boolean(parseInt(data.is_processed))}
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleProcessStoolOrder(data);
                          }}
                        >
                          Process
                        </Button>
                      </Box>
                    </Box>

                    <Box hidden={!Boolean(parseInt(data.is_processed))}>
                      {/* hidden inputs */}
                      <Box>
                        <TextField
                          margin="dense"
                          fullWidth
                          name="order_id[]"
                          label="order_id"
                          value={data.order_id}
                          hidden
                        />
                        <TextField
                          margin="dense"
                          fullWidth
                          name="patient_id"
                          label="patient_id"
                          value={data.patient_id}
                          hidden
                        />

                        <TextField
                          margin="dense"
                          fullWidth
                          name="doctors_id"
                          label="doctor_id"
                          value={data.doctor_id === null ? "" : data.doctor_id}
                          hidden
                        />
                      </Box>
                      {/* fecal hidden if not process */}
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                label="Color"
                                name="color[]"
                                fullWidth
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                label="Consistency"
                                name="consistency[]"
                                fullWidth
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                label="Occult Blood"
                                name="occult_blood[]"
                                fullWidth
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Box mt={2}>
                              <Typography>
                                <strong> Microscopy </strong>
                              </Typography>
                            </Box>

                            <Box mt={3}>
                              <Typography>Ascaris Lumbricoides:</Typography>
                            </Box>

                            <Box>
                              <Typography> Hookworm: </Typography>
                            </Box>

                            <Box mt={2}>
                              <Typography>Blastocystis Hominis:</Typography>
                            </Box>

                            <Box mt={2.1}>
                              <Typography>
                                <strong> Giardia Lamblia </strong>
                              </Typography>
                            </Box>

                            <Box>
                              <Typography align="right"> Cyst: </Typography>
                            </Box>

                            <Box mt={1}>
                              <Typography align="right">
                                Trophozoite:
                              </Typography>
                            </Box>

                            <Box mt={1}>
                              <Typography> Trichuris Trichiura </Typography>
                            </Box>

                            <Box mt={1.3}>
                              <Typography>
                                <strong> Entamoeba Histolytica </strong>
                              </Typography>
                            </Box>

                            <Box mt={2}>
                              <Typography align="right"> Cyst: </Typography>
                            </Box>

                            <Box mt={1}>
                              <Typography align="right">
                                Trophozoite:
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Box align="center">
                              {microscopic.dfs ? (
                                <IconButton
                                  onClick={() =>
                                    setMicroscopy({
                                      ...microscopic,
                                      dfs: false,
                                    })
                                  }
                                >
                                  <CheckBox color="primary" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    setMicroscopy({
                                      ...microscopic,
                                      dfs: true,
                                    })
                                  }
                                >
                                  <CheckBoxOutlineBlank />
                                </IconButton>
                              )}
                              Direct Fecal Smear
                            </Box>

                            <Box>
                              <TextField
                                InputProps={{ disabled: !microscopic.dfs }}
                                label=""
                                name="dfs_ascaris[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                InputProps={{ disabled: !microscopic.dfs }}
                                label=""
                                name="dfs_hookworm[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                InputProps={{ disabled: !microscopic.dfs }}
                                label=""
                                name="dfs_blasto[]"
                                fullWidth
                              />
                            </Box>

                            <Box mt={4}>
                              <TextField
                                InputProps={{ disabled: !microscopic.dfs }}
                                label=""
                                name="dfs_giadia_cyst[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                InputProps={{ disabled: !microscopic.dfs }}
                                label=""
                                name="dfs_giadia_trophozoite[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                InputProps={{ disabled: !microscopic.dfs }}
                                label=""
                                name="dfs_trichuris[]"
                                fullWidth
                              />
                            </Box>

                            <Box mt={4}>
                              <TextField
                                InputProps={{ disabled: !microscopic.dfs }}
                                label=""
                                name="dfs_estamoeba_cyst[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                InputProps={{ disabled: !microscopic.dfs }}
                                label=""
                                name="dfs_estamoeba_trophozoite[]"
                                fullWidth
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Box>
                              {microscopic.kt ? (
                                <IconButton
                                  onClick={() =>
                                    setMicroscopy({
                                      ...microscopic,
                                      kt: false,
                                    })
                                  }
                                >
                                  <CheckBox color="primary" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    setMicroscopy({
                                      ...microscopic,
                                      kt: true,
                                    })
                                  }
                                >
                                  <CheckBoxOutlineBlank />
                                </IconButton>
                              )}
                              Kato-Thick
                            </Box>

                            <Box>
                              <TextField
                                label=""
                                disabled={!microscopic.kt}
                                name="kt_ascaris[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                label=""
                                disabled={!microscopic.kt}
                                name="kt_hookworm[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                label=""
                                disabled={!microscopic.kt}
                                name="kt_blasto[]"
                                fullWidth
                              />
                            </Box>

                            <Box mt={4}>
                              <TextField
                                label=""
                                disabled={!microscopic.kt}
                                name="kt_giadia_cyst[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                label=""
                                disabled={!microscopic.kt}
                                name="kt_giadia_trophozoite[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                label=""
                                disabled={!microscopic.kt}
                                name="kt_trichuris[]"
                                fullWidth
                              />
                            </Box>

                            <Box mt={4}>
                              <TextField
                                label=""
                                disabled={!microscopic.kt}
                                name="kt_estamoeba_cyst[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                label=""
                                disabled={!microscopic.kt}
                                name="kt_estamoeba_trophozoite[]"
                                fullWidth
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Box>
                              {microscopic.kk ? (
                                <IconButton
                                  onClick={() =>
                                    setMicroscopy({
                                      ...microscopic,
                                      kk: false,
                                    })
                                  }
                                >
                                  <CheckBox color="primary" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    setMicroscopy({
                                      ...microscopic,
                                      kk: true,
                                    })
                                  }
                                >
                                  <CheckBoxOutlineBlank />
                                </IconButton>
                              )}
                              Kato-Katz
                            </Box>

                            <Box align="center">
                              <TextField
                                disabled={!microscopic.kk}
                                label=""
                                name="kk_ascaris[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                disabled={!microscopic.kk}
                                label=""
                                name="kk_hookworm[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                disabled={!microscopic.kk}
                                label=""
                                name="kk_blasto[]"
                                fullWidth
                              />
                            </Box>

                            <Box mt={4}>
                              <TextField
                                disabled={!microscopic.kk}
                                label=""
                                name="kk_giadia_cyst[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                disabled={!microscopic.kk}
                                label=""
                                name="kk_giadia_trophozoite[]"
                                fullWidth
                              />
                            </Box>
                            <Box>
                              <TextField
                                disabled={!microscopic.kk}
                                label=""
                                name="kk_trichuris[]"
                                fullWidth
                              />
                            </Box>
                            <Box mt={4}>
                              <TextField
                                disabled={!microscopic.kk}
                                label=""
                                name="kk_estamoeba_cyst[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                disabled={!microscopic.kk}
                                label=""
                                name="kk_estamoeba_trophozoite[]"
                                fullWidth
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <TextField
                          label="Other"
                          name="others[]"
                          multiline
                          fullWidth
                        />
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                label="Pus Cells"
                                name="pus_cells[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                label="Red Blood Cells"
                                name="rbc[]"
                                fullWidth
                              />
                            </Box>

                            <Box>
                              <TextField
                                label="Fat Globules"
                                name="fat_globules[]"
                                fullWidth
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                label="Yeast Cells"
                                name="yeast_cells[]"
                                fullWidth
                              />
                            </Box>
                            <Box>
                              <TextField
                                label="Bacteria"
                                name="bacteria[]"
                                fullWidth
                              />
                            </Box>
                            <Box>
                              <TextField
                                label="Oil Droplets"
                                name="oil_droplets[]"
                                fullWidth
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                label="Undigested Food Particles"
                                name="undigested_food[]"
                                fullWidth
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <TextField
                          label="Remarks"
                          name="remarks[]"
                          multiline
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
                <Box display="flex" m={1}>
                  <Box flexGrow={1} />
                  <Box>
                    <CardActions>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        disabled={savebtnStoolDisabled}
                      >
                        Save Result
                      </Button>
                    </CardActions>
                  </Box>
                </Box>
              </Fragment>
            ))}
        </Card>
      </form>
    </>
  );
};

export default Laboratory;
