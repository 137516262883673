import React, { useEffect, useState, Fragment, useContext } from "react";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import Notify from "../../notification/Notify";
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import WrapTextIcon from "@material-ui/icons/WrapText";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Label from "../../utils/Label";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { UsersData } from "../../ContextAPI";

const Report = () => {
  const history = useHistory();
  const [category, setCategory] = useState("record");
  const [selectedId, setSelectedId] = useState([]);
  const [details, setDetails] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userData = useContext(UsersData);

  var totalpayment = 0;
  var totalrefund = 0;

  const [record, setRecord] = useState({
    data: [],
    ready: false,
  });

  const [selectedRefund, setSelectedRefund] = useState({
    data: null,
    dialog: false,
  });

  const [recordRefund, setRecordRefund] = useState({
    data: [],
    dialog: false,
  });

  const getBillingRecords = () => {
    var params = { management_id: userData.users.management_id };
    axios
      .get("cashier/billing/records/list", { params })
      .then((response) => {
        const data = response.data;
        setRecord({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getBillingRecordsRefund = () => {
    var params = { management_id: userData.users.management_id };
    axios
      .get("cashier/billing/records/refund-list", { params })
      .then((response) => {
        const data = response.data;
        setRecordRefund({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getBillingRecordsDetails = (trace_number, receiptid) => {
    var params = {
      management_id: userData.users.management_id,
      trace_number: trace_number,
      receipt_id: receiptid,
    };
    axios
      .get("cashier/billing/records/details/by-orderid", { params })
      .then((response) => {
        const data = response.data;
        setDetails(data);
        setSelectedId(trace_number);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleRefundOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", userData.users.user_id);
    formdata.append("username", userData.users.username);

    var error = [];

    if (
      formdata.get("refund_reason").length === 0 ||
      formdata.get("refund_reason").trim() === ""
    ) {
      Notify.fieldRequired("refund_reason");
      error = "error";
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      Notify.fieldRequired("password");
      error = "error";
    }
    if (error.length > 0) {
      console.log("form has an error.");
    } else {
      setIsSubmitting(true);
      axios
        .post("cashier/billing/records/refund-orderbyid", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getBillingRecordsDetails(
              formdata.get("order_id"),
              formdata.get("receipt_number")
            );
            setSelectedRefund({ data: null, dialog: false });
            getBillingRecords();
            getBillingRecordsRefund();
            Notify.successRequest("Item refund.");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    getBillingRecordsRefund();
    getBillingRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={Paper} variant="outlined">
      <Box ml={2} mt={2}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ textTransform: "uppercase" }}
          component={Box}
        >
          {category === "record" ? (
            " Billings Record "
          ) : (
            <>
              <IconButton onClick={() => setCategory("record")}>
                <ArrowBackIcon color="secondary" />
              </IconButton>
              Refund Item List
            </>
          )}
        </Typography>
      </Box>

      {category === "record" && (
        <TableContainer>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b> Date </b>
                  </TableCell>
                  <TableCell>
                    <b> Patient </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Bill </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Action </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.ready ? (
                  record.data.length > 0 ? (
                    record.data.map((data, index) => (
                      <Fragment key={index}>
                        <TableRow hover>
                          <TableCell>
                            {Notify.dateTimeConvert(data.created_at)}
                          </TableCell>
                          <TableCell>
                            {` ${data.fname} ${data.lname} `}
                          </TableCell>
                          <TableCell align="right">
                            <span className="d-none">
                              {(totalpayment += parseFloat(data.totalpayment))}
                              {(totalrefund = parseFloat(data.totalrefund))}
                            </span>
                            {Notify.convertToNumber(data.totalpayment)}
                          </TableCell>
                          <TableCell align="center">
                            {/* <Tooltip title="Refund Details" arrow>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  getBillingRecordsDetails(
                                    data.trace_number,
                                    data.receipt_number
                                  );
                                }}
                              >
                                <WrapTextIcon />
                              </IconButton>
                            </Tooltip> */}
                            <Tooltip title="View Details" arrow>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  history.push(
                                    `/bmcdc/app/cashier/billing/receipt/${data.receipt_number}`
                                  )
                                }
                              >
                                <ArrowForwardIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse
                              in={selectedId === data.trace_number}
                              timeout="auto"
                              unmountOnExit
                            >
                              {selectedId && (
                                <Box margin={1}>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    component="div"
                                  >
                                    <b> Order Details </b>
                                  </Typography>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell> Department </TableCell>
                                        <TableCell> Billing </TableCell>
                                        <TableCell align="center">
                                          Amount
                                        </TableCell>
                                        <TableCell align="center">
                                          Refunded
                                        </TableCell>
                                        <TableCell align="center">
                                          Action
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {details.length > 0 ? (
                                        details.map((data, index) => (
                                          <TableRow key={index}>
                                            <TableCell>
                                              {data.bill_department}
                                            </TableCell>
                                            <TableCell>
                                              {data.bill_name}
                                            </TableCell>
                                            <TableCell align="right">
                                              {data.bill_amount}
                                            </TableCell>
                                            <TableCell align="center">
                                              <Label
                                                color={
                                                  Boolean(
                                                    parseInt(data.is_refund)
                                                  )
                                                    ? "error"
                                                    : "primary"
                                                }
                                              >
                                                {Boolean(
                                                  parseInt(data.is_refund)
                                                )
                                                  ? "Yes"
                                                  : "No"}
                                              </Label>
                                            </TableCell>
                                            <TableCell align="center">
                                              <Tooltip
                                                title={
                                                  Boolean(
                                                    parseInt(data.is_refund)
                                                  )
                                                    ? data.is_refund_reason
                                                    : "Refund Item"
                                                }
                                                arrow
                                              >
                                                <Box>
                                                  <Button
                                                    disabled={Boolean(
                                                      parseInt(data.is_refund)
                                                    )}
                                                    color="secondary"
                                                    onClick={() =>
                                                      setSelectedRefund({
                                                        data,
                                                        dialog: true,
                                                      })
                                                    }
                                                  >
                                                    refund
                                                  </Button>
                                                </Box>
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>
                                        ))
                                      ) : (
                                        <TableRow>
                                          <TableCell colSpan={3} align="center">
                                            <Typography color="secondary">
                                              No details found.
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography color="secondary">
                          No complete appointment found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography color="primary">Please wait...</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {/* <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <b> Payment </b>
                  </TableCell>
                  <TableCell>
                    &#8369; {Notify.convertToNumber(totalpayment)}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody> */}
              {/* <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <b> Refund </b>
                  </TableCell>
                  <TableCell>
                    &#8369; {Notify.convertToNumber(totalrefund)}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Refund Details" arrow>
                      <IconButton
                        color="primary"
                        onClick={() => setCategory("refund")}
                      >
                        <ArrowForwardIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody> */}
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <b> Total: </b>
                  </TableCell>
                  <TableCell align="right">
                    &#8369;
                    {Notify.convertToNumber(
                      parseFloat(totalpayment) - parseFloat(totalrefund)
                    )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </TableContainer>
      )}

      {category === "refund" && <RenderRefundList list={recordRefund} />}

      {selectedRefund.data &&
        selectedRefund.dialog && ( // refund dilaog
          <Dialog
            open={selectedRefund.dialog}
            onClose={() => setSelectedRefund({ data: null, dialog: false })}
            disableBackdropClick
          >
            <form onSubmit={handleRefundOrder}>
              <DialogTitle>Refund Order</DialogTitle>
              <DialogContent dividers>
                <Box mb={2}>
                  {/* hidden files */}
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="trace_number"
                    value={selectedRefund.data.trace_number}
                    name="trace_number"
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="order_id"
                    value={selectedRefund.data.order_id}
                    name="order_id"
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Doctor"
                    value={selectedRefund.data.doctors_id}
                    name="doctor_id"
                  />

                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Id"
                    value={selectedRefund.data.cpr_id}
                    name="cpr_id"
                  />

                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Department"
                    value={selectedRefund.data.bill_department}
                    name="department"
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Receipt"
                    value={selectedRefund.data.receipt_number}
                    name="receipt_number"
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Patient Id"
                    value={selectedRefund.data.patient_id}
                    name="patient_id"
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Billing"
                    name="bill_name"
                    value={selectedRefund.data.bill_name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Amount To Refund"
                    name="refund_amount"
                    defaultValue={selectedRefund.data.bill_amount}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Refund Reason"
                    name="refund_reason"
                    rows={3}
                    multiline
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Password"
                    name="password"
                    type="password"
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<HighlightOffIcon />}
                  color="default"
                  variant="contained"
                  onClick={() =>
                    setSelectedRefund({ data: null, dialog: false })
                  }
                >
                  No
                </Button>
                <Button
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <MoneyOffIcon />
                    )
                  }
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  Refund
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
    </Box>
  );
};

export default Report;

const RenderRefundList = ({ list }) => {
  var totalrefund = 0;

  return (
    <TableContainer>
      <PerfectScrollbar>
        {list.ready ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b> Date </b>
                </TableCell>
                <TableCell>
                  <b> Bill </b>
                </TableCell>
                <TableCell>
                  <b> Amount </b>
                </TableCell>
                <TableCell>
                  <b> Reason </b>
                </TableCell>
                <TableCell>
                  <b> Refund By </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.data.length > 0 ? (
                list.data.map((data, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>
                        {Notify.dateTimeConvert(data.is_refund_date)}
                      </TableCell>
                      <TableCell> {data.bill_name} </TableCell>
                      <TableCell align="right">
                        <span className="d-none">
                          {(totalrefund += parseFloat(data.bill_amount))}
                        </span>
                        {Notify.convertToNumber(data.bill_amount)}
                      </TableCell>
                      <TableCell> {data.is_refund_reason} </TableCell>
                      <TableCell> {data.is_refund_by} </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography color="secondary">No record found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell />
                <TableCell>
                  <b> Total Refund : </b>
                </TableCell>
                <TableCell>
                  <b> {Notify.convertToNumber(totalrefund)} </b>
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          Notify.loading()
        )}
      </PerfectScrollbar>
    </TableContainer>
  );
};
