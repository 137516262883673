import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
  CardMedia,
} from "@material-ui/core";
import { KeyboardArrowLeft } from "@material-ui/icons";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Select from "react-select";
import ResultToEditIndex from "src/laboratory/editconfirmed";

const EditConfirmedResult = () => {
  const { order_id } = useParams();
  const { users } = useContext(UsersData);
  const history = useHistory();
  const [department, setDepartment] = useState({ data: [], ready: false });
  const [specTest, setSpecTest] = useState({ data: [], ready: false });
  const theme = useTheme();
  const [selectedSpecTest, setSelectedSpecTest] = useState("");

  const getAllTestByTransactionId = useCallback(async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      order_id: order_id,
    };
    let response = await axios.get("laboratory/get/all-test/by-tracerno", {
      params,
    });
    const data = response.data;
    setDepartment({ data, ready: true });
  }, [users, order_id]);

  const handleGetSpecTestByDept = async (e) => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      order_id: order_id,
      department: e.value,
    };
    let response = await axios.get("laboratory/get/all-spec-test/by-dept", {
      params,
    });
    const data = response.data;
    setSpecTest({ data, ready: true });
  };

  useEffect(() => {
    getAllTestByTransactionId();
  }, [order_id, getAllTestByTransactionId]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "edit result",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton
              onClick={() => history.push("/bmcdc/app/laboratory/lab-report/")}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h4">Edit Result</Typography>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box component={Paper} variant="outlined" p={2}>
            <Box mb={2}>
              <Typography
                color="primary"
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
              >
                Select to edit
              </Typography>
            </Box>

            <Box mb={2}>
              <Select
                options={department.data}
                onChange={(data) => handleGetSpecTestByDept(data)}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 99999,
                    color: "#000",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: 55,
                    backgroundColor: "transparent",
                  }),
                  singleValue: () => ({
                    color: theme.palette.type === "dark" ? "#fff" : "#000",
                  }),
                }}
              />
            </Box>

            <Box mb={2}>
              <Select
                options={specTest.data}
                onChange={(data) => {
                  setSelectedSpecTest(data);
                  console.log("data", data);
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 99999,
                    color: "#000",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: 55,
                    backgroundColor: "transparent",
                  }),
                  singleValue: () => ({
                    color: theme.palette.type === "dark" ? "#fff" : "#000",
                  }),
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          {selectedSpecTest === "" && <RenderSelectPatient />}
          {selectedSpecTest !== "" && (
            <ResultToEditIndex
              selectedSpecTest={selectedSpecTest}
              close={() => setSelectedSpecTest("")}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditConfirmedResult;

const RenderSelectPatient = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />
    <Typography color="secondary" variant="h3">
      NO TEST SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select test in the list to edit result.
    </Typography>
  </Box>
);
