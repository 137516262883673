import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const UrinalysisEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/urinalysis/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {Boolean(parseInt(info.data.urinalysis)) && (
              <Box>
                <Box mb={2} display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="h6" align="center">
                      <b> Urinalysis </b>
                    </Typography>
                  </Box>
                </Box>

                <Box hidden={!Boolean(parseInt(info.data.is_processed))}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">color :</span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            required
                            name="color"
                            margin="dense"
                            defaultValue={
                              info.data.color === null ? "" : info.data.color
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">reaction :</span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            required
                            name="reaction"
                            margin="dense"
                            defaultValue={
                              info.data.reaction === null
                                ? ""
                                : info.data.reaction
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              transparency :
                            </span>
                          </Typography>
                        </Box>

                        <Box>
                          <TextField
                            fullWidth
                            name="transparency"
                            margin="dense"
                            defaultValue={
                              info.data.transparency === null
                                ? ""
                                : info.data.transparency
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              sp gravity :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="sp_gravity"
                            margin="dense"
                            defaultValue={
                              info.data.sp_gravity === null
                                ? ""
                                : info.data.sp_gravity
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              albumin (negative) :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="albumin"
                            margin="dense"
                            defaultValue={
                              info.data.albumin === null
                                ? ""
                                : info.data.albumin
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              sugar (negative):
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="sugar"
                            margin="dense"
                            defaultValue={
                              info.data.sugar === null ? "" : info.data.sugar
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">pus cells:</span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="pus_cell"
                            margin="dense"
                            defaultValue={
                              info.data.pus_cell === null
                                ? ""
                                : info.data.pus_cell
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">R.B.C:</span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="rbc"
                            margin="dense"
                            defaultValue={
                              info.data.rbc === null ? "" : info.data.rbc
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              epithelial cells :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="epithelial_cell"
                            margin="dense"
                            defaultValue={
                              info.data.epithelial_cell === null
                                ? ""
                                : info.data.epithelial_cell
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              mucus threads :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="mucus_threads"
                            margin="dense"
                            defaultValue={
                              info.data.mucus_threads === null
                                ? ""
                                : info.data.mucus_threads
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              renal cells :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="renal_cell"
                            margin="dense"
                            defaultValue={
                              info.data.renal_cell === null
                                ? ""
                                : info.data.renal_cell
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              yeast cells :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="yeast_cell"
                            margin="dense"
                            defaultValue={
                              info.data.yeast_cell === null
                                ? ""
                                : info.data.yeast_cell
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">hyaline :</span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="hyaline"
                            margin="dense"
                            defaultValue={
                              info.data.hyaline === null
                                ? ""
                                : info.data.hyaline
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">rbc cast :</span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="rbc_cast"
                            margin="dense"
                            defaultValue={
                              info.data.rbc_cast === null
                                ? ""
                                : info.data.rbc_cast
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">wbc cast :</span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="wbc_cast"
                            margin="dense"
                            defaultValue={
                              info.data.wbc_cast === null
                                ? ""
                                : info.data.wbc_cast
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              coarse granular cast :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="coarse_granular_cast"
                            margin="dense"
                            defaultValue={
                              info.data.coarse_granular_cast === null
                                ? ""
                                : info.data.coarse_granular_cast
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              fine granular cast :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="fine_granular_cast"
                            margin="dense"
                            defaultValue={
                              info.data.fine_granular_cast === null
                                ? ""
                                : info.data.fine_granular_cast
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              pus in clumps :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="pus_in_clumps"
                            margin="dense"
                            defaultValue={
                              info.data.pus_in_clumps === null
                                ? ""
                                : info.data.pus_in_clumps
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              rbc in clumps :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="rbc_in_clumps"
                            margin="dense"
                            defaultValue={
                              info.data.rbc_in_clumps === null
                                ? ""
                                : info.data.rbc_in_clumps
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              calcium oxalate :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="calcium_oxalate"
                            margin="dense"
                            defaultValue={
                              info.data.calcium_oxalate === null
                                ? ""
                                : info.data.calcium_oxalate
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">uric acid :</span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="uric_acid"
                            margin="dense"
                            defaultValue={
                              info.data.uric_acid === null
                                ? ""
                                : info.data.uric_acid
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              amorphous urate :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="amorphous_urate"
                            margin="dense"
                            defaultValue={
                              info.data.amorphous_urate === null
                                ? ""
                                : info.data.amorphous_urate
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              amorphous phosphate :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="amorphous_phosphate"
                            margin="dense"
                            defaultValue={
                              info.data.amorphous_phosphate === null
                                ? ""
                                : info.data.amorphous_phosphate
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              calcium carbonate :
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="calcium_carbonate"
                            margin="dense"
                            defaultValue={
                              info.data.calcium_carbonate === null
                                ? ""
                                : info.data.calcium_carbonate
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              ammonium biurate:
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="ammonium_biurate"
                            margin="dense"
                            defaultValue={
                              info.data.ammonium_biurate === null
                                ? ""
                                : info.data.ammonium_biurate
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              triple phosphate:
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="triple_phosphate"
                            margin="dense"
                            defaultValue={
                              info.data.triple_phosphate === null
                                ? ""
                                : info.data.triple_phosphate
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              spermatozoa:
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="spermatozoa"
                            margin="dense"
                            defaultValue={
                              info.data.spermatozoa === null
                                ? ""
                                : info.data.spermatozoa
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              trichomonas vaginalis:
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="trichomonas_vaginalis"
                            margin="dense"
                            defaultValue={
                              info.data.trichomonas_vaginalis === null
                                ? ""
                                : info.data.trichomonas_vaginalis
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              micral test:
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="micral_test"
                            margin="dense"
                            defaultValue={
                              info.data.micral_test === null
                                ? ""
                                : info.data.micral_test
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">
                              urine ketone:
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="urine_ketone"
                            margin="dense"
                            defaultValue={
                              info.data.urine_ketone === null
                                ? ""
                                : info.data.urine_ketone
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box mt={1.5} mr={1} flexGrow={1}>
                          <Typography>
                            <span className="text-capitalize">others:</span>
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            fullWidth
                            name="others"
                            margin="dense"
                            defaultValue={
                              info.data.others === null ? "" : info.data.others
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box mt={2}>
                    <TextField
                      fullWidth
                      label="Remarks"
                      name="remarks"
                      margin="dense"
                      multiline
                      defaultValue={
                        info.data.remarks === null ? "" : info.data.remarks
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}

            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default UrinalysisEditForm;
