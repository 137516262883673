import React, {  Fragment, useContext, useEffect, useState } from 'react';   
import Notify from 'src/notification/Notify';
import Axios from 'axios';  
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Card, TextField, CardContent, IconButton, Button, CircularProgress } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'; 
import IsMountedRef from 'src/utils/IsMountedRef';
import { UsersData } from 'src/ContextAPI';

const UnsavePrescription = ({ patient_id, prescType, getUnsavePrescription, connection }) =>{

    const[ unsave, setUnsave] = useState({ data: [], ready: false }) 
    const [removeSelectedId, setRemoveSelectedId] = useState(null)
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
    const [isProcess, setIsProcess] = useState(false)
    const [saveDialogOpen, setSaveDialogOpen] = useState(false)
    const [ isSaving, setIsSaving] = useState(false)
    const mounted  = IsMountedRef()
    const { users } = useContext(UsersData)

    const unsavePrescription = () =>{
        var formdata = new FormData();   
        formdata.set('user_id', users.user_id);    
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', prescType === 'virtual-prescription' ? 'online' : 'local')
        
        Axios.post('prescription/local/product-unsave', formdata )
        .then((response)=>{ 
            const data = response.data;   
            if(mounted.current){  
                setUnsave({ data, ready: true })
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const closeRemovedialog = () =>{
        setRemoveSelectedId(null)
        setRemoveDialogOpen(false) 
    }

    const handleSelectedProd = (e) =>{
        setRemoveDialogOpen(true)
        setRemoveSelectedId(e.currentTarget.id)  
    }

    const handleRemove = (e) =>{
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);   
        formdata.set('user_id', users.user_id);    
        formdata.set('username', users.username);    
        formdata.set('remove_id', removeSelectedId);   
        formdata.set('connection', prescType === 'virtual-prescription' ? 'online' : 'local')
        
        var error = []; 
        if(error.length > 0){
            Notify.consoleLog('remove presc')
        }else{ 
            setIsProcess(true)
            Axios.post('prescription/local/product-unsave-remove', formdata )
            .then((response) => { 
                const data = response.data;    
                if(data ==='success'){
                    setRemoveDialogOpen(false)
                    getUnsavePrescription()
                    unsavePrescription();
                    Notify.successRequest('remove presc')
                }else{
                    Notify.warnRequest('remove presc')
                } 
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() =>{
                setIsProcess(false)
            })
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        e.persist();
        
        var formdata = new FormData(e.target)
            formdata.set('user_id', users.user_id) 
            formdata.set('username', users.username)
            formdata.set('patient_id', patient_id)
            formdata.set('connection', prescType === 'virtual-prescription' ? 'online' : 'local')
            
        var error = [];
        if(error.length > 0){
            console.warn("Unable to process sample, Form has an error.")
        }else{ 
            setIsSaving(true)
            Axios.post('prescription/doctor/local/prescriptionsaveallUnsave', formdata )
            .then((response) => { 
                const data = response.data;   
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'success'){ 
                    setSaveDialogOpen(false)
                    unsavePrescription()
                    getUnsavePrescription()
                    Notify.successRequest('prescription save')
                }
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() =>{ 
                setIsSaving(false)
            })
        }
    }

    useEffect(() =>{

        unsavePrescription()

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    return(
        <Fragment>
            <Box mb={1} hidden={ connection === 'online'}>
                <Typography variant="h6" color="textSecondary">  Unsave Prescription </Typography>
            </Box>

            {
                unsave.ready ? 
                    unsave.data.length > 0 ? 
                        unsave.data.map((data, index)=>{
                            return(
                                <Card 
                                    key={index}  
                                    elevation={0} 
                                >
                                    <CardContent>
                                        <Box display="flex">
                                            <Box flexGrow={1}>
                                                <Typography variant="h6" className={`gtc-uppercase`}>
                                                    <b> &bull; { data.product_name === null ? data.prescription : data.product_name } </b>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IconButton
                                                    id={ data.id }
                                                    onClick={ handleSelectedProd } 
                                                    color="secondary"
                                                >
                                                    <TrashIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>

                                        <Box>
                                            <Typography variant="subtitle1">
                                                QUANTITY : { data.quantity }
                                            </Typography> 
                                        </Box> 

                                        <Box>
                                            <Typography variant="subtitle1" color="textSecondary"> 
                                                Sig. { data.remarks }
                                            </Typography> 
                                        </Box>
                                    </CardContent>   
                                </Card>
                            )
                        })
                    :  Notify.noRecord() 
                : Notify.loading()
            }

            {/* save prescription dialog */}
            { unsave.data.length > 0 &&  (
                <Box mt={3}>
                    <Button 
                        variant='contained'
                        color="primary"
                        onClick={()=> setSaveDialogOpen(true)}
                    > 
                        Save Prescription 
                    </Button>
                </Box>
            )}

            {/* remove prescription dialog */}  
            <Dialog open={ removeDialogOpen } onClose={ closeRemovedialog }>  
                <form onSubmit={ handleRemove }>
                    <DialogContent> 
                        <Typography>
                            Are you sure to remove this item?
                        </Typography>

                        <Box display="flex" justifyContent="flex-end" mt={2}> 
                            <Button 
                                variant="contained" 
                                color="default"
                                onClick={ closeRemovedialog }
                                startIcon={ <HighlightOffIcon /> }
                            > 
                                No 
                            </Button>
                            <Box ml={2}>
                                <Button 
                                    variant="contained" 
                                    color="secondary"
                                    type="submit"
                                    disabled = { isProcess}
                                    startIcon={ 
                                        isProcess ? <CircularProgress size={20} color="inherit" /> : <CheckCircleOutlineIcon /> 
                                    }
                                > 
                                    Yes 
                                </Button>
                            </Box>
                        </Box>
                        
                    </DialogContent> 
                </form>
            </Dialog>

            <Dialog 
                open = { saveDialogOpen }
                onClose={()=> setSaveDialogOpen(false) }
            >
                <DialogTitle> Save Prescription</DialogTitle> 
                <form onSubmit={ handleSave }>
                    <DialogContent dividers>
                        Verify your account to continue.
                        <Box>
                            <TextField 
                                variant="outlined"
                                label="Enter your password"
                                name="password"
                                type="password"
                                fullWidth 
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions> 
                        <Button 
                            variant="contained" 
                            color="default"
                            onClick={()=> setSaveDialogOpen(false) }
                            startIcon={ <HighlightOffIcon /> }
                        > 
                                No 
                        </Button>
                        <Button 
                            variant="contained" 
                            color="primary"
                            type="submit"
                            disabled = { isSaving}
                            startIcon={ 
                                isSaving ? <CircularProgress size={20} color="inherit" /> : <CheckCircleOutlineIcon /> 
                            }
                        > 
                                Save 
                        </Button> 
                    </DialogActions>
                </form>
            </Dialog> 
        </Fragment>
    )
}

export default UnsavePrescription;