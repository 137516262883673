import React, { useContext, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import {
  Avatar,
  Dialog,
  Zoom,
  IconButton,
  makeStyles,
  Badge,
  CircularProgress,
  Button,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Paper } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { CancelOutlined, Send } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Cropper from "react-cropper";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 30px",
    width: "100%",
  },
  image: {
    marginTop: 25,
    marginBottom: 55,
    height: isMobile ? 250 : 400,
    border: "1px solid #aaa",
    margin: "auto",
    display: "block",
  },
  tag: {
    zIndex: 1,
    position: "absolute",
    paddingTop: 26,
    marginLeft: 38,
  },
  tag_label: {
    backgroundColor: "#00000094",
    color: "#fff",
    padding: 2,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
  },
}));

const PatientHeaderDetails = ({ getPatientInformation, patient_id, info }) => {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  const { users } = useContext(UsersData);
  const [_uploadprocess, setUploadprocess] = useState(false);

  const [imgUploadTemp, setImgUploadTemp] = useState(null);
  const [openCropImage, setOpenCropImage] = useState(false);
  const [image, setImage] = useState(null);

  var profileimage = useRef();

  var cropperRef = useRef();

  const handleClickupload = () => {
    profileimage.click();
  };

  // const handleUploadImage = (event) => {
  //   var ext = event.target.files[0].type.split("/")[1];
  //   var maxfile = event.target.files[0].size / 1024 / 1024;
  //   var extArray = ["png", "jpeg", "jpg"];
  //   var resetUpload = event.target;
  //   if (extArray.indexOf(ext) === -1) {
  //     Notify.customToast("Image Not Allowed", "Upload PNG and JPEG only.");
  //     resetUpload.value = "";
  //   } else if (maxfile.toFixed(2) > 2) {
  //     Notify.customToast(
  //       "Image Not Allowed",
  //       "Image size too big, Upload 2mb or less."
  //     );
  //     resetUpload.value = "";
  //   } else {
  //     var formdata = new FormData();
  //     formdata.set("user_id", users.user_id);
  //     formdata.set("patient_id", patient_id);
  //     formdata.set("profile", event.target.files[0]);
  //     formdata.set(
  //       "current_profile",
  //       info.data.length > 0
  //         ? info.data.image !== null
  //           ? info.data.image
  //           : ""
  //         : ""
  //     );
  //     setUploadprocess(true);
  //     axios
  //       .post("nurse/information/patient-uploadnewprofile", formdata)
  //       .then((response) => {
  //         const data = response.data;
  //         if (data === "success") {
  //           Notify.successRequest("profile");
  //           getPatientInformation();
  //           resetUpload.value = "";
  //         }
  //       })
  //       .catch((error) => {
  //         Notify.requestError(error);
  //       })
  //       .finally(() => {
  //         setUploadprocess(false);
  //       });
  //   }
  // };

  const handleUploadImage = (event) => {
    var file = event.target.files[0];
    if (file !== undefined) {
      var reader = new FileReader();
      reader.onloadend = function () {
        var b64 = reader.result;
        setImgUploadTemp(b64);
      };
      reader.readAsDataURL(file);
      setOpenCropImage(true);
    }
  };

  const handleUpdatePicture = () => {
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("managament_id", users.managament_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("patient_id", patient_id);
    formdata.append("image", image);
    axios
      .post("nurse/update/patient-prof-pic", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("profile");
          getPatientInformation();
          setOpenCropImage(false);
          setImage(null);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setUploadprocess(false);
      });
  };

  return (
    <>
      <Box component={Paper} variant="outlined" borderRadius={4} p={2}>
        {info.ready ? (
          info.data ? (
            <div className="clearfix">
              <div className="float-left">
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    _uploadprocess ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <Box
                      // hidden={info.data.image !== null}
                      >
                        <CameraAltIcon
                          style={{
                            width: isMobile
                              ? 30
                              : window.innerWidth >= 899 &&
                                window.innerWidth <= 959
                              ? 60
                              : window.innerWidth <= 898
                              ? 50
                              : 40,
                            height: isMobile
                              ? 30
                              : window.innerWidth >= 899 &&
                                window.innerWidth <= 959
                              ? 60
                              : window.innerWidth <= 898
                              ? 50
                              : 40,
                            cursor: "pointer",
                          }}
                          onClick={handleClickupload}
                        />
                      </Box>
                    )
                  }
                >
                  <Avatar
                    style={{
                      width: "120px",
                      height: "120px",
                      cursor: "pointer",
                    }}
                    alt="profile-pic"
                    src={
                      info.data.image !== null
                        ? imageLocation + "patients/" + info.data.image
                        : ""
                    }
                    onClick={() => {
                      setSelectedImage(info.data.image);
                      setOpenLightbox(true);
                    }}
                  >
                    {info.data.image === null ? "No Image" : ""}
                  </Avatar>
                  <input
                    type="file"
                    onChange={handleUploadImage}
                    className="d-none"
                    ref={(ref) => (profileimage = ref)}
                  />
                </Badge>
              </div>
              <div className="ml-sm-4 float-left">
                <p className="h5 header-label text-capitalize m-0 my-2 font-weight-bold">
                  {info.data.lastname}, {info.data.firstname} {info.data.middle}
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    {" "}
                    Age:{" "}
                  </span>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  {Notify.calculateAge(info.data.birthday)} years old
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    {" "}
                    Gender:{" "}
                  </span>{" "}
                  &nbsp;&nbsp; {info.data.gender}
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    {" "}
                    Birthday:{" "}
                  </span>{" "}
                  {Notify.birthday(info.data.birthday)}
                </p>
              </div>
            </div>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>

      <Dialog
        open={openLightbox}
        onClose={() => setOpenLightbox(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="sm"
        fullWidth
      >
        <Box display="flex">
          <Box flexGrow={1} />
          <IconButton
            color="secondary"
            onClick={() => {
              setOpenLightbox(false);
            }}
          >
            <CancelOutlined />
          </IconButton>
        </Box>
        <Box>
          <SwipeableViews
            className={classes.root}
            slideStyle={{ padding: "0 10px" }}
            enableMouseEvents
            index={current}
            onChangeIndex={(e) => setCurrent(e)}
          >
            <Box>
              <img
                alt="attach-file-pt"
                src={`${imageLocation}/patients/${selectedImage}`}
                className={classes.image}
              />
            </Box>
          </SwipeableViews>
        </Box>
      </Dialog>

      {/* for copping */}
      <Dialog
        open={openCropImage}
        fullScreen
        onClose={() => setOpenCropImage(false)}
        TransitionComponent={Zoom}
        transitionDuration={900}
      >
        <Box
          display="flex"
          width="100%"
          height="80vh"
          justifyContent="center"
          alignItems="center"
        >
          <Cropper
            style={{ height: "100%", width: "100%" }}
            zoomTo={0.5}
            aspectRatio={1}
            src={image === null ? imgUploadTemp : image}
            viewMode={1}
            guides={false}
            background={false}
            responsive={true}
            dragMode={"move"}
            cropBoxResizable={true}
            checkOrientation={false}
            rotatable
            scalable
            ref={cropperRef}
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          height={"20vh"}
          justifyContent="center"
          alignItems="center"
        >
          <Box mr={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Send />}
              disabled={imgUploadTemp === null}
              onClick={() => {
                const imageElement = cropperRef.current;
                const cropper = imageElement.cropper;
                setImage(cropper.getCroppedCanvas().toDataURL());
                setImgUploadTemp(null);
                // setOpenCropImage(false);
                // handleUpdatePicture();
              }}
            >
              Confirm
            </Button>
          </Box>

          <Box mr={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Send />}
              disabled={image === null}
              onClick={handleUpdatePicture}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default PatientHeaderDetails;
