import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  Paper,
  CardMedia,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Notify from "src/notification/Notify";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMouse } from "@fortawesome/free-solid-svg-icons";
import BankTransactionDetails from "./BankTransactionDetails";

const BankTransaction = () => {
  const [search, setSearch] = useState("");
  const { users } = useContext(UsersData);
  const [bank, setBank] = useState({ data: [], ready: false });
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [totalBalance, setTotalBalance] = useState(null);

  const getBankAccountList = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      const request = await axios.get("accounting/get/bank-list", { params });
      const data = request.data;
      setBank({ data, ready: true });
      setTotalBalance(calculateTotalBalance(data));
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  const calculateTotalBalance = (data) => {
    var totalBal = 0;
    for (let i = 0; i < data.length; i++) {
      totalBal += parseFloat(data[i].total_bank_balance) * parseFloat(1);
    }
    return totalBal;
  };

  useEffect(() => {
    if (users.management_name === "BMCDC HQ") {
      getBankAccountList();
    }
    // eslint-disable-next-line
  }, [getBankAccountList]);

  const searchable = bank.data.filter((data) => {
    return data.bank_name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box>
            <Box>
              <Box>
                <Typography
                  color="primary"
                  variant={"subtitle1"}
                  style={{ fontWeight: "bolder" }}
                >
                  BANK LIST
                </Typography>
              </Box>
              <Box>
                <TextField
                  label="Search bank"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box component={Paper} mb={1}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box flexGrow={1} mt={2} mb={2}>
                <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                  TOTAL BALANCE
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bolder" }}
                >
                  P {Notify.convertToNumber(parseFloat(totalBalance))}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Account</TableCell>
                    <TableCell align="center">Balance</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bank.ready ? (
                    bank.data.length > 0 ? (
                      searchable.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>{data.bank_name}</TableCell>
                          <TableCell align="right">
                            {Notify.convertToNumber(data.total_bank_balance)}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              onClick={() => setSelectedBankId(data.bank_id)}
                            >
                              <FontAwesomeIcon icon={faMouse} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography color="primary">
                          Loading, please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box>
            {selectedBankId === null && <RenderSelectBank />}
            {selectedBankId && (
              <BankTransactionDetails
                selectedBankId={selectedBankId}
                resetDisplay={() => {
                  getBankAccountList();
                }}
                close={() => setSelectedBankId(null)}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BankTransaction;

const RenderSelectBank = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />

    <Typography color="secondary" variant="h3">
      NO BANK SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select bank in the list to view details.
    </Typography>
  </Box>
);
