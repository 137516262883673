import React, { Fragment } from "react";
import Container from "src/layout/Container";
import { Grid, Paper, Tab, Tabs, Box } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import PharmacyInventory from "./PharmacyInventory";
import PharmacySales from "./PharmacySales";
import PharmacyStocks from "./PharmacyStocks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faHandHoldingUsd,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const HIMSPharmacyMonitor = () => {
  const [category, setCategory] = React.useState(0);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "pharmacy monitoring",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="Pharmacy Monitoring"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component={Paper} variant="outlined" mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab
                  wrapped
                  label="Inventory"
                  icon={<FontAwesomeIcon icon={faDatabase} size={"2x"} />}
                />
                <Tab
                  wrapped
                  label="Sales"
                  icon={<FontAwesomeIcon icon={faHandHoldingUsd} size={"2x"} />}
                />
                <Tab
                  wrapped
                  label="Stock Notification"
                  icon={<FontAwesomeIcon icon={faQuestionCircle} size={"2x"} />}
                />
              </Tabs>
            </Box>

            <TabPanel value={category} index={0}>
              <PharmacyInventory />
            </TabPanel>

            <TabPanel value={category} index={1}>
              <PharmacySales />
            </TabPanel>

            <TabPanel value={category} index={2}>
              <PharmacyStocks />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default HIMSPharmacyMonitor;
