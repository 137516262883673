import { Box, Grid, makeStyles, Tabs, Tab, Paper } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import React, { useEffect, useState } from "react";
import PsychologyOrder from "./PsychologyOrder";
import PsychologyUnpaidList from "./PsychologyUnpaidList";
import PsychologyPaidList from "./PsychologyPaidList";

const useStyle = makeStyles({
  indicatorLeft: {
    left: "0px",
  },
});

const PsychologyLocalOrder = ({
  connection,
  patient_id,
  getPermission,
  getPatientsList,
  permission,
}) => {
  const classes = useStyle();
  const [category, setCategory] = useState("order");

  const handleChange = (event, cat) => {
    event.persist();
    setCategory(cat);
  };

  useEffect(() => {}, [patient_id]);

  return (
    <Grid container spacing={2}>
      <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
        <Grid item xs={12} sm={12} md={9}>
          <TabPanel value={category} index={"order"}>
            <PsychologyOrder patient_id={patient_id} connection={connection} />
          </TabPanel>

          <TabPanel value={category} index={"order-unpaid"}>
            <PsychologyUnpaidList
              patient_id={patient_id}
              connection={connection}
            />
          </TabPanel>

          <TabPanel value={category} index={"order-paid"}>
            <PsychologyPaidList
              patient_id={patient_id}
              connection={connection}
              getPatientsList={() => getPatientsList()}
              getPermission={() => getPermission()}
              permission={permission}
            />
          </TabPanel>
        </Grid>
      </Box>

      <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
        <Grid item xs={12} sm={12} md={3}>
          <Box component={Paper} elevation={2} mb={2}>
            <Tabs
              value={category}
              onChange={handleChange}
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              orientation={window.innerWidth > 959 ? "vertical" : "horizontal"}
              classes={{
                indicator: classes.indicatorLeft,
              }}
            >
              <Tab wrapped value="order" label="Create Order" />

              <Tab wrapped value="order-unpaid" label="Unpaid Order" />

              <Tab wrapped value="order-paid" label="Paid Order" />
            </Tabs>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default PsychologyLocalOrder;
