
import React, { useEffect, useState, Fragment } from 'react';
import {
    Box, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Typography,
    Button, IconButton, Tooltip, Collapse, CircularProgress, Grid
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';
import Notify from 'src/notification/Notify';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Label from 'src/utils/Label';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import DateFnsUtils from '@date-io/date-fns';
import { UsersData } from 'src/ContextAPI';


const LaboratoryReport = () => {
    const {users} = React.useContext(UsersData);
    const [category, setCategory] = useState('record')
    const [selectedId, setSelectedId] = useState([])
    const [record, setRecord] = useState({
        data: [],
        ready: false
    })
    const [details, setDetails] = useState([])
    const [recordRefund, setRecordRefund] = useState({
        data: [],
        dialog: false
    })
    var totalpayment = 0;
    var totalrefund = 0;
    const [dateFrom, setDateFrom] = useState(new Date())
    const [dateTo, setDateTo] = useState(new Date())
    const [isSubmitting, setIsSubmitting] = useState(false)

    //okay
    const getBillingRecords = () => {
        var formdata = new FormData();
        formdata.set('management_id', users.management_id);

        axios.get('cashier/billing/records/list', formdata)
            .then((response) => {
                const data = response.data;
                setRecord({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getBillingRecordsByDate = () => {
        var formdata = new FormData();
        formdata.set('management_id', users.management_id);
        formdata.set('date_from', new Date(dateFrom).toLocaleString());
        formdata.set('date_to', new Date(dateTo).toLocaleString());
        var error = [];

        if (parseInt(new Date(dateTo).getTime()) < parseInt(new Date(dateFrom).getTime())) {
            error = 'error'
            Notify.customToast('Invalid Date To', 'Date To must be greather than Date From')
        }

        if (error.length > 0) {
            console.log('Error is sample only..')
        } else {
            setIsSubmitting(true)
            axios.post('doctor/billing/records/list-bydate', formdata)
            .then((response) => {
                const data = response.data;
                setRecord({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            }).finally(() =>{ 
                setIsSubmitting(false)
            });
        }
    }

    //okay
    const getBillingRecordsRefund = () => {
        var formdata = new FormData();
        formdata.set('management_id', users.management_id);

        axios.get('cashier/billing/records/refund-list', formdata)
            .then((response) => {
                const data = response.data;
                setRecordRefund({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    
    //okay
    const getBillingRecordsDetails = (orderid, receiptid) => {
        var formdata = new FormData();
        formdata.set('management_id', users.management_id);
        formdata.set('order_id', orderid);
        formdata.set('receipt_id', receiptid);

        axios.get('cashier/billing/records/details/by-orderid', formdata)
            .then((response) => {
                const data = response.data;
                setDetails(data)
                setSelectedId(orderid)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        getBillingRecordsRefund()
        getBillingRecords()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <> 
            {category === 'record' && (
                <>
                    <Box mb={2} p={2}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box my={2}>
                                    <Typography
                                        variant="subtitle2"
                                        color="primary"
                                        className="gtc-uppercase"
                                    >
                                        Billings Record
                                </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Box display="flex">
                                        <Box flexGrow={1}>
                                            <DatePicker
                                                fullWidth
                                                margin="dense"
                                                label="From"
                                                format="MM/dd/yyyy"
                                                inputVariant="outlined"
                                                value={dateFrom}
                                                onChange={(date) => setDateFrom(date)}
                                            />
                                        </Box>

                                        <Box ml={2}>
                                            <DatePicker
                                                fullWidth
                                                margin="dense"
                                                label="To"
                                                format="MM/dd/yyyy"
                                                inputVariant="outlined"
                                                value={dateTo}
                                                onChange={(date) => setDateTo(date)}
                                            />
                                        </Box>
                                        <Box mt={1.1} ml={1}>
                                            <Button
                                                onClick={() => {
                                                    getBillingRecordsByDate()
                                                }}
                                                disabled={isSubmitting}
                                                variant="contained" color="primary" startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <SearchIcon />}>
                                                Search
                                        </Button>
                                        </Box>
                                    </Box>
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Box>

                    <TableContainer>
                        <PerfectScrollbar>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> <b> Date </b> </TableCell>
                                        <TableCell> <b> Patient </b> </TableCell>
                                        <TableCell> <b> Bill </b> </TableCell>
                                        <TableCell> <b> Department </b> </TableCell>
                                        <TableCell> <b> Action </b> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        record.ready ?
                                            record.data.length > 0 ?
                                                record.data.map((data, index) => (
                                                    <Fragment key={index}>
                                                        <TableRow hover>
                                                            <TableCell align="right"> {Notify.dateTimeConvert(data.created_at)} </TableCell>
                                                            <TableCell align="left"> {` ${data.fname} ${data.lname} `} </TableCell>
                                                            <TableCell align="right">
                                                                <span className="d-none">
                                                                    {totalpayment += parseFloat(data.totalpayment)}
                                                                    {totalrefund = parseFloat(data.totalrefund)}
                                                                </span>
                                                                {Notify.convertToNumber(data.totalpayment)}
                                                            </TableCell>
                                                            <TableCell align="left"> {` ${data.bill_from} `} </TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="Details" arrow>
                                                                    <Box>
                                                                        <IconButton
                                                                            disabled={ data.order_id === null }
                                                                            color="primary"
                                                                            onClick={() => { getBillingRecordsDetails(data.order_id, data.receipt_number) }}
                                                                        > <WrapTextIcon /> </IconButton>
                                                                    </Box>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                <Collapse in={selectedId === data.order_id} timeout="auto" unmountOnExit>
                                                                    {selectedId && (
                                                                        <Box margin={1}>
                                                                            <Typography color="primary" variant="subtitle2" gutterBottom component="div">
                                                                                ORDER DETAILS
                                                                            </Typography>
                                                                            <Table size="small">
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell> <b> Department </b> </TableCell>
                                                                                        <TableCell> <b> Billing </b> </TableCell>
                                                                                        <TableCell align="center"> <b> Amount </b> </TableCell>
                                                                                        <TableCell> <b> Refunded </b> </TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {
                                                                                        details.length > 0 ?
                                                                                            details.map((data, index) => (
                                                                                                <TableRow key={index}>
                                                                                                    <TableCell align="left">
                                                                                                        {data.bill_department}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="left">
                                                                                                        {data.bill_name}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="right">
                                                                                                        {data.bill_amount}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center">
                                                                                                        <Label color={Boolean(parseInt(data.is_refund)) ? 'error' : 'primary'}>
                                                                                                            {Boolean(parseInt(data.is_refund)) ? 'Yes' : 'No'}
                                                                                                        </Label>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            ))
                                                                                            :
                                                                                            <TableRow>
                                                                                                <TableCell colSpan={4}>
                                                                                                    <Typography color="secondary"> No details found. </Typography>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                        </Box>
                                                                    )}
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Fragment>
                                                ))
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        <Typography color="secondary">
                                                            No complete appointment found.
                                                    </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            :
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography color="primary">
                                                        Please wait...
                                                </Typography>
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                                <TableBody>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="left"> <b> Payment </b> </TableCell>
                                        <TableCell align="right"> &#8369; <b>  {Notify.convertToNumber(totalpayment)} </b>  </TableCell>
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </TableBody>
                                <TableBody>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="left"> <b> Refund </b> </TableCell>
                                        <TableCell align="right"> &#8369; <b>  {Notify.convertToNumber(totalrefund)} </b>  </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Refund Details" arrow>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => setCategory('refund')}
                                                > <ArrowForwardIcon /> </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableBody>
                                <TableBody>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="left"> <b> Total </b> </TableCell>
                                        <TableCell align="right"> &#8369; <b>  {Notify.convertToNumber(parseFloat(totalpayment) - parseFloat(totalrefund))} </b> </TableCell>
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </PerfectScrollbar>
                    </TableContainer>
                </>
            )}

            {category === 'refund' && (
                <>
                    <Box mb={2}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box my={2}>
                                    <Typography
                                        variant="subtitle2"
                                        color="primary"
                                        className="gtc-uppercase"
                                    >
                                        <IconButton onClick={() => setCategory('record')}> <ArrowBackIcon color="secondary" /> </IconButton>  Refund Item List
                                </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <RenderRefundList list={recordRefund} />
                </>
            )} 
        </>
    )
}
export default LaboratoryReport;



const RenderRefundList = ({ list }) => {

    var totalrefund = 0;

    return (
        <TableContainer>
            <PerfectScrollbar>
                {
                    list.ready ?
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"> <b> Date </b> </TableCell>
                                    <TableCell align="center"> <b> Bill </b> </TableCell>
                                    <TableCell align="center"> <b> Amount </b> </TableCell>
                                    <TableCell align="center"> <b> Reason </b> </TableCell>
                                    <TableCell align="center"> <b> Refund By </b> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    list.data.length > 0 ?
                                        list.data.map((data, index) => {
                                            return (
                                                <TableRow hover key={index}>
                                                    <TableCell align="right"> {Notify.dateTimeConvert(data.is_refund_date)} </TableCell>
                                                    <TableCell align="left"> {data.bill_name} </TableCell>
                                                    <TableCell align="right">
                                                        <span className="d-none"> {totalrefund += parseFloat(data.bill_amount)}</span>
                                                        {Notify.convertToNumber(data.bill_amount)}
                                                    </TableCell>
                                                    <TableCell align="left"> {data.is_refund_reason} </TableCell>
                                                    <TableCell align="left"> {data.is_refund_by} </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Typography color="secondary">
                                                    No record found.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                            <TableBody>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="left"> <b> Total Refund :  </b> </TableCell>
                                    <TableCell align="right"> &#8369; <b> {Notify.convertToNumber(totalrefund)} </b> </TableCell>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        </Table>
                        : Notify.loading()
                }
            </PerfectScrollbar>
        </TableContainer>
    )
}