


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { CardMedia, Box } from '@material-ui/core';
// import Notify from '../notification/Notify';
import Axios from 'axios';
import IsMountedRef from '../utils/IsMountedRef';

const useStyles = makeStyles(() => ({
    thumbnail: {
        maxHeight: 350,
        borderRadius: 15,
        cursor: 'grab',
    },
    thumbnail_title: {
        background: 'linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0))',
        color: '#fff',
        position: 'absolute',
        bottom: 1,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomLeftRadius: 15,
        fontSize: '20px',
        whiteSpace: 'nowrap',
        width: '90%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        "&:hover": {
            color: '#0078ff'
        }
    },
    tag: {
        zIndex: 1,
        position: 'absolute',
        right: 60,
        paddingTop: 10,
    },
    tag_label: {
        backgroundColor: '#00000094',
        color: '#fff',
        padding: 2,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 20,
    }
}));

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const Featured = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [featured, setFeatured] = React.useState([])
    const ismounted = IsMountedRef()

    const getFeaturedNews = async () => {
        try {
            var params = { is_featured: 1 }
            let response = await Axios.get('gtc/news/featured-list', { params })
            if (ismounted.current) {
                setFeatured(response.data)
            }
        } catch (error) {
            console.log(error);
            // Notify.requestError(error)
        }
    }

    React.useEffect(() => {
        getFeaturedNews()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        Boolean(parseInt(featured.length)) && (
            <Box>
                {/* count and active label */}
                <div className={classes.tag}>
                    <span className={classes.tag_label}> {`${activeStep + 1} / ${featured.length}`} </span>
                </div>
                <SwipeableViews
                    index={activeStep}
                    onChangeIndex={(step) => setActiveStep(step)}
                    enableMouseEvents
                    style={styles.featuredContainer}
                    slideStyle={styles.slideContainer}
                >
                    {featured.map((data, index) => (
                        <Box
                            key={index}
                            styles={styles.slide}
                        >
                            <CardMedia
                                component={'img'}
                                alt="gtc-news"
                                src={`${imageLocation}news/thumbnail/${data.news_thumbnail}`}
                                className={classes.thumbnail}
                            />
                            <div className={classes.thumbnail_title} onClick={() => window.open(data.news_url, "_blank")}>
                                {data.news_title}
                            </div>
                        </Box>
                    ))}
                </SwipeableViews>
            </Box>
        )
    );
}

export default Featured;

const styles = {
    featuredContainer: { padding: '0 20px' },
    slideContainer: { padding: '0 10px' },
};