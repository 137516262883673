
import React, { Fragment, useState, useEffect, useCallback } from 'react' 
import { Button, 
    // Dialog, DialogActions, DialogContent, Zoom, 
    Box, Grid, Table, TableHead, TableRow, TableBody, TableCell, Typography, Divider } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import Print from '@material-ui/icons/Print';  
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrescription } from '@fortawesome/free-solid-svg-icons';
import Notify from 'src/notification/Notify'; 
import { useParams } from 'react-router';

const imageLocation = process.env.REACT_APP_API_IMAGE;   

const DonascoRx = () =>{ 
    const [doctorsInfo, setDoctorsInfo] = useState([])
    const [doctorsInfoRd, setDoctorsInfoRd] = useState(false)
    const [prescDetails, setPrescDetails] = useState([])
    const [prescDetailsRd, setPrescDetailsRd] = useState(false)
    const [patientInfo, setPatientInfo] = useState([])
    const [patientInfoRd, setPatientInfoRd] = useState(false)
    const { claim_id , patient_id, doctors_id } = useParams()

    const getDoctorsInfo = useCallback(() => { 
        const params = { doctors_id }

        Axios.get('patients/rx/printable/doctorsdetails', { params })
        .then((response) =>{
            const data = response.data
            setDoctorsInfo(data)
            setDoctorsInfoRd(true) 
        }).catch((error) =>{
            Notify.requestError(error)
        })
    } , [ doctors_id] );

    const getPatientInfo = useCallback(() => { 
        const params = { patient_id } 
        
        Axios.get('doctor/patient/patient-information', { params })
        .then((response) =>{
            const data = response.data
            setPatientInfo(data)
            setPatientInfoRd(true)  
        }).catch((error) =>{
            Notify.requestError(error)
        })
    }, [ patient_id] );

    const getPrescriptionDetails = useCallback(() => {
        var formdata  = new FormData();
            formdata.set('claim_id', claim_id) 
            formdata.set('patient_id', patient_id)
            formdata.set('user_id', doctors_id)

        Axios.post('patients/rx/printable/prescriptiondetails', formdata)
        .then((response) =>{
            const data = response.data 
            setPrescDetails(groupRxByTwoForDocDonasco(data).filter((x) => x.length !== 0)) //remove empty array
            setPrescDetailsRd(true)  
        }).catch((error) =>{
            Notify.requestError(error)
        })
    }, [ claim_id,patient_id, doctors_id] ); 

    useEffect( () =>{
        getDoctorsInfo()
        getPrescriptionDetails()
        getPatientInfo()
    }, [getDoctorsInfo, getPrescriptionDetails, getPatientInfo])
 
    
    return (
        <Fragment>
            {/* dialog for print rx */}
            <Box 
                zIndex={9999999}
                bgcolor="white"
                position="relative"
                display="block" 
                width={'100%'}
                p={3} 
                className="docdonascoPageBreak"
            >
                {
                    prescDetailsRd ?  
                        prescDetails.length > 0 ?
                            prescDetails.map((rxitems, rxindex) =>{
                                return( 
                                    <div key={rxindex}>
                                        {
                                            doctorsInfoRd ?
                                                doctorsInfo.length > 0 ?
                                                    
                                                    <Box align="center" mb={1}>
                                                        {console.log('doctorsInfo', doctorsInfo)}
                                                        <Box>
                                                            <Typography variant="h6" className={`gtc-uppercase`}>
                                                                <b> {doctorsInfo[0].header} </b>
                                                            </Typography> 
                                                        </Box>
                            
                                                        <Box>
                                                            <Typography variant="subtitle1"  className={`gtc-uppercase`} color="textPrimary">
                                                                {doctorsInfo[0].sub_header}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {doctorsInfo[0].location}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {doctorsInfo[0].contact_no}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {doctorsInfo[0].days_open}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                : <Box> 
                                                    { Notify.noRecord() }
                                                    <Typography>
                                                        Doctors Information Not Found.
                                                    </Typography>
                                                </Box>
                                            : Notify.loading()
                                        }
                                            
                                        <Divider /> 

                                        <Box mt={1}>
                                            {/* patients information */} 

                                            {
                                                patientInfoRd ? 
                                                    patientInfo.length > 0 ?
                                                        <>
                                                            <Box mb={1}>
                                                                <b> Name: </b> <span className="text-capitalize"> { patientInfo[0].firstname } { patientInfo[0].middle } { patientInfo[0].firstname } </span>
                                                            </Box>
                                                            <Grid container> 
                                                                <Grid item xs={6}>
                                                                    <Box>
                                                                        <b> Gender: </b> { patientInfo[0].gender }
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <Box>
                                                                                <b> Age: </b> { Notify.calculateAge(patientInfo[0].birthday) }
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Box>
                                                                                <b> Rx: </b> {`${rxindex + 1} of ${prescDetails.length}`}
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid> 
                                                                <Grid item xs={12}>
                                                                    <Box mt={1}>
                                                                        <b> Date: </b> { prescDetails.length > 0 ? Notify.dateTimeConvert(new Date().toLocaleDateString()) : 'No date found.'}
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    : Notify.noRecord()
                                                : Notify.loading()
                                            }

                                            {/* RX ICON */}
                                            <Box mt={2}>
                                                <FontAwesomeIcon icon={faPrescription} size="5x" />
                                            </Box>

                                            <Table className="gtc-table-borderless" padding={'checkbox'}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell> <b> Products </b> </TableCell>
                                                        <TableCell> <b> Quantity </b> </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody> 
                                                    { 
                                                        rxitems.length > 0 ?
                                                            rxitems.map((data, index) => {
                                                                return(  
                                                                    <Fragment key={index}>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <Box>
                                                                                    <Typography 
                                                                                        variant="subtitle2"
                                                                                        noWrap={false}
                                                                                    >
                                                                                        <span className="text-uppercase"> { data.product_name } { data.type } { data.dosage }  </span>
                                                                                    </Typography> 
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography 
                                                                                        variant="subtitle2" 
                                                                                        color="textSecondary"
                                                                                        noWrap={false}
                                                                                    >
                                                                                        Sig. { data.remarks }
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Box>
                                                                                    { data.quantity }
                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow> 
                                                                    </Fragment>
                                                                )
                                                            })
                                                        :  <TableRow>
                                                                <TableCell align="right">
                                                                    <Typography color="secondary">
                                                                        No record found.
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow> 
                                                    } 
                                                </TableBody>
                                            </Table>
                                        </Box> 

                                        {/* signs and licencess */}
                                        {
                                            doctorsInfoRd ?
                                                doctorsInfo.length > 0 ?
                                                    <Box display="flex">
                                                        <Box flexGrow={1}/>
                                                        <Box>
                                                            <Box>
                                                                <Box align="left"> 
                                                                    {/* <img src="/sample-sign.png" alt="" width="120" style={{position: 'relative', top: 140}}/> */}
                                                                    <img  
                                                                        src={imageLocation+"doctors/doctors_signatures/"+doctorsInfo[0].image_signature } 
                                                                        alt="" 
                                                                        width="120" 
                                                                        style={{position: 'relative', top: 30}}
                                                                    />
                                                                </Box> 

                                                                <Typography variant="subtitle1">
                                                                    <b className="text-capitalize"> { doctorsInfo[0].name } </b>
                                                                </Typography>
                                                            </Box>
                                                                
                                                            <Box>
                                                                <Typography variant="subtitle2">
                                                                    PRC No. { doctorsInfo[0].cil_umn }
                                                                </Typography>
                                                            </Box>
                                                            <Box> 
                                                                <Typography variant="subtitle2">
                                                                    PTR No.
                                                                </Typography>
                                                            </Box>
                                                            <Box> 
                                                                <Typography variant="subtitle2">
                                                                    TIN No.
                                                                </Typography>
                                                            </Box>
                                                            <Box> 
                                                                <Typography variant="subtitle2">
                                                                    S2 No.
                                                                </Typography>
                                                            </Box>
                                                        </Box> 
                                                    </Box>
                                                : Notify.noRecord()
                                            : Notify.loading()
                                        }   

                                         <div className="breakerpage" />{/*  force to next page on print  */}
                                    </div> 
                                )
                            })

                        : Notify.noRecord()

                    : Notify.loading()
                }  

                <Box className="d-print-none" mt={3} display="flex" justifyContent="flex-end"> 
                    <Box mr={2}>
                        <Button onClick={() => window.close() } startIcon={<Cancel />} size="large" variant="contained" color="default">
                            Close
                        </Button>
                    </Box>

                    <Box>
                        <Button onClick={() => window.print() } startIcon={<Print />} size="large" variant="contained" color="primary">
                            Print
                        </Button>
                    </Box>  

                </Box>
            </Box> 
        </Fragment>
    )
}

export default DonascoRx;





const groupRxByTwoForDocDonasco = (data) => {  
       return data.reduce((acc, val, ind) => { 
           
           if(ind < 2){    
               acc.push([]) 
               acc[0].splice(1, 0, data[ind])
               acc[0].join()
               return acc;
           }; 

           if(ind >= 2 && ind < 4){ 
               acc.push([]) 
               acc[1].splice(1, 0, data[ind])
               acc[1].join()
               return acc;
           };     

           if(ind >= 4 && ind < 6){ 
                acc.push([]) 
                acc[2].splice(1, 0, data[ind])
                acc[2].join()
                return acc;
            };  
               
           return acc;
       },[]); 
}