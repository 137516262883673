import { Box, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import IsMountedRef from "src/utils/IsMountedRef";

const Specimen = ({ patient_id, trace_number }) => {
  const mounted = IsMountedRef();
  const [list, setList] = useState([]);

  const getSpecimenInReceiving = React.useCallback(async () => {
    let response = await axios.get("laboratory/order/specimen-list", {
      params: {
        patient_id,
        trace_number,
      },
    });

    if (mounted.current) {
      setList(response.data);
    }
  }, [patient_id, trace_number, mounted]);

  useEffect(() => {
    getSpecimenInReceiving();
  }, [getSpecimenInReceiving]);

  return (
    <Box>
      <Box>
        <Typography variant="caption">
          <b> SPECIMEN IN RECEIVING </b>
        </Typography>
      </Box>
      <Box display="flex">
        {list.length > 0 &&
          list.map((data, index) => (
            <Box key={index} mr={1}>
              <Typography style={{ textTransform: "capitalize" }}>
                {data.specimen},
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default Specimen;
