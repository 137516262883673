import React, { Fragment, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  Divider,
  Paper,
  IconButton,
  TextField,
  InputAdornment,
  FormHelperText,
  Grid,
  Zoom,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import PrintIcon from "@material-ui/icons/Print";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { Formik } from "formik";
import * as Yup from "yup";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import PharmacySalesPrint from "./PharmacySalesPrint";

const getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

const PharmacySales = () => {
  const { users } = React.useContext(UsersData);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sales, setSales] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [printSalesDiag, setPrintSalesDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [totalSalesssuu, setTotalSalesssuu] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const calculateTotalSales = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total +=
        parseFloat(data[i].sum_all_total_quantity) *
        parseFloat(data[i].productSrp);
    }
    return total;
  };

  const fetchSales = () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("pharmacy/get-sales-list", { params })
      .then((response) => {
        const data = response.data;
        setSales({ data: data, ready: true });
        setTotalSalesssuu(calculateTotalSales(data));
      })
      .catch((error) => {
        console.log("error : ", error);
        // Notify.requestError(error);
      });
  };

  React.useEffect(() => {
    fetchSales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = sales.data.filter((data) => {
    return data.product.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography style={{ marginTop: 20 }} variant="h6">
            <strong>
              Overall Sales: {Notify.convertToNumber(totalSalesssuu)}
            </strong>
          </Typography>
        </Box>
        <Box mb={1}>
          <TextField
            className={"d-print-none"}
            label="Search brand"
            variant="outlined"
            margin="dense"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Paper component={Box}>
        <Box>
          <Box p={2} borderRadius={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box flexGrow={1} />
              <Box mr={2}>
                <IconButton
                  title="Filter by Date"
                  size="small"
                  className={"d-print-none"}
                  color="primary"
                  onClick={() => setFilterDialog(true)}
                >
                  <FilterListIcon />
                </IconButton>
              </Box>
              <IconButton
                title="Print"
                size="small"
                color="primary"
                onClick={() => setPrintSalesDialog(true)}
              >
                <PrintIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box m={1}>
            <TableContainer component={Box}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> Brand </TableCell>
                    <TableCell align="center"> Generic </TableCell>
                    <TableCell align="center"> Date </TableCell>
                    <TableCell align="center"> Unit </TableCell>
                    <TableCell align="center"> Qty </TableCell>
                    <TableCell align="center"> Amount </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sales.ready ? (
                    sales.data.length > 0 ? (
                      searchable
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                          <TableRow key={index}>
                            <TableCell> {data.product} </TableCell>
                            <TableCell>{data.description}</TableCell>
                            <TableCell align="right">
                              {Notify.dateTimeConvert(data.created_at)}
                            </TableCell>
                            <TableCell>{data.unit}</TableCell>
                            <TableCell align="right">
                              {data.sum_all_total_quantity}
                            </TableCell>
                            <TableCell align="right">
                              {Notify.convertToNumber(
                                data.sum_spec_total_quantity
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography variant="subtitle2" color="secondary">
                            {" "}
                            No sales found{" "}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography variant="subtitle2" color="primary">
                          {" "}
                          Loading...{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TablePagination
                className={"d-print-none"}
                component={"div"}
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={sales.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        </Box>
      </Paper>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={filterDialog}
        TransitionComponent={Zoom}
        transitionDuration={1000}
        disableBackdropClick
      >
        <DialogTitle>
          <Typography color="primary">Filter By Date</Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            user_id: users.user_id,
            management_id: users.management_id,
            date_from: "",
            date_to: "",
          }}
          validationSchema={Yup.object().shape({
            date_from: Yup.string().required(),
            date_to: Yup.string().required(),
          })}
          onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
              const request = await axios.post(
                "pharmacy/get-filter-by-date",
                getFormData(values)
              );
              const data = request.data;
              setSales({ data: data, ready: true });
              setTotalSalesssuu(calculateTotalSales(data));
              resetForm();
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box mb={1}>
                        <TextField
                          required
                          fullWidth
                          label="Date From"
                          error={Boolean(touched.date_from && errors.date_from)}
                          helperText={touched.date_from && errors.date_from}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.date_from}
                          name="date_from"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box mb={1}>
                        <TextField
                          required
                          fullWidth
                          label="Date To"
                          error={Boolean(touched.date_to && errors.date_to)}
                          helperText={touched.date_to && errors.date_to}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.date_to}
                          name="date_to"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box mb={1} display="flex">
                  <Box flexGrow={1} />
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<ClearIcon />}
                    onClick={() => setFilterDialog(false)}
                  >
                    Close
                  </Button>
                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<CheckIcon />}
                    >
                      Go
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={printSalesDiag}
        TransitionComponent={Zoom}
        transitionDuration={500}
        fullScreen
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography
                    component={Box}
                    fontWeight="bold"
                    align="center"
                    variant="h5"
                  >
                    Sales Report
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    className={"d-print-none"}
                    color="secondary"
                    onClick={() => setPrintSalesDialog(false)}
                  >
                    <ClearIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <PharmacySalesPrint close={() => setPrintSalesDialog(false)} />
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default PharmacySales;
