import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Grid,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Send, Trash } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const SaveAndFinalExpense = ({ tempExpense, getTemporaryAddExpense }) => {
  const { users } = useContext(UsersData);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const handleRemoveTempExpense = (e, id) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("cet_id", id);

    axios
      .post("accounting/remove/temp-expense", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          getTemporaryAddExpense();
          e.target.reset();
          Notify.successRequest("add expense");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleConfirmExpense = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("management_id", users.management_id);

    var error = [];
    if (
      formdata.get("invoice_date").length === 0 ||
      formdata.get("invoice_date").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Invoice Date");
    }
    if (
      formdata.get("voucher").length === 0 ||
      formdata.get("voucher").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Voucher");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/save/confirm-expense", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            history.push("/bmcdc/app/accounting/expense");
            e.target.reset();
            Notify.successRequest("add expense");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">Unit Price</TableCell>
              <TableCell align="center">Tax</TableCell>
              <TableCell align="center">Amount PHP</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tempExpense.ready ? (
              tempExpense.data.length > 0 ? (
                tempExpense.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.description}</TableCell>
                    <TableCell align="right">{data.quantity}</TableCell>
                    <TableCell align="right">
                      {Notify.convertToNumber(data.unit_price)}
                    </TableCell>
                    <TableCell align="center">
                      {data.tax === "Non-Vat" ? "Tax Exempt" : "Tax 12%"}
                    </TableCell>
                    <TableCell align="right">
                      {Notify.convertToNumber(
                        parseFloat(data.unit_price) * parseFloat(data.quantity)
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="secondary"
                        onClick={(e) => handleRemoveTempExpense(e, data.cet_id)}
                      >
                        <Trash />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="subtitle2" color="secondary">
                      No record found.
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="subtitle2" color="primary">
                    Loading, Please Wait.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {tempExpense.data.length > 0 && (
          <Box display="flex" mt={2}>
            <Box flexGrow={1} />
            <Box>
              <Button
                startIcon={<Send />}
                variant="contained"
                color="primary"
                onClick={() => setConfirmDialog(true)}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        )}
      </TableContainer>
      <Dialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle>Confirm Expense</DialogTitle>
        <DialogContent>
          <form onSubmit={handleConfirmExpense}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    label="Invoice Date"
                    name="invoice_date"
                    fullWidth
                    required
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    required
                    fullWidth
                    name="voucher"
                    label="Voucher"
                    variant="outlined"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    type="password"
                    label="Password"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box display="flex">
                  <Box flexGrow={1} />
                  <Button
                    variant="contained"
                    color="default"
                    type="reset"
                    startIcon={<HighlightOff />}
                    onClick={() => setConfirmDialog(false)}
                  >
                    Close
                  </Button>

                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <CheckCircleOutline />
                        )
                      }
                      disabled={isSubmitting}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SaveAndFinalExpense;
