import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
import Footer from "src/laboratory/print/Footer";

const HepatitisOrder = ({ order_id, patient_id, formheader }) => {
  const { users } = React.useContext(UsersData);
  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_hepatitis_profile");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log(
        "lab form order laboratory_hepatitis_profile table error",
        error
      );
    }
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box my={2} p={2}>
            <Box>
              <Typography variant="h6" align="center">
                <b> HEPATITIS PROFILE </b>
              </Typography>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> TEST </TableCell>
                    <TableCell> RESULT </TableCell>
                    <TableCell> NORMAL VALUES </TableCell>
                    <TableCell> REMARKS </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {details.map((data, index) => {
                    return (
                      <Fragment key={index}>
                        <TableRow hidden={data.hbsag_quali === null}>
                          <TableCell> HBsAG(Quali) </TableCell>
                          <TableCell>
                            <Typography>
                              {checkResult(data.hbsag_quali)}
                            </Typography>
                          </TableCell>
                          <TableCell> Non Reactive </TableCell>
                          <TableCell> {data.hbsag_quali_remarks} </TableCell>
                        </TableRow>

                        <TableRow hidden={data.antihbs_quali === null}>
                          <TableCell> Anti-HBs(Quali) </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.antihbs_quali)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell> Reactive </TableCell>
                          <TableCell>{data.antihbs_quali_remarks}</TableCell>
                        </TableRow>

                        <TableRow hidden={data.antihcv_quali === null}>
                          <TableCell> Anti-HCV(Quali) </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.antihcv_quali)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell> Non Reactive </TableCell>
                          <TableCell>{data.antihcv_quali_remarks}</TableCell>
                        </TableRow>

                        <TableRow hidden={data.hbsag_quanti === null}>
                          <TableCell> HBsAG(Quanti) </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.hbsag_quanti)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell> {` > 2.0 (Reactive)`} </TableCell>
                          <TableCell> {data.hbsag_quanti_remarks} </TableCell>
                        </TableRow>

                        <TableRow hidden={data.antihbs_quanti === null}>
                          <TableCell> Anti-HBs(Quanti) </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.antihbs_quanti)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell> ABOVE 10 IU/L (Reactive) </TableCell>
                          <TableCell>{data.antihbs_quanti_remarks}</TableCell>
                        </TableRow>

                        <TableRow hidden={data.hbeaag === null}>
                          <TableCell> HBeAg </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.hbeaag)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell> Reactive : {` > 0.105`} </TableCell>
                          <TableCell> {data.hbeaag_remarks} </TableCell>
                        </TableRow>

                        <TableRow hidden={data.antihbe === null}>
                          <TableCell> Anti-HBe </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.antihbe)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell> Non Reactive : {` > 0.81`} </TableCell>
                          <TableCell> {data.antihbe_remarks} </TableCell>
                        </TableRow>

                        <TableRow hidden={data.antihbc_igm === null}>
                          <TableCell> Anti-BHc(IgM) </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.antihbc_igm)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            Non Reactive :{" "}
                            {` > OR = 1.68; Reactive : < OR = 1.68 `}{" "}
                          </TableCell>
                          <TableCell> {data.antihbc_igm_remarks} </TableCell>
                        </TableRow>

                        <TableRow hidden={data.antihav_igm === null}>
                          <TableCell> Anti-HAV(IgM) </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.antihav_igm)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell> Negative </TableCell>
                          <TableCell> {data.antihav_igm_remarks} </TableCell>
                        </TableRow>

                        <TableRow hidden={data.anti_havigm_igg === null}>
                          <TableCell> Anti-HAVIGM/IGG </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.anti_havigm_igg)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell> Negative </TableCell>
                          <TableCell>
                            {" "}
                            {data.anti_havigm_igg_remarks}{" "}
                          </TableCell>
                        </TableRow>

                        <TableRow hidden={data.antihbc_iggtotal === null}>
                          <TableCell> Anti-HBc(IgG Total) </TableCell>
                          <TableCell>
                            <Box>
                              <Typography>
                                {checkResult(data.antihbc_iggtotal)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell> Reactive : {`<1.0`} </TableCell>
                          <TableCell>{data.antihbc_iggtotal_remarks}</TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Footer formheader={formheader} />
        </>
      )}
    </>
  );
};

export default HepatitisOrder;
