import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";
import { useHistory } from "react-router";
import { UsersData } from "src/ContextAPI";

const Password = () => {
  const history = useHistory();
  const { users } = React.useContext(UsersData);

  const [isprocess, setIsProcess] = useState(false);

  const handleChangePassword = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    var error = [];

    if (
      formdata.get("new_password").length === 0 ||
      formdata.get("new_password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new password");
    }

    if (formdata.get("new_password").length < 6) {
      error = "error";
      Notify.customToast("Password Invalid", "Password must be 6 characters");
    }

    if (
      formdata.get("new_password_reenter").length === 0 ||
      formdata.get("new_password_reenter").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new password re-enter");
    }

    if (
      formdata.get("new_password_reenter").length &&
      formdata.get("new_password").length
    ) {
      if (
        formdata.get("new_password_reenter") !== formdata.get("new_password")
      ) {
        error = "error";
        Notify.customToast("Password not match", "Inputed password not match");
      }
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("current password");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      axios
        .post(`doctor/account/change-password`, formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            e.target.reset();
            Notify.successRequest("password updated, You are logout.");
            setTimeout(() => {
              history.push("/bmcdc/app/logout");
            }, 2000);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h6" color="textSecondary">
          Password Information
        </Typography>
      </Box>

      <form onSubmit={handleChangePassword}>
        <Box>
          <TextField
            label=" New Password"
            fullWidth
            variant="outlined"
            name="new_password"
            type="password"
          />
        </Box>

        <Box my={3}>
          <TextField
            label=" Re-enter New Password "
            fullWidth
            variant="outlined"
            name="new_password_reenter"
            type="password"
          />
        </Box>

        <Box my={3}>
          <TextField
            label=" Enter your current password "
            fullWidth
            variant="outlined"
            name="password"
            type="password"
          />
        </Box>

        <Box display="flex" justifyContent={"flex-end"}>
          <Box>
            <Button
              size="large"
              variant={"contained"}
              color="default"
              type="reset"
            >
              Clear
            </Button>
          </Box>

          <Box ml={3}>
            <Button
              size="large"
              variant={"contained"}
              color="primary"
              type="submit"
              disabled={isprocess}
              startIcon={
                isprocess && <CircularProgress color="inherit" size={25} />
              }
            >
              Change Password
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default Password;
