import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Footer from "./Footer";
import Header from "./Header";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const HepatitisProfileOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bolder", color: "#000" }}
            >
              HEPATITIS PROFILE
            </Typography>
          </Box>

          <Box>
            {/* order details */}

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      TEST
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      RESULT
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      NORMAL VALUES
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      REMARKS
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orderDetails.ready ? (
                  orderDetails.data.length > 0 ? (
                    orderDetails.data.map((data, index) => {
                      return (
                        <Fragment key={index}>
                          <TableRow hidden={data.hbsag_quali === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                HBsAG(Quali)
                              </span>
                            </TableCell>
                            <TableCell>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.hbsag_quali}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Non Reactive
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.hbsag_quali_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.antihbs_quali === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Anti-HBs(Quali)
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.antihbs_quali}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Reactive
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.antihbs_quali_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.antihcv_quali === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Anti-HCV(Quali)
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.antihcv_quali}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Non Reactive
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.antihcv_quali_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.hbsag_quanti === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                HBsAG(Quanti)
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.hbsag_quanti}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >{` > 2.0 (Reactive)`}</span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.hbsag_quanti_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.antihbs_quanti === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Anti-HBs(Quanti)
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.antihbs_quanti}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                ABOVE 10 IU/L (Reactive)
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.antihbs_quanti_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.hbeaag === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                HBeAg
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.hbeaag}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Reactive : {` > 0.105`}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.hbeaag_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.antihbe === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Anti-HBe
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.antihbe}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Non Reactive : {` > 0.81`}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.antihbe_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.antihbc_igm === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Anti-BHc(IgM)
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.antihbc_igm}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Non Reactive :
                                {` > OR = 1.68; Reactive : < OR = 1.68 `}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.antihbc_igm_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.antihav_igm === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Anti-HAV(IgM)
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.antihav_igm}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Negative
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.antihav_igm_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.anti_havigm_igg === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Anti-HAVIGM/IGG
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.anti_havigm_igg}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Negative
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.anti_havigm_igg_remarks}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow hidden={data.antihbc_iggtotal === null}>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Anti-HBc(IgG Total)
                              </span>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.antihbc_iggtotal}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                Reactive : {`<1.0`}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.antihbc_iggtotal_remarks}
                              </span>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography
                          color="secondary"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography
                        color="primary"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>

          <Box my={3} align="center">
            <Footer formheader={formheader} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default HepatitisProfileOrder;
