import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { FormInformation, UsersData, UsersHeader } from "../ContextAPI";
import BillingDashboard from "src/billing";
import AccountBilling from "src/billing/account/Account";
import BillingSOA from "src/billing/soa";
import PatientSoa from "src/billing/soa/PatientSoa";
import CompanySoa from "src/billing/soa/CompanySoa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faCog,
  faFolderOpen,
  faHome,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import BillingPrintOption from "src/billing/option/BillingPrintOption";
import CompanyHMOSoa from "src/billing/soa/CompanyHMOSoa";

const RouteBilling = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const [formInfo, setFormInfo] = useState([]);

  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("billing/sidebar/header-infomartion", {
        params,
      });

      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const getCurrentFormInfoForSOA = async () => {
    try {
      var params = {
        user_id: userContext.users.user_id,
        management_id: userContext.users.management_id,
        main_mgmt_id: userContext.users.main_mgmt_id,
      };
      const response = await axios.get("billing/get/form-infomartion", {
        params,
      });
      setFormInfo(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/bmcdc/app/billing",
      subitem: [],
    },
    {
      name: "SOA",
      show: true,
      icon: <FontAwesomeIcon icon={faFolderOpen} color="white" size={"2x"} />,
      path: "/bmcdc/app/billing/soa",
      subitem: [],
    },
    {
      name: "Print Option",
      show: true,
      icon: <FontAwesomeIcon icon={faCog} color="white" size={"2x"} />,
      path: "/bmcdc/app/billing/print-option",
      subitem: [],
    },
    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/bmcdc/app/billing/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/billing/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  const handleRerenderFormInfo = () => {
    getCurrentFormInfoForSOA();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getCurrentFormInfoForSOA();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <FormInformation.Provider
          value={{ formInfo: formInfo, renderFormInfo: handleRerenderFormInfo }}
        >
          <BrowserRouter>
            <Sidebar
              notification={{
                enable: true,
                owner: "billing",
              }}
              header={sidebarHeader}
              routes={sidebarRoute}
              module={"billing"}
            />
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/bmcdc/app/billing" />}
              />

              <Route
                exact
                path="/bmcdc"
                component={() => <Redirect to="/bmcdc/app/billing" />}
              />

              <Route
                exact
                path="/bmcdc/app"
                component={() => <Redirect to="/bmcdc/app/billing" />}
              />

              <Route
                exact
                path="/bmcdc/app/billing"
                component={BillingDashboard}
              />

              <Route
                exact
                path="/bmcdc/app/billing/account"
                component={AccountBilling}
              />

              <Route
                exact
                path="/bmcdc/app/billing/soa"
                component={BillingSOA}
              />

              <Route
                exact
                path="/bmcdc/app/billing/print-option"
                component={BillingPrintOption}
              />

              <Route
                exact
                path="/bmcdc/app/billing/soa/patient/:patient_id"
                component={PatientSoa}
              />

              <Route
                exact
                path="/bmcdc/app/billing/leave-application"
                component={PatientSoa}
              />

              <Route
                exact
                path="/bmcdc/app/billing/soa/company/:company"
                component={CompanySoa}
              />

              <Route
                exact
                path="/bmcdc/app/billing/soa/hmo/:hmo_id"
                component={CompanyHMOSoa}
              />

              <Route exact path="/bmcdc/app/logout" component={Logout} />

              <Route render={() => <PageNotFound title="Page not found" />} />
            </Switch>
          </BrowserRouter>
        </FormInformation.Provider>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteBilling;
