import { useTheme } from "@material-ui/core";

const SelectStyle = () => {
  const theme = useTheme();

  const style = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: theme.palette.type === "dark" ? "#424242" : "#fff",
      color: theme.palette.type === "dark" ? "#fff" : "#000",
      maxHeight: 300,
      overflow: "auto",
    }),
    control: (base) => ({
      ...base,
      minHeight: 55,
      backgroundColor: theme.palette.type === "dark" ? "#424242" : "#fff",
      color: theme.palette.type === "dark" ? "#fff" : "#000",
    }),
    singleValue: () => ({
      color: theme.palette.type === "dark" ? "#fff" : "#000",
    }),
    menuList: () => ({
      color: theme.palette.type === "dark" ? "#fff" : "#000",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return style;
};

export default SelectStyle;
