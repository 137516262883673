import { Card, CardHeader, CardContent, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import New from "./New";
import TempList from "./TempList";
import axios from "axios";

const StockRoomProductIn = () => {
  const { users } = React.useContext(UsersData);
  const mounted = IsMountedRef();
  const [products, setProducts] = useState({ data: [], ready: false });

  const getTempDrProducts = React.useCallback(async () => {
    let response = await axios.get("stockroom/product/dr/temp-list", {
      params: {
        management_id: users.management_id,
        type: "IN",
      },
    });

    if (mounted.current) {
      setProducts({ data: response.data, ready: true });
    }
  }, [mounted, users]);

  useEffect(() => {
    getTempDrProducts();
  }, [getTempDrProducts]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <New getTempDrProducts={() => getTempDrProducts()} />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Card>
          <CardHeader title="Temp. IN Product" />
          <CardContent>
            <TempList
              products={products}
              getTempDrProducts={() => getTempDrProducts()}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StockRoomProductIn;
