import React from 'react'
import { Box, Grid, Paper} from '@material-ui/core'; 
import RadiologistPatientDetailsOrder from './RadiologistPatientDetailsOrder';

const RadiologistPatientDetails = ({ selectedPatient, resetDisplay }) => {  
    return( 
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper
                    variant="outlined"
                    component={Box}
                    p={2}
                > 
                    <RadiologistPatientDetailsOrder details = { selectedPatient } resetDisplay = { resetDisplay }/>
                </Paper>
            </Grid> 
        </Grid> 
    )
}

export default RadiologistPatientDetails;