import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import axios from "axios";
import { UsersData } from "src/ContextAPI";

const CashierORPrint = ({ closeModal, selectedReceipt }) => {
  const userData = React.useContext(UsersData);

  const [details, setDetails] = useState({
    data: [],
    ready: false,
  });

  const getReceiptDetails = () => {
    var params = {
      user_id: userData.users.user_id,
      management_id: userData.users.management_id,
      receipt_number: selectedReceipt,
    };
    axios
      .get("hmis/get-receipt-info-print", { params })
      .then((response) => {
        const data = response.data;
        setDetails({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        console.log("error : ", error);
        // Notify.requestError(error);
      });
  };

  useEffect(() => {
    getReceiptDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box m={2}>
      <Box display="flex" justifyContent="center" align="center" mb={1}>
        <Box>
          <Box mb={1}>
            <Typography variant="h4">
              <strong> PAYMENT RECEIPT </strong>
            </Typography>
          </Box>

          {/* <Typography variant="h5">
            <strong>
              {details.data.length > 0 &&
                (details.data[0].bill_from === "laboratory"
                  ? `${details.data[0].lab_name}`
                  : `${details.data[0].imaging_name}`)}
            </strong>
          </Typography>

          <Typography>
            {details.data.length > 0 &&
              (details.data[0].bill_from === "laboratory"
                ? `${details.data[0].lab_address}`
                : `${details.data[0].imaging_address}`)}
          </Typography> */}
        </Box>
      </Box>

      <Box mb={1}>
        <Typography>
          Name: {details.data.length > 0 && `${details.data[0].patient_name}`}
        </Typography>
      </Box>

      <Box mb={1}>
        <Typography>
          Address:
          {details.data.length > 0 && `${details.data[0].patient_address}`}
        </Typography>
      </Box>

      <Box mb={1}>
        <Typography>
          Date:
          {details.data.length > 0 &&
            Notify.dateTimeConvert(details.data[0].created_at)}
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b> Description </b>
              </TableCell>
              <TableCell>
                <b> Amount </b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details.ready ? (
              details.data.length > 0 ? (
                details.data.map((data, index) => (
                  <TableRow key={index} hover>
                    <TableCell>
                      <Typography variant={"caption"} gtc={`gtc-uppercase`}>
                        {data.bill_name}
                      </Typography>
                    </TableCell>
                    <TableCell align="right"> {data.bill_amount} </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography color="secondary">No record found.</Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography color="primary">Please wait...</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell align="right">
                <b> Total </b>
              </TableCell>
              <TableCell align="right">
                <b>
                  {details.data.length > 0 &&
                    Notify.convertToNumber(details.data[0].bill_total)}
                </b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="right">
                <b> Payment </b>
              </TableCell>
              <TableCell align="right">
                <b>
                  {details.data.length > 0 &&
                    Notify.convertToNumber(details.data[0].bill_payment)}
                </b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="right">
                <b> Change </b>
              </TableCell>
              <TableCell align="right">
                <b>
                  {details.data.length > 0 &&
                    Notify.convertToNumber(
                      parseFloat(details.data[0].bill_payment) -
                        parseFloat(details.data[0].bill_total)
                    )}
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" className={"d-print-none"}>
        <Box flexGrow={1} />
        <Box>
          <Button
            variant="contained"
            color="default"
            onClick={() => closeModal()}
          >
            Close
          </Button>

          <Button
            component={Box}
            ml={2}
            variant="contained"
            color="primary"
            onClick={() => window.print()}
          >
            Print
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CashierORPrint;
