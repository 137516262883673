import {
  Box,
  Typography,
  Paper,
  Collapse,
  IconButton,
} from "@material-ui/core";
import Axios from "axios";
import React, { useState, useCallback, useEffect, Fragment } from "react";
import Notify from "../notification/Notify";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ListAltIcon from "@material-ui/icons/ListAlt";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import WarningIcon from "@material-ui/icons/Warning";
import { grey } from "@material-ui/core/colors";
import { UsersData } from "src/ContextAPI";
import MiscellaneousOrderDetails from "./details/MiscellaneousOrderDetails";

const MiscellaneousTest = ({ formheader, patient_id }) => {
  const { users } = React.useContext(UsersData);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [order, setOrder] = useState({
    data: [],
    ready: false,
  });

  const getMiscellaneousTestOrder = useCallback(() => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);

    Axios.post("laboratory/order/ordernew-miscellaneous", formdata)
      .then((response) => {
        const data = response.data;
        setOrder({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, patient_id]);

  const resetDisplay = () => {
    setSelectedOrder(null);
  };

  useEffect(() => {
    getMiscellaneousTestOrder();
  }, [getMiscellaneousTestOrder]);

  return (
    <>
      <Box my={1}>
        <Typography color="textSecondary" variant="h6">
          Miscellaneous
        </Typography>
      </Box>

      {order.ready ? (
        order.data.length > 0 ? (
          order.data.map((data, index) => {
            return (
              <Fragment key={index}>
                <Paper component={Box} my={2} p={2}>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Box display="flex">
                        <Box mt={1} mr={2}>
                          {Boolean(parseInt(data.is_processed)) ? (
                            <QueryBuilderIcon
                              style={{ fontSize: "2rem" }}
                              color="primary"
                            />
                          ) : Boolean(parseInt(data.is_pending)) ? (
                            <WarningIcon
                              style={{ fontSize: "2rem" }}
                              color="secondary"
                            />
                          ) : (
                            <ListAltIcon
                              style={{ fontSize: "2rem", color: grey[500] }}
                            />
                          )}
                        </Box>

                        <Box>
                          <Box>
                            <Typography
                              variant="subtitle1"
                              className={`gtc-uppercase`}
                            >
                              {Notify.dateTimeConvert(data.date_ordered)}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              className={`gtc-uppercase`}
                            >
                              {data.trace_number}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setSelectedOrder(
                            selectedOrder &&
                              selectedOrder.trace_number === data.trace_number
                              ? null
                              : data
                          )
                        }
                      >
                        {selectedOrder &&
                        selectedOrder.trace_number === data.trace_number ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </Box>
                  </Box>

                  <>
                    {/* order result form */}
                    {selectedOrder && (
                      <Collapse
                        in={selectedOrder.trace_number === data.trace_number}
                      >
                        {selectedOrder.trace_number === data.trace_number && (
                          <Box mt={2}>
                            <MiscellaneousOrderDetails
                              order={selectedOrder}
                              getMiscellaneousTestOrder={() =>
                                getMiscellaneousTestOrder()
                              }
                              resetDisplay={resetDisplay}
                              formheader={formheader}
                            />
                          </Box>
                        )}
                      </Collapse>
                    )}
                  </>
                </Paper>
              </Fragment>
            );
          })
        ) : (
          <Box>{Notify.noRecord()}</Box>
        )
      ) : (
        <Box>{Notify.loading()}</Box>
      )}
    </>
  );
};

export default MiscellaneousTest;
