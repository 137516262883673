import React, { Fragment, useState, useContext } from 'react'; 
import Badge from '@material-ui/core/Badge'; 
import Tabs from '@material-ui/core/Tabs';  
import Tab from '@material-ui/core/Tab'; 
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import Box from '@material-ui/core/Box'; 
import PatientAppointmentLocal from './PatientAppointmentLocal'; 
import PatientAppointmentVirtual from './PatientAppointmentVirtual'; 
import TabPanel from '../../utils/TabPanel';  
import { PatientsUnRead } from 'src/ContextAPI';

function PatientAppointment({ patient_id, connection }) {  
 
    const [category, setCategory] = useState(0);  
    
    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( notifFrom, category, department ) =>{
        
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;
        let count = 0;

        if(notifFrom === 'local'){
            for (let i = 0; i < xx.length; i++) {
                if( patient_id === xx[i].patient_id &&  category === xx[i].category && department === xx[i].department){
                    count += 1;
                }
            }
        }else{
            for (let i = 0; i < yy.length; i++) {
                if(patient_id === yy[i].patient_id && category === yy[i].category && department === yy[i].department){
                    count += 1;
                }
            }
        }
        
        return count;
    } 
    const handleChange = (event, newCategory) => {
        event.persist()
        setCategory(newCategory);
    };
 

    return (
        <Fragment>  
            <Box mb={2}>
                <Grid 
                    container 
                    justify="flex-end" 
                > 
                    <Grid item xs={12} md={5} lg={4}>
                        <Tabs  
                            component={ Paper } 
                            value={category}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"      
                            scrollButtons="auto"  
                            variant="fullWidth"  
                        > 
                            <Tab 
                                wrapped
                                label={
                                    <Badge 
                                        color="error" 
                                        invisible={! Boolean(parseInt(checkPatientUnreadNotif('local','appointment', 'local-appointment')))}
                                        badgeContent={'new'}
                                    > Local </Badge>
                                } 
                            />  
                            <Tab 
                                wrapped
                                label={
                                    <Badge 
                                        color="error" 
                                        invisible={!Boolean(parseInt(checkPatientUnreadNotif('virtual','appointment', 'virtual-appointment')))} 
                                        badgeContent={'new'}
                                    > Virtual </Badge> 
                                } 
                            /> 
                        </Tabs>
                    </Grid>
                </Grid> 
            </Box>

            <Box>
                <TabPanel 
                    value={ category } 
                    index={0}
                >
                    <PatientAppointmentLocal connection={ connection } patient_id = {patient_id} />
                </TabPanel>  

                <TabPanel 
                    value={ category } 
                    index={1}
                >
                    <PatientAppointmentVirtual connection={ connection } patient_id = {patient_id} /> 
                </TabPanel>  
            </Box>
        </Fragment>
    )
}

export default PatientAppointment;
