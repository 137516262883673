
import React, { Fragment  } from 'react'
import {  DialogContent, Box, IconButton } from '@material-ui/core';

import QRCode from 'qrcode.react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const VirtualQR = ({ close, selectedPharmaID }) => {

    return (
        <Fragment>
            <Box display="flex">
                <Box flexGrow={1} />
                <Box>
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            close()
                        }}
                        className={'d-print-none'}
                    >
                        <HighlightOffIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent >
                <Box display="flex" justifyContent="center" alignItems="center" mb={5}>
                    <QRCode value={selectedPharmaID} level="H" size={300} />
                </Box>
            </DialogContent>
        </Fragment>
    )
}

export default VirtualQR;
