import React, { useEffect, useState, useContext } from "react";
import {
  Paper,
  Box,
  Typography,
  Collapse,
  IconButton,
  TablePagination,
  Badge,
  useTheme,
  Tooltip,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import axios from "axios";
import ClinicalMicroscopy from "../orderform/ClinicalMicroscopy";
import Hemathology from "../orderform/Hemathology";
import SerologyOrder from "../orderform/SerologyOrder";
import ChemistryOrder from "../orderform/ChemistryOrder";
import FecalOrder from "../orderform/FecalOrder";
import { PatientsUnRead, UsersData } from "src/ContextAPI";
import {
  MinusCircle,
  PlusCircle as PlusCircleIcon,
  Printer as PrinterIcon,
} from "react-feather";
import PrintableOrder from "./PrintableOrder";
import UrinalysisOrder from "../orderform/UrinalysisOrder";
import ECGOrder from "../orderform/ECGOrder";
import MedicalExamOrder from "../orderform/MedicalExamOrder";
import PapsmearOrder from "../orderform/PapsmearOrder";
import OralGlucoseOrder from "../orderform/OralGlucoseOrder";
import MiscellaneousOrder from "../orderform/MiscellaneousOrder";
import HepatitisOrder from "../orderform/HepatitisOrder";
import ThyroidProfileOrder from "../orderform/ThyroidProfileOrder";
import StooltestOrder from "../orderform/StooltestOrder";
import ImmunologyOrder from "../orderform/ImmunologyOrder";
import CBCOrderForm from "../orderform/CBCOrderForm";
import CovidTestOrder from "../orderform/CovidTestOrder";
import TumorMakerOrder from "../orderform/TumorMakerOrder";
import DrugTestOrder from "../orderform/DrugTestOrder";

const PaidOrder = ({ connection, patient_id }) => {
  const theme = useTheme();
  const [paid, setPaid] = useState({ data: [], ready: false });

  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const unread = useContext(PatientsUnRead);
  const { users } = useContext(UsersData);

  const [printableOrder, setPrintableOrder] = useState({
    order_id: null,
    labmngId: null,
  });

  const checkPatientUnreadNotif = (category, order_id) => {
    let xx = unread.unviewNotif;
    let count = 0;
    for (let i = 0; i < xx.length; i++) {
      if (
        patient_id === xx[i].patient_id &&
        category === xx[i].category &&
        order_id === xx[i].order_id
      ) {
        count += 1;
      }
    }

    return count;
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const getpaidLaboratoryOrder = () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    axios
      .post("doctor/patient/laboratory/order/paid-list", formdata)
      .then((res) => {
        const data = res.data;
        setPaid({ data, ready: true });
      })
      .catch((er) => Notify.requestError(er));
  };

  // const getpaidLaboratoryOrderDetails = (orderid) => {
  //     setSelectedOrderId(selectedOrderId === orderid ? null : orderid)
  //     var formdata = new FormData();
  //     formdata.set('token', token);
  //     formdata.set('user_id', user_id);
  //     formdata.set('patient_id', patient_id);
  //     formdata.set('order_id', orderid);
  //     formdata.set('connection', connection)

  //     axios.post('doctor/patient/doc-notif-update', formdata)
  //     .then((res) => {
  //         getPatientsList()
  //         getPermission()
  //         getpaidLaboratoryOrder()
  //     }).catch((er) => Notify.requestError(er))

  // }

  const handleSelectedTraceNumber = (trace_number) => {
    if (selectedOrderId === trace_number) {
      console.log("trace number currently selected");
      return false;
    } else {
      setSelectedOrderId(trace_number);
    }
  };

  useEffect(() => {
    getpaidLaboratoryOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyPagination = (order, page, limit) => {
    return order.slice(page * limit, page * limit + limit);
  };

  const paginatedOrder = applyPagination(paid.data, page, rowsPerPage);

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" color="textSecondary">
          Laboratory List
        </Typography>
      </Box>

      {paid.ready
        ? paid.data.length > 0
          ? paginatedOrder.map((data, index) => (
              <Paper elevation={2} key={index} component={Box} mb={2} p={2}>
                <Box display="flex">
                  <Box flexGrow={1} mt={1}>
                    <Typography variant="h6">
                      {Notify.dateTimeConvert(data.created_at)}
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip
                      title={
                        data.management_id === users.management_id
                          ? "Print Order"
                          : "Print Unavailable"
                      }
                    >
                      <span>
                        <IconButton
                          disabled={
                            data.management_id === users.management_id
                              ? false
                              : true
                          }
                          onClick={() =>
                            setPrintableOrder({
                              order_id: data.trace_number,
                              labmngId: data.management_id,
                            })
                          }
                        >
                          <PrinterIcon color={theme.palette.primary.light} />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <IconButton
                      onClick={() =>
                        handleSelectedTraceNumber(data.trace_number)
                      }
                    >
                      <Badge
                        invisible={
                          !Boolean(
                            parseInt(
                              checkPatientUnreadNotif(
                                "laboratory",
                                data.trace_number
                              )
                            )
                          )
                        }
                        variant="dot"
                        color="error"
                      >
                        {selectedOrderId === data.trace_number ? (
                          <MinusCircle color={theme.palette.error.main} />
                        ) : (
                          <PlusCircleIcon color={theme.palette.primary.main} />
                        )}
                      </Badge>
                    </IconButton>
                  </Box>
                </Box>

                {/* collapse for order details */}
                {selectedOrderId && (
                  <Collapse in={selectedOrderId === data.trace_number}>
                    {selectedOrderId === data.trace_number && (
                      <Box>
                        <Hemathology
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                        <ClinicalMicroscopy
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                        <SerologyOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                        <ChemistryOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                        <FecalOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <UrinalysisOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <ECGOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <MedicalExamOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <PapsmearOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <OralGlucoseOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <MiscellaneousOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <HepatitisOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <ThyroidProfileOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <StooltestOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <ImmunologyOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <CBCOrderForm
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <CovidTestOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <TumorMakerOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <DrugTestOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                      </Box>
                    )}
                  </Collapse>
                )}
              </Paper>
            ))
          : Notify.noRecord()
        : Notify.loading()}

      <TablePagination
        labelRowsPerPage="List"
        rowsPerPageOptions={[5, 20, 50, 100]}
        component="div"
        count={paid.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {/* printable order by orderid and patient id */}
      {Boolean(printableOrder.order_id) && Boolean(printableOrder.labmngId) && (
        <PrintableOrder
          labownerid={printableOrder.labmngId}
          order_id={printableOrder.order_id}
          patient_id={patient_id}
          close={() => setPrintableOrder({ order_id: null, labmngId: null })}
        />
      )}
    </>
  );
};

export default PaidOrder;
