import {
  Box,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Card,
  Grid,
  FormHelperText,
  useTheme,
} from "@material-ui/core";
import Axios from "axios";
import React, { useContext, useState } from "react";
import { Edit, Trash } from "react-feather";
import Notify from "src/notification/Notify";
import ScrollBar from "react-perfect-scrollbar";
import { UsersData } from "src/ContextAPI";
import { Cancel } from "@material-ui/icons";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
// import SelectStyle from "src/utils/SelectStyle";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const CopyAsInvoiceProductTemp = ({
  copyInvoiceProducts,
  getAllProductsByPONumber,
  po_number,
}) => {
  const [remove, setRemove] = useState({ dialog: false, id: null });
  const [edit, setEdit] = useState({ dialog: false, data: null });
  const [process, setProcess] = useState({ dialog: false, isprocess: false });
  const { users } = useContext(UsersData);
  const [batches, setBatches] = useState([]);
  // const selectStyle = SelectStyle();
  const [productBatch, setProductBatch] = useState(null);
  const [productMfg, setProductMfg] = useState(null);
  const [productExpiry, setProductExpiry] = useState(null);
  const [selectedBatchType, setSelectedBatchType] = useState(null);
  const theme = useTheme();

  const handleRemoveProductItem = async () => {
    var formdata = new FormData();
    formdata.set("remove_id", remove.id);

    let response = await Axios.post(
      "pharmacy/warehouse/inventory/product/po/item-removeintemp",
      formdata
    );

    let result = response.data;

    if (result.message === "success") {
      getAllProductsByPONumber();
      setRemove({ dialog: false, id: null });
      Notify.successRequest("item remove.");
    }
  };

  // const handleSaveConfirmedItems = async () => {
  //   var formdata = new FormData();
  //   formdata.set("management_id", users.management_id);
  //   formdata.set("user_id", users.user_id);
  //   formdata.set("main_mgmt_id", users.main_mgmt_id);
  //   formdata.set("po_number", po_number);

  //   let response = await Axios.post(
  //     "pharmacy/warehouse/inventory/product/po/save-confirmeditem",
  //     formdata
  //   );
  //   let result = response.data;
  //   if (result.message === "success") {
  //     getAllProductsByPONumber();
  //     setProcess({ dialog: false, isprocess: false });
  //     Notify.successRequest("confirm item.");
  //   }
  // };

  const handleSaveInfoProducts = async (e) => {
    e.preventDefault();
    setProcess({ ...process, isprocess: true });
    var formdata = new FormData(e.target);

    formdata.set("management_id", users.management_id);
    formdata.set("username", users.username);
    formdata.set("user_id", users.user_id);
    formdata.set("selectedBatchType", selectedBatchType);
    formdata.set("productBatch", productBatch);
    formdata.set("productMfg", productMfg);
    formdata.set("productExpiry", productExpiry);
    formdata.set(
      "product_id",
      edit.data !== null && edit.data.product_id ? edit.data.product_id : ""
    );

    let response = await Axios.post(
      "pharmacy/warehouse/inventory/product/po/save-products-info",
      formdata
    );

    let result = response.data;

    if (result.message === "code-exist") {
      Notify.fieldInvalid("product code already taken");
    }

    if (result.message === "pass-invalid") {
      Notify.fieldInvalid("password");
    }

    if (result.message === "success") {
      getAllProductsByPONumber();
      setEdit({ dialog: false, data: null });
      Notify.successRequest("edit order");
    }

    setProcess({ ...process, isprocess: false });
  };

  const getProductBatches = async (product_id) => {
    let response = await Axios.get(
      "pharmacy/warehouse/inventory/product-details",
      {
        params: {
          management_id: users.management_id,
          product_id: product_id,
        },
      }
    );

    setBatches(response.data);
  };

  const validationSchema = Yup.object().shape({
    invoice_number: Yup.string().required("Invoice number is a required field"),
    dr_date: Yup.date().required("Delivery date is a required field"),
    dr_by: Yup.string().required("Delivered by is a required field."),
    password: Yup.string().required("Product quantity is a required field"),
  });

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("main_mgmt_id", users.main_mgmt_id);
    formData.append("po_number", po_number);
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const initialValues = {
    user_id: users.user_id,
    username: users.username,
    management_id: users.management_id,
    invoice_number: "",
    dr_date: moment(new Date()).format("YYYY-MM-DD"),
    dr_by: "",
    password: "",
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title="Unprocess Invoice"
          subheader="Note: The button is disable if detect empty information."
        />
        <CardContent>
          <TableContainer>
            <ScrollBar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Code </TableCell>
                    <TableCell> Brand </TableCell>
                    <TableCell> Description </TableCell>
                    <TableCell> Unit </TableCell>
                    <TableCell> Qty </TableCell>
                    <TableCell> Batch Number </TableCell>
                    <TableCell> Expiry Date </TableCell>
                    <TableCell> Mfg Date </TableCell>
                    <TableCell> Msrp </TableCell>
                    <TableCell> Srp </TableCell>
                    <TableCell align="center"> Action </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {copyInvoiceProducts.ready ? (
                    copyInvoiceProducts.data.length > 0 ? (
                      copyInvoiceProducts.data.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell> {data.product_code} </TableCell>
                          <TableCell> {data.product_name} </TableCell>
                          <TableCell> {data.product_generic} </TableCell>
                          <TableCell> {data.unit} </TableCell>
                          <TableCell align="right"> {data.qty} </TableCell>

                          <TableCell> {data.batch_number} </TableCell>
                          <TableCell> {data.expiration_date} </TableCell>
                          <TableCell> {data.manufactured_date} </TableCell>

                          <TableCell align="right">
                            {Notify.convertToNumber(data.msrp)}
                          </TableCell>
                          <TableCell align="right">
                            {Notify.convertToNumber(data.srp)}
                          </TableCell>

                          <TableCell align="center">
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                setRemove({ dialog: true, id: data.id })
                              }
                            >
                              <Trash />
                            </IconButton>

                            <IconButton
                              color="primary"
                              onClick={() => {
                                getProductBatches(data.product_id);
                                setEdit({ dialog: true, data });
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={11}>
                          <Typography color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={11}>
                        <Typography color="primary">
                          Loading, Please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </ScrollBar>
          </TableContainer>

          {copyInvoiceProducts.data.length > 0 && (
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => setProcess({ dialog: true })}
                disabled={
                  parseFloat(copyInvoiceProducts.data[0]._total_count) > 0
                }
              >
                Confirm
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* dialog remove item */}
      <Dialog
        open={remove.dialog}
        onClose={() => setRemove({ dialog: false, id: null })}
      >
        <DialogContent>
          <Box mb={2}>
            <Typography>Are you sure to remove this item?</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Button
                variant="contained"
                color="default"
                onClick={() => setRemove({ dialog: false, id: null })}
              >
                No
              </Button>
            </Box>
            <Box ml={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleRemoveProductItem()}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* dialog for edit info */}
      <Dialog
        open={process.dialog}
        onClose={() => {
          setProcess({ dialog: false, isprocess: false });
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="draggable-handle">Process Invoice Dialog</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
              let response = await Axios.post(
                "pharmacy/warehouse/inventory/product/po/save-confirmeditem",
                getFormData(values)
              );

              let result = response.data;

              if (result.message === "pass-invalid") {
                setErrors({ password: "Password is invalid." });
              }

              if (result.message === "success") {
                Notify.successRequest("product save");
                getAllProductsByPONumber();
                resetForm();
                setProcess({ dialog: false });
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Invoice Number"
                        value={values.invoice_number}
                        name="invoice_number"
                        error={Boolean(
                          touched.invoice_number && errors.invoice_number
                        )}
                        helperText={
                          touched.invoice_number && errors.invoice_number
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Delivery Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={values.dr_date}
                        name="dr_date"
                        error={Boolean(touched.dr_date && errors.dr_date)}
                        helperText={touched.dr_date && errors.dr_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Delivery By"
                        name="dr_by"
                        error={Boolean(touched.dr_by && errors.dr_by)}
                        helperText={touched.dr_by && errors.dr_by}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Password"
                        name="password"
                        type="password"
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box my={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  size="large"
                  variant="contained"
                  color="default"
                  startIcon={<ClearIcon />}
                  onClick={() =>
                    setProcess({ dialog: false, isprocess: false })
                  }
                >
                  No
                </Button>

                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={23} color="inherit" />
                    ) : (
                      <CheckIcon />
                    )
                  }
                  disabled={isSubmitting}
                >
                  Yes
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>

        {/* <DialogContent>
          <Box mb={2}>
            <Typography>Are you sure to confirm this items?</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Button
                variant="contained"
                color="default"
                onClick={() => setProcess({ dialog: false, isprocess: false })}
              >
                No
              </Button>
            </Box>
            <Box ml={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveConfirmedItems()}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </DialogContent> */}
      </Dialog>

      {/* dialog for confirmation */}
      <Dialog
        open={edit.dialog}
        onClose={() => {
          setEdit({ dialog: false, data: null });
        }}
      >
        <DialogTitle>Edit Product</DialogTitle>
        <form onSubmit={handleSaveInfoProducts}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                label="Product Type"
                name="product_type"
                fullWidth
                variant="outlined"
                defaultValue={edit.data !== null ? edit.data.type : ""}
                InputProps={{
                  readOnly: edit.data !== null && edit.data.type ? true : false,
                }}
                required
                hidden
              />

              <TextField
                label="Product Code"
                name="product_code"
                fullWidth
                variant="outlined"
                defaultValue={edit.data !== null ? edit.data.prod_code : ""}
                InputProps={{
                  readOnly:
                    edit.data !== null && edit.data.prod_code ? true : false,
                }}
                required
              />
            </Box>
            <Box>
              <Box my={3}>
                <TextField
                  fullWidth
                  required
                  label="Batch Type"
                  variant="outlined"
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setProductBatch(null);
                    setProductMfg(null);
                    setProductExpiry(null);
                    setSelectedBatchType(e.target.value);
                    if (e.target.value === "existing_batch") {
                      getProductBatches(edit.data.product_id);
                    }
                  }}
                >
                  <option value=""> Select </option>
                  <option value="new_batch"> New Batch </option>
                  <option value="existing_batch"> Existing Batch </option>
                </TextField>
              </Box>

              {selectedBatchType === "new_batch" && (
                <>
                  {/* product_batch */}
                  <Box my={3}>
                    <TextField
                      label="Batch Number"
                      fullWidth
                      variant="outlined"
                      name="product_batch"
                      required
                    />
                  </Box>

                  <Box my={3}>
                    <Grid container spacing={2}>
                      {/* product_manufactured */}
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            label="Manufactured Date"
                            fullWidth
                            variant="outlined"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            name="product_manufactured"
                            required
                          />
                        </Box>
                      </Grid>
                      {/* product_expiration */}
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            label="Expiration Date"
                            fullWidth
                            variant="outlined"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            name="product_expiration"
                            required
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}

              {selectedBatchType === "existing_batch" && (
                <>
                  {/* product_batch */}
                  <Box my={3}>
                    <Select
                      options={batches}
                      onChange={(value) => {
                        setProductBatch(value.value);
                        setProductMfg(value.manufactured_date);
                        setProductExpiry(value.expiration_date);
                      }}
                      placeholder="Select Batch Number"
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 99999,
                          color: "#000",
                        }),
                        control: (base) => ({
                          ...base,
                          minHeight: 55,
                          backgroundColor: "transparent",
                        }),
                        singleValue: () => ({
                          color:
                            theme.palette.type === "dark" ? "#fff" : "#000",
                        }),
                      }}
                    />
                  </Box>

                  <Box my={3}>
                    <Grid container spacing={2}>
                      {/* product_manufactured */}
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            label="Manufactured Date"
                            fullWidth
                            variant="outlined"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={productMfg !== null ? productMfg : ""}
                            name="product_manufactured"
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                      </Grid>
                      {/* product_expiration */}
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            label="Expiration Date"
                            fullWidth
                            variant="outlined"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={productExpiry !== null ? productExpiry : ""}
                            name="product_expiration"
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}

              <Box my={3}>
                <Grid container spacing={2}>
                  {/* product_manufactured */}
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Product Msrp"
                        name="msrp"
                        fullWidth
                        variant="outlined"
                        defaultValue={
                          edit.data !== null ? edit.data.prod_msrp : ""
                        }
                        required
                      />
                    </Box>
                  </Grid>
                  {/* product_expiration */}
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Product Srp"
                        name="srp"
                        fullWidth
                        variant="outlined"
                        defaultValue={
                          edit.data !== null ? edit.data.prod_srp : ""
                        }
                        InputProps={{
                          readOnly:
                            edit.data !== null && edit.data.prod_srp
                              ? true
                              : false,
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2}>
                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  name="password"
                  required
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="default"
              onClick={() => {
                setEdit({ dialog: false, data: null });
              }}
              startIcon={<Cancel />}
            >
              No
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={process.isprocess}
              startIcon={
                process.isprocess ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleOutline />
                )
              }
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default CopyAsInvoiceProductTemp;
