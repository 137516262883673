import React, { useEffect, Fragment, useState, useContext } from "react";
import {
  Box,
  Paper,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  useTheme,
  TablePagination,
  TableFooter,
  Button,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import { Search } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const DoctorsWageDetails = ({
  doctors_id,
  getAllDoctors,
  setStateOpenDetails,
}) => {
  const themes = useTheme();
  const { users } = useContext(UsersData);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [selectedChargeType, setSelectedChargeType] = useState("all");
  const [totalAmount, setTotalAmount] = useState(0);
  const [info, setInfo] = useState({
    data: null,
    ready: false,
  });

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const getPatientInformation = () => {
    var params = {
      doctors_id: doctors_id,
      management_id: users.management_id,
      date: "all",
      charge_type: "all",
    };
    Axios.get("cashier/get/by-doctor-id/doctor-details", { params })
      .then((response) => {
        const data = response.data;
        setInfo({
          data: data,
          ready: true,
        });
        setTotalAmount(calculateTotal(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleFilterByDate = (e) => {
    e.preventDefault();
    e.persist();

    var params = {
      doctors_id: doctors_id,
      management_id: users.management_id,
      // date: e.target.value,
      date_from: selectedDateFrom,
      date_to: selectedDateTo,
      charge_type: selectedChargeType,
    };
    Axios.get("cashier/get/by-doctor-id/doctor-details", { params })
      .then((response) => {
        const data = response.data;
        setInfo({
          data: data,
          ready: true,
        });
        setTotalAmount(calculateTotal(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const calculateTotal = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total += parseFloat(data[i].bill_amount) * 1;
    }
    return total;
  };

  const handleChangeType = (e) => {
    setSelectedChargeType(e.target.value);
  };

  const handleGenerateDocRecord = () => {
    var doctor_share =
      totalAmount / (100 / parseFloat(info.data[0].share_rate));
    var company_rate = 100 - parseFloat(info.data[0].share_rate);
    var company_share = totalAmount / (100 / parseFloat(company_rate));

    var formdata = new FormData();
    formdata.set("doctors_id", doctors_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("type", selectedChargeType);
    formdata.set("date_from", selectedDateFrom);
    formdata.set("date_to", selectedDateTo);
    formdata.set("doctor_share", doctor_share);
    formdata.set("company_share", company_share);
    formdata.set("original_amount", totalAmount);

    Axios.post("cashier/create/salary-record", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("new record");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getPatientInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctors_id]);

  return (
    <>
      {info.ready ? (
        <Box>
          <Box component={Paper} elevation={1} p={2}>
            <Box>
              <Box display="flex">
                <Box flexGrow={1} />
                <Box mb={1}>
                  <TextField
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    name="type"
                    label="Type"
                    variant="outlined"
                    margin="dense"
                    onChange={(e) => handleChangeType(e)}
                    value={selectedChargeType}
                  >
                    <option value={""}> Select </option>
                    <option value="all"> All </option>
                    <option value="cash"> Cash </option>
                    <option value="charge"> Charge </option>
                  </TextField>
                </Box>
                <Box mx={1}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // onChange={handleFilterByDate}
                    onChange={(e) => setSelectedDateFrom(e.target.value)}
                    type="date"
                    variant="outlined"
                    label="Date From"
                    name="date_from"
                    fullWidth
                    margin="dense"
                  />
                </Box>
                <Box mr={1}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // onChange={handleFilterByDate}
                    onChange={(e) => setSelectedDateTo(e.target.value)}
                    type="date"
                    variant="outlined"
                    label="Date To"
                    name="date_to"
                    fullWidth
                    margin="dense"
                  />
                </Box>

                <Box mt={0.9}>
                  <IconButton
                    onClick={handleFilterByDate}
                    style={{
                      width: 40,
                      height: 40,
                      backgroundColor: themes.palette.primary.main,
                      color: "#fff",
                    }}
                  >
                    <Search />
                  </IconButton>
                </Box>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"> Date </TableCell>
                      <TableCell align="center"> Patient Name </TableCell>
                      <TableCell align="center"> Order Name </TableCell>
                      <TableCell align="center"> Amount </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {info.ready ? (
                      info.data.length > 0 ? (
                        info.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {Notify.createdAt(data.created_at)}
                            </TableCell>
                            <TableCell>
                              <Typography className="text-capitalize">
                                {data.pfirstname}, {data.plastname}
                              </Typography>
                            </TableCell>
                            <TableCell>{data.bill_name}</TableCell>
                            <TableCell align="right">
                              {data.bill_amount}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <Typography color="secondary">
                              No patient found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography color="primary">
                            Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={3} align="right">
                        Total:
                      </TableCell>
                      <TableCell align="right">
                        {Notify.convertToNumber(totalAmount)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
            <Box display="flex" mt={1}>
              <Box flexGrow={1} />
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateDocRecord}
                  startIcon={<FontAwesomeIcon icon={faSave} />}
                  disabled={
                    selectedDateFrom === null ||
                    selectedDateTo === null ||
                    selectedChargeType === "all"
                      ? true
                      : false
                  }
                >
                  Generate
                </Button>
              </Box>
            </Box>
            <TablePagination
              labelRowsPerPage="List"
              rowsPerPageOptions={[5, 20, 50, 100]}
              component="div"
              count={info.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      ) : (
        Notify.loading()
      )}
    </>
  );
};

export default DoctorsWageDetails;
