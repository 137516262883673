import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import AddOnTestCreateOrder from "./AddOnTestCreateOrder";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";

const AddOnTest = () => {
  const { users } = React.useContext(UsersData);
  const [list, setList] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();

  const getOtherTest = React.useCallback(async () => {
    let response = await axios.get("accounting/other/order/get-othertest", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [mounted, users]);

  useEffect(() => {
    getOtherTest();
  }, [getOtherTest]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Card>
            <CardHeader title="Additional Order" />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> Date </TableCell>
                      {/* <TableCell> Department </TableCell> */}
                      <TableCell> Order </TableCell>
                      <TableCell> Amount </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {list.ready ? (
                      list.data.length > 0 ? (
                        list.data.map((data, key) => (
                          <TableRow key={key}>
                            <TableCell>
                              {Notify.createdAt(data.created_at)}
                            </TableCell>
                            {/* <TableCell> {data.department} </TableCell> */}
                            <TableCell> {data.order_name} </TableCell>
                            <TableCell align="right">
                              {Notify.convertToNumber(data.order_amount)}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={4}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={4}>
                          <Typography color="primary">
                            Loading, please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AddOnTestCreateOrder getOtherTest={() => getOtherTest()} />
        </Grid>
      </Grid>
    </>
  );
};

export default AddOnTest;
