import React, { useState } from "react";
import { Box, Tabs, Tab, Paper } from "@material-ui/core";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import InvoiceForApproval from "./InvoiceForApproval";
import DrForApproval from "./DrForApproval";

const ForApprovalList = () => {
  const [category, setCategory] = useState("delivery");

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Everytime",
        items: [{ name: "Dashboard", path: "/bmcdc/app/accounting" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>For Approval</Box>
          <Box>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab wrapped value={"invoice"} label="Invoice" />
                <Tab wrapped value={"delivery"} label="Delivery" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <TabPanel value={category} index={"invoice"}>
        <InvoiceForApproval />
      </TabPanel>

      <TabPanel value={category} index={"delivery"}>
        <DrForApproval />
      </TabPanel>
    </Container>
  );
};

export default ForApprovalList;
