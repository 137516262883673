import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Footer from "./Footer";
import Header from "./Header";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const MiscellaneousOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Box flexGrow={1}>
              <Typography variant="h6" align="center">
                <span
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                  style={{ fontWeight: "bolder", color: "#000" }}
                >
                  MISCELLANEOUS
                </span>
              </Typography>
            </Box>
          </Box>

          {orderDetails.data.length > 0 ? (
            orderDetails.data.map((data, key) => (
              <Fragment key={key}>
                {Boolean(parseInt(data.miscellaneous_test)) && (
                  <Box>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              SPICEMEN :
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.speciment}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              TEST :
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.test}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              RESULT :
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.result}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                )}

                {Boolean(parseInt(data.pregnancy_test_urine)) && (
                  <Box mt={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> PREGNANCY TEST (URINE) </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Box>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RESULT
                          </Typography>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.pregnancy_test_urine_result}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
                {Boolean(parseInt(data.pregnancy_test_serum)) && (
                  <Box mt={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> PREGNANCY TEST (SERUM) </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Box>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RESULT
                          </Typography>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.pregnancy_test_serum_result}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}

                {Boolean(parseInt(data.papsmear_test)) && (
                  <Box mt={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> PAPSMEAR TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Box>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RESULT
                          </Typography>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.papsmear_test_result}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}

                {Boolean(parseInt(data.papsmear_test_with_gramstain)) && (
                  <Box mt={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          PAPSMEAR WITH GRAMSTAIN TEST
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Box>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RESULT
                          </Typography>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.papsmear_test_with_gramstain_result}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Fragment>
            ))
          ) : (
            <Typography
              color="secondary"
              style={{ fontWeight: "bolder", color: "#000" }}
            >
              No order found
            </Typography>
          )}

          <Box my={3} align="center">
            <Footer formheader={formheader} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default MiscellaneousOrder;
