import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, Typography, useTheme } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const BranchGrandInventory = ({ selectedBranch }) => {
  const mounted = IsMountedRef();
  const theme = useTheme();
  const [invLab, setInvLab] = useState(0);
  const [invStckroom, setInvStckroom] = useState(0);
  const [category, setCategory] = useState("all");

  const getAllLaboratoryInventory = React.useCallback(async () => {
    let response = await axios.get(
      "accounting/branch/laboratory/inventory-totalamount",
      {
        params: {
          management_id: selectedBranch.management_id,
        },
      }
    );

    if (mounted.current) {
      if (response.data.length > 0) {
        calculateLabInventory(response.data);
      } else {
        setInvLab(0);
      }
    }
  }, [mounted, selectedBranch]);

  const calculateLabInventory = (data) => {
    let xx = 0;

    data.forEach((element) => {
      xx +=
        parseInt(element._qty_remaining) *
        parseFloat(element.msrp === null ? 0 : element.msrp);
    });

    setInvLab(xx);
  };

  const getAllStockroomInventory = React.useCallback(async () => {
    let response = await axios.get(
      "accounting/branch/stockroom/inventory-totalamount",
      {
        params: {
          management_id: selectedBranch.management_id,
        },
      }
    );

    if (mounted.current) {
      if (response.data.length > 0) {
        calculateStockroomInventory(response.data);
      } else {
        setInvStckroom(0);
      }
    }
  }, [mounted, selectedBranch]);

  const calculateStockroomInventory = (data) => {
    let xx = 0;

    data.forEach((element) => {
      xx +=
        parseInt(element._qty_remaining) *
        parseFloat(element.msrp === null ? 0 : element._srp);
    });

    setInvStckroom(xx);
  };

  useEffect(() => {
    getAllLaboratoryInventory();
    getAllStockroomInventory();
  }, [getAllLaboratoryInventory, getAllStockroomInventory]);

  return (
    <Card elevation={5}>
      <CardHeader
        avatar={
          <FontAwesomeIcon
            icon={faBoxes}
            size={"5x"}
            style={{ color: `${theme.palette.primary.main}` }}
          />
        }
        title={
          <>
            <Typography variant="h4" color="textSecondary">
              Inventory
            </Typography>

            <Typography variant="caption" color="textSecondary">
              <span
                style={{
                  cursor: "pointer",
                  color:
                    category === "all" ? theme.palette.primary.main : "#888",
                }}
                onClick={() => setCategory("all")}
              >
                ALL
              </span>{" "}
              |{" "}
              <span
                style={{
                  cursor: "pointer",
                  color:
                    category === "laboratory"
                      ? theme.palette.primary.main
                      : "#888",
                }}
                onClick={() => setCategory("laboratory")}
              >
                LABORATORY
              </span>{" "}
              |{" "}
              <span
                style={{
                  cursor: "pointer",
                  color:
                    category === "stockroom"
                      ? theme.palette.primary.main
                      : "#888",
                }}
                onClick={() => setCategory("stockroom")}
              >
                STOCKROOM
              </span>
            </Typography>
          </>
        }
        subheader={
          <>
            <Typography variant="h4" color="textPrimary">
              <strong>
                {" "}
                &#8369;{" "}
                {Notify.convertToNumber(
                  category === "all"
                    ? invLab + invStckroom
                    : category === "laboratory"
                    ? invLab
                    : category === "stockroom"
                    ? invStckroom
                    : 0
                )}
              </strong>
            </Typography>
          </>
        }
      />
    </Card>
  );
};

export default BranchGrandInventory;
