import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  Suspense,
} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import {
  faAddressCard,
  faHome,
  faLock,
  // faStore,
  // faUserLock,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingScreen from "src/utils/LoadingScreen";
import IsMountedRef from "src/utils/IsMountedRef";
import { Badge } from "@material-ui/core";
import LeaveForm from "src/receiving/leave/LeaveForm";

const ReceivingDashboard = React.lazy(() => import("src/receiving"));
const ReceivingPatient = React.lazy(() => import("src/receiving/patients"));
const AccountReceiving = React.lazy(() =>
  import("src/receiving/account/Account")
);

const RouteReceiving = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);
  const [patient, setPatients] = useState({ data: [], ready: false });
  const [newPatientCount, setNewPatientCount] = useState(0);
  const mounted = IsMountedRef();
  var interval = null;

  const getSidebarHeaderInfo = React.useCallback(async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("receiving/sidebar/header-infomartion", {
        params,
      });
      if (mounted.current) {
        setSidebarHeader(response.data);
      }
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  }, [userContext, mounted]);

  const getPatientsOnQueue = React.useCallback(async () => {
    let response = await axios.get("receiving/patient/queue-list", {
      params: {
        management_id: userContext.users.management_id,
        type: "receiving",
      },
    });

    const data = response.data;
    if (mounted.current) {
      setPatients({ data, ready: true });
      if (data.length > 0) {
        setNewPatientCount(data.length);
      } else {
        setNewPatientCount(0);
      }
    }
  }, [mounted, userContext]);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faHome} color="white" style={{ fontSize: 20 }} />
      ),
      path: "/bmcdc/app/receiving",
      subitem: [],
    },
    {
      name: "Patients",
      show: true,
      icon: (
        <Badge color="error" badgeContent={newPatientCount}>
          <FontAwesomeIcon
            icon={faUsers}
            color="white"
            style={{ fontSize: 20 }}
          />
        </Badge>
      ),
      path: "/bmcdc/app/receiving/patients",
      subitem: [],
    },
    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/bmcdc/app/receiving/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faLock} color="white" style={{ fontSize: 20 }} />
      ),
      path: "/bmcdc/app/receiving/account",
      subitem: [],
    },
  ];

  const initializeInterval = () => {
    interval = setInterval(() => {
      getPatientsOnQueue();
    }, 20000);
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getPatientsOnQueue();
  }, [getSidebarHeaderInfo, getPatientsOnQueue]);

  useEffect(() => {
    initializeInterval();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Suspense fallback={<LoadingScreen />}>
        <UsersHeader.Provider
          value={{
            sidebarHeader: sidebarHeader,
            handleRenderInfo: () => getSidebarHeaderInfo(),
            queue: {
              patient: patient,
              getPatientsOnQueue: () => getPatientsOnQueue(),
            },
          }}
        >
          <BrowserRouter>
            <Sidebar
              notification={{
                enable: true,
                owner: "receiving",
              }}
              header={sidebarHeader}
              routes={sidebarRoute}
              module={"receiving"}
            />

            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/bmcdc/app/receiving" />}
              />

              <Route
                exact
                path="/bmcdc"
                component={() => <Redirect to="/bmcdc/app/receiving" />}
              />

              <Route
                exact
                path="/bmcdc/app"
                component={() => <Redirect to="/bmcdc/app/receiving" />}
              />

              <Route
                exact
                path="/bmcdc/app/receiving"
                component={ReceivingDashboard}
              />

              <Route
                exact
                path="/bmcdc/app/receiving/patients"
                component={ReceivingPatient}
              />

              <Route
                exact
                path="/bmcdc/app/receiving/leave-application"
                component={LeaveForm}
              />

              <Route
                exact
                path="/bmcdc/app/receiving/account"
                component={AccountReceiving}
              />

              <Route exact path="/bmcdc/app/logout" component={Logout} />
              <Route render={() => <PageNotFound title="Page not found" />} />
            </Switch>
          </BrowserRouter>
        </UsersHeader.Provider>
      </Suspense>
    </Fragment>
  );
};

export default RouteReceiving;
