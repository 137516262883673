import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const Covid19EditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/covid19/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {info.ready && (
              <>
                <Box my={2}>
                  <Box hidden={!Boolean(parseInt(info.data.rapid_test))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> RAPID TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    <Box mt={1}>
                      <Box my={2}>
                        <TextField
                          label="Result"
                          fullWidth
                          name="rapid_test_result"
                          select
                          SelectProps={{ native: true }}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          defaultValue={
                            info.data.rapid_test_result === null
                              ? ""
                              : info.data.rapid_test_result
                          }
                        >
                          <option value=""> Select </option>
                          <option value="Negative"> Negative </option>
                          <option value="Positive"> Positive </option>
                          <option value="Invalid"> Invalid </option>
                        </TextField>
                      </Box>

                      <Box>
                        <TextField
                          label="Result Remarks"
                          fullWidth
                          name="rapid_test_result_remarks"
                          multiline
                          variant="outlined"
                          defaultValue={
                            info.data.rapid_test_result_remarks === null
                              ? ""
                              : info.data.rapid_test_result_remarks
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box hidden={!Boolean(parseInt(info.data.antigen_test))}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> ANTIGEN TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    <Box mt={1}>
                      <Box my={2}>
                        <TextField
                          label="Result"
                          fullWidth
                          name="antigen_test_result"
                          select
                          SelectProps={{ native: true }}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          defaultValue={
                            info.data.antigen_test_result === null
                              ? ""
                              : info.data.antigen_test_result
                          }
                        >
                          <option value=""> Select </option>
                          <option value="Negative"> Negative </option>
                          <option value="Positive"> Positive </option>
                          <option value="Invalid"> Invalid </option>
                        </TextField>
                      </Box>

                      <Box>
                        <TextField
                          label="Result Remarks"
                          fullWidth
                          name="antigen_test_result_remarks"
                          multiline
                          variant="outlined"
                          defaultValue={
                            info.data.antigen_test_result_remarks === null
                              ? ""
                              : info.data.antigen_test_result_remarks
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default Covid19EditForm;
