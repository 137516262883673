import React from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Grid,
  Button,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { CancelOutlined, Print } from "@material-ui/icons";

const CensusPrint = ({ census, close }) => {
  return (
    <Grid item xs={12} sm={12}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> Date </TableCell>
              <TableCell> Patient </TableCell>
              <TableCell> Transaction Type </TableCell>
              <TableCell> Symptoms </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {census.ready ? (
              census.data.length > 0 ? (
                census.data.map((data, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{Notify.createdAt(data.created_at)}</TableCell>
                    <TableCell>{`${data.lastname}, ${data.firstname}`}</TableCell>
                    <TableCell>{data.transaction_type}</TableCell>
                    <TableCell>{data.sickness.replace(/,/g, ", ")}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Typography variant="subtitle2" color="secondary">
                      No sale found
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="subtitle2" color="primary">
                    Loading...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" mt={2}>
        <Box flexGrow={1} />

        <Box mr={2}>
          <Button
            variant="outlined"
            className={"d-print-none"}
            color="default"
            startIcon={<CancelOutlined />}
            onClick={() => close()}
          >
            Close
          </Button>
        </Box>

        <Button
          variant="outlined"
          className={"d-print-none"}
          color="primary"
          startIcon={<Print />}
          onClick={() => window.print()}
        >
          Print
        </Button>
      </Box>
    </Grid>
  );
};

export default CensusPrint;
