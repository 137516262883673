import {
  Box,
  Typography,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  Paper,
  TableBody,
  Collapse,
  IconButton,
} from "@material-ui/core";
import React, { useState, useEffect, Fragment } from "react";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import { ArrowForward, ArrowDownward } from "@material-ui/icons";

const StockroomInventory = () => {
  const { users } = React.useContext(UsersData);
  const mounted = IsMountedRef();
  const [products, setProducts] = useState({ data: [], ready: false });
  const [details, setDetails] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState("");

  const getStockroomInventory = React.useCallback(async () => {
    let response = await axios.get("stockroom/product/inventory-list", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setProducts({ data: response.data, ready: true });
    }
  }, [mounted, users]);

  const getStockroomInventoryDetails = async (data) => {
    if (selectedProduct.product_id === data.product_id) {
      console.log("current id is selected");
      return;
    }

    let response = await axios.get("stockroom/product/inventory-list-details", {
      params: {
        product_id: data.product_id,
      },
    });

    setDetails(response.data);
    setSelectedProduct(data);
    // stockroom/product/inventory-list-details
  };

  useEffect(() => {
    getStockroomInventory();
  }, [getStockroomInventory]);

  return (
    <Paper>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell> Available Qty </TableCell>
                <TableCell align="center"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {products.ready ? (
                products.data.length > 0 ? (
                  products.data.map((data, index) => (
                    <Fragment key={index}>
                      <TableRow>
                        <TableCell>{data.product}</TableCell>
                        <TableCell>{data.unit}</TableCell>
                        <TableCell align="right">
                          {data._total_available_qty}{" "}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            disabled={
                              data.product_id === selectedProduct.product_id
                            }
                            color="primary"
                            onClick={() => getStockroomInventoryDetails(data)}
                          >
                            {data.product_id === selectedProduct.product_id ? (
                              <ArrowDownward />
                            ) : (
                              <ArrowForward />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={4}
                        >
                          <Collapse
                            in={data.product_id === selectedProduct.product_id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box m={1}>
                              <Typography
                                variant="subtitle1"
                                color="textSecondary"
                                gutterBottom
                              >
                                Product Details
                              </Typography>

                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell> Type </TableCell>
                                    <TableCell> Qty </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {details.length > 0 ? (
                                    details.map((data, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{data.product}</TableCell>
                                        <TableCell>{data.unit}</TableCell>
                                        <TableCell> {data.type} </TableCell>
                                        <TableCell align="right">
                                          {" "}
                                          {data.qty}{" "}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={4}>
                                        <Typography color="secondary">
                                          No record found.
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default StockroomInventory;
