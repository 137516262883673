import React, {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useContext,
} from "react";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Axios from "axios";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import Container from "src/layout/Container";
import { UsersData } from "src/ContextAPI";
import VanNewPatient from "./VanNewPatient";
import IsMountedRef from "src/utils/IsMountedRef";
import VanPatientDetails from "./VanPatientDetails";

// import PatientDetails from "./patients/PatientDetails";
// import NewPatient from "./NewPatient";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const VanPatientList = () => {
  const classes = useStyles();
  const { users } = useContext(UsersData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState("new-patient");
  const [search, setSearch] = useState("");
  const [accreditedCompany, setAccreditedCompany] = useState([]);
  const mounted = IsMountedRef();

  const [selectedCompany, setSelectedCompany] = useState("all");

  const [patient, setPatient] = useState({
    data: [],
    ready: false,
  });

  const getDoctorsPatient = useCallback(() => {
    var params = {
      company: selectedCompany,
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    Axios.get("admission/patients/getpatient-list", { params })
      .then((response) => {
        const data = response.data;
        setPatient({ data: data, ready: true });
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, [users, selectedCompany]);

  const getAccreditedCompanyList = React.useCallback(async () => {
    let response = await Axios.get("admission/accredited/company/get-list", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    if (mounted.current) {
      setAccreditedCompany(response.data);
    }
  }, [mounted, users]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleClickCompany = async (data) => {
    setSelectedCompany(data);
    var params = {
      company: data,
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    Axios.get("admission/patients/getpatient-list", { params })
      .then((response) => {
        const data = response.data;
        setPatient({ data: data, ready: true });
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getDoctorsPatient();
    getAccreditedCompanyList();
  }, [getDoctorsPatient, getAccreditedCompanyList]);

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "patient list",
          items: [{ name: "dashboard", path: "/bmcdc/app/registration" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Patient List</Box>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <Card>
              <CardHeader title="Patient List" />
              <CardContent>
                <Box>
                  <TextField
                    select
                    SelectProps={{ native: true }}
                    onChange={(e) =>
                      e.target.value === "all"
                        ? handleClickCompany("all")
                        : handleClickCompany(e.target.value)
                    }
                    fullWidth
                    required
                    name="company"
                    label="Company"
                    variant="outlined"
                    margin="dense"
                    value={selectedCompany}
                  >
                    <option value={"all"}> All </option>
                    {accreditedCompany.length > 0 ? (
                      accreditedCompany.map((data, index) => (
                        <option key={index} value={data.company_id}>
                          {data.label}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No company found.
                      </option>
                    )}
                  </TextField>
                </Box>
                <Box mb={1}>
                  <TextField
                    label="Search for patient"
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box>
                  <ListItem
                    button
                    className="mb-2"
                    onClick={() => setSelectedPatient("new-patient")}
                    selected={selectedPatient === "new-patient"}
                  >
                    <ListItemIcon>
                      <Avatar>
                        <strong style={{ fontSize: 30 }}>+</strong>
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      className={`gtc-capitalize`}
                      primary={`Add New Patient`}
                    />
                  </ListItem>
                  {patient.ready ? (
                    searchable.length > 0 ? (
                      <List component="div">
                        {searchable
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data, index) => (
                            <ListItem
                              key={index}
                              button
                              className="mb-2"
                              onClick={() =>
                                setSelectedPatient(data.patient_id)
                              }
                              selected={selectedPatient === data.patient_id}
                            >
                              <ListItemIcon>
                                {data.image === null ? (
                                  <Avatar className={classes.primary}>
                                    {data.lastname.charAt().toUpperCase()}
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    className={classes.primary}
                                    src={`${imageLocation}patients/${data.image}`}
                                    alt=""
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                className={`gtc-capitalize`}
                                primary={`${data.lastname}, ${data.firstname} ${
                                  Boolean(data.middle) ? data.middle : ``
                                } `}
                              />
                            </ListItem>
                          ))}
                      </List>
                    ) : (
                      Notify.noRecord()
                    )
                  ) : (
                    Notify.loading()
                  )}
                </Box>

                <TablePagination
                  component="div"
                  count={searchable.length}
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={8} lg={9}>
            {selectedPatient === "new-patient" ? (
              <VanNewPatient
                accreditedCompany={accreditedCompany}
                getDoctorsPatient={getDoctorsPatient}
              />
            ) : (
              <VanPatientDetails
                accreditedCompany={accreditedCompany}
                patient_id={selectedPatient}
                getDoctorsPatient={getDoctorsPatient}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default VanPatientList;
