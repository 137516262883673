import { Grid } from "@material-ui/core";
import React from "react";
import GrandCardBank from "./grand-card/GrandCardBank";
import GrandCardCollection from "./grand-card/GrandCardCollection";
import GrandCardExpense from "./grand-card/GrandCardExpense";
import CardGrandInventory from "./grand-card/GrandCardInventory";
import GrandCardReceivable from "./grand-card/GrandCardReceivable";
import GrandCardSales from "./grand-card/GrandCardSales";
import GrandTotalPayable from "./grand-card/GrandTotalPayable";

const GrandCard = () => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12} md={12}>
        <CardGrandInventory />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <GrandCardSales />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <GrandTotalPayable />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <GrandCardReceivable />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <GrandCardExpense />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <GrandCardBank />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <GrandCardCollection />
      </Grid>
    </Grid>
  );
};

export default GrandCard;
