import React, { useContext, useEffect, useState } from "react";
import Container from "src/layout/Container";
import {
  Card,
  //   CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  Dialog,
  Box,
  Zoom,
  Divider,
  TextField,
  Button,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import Notify from "src/notification/Notify";
import { Print } from "@material-ui/icons";
import { Filter, X, Search } from "react-feather";
// import PrintMobileVanOrders from "./PrintMobileVanOrders";
// import XrayPrintLayout from "./XrayPrintLayout";

const PrintResultForVan = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [patientList, setPatientList] = useState({ data: [], ready: false });
  //   const [selectedResult, setSelectedResult] = useState(null);
  const [printDialogResult, setPrintDialogResult] = useState(false);
  const [docuFilter, setDocuFilter] = useState(false);
  const [accreditedCompany, setAccreditedCompany] = useState([]);
  //   const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isSearch, setIsSearch] = useState(false);

  const getAccreditedCompanyList = React.useCallback(async () => {
    let response = await axios.get("admission/accredited/company/get-list", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    if (mounted.current) {
      setAccreditedCompany(response.data);
    }
  }, [mounted, users]);

  const handleSearchPatient = React.useCallback(async () => {
    var params = {
      user_id: users.user_id,
      company: selectedCompany,
    };
    axios.get("van/get/online/to-print", { params }).then((response) => {
      const data = response.data;
      if (mounted.current) {
        setPatientList({ data, ready: true });
        setIsSearch(true);
      }
    });
  }, [users, mounted, selectedCompany]);

  useEffect(() => {
    getAccreditedCompanyList();
  }, [getAccreditedCompanyList]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Van Result Print",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Van result print</Box>
        </Box>
      }
    >
      <Card>
        {/* <CardHeader title="Documentation's encoded" /> */}
        <Box m={0} display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1} m={2} />
          <Box>
            <IconButton onClick={() => setDocuFilter(true)}>
              <Filter fontSize="small" />
            </IconButton>
          </Box>
        </Box>

        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSearch ? (
                  patientList.ready ? (
                    patientList.data.length > 0 ? (
                      patientList.data.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell>
                            {data.lastname}, {data.firstname}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                // setSelectedResult(data);
                                setPrintDialogResult(true);
                              }}
                            >
                              <Print />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography color="secondary">
                            No result to edit.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography color="primary">
                          Loading, please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography color="secondary">
                        Select Filter First
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Dialog
        open={printDialogResult}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
        onClose={() => {
          setPrintDialogResult(false);
          //   setSelectedResult(null);
        }}
      >
        <Box>
          {/* <PrintMobileVanOrders
            close={() => {
              setSelectedResult(null);
              setPrintDialogResult(false);
            }}
            selectedResult={selectedResult}
          /> */}
          <Typography color="secondary">No record found.</Typography>
        </Box>
      </Dialog>
      <Dialog
        open={docuFilter}
        onClose={() => setDocuFilter(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="xs"
        fullWidth
      >
        <Box m={2}>
          <Box mb={1}>
            <Typography variant="h5">Filter</Typography>
          </Box>
          <Divider light />
          <Box mb={2}>
            <TextField
              select
              SelectProps={{ native: true }}
              onChange={(e) => setSelectedCompany(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              name="company"
              label="Company"
              variant="outlined"
              value={selectedCompany}
            >
              <option value={""}> Select </option>
              {accreditedCompany.length > 0 ? (
                accreditedCompany.map((data, index) => (
                  <option key={index} value={data.company_id}>
                    {data.label}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No company found.
                </option>
              )}
            </TextField>
          </Box>

          <Box display="flex">
            <Box flexGrow={1} />
            <Box mr={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<X />}
                onClick={() => setDocuFilter(false)}
              >
                Close
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Search />}
                disabled={selectedCompany === null}
                onClick={handleSearchPatient}
              >
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
};

export default PrintResultForVan;
