import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  TextField,
  TablePagination,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import { useParams } from "react-router-dom";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const InventoryStockroom = () => {
  const mounted = IsMountedRef();
  const [list, setList] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  let total = 0;
  const { management_id } = useParams();

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getStockroomInventory = React.useCallback(async () => {
    let response = await axios.get("accounting/branch/inventory/stockroom", {
      params: {
        management_id: management_id,
      },
    });

    const data = response.data;
    if (mounted.current) {
      setList({ data, ready: true });
    }
  }, [mounted, management_id]);

  const calculateTotal = (_qty_remaining, _srp) => {
    let x = _qty_remaining * _srp;
    total += x;
    return Notify.convertToNumber(x);
  };

  useEffect(() => {
    getStockroomInventory();
  }, [getStockroomInventory]);

  const searchable = list.data.filter((data) => {
    return (
      data.product.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    );
  });

  return (
    <Paper>
      <Box p={2}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h6" color="textSecondary">
              Stockroom Inventory
            </Typography>
          </Box>
          <Box>
            <TextField
              label="Search product"
              variant="outlined"
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Product Id </TableCell>
                <TableCell> Product </TableCell>
                <TableCell> Unit </TableCell>
                <TableCell> In Qty </TableCell>
                <TableCell> Out Qty </TableCell>
                <TableCell> Rem. Qty </TableCell>
                <TableCell> Amount </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list.ready ? (
                searchable.length > 0 ? (
                  searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow key={index}>
                        <TableCell> {data.product_id} </TableCell>
                        <TableCell> {data.product} </TableCell>
                        <TableCell> {data.unit} </TableCell>
                        <TableCell align="right"> {data._qty_in} </TableCell>
                        <TableCell align="right"> {data._qty_out} </TableCell>
                        <TableCell align="right">
                          {data._qty_remaining}
                        </TableCell>
                        <TableCell align="right">
                          {calculateTotal(data._qty_remaining, data._srp)}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}></TableCell>
                <TableCell> Total: </TableCell>
                <TableCell align="right">
                  {Notify.convertToNumber(total)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>

          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[10, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default InventoryStockroom;
