import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Collapse,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment, useCallback, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const DoctorList = () => {
  const { users } = React.useContext(UsersData);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [doctorData, setDoctorData] = useState({
    data: [],
    ready: false,
  });
  const [selectedDoctor, setSelectedDoctor] = useState(false);
  const [services, setServices] = useState({ data: [], ready: false });

  const fetchDoctorList = useCallback(async () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    try {
      let response = await axios.get("hmis/get/doctor-list", { params });
      const data = response.data;
      setDoctorData({ data: data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getDoctorServices = async (data) => {
    if (selectedDoctor.doctors_id === data.doctors_id) {
      console.log("selected item duplicated");
      return;
    }
    let response = await axios.get("hmis/get/doctor-id/services", {
      params: {
        management_id: users.management_id,
        doctors_id: data.doctors_id,
      },
    });

    setSelectedDoctor(data);
    setServices({ data: response.data, ready: true });
  };

  React.useEffect(() => {
    fetchDoctorList();
  }, [fetchDoctorList]);

  const searchable = doctorData.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1} />
        <Box>
          <TextField
            label="Search doctor"
            variant="outlined"
            margin="dense"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Paper>
        <Box m={1}>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"> Action </TableCell>
                  <TableCell align="center"> NAME </TableCell>
                  <TableCell align="center"> SPECIALIZATION </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doctorData.ready ? (
                  doctorData.data.length > 0 ? (
                    searchable
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <Fragment key={index}>
                          <TableRow>
                            <TableCell align="center">
                              <IconButton
                                onClick={() => {
                                  getDoctorServices(data);
                                }}
                                color={
                                  selectedDoctor.doctors_id === data.doctors_id
                                    ? "secondary"
                                    : "primary"
                                }
                              >
                                {selectedDoctor.doctors_id ===
                                data.doctors_id ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell> {data.name} </TableCell>
                            <TableCell>
                              {data.specialization !== null
                                ? data.specialization
                                : "None"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={10} style={{ border: 0 }}>
                              <Collapse
                                in={
                                  selectedDoctor.doctors_id === data.doctors_id
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box margin={1}>
                                  <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    component={Box}
                                    flexGrow={1}
                                    color="textPrimary"
                                  >
                                    Services
                                  </Typography>
                                  <Table>
                                    <TableBody>
                                      {services.ready ? (
                                        services.data.length > 0 ? (
                                          services.data.map((xdata, xindex) => (
                                            <TableRow key={xindex}>
                                              <TableCell>
                                                {xdata.services}
                                              </TableCell>
                                              <TableCell align="right">
                                                {Notify.convertToNumber(
                                                  xdata.amount
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        ) : (
                                          <TableRow>
                                            <TableCell colSpan={2}>
                                              <Typography color="second">
                                                No services added...
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      ) : (
                                        <TableRow>
                                          <TableCell colSpan={2}>
                                            <Typography color="primary">
                                              Loading, please wait...
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        <Typography variant="subtitle2" color="secondary">
                          No doctor added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <Typography variant="subtitle2" color="primary">
                        Loading...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component={"div"}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={doctorData.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default DoctorList;
