import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PersonalInfo from "src/doctor/patients/PersonalInfo";
import PersonalInfoHeader from "src/doctor/patients/PersonalInfoHeader";
import Health from "src/doctor/patients/Health";
import Notes from "src/doctor/patients/Notes";
import PatientHistory from "src/doctor/patients/PatientHistory";
import Vitals from "src/doctor/patients/Vitals";
import Imaging from "src/doctor/patients/imaging/Imaging";
import Laboratory from "src/doctor/patients/laboratory_new/Laboratory";
import Prescription from "src/doctor/patients/Prescription";
import { Grid, Paper, Typography, Badge } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LocalHospital from "@material-ui/icons/LocalHospital";
import Whatshot from "@material-ui/icons/Whatshot";
import EventNote from "@material-ui/icons/EventNote";
import HdrWeak from "@material-ui/icons/HdrWeak";
import InvertColors from "@material-ui/icons/InvertColors";
import VerticalSplit from "@material-ui/icons/VerticalSplit";
import Timeline from "@material-ui/icons/Timeline";
import TreatmentPlan from "src/doctor/patients/treatmentplan/TreatmentPlan";
import EventAvailable from "@material-ui/icons/EventAvailable";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import PatientAppointment from "src/doctor/patients/PatientAppointment";
import TabPanel from "src/utils/TabPanel";
import Notify from "src/notification/Notify";
import Axios from "axios";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import { PatientsUnRead, UsersData } from "src/ContextAPI";

function VcallPatientHeader({ connection, patient_id, getPatientsList }) {
  const { users } = React.useContext(UsersData);

  const [category, setCategory] = useState(0);

  const [permission, setPermission] = useState([]);

  const unread = useContext(PatientsUnRead);

  const checkPatientUnreadNotif = (category) => {
    let xx = unread.unviewNotif;
    let yy = unread.unviewNotifVirtual;

    let count = 0;

    for (let i = 0; i < xx.length; i++) {
      if (patient_id === xx[i].patient_id && category === xx[i].category) {
        count += 1;
      }
    }

    for (let i = 0; i < yy.length; i++) {
      if (patient_id === yy[i].patient_id && category === yy[i].category) {
        count += 1;
      }
    }

    return count;
  };

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  const getPermission = useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.append("user_id", users.user_id);
      formdata.append("patient_id", patient_id);
      formdata.append("connection", connection);

      const request = await Axios.post(
        "doctor/patients/permission/get-permission",
        formdata
      );
      setPermission(request.data);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [patient_id, users, connection]);

  useEffect(() => {
    getPermission();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPermission, patient_id]);

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <PersonalInfoHeader patient_id={patient_id} connection={connection} />

          <Box m={0} p={0}>
            <Box component={Paper} variant="outlined" mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab
                  wrapped
                  label="Appointment"
                  icon={
                    Boolean(
                      parseInt(checkPatientUnreadNotif("appointment"))
                    ) ? (
                      <Badge
                        color="error"
                        badgeContent={checkPatientUnreadNotif("appointment")}
                      >
                        <PermContactCalendar />
                      </Badge>
                    ) : (
                      <PermContactCalendar />
                    )
                  }
                />
                <Tab wrapped label="Profile" icon={<AccountCircle />} />
                <Tab wrapped label="Health" icon={<LocalHospital />} />
                <Tab wrapped label="Vitals" icon={<Whatshot />} />

                {/* need further edit */}
                <Tab
                  wrapped
                  label="Laboratory"
                  icon={
                    Boolean(parseInt(checkPatientUnreadNotif("laboratory"))) ? (
                      <Badge
                        color="error"
                        badgeContent={checkPatientUnreadNotif("laboratory")}
                      >
                        <InvertColors />
                      </Badge>
                    ) : (
                      <InvertColors />
                    )
                  }
                />

                <Tab
                  wrapped
                  label="Imaging"
                  icon={
                    Boolean(parseInt(checkPatientUnreadNotif("imaging"))) ? (
                      <Badge
                        color="error"
                        badgeContent={checkPatientUnreadNotif("imaging")}
                      >
                        <VerticalSplit />
                      </Badge>
                    ) : (
                      <VerticalSplit />
                    )
                  }
                />

                <Tab wrapped label="Prescription" icon={<HdrWeak />} />
                <Tab wrapped label="Notes" icon={<EventNote />} />
                <Tab wrapped label="Treatment Plan" icon={<EventAvailable />} />
                <Tab wrapped label="History" icon={<Timeline />} />
              </Tabs>
            </Box>

            <TabPanel value={category} index={0}>
              {permission.length > 0 ? (
                <PatientAppointment
                  patient_id={patient_id}
                  connection={connection}
                />
              ) : (
                <NoPermission />
              )}
            </TabPanel>

            <TabPanel value={category} index={1}>
              <PersonalInfo patient_id={patient_id} connection={connection} />
            </TabPanel>

            <TabPanel value={category} index={2}>
              {permission.length > 0 ? (
                <Health patient_id={patient_id} connection={connection} />
              ) : (
                <NoPermission />
              )}
            </TabPanel>

            <TabPanel value={category} index={3}>
              {permission.length > 0 ? (
                <Vitals patient_id={patient_id} connection={connection} />
              ) : (
                <NoPermission />
              )}
            </TabPanel>

            <TabPanel value={category} index={4}>
              {permission.length > 0 ? (
                <Laboratory
                  patient_id={patient_id}
                  connection={connection}
                  getPermission={() => getPermission()}
                  getPatientsList={() => getPatientsList()}
                  permission={permission}
                />
              ) : (
                <NoPermission />
              )}
            </TabPanel>

            <TabPanel value={category} index={5}>
              {permission.length > 0 ? (
                <Imaging patient_id={patient_id} connection={connection} />
              ) : (
                <NoPermission />
              )}
            </TabPanel>

            <TabPanel value={category} index={6}>
              {permission.length > 0 ? (
                <Prescription patient_id={patient_id} connection={connection} />
              ) : (
                <NoPermission />
              )}
            </TabPanel>

            <TabPanel value={category} index={7}>
              <Notes patient_id={patient_id} connection={connection} />
            </TabPanel>

            <TabPanel value={category} index={8}>
              <TreatmentPlan patient_id={patient_id} connection={connection} />
            </TabPanel>

            <TabPanel value={category} index={9}>
              {permission.length > 0 ? (
                <PatientHistory
                  patient_id={patient_id}
                  connection={connection}
                />
              ) : (
                <NoPermission />
              )}
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default VcallPatientHeader;

const NoPermission = () => {
  return (
    <Paper component={Box} variant="outlined" p={2} align={"center"}>
      <EnhancedEncryptionIcon color="error" style={{ fontSize: "3rem" }} />
      <Typography color="error" variant="h5">
        NO PERMISSION
      </Typography>
      <Typography
        color="textSecondary"
        variant="caption"
        className={`gtc-uppercase`}
      >
        YOUR PERMISSION IS REMOVE BY PATIENT
      </Typography>
    </Paper>
  );
};
