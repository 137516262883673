import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";
import HemathologyRef from "src/laboratory/references/Ref_Hemathology";

const HemaEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/hema/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {info.ready && (
              <>
                <Box display="flex" my={2}>
                  <Box flexGrow={1}>
                    <Typography variant="body1" align="center">
                      <b> HEMATOLOGY </b>
                    </Typography>
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell align="center">
                          <b> RESULT </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> REFERENCE VALUES </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> REMARKS </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hidden={info.data.hemoglobin === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Hemoglobin
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hemoglobin"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.hemoglobin === null
                                  ? ""
                                  : info.data.hemoglobin
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box> {HemathologyRef.hemoglobin()} </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hemoglobin_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.hemoglobin_remarks === null
                                  ? ""
                                  : info.data.hemoglobin_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.hematocrit === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Hematocrit
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hematocrit"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.hematocrit === null
                                  ? ""
                                  : info.data.hematocrit
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hematocrit_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.hematocrit_remarks === null
                                  ? ""
                                  : info.data.hematocrit_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.rbc === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            RBC
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="rbc"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.rbc === null ? "" : info.data.rbc
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.rbc()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="rbc_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.rbc_remarks === null
                                  ? ""
                                  : info.data.rbc_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.wbc === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            WBC
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="wbc"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.wbc === null ? "" : info.data.wbc
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.wbc()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="wbc_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.wbc_remarks === null
                                  ? ""
                                  : info.data.wbc_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.platelet_count === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Platelet Count
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="platelet_count"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.platelet_count === null
                                  ? ""
                                  : info.data.platelet_count
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.plateletCount()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="platelet_count_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.platelet_count_remarks === null
                                  ? ""
                                  : info.data.platelet_count_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.differential_count === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Differential Count
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="differential_count"
                              label="Result"
                              InputProps={{
                                readOnly: !Boolean(
                                  parseInt(info.data.is_processed)
                                ),
                              }}
                              defaultValue={
                                info.data.differential_count === null
                                  ? ""
                                  : info.data.differential_count
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="differential_count_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.differential_count_remarks === null
                                  ? ""
                                  : info.data.differential_count_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.neutrophil === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Neutrophil
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="neutrophil"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.neutrophil === null
                                  ? ""
                                  : info.data.neutrophil
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.neutrophil()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="neutrophil_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.neutrophil_remarks === null
                                  ? ""
                                  : info.data.neutrophil_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.lymphocyte === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Lymphocyte
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="lymphocyte"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.lymphocyte === null
                                  ? ""
                                  : info.data.lymphocyte
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.lymphocyte()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="lymphocyte_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.lymphocyte_remarks === null
                                  ? ""
                                  : info.data.lymphocyte_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.monocyte === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Monocyte
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="monocyte"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.monocyte === null
                                  ? ""
                                  : info.data.monocyte
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.monocyte()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="monocyte_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.monocyte_remarks === null
                                  ? ""
                                  : info.data.monocyte_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.eosinophil === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Eosinophil
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="eosinophil"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.eosinophil === null
                                  ? ""
                                  : info.data.eosinophil
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.eosinophil()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="eosinophil_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.eosinophil_remarks === null
                                  ? ""
                                  : info.data.eosinophil_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.basophil === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Basophil
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="basophil"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.basophil === null
                                  ? ""
                                  : info.data.basophil
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.basophil()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="basophil_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.basophil_remarks === null
                                  ? ""
                                  : info.data.basophil_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.bands === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Bands
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="bands"
                              label="Result"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0.01,
                                  max: 1000,
                                  step: 0.01,
                                },
                              }}
                              defaultValue={
                                info.data.bands === null ? "" : info.data.bands
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.bands()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="bands_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.bands_remarks === null
                                  ? ""
                                  : info.data.bands_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow
                        hidden={info.data.abo_blood_type_and_rh_type === null}
                      >
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            ABO blood type / rh type
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="abo_blood_type_and_rh_type"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.abo_blood_type_and_rh_type === null
                                  ? ""
                                  : info.data.abo_blood_type_and_rh_type
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="abo_blood_type_and_rh_type_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.abo_blood_type_and_rh_type_remarks ===
                                null
                                  ? ""
                                  : info.data.abo_blood_type_and_rh_type_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.bleeding_time === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Bleeding time
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="bleeding_time"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.bleeding_time === null
                                  ? ""
                                  : info.data.bleeding_time
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.bleedingTime()} </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="bleeding_time_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.bleeding_time_remarks === null
                                  ? ""
                                  : info.data.bleeding_time_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.clotting_time === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Clotting time
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="clotting_time"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.clotting_time === null
                                  ? ""
                                  : info.data.clotting_time
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>{HemathologyRef.clottingTime()}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="clotting_time_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.clotting_time_remarks === null
                                  ? ""
                                  : info.data.clotting_time_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.mcv === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            MCV
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="mcv"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.mcv === null ? "" : info.data.mcv
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="mcv_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.mcv_remarks === null
                                  ? ""
                                  : info.data.mcv_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.mch === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            MCH
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="mch"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.mch === null ? "" : info.data.mch
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="mch_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.mch_remarks === null
                                  ? ""
                                  : info.data.mch_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.mchc === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            MCHC
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="mchc"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.mchc === null ? "" : info.data.mchc
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="mchc_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.mchc_remarks === null
                                  ? ""
                                  : info.data.mchc_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.rdw === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            RDW
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="rdw"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.rdw === null ? "" : info.data.rdw
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="rdw_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.rdw_remarks === null
                                  ? ""
                                  : info.data.rdw_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.mpv === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            MPV
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="mpv"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.mpv === null ? "" : info.data.mpv
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="mpv_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.mpv_remarks === null
                                  ? ""
                                  : info.data.mpv_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.pdw === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            PDW
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="pdw"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.pdw === null ? "" : info.data.pdw
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="pdw_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.pdw_remarks === null
                                  ? ""
                                  : info.data.pdw_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.pct === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            PCT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="pct"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.pct === null ? "" : info.data.pct
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="pct_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.pct_remarks === null
                                  ? ""
                                  : info.data.pct_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow
                        hidden={info.data.blood_typing_with_rh === null}
                      >
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Blood Typing / RH
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="blood_typing_with_rh"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.blood_typing_with_rh === null
                                  ? ""
                                  : info.data.blood_typing_with_rh
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="blood_typing_with_rh_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.blood_typing_with_rh_remarks === null
                                  ? ""
                                  : info.data.blood_typing_with_rh_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ct_bt === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            CT/BT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ct_bt"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.ct_bt === null ? "" : info.data.ct_bt
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ct_bt_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.ct_bt_remarks === null
                                  ? ""
                                  : info.data.ct_bt_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.esr === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            ESR
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="esr"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.esr === null ? "" : info.data.esr
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="esr_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.esr_remarks === null
                                  ? ""
                                  : info.data.esr_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ferritin === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Ferritin
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ferritin"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.ferritin === null
                                  ? ""
                                  : info.data.ferritin
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ferritin_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.ferritin_remarks === null
                                  ? ""
                                  : info.data.ferritin_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.aptt === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            APTT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="aptt"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.aptt === null ? "" : info.data.aptt
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="aptt_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.aptt_remarks === null
                                  ? ""
                                  : info.data.aptt_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.peripheral_smear === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Peripheral Smear
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="peripheral_smear"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.peripheral_smear === null
                                  ? ""
                                  : info.data.peripheral_smear
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="peripheral_smear_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.peripheral_smear_remarks === null
                                  ? ""
                                  : info.data.peripheral_smear_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.protime === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            Protime
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="protime"
                              label="Result"
                              multiline
                              defaultValue={
                                info.data.protime === null
                                  ? ""
                                  : info.data.protime
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="protime_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.protime_remarks === null
                                  ? ""
                                  : info.data.protime_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default HemaEditForm;
