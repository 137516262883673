import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../auth/Logout";
import Patients from "src/doctor/patients/Patient";
import Dashboard from "../doctor/DashboardAlternative";
// import Dashboard from "../doctor/Dashboard";
import AppointmentApprovedDetailsReadmore from "../doctor/appointment/AppointmentApprovedDetailsReadmore";
import StreamError from "../StreamError";
import CreateRoomOLd from "src/doctor/onlinecheckup/CreateRoomOLd";
import { PatientsUnRead, UsersData } from "src/ContextAPI";
import Report from "src/doctor/Income/Report";
import axios from "axios";
import CheckInternet from "src/utils/CheckInternet";
import Sidebar from "src/layout/Sidebar";
import RouteInvalid from "src/layout/RouteInvalid";

import {
  Home as HomeIcon,
  Users as PeopleAltIcon,
  FilePlus as FilePlusIcon,
  FileText as ListIcon,
  Database as ReportIcon,
  Calendar as AppointmentIcon,
  MessageSquare,
  Disc,
  CheckCircle,
  FileText,
} from "react-feather";

import NewPatient from "src/doctor/patients/NewPatient";
import PatientHeader from "src/doctor/patients/PatientHeader";
import FullcalendarAppointment from "src/doctor/appointment-fullcalendar";
import FullcalendarAppointmentDetails from "src/doctor/appointment-fullcalendar/Details";
import AppointmentListByStatus from "src/doctor/alternatives/AppointmentListByStatus";
import AppointmentRequestForApproval from "src/doctor/alternatives/AppointmentRequestForApproval";
import GraphLaboratory from "src/doctor/patients/laboratory_new/graph/GraphLaboratory";
import CommentsList from "src/doctor/comment";
import DoctorsAccount from "src/doctor/account";
import IsMountedRef from "src/utils/IsMountedRef";
import DonascoRx from "src/utils/Rx/DonascoRx";
import { Badge } from "@material-ui/core";
import DoctorServices from "src/doctor/services/DoctorServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheckAlt,
  // faAddressCard,
  // faReceipt,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import LeaveForm from "src/doctor/leave/LeaveForm";
import PhysicalExam from "src/doctor/physical-exam";
import MedicalCertificate from "src/doctor/medical-certificate";
import DoctorsSalaryReceiveStatus from "src/doctor/mysalary/DoctorsSalaryReceiveStatus";

const RouteDoctor = () => {
  const [patientNotifBadge, setPatientNotifBadge] = useState(0);

  const [unviewNotif, setUnviewNotif] = useState([]);
  const [unviewNotifVirtual, setUnviewNotifVirtual] = useState([]);

  const [sidebarHeader, setSidebarHeader] = useState([]);
  const [newReqForApproval, setNewReqForApproval] = useState(0);

  const [orderPE, setOrderPE] = useState([]);

  const ismounted = IsMountedRef();

  const [medCertOrder, setMedCertOrder] = useState([]);

  const { users } = React.useContext(UsersData);

  var interval = null;

  const updateDocNotif = (data) => {
    setPatientNotifBadge(data);
  };

  const getLocalNotifUnread = async () => {
    try {
      var params = {
        user_id: users.user_id,
        connection: "local",
      };

      const response = await axios.get(
        "doctor/unview/notification-unview-orders",
        { params }
      );
      const data = response.data;
      if (ismounted.current) {
        setUnviewNotif(data);
      }
    } catch (error) {
      console.log("doctors unread notif error.", error);
    }
  };

  const getVirtualNotifUnread = () => {
    CheckInternet.online()
      .then(async () => {
        try {
          var params = {
            user_id: users.user_id,
            connection: "online",
          };

          const response = await axios.get(
            "doctor/unview/notification-unview-orders",
            { params }
          );
          const data = response.data;
          if (ismounted.current) {
            setUnviewNotifVirtual(data);
          }
        } catch (error) {
          console.log("doctors unread notif error.", error);
        }
      })
      .catch((error) => {
        console.log(
          "no internet connection, notification from virtual not availbale.",
          error
        );
      });
  };

  const getRequestForAppointment = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("connection", "online");

    try {
      const response = await axios.post(
        "appointment/doctors/request-appointment-list",
        formdata
      );
      const data = response.data;
      if (ismounted.current) {
        setNewReqForApproval(data.length);
      }
    } catch (error) {
      console.log("unable to get new request for appointment.", error);
    }
  };

  const getSidebarHeaderInfo = async () => {
    try {
      var params = {
        user_id: users.user_id,
      };
      const response = await axios.get("doctor/sidebar/header-infomartion", {
        params,
      });
      if (ismounted.current) {
        setSidebarHeader(response.data);
      }
    } catch (error) {
      console.log("unable to get sdiebar header info.", error);
    }
  };

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  const getPENewOrder = async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };

    const response = await axios.get("doctor/pe-exam/new-pe-orderlist", {
      params,
    });

    if (ismounted.current) {
      setOrderPE(response.data);
    }
  };

  const getMedcertOrder = React.useCallback(async () => {
    var params = { user_id: users.user_id };
    let response = await axios.get(
      "doctors/certificates/medicalcert-order-all",
      {
        params,
      }
    );

    if (ismounted.current) {
      const data = response.data;
      setMedCertOrder(data);
    }
  }, [users, ismounted]);

  const initializeInterval = () => {
    interval = setInterval(() => {
      getPENewOrder();
      getMedcertOrder();
      getRequestForAppointment();
      getLocalNotifUnread();
      setTimeout(() => {
        getVirtualNotifUnread();
      }, 3000);
    }, 180000);
  };

  useEffect(() => {
    initializeInterval();
    getSidebarHeaderInfo();
    getPENewOrder();
    getMedcertOrder();

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidbarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <HomeIcon color="white" />,
      path: "/bmcdc/app/doctor",
      subitem: [],
    },

    {
      name: "Appointment",
      show: true,
      icon: <AppointmentIcon color="white" />,
      path: "/bmcdc/app/doctor/appointment/calendar",
      subitem: [],
    },

    {
      name: "Appointment Request",
      show: true,
      icon: (
        <Badge color="error" badgeContent={newReqForApproval}>
          {" "}
          <CheckCircle color="white" />{" "}
        </Badge>
      ),
      path: "/bmcdc/app/doctor/appointment/request",
      subitem: [],
    },

    {
      name: "Patients",
      show: true,
      icon: <PeopleAltIcon color="white" />,
      subitem: [
        {
          name: "List",
          show: true,
          icon: <ListIcon size={20} color="white" />,
          path: "/bmcdc/app/doctor/patient/list",
        },
        {
          name: "New",
          show: true,
          icon: <FilePlusIcon size={20} color="white" />,
          path: "/bmcdc/app/doctor/patient/new",
        },
      ],
    },

    {
      name: "Physical Exam",
      show: true,
      icon: (
        <Badge color="error" badgeContent={orderPE.length}>
          {" "}
          <Disc color="white" />{" "}
        </Badge>
      ),
      path: "/bmcdc/app/doctor/physical-exam",
      subitem: [],
    },

    {
      name: "Medical Certificate",
      show: true,
      icon: (
        <Badge color="error" badgeContent={medCertOrder.length}>
          {" "}
          <FileText color="white" />{" "}
        </Badge>
      ),
      path: "/bmcdc/app/doctor/medical-certificate",
      subitem: [],
    },

    {
      name: "Comments",
      show: true,
      icon: <MessageSquare color="white" />,
      path: "/bmcdc/app/doctor/comment/list",
      subitem: [],
    },

    // {
    //   name: "Services",
    //   show: true,
    //   icon: <FontAwesomeIcon icon={faReceipt} color="white" size={"2x"} />,
    //   path: "/bmcdc/app/doctor/services",
    //   subitem: [],
    // },

    {
      name: "Report",
      show: true,
      icon: <ReportIcon color="white" />,
      path: "/bmcdc/app/doctor/income/report",
      subitem: [],
    },

    {
      name: "Salary Record",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faMoneyCheckAlt} color="white" size={"2x"} />
      ),
      path: "/bmcdc/app/doctor/salary-record",
      subitem: [],
    },

    // {
    //   name: "Leave Application",
    //   show: true,
    //   icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
    //   path: "/bmcdc/app/doctor/leave-application",
    //   subitem: [],
    // },
    {
      name: "Accounts",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/doctor/account",
      subitem: [],
    },
  ];

  return (
    <Fragment>
      <PatientsUnRead.Provider
        value={{
          doctorNotif: patientNotifBadge,
          updateDocNotif: updateDocNotif,
          unviewNotif: unviewNotif,
          unviewNotifVirtual: unviewNotifVirtual,
          sidebarHeader: sidebarHeader,
          renderDoctorInfo: handleRenderInfo,
          physicalExam: {
            queueing: orderPE,
            getPENewOrder: () => getPENewOrder(),
          },
          medicalCert: {
            queueing: medCertOrder,
            getMedcertOrder: () => getMedcertOrder(),
          },
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              local: unviewNotif,
              virtual: unviewNotifVirtual,
              owner: "doctor",
            }}
            header={sidebarHeader}
            routes={sidbarRoute}
            module={"doctor"}
          />

          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/bmcdc/app/doctor" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/bmcdc/app/doctor" />}
            />

            <Route
              exact
              path="/bmcdc/app"
              component={() => <Redirect to="/bmcdc/app/doctor" />}
            />

            <Route exact path="/bmcdc/app/doctor" component={Dashboard} />

            <Route
              exact
              path="/bmcdc/app/doctor/account"
              component={DoctorsAccount}
            />

            <Route
              exact
              path="/bmcdc/app/doctor/patient/list"
              component={Patients}
            />

            <Route
              exact
              path="/bmcdc/app/doctor/patient/laboratory/:type/:patient_id"
              component={GraphLaboratory}
            />
            <Route
              exact
              path="/bmcdc/app/doctor/patient/new"
              component={NewPatient}
            />
            <Route
              exact
              path="/bmcdc/app/doctor/appointment/request"
              component={AppointmentRequestForApproval}
            />
            <Route
              exact
              path="/bmcdc/app/doctor/comment/list"
              component={CommentsList}
            />
            <Route
              exact
              path="/bmcdc/app/doctor/appointment/calendar"
              component={FullcalendarAppointment}
            />
            <Route
              exact
              path="/bmcdc/app/doctor/appointment/calendar/details/:from/:appid"
              component={FullcalendarAppointmentDetails}
            />
            <Route
              exact
              path="/bmcdc/app/doctor/appointment/calendar/list/:status"
              component={AppointmentListByStatus}
            />
            <Route
              exact
              path="/bmcdc/app/doctor/patient/details/:patient_id"
              component={PatientHeader}
            />

            <Route
              exact
              path="/bmcdc/app/doctor/services"
              component={DoctorServices}
            />

            <Route
              exact
              path="/bmcdc/app/doctor/income/report"
              component={Report}
            />
            <Route exact path="/bmcdc/app/logout" component={Logout} />
            <Route
              exact
              path="/bmcdc/app/doctor/appointment/patient/information/:patient_id"
              component={AppointmentApprovedDetailsReadmore}
            />
            <Route
              exact
              path="/bmcdc/app/doctor/appointment/room/:ref_number/:room_number"
              component={CreateRoomOLd}
            />

            <Route
              exact
              path="/bmcdc/app/doctor/leave-application"
              component={LeaveForm}
            />

            <Route
              exact
              path="/bmcdc/app/streamerror"
              component={StreamError}
            />

            {/* doc donasco rx exclusive sample */}
            <Route
              exact
              path="/bmcdc/app/doctor/rx/:claim_id/:patient_id/:doctors_id"
              component={DonascoRx}
            />

            <Route
              exact
              path="/bmcdc/app/doctor/physical-exam"
              component={PhysicalExam}
            />

            <Route
              exact
              path="/bmcdc/app/doctor/salary-record"
              component={DoctorsSalaryReceiveStatus}
            />

            <Route
              exact
              path="/bmcdc/app/doctor/medical-certificate"
              component={MedicalCertificate}
            />

            <Route render={() => <RouteInvalid />} />
          </Switch>
        </BrowserRouter>
      </PatientsUnRead.Provider>
    </Fragment>
  );
};

export default RouteDoctor;
