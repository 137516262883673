import { Box, Grid, Tab, Tabs, Paper } from "@material-ui/core";
import React, { useState } from "react";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import PsychologyCreateOrder from "./orders";
import PsychologySalesss from "./sales/PsychologySalesss";

const AccountingPsychology = () => {
  const [category, setCategory] = useState("sales");

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Psychology",
        items: [{ name: "Dashboard", path: "/bmcdc/app/accounting" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Psychology</Box>
          <Box maxWidth={450}>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab value="sales" label="Sales" />
                <Tab value="test" label="Order" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TabPanel value={category} index="sales">
            <PsychologySalesss />
          </TabPanel>
          <TabPanel value={category} index="test">
            <PsychologyCreateOrder />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountingPsychology;
