import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const CBCOrder = ({ formheader, cbcHemaOrderDetails }) => {
  return (
    <>
      {cbcHemaOrderDetails.ready ? (
        cbcHemaOrderDetails.data.length > 0 ? (
          cbcHemaOrderDetails.data.map((data, key) => (
            <Fragment key={key}>
              <Card
                variant="outlined"
                component={Box}
                mb={5}
                className="pagebreaker-before"
              >
                <Box display="flex" justifyContent="center">
                  <Box mt={2}>
                    {formheader && (
                      <CardMedia
                        style={{ width: 70 }}
                        component={"img"}
                        src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                      />
                    )}
                  </Box>
                  <Box>
                    <CardHeader
                      component={Box}
                      align="center"
                      title={
                        formheader && (
                          <strong
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {formheader.name}
                          </strong>
                        )
                      }
                      subheader={
                        <Box>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {formheader && formheader.address}
                          </Typography>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {formheader && formheader.contact_number}
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Box>

                <CardContent>
                  <Header details={cbcHemaOrderDetails} />
                </CardContent>

                <CardContent>
                  <Box>
                    {Boolean(parseInt(data.cbc)) && (
                      <Box>
                        <Box display="flex" my={2}>
                          <Box flexGrow={1}>
                            <Typography variant="h6" align="center">
                              <span
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                HEMATOLOGY
                              </span>
                            </Typography>

                            <Typography variant="body1" align="center">
                              <span
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                COMPLETE BLOOD COUNT
                              </span>
                            </Typography>
                          </Box>
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.is_processed))}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    WBC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.wbc}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    LYM :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.lym}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MID :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mid}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    NEUT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.neut}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RBC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rbc}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    HGB :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    {data.hgb}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    HTC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.hct}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mcv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCH :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mch}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCHC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mchc}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RDW-SD :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rdw_sd}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RDW-CV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rdw_cv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MPV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mpv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PDW :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.pdw}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PCT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.pct}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2} display="flex">
                            <Box width={90}>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                P-LCR :{" "}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                align="center"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.p_lcr}
                              </Typography>
                            </Box>
                          </Box>

                          <Box mt={2} display="flex">
                            <Box width={90}>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                REMARKS :{" "}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                align="center"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.remarks}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {Boolean(parseInt(data.cbc_platelet)) && (
                      <Box my={3}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography
                              variant="h6"
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              HEMATOLOGY
                            </Typography>

                            <Typography
                              variant="body1"
                              align="center"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              COMPLETE BLOOD COUNT WITH PLATELET
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          mt={3}
                          hidden={!Boolean(parseInt(data.is_processed))}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    WBC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.wbc}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    LYM :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.lym}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MID :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mid}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    NEUT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.neut}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RBC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rbc}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    HGB :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.hgb}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    HTC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.hct}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography align="center">
                                    {data.mcv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCH :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mch}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MCHC :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mchc}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RDW-SD :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rdw_sd}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    RDW-CV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rdw_cv}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    MPV :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.mpv}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PDW :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.pdw}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mb={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PCT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.pct}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box mt={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    PLT :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.plt}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box mt={2} display="flex">
                                <Box width={90}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    P-LCR :{" "}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.p_lcr}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2} display="flex">
                            <Box width={90}>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                REMARKS :{" "}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                align="center"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.remarks}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box align="center">
                    <Footer formheader={formheader} />
                  </Box>
                </CardContent>
              </Card>

              <div className="pagebreaker-before" />
            </Fragment>
          ))
        ) : (
          <Typography color="secondary" align="center">
            No record found.
          </Typography>
        )
      ) : (
        <Typography color="primary" align="center">
          Loading, Please wait...
        </Typography>
      )}
    </>
  );
};

export default CBCOrder;
