import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "src/laboratory/print/Header";
import ChartistGraph from "react-chartist";
import { LinearScale } from "@material-ui/icons";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const AudiometryOrder = ({ formheader, audiometryOrderDetails }) => {
  const label = ["8000", "4000", "2000", "1000", "500", "250"];

  const options = {
    showArea: true,
    height: window.innerWidth > 400 ? 300 : 200,
    width: 1000,
  };

  const data = {
    labels: label,
    series: [
      [
        audiometryOrderDetails.data[0].left_8000,
        audiometryOrderDetails.data[0].left_4000,
        audiometryOrderDetails.data[0].left_2000,
        audiometryOrderDetails.data[0].left_1000,
        audiometryOrderDetails.data[0].left_500,
        audiometryOrderDetails.data[0].left_250,
      ],
      [
        audiometryOrderDetails.data[0].right_8000,
        audiometryOrderDetails.data[0].right_4000,
        audiometryOrderDetails.data[0].right_2000,
        audiometryOrderDetails.data[0].right_1000,
        audiometryOrderDetails.data[0].right_500,
        audiometryOrderDetails.data[0].right_250,
      ],
    ],
  };

  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formheader && formheader.name}
              subheader={
                <Box>
                  <Typography>{formheader && formheader.address}</Typography>
                  <Typography>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={audiometryOrderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Typography variant="h6" align="center">
              <b> AUDIOMETRIC REPORT </b>
            </Typography>
          </Box>

          <Box align="center">
            <ChartistGraph data={data} options={options} type={"Line"} />
          </Box>

          {audiometryOrderDetails.data.map((data, index) => (
            <Fragment key={index}>
              <Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> Frequency in hertz </TableCell>
                        <TableCell align="center">
                          <b> 8000 </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> 4000 </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> 2000 </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> 1000 </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> 500 </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> 250 </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow hidden={data.audiometry_test === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            LEFT EAR
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.left_8000}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.left_4000}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.left_2000}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.left_1000}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.left_500}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.left_250}</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow hidden={data.audiometry_test === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            RIGHT EAR
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.right_8000}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.right_4000}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.right_2000}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.right_1000}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.right_500}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <Typography>{data.right_250}</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box m={5}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="h6">
                      <b> INTERPRETATION </b>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                      <Box mt={2} align="center" display="flex">
                        <IconButton color={"primary"}>
                          <LinearScale />
                        </IconButton>
                        <Box mt={1.2}>
                          <Typography variant="subtitle1">
                            <b> LEFT EAR </b>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <Box
                        mt={2}
                        align="center"
                        borderBottom={1}
                        borderColor={"#AEAEAE"}
                      >
                        <Typography variant="subtitle1">
                          <b> {data.left_ear_interpret} </b>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <Box mt={2} align="center" display="flex">
                        <IconButton color={"secondary"}>
                          <LinearScale />
                        </IconButton>
                        <Box mt={1.2}>
                          <Typography variant="subtitle1">
                            <b> RIGHT EAR </b>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <Box
                        mt={2}
                        align="center"
                        borderBottom={1}
                        borderColor={"#AEAEAE"}
                      >
                        <Typography variant="subtitle1">
                          <b> {data.right_ear_interpret} </b>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fragment>
          ))}
          <Box>
            <Box display="flex" my={3}>
              <Box flexGrow={1} />
              <Box>
                <Box align="center" width={250}>
                  <Box mt={5}>
                    <Box borderTop={1}>
                      <b> AUDIOMETRICIAN </b>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default AudiometryOrder;
