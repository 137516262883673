import { Box, Dialog, DialogContent, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <Fragment>
      <Dialog
        open={true}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
        style={{ backgroundColor: "#2681ca" }}
      >
        <DialogContent>
          <Box mb={1}>
            <Typography variant="h6">
              <b> Page Not Found </b>
            </Typography>
          </Box>

          <Box mb={1}>
            <Typography variant="subtitle1">
              Looks like you've followed a broken link or entered a URL that
              doens't exist on this site
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography
              style={{ cursor: "pointer" }}
              color="primary"
              variant="subtitle2"
              onClick={() => history.push("/bmcdc/app")}
            >
              Back to our site
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default PageNotFound;
