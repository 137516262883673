import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Trash } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import ReactSelect from "react-select";
import IsMountedRef from "src/utils/IsMountedRef";

const ListofTempOrder = ({
  tempList,
  getTempOrderList,
  getLaboratoryOrderList,
}) => {
  const [dialog, setDialog] = useState({
    open: false,
    data: null,
  });

  const [remove, setRemove] = useState({
    dialog: false,
    data: null,
  });

  return (
    <Card>
      <CardHeader title="Temporary Order List" />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Category </TableCell>
                <TableCell> LabOrder </TableCell>
                <TableCell> Rate </TableCell>
                <TableCell align="center"> Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tempList.ready ? (
                tempList.data.length > 0 ? (
                  tempList.data.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell> {data.category}</TableCell>
                        <TableCell> {data.laborder}</TableCell>
                        <TableCell align="right">
                          {Notify.convertToNumber(data.rate)}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              setDialog({
                                open: true,
                                data: data,
                              });
                            }}
                          >
                            add items
                          </Button>
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              setRemove({
                                dialog: true,
                                data: data,
                              });
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      {/* reagent/items dialogs */}
      {dialog.open && (
        <RenderItemOrReagents
          open={dialog.open}
          selectedOrder={dialog.data}
          close={() => setDialog({ open: false })}
          action={getTempOrderList}
          getLaboratoryOrderList={() => getLaboratoryOrderList()}
        />
      )}

      {/* remove temp order */}
      {remove.dialog && (
        <RemoveTempOrder
          open={remove.dialog}
          data={remove.data}
          close={() => setRemove({ dialog: false, data: null })}
          action={getTempOrderList}
        />
      )}
    </Card>
  );
};

export default ListofTempOrder;

const RenderItemOrReagents = ({
  open,
  selectedOrder,
  close,
  action,
  getLaboratoryOrderList,
}) => {
  const { users } = useContext(UsersData);
  const [orderItems, setOrderItems] = useState({ data: [], ready: false });
  const [dialog, setDialog] = useState({ open: false });
  const [process, setProcess] = useState({ dialog: false });
  const mounted = IsMountedRef();
  const [password, setPassword] = useState("");

  const getItemList = useCallback(async () => {
    let response = await axios.get("accounting/items/laborder/list-tempitems", {
      params: {
        user_id: users.user_id,
        management_id: users.management_id,
        order_id: selectedOrder.order_id,
      },
    });

    if (mounted.current) {
      setOrderItems({ data: response.data, ready: true });
    }
  }, [users, selectedOrder, mounted]);

  const handleProcessTempOrderWithItems = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("username", users.username);
    formdata.set("password", password);
    formdata.set("order_id", selectedOrder.order_id);

    let response = await axios.post(
      "accounting/items/laborder/temporder-withitems-processed",
      formdata
    );

    if (response.data.message === "pass-invalid") {
      Notify.fieldInvalid("password");
    }

    if (response.data.message === "success") {
      Notify.successRequest("laboratory order process");
      close();
      action();
      getLaboratoryOrderList();
    }
  };

  const handleRemoveItem = async (data) => {
    var formdata = new FormData();
    formdata.set("remove_id", data.ids);

    let response = await axios.post(
      "accounting/test/order/temp-removeorder-item",
      formdata
    );

    if (response.data.message === "success") {
      Notify.successRequest("order item remove");
      getItemList();
    }
  };

  useEffect(() => {
    getItemList();
  }, [getItemList]);

  return (
    <Dialog open={open} onClose={close} maxWidth={"sm"} fullWidth>
      <DialogTitle>Reagents / Items</DialogTitle>
      <form>
        <DialogContent dividers>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Brand</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderItems.ready ? (
                    orderItems.data.length > 0 ? (
                      orderItems.data.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell> {data.item}</TableCell>
                          <TableCell style={{ maxWidth: 250 }}>
                            {data.description}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                handleRemoveItem(data);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <Typography color="secondary">
                            No item found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        <Typography color="primary">
                          Loading, Please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="default" onClick={() => close()}>
            close
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setDialog({ open: true })}
          >
            Add Item
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setProcess({ dialog: true })}
            disabled={orderItems.data.length === 0}
          >
            Process laboratory order
          </Button>
        </DialogActions>
      </form>

      {/* add items to dialog */}
      {dialog.open && (
        <AddItems
          open={dialog.open}
          selectedOrder={selectedOrder}
          action={() => getItemList()}
          close={() => setDialog({ open: false })}
        />
      )}

      {/* add items to dialog */}
      {process.dialog && (
        <ProcessTempOrderWithItemsDialog
          open={process.dialog}
          action={() => handleProcessTempOrderWithItems()}
          close={() => setProcess({ dialog: false })}
          password={password}
          setPassword={setPassword}
        />
      )}
    </Dialog>
  );
};

const AddItems = ({ open, selectedOrder, close, action }) => {
  const { users } = useContext(UsersData);
  const [list, setList] = useState([]);
  const [isprocess, setIsProcess] = useState(false);
  const theme = useTheme();

  const getReagentsList = useCallback(async () => {
    let response = await axios.get("accounting/test/items/get-itemlist", {
      params: {
        user_id: users.user_id,
        management_id: users.management_id,
      },
    });

    setList(response.data);
  }, [users]);

  const handleSelectedItem = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("order_id", selectedOrder.order_id);
    formdata.set("category", selectedOrder.category);
    formdata.set("laborder", selectedOrder.laborder);
    formdata.set("rate", selectedOrder.rate);

    var error = [];

    if (formdata.get("selected_item").trim() === "") {
      error = "error";
      Notify.fieldRequired("item");
    }

    if (error.length > 0) {
      console.log("unable to save item, form has an error.");
    } else {
      setIsProcess(true);

      let response = await axios.post(
        "accounting/test/items/orderagent-save",
        formdata
      );

      if (response.data.message === "success") {
        Notify.successRequest("item added");
        action();
        close();
      }

      setIsProcess(false);
    }
  };

  useEffect(() => {
    getReagentsList();
  }, [getReagentsList]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={"xs"}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle> Add Item </DialogTitle>
      <form onSubmit={handleSelectedItem}>
        <DialogContent dividers>
          <Box mb={2}>
            <ReactSelect
              options={list}
              name="selected_item"
              menuPortalTarget={document.body}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                }),
                control: (base) => ({
                  ...base,
                  minHeight: 55,
                  backgroundColor: "transparent",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                singleValue: () => ({
                  color: theme.palette.type === "dark" ? "#fff" : "#000",
                }),
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="default" onClick={close}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isprocess}
            startIcon={
              isprocess && <CircularProgress color="inherit" size={25} />
            }
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ProcessTempOrderWithItemsDialog = ({
  open,
  close,
  action,
  password,
  setPassword,
}) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle> Process order to available list </DialogTitle>
      <DialogContent dividers>
        <Box>
          <TextField
            label="Enter your password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="default" onClick={() => close()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={() => action()}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RemoveTempOrder = ({ open, close, data, action }) => {
  const [isprocess, setIsProcess] = useState(false);

  const handleRemoveItem = async () => {
    setIsProcess(true);
    var formdata = new FormData();
    formdata.set("management_id", data.management_id);
    formdata.set("laboratory_id", data.laboratory_id);
    formdata.set("order_id", data.order_id);

    let response = await axios.post(
      "accounting/test/order/temp-removeorder",
      formdata
    );

    if (response.data.message === "success") {
      Notify.successRequest("order remove");
      close();
      action();
    }

    setIsProcess(false);
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogContent>
        <Typography>Are you sure to remove this order?</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="default" variant="contained" onClick={() => close()}>
          No
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleRemoveItem()}
          disabled={isprocess}
          startIcon={
            isprocess && <CircularProgress size={20} color="inherit" />
          }
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
