import React, {
  Fragment,
  // useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardContent,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Typography,
  IconButton,
  Box,
  Dialog,
  DialogContent,
  CardMedia,
  DialogActions,
  Button,
  TablePagination,
} from "@material-ui/core";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
// import { UsersData } from "src/ContextAPI";
import { Print } from "@material-ui/icons";
import Notify from "src/notification/Notify";
import { useReactToPrint } from "react-to-print";

const DocuPatientDetailsPE = ({ patient_id }) => {
  const [medCert, setMedCert] = useState([]);
  const mounted = IsMountedRef();
  // const { users } = useContext(UsersData);
  const [dialog, setDialog] = useState({ data: null, open: false });
  const printableRef = useRef(null);
  const [doctorsInfo, setDoctorsInfo] = useState([]);
  const [patientInfo, setPatientInfo] = useState([]);

  // const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getCompletedMedCert = React.useCallback(async () => {
    let response = await axios.get(
      "documentation/van/patient/get-neworderMedCert",
      {
        params: {
          patient_id,
        },
      }
    );

    if (mounted.current) {
      setMedCert(response.data);
    }
  }, [patient_id, mounted]);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  useEffect(() => {
    getCompletedMedCert();
  }, [getCompletedMedCert]);

  const handleSelectedMedCert = async (data) => {
    let response = await axios.get(
      "nurse/certificates/medicalcert-doctors-info",
      {
        params: {
          doctor_id: data.doctors_id,
        },
      }
    );

    setDoctorsInfo(response.data);

    let xpres = await axios.get("nurse/certificates/medicalcert-patient-info", {
      params: {
        patient_id: data.patient_id,
      },
    });

    setPatientInfo(xpres.data);

    setTimeout(() => {
      setDialog({ data: data, open: true });
    }, 1000);
  };

  return (
    <Fragment>
      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Date </TableCell>
                  <TableCell> Diagnosis </TableCell>
                  <TableCell> Status </TableCell>
                  <TableCell> Action </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {medCert.length > 0 ? (
                  medCert
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, key) => (
                      <TableRow key={key}>
                        <TableCell>
                          {Notify.createdAt(data.created_at)}
                        </TableCell>
                        <TableCell>
                          {data.diagnosis_findings === null
                            ? "Not yet read"
                            : data.diagnosis_findings}
                        </TableCell>
                        <TableCell> {data.order_status} </TableCell>
                        <TableCell>
                          <IconButton
                            color="primary"
                            onClick={() => handleSelectedMedCert(data)}
                            disabled={
                              data.order_status === "completed" ? false : true
                            }
                          >
                            <Print />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              component={"div"}
              count={medCert.length}
              labelRowsPerPage="List"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 50, 100]}
            />
          </TableContainer>
        </CardContent>
      </Card>

      {/* print medical cert */}
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ data: null, open: false })}
        fullScreen
      >
        <DialogContent>
          <Box ref={printableRef} p={4}>
            <Box mb={5}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Box align="center">
                    <CardMedia
                      style={{ width: 150 }}
                      component={"img"}
                      src="/bmcdc_logo.png"
                    />
                  </Box>
                </Grid>

                <Grid item xs={9}>
                  <Box align="center">
                    <Box>
                      <Typography variant="h4">
                        BMCDC HEALTHCARE, INC.
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography variant="h5">
                        DOOR G/F, GIMENES BLGD., KM. 5 BUHANGIN, DAVAO CITY
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="h5">
                        Tel. 285-4324/293-9684, Telefax: 241-0925
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={1}></Grid>
              </Grid>
            </Box>
            <Box my={3}>
              <Typography variant="h4" align="center">
                <strong>MEDICAL CERTIFICATE </strong>
              </Typography>
            </Box>

            <Box my={3}>
              {dialog.data && (
                <>
                  <Typography
                    style={{
                      fontSize: 22,
                    }}
                  >
                    This is to certify that
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      {" "}
                      {patientInfo.length > 0
                        ? `${patientInfo[0].firstname} ${patientInfo[0].lastname}`
                        : ""}
                    </span>
                    , a
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      {patientInfo.length > 0
                        ? Notify.calculateAge(patientInfo[0].birthday)
                        : ""}
                    </span>
                    years old, presently residing at
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      {patientInfo.length > 0
                        ? `${patientInfo[0].street} ${patientInfo[0].barangay} ${patientInfo[0].city}`
                        : ""}
                    </span>
                    , was examined on{" "}
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      {Notify.createdAt(dialog.data.created_at)}
                    </span>
                    .
                  </Typography>

                  <Box my={3}>
                    <Typography
                      style={{
                        fontSize: 22,
                      }}
                    >
                      <b> Diagnosis/Laboratory Findings:</b>{" "}
                      <span
                        style={{
                          whiteSpace: "pre-line",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: dialog.data.diagnosis_findings,
                        }}
                      />
                    </Typography>
                  </Box>

                  <Box my={3}>
                    <Typography
                      style={{
                        fontSize: 22,
                      }}
                    >
                      <b> Recommendation:</b>{" "}
                      <span
                        style={{
                          whiteSpace: "pre-line",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: dialog.data.recommendation,
                        }}
                      ></span>
                    </Typography>
                  </Box>

                  <Box my={3}>
                    <Typography
                      style={{
                        fontSize: 22,
                      }}
                    >
                      <b> Remarks:</b>{" "}
                      <span
                        style={{
                          whiteSpace: "pre-line",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: dialog.data.remarks,
                        }}
                      ></span>
                    </Typography>
                  </Box>

                  <Typography
                    style={{
                      fontSize: 22,
                    }}
                  >
                    Issued on{" "}
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      {Notify.createdAt(dialog.data.issued_at)}{" "}
                    </span>{" "}
                    at Davao City, Philippines.
                  </Typography>
                </>
              )}
            </Box>

            <Box maxWidth={450} mt={4} className="fixedbottom-onprint">
              <Box borderBottom={1} align="center">
                <Typography variant="h5">
                  {doctorsInfo.length > 0 ? doctorsInfo[0].name : ""}
                </Typography>
              </Box>
              <Typography align="center" variant="h6">
                Attending Physician
              </Typography>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            size="large"
            variant="contained"
            color="default"
            onClick={() => setDialog({ data: null, open: false })}
          >
            Close
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => handlePrint()}
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DocuPatientDetailsPE;
