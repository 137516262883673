import {
  Grid,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const MedicalExam = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <Box p={2}>
          <Header details={orderDetails} />
        </Box>

        <CardContent>
          {/* order details */}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bolder", color: "#000" }}
            >
              MEDICAL EXAMINATION REPORT
            </Typography>
          </Box>

          <Box my={2}>
            {/* clinical microscopy */}
            {orderDetails.data.map((data, index) => (
              <Box key={index}>
                {/* hidden inputs */}
                <Box display="flex">
                  <Box mt={1.3}>
                    {" "}
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      Nature of Examimination:
                    </span>{" "}
                  </Box>
                  <Box mx={2}>
                    <FormControlLabel
                      label={
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Pre-employment
                        </span>
                      }
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            data.nature_of_exam
                              ? data.nature_of_exam.includes("Pre-employment")
                                ? true
                                : false
                              : false
                          }
                        />
                      }
                    />
                  </Box>
                  <Box mx={2}>
                    <FormControlLabel
                      label={
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Annual PE
                        </span>
                      }
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            data.nature_of_exam
                              ? data.nature_of_exam.includes("Annual PE")
                                ? true
                                : false
                              : false
                          }
                        />
                      }
                    />
                  </Box>
                  <Box mx={2}>
                    <FormControlLabel
                      label={
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Other
                        </span>
                      }
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            data.nature_of_exam &&
                            !data.nature_of_exam.includes("Pre-employment") &&
                            !data.nature_of_exam.includes("Annual PE")
                          }
                        />
                      }
                    />
                  </Box>
                  {data.nature_of_exam &&
                    !data.nature_of_exam.includes("Pre-employment") &&
                    !data.nature_of_exam.includes("Annual PE") && (
                      <Box mt={1}>
                        <Typography
                          style={{
                            textTransform: "capitalize",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          <u> {data.nature_of_exam} </u>
                        </Typography>
                      </Box>
                    )}
                </Box>

                <Box my={2}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bolder", color: "#000" }}
                  >
                    I. MEDICAL HISTORY
                  </Typography>
                </Box>

                <Box my={1}>
                  <Box my={2}>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      PAST MEDICAL HISTORY
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Box>
                      <FormControlLabel
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Hypertension
                          </span>
                        }
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              data.pmh && data.pmh.includes("hypertension")
                            }
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              data.pmh && data.pmh.includes("diabetes_melitus")
                            }
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Diabetes Melitus
                          </span>
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={data.pmh && data.pmh.includes("ptb")}
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            PTB
                          </span>
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={data.pmh && data.pmh.includes("others")}
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Others
                          </span>
                        }
                      />
                    </Box>
                    {data.pmh && data.pmh.includes("others") && (
                      <Box mt={1.2}>
                        <Typography
                          style={{
                            textTransform: "capitalize",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          <u> {data.pmh_other_specify} </u>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box my={1}>
                  <Box my={2}>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      FAMILY HISTORY
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              data.fam_history &&
                              data.fam_history.includes("hypertension")
                            }
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Hypertension
                          </span>
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              data.fam_history &&
                              data.fam_history.includes("asthma")
                            }
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Asthma
                          </span>
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              data.fam_history &&
                              data.fam_history.includes("cancers")
                            }
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Cancers
                          </span>
                        }
                      />
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              data.fam_history &&
                              data.fam_history.includes("cardiac_disease")
                            }
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Cardiac Disease
                          </span>
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              data.fam_history &&
                              data.fam_history.includes("ptb")
                            }
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            PTB
                          </span>
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              data.fam_history &&
                              data.fam_history.includes("hyperthyroidism")
                            }
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Hyperthyroidism
                          </span>
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              data.fam_history &&
                              data.fam_history.includes("others")
                            }
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Others
                          </span>
                        }
                      />
                    </Box>

                    <Box>
                      {data.fam_history && data.fam_history.includes("others") && (
                        <Box mt={1.2}>
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            <u>{data.fam_history_other_specify}</u>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box my={2}>
                  <TextField
                    label="Previous Operation/Hospitalization"
                    name="prev_operation_hospital"
                    fullWidth
                    variant="outlined"
                    value={data.prev_operation}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                <Box>
                  <Box mb={2}>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      II. PERSONAL HISTORY
                    </Typography>
                  </Box>

                  <Box display="flex">
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={data.personal_history_smoke === "yes"}
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Smoking
                          </span>
                        }
                      />
                    </Box>

                    <Box mx={3} mt={1.2}>
                      <Typography
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        Qty per day: <u> {data.personal_history_smoke_qty} </u>
                      </Typography>
                    </Box>

                    <Box mx={3} mt={1.2}>
                      <Typography
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        # of years :
                        <u> {data.personal_history_smoke_no_year} </u>
                      </Typography>
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={data.personal_history_alchohol === "yes"}
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Alcohol
                          </span>
                        }
                      />
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={data.personal_history_drug === "yes"}
                          />
                        }
                        label={
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            Drug Abuse
                          </span>
                        }
                      />
                    </Box>
                  </Box>
                </Box>

                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box my={2}>
                        <TextField
                          label="Allergies of Food"
                          name="allergies_of_foods"
                          fullWidth
                          variant="outlined"
                          multiline
                          value={data.allergy_foods}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box my={2}>
                        <TextField
                          label="Allergies of Drugs"
                          name="allergies_of_drugs"
                          fullWidth
                          variant="outlined"
                          multiline
                          value={data.allergy_drugs}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Box mb={2}>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      MENSTRUAL/OBSTETRICAL HISTORY
                    </Typography>
                  </Box>

                  <Box mb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="LMP"
                          name="menstrual_lmp"
                          value={data.menstrual_hist_lmp}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="PMP"
                          name="menstrual_pmp"
                          value={data.menstrual_hist_pmp}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="G"
                          name="menstrual_g"
                          value={data.menstrual_hist_g}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          label="P"
                          fullWidth
                          name="menstrual_p"
                          value={data.menstrual_hist_p}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          label="Other"
                          fullWidth
                          name="menstrual_other"
                          value={data.menstrual_hist_other}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box my={2}>
                  <TextField
                    label="Medication"
                    name="medication"
                    fullWidth
                    variant="outlined"
                    value={data.medication}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                <Box my={2}>
                  <Box mb={2}>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      III. PHYSICAL EXAMINATION
                    </Typography>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="BP"
                          name="bp"
                          fullWidth
                          variant="outlined"
                          value={data.pe_bp}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="PR"
                          name="pr"
                          fullWidth
                          variant="outlined"
                          value={data.pe_pr}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="HT"
                          name="ht"
                          fullWidth
                          variant="outlined"
                          value={data.pe_ht}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="WT"
                          name="wt"
                          fullWidth
                          variant="outlined"
                          value={data.pe_wt}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="BMI"
                          name="bmi"
                          fullWidth
                          variant="outlined"
                          value={data.pe_bmi}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Range"
                          name="range"
                          fullWidth
                          variant="outlined"
                          value={data.pe_range}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Box mb={2}>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      VISUAL ACUITY
                    </Typography>
                  </Box>

                  <Box my={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            label="OD NEAR"
                            name="od_near"
                            fullWidth
                            variant="outlined"
                            value={data.visual_acuity_od_near}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            label="OD FAR"
                            name="od_far"
                            fullWidth
                            variant="outlined"
                            value={data.visual_acuity_od_far}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box my={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            label="OS NEAR"
                            name="os_near"
                            fullWidth
                            variant="outlined"
                            value={data.visual_acuity_os_near}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            label="OS FAR"
                            name="os_far"
                            fullWidth
                            variant="outlined"
                            value={data.visual_acuity_os_far}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box my={2}>
                  <Box mb={1}>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      {" "}
                      DENTAL SURVEY{" "}
                    </Typography>
                  </Box>

                  <Box mb={1}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      UPPER: &nbsp;8 &nbsp; 7 &nbsp; 6 &nbsp; 5 &nbsp; 4 &nbsp;
                      3 &nbsp; 2 &nbsp; 1 &nbsp; &nbsp; 1 &nbsp; 2 &nbsp; 3
                      &nbsp; 4 &nbsp; 5 &nbsp; 6 &nbsp; 7 &nbsp; 8
                    </Typography>
                  </Box>

                  <Box mb={1}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      LOWER: &nbsp;8 &nbsp; 7 &nbsp; 6 &nbsp; 5 &nbsp; 4 &nbsp;
                      3 &nbsp; 2 &nbsp; 1 &nbsp; &nbsp; 1 &nbsp; 2 &nbsp; 3
                      &nbsp; 4 &nbsp; 5 &nbsp; 6 &nbsp; 7 &nbsp; 8
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      SUGGEST: (&nbsp;&nbsp;&nbsp;&nbsp;) PROPHY:
                      (&nbsp;&nbsp;&nbsp;&nbsp;) EXO: (&nbsp;&nbsp;&nbsp;&nbsp;)
                      RESTO, INDICATE NO:
                    </Typography>
                  </Box>
                </Box>

                <Box my={2}>
                  <Box mb={2}>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      HEARING
                    </Typography>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <TextField
                          label="HEARING AD"
                          name="hearing_ad"
                          fullWidth
                          variant="outlined"
                          value={data.pe_hearing_ad}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box>
                        <TextField
                          label="HEARING AS"
                          name="hearing_as"
                          fullWidth
                          variant="outlined"
                          value={data.pe_hearing_as}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Skin"
                          name="skin"
                          fullWidth
                          variant="outlined"
                          value={data.pe_skin}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Heent"
                          name="heent"
                          fullWidth
                          variant="outlined"
                          value={data.pe_heent}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Neck"
                          name="neck"
                          fullWidth
                          variant="outlined"
                          value={data.pe_neck}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Chest/Lungs"
                          name="chest"
                          fullWidth
                          variant="outlined"
                          value={data.pe_chest}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Abdomen"
                          name="abdomen"
                          fullWidth
                          variant="outlined"
                          value={data.pe_cardio}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Cardiovascular"
                          name="cardio"
                          fullWidth
                          variant="outlined"
                          value={data.pe_abdomen}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Genitourinary Tract"
                          name="genitourinary"
                          fullWidth
                          variant="outlined"
                          value={data.pe_genitourinary}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Genitalia"
                          name="genitalia"
                          fullWidth
                          variant="outlined"
                          value={data.pe_genitalia}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Inguinal"
                          name="inguinal"
                          fullWidth
                          variant="outlined"
                          value={data.pe_linguinal}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Extremities"
                          name="extremities"
                          fullWidth
                          variant="outlined"
                          value={data.pe_extremities}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Reflexes"
                          name="reflexes"
                          fullWidth
                          variant="outlined"
                          value={data.pe_reflexes}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          label="Neurology"
                          name="neuro"
                          fullWidth
                          variant="outlined"
                          value={data.pe_neurology}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      III. CLASSIFICATION
                    </Typography>
                  </Box>

                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="classification_a"
                          color="primary"
                          checked={data.classification_a === "yes"}
                        />
                      }
                      label={
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Physically fit to work
                        </span>
                      }
                    />
                  </Box>

                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="classification_b"
                          color="primary"
                          checked={data.classification_b === "yes"}
                        />
                      }
                      label={
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Physically to work with findings but offers no
                          handicap to the job applied.
                        </span>
                      }
                    />
                  </Box>

                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="classification_c"
                          color="primary"
                          checked={data.classification_c === "yes"}
                        />
                      }
                      label={
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          With abnormal findings (employment upon descretion of
                          employer).
                        </span>
                      }
                    />
                  </Box>

                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="classification_d"
                          color="primary"
                          checked={data.classification_d === "yes"}
                        />
                      }
                      label={
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Unfit for any type of employment
                        </span>
                      }
                    />
                  </Box>

                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="classification_e"
                          color="primary"
                          checked={data.classification_e === "yes"}
                        />
                      }
                      label={
                        <span style={{ fontWeight: "bolder", color: "#000" }}>
                          Classification pending due to
                        </span>
                      }
                    />
                  </Box>
                </Box>

                <Box my={2}>
                  <TextField
                    label="Impression/Diagnosis"
                    name="impression"
                    fullWidth
                    variant="outlined"
                    value={data.impression}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    label="Recommendations"
                    name="recommendations"
                    fullWidth
                    variant="outlined"
                    value={data.recommendations}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                <Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      EXAMINING PHYSISCIAN
                    </Typography>
                  </Box>

                  <Box
                    border={1}
                    borderColor="#ccc"
                    p={4}
                    borderRadius={2}
                    my={2}
                  >
                    <Grid container spacing={2}>
                      <Grid item sm={6}>
                        <Box align="center" mt={2}>
                          <Box borderTop={1}>MD</Box>
                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {" "}
                              License No. __________{" "}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item sm={6}>
                        <Box borderTop={1} align="center" mt={2}>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            Patient's Signature
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                {/* <Box my={2}>
                  <Box mb={1}>
                    <Typography variant="subtitle1">
                      ANNUAL P.E PROCEDURE
                    </Typography>
                  </Box>

                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell> Step 1 </TableCell>
                        <TableCell>Registration (P.E Form )</TableCell>
                        <TableCell>
                          <TextField label="Time" name="registration_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 2 </TableCell>
                        <TableCell> Vital Signs Taking </TableCell>
                        <TableCell>
                          <TextField label="Time" name="vital_signs_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 3 </TableCell>
                        <TableCell> CBC/Blood Extraction </TableCell>
                        <TableCell>
                          <TextField label="Time" name="cbc_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 4 </TableCell>
                        <TableCell> Urinalysis </TableCell>
                        <TableCell>
                          <TextField label="Time" name="urinalysis_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 5 </TableCell>
                        <TableCell> Fecalysis </TableCell>
                        <TableCell>
                          <TextField label="Time" name="fecalysis_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 6 </TableCell>
                        <TableCell> X-ray</TableCell>
                        <TableCell>
                          <TextField label="Time" name="xray_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 7 </TableCell>
                        <TableCell> ECG </TableCell>
                        <TableCell>
                          <TextField label="Time" name="ecg_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 8 </TableCell>
                        <TableCell> Pap Smear </TableCell>
                        <TableCell>
                          <TextField label="Time" name="papsmear_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 9 </TableCell>
                        <TableCell> Eye Checkup </TableCell>
                        <TableCell>
                          <TextField label="Time" name="eye_checkup_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 10 </TableCell>
                        <TableCell> Dental Checkup </TableCell>
                        <TableCell>
                          <TextField label="Time" name="dental_checkup_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 11 </TableCell>
                        <TableCell> Physical Examination </TableCell>
                        <TableCell>
                          <TextField label="Time" name="pe_time" />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell> Step 12 </TableCell>
                        <TableCell> Exit Signature </TableCell>
                        <TableCell>
                          <TextField label="Time" name="exit_signature_time" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box> */}
              </Box>
            ))}
          </Box>

          <Box>
            <Footer formheader={formheader} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default MedicalExam;
