import React, { useEffect, useState } from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box,
  TablePagination,
  Button,
  Dialog,
  Zoom,
  DialogTitle,
  DialogContent,
  IconButton,
  Badge,
  Paper,
  Grid,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { HighlightOffOutlined } from "@material-ui/icons";
import VirtualOrderReportDetails from "./VirtualOrderReportDetails";
// import DatePicker from "react-datepicker";
import { UsersData } from "src/ContextAPI";
import * as Yup from "yup";
import { Formik } from "formik";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const VirtualOrderReport = () => {
  const { users } = React.useContext(UsersData);
  const [reports, setReports] = useState({ data: [], ready: false });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [selectedAttachmentID, setSelectedAttachmentID] = useState(null);
  const [attachmentDialog, setAttachmentDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);

  const getFormData = (object) => {
    const formData = new FormData();
    formData.set("user_id", users.user_id);
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getImagingVirtualOrder = () => {
    var params = { user_id: users.user_id };
    axios
      .get("imaging/virtual/order/reports", { params })
      .then((response) => {
        const data = response.data;
        setReports({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const applyPagination = (reports, page, limit) => {
    return reports.slice(page * limit, page * limit + limit);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  useEffect(() => {
    getImagingVirtualOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginatedReport = applyPagination(reports.data, page, limit);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Paper component={Box} variant="outlined">
            <Box display="flex" className={"d-print-none"} p={2}>
              <Box flexGrow={1}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={`d-print-none`}
                >
                  Virtual Imaging Report
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => setFilterDialog(true)}
                >
                  Report
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.print();
                  }}
                >
                  Print
                </Button>
              </Box>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> DATE </TableCell>
                    {/* <TableCell> CHARGE SLIP </TableCell> */}
                    <TableCell> PATIENT </TableCell>
                    <TableCell> AGE </TableCell>
                    {/* <TableCell> ORDER </TableCell> */}
                    {/* <TableCell> NO. OF SHOTS </TableCell> */}
                    <TableCell> TYPE </TableCell>
                    <TableCell className={`d-print-none`}> ACTION </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.ready ? (
                    reports.data.length > 0 ? (
                      paginatedReport.map((data, index) => {
                        return (
                          <TableRow key={index} hover>
                            <TableCell>
                              <Badge
                                invisible={parseInt(data.is_viewed) !== 3}
                                variant="dot"
                                color="secondary"
                                className={`d-print-none`}
                              />
                              {Notify.dateTimeConvert(data.created_at)}
                            </TableCell>
                            {/* <TableCell> {data.charge_slip}</TableCell> */}
                            <TableCell>
                              <Typography
                                component={Box}
                                style={{ textTransform: "capitalize" }}
                                variant="subtitle2"
                              >
                                {` ${data.fname} ${data.lname} `}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {data.birthday === null
                                ? "none"
                                : Notify.calculateAge(data.birthday)}
                            </TableCell>
                            {/* <TableCell>
                                                                {data.imaging_order}
                                                            </TableCell> */}
                            {/* <TableCell align={'right'}>
                                                                {data.number_shots}
                                                            </TableCell> */}
                            <TableCell>{data.radiologist_type}</TableCell>
                            <TableCell className={`d-print-none`}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  setSelectedAttachmentID(
                                    data.imaging_center_id
                                  );
                                  setAttachmentDialog(true);
                                }}
                              >
                                <AttachFileIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Typography variant="subtitle2" color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography variant="subtitle2" color="primary">
                          Please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component={Box}
              count={reports.data.length}
              labelRowsPerPage="Rows per page"
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 20, 50, 100]}
            />
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={filterDialog}
        ransitionComponent={Zoom}
        transitionDuration={800}
        fullWidth
        maxWidth="xs"
      >
        <Box display="flex">
          <Box flexGrow={1}>
            <DialogTitle>Filter</DialogTitle>
          </Box>
          <Box>
            <IconButton
              onClick={() => setFilterDialog(false)}
              color="secondary"
            >
              <HighlightOffOutlined fontSize="large" />
            </IconButton>
          </Box>
        </Box>
        <DialogContent>
          <Formik
            initialValues={{
              date_from: "",
              date_to: "",
            }}
            validationSchema={Yup.object().shape({
              date_from: Yup.string().required(),
              date_to: Yup.string().required(),
            })}
            onSubmit={async (
              values,
              { setErrors, setSubmitting, resetForm }
            ) => {
              try {
                setReports({ data: [], ready: false });
                const request = await axios.post(
                  "imaging/virtual/order/reports-bydate",
                  getFormData(values)
                );
                const data = request.data;
                if (data.length > 0) {
                  setReports({ data: data, ready: true });
                  Notify.successRequest("filter by date");
                  resetForm();
                  setSubmitting(true);
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box mb={2}>
                  <TextField
                    required
                    fullWidth
                    label="Date From"
                    error={Boolean(touched.date_from && errors.date_from)}
                    helperText={touched.date_from && errors.date_from}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.date_from}
                    name="date_from"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    required
                    fullWidth
                    label="Date To"
                    error={Boolean(touched.date_to && errors.date_to)}
                    helperText={touched.date_to && errors.date_to}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.date_to}
                    name="date_to"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box mb={2} display="flex">
                  <Box flexGrow={1} />
                  <Button
                    variant="contained"
                    color="default"
                    type="reset"
                    startIcon={<ClearIcon />}
                  >
                    Clear
                  </Button>
                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<CheckIcon />}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Dialog
        open={attachmentDialog}
        TransitionComponent={Zoom}
        transitionDuration={800}
        fullWidth
        maxWidth="sm"
      >
        <Box display="flex">
          <Box flexGrow={1}>
            <DialogTitle>Order Details</DialogTitle>
          </Box>
          <Box>
            <IconButton
              onClick={() => setAttachmentDialog(false)}
              color="secondary"
            >
              <HighlightOffOutlined />
            </IconButton>
          </Box>
        </Box>
        <DialogContent>
          <VirtualOrderReportDetails
            selectedAttachmentID={selectedAttachmentID}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VirtualOrderReport;
