import Axios from "axios";
import { useContext, useEffect, useState } from "react";
import { UsersData } from "./ContextAPI";
import SlashScreen from "./utils/SlashScreen";

const CheckAuth = ({ children }) => {
  const userContext = useContext(UsersData);
  const [loading, setLoading] = useState(false);

  const getUsersInformationOnReload = async () => {
    try {
      let response = await Axios.get("my/information");
      userContext.updateUsers(response.data[0]);
      setTimeout(() => {
        setLoading(true);
      }, 2000);
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        localStorage.removeItem("hmis_token");
        localStorage.removeItem("hmis_page");
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    getUsersInformationOnReload();
    //eslint-disable-next-line
  }, []);

  if (!loading) {
    return <SlashScreen />;
  }

  return children;
};

export default CheckAuth;
