import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  InputAdornment,
  Paper,
  Grid,
} from "@material-ui/core";
import Notify from "../../notification/Notify";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import { UsersData } from "../../ContextAPI";
import Container from "../../layout/Container";

function Logs() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [log, setLog] = useState([]);
  const [logReady, setLogReady] = useState(false);
  const [search, setSearch] = useState("");
  const userData = useContext(UsersData);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchLogActivity = () => {
    var params = {
      user_id: userData.users.user_id,
      management_id: userData.users.management_id,
    };
    Axios.get("pharmacy/get-log-list", { params })
      .then((response) => {
        const data = response.data;
        setLog(data);
        setLogReady(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    fetchLogActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = log.filter((data) => {
    return data.product.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "logs",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="Logs"
      >
        <Grid container>
          <Grid item sm={12} xs={12}>
            <Box display="flex">
              <Box flexGrow={1} />
              <Box mb={1}>
                <TextField
                  label="Search brand"
                  variant="outlined"
                  margin="dense"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Paper>
              <Box m={1}>
                <TableContainer component={Box}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"> Date </TableCell>
                        <TableCell align="center"> Added By </TableCell>
                        <TableCell align="center"> Brand </TableCell>
                        <TableCell align="center"> Generic </TableCell>
                        <TableCell align="center"> Qty </TableCell>
                        <TableCell align="center"> Unit </TableCell>
                        <TableCell align="center"> Type </TableCell>
                        <TableCell align="center"> Supplier </TableCell>
                        <TableCell align="center"> Invoice/DR </TableCell>
                        <TableCell align="center"> Remarks </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logReady ? (
                        log.length > 0 ? (
                          searchable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <TableRow key={index}>
                                <TableCell align="right">
                                  {" "}
                                  {Notify.dateTimeConvert(data.created_at)}{" "}
                                </TableCell>
                                <TableCell>{data.pharmName}</TableCell>
                                <TableCell>{data.product}</TableCell>
                                <TableCell>{data.description}</TableCell>
                                <TableCell align="right">
                                  {data.quantity}
                                </TableCell>
                                <TableCell>{data.unit}</TableCell>
                                <TableCell align="center">
                                  {data.request_type}
                                </TableCell>
                                <TableCell>{data.supplier}</TableCell>
                                <TableCell align="right">
                                  {data.dr_no}
                                </TableCell>
                                <TableCell>{data.remarks}</TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={10} align="center">
                              <Typography variant="subtitle2" color="secondary">
                                {" "}
                                No log added{" "}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={10} align="center">
                            <Typography variant="subtitle2" color="primary">
                              {" "}
                              Loading...{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  <TablePagination
                    component={"div"}
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={log.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default Logs;
