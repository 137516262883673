
import React, { useState, useEffect, Fragment } from 'react';   
import Axios from 'axios';
import Notify from '../../notification/Notify';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';  
 
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';  
import TablePagination from '@material-ui/core/TablePagination'; 
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton'; 
import Collapse from '@material-ui/core/Collapse';  
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import PerfectScrollbar from 'react-perfect-scrollbar'; 
import { Paper } from '@material-ui/core';
import { UsersData } from 'src/ContextAPI';

const HealthMedication = (props) => { 

    const [page, setPage] = useState(0);  
    
    const [medication, setMedication] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(5);  

    const[selectedid, setselectedid] = useState(null) 

    const[medicationDetails, setMedicationDetails] = useState([]) 
 
    const [personalMedication, setPersonalMedication] = useState([]) 

    const [personalMedicationByDate, setPersonalMedicationByDate] = useState([])
    const [personalMedicationByDateReady, setPersonalMedicationByDateReady] = useState(false)

    const [pmedId, setPmedId ] = useState(null)

    const { users } = React.useContext(UsersData)

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };   

    const getMedication = () =>{
        var formdata = new FormData(); 
        formdata.set('user_id', users.user_id);
        formdata.set('patient_id', props.patient_id);
        formdata.set('connection', props.connection); 
        formdata.set('type', 'doctor');

        Axios.post('doctor/patient/medication/getmedication-list', formdata )
        .then((response)=>{ 
            const data = response.data;  
            setMedication(data)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const handleSelectedPrescription = (e) =>{ 
        if(e === 'match') { return null } 
        var prescription_date = e.currentTarget.getAttribute('date');
        var prescription_type = e.currentTarget.getAttribute('type');
        
        var formdata = new FormData(); 
            formdata.set('user_id', users.user_id)
            formdata.set('patient_id', props.patient_id) 
            formdata.set('prescription_date', prescription_date)
            formdata.set('prescription_type', prescription_type)
            formdata.set('connection', props.connection);

            Axios.post('doctor/patient/medication/getmedication-details', formdata)
            .then( (response) => { 
                const data = response.data;     
                setMedicationDetails(data) 
            }).catch(error=>{ 
                Notify.requestError(error);
            }); 

    }


    const getPersonalMedicationByDate = () =>{ 
        var formdata = new FormData();
 
        formdata.set('user_id', users.user_id);
        formdata.set('patient_id', props.patient_id);
        formdata.set('connection', props.connection);

        Axios.post('patient/medication/monitoring/list-medicationbydate', formdata )
        .then((response)=>{ 
            const data = response.data;  
            setPersonalMedicationByDate(data)
            setPersonalMedicationByDateReady(true) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }  

    const handleSelectedMedication = (e) =>{
        if(e === 'match') { return null } 
        var prescription_date = e.currentTarget.getAttribute('date'); 
        
        var formdata = new FormData(); 
        formdata.set('user_id', users.user_id);
        formdata.set('patient_id', props.patient_id);
        formdata.set('created_at', prescription_date)
        formdata.set('connection', props.connection);

        Axios.post('patient/medication/monitoring/list-medication', formdata )
        .then((response)=>{ 
            const data = response.data;  
            setPersonalMedication(data) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    useEffect(() =>{

        getMedication()

        getPersonalMedicationByDate() 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.patient_id])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box 
                        p={2} 
                        component={Paper}
                        elevation={2} 
                    >
                        <Box p={1}> 
                            <Typography variant="h6" color="textSecondary"> Prescriptions </Typography> 
                        </Box>
                        
                        {
                            medication.length > 0 ?  
                                <PerfectScrollbar>
                                    <TableContainer> 
                                        <Table className="gtc-table-borderless">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell> </TableCell>
                                                    <TableCell> <strong> DATE </strong> </TableCell> 
                                                    <TableCell> <strong> TYPE </strong> </TableCell>  
                                                </TableRow> 
                                            </TableHead>

                                            <TableBody> 
                                                {
                                                    (rowsPerPage > 0
                                                        ? medication.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : medication
                                                    ).map((data, index) => (
                                                        <Fragment key={index}>
                                                            <TableRow 
                                                                className= {selectedid === data.prescription_id ? "gtc-bg-light" : ""}  
                                                            > 
                                                                <TableCell>
                                                                    <IconButton 
                                                                        color="primary" 
                                                                        id={data.prescription_id} 
                                                                        date={data.created_at} 
                                                                        type={data.prescription_type} 
                                                                        onClick={(e)=>{
                                                                            setselectedid(selectedid === data.prescription_id ? null : data.prescription_id)  
                                                                            setMedicationDetails([])
                                                                            handleSelectedPrescription(selectedid === data.prescription_id ? 'match' : e)
                                                                        }}
                                                                    >
                                                                        {selectedid === data.prescription_id ? <KeyboardArrowDown/> : <KeyboardArrowRight />}
                                                                    </IconButton>
                                                                </TableCell> 
                                                                <TableCell>  { Notify.dateTimeConvert(data.created_at) } </TableCell>
                                                                <TableCell> <Typography variant="inherit" style={{textTransform: 'uppercase'}}> { data.prescription_type } </Typography> </TableCell>  
                                                            </TableRow>

                                                            {/* collapse */}
                                                            <TableRow>
                                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                    <Collapse in={selectedid === data.prescription_id ? true : false}>
                                                                        <Box margin={1}>
                                                                            <Typography  color="textSecondary" variant="h6">
                                                                                Prescription Details
                                                                            </Typography> 
                                                                            <Table size="small">
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell> Item  </TableCell>
                                                                                        <TableCell> Type  </TableCell>
                                                                                        <TableCell> Dosage  </TableCell>
                                                                                        <TableCell> Every   </TableCell>
                                                                                        <TableCell> Times   </TableCell> 
                                                                                        <TableCell> Remarks   </TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody> 
                                                                                    {
                                                                                        medicationDetails.length > 0 ? 
                                                                                            medicationDetails.map((meds, vindex) =>{
                                                                                                return(
                                                                                                    <TableRow key={vindex}>
                                                                                                        <TableCell> { meds.product_name }</TableCell>
                                                                                                        <TableCell> { meds.type } </TableCell>
                                                                                                        <TableCell> { meds.dosage } </TableCell>
                                                                                                        <TableCell className="text-right"> { meds.per_day } </TableCell>
                                                                                                        <TableCell className="text-right"> { meds.per_take } </TableCell>
                                                                                                        <TableCell>
                                                                                                            <Box  maxWidth={300}>
                                                                                                                <Typography>
                                                                                                                    { meds.remarks} 
                                                                                                                </Typography>
                                                                                                            </Box> 
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                )
                                                                                            })
                                                                                        :<TableRow>
                                                                                            <TableCell colSpan={6}> 
                                                                                                <Typography color="primary" align="center">
                                                                                                    please wait...
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    }
                                                                                </TableBody>
                                                                            </Table> 
                                                                        </Box>
                                                                    </Collapse>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    )) 
                                                }
                                            </TableBody>
                                        </Table>   
                                    </TableContainer> 
                                    <TablePagination
                                        labelRowsPerPage = "List"
                                        rowsPerPageOptions={[5, 20, 50, 100]}
                                        component="div"
                                        count={medication.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </PerfectScrollbar>
                            : Notify.noRecord()
                        }
                    </Box>
                </Grid>  
            
            
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box 
                        p={2} 
                        component={Paper} 
                        elevation={2} 
                    >
                        <Box p={1}>
                            <Typography variant="h6" color="textSecondary"> Personal Medication  </Typography>    
                        </Box> 

                        <Box pt={4}>
                            {
                                personalMedicationByDateReady ?
                                    personalMedicationByDate.length > 0 ?
                                        personalMedicationByDate.map((d, d_index) =>(
                                            <Box key={d_index}>   
                                                <Box  display="flex" >
                                                    <Box> 
                                                        <IconButton 
                                                            color="primary"
                                                            date={d.created_at}
                                                            onClick = {  (e) =>{
                                                                setPmedId( pmedId === d.id ? null : d.id )
                                                                setPersonalMedication([])
                                                                handleSelectedMedication(pmedId === d.id ? 'match' : e)
                                                            }} 
                                                        >  
                                                            { pmedId === d.id ? ( <KeyboardArrowDown /> ) : (<KeyboardArrowRight />) }
                                                        </IconButton>
                                                    </Box>
                                                    <Box mt={2} ml={3}>
                                                        <Typography variant="subtitle2">   
                                                            { Notify.createdAt(d.created_at) }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                
                                                <Collapse in={ pmedId === d.id ? true : false }> 
                                                    <Box ml={3}>  
                                                        {
                                                            personalMedication.length > 0 ?
                                                                personalMedication.map((data, index) =>(
                                                                    <Box key={index}  >
                                                                        <Box> 
                                                                            <Typography color="textSecondary" variant="subtitle1"> { data.meals } </Typography>
                                                                        </Box> 
                                                                        <Box px={1}>
                                                                            <Box  dangerouslySetInnerHTML={{ __html: data.description }}/>
                                                                        </Box> 
                                                                    </Box> 
                                                                ))
                                                            : Notify.loading()
                                                        }
                                                    </Box> 
                                                </Collapse> 
                                            </Box>
                                        ))
                                    :Notify.noRecord()
                                :Notify.loading()
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
}

// last push

export default HealthMedication; 