import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  // Box,
  Grid,
  // Typography,
  // Button,
  // TextField,
  // FormHelperText,
  // Paper,
  // Divider,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "../../notification/Notify";
// import * as Yup from "yup";
// import { Formik } from "formik";
// import CheckIcon from "@material-ui/icons/CheckCircleOutline";
// import ClearIcon from "@material-ui/icons/HighlightOff";
import LaboratoryTestList from "./LaboratoryTestList";
// import Select from "react-select";
// import LaboratoryOrder from "../../utils/LaboratoryOrder";
import { UsersData } from "../../ContextAPI";

const LaboratoryTest = () => {
  const { users } = React.useContext(UsersData);

  const [testList, setTestList] = useState({
    data: [],
    ready: false,
  });

  // const getFormData = (object) => {
  //   const formData = new FormData();

  //   Object.keys(object).forEach((key) => formData.append(key, object[key]));
  //   return formData;
  // };

  const getLabTest = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const request = await Axios.get("hims/laboratory/test/getAllTest", {
        params,
      });
      setTestList({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      // console.log("error : ", error);
      Notify.requestError(error);
    }
  }, [users]);

  useEffect(() => {
    getLabTest();
  }, [getLabTest]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <LaboratoryTestList list={testList} getLabTest={getLabTest} />
        </Grid>

        {/* <Grid item xs={12} sm={5} md={4}>
          <Box component={Paper} variant="outlined" p={2}>
            <Formik
              initialValues={{
                username: users.username,
                user_id: users.user_id,
                management_id: users.management_id,
                test: "",
                dept: "",
                rate: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string().required(),
                test: Yup.string().required(),
                dept: Yup.string().required(),
                rate: Yup.number().min(1).required(),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting, resetForm }
              ) => {
                try {
                  const request = await Axios.post(
                    "hims/laboratory/test/newtest-save",
                    getFormData(values)
                  );
                  if (request.data === "pass-invalid") {
                    setErrors({ password: "Password is invalid." });
                    Notify.fieldInvalid("password");
                  }
                  if (request.data === "success") {
                    Notify.successRequest("new test");
                    resetForm();
                    setSubmitting(true);
                    getLabTest();
                  }
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Box mb={2}>
                    <Typography color="textPrimary" variant="subtitle2">
                      NEW LABORATORY TEST
                    </Typography>
                  </Box>

                  <Divider />

                  <Box my={2} className={`labselect`}>
                    <Select
                      options={LaboratoryOrder.list()}
                      onChange={(data) => setFieldValue("test", data.value)}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                    {touched.dept && errors.dept && (
                      <FormHelperText error> {errors.dept} </FormHelperText>
                    )}
                  </Box>

                  <Box my={2} className={`labselect`}>
                    <Select
                      options={LaboratoryOrder.dept()}
                      onChange={(data) => setFieldValue("dept", data.value)}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                    {touched.dept && errors.dept && (
                      <FormHelperText error> {errors.dept} </FormHelperText>
                    )}
                  </Box>

                  <Box mb={2} className={`labselect2`}>
                    <TextField
                      error={Boolean(touched.rate && errors.rate)}
                      helperText={touched.rate && errors.rate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.rate}
                      fullWidth
                      required
                      name="rate"
                      label="Laboratory Rate"
                      variant="outlined"
                      type="number"
                      margin="dense"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.01,
                        },
                      }}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      fullWidth
                      required
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      label="Enter your password"
                      variant="outlined"
                      type="password"
                      margin="dense"
                    />
                  </Box>

                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <Divider />

                  <Box my={2} display="flex">
                    <Box flexGrow={1} />

                    <Button
                      variant="contained"
                      color="default"
                      type="reset"
                      startIcon={<ClearIcon />}
                    >
                      Clear
                    </Button>
                    <Box ml={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<CheckIcon />}
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Grid> */}
      </Grid>
    </Fragment>
  );
};

export default LaboratoryTest;
