 
import Axios from "axios";
import React, { useCallback, useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import Box from "@material-ui/core/Box"; 
import { Grid, Typography, useTheme } from "@material-ui/core"; 
import { Line } from 'react-chartjs-2';     
import { isMobile } from "react-device-detect"; 
import ChartCustomTooltip from "../laboratory_new/graph/ChartCustomTooltip";


const Glucose = ({ patient_id, connection }) => { 
    const [recordsLength, setRecordsLength] = useState(0)
    const [ready, setReady] = useState(false)

    const [glucose, setGlucose] = useState([])
    const [label, setLabel] = useState([]) 
    const themes = useTheme()
    const chartRef = React.useRef() 
    

    const getGlucose = useCallback(() => { 
        var formData = new FormData(); 
			formData.set('patient_id', patient_id);   
			formData.set('connection', connection); 

        Axios.post('doctor/patient/vitals/graph/get-glucose',  formData)
        .then((response) => {
            const data = response.data;
            setRecordsLength(data.length)
            pushDataToChart(data)
            setReady(true)
        })
        .catch(error => {
            Notify.requestError(error);
        })
    }, [ patient_id, connection]); 


    const pushDataToChart = (data) => {   
        if(data.length > 0){
            for (let i = 0; i < data.length; i++) {  
                setGlucose((glucose) => glucose.concat(data[i].glucose === null ? 0 : data[i].glucose)) 
                setLabel((label) => label.concat(Notify.dateTimeConvert(data[i].created_at)))
            } 
        }else{
            setGlucose((glucose) => glucose.concat(0)) 
            setLabel((label) => label.concat('No record'))
        }
         
    }  
    
    const data = {
        labels: label,
        datasets: [
            {
                label: 'Glucose', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                borderColor: themes.palette.primary.main,  
                pointBackgroundColor: themes.palette.primary.main,
                pointHoverRadius: 5,
                borderWidth: 2,
                pointRadius: 5 ,
                lineTension: 0,
                data: glucose
            }
        ]
    }


    const options = {
        animationEnabled: true,  
        maintainAspectRatio: false,    
        legend:{
            position: 'bottom',
            cursor: 'pointer',   
            display: false, 
        }, 
        scales:{
            xAxes: [{
                ticks: {
                    display: ! isMobile  //this will remove only the label
                }
            }]
        },
        tooltips: {   
            enabled: false, 
            custom:  (tooltipModel) => { 
                ChartCustomTooltip.show(tooltipModel, chartRef)
            } 
        },
        animation:{
            onComplete: () => {  
                const copyWidth = chartRef.current.chartInstance.scales['y-axis-0'].width - 10;
                const copyHeight = chartRef.current.chartInstance.scales['y-axis-0'].height + chartRef.current.chartInstance.scales['y-axis-0'].top + 10;
                const targetCtx = document.getElementById("myChartAxis").getContext("2d");
                      targetCtx.canvas.width = copyWidth;
                      targetCtx.drawImage(chartRef.current.chartInstance.canvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight); 
            } 
        }
    } 


    const initializeChartWidth = () =>{   
        chartRef.current.chartInstance.canvas.parentNode.style.width = '3500px';     
        chartRef.current.chartInstance.canvas.parentNode.style.height = `calc(90vh - 120px)`;       
    }

    useEffect(() => {
        
        initializeChartWidth()
        getGlucose()

        return(() =>{
            setReady(false)
        })

         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getGlucose])

    return (
        <Fragment>   
            <Box mb={2}>
                <Typography color="textSecondary" variant="h6">
                    Glucose Monitoring
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    { recordsLength } test recorded
                </Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>  
                    <Box flexGrow={1} maxWidth={'100%'} overflow={"auto"}> 
                        { ! ready && 
                            <Box align="center"> 
                                <Typography color="primary" variant="subtitle2" >
                                    fetching glucose records...
                                </Typography>
                            </Box>
                        } 
                        <div className="chartWrapper">  
                            <div 
                                style={{
                                    width:'100%', 
                                    overflowX: 'auto',
                                    overflowY: 'hidden'
                                }}
                            >   
                                    <Line    
                                        ref = { chartRef }
                                        data = { chartRef && data } 
                                        options={chartRef && options}  
                                    />  
                            </div> 
                            <canvas id="myChartAxis" height="100%" width="0"></canvas> 
                        </div>   
                    </Box>   
                </Grid>
            </Grid> 
        </Fragment>
    );
};

export default Glucose;