import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Box,
  useTheme,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import Select from "react-select";
import Notify from "src/notification/Notify";

const New = ({ getTempDrProducts }) => {
  const { users } = React.useContext(UsersData);
  const mounted = IsMountedRef();
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProducts] = useState("");

  const getProductsInWarehouse = React.useCallback(async () => {
    let response = await axios.get("stockroom/product/get-from-warehouse", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setProducts(response.data);
    }
  }, [users, mounted]);

  const handleNewProductIn = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("product_id", selectedProduct.product_id);
    formdata.set("product", selectedProduct.product_name);
    formdata.set("unit", selectedProduct.unit);
    formdata.set("management_id", users.management_id);
    formdata.set("type", "IN");

    var error = [];

    if (formdata.get("qty").trim() === "" || formdata.get("qty").length === 0) {
      error = "error";
      Notify.fieldRequired("qty");
    }

    if (
      formdata.get("product_id").trim() === "" ||
      formdata.get("product_id").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("product");
    }

    if (error.length > 0) {
      console.log("Form has an error");
    } else {
      let response = await axios.post(
        "stockroom/product/warehouse/save-totemp",
        formdata
      );
      const data = response.data;

      if (data.message === "success") {
        Notify.successRequest("product added");
        setSelectedProducts("");
        getTempDrProducts();
        e.target.reset();
      }
    }
  };

  useEffect(() => {
    getProductsInWarehouse();
  }, [getProductsInWarehouse]);

  return (
    <Card>
      <CardHeader title="New Product IN" />
      <CardContent>
        <form onSubmit={handleNewProductIn}>
          <Box>
            <Select
              options={products}
              onChange={(data) => setSelectedProducts(data)}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  backgroundColor:
                    theme.palette.type === "dark" ? "#424242" : "#fff",
                  color: theme.palette.type === "dark" ? "#fff" : "#000",
                }),
                control: (base) => ({
                  ...base,
                  minHeight: 55,
                  backgroundColor:
                    theme.palette.type === "dark" ? "#424242" : "#fff",
                  color: theme.palette.type === "dark" ? "#fff" : "#000",
                }),
                singleValue: () => ({
                  color: theme.palette.type === "dark" ? "#fff" : "#000",
                }),
                menuList: () => ({
                  color: theme.palette.type === "dark" ? "#fff" : "#000",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 99999 }),
              }}
              menuPortalTarget={document.body}
            />
          </Box>

          <Box my={3}>
            <TextField
              label="Generic Name"
              fullWidth
              variant="outlined"
              value={
                selectedProduct === "" ? "" : selectedProduct.product_generic
              }
              disabled
            />
          </Box>

          <Box my={3}>
            <TextField
              label="Unit"
              fullWidth
              variant="outlined"
              value={selectedProduct === "" ? "" : selectedProduct.unit}
              disabled
            />
          </Box>

          <Box my={3}>
            <TextField
              label="Qty"
              type="number"
              InputProps={{ inputProps: { min: 1, max: 9999999 } }}
              fullWidth
              variant="outlined"
              name="qty"
            />
          </Box>

          <Box display="flex">
            <Box>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                ADD
              </Button>
            </Box>

            <Box ml={2}>
              <Button
                size="large"
                variant="contained"
                color="default"
                onClick={() => setSelectedProducts("")}
              >
                No
              </Button>
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default New;
