import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { UsersData } from "src/ContextAPI";
// import Notify from "src/notification/Notify";

const PharmacyInventory = () => {
  const { users } = React.useContext(UsersData);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [invetoryData, setInvetoryData] = useState({
    data: [],
    ready: false,
  });

  const fetchInventoryList = () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("pharmacy/get-product-list", { params })
      .then((response) => {
        const data = response.data;
        setInvetoryData({ data: data, ready: true });
      })
      .catch((error) => {
        console.log("error : ", error);
        // Notify.requestError(error);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  React.useEffect(() => {
    fetchInventoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = invetoryData.data.filter((data) => {
    return (
      data.product.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.description.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1} />
        <Box>
          <TextField
            label="Search brand/generic"
            variant="outlined"
            margin="dense"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Paper>
        <Box m={1}>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"> BRAND </TableCell>
                  <TableCell align="center"> GENERIC </TableCell>
                  <TableCell align="center"> MSRP </TableCell>
                  <TableCell align="center"> SRP </TableCell>
                  <TableCell align="center"> RATE </TableCell>
                  <TableCell align="center"> AVAILABLE </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invetoryData.ready ? (
                  invetoryData.data.length > 0 ? (
                    searchable
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow key={index}>
                          <TableCell> {data.product} </TableCell>
                          <TableCell>{data.description}</TableCell>
                          <TableCell align="right">{data.unit_price}</TableCell>
                          <TableCell align="right">{data.srp}</TableCell>
                          <TableCell align="right">
                            {(
                              100 -
                              (parseFloat(data.unit_price) /
                                parseFloat(data.srp)) *
                                100
                            ).toFixed(2)}
                            %
                          </TableCell>
                          <TableCell align="right">
                            {data.sum_all_quantity}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography variant="subtitle2" color="secondary">
                          {" "}
                          No product added{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="subtitle2" color="primary">
                        {" "}
                        Loading...{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component={"div"}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={invetoryData.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default PharmacyInventory;
