import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Grid,
  makeStyles,
  Fab,
  createStyles,
} from "@material-ui/core";
import { Close, Print } from "@material-ui/icons";
import React, { useEffect, useState, useContext } from "react";
import { FormInformation } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const PrintExpense = ({ expenseDetails, close }) => {
  const classes = useStyles();
  const [totalexpese, settotalexpense] = useState(0);
  const { formInfo } = useContext(FormInformation);

  const getCalculate = (data) => {
    var total_expense = 0;
    for (let i = 0; i < data.length; i++) {
      total_expense +=
        parseFloat(data[i].unit_price) * parseFloat(data[i].quantity);
    }
    return total_expense;
  };

  useEffect(() => {
    settotalexpense(getCalculate(expenseDetails.data));
  }, [expenseDetails.data]);

  console.log("qweqwe", formInfo);

  return (
    <Box m={2}>
      <Box display="flex">
        <Box flexGrow={1} />
        <Typography variant="h4">{formInfo.company_name}</Typography>
      </Box>
      <Box display="flex">
        <Box flexGrow={1} />
        <Typography variant="h4">{expenseDetails.data[0].voucher}</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography>To: </Typography>
          <Typography>{formInfo.company_name}</Typography>
          <Typography>{formInfo.to_attention}</Typography>
          <Typography>{formInfo.to_address}</Typography>
          <Typography>{formInfo.to_full_address}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>Invoice Date</Typography>
          <Typography>
            {Notify.createdAt(expenseDetails.data[0].invoice_date)}
          </Typography>
          <Typography />
          <Typography>Reference Number</Typography>
          <Typography>{formInfo.ref_number}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>{formInfo.expense_category}</Typography>
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="center">Tax</TableCell>
              <TableCell align="right">Amount PHP</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {expenseDetails.data.map((data, index) => (
              <TableRow key={index}>
                <TableCell>{data.description}</TableCell>
                <TableCell align="right">{data.quantity}</TableCell>
                <TableCell align="right">
                  {Notify.convertToNumber(data.unit_price)}
                </TableCell>
                <TableCell align="center">
                  {data.tax === "Non-Vat" ? "Tax Exempt" : "Tax 12%"}
                </TableCell>
                <TableCell align="right">
                  {Notify.convertToNumber(
                    parseFloat(data.unit_price) * parseFloat(data.quantity)
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell align="center">Subtotal</TableCell>
              <TableCell align="right">
                {Notify.convertToNumber(totalexpese)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box my={2} mt={5}>
        <Box display="flex">
          <Box flexGrow={1} width={"50%"}>
            <Typography> Prepared by: </Typography>
            <Box align="center">{formInfo.prepared_by}</Box>
            <Box align="center">
              <Box style={{ borderTop: "1px solid #888" }} maxWidth={350} />
            </Box>
          </Box>

          <Box width={"50%"}>
            <Typography> Approved by: </Typography>
            <Box align="center">{formInfo.approved_by}</Box>
            <Box align="center">
              <Box style={{ borderTop: "1px solid #888" }} maxWidth={350} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box display="flex">
          <Box flexGrow={1} width={"50%"}>
            <Typography> Reviewed by: </Typography>
            <Box align="center">{formInfo.reviewed_by}</Box>
            <Box align="center">
              <Box style={{ borderTop: "1px solid #888" }} maxWidth={350} />
            </Box>
          </Box>

          <Box width={"50%"}>
            <Typography> Received by: </Typography>
            <Box align="center" mt={2.1}>
              <Box style={{ borderTop: "1px solid #888" }} maxWidth={350} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="d-print-none">
        <Fab
          aria-label={"Print"}
          className={classes.fab2}
          color={"secondary"}
          onClick={() => close()}
        >
          <Close />
        </Fab>
        <Fab
          aria-label={"Print"}
          className={classes.fab}
          color={"primary"}
          onClick={() => window.print()}
        >
          <Print />
        </Fab>
      </Box>
    </Box>
  );
};

export default PrintExpense;

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      width: "calc(100vw - 50px)",
      height: "calc(100vh - 90px)",
      justifyContent: "center",
      alignItems: "center",
    },
    idprint: {
      display: "block",
      width: "234px",
      height: "auto",
    },
    fab2: {
      position: "absolute",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },

    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    picturepatient: {
      width: 110,
      height: 110,
    },
    thumbnail: {
      height: 106,
    },
    infopatient: {
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
    },
    [`@media print`]: {
      body: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
      },
      idprint: {
        width: 275,
        height: 410,
      },
      titleCity: {
        fontSize: 30,
        fontWeight: "bolder",
      },
      qrSize: {
        width: 150,
        height: 150,
      },
      picturepatient: {
        width: 120,
        height: 120,
      },
      thumbnail: {
        height: 116,
      },
      infopatient: {
        padding: 0,
        fontSize: 14,
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      },
      infopatient2: {
        fontSize: 11,
      },
    },
  })
);
