import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData, BranchListContext } from "../ContextAPI";
import {
  faAddressCard,
  faHome,
  faPrint,
  // faSpellCheck,
  faUserInjured,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentationDashboard from "src/documentation/DocumentationDashboard";
import LeaveForm from "src/documentation/leave/LeaveForm";
import Account from "src/documentation/account/Account";
import ToEdit from "src/documentation/edit/ToEdit";
import IsMountedRef from "src/utils/IsMountedRef";
// import { Badge } from "@material-ui/core";
// import PrintIndex from "src/documentation/print";
import DocuPatientListVan from "src/documentation/patientqueuemobile/DocuPatientListVan";
import PrintResultForVan from "src/documentation/printforvan";

const RouteDocumentation = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);
  const mounted = IsMountedRef();
  const [branchList, setBranchList] = useState({ data: [], ready: false });

  var interval = null;
  // const [newPatientCountOnline, setNewPatientCountOnline] = useState(0);
  // const [newPatientCountLocal, setNewPatientCountLocal] = useState(0);

  // const [patientOnline, setPatientOnline] = useState({
  //   data: [],
  //   ready: false,
  // });
  const [patientLocal, setPatientLocal] = useState({
    data: [],
    ready: false,
  });

  const getSidebarHeaderInfo = React.useCallback(async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get(
        "documentation/sidebar/header-infomartion",
        {
          params,
        }
      );
      if (mounted.current) {
        setSidebarHeader(response.data);
      }
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  }, [userContext.users, mounted]);

  const getDocumentationQueueLocal = React.useCallback(async () => {
    var params = {
      user_id: userContext.users.user_id,
      management_id: userContext.users.management_id,
      main_mgmt_id: userContext.users.main_mgmt_id,
    };
    axios
      .get("documentation/get/local/result-to-edit", { params })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setPatientLocal({ data, ready: true });
          // if (data.length > 0) {
          //   setNewPatientCountLocal(data.length);
          // } else {
          //   setNewPatientCountLocal(0);
          // }
        }
      });
  }, [userContext.users, mounted]);

  const getAllBranches = React.useCallback(async () => {
    var params = {
      management_id: userContext.users.management_id,
      main_management_id: userContext.users.main_mgmt_id,
    };
    try {
      let response = await axios.get("documentation//to-edit/all-branches", {
        params,
      });
      const data = response.data;
      setBranchList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [userContext]);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/bmcdc/app/documentation",
      subitem: [],
    },

    // {
    //   name: "To Edit",
    //   show: true,
    //   icon: (
    //     <Badge color="error" badgeContent={parseFloat(newPatientCountLocal)}>
    //       <FontAwesomeIcon icon={faSpellCheck} color="white" size={"2x"} />,
    //     </Badge>
    //   ),
    //   path: "/bmcdc/app/documentation/to-edit",
    //   subitem: [],
    // },

    {
      name: "Patients",
      show: true,
      icon: <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
      path: "/bmcdc/app/documentation/patient",
      subitem: [],
    },

    // {
    //   name: "To Print",
    //   show: true,
    //   icon: <FontAwesomeIcon icon={faPrint} color="white" size={"2x"} />,
    //   path: "/bmcdc/app/documentation/to-print",
    //   subitem: [],
    // },

    {
      name: "Van Result Print",
      show: true,
      icon: <FontAwesomeIcon icon={faPrint} color="white" size={"2x"} />,
      path: "/bmcdc/app/documentation/mobile-to-print",
      subitem: [],
    },

    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/bmcdc/app/documentation/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/documentation/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  const initializeInterval = () => {
    interval = setInterval(() => {
      getDocumentationQueueLocal();
    }, 20000);
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getDocumentationQueueLocal();
    getAllBranches();
  }, [getSidebarHeaderInfo, getDocumentationQueueLocal, getAllBranches]);

  useEffect(() => {
    initializeInterval();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
          queue: {
            patientLocal: patientLocal,
            getPatientsOnQueueLocal: () => getDocumentationQueueLocal(),
          },
        }}
      >
        <BranchListContext.Provider
          value={{
            branchList: branchList,
            updateBranchList: () => getAllBranches(),
          }}
        >
          <BrowserRouter>
            <Sidebar
              notification={{
                enable: true,
                owner: "documentation",
              }}
              header={sidebarHeader}
              routes={sidebarRoute}
              module={"documentation"}
            />

            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/bmcdc/app/documentation" />}
              />

              <Route
                exact
                path="/bmcdc"
                component={() => <Redirect to="/bmcdc/app/documentation" />}
              />

              <Route
                exact
                path="/bmcdc/app"
                component={() => <Redirect to="/bmcdc/app/documentation" />}
              />

              <Route
                exact
                path="/bmcdc/app/documentation"
                component={DocumentationDashboard}
              />

              <Route
                exact
                path="/bmcdc/app/documentation/to-edit"
                component={ToEdit}
              />

              <Route
                exact
                path="/bmcdc/app/documentation/patient"
                component={DocuPatientListVan}
              />

              {/* <Route
                exact
                path="/bmcdc/app/documentation/to-print"
                component={PrintIndex}
              /> */}

              <Route
                exact
                path="/bmcdc/app/documentation/mobile-to-print"
                component={PrintResultForVan}
              />

              <Route
                exact
                path="/bmcdc/app/documentation/leave-application"
                component={LeaveForm}
              />

              <Route
                exact
                path="/bmcdc/app/documentation/account"
                component={Account}
              />

              <Route exact path="/bmcdc/app/logout" component={Logout} />

              <Route render={() => <PageNotFound title="Page not found" />} />
            </Switch>
          </BrowserRouter>
        </BranchListContext.Provider>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteDocumentation;
