import React, { useContext, useEffect, useState } from 'react'
import { UsersData } from 'src/ContextAPI'
import Container from 'src/layout/Container'
import { Box, Grid, Paper, TableCell, Table, TableContainer, TableRow, TableBody, Typography, TableHead, TablePagination, TextField, InputAdornment, IconButton, FormHelperText, Button, Divider, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from 'axios'
import Notify from 'src/notification/Notify';
import { Edit, Search } from '@material-ui/icons';
import { Formik } from "formik";
import * as Yup from "yup";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const DoctorServices = () => {
    const [search, setSearch] = useState("");
    const {users} = useContext(UsersData)
    const [listServices, setListServices] = useState({data: [], ready: false}) 
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [is_process, set_is_process] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedServiceAmount, setSelectedServiceAmount] = useState(null);
    const [selectedServiceId, setSelectedServiceId] = useState(null)
    const [editDialog, setEditDialog] = useState(false);

    const getAllServices =  async() =>{
        var params = {user_id : users.user_id, management_id: users.management_id}
        try {
            const request = await axios.get("doctor/get/all/services", {params})
            setListServices({data: request.data, ready: true})
        } catch (error) {
            console.log('error :', error)
        }
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditServices = (e) => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);
        formdata.set("user_id", users.user_id);
        formdata.set("username", users.username);
        formdata.set("service_id", selectedServiceId);

        var error = [];

        if (
        formdata.get("service").length === 0 ||
        formdata.get("service").trim() === ""
        ) {
        error = "error";
        Notify.fieldRequired("service");
        }
        if (
        formdata.get("service_amount").length === 0 ||
        formdata.get("service_amount").trim() === ""
        ) {
        error = "error";
        Notify.fieldRequired("service amount");
        }
        if (
        formdata.get("password").length === 0 ||
        formdata.get("password").trim() === ""
        ) {
        error = "error";
        Notify.fieldRequired("password");
        }
        if (error.length > 0) {
        console.warn("Form has an error, observe please");
        } else {
        set_is_process(true);
        axios
            .post("doctor/update/service", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    getAllServices();
                    setEditDialog(false);
                    setSelectedServiceId(null);
                    setSelectedServiceAmount(null);
                    setSelectedService(null);
                    Notify.successRequest("update service");
                }
                if (data === "pass-invalid") {
                    Notify.fieldInvalid("password");
                }
            })
            .catch((error) => {
                Notify.requestError(error);
            })
            .finally(() => {
                set_is_process(false);
            });
        }
    }

    const getFormData = (object) => {
        const formData = new FormData();
        Object.keys(object).forEach((key) => formData.append(key, object[key]));
        return formData;
    };

    useEffect(()=>{
        getAllServices()
        // eslint-disable-next-line
    }, [])

    const searchable = listServices.data.filter((data) => {
        return (
            data.services.toLowerCase().indexOf(search.trim()) !== -1
        );
    });

    return (
        <Container
        breadcrumbs={{
          enable: true,
          current: "Services",
          items: [
            { name: "Dashboard", path: "/bmcdc/app/doctor" },
          ],
        }}
        title="Services"
      >
        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Paper elevation={2} component={Box}>
                <Box p={2} display="flex" justifyContent="center" alignItems="center">
                    <Box flexGrow={1}>
                        <Typography variant="h6" color="textSecondary">
                            List of Services
                        </Typography>
                    </Box>
                    <Box>
                        <TextField
                            label="Search services"
                            variant="outlined"
                            margin="dense"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment>
                                    <Search />
                                </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
                <TableContainer>
                  <PerfectScrollbar>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell> Service </TableCell>
                          <TableCell> Amount </TableCell>
                          <TableCell> Action </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listServices.ready ? (
                          listServices.data.length > 0 ? (
                            searchable.map((data, index) => (
                              <TableRow key={index}>
                                <TableCell>{data.services}</TableCell>
                                <TableCell>{Notify.convertToNumber(data.amount)}</TableCell>
                                <TableCell> 
                                    <IconButton color="primary" onClick={()=>{
                                        setSelectedService(data.services);
                                        setSelectedServiceAmount(data.amount);
                                        setSelectedServiceId(data.service_id);
                                        setEditDialog(true)
                                    }}>
                                        <Edit />
                                    </IconButton> 
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>
                                <Typography color="error">
                                  No service recorded
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Typography color="primary">
                                Please wait...
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </PerfectScrollbar>
                  <TablePagination
                    component={"div"}
                    rowsPerPageOptions={[ 10, 25, 50, 100]}
                    colSpan={3}
                    count={searchable.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleLimitChange}
                  />
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Paper elevation={2} component={Box}>
                    <Box>
                        <Box p={2} borderRadius={4}>
                            <Box display="flex" justifyContent="center">
                                <Box flexGrow={1}>
                                    <Typography noWrap color="primary">
                                        <strong>New Services</strong>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <Formik
                                initialValues={{
                                    user_id: users.user_id,
                                    username: users.username,
                                    management_id: users.management_id,
                                    service: "",
                                    service_amount: "",
                                    password: "",
                                }}
                                validationSchema={Yup.object().shape({
                                    service: Yup.string().required("Service is required."),
                                    service_amount: Yup.number().required("Service amount is required"),
                                    password: Yup.string().required('Password is required'),
                                })}
                                onSubmit={async (
                                    values,
                                    { setErrors, setSubmitting, resetForm }
                                ) => {
                                    try {
                                    const request = await axios.post(
                                        "doctor/add-new-service",
                                        getFormData(values)
                                    );
                                    if (request.data === "pass-invalid") {
                                        setErrors({ password: "Password doesn't matched" });
                                        Notify.customToast(
                                        "Invalid Password",
                                        "Password doesn't matched"
                                        );
                                    }
                                    if (request.data === "success") {
                                        Notify.successRequest("add new service");
                                        resetForm();
                                        setSubmitting(true);
                                        getAllServices();
                                    }
                                    } catch (error) {
                                    const message = error.message || "Something went wrong";
                                    setErrors({ submit: message });
                                    setSubmitting(false);
                                    }
                                }}
                                >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                    resetForm,
                                    setFieldValue,
                                }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid xs={12} item>
                                        <Box mb={1}>
                                            <TextField
                                                error={Boolean(
                                                    touched.service && errors.service
                                                )}
                                                helperText={
                                                    touched.service && errors.service
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.service}
                                                fullWidth
                                                required
                                                multiline
                                                name="service"
                                                label="Service"
                                                variant="outlined"
                                            />
                                        </Box>
                                        </Grid>
                                        <Grid xs={12} item>
                                        <Box mb={1}>
                                            <TextField
                                            error={Boolean(
                                                touched.service_amount && errors.service_amount
                                              )}
                                              helperText={
                                                touched.service_amount && errors.service_amount
                                              }
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              value={values.service_amount}
                                              fullWidth
                                              required
                                              name="service_amount"
                                              label="Service amount"
                                              variant="outlined"
                                              type="number"
                                              InputProps={{
                                                inputProps: {
                                                  min: 1,
                                                  max: 10000,
                                                  step: 0.01,
                                                }
                                              }}
                                            />
                                        </Box>
                                        </Grid>

                                        <Grid xs={12} item>
                                        <Box mb={1}>
                                            <TextField
                                            fullWidth
                                            required
                                            error={Boolean(
                                                touched.password && errors.password
                                            )}
                                            helperText={touched.password && errors.password}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            name="password"
                                            label="Enter your password"
                                            variant="outlined"
                                            type="password"
                                            />
                                        </Box>
                                        </Grid>
                                    </Grid>

                                    {errors.submit && (
                                        <Box mt={2}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}

                                    <Box mt={2} display="flex">
                                        <Box flexGrow={1} />

                                        <Button
                                        variant="contained"
                                        color="default"
                                        onClick={() => resetForm()}
                                        startIcon={<HighlightOffIcon />}
                                        >
                                        Reset
                                        </Button>

                                        <Box ml={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CheckCircleIcon />}
                                            disabled={isSubmitting}
                                        >
                                            Save
                                        </Button>
                                        </Box>
                                    </Box>
                                    </form>
                                )}
                            </Formik> 
                        </Box>
                    </Box>
                </Paper>       
            </Grid>
        </Grid>
        <Dialog
            open={editDialog}
            onClose={()=>{
                setSelectedService(null);
                setSelectedServiceAmount(null);
                setSelectedServiceId(null);
                setEditDialog(false);
            }}
            fullWidth
            maxWidth="xs"
        >   
            <DialogTitle>
                <Typography>
                    Edit Service
                </Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleEditServices}>
                    <Grid container spacing={2}>
                        <Grid xs={12} item>
                        <Box mb={1}>
                            <TextField
                                fullWidth
                                required
                                multiline
                                name="service"
                                label="Service"
                                variant="outlined"
                                defaultValue={selectedService}

                            />
                            {console.log(selectedService)}
                        </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box mb={1}>
                                <TextField
                                    fullWidth
                                    required
                                    name="service_amount"
                                    label="Service amount"
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            max: 10000,
                                            step: 0.01,
                                        }
                                    }}
                                    defaultValue={selectedServiceAmount}
                                />
                            </Box>
                        </Grid>

                        <Grid xs={12} item>
                            <Box mb={1}>
                                <TextField
                                    fullWidth
                                    required
                                    name="password"
                                    label="Enter your password"
                                    variant="outlined"
                                    type="password"
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={2} display="flex">
                        <Box flexGrow={1} />

                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => {
                                setSelectedService(null);
                                setSelectedServiceAmount(null);
                                setSelectedServiceId(null);
                                setEditDialog(false);
                            }}
                            startIcon={<HighlightOffIcon />}
                        >
                            Close
                        </Button>

                        <Box ml={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={<CheckCircleIcon />}
                                disabled={is_process}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
      </Container>
    )
}

export default DoctorServices;