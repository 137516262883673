import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  Button,
  Divider,
  IconButton,
} from "@material-ui/core";
import Container from "src/layout/Container";
import SearchIcon from "@material-ui/icons/Search";
import { AddCircle } from "@material-ui/icons";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ArrowRight } from "react-feather";
import ExpenseAllList from "./ExpenseAllList";
import ExpenseDetailsList from "./ExpenseDetailsList";

const ExpenseList = () => {
  const { users } = useContext(UsersData);
  const [search, setSearch] = useState("");
  const [expenseList, setExpenseList] = useState({ data: [], ready: false });
  const history = useHistory();
  const [selectedVoucher, setSelectedVoucher] = useState(null);

  const getAllExpenseList = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      management_name: users.management_name,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("accounting/get/expense-list", { params });
      const data = response.data;
      setExpenseList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  useEffect(() => {
    getAllExpenseList();
  }, [getAllExpenseList]);

  const searchable = expenseList.data.filter((data) => {
    return data.voucher.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Expense ",
          items: [{ name: "Dashboard", path: "/bmcdc/app/accounting" }],
        }}
        title={
          <Box display={"flex"}>
            <Box flexGrow={1}>
              <Typography variant="h4">Expense</Typography>
            </Box>
            <Box
              component={Paper}
              display="flex"
              pl={2}
              pr={2}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant={"h6"}
                style={{ fontWeight: "bolder" }}
                color={"primary"}
              >
                OVERALL EXPENSE : ₱
                {expenseList.data.length > 0
                  ? Notify.convertToNumber(expenseList.data[0].totalExpense)
                  : 0}
              </Typography>
            </Box>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box>
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={1}
                >
                  <Box flexGrow={1}>
                    <Typography
                      color="primary"
                      variant={"subtitle1"}
                      style={{ fontWeight: "bolder" }}
                    >
                      VOUCHER LIST
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        history.push("/bmcdc/app/management/add-expense")
                      }
                      startIcon={<AddCircle />}
                    >
                      Expense
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box>
                  <TextField
                    label="Search Voucher"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box component={Paper}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Voucher</TableCell>
                      <TableCell align="center">Invoice Date</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {expenseList.ready ? (
                      expenseList.data.length > 0 ? (
                        searchable.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell>{data.voucher}</TableCell>
                            <TableCell align="right">
                              {Notify.createdAt(data.invoice_date)}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                color={
                                  data.expense_main_id === selectedVoucher
                                    ? "secondary"
                                    : "primary"
                                }
                                onClick={() =>
                                  setSelectedVoucher(data.expense_main_id)
                                }
                              >
                                <ArrowRight />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={3}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <Typography color="primary">
                            Loading, please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            {selectedVoucher === null && <ExpenseAllList />}
            {selectedVoucher !== null && (
              <ExpenseDetailsList
                selectedVoucher={selectedVoucher}
                close={() => setSelectedVoucher(null)}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ExpenseList;
