import React, { useContext, useEffect, useState } from 'react';  
import Notify from '../../../notification/Notify';
import Axios from 'axios';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row' 
import ImagingProcessedDetails from './ImagingProcessedDetails';
import ImagingProcessedAttachment from './ImagingProcessedAttachment'; 
import { PatientsUnRead, UsersData } from 'src/ContextAPI'; 
import { Box, Card,  Dialog, DialogContent, DialogTitle, IconButton, Badge, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { green } from '@material-ui/core/colors';

const ImagingProcessed = ({ patient_id, imagingType }) =>{ 

    const [imaging, setImaging] = useState({ data: [], ready: false })    
 
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [selectedDialog, setSelectedDialog] = useState('details') 
    const { users } = useContext(UsersData)
    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, department, order_id ) =>{
        
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;
        let count = 0;

        if(imagingType === 'local-imaging'){
            for (let i = 0; i < xx.length; i++) {
                if( patient_id === xx[i].patient_id && category === xx[i].category && department === xx[i].department && order_id === xx[i].order_id){
                    count += 1;
                }
            }
        }else{
            for (let i = 0; i < yy.length; i++) {
                if( patient_id === yy[i].patient_id && category === yy[i].category && department === yy[i].department && order_id === yy[i].order_id){
                    count += 1;
                }
            }
        }
        
        return count;
    }

    const getImagingWithResult = () => {
        var formdata = new FormData();   
        formdata.set('user_id', users.user_id);    
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', imagingType === 'virtual-imaging' ? 'online' : 'local')

        Axios.post('doctor/patient/imaging/imaging-processed', formdata )
        .then(function(response){ 
            const data = response.data;   
            setImaging({ data: data, ready: true }) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const closeDialog = () =>{
        setSelectedOrder(null)
        setDialogOpen(false)  
    }

    useEffect(() =>{
        
        getImagingWithResult()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ patient_id, imagingType ])
    
    return(
        <> 
            <Row>
                <Col sm={12} className="mt-3">
                    {
                        imaging.ready ? 
                            imaging.data.length > 0 ?
                                imaging.data.map((data, index) =>{
                                    return( 
                                        <Card key={index} component={Box} borderLeft={3} borderColor={green[600]} mb={2}> 
                                            <CardContent> 
                                                <Box>
                                                    <Typography variant="subtitle1" className="gtc-capitalize"> 
                                                        <Badge 
                                                            color="secondary"
                                                            invisible={ !  Boolean(parseInt(checkPatientUnreadNotif('imaging', 'processed', data.imaging_center_id))) }
                                                            badgeContent={`new`}
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right'
                                                            }} 
                                                        > 
                                                            { data.imaging_order === null ? 'No order.' : data.imaging_order.toLowerCase() } 
                                                        </Badge> 
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        { data.imaging_result === null ? 'No result yet.' : data.imaging_result }
                                                    </Typography>
                                                </Box>
                                            </CardContent>

                                            <CardActions>
                                                <Button
                                                    color="primary" 
                                                    imagingid={data.imaging_center_id} 
                                                    onClick={ ()=> {
                                                        setSelectedOrder(data)
                                                        setSelectedDialog('details')
                                                        setDialogOpen(true)  
                                                    }}
                                                >
                                                    details
                                                </Button> 

                                                <Button
                                                    color="primary" 
                                                    imagingid={data.imaging_center_id} 
                                                    onClick={() =>{
                                                        setSelectedOrder(data.imaging_result_attachment )
                                                        setSelectedDialog('attachment')
                                                        setDialogOpen(true)  
                                                    }}
                                                    disabled={data.imaging_result_attachment === null}
                                                >
                                                    attachment
                                                </Button>

                                                <Typography variant="caption" className="gtc-uppercase">
                                                    { data.end_time === null && `done on ${Notify.createdAt(data.end_time)}` } 
                                                </Typography> 
                                            </CardActions>
                                        </Card>  
                                    )
                                })
                            : <Card> <CardContent> { Notify.noRecord() } </CardContent> </Card>
                        : <Card> <CardContent> { Notify.loading() } </CardContent> </Card> 
                    }
                </Col>
            </Row>
            
            {/* Details Dialog */}
            <Dialog 
                open={ dialogOpen } 
                onClose={ closeDialog } 
                maxWidth="sm"
            >
                <Box display="flex">
                    <Box flexGrow={1}> 
                        <DialogTitle> 
                            Order Information
                        </DialogTitle>
                    </Box>
                    <Box mr={2} mt={1}>
                        <IconButton 
                            onClick={ closeDialog }
                            color="secondary"
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContent dividers>
                    {  selectedOrder !==null && selectedDialog === 'details' && ( <ImagingProcessedDetails imagingType ={ imagingType } details = { selectedOrder } /> ) }
                    {  selectedOrder !==null && selectedDialog === 'attachment' && ( <ImagingProcessedAttachment imagingType ={ imagingType }  details = { selectedOrder } />   ) } 
                </DialogContent>
            </Dialog> 
        </>
    )
} 

export default ImagingProcessed;
 