import {
  Grid,
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Search } from "react-feather";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import CreatePsychologyOrder from "./CreatePsychologyOrder";

const PsychologyCreateOrder = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [search, setSearch] = useState("");
  const [psycOrderList, setPsycOrderList] = useState({
    data: [],
    ready: false,
  });

  const getPsychologyOrderList = React.useCallback(async () => {
    let response = await axios.get("accounting/get/psychology-test", {
      params: {
        management_id: users.management_id,
        user_id: users.user_id,
      },
    });

    if (mounted.current) {
      setPsycOrderList({ data: response.data, ready: true });
    }
  }, [users, mounted]);

  useEffect(() => {
    getPsychologyOrderList();
  }, [getPsychologyOrderList]);

  const searchable = psycOrderList.data.filter((data) => {
    return data.test.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Box component={Paper} p={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h6" color="textSecondary">
                Psychology Order List
              </Typography>
            </Box>
            <Box>
              <TextField
                label="Search order"
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Test</TableCell>
                    <TableCell align="center">Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {psycOrderList.ready ? (
                    psycOrderList.data.length > 0 ? (
                      searchable.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>{data.test}</TableCell>
                          <TableCell align="right">{data.rate}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={2}>
                          <Typography color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={2}>
                        <Typography color="primary">Please wait...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CreatePsychologyOrder
          getPsychologyOrderList={() => getPsychologyOrderList()}
        />
      </Grid>
    </Grid>
  );
};

export default PsychologyCreateOrder;
