import {
  Box,
  Paper,
  TablePagination,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment, useCallback, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import PersonIcon from "@material-ui/icons/Person";

var interval = null;

const LaboratoryPatientPending = () => {
  const { users } = React.useContext(UsersData);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [labPendingData, setLabPendingData] = useState({
    data: [],
    ready: false,
  });

  const fetchAllPatientPending = useCallback(async () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("hims/laboratory/pending-patient", { params })
      .then((response) => {
        const data = response.data;
        setLabPendingData({ data: data, ready: true });
      })
      .catch((error) => {
        console.log("error : ", error);
        // Notify.requestError(error);
      });
  }, [users]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  React.useEffect(() => {
    fetchAllPatientPending();

    interval = setInterval(() => {
      fetchAllPatientPending();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchAllPatientPending]);

  const searchable = labPendingData.data.filter((data) => {
    return data.patient_name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1} />
        <Box>
          <TextField
            label="Search patient"
            variant="outlined"
            margin="dense"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Paper>
        <Box m={1}>
          <List>
            {labPendingData.ready
              ? labPendingData.data.length > 0
                ? searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      (data, index) =>
                        parseFloat(data.order_count) > 0 && (
                          <Fragment key={index}>
                            <ListItem>
                              <Fragment>
                                <ListItemAvatar>
                                  <Avatar>
                                    <PersonIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={data.patient_name}
                                  secondary={data.patient_address}
                                />
                              </Fragment>
                            </ListItem>
                            <Divider light />
                          </Fragment>
                        )
                    )
                : Notify.noRecord()
              : Notify.loading()}
          </List>
          <TablePagination
            component={"div"}
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={labPendingData.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </Fragment>
  );
};

export default LaboratoryPatientPending;
