import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Notify from "src/notification/Notify";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#00cc00",
    color: "#000000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const AdmissionQueuingFullscreen = ({ queuingList }) => {
  useEffect(() => {}, [queuingList]);

  return (
    <Box p={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography style={{ textTransform: "uppercase" }} variant="h3">
                  <b> No. </b>
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography style={{ textTransform: "uppercase" }} variant="h3">
                  <b> Patient </b>
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography style={{ textTransform: "uppercase" }} variant="h3">
                  <b> Department </b>
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {queuingList.ready ? (
              queuingList.data.length > 0 ? (
                queuingList.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography
                        style={{ textTransform: "uppercase" }}
                        variant="h3"
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        style={{ textTransform: "uppercase" }}
                        variant="h3"
                      >
                        <strong>
                          {data.firstname} {data.lastname.charAt(0)}.
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        style={{ textTransform: "uppercase" }}
                        variant="h3"
                      >
                        {data.type === "endorsement"
                          ? "ENDORSEMENT"
                          : data.type === "cashier"
                          ? "CASHIER"
                          : data.type === "nursing-station"
                          ? "NURSE STATION"
                          : data.type === "doctor"
                          ? "DOCTOR"
                          : data.type === "laboratory"
                          ? "LABORATORY"
                          : data.type === "imaging"
                          ? "IMAGING"
                          : data.type === "psychology"
                          ? "PSYCHOLOGY"
                          : "RECEIVING/EXTRACTION"}{" "}
                        {data.doctorsName !== null && `(${data.doctorsName})`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>{Notify.noRecord()}</TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={3}>{Notify.loading()}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdmissionQueuingFullscreen;
