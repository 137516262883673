import React, { useContext, useEffect, useRef, useState } from "react";
import Notify from "../../notification/Notify";
import Axios from "axios";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import {
  InputAdornment,
  TextField,
  Badge,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  makeStyles,
  TableHead,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  useTheme,
  Grid,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { PatientsUnRead, UsersData } from "src/ContextAPI";
import { useHistory } from "react-router-dom";
import Container from "src/layout/Container";
import { Trello as NorecordIcon } from "react-feather";
import IsMountedRef from "src/utils/IsMountedRef";
import TabPanel from "src/utils/TabPanel";
import axios from "axios";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(6.8),
    height: theme.spacing(6.8),
  },
}));

const Patients = () => {
  const classes = useStyles();
  const history = useHistory();

  const [patients, setPatients] = useState({ data: [], ready: false });

  const [search, setSearch] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const tableRef = useRef(null);

  const unread = useContext(PatientsUnRead);

  var interval = null;

  const { users } = React.useContext(UsersData);

  const ismounted = IsMountedRef();

  const getPatients = () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);

    Axios.post("doctor/patient/patients-list", formdata)
      .then((response) => {
        if (ismounted.current) {
          const data = response.data;
          setPatients({ data: data, ready: true });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const checkPatientUnreadNotif = (patient_id, index) => {
    let xx = unread.unviewNotif;
    let yy = unread.unviewNotifVirtual;

    let count = 0;

    for (let i = 0; i < xx.length; i++) {
      if (patient_id === xx[i].patient_id) {
        count += 1;
      }
    }

    for (let i = 0; i < yy.length; i++) {
      if (patient_id === yy[i].patient_id) {
        count += 1;
      }
    }

    return count;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const initializeTableSort = () => {
    interval = setInterval(() => {
      sortTable();
    }, 3000);
  };

  const sortTable = () => {
    console.log("test oly/.");
    var table, rows, switching, i, x, y, shouldSwitch;
    table = tableRef.current;
    switching = true;

    while (switching) {
      switching = false;

      if (table) {
        console.log("rows is empty:", rows);
        return;
      }

      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[0];
        y = rows[i + 1].getElementsByTagName("TD")[0];

        if (Number(x.innerHTML) < Number(y.innerHTML)) {
          shouldSwitch = true;
          break;
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  };

  React.useEffect(() => {
    getPatients();

    if (tableRef.current) {
      initializeTableSort();
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var searchable = patients.data.filter((data) => {
    return (
      data.lastname.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      data.firstname.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  const [category, setCategory] = useState("queuing");

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Patient",
          items: [{ name: "Dashboard", path: "/bmcdc/app/doctor" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1} mt={1}>
              <Typography variant="h5" color="textSecondary">
                Patients
              </Typography>
            </Box>
            <Box>
              <Paper square>
                <Tabs
                  value={category}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab wrapped value="queuing" label="Queuing" />
                  <Tab wrapped value="all" label="All" />
                </Tabs>
              </Paper>
            </Box>
          </Box>
        }
      >
        <Box>
          <TabPanel value={category} index="queuing">
            <RenderQueuingPatients />
          </TabPanel>
        </Box>

        <Box>
          <TabPanel value={category} index="all">
            <Paper component={Box} elevation={2} p={2}>
              <Box>
                <Box display="flex" justifyContent="center" mb={2}>
                  <Box flexGrow={1} mt={1}>
                    <Typography color="textSecondary" variant="h6">
                      Patients List
                    </Typography>
                  </Box>

                  <Box>
                    <TextField
                      label="Search name"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>

                <TableContainer>
                  <Table ref={tableRef}>
                    <TableHead>
                      {/* controller of sortby desc */}
                      <TableRow hidden>
                        <TableCell style={{ width: 0 }} />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {patients.ready ? (
                        searchable.length > 0 ? (
                          searchable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <TableRow
                                key={index}
                                hover
                                className={`cursor-pointer`}
                                onClick={() => {
                                  history.push(
                                    `/bmcdc/app/doctor/patient/details/${data.patient_id}`
                                  );
                                }}
                              >
                                {/* sort by desc purposes */}
                                <TableCell hidden>
                                  {checkPatientUnreadNotif(data.patient_id)}
                                </TableCell>
                                <TableCell style={{ width: 50 }}>
                                  <Badge
                                    invisible={
                                      !Boolean(
                                        checkPatientUnreadNotif(data.patient_id)
                                      )
                                    }
                                    color="error"
                                    badgeContent={checkPatientUnreadNotif(
                                      data.patient_id
                                    )}
                                    anchorOrigin={{
                                      horizontal: "left",
                                      vertical: "top",
                                    }}
                                  >
                                    {data.image === null ? (
                                      <Avatar className={classes.primary}>
                                        {data.lastname.charAt().toUpperCase()}
                                      </Avatar>
                                    ) : (
                                      <Avatar
                                        className={classes.primary}
                                        src={`${imageLocation}patients/${data.image}`}
                                        alt=""
                                      />
                                    )}
                                  </Badge>
                                </TableCell>

                                <TableCell>
                                  <Typography
                                    variant={"h6"}
                                    className={`gtc-capitalize`}
                                  >
                                    {` ${data.lastname}, ${data.firstname}`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              <Typography color="secondary">
                                <NorecordIcon size={30} /> <br /> No patient
                                found.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            <CircularProgress size={20} color="primary" />
                            <Typography color="primary">
                              please wait...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  <TablePagination
                    component="div"
                    count={searchable.length}
                    rowsPerPageOptions={[10, 50, 100]}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Box>
            </Paper>
          </TabPanel>
        </Box>
      </Container>
    </>
  );
};

export default Patients;

const RenderQueuingPatients = () => {
  const { users } = React.useContext(UsersData);
  const history = useHistory();
  const theme = useTheme();
  const [queuing, setQueuing] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getPatientWithLocalAppointment = React.useCallback(async () => {
    let response = await axios.get("doctor/patient/queuing-list", {
      params: {
        user_id: users.user_id,
      },
    });

    setQueuing({ data: response.data, ready: true });
  }, [users]);

  useEffect(() => {
    getPatientWithLocalAppointment();
  }, [getPatientWithLocalAppointment]);

  var searchable = queuing.data.filter((data) => {
    return (
      data.lastname.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      data.firstname.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography color="textSecondary" variant="h6">
                  Queuing List
                </Typography>
              </Box>
              <Box>
                <TextField
                  label="Search name"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            {queuing.ready ? (
              searchable.length > 0 ? (
                <List component="nav">
                  {searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <ListItem
                        key={index}
                        button
                        onClick={() =>
                          history.push(
                            `/bmcdc/app/doctor/patient/details/${data.patients_id}`
                          )
                        }
                        style={{
                          marginBottom: 5,
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              color: "#fff",
                              backgroundColor: theme.palette.primary.main,
                              width: 55,
                              height: 55,
                              marginRight: 25,
                              fontSize: 20,
                            }}
                          >
                            {index + 1}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${data.lastname}, ${data.firstname} ${
                            data.middle === null ? "" : data.middle
                          }`}
                          primaryTypographyProps={{
                            variant: "h6",
                            style: {
                              textTransform: "capitalize",
                            },
                          }}
                          secondary={Notify.dateTimeConvert(
                            data.appointment_date
                          )}
                        />
                      </ListItem>
                    ))}
                </List>
              ) : (
                Notify.noRecord()
              )
            ) : (
              Notify.loading()
            )}
          </Box>

          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[10, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
