import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const HemaCBCEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/hema-cbc/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {Boolean(parseInt(info.data.cbc)) && (
              <Box>
                <Box display="flex" my={2}>
                  <Box flexGrow={1}>
                    <Typography variant="body1" align="center">
                      <b> HEMATOLOGY CBC </b>
                    </Typography>
                  </Box>
                </Box>

                <Box hidden={!Boolean(parseInt(info.data.is_processed))}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          variant="standard"
                          label="WBC"
                          margin="dense"
                          fullWidth
                          name="wbc"
                          defaultValue={
                            info.data.wbc === null ? "" : info.data.wbc
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="LYM"
                          margin="dense"
                          fullWidth
                          name="lym"
                          defaultValue={
                            info.data.lym === null ? "" : info.data.lym
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="MID"
                          margin="dense"
                          fullWidth
                          name="mid"
                          defaultValue={
                            info.data.mid === null ? "" : info.data.mid
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="NEUT"
                          margin="dense"
                          fullWidth
                          name="neut"
                          defaultValue={
                            info.data.neut === null ? "" : info.data.neut
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="RBC"
                          margin="dense"
                          fullWidth
                          name="rbc"
                          defaultValue={
                            info.data.rbc === null ? "" : info.data.rbc
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          variant="standard"
                          label="HGB"
                          margin="dense"
                          fullWidth
                          name="hgb"
                          defaultValue={
                            info.data.rbc === null ? "" : info.data.rbc
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="HTC"
                          margin="dense"
                          fullWidth
                          name="hct"
                          defaultValue={
                            info.data.hct === null ? "" : info.data.hct
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="MCV"
                          margin="dense"
                          fullWidth
                          name="mcv"
                          defaultValue={
                            info.data.mcv === null ? "" : info.data.mcv
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="MCH"
                          margin="dense"
                          fullWidth
                          name="mch"
                          defaultValue={
                            info.data.mch === null ? "" : info.data.mch
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="MCHC"
                          margin="dense"
                          fullWidth
                          name="mchc"
                          defaultValue={
                            info.data.mchc === null ? "" : info.data.mchc
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          variant="standard"
                          label="RDW-SD"
                          margin="dense"
                          fullWidth
                          name="rdw_sd"
                          defaultValue={
                            info.data.rdw_sd === null ? "" : info.data.rdw_sd
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="RDW-CV"
                          margin="dense"
                          fullWidth
                          name="rdw_cv"
                          defaultValue={
                            info.data.rdw_cv === null ? "" : info.data.rdw_cv
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="MPV"
                          margin="dense"
                          fullWidth
                          name="mpv"
                          defaultValue={
                            info.data.mpv === null ? "" : info.data.mpv
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="PDW"
                          margin="dense"
                          fullWidth
                          name="pdw"
                          defaultValue={
                            info.data.pdw === null ? "" : info.data.pdw
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="PCT"
                          margin="dense"
                          fullWidth
                          name="pct"
                          defaultValue={
                            info.data.pct === null ? "" : info.data.pct
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Box mt={2}>
                    <TextField
                      variant="standard"
                      label="P-LCR"
                      margin="dense"
                      fullWidth
                      name="p_lcr"
                      defaultValue={
                        info.data.p_lcr === null ? "" : info.data.p_lcr
                      }
                    />
                  </Box>

                  <Box mt={2}>
                    <TextField
                      variant="standard"
                      label="Remarks"
                      margin="dense"
                      fullWidth
                      name="remarks"
                      defaultValue={
                        info.data.remarks === null ? "" : info.data.remarks
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {Boolean(parseInt(info.data.cbc_platelet)) && (
              <Box my={1}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="body1" align="center">
                      <b> HEMATOLOGY CBC WITH PLATELET </b>
                    </Typography>
                  </Box>
                </Box>

                <Box hidden={!Boolean(parseInt(info.data.is_processed))}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          variant="standard"
                          label="WBC"
                          margin="dense"
                          fullWidth
                          name="wbc"
                          defaultValue={
                            info.data.wbc === null ? "" : info.data.wbc
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="LYM"
                          margin="dense"
                          fullWidth
                          name="lym"
                          defaultValue={
                            info.data.lym === null ? "" : info.data.lym
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="MID"
                          margin="dense"
                          fullWidth
                          name="mid"
                          defaultValue={
                            info.data.mid === null ? "" : info.data.mid
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="NEUT"
                          margin="dense"
                          fullWidth
                          name="neut"
                          defaultValue={
                            info.data.neut === null ? "" : info.data.neut
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="RBC"
                          margin="dense"
                          fullWidth
                          name="rbc"
                          defaultValue={
                            info.data.rbc === null ? "" : info.data.rbc
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="HGB"
                          margin="dense"
                          fullWidth
                          name="hgb"
                          defaultValue={
                            info.data.hgb === null ? "" : info.data.hgb
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          variant="standard"
                          label="HTC"
                          margin="dense"
                          fullWidth
                          name="hct"
                          defaultValue={
                            info.data.hct === null ? "" : info.data.hct
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="MCV"
                          margin="dense"
                          fullWidth
                          name="mcv"
                          defaultValue={
                            info.data.mcv === null ? "" : info.data.mcv
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="MCH"
                          margin="dense"
                          fullWidth
                          name="mch"
                          defaultValue={
                            info.data.mch === null ? "" : info.data.mch
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="MCHC"
                          margin="dense"
                          fullWidth
                          name="mchc"
                          defaultValue={
                            info.data.mchc === null ? "" : info.data.mchc
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="RDW-SD"
                          margin="dense"
                          fullWidth
                          name="rdw_sd"
                          defaultValue={
                            info.data.rdw_sd === null ? "" : info.data.rdw_sd
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="RDW-CV"
                          margin="dense"
                          fullWidth
                          name="rdw_cv"
                          defaultValue={
                            info.data.rdw_cv === null ? "" : info.data.rdw_cv
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          variant="standard"
                          label="MPV"
                          margin="dense"
                          fullWidth
                          name="mpv"
                          defaultValue={
                            info.data.mpv === null ? "" : info.data.mpv
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="PDW"
                          margin="dense"
                          fullWidth
                          name="pdw"
                          defaultValue={
                            info.data.pdw === null ? "" : info.data.pdw
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="PCT"
                          margin="dense"
                          fullWidth
                          name="pct"
                          defaultValue={
                            info.data.pct === null ? "" : info.data.pct
                          }
                        />
                      </Box>
                      <Box>
                        <TextField
                          variant="standard"
                          label="PLT"
                          margin="dense"
                          fullWidth
                          name="plt"
                          defaultValue={
                            info.data.plt === null ? "" : info.data.plt
                          }
                        />
                      </Box>

                      <Box>
                        <TextField
                          variant="standard"
                          label="P-LCR"
                          margin="dense"
                          fullWidth
                          name="p_lcr"
                          defaultValue={
                            info.data.p_lcr === null ? "" : info.data.p_lcr
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Box mt={2}>
                    <TextField
                      variant="standard"
                      label="Remarks"
                      margin="dense"
                      fullWidth
                      name="remarks"
                      defaultValue={
                        info.data.remarks === null ? "" : info.data.remarks
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}

            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default HemaCBCEditForm;
