import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Box,
  TextField,
  InputAdornment,
  TablePagination,
  useTheme,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const ExpiryMoniroting = () => {
  const { users } = useContext(UsersData);
  const [list, setList] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();

  const getMonitoringList = useCallback(async () => {
    let response = await axios.get(
      "laboratory/monitoring/product/in-productbybatches",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
        },
      }
    );

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [mounted, users]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const searchable = list.data.filter((data) => {
    return (
      data.item.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1 ||
      data.description.toLowerCase().indexOf(search.trim().toLowerCase()) !==
        -1 ||
      data.supplier.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1 ||
      data.batch_number.toLowerCase().indexOf(search.trim().toLowerCase()) !==
        -1
    );
  });

  const checkExpirationDate = (expiration) => {
    var currdate = new Date().toLocaleDateString();
    var xxx = moment(new Date(currdate)).format("YYYY-MM-DD");
    var start = moment(new Date(xxx));
    var end = moment(expiration);
    var expired = end.diff(start, "months");

    return expired;
  };

  useEffect(() => {
    getMonitoringList();
  }, [getMonitoringList]);

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6">Laboratory Order List</Typography>
            </Box>

            <Box>
              <TextField
                label="Search Brand, Description, Supplier, Batch"
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        }
      />

      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Date </TableCell>
                <TableCell> Brand </TableCell>
                <TableCell> Description </TableCell>
                <TableCell> Supplier </TableCell>
                <TableCell> Batch Number </TableCell>
                <TableCell> Mfg. Date </TableCell>
                <TableCell> Exp. Date </TableCell>
                <TableCell> Type </TableCell>
                <TableCell> Qty </TableCell>
                <TableCell> Expiration </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list.ready ? (
                searchable.length > 0 ? (
                  searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            checkExpirationDate(data.expiration_date) >= 6
                              ? ""
                              : checkExpirationDate(data.expiration_date) <= 3
                              ? theme.palette.error.light
                              : theme.palette.warning.light,
                        }}
                      >
                        <TableCell style={{ maxWidth: 100 }}>
                          {Notify.dateTimeConvert(data.created_at)}
                        </TableCell>
                        <TableCell style={{ maxWidth: 100 }}>
                          {data.item}
                        </TableCell>
                        <TableCell style={{ maxWidth: 150 }}>
                          {data.description}
                        </TableCell>
                        <TableCell> {data.supplier} </TableCell>
                        <TableCell> {data.batch_number} </TableCell>
                        <TableCell> {data.mfg_date} </TableCell>
                        <TableCell>
                          {Notify.createdAt(data.expiration_date)}
                        </TableCell>
                        <TableCell> {data.type} </TableCell>
                        <TableCell align="right">
                          {data._batch_qty_available}
                        </TableCell>
                        <TableCell align="right">
                          {checkExpirationDate(data.expiration_date)} months
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={10}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={10}>
                    <Typography color="primary">
                      Loading, Please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[10, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ExpiryMoniroting;
