import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, BranchListContext, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserInjured,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import VanEndorsementDashboard from "src/van-endorsement/VanEndorsementDashboard";
import Account from "src/van-endorsement/account/Account";
import VanPatientList from "src/van-endorsement/patient/VanPatientList";

const RouteVanEndorsement = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const [branches, setBranches] = useState([]);
  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("van/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const getBranchesByMainMngtId = async () => {
    var params = { main_management_id: userContext.users.main_mgmt_id };
    try {
      let response = await axios.get("accounting/management/get-branches", {
        params,
      });
      setBranches(response.data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faHome} color="white" style={{ fontSize: 23 }} />
      ),
      path: "/bmcdc/app/van-endorsement",
      subitem: [],
    },
    {
      name: "Patient",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faUserInjured}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/bmcdc/app/van-endorsement/patient",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: (
        <FontAwesomeIcon
          icon={faUserLock}
          color="white"
          style={{ fontSize: 23 }}
        />
      ),
      path: "/bmcdc/app/van-endorsement/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  const handleRenderBranches = () => {
    getBranchesByMainMngtId();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getBranchesByMainMngtId();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BranchListContext.Provider
          value={{
            branches: branches,
            renderBranchList: handleRenderBranches,
          }}
        >
          <BrowserRouter>
            <Sidebar
              notification={{
                enable: true,
                owner: "van endt",
              }}
              header={sidebarHeader}
              routes={sidebarRoute}
              module={"endorsement"}
              render={() => getSidebarHeaderInfo()}
            />

            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/bmcdc/app/van-endorsement" />}
              />

              <Route
                exact
                path="/bmcdc"
                component={() => <Redirect to="/bmcdc/app/van-endorsement" />}
              />

              <Route
                exact
                path="/bmcdc/app"
                component={() => <Redirect to="/bmcdc/app/van-endorsement" />}
              />

              <Route
                exact
                path="/bmcdc/app/van-endorsement"
                component={VanEndorsementDashboard}
              />

              <Route
                exact
                path="/bmcdc/app/van-endorsement/patient"
                component={VanPatientList}
              />

              <Route
                exact
                path="/bmcdc/app/van-endorsement/account"
                component={Account}
              />

              <Route exact path="/bmcdc/app/logout" component={Logout} />

              <Route render={() => <PageNotFound title="Page not found" />} />
            </Switch>
          </BrowserRouter>
        </BranchListContext.Provider>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteVanEndorsement;
