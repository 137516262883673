import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Paper,
  // TextField,
  Button,
  // Dialog,
  // DialogContent,
  // DialogTitle,
  TableContainer,
  Table,
  // TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  // DialogActions,
  Typography,
  // Badge,
  // Divider,
  Grid,
  useTheme,
} from "@material-ui/core";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import Select from "react-select";
// import SelectStyle from "src/utils/SelectStyle";
// import { Trash } from "react-feather";
import Notify from "src/notification/Notify";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

const CashierCreatePackageOrder = ({
  patient_id,
  getPatientBillingDetails,
}) => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [packageList, setPackageList] = useState([]);
  // const [selectedPackage, setSelectedPackages] = useState(null);
  // const [unpaidDialog, setUnpaidDialog] = useState(false);
  // const selectStyle = SelectStyle();
  const [unpaidList, setUnpaidList] = useState({ data: [], ready: false });
  const theme = useTheme();

  const getPackagesList = React.useCallback(async () => {
    let response = await axios.get("cashier/get/package-list", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setPackageList(response.data);
    }
  }, [users, mounted]);

  const getUnpaidBillsByPatientId = React.useCallback(async () => {
    let response = await axios.get("cashier/get/unpaid-bills", {
      params: {
        management_id: users.management_id,
        patient_id: patient_id,
      },
    });

    if (mounted.current) {
      setUnpaidList({ data: response.data, ready: true });
    }
  }, [patient_id, mounted, users]);

  // const handleSelectedPackageAdd = async () => {
  //   if (selectedPackage === null) {
  //     Notify.fieldInvalid("package is required.");
  //     return;
  //   }

  //   var formdata = new FormData();
  //   formdata.set("patient_id", patient_id);
  //   formdata.set("management_id", users.management_id);
  //   formdata.set("billname", selectedPackage.package_name);
  //   formdata.set("package_id", selectedPackage.package_id);
  //   formdata.set("rate", selectedPackage.order_amount);

  //   let response = await axios.post("cashier/add/package-savetemp", formdata);

  //   if (response.data.message === "success") {
  //     getUnpaidBillsByPatientId();
  //     setSelectedPackages(null);
  //     Notify.successRequest("order save");
  //   }
  // };

  const handleDeleteOrder = async (data) => {
    var formdata = new FormData();
    formdata.set("id", data.id);

    let response = await axios.post("cashier/delete/unpaid-package", formdata);

    if (response.data.message === "success") {
      Notify.successRequest("order save");
      getUnpaidBillsByPatientId();
    }
  };

  const handleSaveOrder = async () => {
    var formdata = new FormData();
    formdata.set("patient_id", patient_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    let response = await axios.post("cashier/save/processorder", formdata);

    if (response.data.message === "success") {
      getUnpaidBillsByPatientId();
      getPatientBillingDetails();
      // setUnpaidDialog(false);
      Notify.successRequest("order save");
    }
  };

  const handleSelectOrder = async (packageinfo) => {
    var formdata = new FormData();
    formdata.set("patient_id", patient_id);
    formdata.set("management_id", users.management_id);
    formdata.set("billname", packageinfo.package_name);
    formdata.set("package_id", packageinfo.package_id);
    formdata.set("rate", packageinfo.order_amount);

    let response = await axios.post("cashier/add/package-savetemp", formdata);

    if (response.data.message === "success") {
      getUnpaidBillsByPatientId();
      // setSelectedPackages(null);
      Notify.successRequest("order save");
    }
  };

  useEffect(() => {
    getPackagesList();
    getUnpaidBillsByPatientId();
  }, [getPackagesList, getUnpaidBillsByPatientId]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper component={Box} elevation={2} p={2} mb={2}>
            <Box mb={2}>
              <Typography variant="h6" color="textSecondary">
                Select Order
              </Typography>
            </Box>

            <Box>
              <Box>
                <Select
                  options={packageList}
                  onChange={(data) => handleSelectOrder(data)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                      color: theme.palette.type === "dark" ? "#fff" : "#fff",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Paper component={Box} elevation={2} p={2} mb={2}>
            <Box mb={2}>
              <Typography variant="h6" color="textSecondary">
                Unsave Package Order
              </Typography>
            </Box>
            <Box mb={1}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {unpaidList.ready ? (
                      unpaidList.data.length > 0 ? (
                        unpaidList.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ textTransform: "capitalize" }}>
                              <b style={{ fontSize: 16 }}>
                                {data.package_name}
                              </b>
                              <br />
                              Package
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ fontWeight: "bolder" }}
                            >
                              &#8369;{" "}
                              {Notify.convertToNumber(data.package_amount)}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  handleDeleteOrder(data);
                                }}
                              >
                                <DeleteSweepIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>{Notify.noRecord()}</TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>{Notify.loading()}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box display={"flex"}>
              <Box flexGrow={1} />
              <Box>
                <Button
                  hidden={unpaidList.data.length === 0}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSaveOrder()}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* <Paper>
        <Box p={2}>
          <Box mb={2}>
            <Typography variant="h6" color="textSecondary">
              Create Package Order
            </Typography>
          </Box>
          <Box mb={2}>
            <Select
              options={packageList}
              onChange={(e) => {
                setSelectedPackages(e);
              }}
              styles={selectStyle}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Order Rate"
              variant="outlined"
              fullWidth
              disabled
              value={
                selectedPackage === null ? "0" : selectedPackage.order_amount
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          <Box display="flex">
            <Box mr={2}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => handleSelectedPackageAdd()}
              >
                Add
              </Button>
            </Box>

            <Box>
              <Button size="large" variant="contained" color="default">
                No
              </Button>
            </Box>

            <Box ml={2}>
              <Badge badgeContent={unpaidList.length} color="primary">
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={() => setUnpaidDialog(true)}
                >
                  Unsave order
                </Button>
              </Badge>
            </Box>
          </Box>
        </Box>

        {unpaidDialog && (
          <Dialog
            maxWidth={"xs"}
            fullWidth
            open={unpaidDialog}
            onClose={() => setUnpaidDialog(false)}
          >
            <DialogTitle>Order for confirmation</DialogTitle>
            <Divider />
            <DialogContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> Order </TableCell>
                      <TableCell> Rate </TableCell>
                      <TableCell> Action </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {unpaidList.length > 0 ? (
                      unpaidList.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell> {data.package_name} </TableCell>
                          <TableCell> {data.package_amount} </TableCell>
                          <TableCell>
                            <IconButton
                              color="secondary"
                              onClick={() => handleDeleteOrder(data)}
                            >
                              <Trash />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography color="secondary">
                            No unpaid order.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                color="default"
                variant="contained"
                onClick={() => setUnpaidDialog(false)}
              >
                close
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={() => handleSaveOrder(false)}
              >
                Save Order
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper> */}
    </>
  );
};

export default CashierCreatePackageOrder;
