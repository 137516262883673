import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Box,
  TablePagination,
} from "@material-ui/core";
import React, { useState } from "react";
import Notify from "src/notification/Notify";

const List = ({ inventory, search }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const searchable = inventory.data.filter((data) => {
    return (
      data.item.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1 ||
      data.description.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    );
  });

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> Brand </TableCell>
              <TableCell> Description </TableCell>
              <TableCell> Supplier </TableCell>
              <TableCell> Msrp </TableCell>
              <TableCell> IN QTY </TableCell>
              <TableCell> OUT QTY </TableCell>
              <TableCell> REM. QTY </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory.ready ? (
              searchable.length > 0 ? (
                searchable
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow key={index}>
                      <TableCell> {data.item} </TableCell>
                      <TableCell style={{ maxWidth: 150 }}>
                        {data.description}
                      </TableCell>
                      <TableCell> {data.supplier} </TableCell>
                      <TableCell align="right">
                        {Notify.convertToNumber(data.msrp)}
                      </TableCell>
                      <TableCell align="right">{data._total_qty_in}</TableCell>
                      <TableCell align="right">{data._total_qty_out}</TableCell>
                      <TableCell align="right">
                        {data._total_qty_in - data._total_qty_out}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography color="secondary">No record found.</Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography color="primary">
                    loading, please wait...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={searchable.length}
          rowsPerPageOptions={[10, 50, 100]}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="List"
        />
      </TableContainer>
    </Box>
  );
};

export default List;
