
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import Notify from '../../notification/Notify'

import { 
    Collapse,
    Grid,
    TextField,
    Box, 
    Typography, Button, Paper
} from '@material-ui/core'
 
import Axios from 'axios'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; 

const HealthFamilyHistory = ({ patient_id, connection }) =>{   

    const [histories, setHistories] = useState([])
    const [historiesReady, setHistoriesReady] = useState(false) 
    const [showall, setShowAll] = useState(false) 

    const getHistories = useCallback(() => {
        var formdata = new FormData();   
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', connection);    
        
        Axios.post('doctor/patient/information/personal/family-historybyId', formdata )
        .then((response) => { 
            const data = response.data;   
            setHistories(data)
            setHistoriesReady(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }, [patient_id, connection]);

    useEffect( () =>{
        getHistories() 
    }, [getHistories]) 

    return(
        <Fragment>
            <Collapse 
                in={ showall } 
                timeout={600}
                collapsedHeight={410}
            >   
                <Box
                    p={2}
                    component={Paper}
                    elevation={2} 
                >
                    {
                        historiesReady ? 
                            histories.length > 0 ?
                                histories.map((data, index) => (
                                    <Box key={index} my={1} className="gtc-textfield-noborder">
                                        {/* fathers information */}
                                            <Box mb={2} hidden={ data.category === 'Father' ? false : true }>
                                                <Box display="flex">
                                                    <Box flexGrow={1}>
                                                        <Typography color="primary"> Father Information </Typography>
                                                    </Box>
                                                    
                                                </Box>
                                                <Box mb={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Fullname"
                                                                value={ data.name }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                multiline
                                                                label="Address"
                                                                value={ data.address }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box mb={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Birthday"
                                                                value={ data.birthday }
                                                                margin="dense"
                                                                variant="outlined"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{ 
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Occupation"
                                                                value={ data.occupation }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box mb={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Health Status"
                                                                value={ data.health_status }
                                                                margin="dense"
                                                                variant="outlined"  
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>  
                                                            <TextField 
                                                                fullWidth
                                                                label="Is alive"
                                                                value={ data.is_deceased === 0 ? 'Alive' : 'Deceased' }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box
                                                    mb={1}
                                                    hidden={ data.is_deceased === 0 ? true : false }
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Deceased Date"
                                                                value={ data.is_deceased_date === null ? '' : data.is_deceased_date }
                                                                margin="dense"
                                                                variant="outlined"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}  
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>  
                                                            <TextField 
                                                                fullWidth
                                                                multiline
                                                                label="Deceased Reason"
                                                                value={ data.is_deceased_reason === null ? '' : data.is_deceased_reason }
                                                                margin="dense"
                                                                variant="outlined" 
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        {/* father s information end */}

                                        {/* mothers information  */}
                                            <Box mb={2} hidden={ data.category === 'Mother' ? false : true }>
                                                <Box display="flex">
                                                    <Box flexGrow={1}>
                                                        <Typography color="primary"> Mother Information </Typography>
                                                    </Box>
                                                    
                                                </Box> 
                                                <Box mb={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Fullname"
                                                                value={ data.name }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                multiline
                                                                label="Address"
                                                                value={ data.address }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box mb={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Birthday"
                                                                value={ data.birthday }
                                                                margin="dense"
                                                                variant="outlined"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{ 
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Occupation"
                                                                value={ data.occupation }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box mb={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Health Status"
                                                                value={ data.health_status }
                                                                margin="dense"
                                                                variant="outlined"  
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>  
                                                            <TextField 
                                                                fullWidth
                                                                label="Is alive"
                                                                value={ data.is_deceased === 0 ? 'Alive' : 'Deceased' }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box
                                                    mb={1}
                                                    hidden={ data.is_deceased === 0 ? true : false }
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Deceased Date"
                                                                value={ data.is_deceased_date === null ? '' : data.is_deceased_date}
                                                                margin="dense"
                                                                variant="outlined"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}  
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>  
                                                            <TextField 
                                                                fullWidth
                                                                multiline
                                                                label="Deceased Reason"
                                                                value={ data.is_deceased_reason === null ? '' : data.is_deceased_reason}
                                                                margin="dense"
                                                                variant="outlined" 
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        {/* mothers information end */}


                                        {/* childrens information  */}
                                            <Box mb={2} hidden={ data.category === 'children' ? false : true }> 
                                                <Box display="flex">
                                                    <Box flexGrow={1}>
                                                        <Typography color="primary"> Children {(index - 2) + 1 } Information </Typography>
                                                    </Box>
                                                    
                                                </Box> 
                                                <Box mb={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Fullname"
                                                                value={ data.name }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                multiline
                                                                label="Address"
                                                                value={ data.address }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box mb={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Birthday"
                                                                value={ data.birthday }
                                                                margin="dense"
                                                                variant="outlined"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{ 
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Occupation"
                                                                value={ data.occupation }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box mb={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Health Status"
                                                                value={ data.health_status }
                                                                margin="dense"
                                                                variant="outlined"  
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>  
                                                            <TextField 
                                                                fullWidth
                                                                label="Is alive"
                                                                value={ data.is_deceased === 0 ? 'Alive' : 'Deceased' }
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box
                                                    mb={1}
                                                    hidden={ data.is_deceased === 0 ? true : false }
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}> 
                                                            <TextField 
                                                                fullWidth
                                                                label="Deceased Date"
                                                                value={ data.is_deceased_date === null ? '' : data.is_deceased_date }
                                                                margin="dense"
                                                                variant="outlined"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}  
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>  
                                                            <TextField 
                                                                fullWidth
                                                                multiline
                                                                label="Deceased Reason"
                                                                value={ data.is_deceased_reason === null ? '' : data.is_deceased_reason }
                                                                margin="dense"
                                                                variant="outlined" 
                                                                InputProps={{
                                                                    inputProps:{
                                                                        readOnly: true
                                                                    }
                                                                }}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        {/* childrens information end */}
                                    </Box>
                                ))
                            : Notify.noRecord()
                        : Notify.loading()
                    }
                </Box> 
            </Collapse>     
            <Box 
                hidden={ showall ? true : histories.length > 0 ? false : true  } 
                my={2} 
                display="flex" 
                justifyContent="flex-end"
            >
                <Box> 
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        startIcon={ <ExpandMoreIcon /> }
                        onClick={ () => setShowAll(true) }
                    >
                        Show all
                    </Button>
                </Box>  
            </Box>
        </Fragment>
    )
}

export default HealthFamilyHistory; 