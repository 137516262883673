import React, { useContext, useEffect, useState } from "react";
import {
  TableContainer,
  TableCell,
  TableBody,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { useCallback } from "react";
import { Fragment } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const PayrollEmployeesInfo = ({ employee_id, type }) => {
  const { users } = useContext(UsersData);
  const [employeeinfo, setemployeeinfo] = useState({ data: [], ready: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const GetEmployeePayroll = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      employee_id,
      type,
    };
    try {
      const request = await axios.get("hmis/get/employee-info", { params });
      const data = request.data;
      setemployeeinfo({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users, employee_id, type]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    GetEmployeePayroll();
  }, [GetEmployeePayroll]);

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong> Time In </strong>
              </TableCell>
              <TableCell align="center">
                <strong> Time Out </strong>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {employeeinfo.ready &&
              (employeeinfo.data.length > 0 ? (
                employeeinfo.data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {Notify.dateTimeConvert(data.timein)}
                        </TableCell>
                        <TableCell align="center">
                          {data.timeout !== null
                            ? Notify.dateTimeConvert(data.timeout)
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <Typography color="secondary" variant="subtitle2">
                      No record found.
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component={"div"}
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={employeeinfo.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
};

export default PayrollEmployeesInfo;
