import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  Table,
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  TablePagination,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Edit, Search } from "@material-ui/icons";

const DoctorListShare = () => {
  const { users } = useContext(UsersData);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [doctorList, setDoctorList] = useState({
    data: [],
    ready: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [edit, setEdit] = useState({
    data: null,
    open: false,
  });

  const getDoctorList = async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const request = await axios.get("accounting/get/doctor-list", {
        params,
      });
      setDoctorList({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
      // Notify.requestError(error)
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleEditDoctorShare = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);

    var error = [];

    if (
      formdata.get("share_rate").trim() === "" ||
      formdata.get("share_rate").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("doctor's share");
    }

    if (
      formdata.get("password").trim() === "" ||
      formdata.get("password").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/update/doctor-share", formdata)
        .then((response) => {
          const data = response.data.message;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            Notify.successRequest("update doctor share");
            getDoctorList();
            setEdit({ data: null, open: false });
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    getDoctorList();
    // eslint-disable-next-line
  }, []);

  const searchable = doctorList.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Card>
          <CardHeader
            title={
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h5">Doctor's Share</Typography>
                </Box>
                <Box>
                  <TextField
                    label="Search doctor"
                    variant="outlined"
                    margin="dense"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            }
          />

          <CardMedia>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong> Doctor's Name </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong> Doctor's Share </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong> Company's Share </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong> Action </strong>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {doctorList.ready ? (
                    doctorList.data.length > 0 ? (
                      searchable.map((data, index) => (
                        <TableRow key={index} hover>
                          <TableCell> {data.name} </TableCell>
                          <TableCell align="center">
                            {data.share_rate !== null
                              ? parseInt(data.share_rate)
                              : 0}
                            %
                          </TableCell>
                          <TableCell align="center">
                            {data.share_rate !== null
                              ? 100 - parseInt(data.share_rate)
                              : 100}
                            %
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip arrow title="Edit Doctor's Service">
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  setEdit({
                                    data: data,
                                    open: true,
                                  })
                                }
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          <Typography variant="subtitle2" color="secondary">
                            No doctor services added.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        <Typography variant="subtitle2" color="primary">
                          Please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={searchable.length}
              rowsPerPageOptions={[10, 50, 100]}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="List"
            />
          </CardMedia>
        </Card>
      </Grid>

      {edit.data && edit.open && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={edit.open}
          onClose={() => setEdit({ data: null, open: false })}
        >
          <DialogTitle> Edit Doctor's Share </DialogTitle>
          <form onSubmit={handleEditDoctorShare}>
            <DialogContent dividers>
              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="doctors_id"
                  label="Doctor Id"
                  variant="outlined"
                  hidden
                  defaultValue={edit.data.doctors_id}
                />

                <TextField
                  fullWidth
                  required
                  name="share_rate"
                  label="Doctor's Share"
                  variant="outlined"
                  defaultValue={
                    edit.data.share_rate !== null ? edit.data.share_rate : ""
                  }
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 100,
                      step: 1,
                    },
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEdit({ data: null, open: false })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                startIcon={
                  isSubmitting && <CircularProgress size={20} color="inherit" />
                }
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Grid>
  );
};

export default DoctorListShare;
