import React, { Fragment, useContext, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Container from "src/layout/Container";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Avatar,
  useTheme,
  Typography,
} from "@material-ui/core";
import { PatientsUnRead } from "src/ContextAPI";
import FormExam from "./FormExam";
import Notify from "src/notification/Notify";

const PhysicalExam = () => {
  const { physicalExam } = useContext(PatientsUnRead);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const theme = useTheme();

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Physical Exam",
          items: [
            {
              name: "Dashboard",
              path: "/bmcdc/app/doctor",
            },
          ],
        }}
        title={"Physical Examination"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardHeader title="Queueing Patients" />
              <CardContent>
                <List>
                  {physicalExam.queueing.length > 0 ? (
                    physicalExam.queueing.map((data, index) => (
                      <ListItem
                        key={index}
                        button
                        onClick={() => setSelectedPatient(data.patient_id)}
                      >
                        <ListItemIcon>
                          <Avatar
                            style={{
                              backgroundColor: theme.palette.primary.main,
                              color: "#fff",
                            }}
                          >
                            {index++ + 1}
                          </Avatar>
                        </ListItemIcon>
                        <ListItemText
                          primary={`${data.lastname}, ${data.firstname}`}
                          primaryTypographyProps={{
                            style: {
                              textTransform: "capitalize",
                            },
                          }}
                          secondary={Notify.dateTimeConvert(
                            data.transaction_date
                          )}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <>
                      <Typography color="secondary" align="center">
                        No order found.
                      </Typography>
                    </>
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            {selectedPatient !== null && (
              <FormExam
                patient_id={selectedPatient}
                removeSelectedPatient={() => setSelectedPatient(null)}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default PhysicalExam;
