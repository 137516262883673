import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import HemathologyRef from "src/laboratory/references/Ref_Hemathology";
import Label from "src/utils/Label";
import { BarChart2 } from "react-feather";
import { useHistory } from "react-router";
import { UsersData } from "src/ContextAPI";
import Footer from "src/laboratory/print/Footer";

const Hemathology = ({ order_id, patient_id, formheader }) => {
  const { users } = React.useContext(UsersData);
  const history = useHistory();
  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_hematology");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_hematology table error", error);
    }
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box my={2} p={2}>
            <Box display="flex" mb={2}>
              <Box flexGrow={1}>
                <Typography variant="h6" align="center">
                  Hemathology Order
                </Typography>
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="text"
                  startIcon={<BarChart2 />}
                  onClick={() =>
                    history.push(
                      `/bmcdc/app/doctor/patient/laboratory/hemathology/${patient_id}`
                    )
                  }
                  className={`d-print-none`}
                >
                  Graph
                </Button>
              </Box>
            </Box>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell> ORDER </TableCell>
                    <TableCell align="center"> RESULT </TableCell>
                    <TableCell align="center"> REFERENCE VALUES </TableCell>
                    <TableCell align="center"> REMARKS </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {details.map((data, index) => (
                    <Fragment key={index}>
                      {data.hemoglobin && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              hemoglobin
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.hemoglobin)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.hemoglobin()}
                          </TableCell>
                          <TableCell align="center">
                            {data.hemoglobin_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.hematocrit && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              hematocrit
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.hematocrit)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.hematocrit()}
                          </TableCell>
                          <TableCell align="center">
                            {data.hematocrit_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.rbc && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              RBC
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.rbc)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.rbc()}
                          </TableCell>
                          <TableCell align="center">
                            {data.rbc_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.wbc && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              wbc
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.wbc)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.wbc()}
                          </TableCell>
                          <TableCell align="center">
                            {data.wbc_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.platelet_count && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              Platelet count
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.platelet_count)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.plateletCount()}
                          </TableCell>
                          <TableCell align="center">
                            {data.platelet_count_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.differential_count && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              differential count
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.differential_count)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center"> </TableCell>
                          <TableCell align="center">
                            {data.differential_count_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.neutrophil && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              neutrophil
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.neutrophil)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.neutrophil()}
                          </TableCell>
                          <TableCell align="center">
                            {data.neutrophil_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.lymphocyte && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              lymphocyte
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.lymphocyte)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.lymphocyte()}
                          </TableCell>
                          <TableCell align="center">
                            {data.lymphocyte_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.monocyte && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              monocyte
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.monocyte)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.monocyte()}
                          </TableCell>
                          <TableCell align="center">
                            {data.monocyte_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.eosinophil && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              eosinophil
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.eosinophil)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.eosinophil()}
                          </TableCell>
                          <TableCell align="center">
                            {data.eosinophil_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.basophil && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              basophil
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.basophil)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.basophil()}
                          </TableCell>
                          <TableCell align="center">
                            {data.basophil_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.bands && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              bands
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.bands)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.bands()}
                          </TableCell>
                          <TableCell align="center">
                            {data.bands_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.abo_blood_type_and_rh_type && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              ABO blood type / rh type
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.abo_blood_type_and_rh_type)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {data.abo_blood_type_and_rh_type_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.bleeding_time && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              bleeding time
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.bleeding_time)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.bleedingTime()}
                          </TableCell>
                          <TableCell align="center">
                            {data.bleeding_time_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.clotting_time && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              clotting time
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.clotting_time)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {HemathologyRef.clottingTime()}
                          </TableCell>
                          <TableCell align="center">
                            {data.clotting_time_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.mcv && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              mcv
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.mcv)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"> </TableCell>
                          <TableCell align="center">
                            {data.mcv_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.mch && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              mch
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.mch)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"> </TableCell>
                          <TableCell align="center">
                            {data.mch_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.mchc && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              mchc
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.mchc)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"> </TableCell>
                          <TableCell align="center">
                            {data.mchc_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.rdw && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              rdw
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.rdw)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"> </TableCell>
                          <TableCell align="center">
                            {data.rdw_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.mpv && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              mpv
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.mpv)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"> </TableCell>
                          <TableCell align="center">
                            {data.mpv_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.pdw && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              pdw
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.pdw)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"> </TableCell>
                          <TableCell align="center">
                            {data.pdw_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.pct && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              pct
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.pct)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"> </TableCell>
                          <TableCell align="center">
                            {data.pct_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.blood_typing_with_rh && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              Blood Typing w/ RH
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.blood_typing_with_rh)}
                            </Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {data.blood_typing_with_rh_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.ct_bt && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              CT/BT
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.ct_bt)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {data.ct_bt_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.esr && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              ESR
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.esr)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {data.esr_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.ferritin && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              Ferritin
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.ferritin)}
                            </Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {data.ferritin_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.aptt && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              APTT
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.aptt)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {data.aptt_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.peripheral_smear && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              Peripheral Smear
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.peripheral_smear)}
                            </Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {data.peripheral_smear_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.protime && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              Protime
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.protime)}</Typography>
                          </TableCell>
                          {/* references not set */}
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {data.protime_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Footer formheader={formheader} />
        </>
      )}
    </>
  );
};

export default Hemathology;
