import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faFileMedical,
  faHome,
  faListOl,
  faTasks,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import NurseDashboard from "src/nurse/NurseDashboard";
import LeaveForm from "src/nurse/leave/LeaveForm";
import Account from "src/nurse/account/Account";
import PatientQueu from "src/nurse/queue/PatientQueu";
import IsMountedRef from "src/utils/IsMountedRef";
import { Badge } from "@material-ui/core";
import FollowUpCheck from "src/nurse/queue/followupcheck";
import MedicalCertificate from "src/nurse/medical-certificate";

const RouteNurse = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const mounted = IsMountedRef();
  const userContext = useContext(UsersData);
  const [patient, setPatients] = useState([]);
  const [newPatientCount, setNewPatientCount] = useState(0);
  var interval = null;

  const getSidebarHeaderInfo = React.useCallback(async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("nurse/sidebar/header-infomartion", {
        params,
      });
      if (mounted.current) {
        setSidebarHeader(response.data);
      }
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  }, [userContext, mounted]);

  const getPatientsOnQueue = React.useCallback(async () => {
    let response = await axios.get("nurse/patients/nurse-queue", {
      params: {
        management_id: userContext.users.management_id,
        user_id: userContext.users.user_id,
      },
    });

    const data = response.data;
    if (mounted.current) {
      setPatients(response.data);
      if (data.length > 0) {
        setNewPatientCount(data.length);
      } else {
        setNewPatientCount(0);
      }
    }
  }, [mounted, userContext]);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/bmcdc/app/nurse",
      subitem: [],
    },
    {
      name: "Patient Queue",
      show: true,
      icon: (
        <Badge color="error" badgeContent={newPatientCount}>
          <FontAwesomeIcon icon={faListOl} color="white" size={"2x"} />
        </Badge>
      ),
      path: "/bmcdc/app/nurse/patient-queue",
      subitem: [],
    },

    {
      name: "Medical Certificate",
      show: true,
      icon: <FontAwesomeIcon icon={faFileMedical} color="white" size={"2x"} />,
      path: "/bmcdc/app/nurse/medical-certificate",
      subitem: [],
    },

    {
      name: "Follow-up Check",
      show: true,
      icon: <FontAwesomeIcon icon={faTasks} color="white" size={"2x"} />,
      path: "/bmcdc/app/nurse/follow-up-check",
      subitem: [],
    },

    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/bmcdc/app/nurse/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/nurse/account",
      subitem: [],
    },
  ];

  const initializeInterval = () => {
    interval = setInterval(() => {
      getPatientsOnQueue();
    }, 20000);
  };

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getPatientsOnQueue();
    //eslint-disable-next-line
  }, [getSidebarHeaderInfo, getPatientsOnQueue]);

  useEffect(() => {
    initializeInterval();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
          queue: {
            patient: patient,
            getPatientsOnQueue: () => getPatientsOnQueue(),
          },
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "nurse",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"nurse"}
          />
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/bmcdc/app/nurse" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/bmcdc/app/nurse" />}
            />

            <Route
              exact
              path="/bmcdc/app"
              component={() => <Redirect to="/bmcdc/app/nurse" />}
            />

            <Route exact path="/bmcdc/app/nurse" component={NurseDashboard} />

            <Route
              exact
              path="/bmcdc/app/nurse/patient-queue"
              component={PatientQueu}
            />

            <Route
              exact
              path="/bmcdc/app/nurse/medical-certificate"
              component={MedicalCertificate}
            />

            <Route
              exact
              path="/bmcdc/app/nurse/follow-up-check"
              component={FollowUpCheck}
            />

            <Route
              exact
              path="/bmcdc/app/nurse/leave-application"
              component={LeaveForm}
            />

            <Route exact path="/bmcdc/app/nurse/account" component={Account} />

            <Route exact path="/bmcdc/app/logout" component={Logout} />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteNurse;
