import React, { useState, useContext } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Trash } from "react-feather";
import axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";

const TempList = ({ products, getTempDrProducts }) => {
  const { users } = useContext(UsersData);
  const [remove, setRemove] = useState({ data: "", dialog: false });
  const [process, setProcess] = useState({
    data: "",
    dialog: false,
    processing: false,
  });
  const [values, setValues] = useState("");

  const handleCloseDialog = () => {
    setRemove({ data: "", dialog: false });
    setProcess({ data: "", dialog: false });
  };

  const handleRemove = async () => {
    var formdata = new FormData();
    formdata.set("remove_id", remove.data.sapt_id);

    let response = await axios.post(
      "stockroom/product/dr/temp-remove",
      formdata
    );

    const data = response.data;
    if (data.message === "success") {
      Notify.successRequest("remove");
      getTempDrProducts();
      handleCloseDialog();
    }

    if (data.message === "success") {
      Notify.successRequest("remove");
      getTempDrProducts();
      handleCloseDialog();
    }

    if (data.message === "success") {
      Notify.successRequest("remove");
      getTempDrProducts();
      handleCloseDialog();
    }
  };

  const onInputchange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleProcessInProduct = async () => {
    var error = [];

    if (Object.keys(values).length === 0) {
      error = "error";
      Notify.fieldRequired("all");
    } else {
      if (values.dr_number) {
        if (values.dr_number.trim() === "") {
          error = "error";
          Notify.fieldInvalid("dr number");
        }
      } else {
        error = "error";
        Notify.fieldInvalid("dr number");
      }

      if (values.dr_date) {
        if (values.dr_date.trim() === "") {
          error = "error";
          Notify.fieldInvalid("dr date");
        }
      } else {
        error = "error";
        Notify.fieldInvalid("dr date");
      }

      if (values.password) {
        if (values.password.trim() === "") {
          error = "error";
          Notify.fieldInvalid("password");
        }
      } else {
        error = "error";
        Notify.fieldInvalid("password");
      }
    }

    if (error.length > 0) {
      console.log("form has error.");
    } else {
      var formdata = new FormData();
      formdata.set("user_id", users.user_id);
      formdata.set("username", users.username);
      formdata.set("management_id", users.management_id);
      formdata.set("dr_number", values.dr_number);
      formdata.set("dr_date", values.dr_date);
      formdata.set("password", values.password);

      setProcess({ ...process, processing: true });

      let response = await axios.post(
        "stockroom/product/dr/product-in",
        formdata
      );

      const data = response.data;

      if (data.message === "pass-invalid") {
        Notify.fieldInvalid("password");
      }

      if (data.message === "success") {
        Notify.successRequest("product in");
        getTempDrProducts();
        setValues("");
      }

      setProcess({
        ...process,
        dialog: data.message === "success" ? false : true,
        processing: false,
      });
    }
  };

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {products.ready ? (
              products.data.length > 0 ? (
                products.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.product}</TableCell>
                    <TableCell>{data.unit}</TableCell>
                    <TableCell> {data.qty} </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="secondary"
                        onClick={() => setRemove({ data, dialog: true })}
                      >
                        <Trash />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="secondary">No record found.</Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography color="primary">
                    loading, please wait...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={products.data.length === 0}
                  onClick={() => setProcess({ ...process, dialog: true })}
                >
                  Process Product IN
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* dialoog process in product */}
      <Dialog open={remove.dialog} onClose={() => handleCloseDialog()}>
        <DialogContent>Remove this temporary product?</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => handleCloseDialog()}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleRemove()}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog process in product */}
      <Dialog open={process.dialog} onClose={() => handleCloseDialog()}>
        <DialogTitle>Process Product IN</DialogTitle>
        <DialogContent dividers>
          <Box>
            <TextField
              onChange={onInputchange}
              fullWidth
              variant="outlined"
              label="Dr number"
              name="dr_number"
            />
          </Box>

          <Box my={2}>
            <TextField
              onChange={onInputchange}
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              label="Dr date"
              name="dr_date"
              type="date"
            />
          </Box>

          <Box my={2}>
            <TextField
              onChange={onInputchange}
              fullWidth
              variant="outlined"
              label="Password"
              name="password"
              type="password"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={handleCloseDialog}
          >
            No
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleProcessInProduct}
            disabled={process.processing}
            startIcon={
              process.processing && (
                <CircularProgress size={25} color="inherit" />
              )
            }
          >
            Process
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TempList;
