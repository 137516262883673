import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  Suspense,
} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import axios from "axios";
import { UsersHeader, UsersData, WarehouseRoleAndId } from "../ContextAPI";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faDatabase,
  // faDesktop,
  // faHome,
  faReceipt,
  faStoreAlt,
  faTruck,
  faUserLock,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "src/utils/LoadingScreen";
import CopyAsInvoicee from "src/warehouse/copyasinvoice";

const AccountDrProduct = React.lazy(() =>
  import("src/warehouse/dr-accounts/AccountDrProduct")
);
const AgentsSales = React.lazy(() => import("src/warehouse/agent/accounts"));
const Account = React.lazy(() => import("src/warehouse/account"));
const DrAccountAgents = React.lazy(() => import("src/warehouse/agent"));
const WarehousePurchaseOrder = React.lazy(() =>
  import("src/warehouse/purchaseorder")
);
const PageNotFound = React.lazy(() => import("../PageNotFound"));
const Logout = React.lazy(() => import("../auth/Logout"));
const Sidebar = React.lazy(() => import("../layout/Sidebar"));

const PharmacyWarehouseProducts = React.lazy(() =>
  import("src/warehouse/products")
);

const WarehouseDashboard = React.lazy(() => import("src/warehouse"));

// const PharmacyWarehouseInventory = React.lazy(() =>
//   import("src/warehouse/inventory")
// );

const PharmacyWarehouseInventory = React.lazy(() =>
  import("src/warehouse/inventory/InventoryDashboard")
);

const DeliveredProducts = React.lazy(() =>
  import("src/warehouse/delivery/DeliveredProducts")
);

const PharmacyWarehouseNewInvoice = React.lazy(() =>
  import("src/warehouse/invoice/PharmacyWarehouseNewInvoice")
);

const WarehouseMonitoring = React.lazy(() =>
  import("src/warehouse/monitoring")
);

const ProductMonitoring = React.lazy(() =>
  import("src/warehouse/products/ProductMonitoring")
);

const RouteWarehouse = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);
  const [userRoleAndId, setUserRoleAndId] = useState({
    role: null,
    pharmacy_id: null,
    pharmacy_category: null,
    ready: false,
  });

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("warehouse/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const fetchRole = async () => {
    try {
      var params = {
        user_id: userContext.users.user_id,
        management_id: userContext.users.management_id,
      };
      const response = await axios.get("warehouse/get-spec-role", { params });
      setUserRoleAndId({
        ...userRoleAndId,
        role: response.data.role,
        warehouse_id: response.data.warehouse_id,
        ready: true,
      });
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Delivery",
      show: true,
      icon: <FontAwesomeIcon icon={faTruck} color="white" size={"2x"} />,
      path: "/bmcdc/app/haptech",
      subitem: [],
    },
    // {
    //   name: "DR Products",
    //   show: true,
    //   icon: <FontAwesomeIcon icon={faTruck} color="white" size={"2x"} />,
    //   path: "/bmcdc/app/haptech/delivered",
    //   subitem: [],
    // },
    {
      name: "Invoice",
      show: true,
      icon: <FontAwesomeIcon icon={faReceipt} color="white" size={"2x"} />,
      path: "/bmcdc/app/haptech/inventory/new-invoice",
      subitem: [],
    },
    {
      name: "Products",
      show: true,
      icon: <FontAwesomeIcon icon={faBox} color="white" size={"2x"} />,
      path: "/bmcdc/app/haptech/product",
      subitem: [],
    },
    {
      name: "Inventory",
      show: true,
      icon: <FontAwesomeIcon icon={faDatabase} color="white" size={"2x"} />,
      path: "/bmcdc/app/haptech/inventory",
      subitem: [],
    },
    // {
    //   name: "Monitoring",
    //   show: true,
    //   icon: <FontAwesomeIcon icon={faDesktop} color="white" size={"2x"} />,
    //   path: "/bmcdc/app/haptech/monitoring",
    //   subitem: [],
    // },
    {
      name: "Purchase Order",
      show: true,
      icon: <FontAwesomeIcon icon={faStoreAlt} color="white" size={"2x"} />,
      path: "/bmcdc/app/haptech/purchase-order",
      subitem: [],
    },
    {
      name: "Sales Agent",
      show: true,
      icon: <FontAwesomeIcon icon={faUsers} color="white" size={"2x"} />,
      path: "/bmcdc/app/haptech/sales-agents",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/haptech/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    fetchRole();
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Suspense fallback={<LoadingScreen />}>
        <WarehouseRoleAndId.Provider
          value={{
            userRoleAndId: userRoleAndId,
          }}
        >
          <UsersHeader.Provider
            value={{
              sidebarHeader: sidebarHeader,
              renderPharmacyInfo: handleRenderInfo,
            }}
          >
            <BrowserRouter>
              <Sidebar
                notification={{
                  enable: true,
                  owner: "haptech",
                }}
                header={sidebarHeader}
                routes={sidebarRoute}
                module={"haptech"}
              />

              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => <Redirect to="/bmcdc/app/haptech" />}
                />

                <Route
                  exact
                  path="/bmcdc"
                  component={() => <Redirect to="/bmcdc/app/haptech" />}
                />

                <Route
                  exact
                  path="/bmcdc/app"
                  component={() => <Redirect to="/bmcdc/app/haptech" />}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech"
                  component={WarehouseDashboard}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech/product"
                  component={PharmacyWarehouseProducts}
                />
                <Route
                  exact
                  path="/bmcdc/app/haptech/inventory"
                  component={PharmacyWarehouseInventory}
                />
                <Route
                  exact
                  path="/bmcdc/app/haptech/delivered"
                  component={DeliveredProducts}
                />
                <Route
                  exact
                  path="/bmcdc/app/haptech/account"
                  component={Account}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech/inventory/new-invoice"
                  component={PharmacyWarehouseNewInvoice}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech/monitoring"
                  component={WarehouseMonitoring}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech/sales-agents"
                  component={DrAccountAgents}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech/purchase-order"
                  component={WarehousePurchaseOrder}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech/account/delivery/:account_id"
                  component={AccountDrProduct}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech/sales-agents/:agent_id"
                  component={AgentsSales}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech/product/:product_id"
                  component={ProductMonitoring}
                />

                <Route
                  exact
                  path="/bmcdc/app/haptech/purchase-order/:po_number"
                  component={CopyAsInvoicee}
                />

                <Route exact path="/bmcdc/app/logout" component={Logout} />

                <Route render={() => <PageNotFound title="Page not found" />} />
              </Switch>
            </BrowserRouter>
          </UsersHeader.Provider>
        </WarehouseRoleAndId.Provider>
      </Suspense>
    </Fragment>
  );
};

export default RouteWarehouse;
