import React, { useContext, useState } from "react";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import {
  Box,
  Grid,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";

const BankInformation = ({
  selectedBankAddress,
  selectedBankName,
  selectedBankId,
  selectedBankAccountNo,
  getBankAccountList,
  close,
}) => {
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEditBankInfo = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("bank_id", selectedBankId);

    var error = [];

    if (
      formdata.get("bank_name").length === 0 ||
      formdata.get("bank_name").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Bank Name");
    }
    if (
      formdata.get("bank_address").length === 0 ||
      formdata.get("bank_address").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Bank Address");
    }

    if (
      formdata.get("bank_account_no").length === 0 ||
      formdata.get("bank_account_no").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Account Number");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/edit/bank-info", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getBankAccountList();
            close();
            e.target.reset();
            Notify.successRequest("edit contact");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };
  return (
    <>
      <form onSubmit={handleEditBankInfo}>
        <Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Box mb={1}>
                  <TextField
                    fullWidth
                    name="bank_name"
                    label="Bank Name"
                    variant="outlined"
                    defaultValue={
                      selectedBankName !== null ? selectedBankName : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box mb={1}>
                  <TextField
                    fullWidth
                    name="bank_address"
                    label="Bank Address"
                    variant="outlined"
                    defaultValue={
                      selectedBankAddress !== null ? selectedBankAddress : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box mb={1}>
                  <TextField
                    fullWidth
                    name="bank_account_no"
                    label="Account Number"
                    variant="outlined"
                    defaultValue={
                      selectedBankAccountNo !== null
                        ? selectedBankAccountNo
                        : ""
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={2} mb={1} display="flex">
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="default"
              type="reset"
              startIcon={<HighlightOff />}
              onClick={() => close()}
            >
              Close
            </Button>

            <Box ml={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default BankInformation;
