import React, { useContext, useEffect, useState } from "react";
import Container from "src/layout/Container";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { SaveAlt } from "@material-ui/icons";
import { FormInformation, UsersData } from "src/ContextAPI";
import axios from "axios";

const useStyles = makeStyles(() => ({
  inputComponent: {
    height: "30px",
    width: "350px",
  },
  inputComponentSub1: {
    height: "30px",
    width: "500px",
  },
  inputComponentSub2: {
    height: "30px",
    width: "600px",
  },
  inputComponentSub3: {
    height: "30px",
    width: "400px",
  },
}));

const BillingPrintOption = () => {
  const classes = useStyles();
  const { users } = useContext(UsersData);
  const { formInfo, renderFormInfo } = useContext(FormInformation);
  const [data, setData] = useState({});

  const handleFromEdit = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);

    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    let response = await axios.post("billing/edit/form-print-info", formdata);

    if (response.data === "success") {
      e.target.reset();
      renderFormInfo();
      Notify.successRequest("edit form info");
    }
  };

  const onInputchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const renderB = React.useCallback(() => {
    setTimeout(() => {
      setData(formInfo);
    }, 2000);
  }, [formInfo]);

  useEffect(() => {
    renderB();
  }, [renderB]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "print option",
        items: [{ name: "dashboard", path: "/bmcdc/app/billing" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Print Out Options</Box>
        </Box>
      }
    >
      <Paper component={Box} p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <form onSubmit={handleFromEdit} noValidate>
              <Box>
                <Box align="center" mb={2}>
                  <Box hidden>
                    <TextField
                      required
                      name="ffhi_id"
                      value={
                        data.ffhi_id !== null || data.ffhi_id !== undefined
                          ? data.ffhi_id
                          : ""
                      }
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      onChange={onInputchange}
                    />
                  </Box>
                  <Box>
                    <TextField
                      required
                      name="subtitle1"
                      value={data.subtitle1}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      className={classes.inputComponentSub1}
                      onChange={onInputchange}
                    />
                  </Box>
                  <Box>
                    <TextField
                      required
                      name="subtitle2"
                      value={data.subtitle2}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      className={classes.inputComponentSub2}
                      onChange={onInputchange}
                    />
                  </Box>
                  <Box>
                    <TextField
                      required
                      name="subtitle3"
                      value={data.subtitle3}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      className={classes.inputComponentSub3}
                      onChange={onInputchange}
                    />
                  </Box>
                </Box>

                <Box align="center">
                  <Box mb={1}>
                    <Typography variant="h6" className="textUppercase">
                      <b> STATEMENT OF ACCOUNT </b>
                    </Typography>

                    <Typography>Date from - Date to</Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography>
                      <b> Company : </b>
                    </Typography>
                    <Typography>
                      <b> HMO Charge To : </b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>Statement No. : </Typography>
                    <Typography>Statement Date. :</Typography>
                  </Box>
                </Box>

                <Box mt={3}>
                  <Box
                    display="flex"
                    border={1}
                    justifyContent="center"
                    alignItems="center"
                    height={100}
                    borderColor={"#AEAEAE"}
                    borderRadius={4}
                  >
                    <Typography variant="h3"> CONTENT </Typography>
                  </Box>
                  <Box my={2} mt={5}>
                    <Box display="flex">
                      <Box flexGrow={1} width={"50%"}>
                        <Typography> Prepared by: </Typography>
                        <Box align="center">
                          <Box>
                            <TextField
                              required
                              name="prepared_by"
                              value={data.prepared_by}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            />
                          </Box>
                          <Box maxWidth={350}>Cashier</Box>
                        </Box>
                      </Box>
                      <Box width={"50%"}>
                        <Typography> Verified by: </Typography>
                        <Box align="center">
                          <Box>
                            <TextField
                              required
                              name="verified_by"
                              value={data.verified_by}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            />
                          </Box>
                          <Box maxWidth={350}>Accounting in Charge</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box display="flex">
                      <Box flexGrow={1} width={"50%"}>
                        <Typography> Checked by: </Typography>
                        <Box align="center">
                          <Box>
                            <TextField
                              required
                              name="checked_by"
                              value={data.checked_by}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            />
                          </Box>
                          <Box maxWidth={350}>Clinic Coordinator</Box>
                        </Box>
                      </Box>

                      <Box width={"50%"}>
                        <Typography> Noted by: </Typography>
                        <Box align="center">
                          <Box>
                            <TextField
                              required
                              name="noted_by"
                              value={data.noted_by}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            />
                          </Box>
                          <Box maxWidth={350}>Accounting Head</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flexGrow={1} />
                    <Box>
                      <Button
                        className={"d-print-none"}
                        variant="contained"
                        color="primary"
                        startIcon={<SaveAlt />}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default BillingPrintOption;
