import React, { useContext, useEffect, useState, Fragment } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Box,
  TextField,
  InputAdornment,
  TablePagination,
  IconButton,
  Collapse,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import { ArrowRight, ArrowUp } from "react-feather";

const PackageList = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [packages, setPackages] = useState({ data: [], ready: false });
  const { users } = useContext(UsersData);
  const [selectedPackageId, setSelectedPackageId] = useState(null);

  const [orderList, setOrderList] = useState({ data: [], ready: false });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getAllPackage = async () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    try {
      const request = await axios.get("accounting/get/all/confirmed-package", {
        params,
      });
      const data = request.data;
      setPackages({ data, ready: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPackageDetails = (value) => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      package_id: value,
    };
    axios
      .get("accounting/get/details/package/by-id", { params })
      .then((res) => {
        setOrderList(res.data);
      })
      .catch((error) => {
        console.log(error);
        //   Notify.requestError(error);
      });
  };

  const searchable = packages.data.filter((data) => {
    return (
      data.package_name.toLowerCase().indexOf(search.trim().toLowerCase()) !==
      -1
    );
  });

  useEffect(() => {
    getAllPackage();
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6"> Package List </Typography>
            </Box>
            <Box>
              <TextField
                label="Search package"
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Package Name </TableCell>
                <TableCell> Amount </TableCell>
                <TableCell align="center"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {packages.ready ? (
                searchable.length > 0 ? (
                  searchable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <Fragment key={index}>
                        <TableRow>
                          <TableCell> {data.package_name} </TableCell>
                          <TableCell align="right">
                            {" "}
                            {data.order_amount}{" "}
                          </TableCell>
                          <TableCell align="center" style={{ width: 130 }}>
                            <IconButton
                              color="primary"
                              disabled={data.package_id === selectedPackageId}
                              onClick={() => {
                                setSelectedPackageId(data.package_id);
                                handleGetPackageDetails(data.package_id);
                              }}
                            >
                              {data.package_id === selectedPackageId ? (
                                <ArrowUp />
                              ) : (
                                <ArrowRight />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse
                              in={data.package_id === selectedPackageId}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Box>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          <b> Department </b>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 250,
                                          }}
                                        >
                                          <b> Service/Order </b>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {orderList.length > 0 ? (
                                        orderList.map((datax, xindex) => (
                                          <TableRow key={xindex}>
                                            <TableCell>
                                              {datax.department}
                                            </TableCell>
                                            <TableCell>
                                              {datax.order_name}
                                            </TableCell>
                                          </TableRow>
                                        ))
                                      ) : (
                                        <TableRow>
                                          <TableCell align="center" colSpan={3}>
                                            <Typography color="secondary">
                                              No record found.
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Typography color="primary">
                      Loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[5, 50, 100]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default PackageList;
