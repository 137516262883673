import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@material-ui/core";
import LaboratoryReport from "./laboratoryreport/LaboratoryReport";
import { makeStyles } from "@material-ui/core/styles";
import ApartmentIcon from "@material-ui/icons/Apartment";
import WifiIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import LocalPrescriptionReport from "./prescriptionreport/LocalPrescriptionReport";
import VirtualPrescriptionReport from "./prescriptionreport/VirtualPrescriptionReport";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Container from "src/layout/Container";
import { ChevronDown, ChevronRight } from "react-feather";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Report = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [displayDefault, setDisplayDefault] = useState("laboratory");
  const [innerWidth] = useState(window.innerWidth);

  const checkingWidth = () => {
    if (innerWidth < 745) {
      setExpand(true);
    } else {
      setExpand(false);
    }
  };

  useEffect(() => {
    checkingWidth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        title="Income Report"
        breadcrumbs={{
          enable: true,
          current: "Report",
          items: [{ name: "Dashboard", path: "/bmcdc/app/doctor" }],
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Box component={Paper} elevation={2} p={2}>
              <List>
                <ListItem button onClick={() => setExpand(!expand)}>
                  <ListItemText
                    primary={`Menu`}
                    primaryTypographyProps={{ color: "primary" }}
                  />
                </ListItem>

                <Divider />

                <ListItem
                  button
                  onClick={() => {
                    setOpen(false);
                    setDisplayDefault("laboratory");
                  }}
                >
                  <ListItemIcon>
                    <ApartmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Laboratory"} />
                </ListItem>

                <Divider />

                <ListItem button onClick={() => setOpen(!open)}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Prescription"} />
                  {open ? (
                    <ChevronDown size={20} />
                  ) : (
                    <ChevronRight size={20} />
                  )}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => setDisplayDefault("prescription-local")}
                    >
                      <ListItemIcon>
                        <WifiOffIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Local"} />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => setDisplayDefault("prescription-virtual")}
                    >
                      <ListItemIcon>
                        <WifiIcon />
                      </ListItemIcon>
                      <ListItemText variant="primary" primary={"Virtual"} />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={9}>
            <Box component={Paper} elevation={2} p={2}>
              {displayDefault === "laboratory" && <LaboratoryReport />}
              {displayDefault === "prescription-local" && (
                <LocalPrescriptionReport />
              )}
              {displayDefault === "prescription-virtual" && (
                <VirtualPrescriptionReport />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Report;
