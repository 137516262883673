import {
  Box,
  Collapse,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";

import Notify from "src/notification/Notify";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue } from "@material-ui/core/colors";

import DocuPatientDetailsPEFormDetails from "./DocuPatientDetailsPEFormDetails";

// 11-19-2021-updates-jhomar

const DocuPatientDetailsPE = ({ patient_id }) => {
  const [newPEOrder, setNewPEOrder] = useState([]);
  const [newPEOrderRd, setNewPEOrderRd] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const getPEOrderToProcess = () => {
    axios
      .get("documentation/van/patient/get-neworderPE", {
        params: {
          patient_id,
        },
      })
      .then((response) => {
        const data = response.data;
        setNewPEOrder(data);
      })
      .catch((error) => console.log("error:", error.message))
      .finally(() => {
        setNewPEOrderRd(true);
      });
  };

  useEffect(() => {
    getPEOrderToProcess();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {newPEOrderRd ? (
        newPEOrder.length > 0 ? (
          newPEOrder.map((data, index) => {
            return (
              <Fragment key={index}>
                {index === 0 && (
                  <Box my={1}>
                    <Typography color="textSecondary" variant="h6">
                      Physical Examination Order
                    </Typography>
                  </Box>
                )}
                <Paper component={Box} my={2} p={2}>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Box display="flex">
                        <Box mt={1} mr={2}>
                          <ListAltIcon
                            style={{ fontSize: "2rem", color: blue[500] }}
                          />
                        </Box>

                        <Box>
                          <Box>
                            <Typography
                              variant="subtitle1"
                              className={`gtc-uppercase`}
                            >
                              {Notify.dateTimeConvert(data.created_at)}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              className={`gtc-uppercase`}
                            >
                              {data.trace_number}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setSelectedOrder(
                            selectedOrder &&
                              selectedOrder.trace_number === data.trace_number
                              ? null
                              : data
                          )
                        }
                      >
                        {selectedOrder &&
                        selectedOrder.trace_number === data.trace_number ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                  <>
                    {/* order result form */}
                    {selectedOrder && (
                      <Collapse
                        in={selectedOrder.trace_number === data.trace_number}
                      >
                        <Box mt={2}>
                          <DocuPatientDetailsPEFormDetails
                            order={selectedOrder}
                            getPEOrderToProcess={() => getPEOrderToProcess()}
                            // getLabMedicalExam={getLabMedicalExam}
                            // resetDisplay={resetDisplay}
                          />
                        </Box>
                      </Collapse>
                    )}
                  </>
                </Paper>
              </Fragment>
            );
          })
        ) : (
          <Box component={Paper} p={2}>
            {Notify.noRecord()}
          </Box>
        )
      ) : (
        <Box>{Notify.loading()}</Box>
      )}
    </>
  );
};

export default DocuPatientDetailsPE;
