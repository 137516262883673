import React, { useContext, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableFooter, TableRow, Box, DialogActions, Button, IconButton, CircularProgress, TextField } from "@material-ui/core";
import { CheckCircle, Trash, XCircle } from 'react-feather';
import Notify from 'src/notification/Notify';
import axios from 'axios';
import { UsersData } from 'src/ContextAPI';


const ProcessUnsaveOrder = ({ open, close, unsaveList, getUnsaveList, patient_id }) => {

    var total= 0;
    const [deletedId, setDeletedId] = useState(null)
    const [password, setPassword] = useState(false)
    const { users } = useContext(UsersData)

    const calculateTotal = ( amount ) =>{
        total += Number(amount);
        return amount;
    }

    const deleteOrder = (data) => { 
        var formdata = new FormData();   
        formdata.set('icu_id', data.icu_id);       
        setDeletedId(data.id)

        axios.post('imaging/order/local/unsave/delete-order', formdata )
        .then( (response) => {  
            const data = response.data;    
            if(data === 'success'){
                getUnsaveList()
                setDeletedId(null)
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const handleProcess = () => {
        var formdata = new FormData();   
        formdata.set('password', password);        
        formdata.set('user_id', users.user_id)
        formdata.set('username', users.username)
        formdata.set('management_id', users.management_id)
        formdata.set('patient_id', patient_id)
        var error = [];

        if(password.length ===0){
            error = 'error';
            Notify.fieldInvalid('password')
        }

        if(error.length > 0 ){
            console.log('Error sample and more and more and more....')
        }else{
            axios.post('imaging/order/local/unsave/process-order', formdata )
            .then( (response) => {  
                const data = response.data;     
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'success'){
                    Notify.successRequest('order process')
                    close()
                    getUnsaveList()
                }
            }).catch(error=>{ 
                Notify.requestError(error);
            });
        }
    }

    return(
        <>
            <Dialog
                open = { open }
                onClose = { close }
            >
                <DialogTitle>
                    Unsave Order
                </DialogTitle>

                <DialogContent dividers>  
                    <Table>  
                        <TableBody>
                            {
                                unsaveList.map((data, index) => (
                                    <TableRow key={index} >
                                        <TableCell> { data.imaging_order } </TableCell>
                                            <TableCell> { calculateTotal(data.amount) } </TableCell> 
                                            <TableCell>
                                            <IconButton 
                                                color="secondary"
                                                onClick={ () => deleteOrder(data) }
                                                disabled = { deletedId === data.id }
                                            >
                                                { deletedId === data.id ? <CircularProgress size={20} color="inherit" /> : <Trash /> } 
                                            </IconButton>
                                        </TableCell> 
                                    </TableRow>
                                ))
                            }  
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell> Total </TableCell>
                                <TableCell> { total } </TableCell> 
                                <TableCell />
                            </TableRow>
                        </TableFooter>
                    </Table>

                    <Box mt={2}>
                        <TextField 
                            label="Password"
                            variant="outlined" 
                            fullWidth
                            onChange={(e) => setPassword(e.target.value) }
                            type="password"
                        />
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="contained" 
                        size="large" 
                        color="default"
                        startIcon={ <XCircle /> }
                        onClick={ () => close() }
                    > 
                        Close 
                    </Button>

                    <Button 
                        variant="contained" 
                        size="large"
                        color="primary"
                        startIcon={ <CheckCircle /> }
                        onClick = { handleProcess }
                    > 
                        Process 
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}

export default ProcessUnsaveOrder;