import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const StoolTestEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/stool/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {Boolean(parseInt(info.data.fecalysis)) && (
              <Box>
                <Box mb={2} display="flex">
                  <Box flexGrow={1}>
                    <Box align="center">
                      <Typography variant="h6">
                        <b> FECALYSIS </b>
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box hidden={!Boolean(parseInt(info.data.is_processed))}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            label="Color"
                            name="color"
                            fullWidth
                            defaultValue={
                              info.data.color === null ? "" : info.data.color
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            label="Consistency"
                            name="consistency"
                            fullWidth
                            defaultValue={
                              info.data.consistency === null
                                ? ""
                                : info.data.consistency
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            label="Occult Blood"
                            name="occult_blood_result"
                            fullWidth
                            defaultValue={
                              info.data.occult_blood_result === null
                                ? ""
                                : info.data.occult_blood_result
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <Box mt={2}>
                          <Typography>
                            <strong> Microscopy </strong>
                          </Typography>
                        </Box>

                        <Box mt={3}>
                          <Typography>Ascaris Lumbricoides:</Typography>
                        </Box>

                        <Box>
                          <Typography> Hookworm: </Typography>
                        </Box>

                        <Box mt={2}>
                          <Typography>Blastocystis Hominis:</Typography>
                        </Box>

                        <Box mt={2.1}>
                          <Typography>
                            <strong> Giardia Lamblia </strong>
                          </Typography>
                        </Box>

                        <Box>
                          <Typography align="right"> Cyst: </Typography>
                        </Box>

                        <Box mt={1}>
                          <Typography align="right">Trophozoite:</Typography>
                        </Box>

                        <Box mt={1}>
                          <Typography> Trichuris Trichiura </Typography>
                        </Box>

                        <Box mt={1.3}>
                          <Typography>
                            <strong> Entamoeba Histolytica </strong>
                          </Typography>
                        </Box>

                        <Box mt={2}>
                          <Typography align="right"> Cyst: </Typography>
                        </Box>

                        <Box mt={1}>
                          <Typography align="right">Trophozoite:</Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Box align="center">Direct Fecal Smear</Box>

                        <Box>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.dfs)),
                            }}
                            label=""
                            name="dfs_ascaris"
                            fullWidth
                            defaultValue={
                              info.data.dfs_ascaris === null
                                ? ""
                                : info.data.dfs_ascaris
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.dfs)),
                            }}
                            label=""
                            name="dfs_hookworm"
                            fullWidth
                            defaultValue={
                              info.data.dfs_hookworm === null
                                ? ""
                                : info.data.dfs_hookworm
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.dfs)),
                            }}
                            label=""
                            name="dfs_blastocystis"
                            fullWidth
                            defaultValue={
                              info.data.dfs_blastocystis === null
                                ? ""
                                : info.data.dfs_blastocystis
                            }
                          />
                        </Box>

                        <Box mt={1}>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.dfs)),
                            }}
                            label=""
                            name="dfs_giardia_lamblia_cyst"
                            fullWidth
                            defaultValue={
                              info.data.dfs_giardia_lamblia_cyst === null
                                ? ""
                                : info.data.dfs_giardia_lamblia_cyst
                            }
                          />
                        </Box>

                        <Box mt={3}>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.dfs)),
                            }}
                            label=""
                            name="dfs_giardia_lamblia_trophozoite"
                            fullWidth
                            defaultValue={
                              info.data.dfs_giardia_lamblia_trophozoite === null
                                ? ""
                                : info.data.dfs_giardia_lamblia_trophozoite
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.dfs)),
                            }}
                            label=""
                            name="dfs_trichusris_trichuira"
                            fullWidth
                            defaultValue={
                              info.data.dfs_trichusris_trichuira === null
                                ? ""
                                : info.data.dfs_trichusris_trichuira
                            }
                          />
                        </Box>

                        <Box mt={9}>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.dfs)),
                            }}
                            label=""
                            name="dfs_entamoeba_lamblia_cyst"
                            fullWidth
                            defaultValue={
                              info.data.dfs_entamoeba_lamblia_cyst === null
                                ? ""
                                : info.data.dfs_entamoeba_lamblia_cyst
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.dfs)),
                            }}
                            label=""
                            name="dfs_entamoeba_lamblia_trophozoite"
                            fullWidth
                            defaultValue={
                              info.data.dfs_entamoeba_lamblia_trophozoite ===
                              null
                                ? ""
                                : info.data.dfs_entamoeba_lamblia_trophozoite
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Box align="center">Kato-Thick</Box>

                        <Box>
                          <TextField
                            label=""
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kt)),
                            }}
                            name="kt_ascaris"
                            fullWidth
                            defaultValue={
                              info.data.kt_ascaris === null
                                ? ""
                                : info.data.kt_ascaris
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kt)),
                            }}
                            name="kt_hookworm"
                            fullWidth
                            defaultValue={
                              info.data.kt_hookworm === null
                                ? ""
                                : info.data.kt_hookworm
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kt)),
                            }}
                            name="kt_blastocystis"
                            fullWidth
                            defaultValue={
                              info.data.kt_blastocystis === null
                                ? ""
                                : info.data.kt_blastocystis
                            }
                          />
                        </Box>

                        <Box mt={1}>
                          <TextField
                            label=""
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kt)),
                            }}
                            name="kt_giardia_lamblia_cyst"
                            fullWidth
                            defaultValue={
                              info.data.kt_giardia_lamblia_cyst === null
                                ? ""
                                : info.data.kt_giardia_lamblia_cyst
                            }
                          />
                        </Box>

                        <Box mt={3}>
                          <TextField
                            label=""
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kt)),
                            }}
                            name="kt_giardia_lamblia_trophozoite"
                            fullWidth
                            defaultValue={
                              info.data.kt_giardia_lamblia_trophozoite === null
                                ? ""
                                : info.data.kt_giardia_lamblia_trophozoite
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kt)),
                            }}
                            name="kt_trichusris_trichuira"
                            fullWidth
                            defaultValue={
                              info.data.kt_trichusris_trichuira === null
                                ? ""
                                : info.data.kt_trichusris_trichuira
                            }
                          />
                        </Box>

                        <Box mt={9}>
                          <TextField
                            label=""
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kt)),
                            }}
                            name="kt_entamoeba_lamblia_cyst"
                            fullWidth
                            defaultValue={
                              info.data.kt_entamoeba_lamblia_cyst === null
                                ? ""
                                : info.data.kt_entamoeba_lamblia_cyst
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            label=""
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kt)),
                            }}
                            name="kt_entamoeba_lamblia_trophozoite"
                            fullWidth
                            defaultValue={
                              info.data.kt_entamoeba_lamblia_trophozoite ===
                              null
                                ? ""
                                : info.data.kt_entamoeba_lamblia_trophozoite
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Box align="center">Kato-Katz</Box>

                        <Box align="center">
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kk)),
                            }}
                            label=""
                            name="kk_ascaris"
                            fullWidth
                            defaultValue={
                              info.data.kk_ascaris === null
                                ? ""
                                : info.data.kk_ascaris
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kk)),
                            }}
                            label=""
                            name="kk_hookworm"
                            fullWidth
                            defaultValue={
                              info.data.kk_hookworm === null
                                ? ""
                                : info.data.kk_hookworm
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kk)),
                            }}
                            label=""
                            name="kk_blastocystis"
                            fullWidth
                            defaultValue={
                              info.data.kk_blastocystis === null
                                ? ""
                                : info.data.kk_blastocystis
                            }
                          />
                        </Box>

                        <Box mt={1}>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kk)),
                            }}
                            label=""
                            name="kk_giardia_lamblia_cyst"
                            fullWidth
                            defaultValue={
                              info.data.kk_giardia_lamblia_cyst === null
                                ? ""
                                : info.data.kk_giardia_lamblia_cyst
                            }
                          />
                        </Box>

                        <Box mt={3}>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kk)),
                            }}
                            label=""
                            name="kk_giardia_lamblia_trophozoite"
                            fullWidth
                            defaultValue={
                              info.data.kk_giardia_lamblia_trophozoite === null
                                ? ""
                                : info.data.kk_giardia_lamblia_trophozoite
                            }
                          />
                        </Box>
                        <Box>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kk)),
                            }}
                            label=""
                            name="kk_trichusris_trichuira"
                            fullWidth
                            defaultValue={
                              info.data.kk_trichusris_trichuira === null
                                ? ""
                                : info.data.kk_trichusris_trichuira
                            }
                          />
                        </Box>
                        <Box mt={9}>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kk)),
                            }}
                            label=""
                            name="kk_entamoeba_lamblia_cyst"
                            fullWidth
                            defaultValue={
                              info.data.kk_entamoeba_lamblia_cyst === null
                                ? ""
                                : info.data.kk_entamoeba_lamblia_cyst
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            InputProps={{
                              disabled: !Boolean(parseInt(info.data.kk)),
                            }}
                            label=""
                            name="kk_entamoeba_lamblia_trophozoite"
                            fullWidth
                            defaultValue={
                              info.data.kk_entamoeba_lamblia_trophozoite ===
                              null
                                ? ""
                                : info.data.kk_entamoeba_lamblia_trophozoite
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <TextField
                      label="Other"
                      name="others"
                      multiline
                      fullWidth
                      defaultValue={
                        info.data.others === null ? "" : info.data.others
                      }
                    />
                  </Box>

                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            label="Pus Cells"
                            name="pus_cells"
                            fullWidth
                            defaultValue={
                              info.data.pus_cells === null
                                ? ""
                                : info.data.pus_cells
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            label="Red Blood Cells"
                            name="reb_blood_cells"
                            fullWidth
                            defaultValue={
                              info.data.reb_blood_cells === null
                                ? ""
                                : info.data.reb_blood_cells
                            }
                          />
                        </Box>

                        <Box>
                          <TextField
                            label="Fat Globules"
                            name="fat_globules"
                            fullWidth
                            defaultValue={
                              info.data.fat_globules === null
                                ? ""
                                : info.data.fat_globules
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            label="Yeast Cells"
                            name="yeast_cells"
                            fullWidth
                            defaultValue={
                              info.data.yeast_cells === null
                                ? ""
                                : info.data.yeast_cells
                            }
                          />
                        </Box>
                        <Box>
                          <TextField
                            label="Bacteria"
                            name="bacteria"
                            fullWidth
                            defaultValue={
                              info.data.bacteria === null
                                ? ""
                                : info.data.bacteria
                            }
                          />
                        </Box>
                        <Box>
                          <TextField
                            label="Oil Droplets"
                            name="oil_droplets"
                            fullWidth
                            defaultValue={
                              info.data.oil_droplets === null
                                ? ""
                                : info.data.oil_droplets
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            label="Undigested Food Particles"
                            name="undigested_foods_paticles"
                            fullWidth
                            defaultValue={
                              info.data.undigested_foods_paticles === null
                                ? ""
                                : info.data.undigested_foods_paticles
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <TextField
                      label="Remarks"
                      name="remarks"
                      multiline
                      fullWidth
                      defaultValue={
                        info.data.remarks === null ? "" : info.data.remarks
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}
            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default StoolTestEditForm;
