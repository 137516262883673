import React from 'react';    
import Box from '@material-ui/core/Box';   
import PersonalInfo from '../patients/PersonalInfo'; 

const AppointmentApprovedDetailsReadmore = ({ connection, patient_id }) =>{  
    return( 
        <Box m={0} p={0}>
            <PersonalInfo connection={ connection } patient_id = { patient_id } />
        </Box> 
    )
}

export default AppointmentApprovedDetailsReadmore;