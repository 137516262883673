import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  makeStyles,
  Fab,
  createStyles,
} from "@material-ui/core";
import { Close, Print } from "@material-ui/icons";
import Notify from "src/notification/Notify";

const PrintReport = ({ report, close, total }) => {
  const classes = useStyles();

  return (
    <Box m={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b> Date </b>
              </TableCell>
              <TableCell align="center">
                <b> Patient </b>
              </TableCell>
              <TableCell align="center">
                <b> Category </b>
              </TableCell>
              <TableCell align="center">
                <b> Company </b>
              </TableCell>
              <TableCell align="center">
                <b> Amount </b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {report.data.map((data, index) => (
              <TableRow key={index}>
                <TableCell>{Notify.createdAt(data.created_at)}</TableCell>
                <TableCell>
                  {data.lastname}, {data.firstname}
                </TableCell>
                <TableCell>
                  {data.is_charged === 0 ? "Walk-In" : "Corporate"}
                </TableCell>
                <TableCell>
                  {data.hmo_used !== null && data.charge_type === "direct"
                    ? data.company_complete_name
                    : data.hmo_used !== null && data.charge_type === "hmo"
                    ? `${data.company_complete_name} - ${data.hmo_complete_name}`
                    : ""}
                </TableCell>
                <TableCell align="right">
                  {Notify.convertToNumber(data.bill_total)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell align="center">Total</TableCell>
              <TableCell align="right">
                {Notify.convertToNumber(total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box className="d-print-none">
        <Fab
          aria-label={"Print"}
          className={classes.fab2}
          color={"secondary"}
          onClick={() => close()}
        >
          <Close />
        </Fab>
        <Fab
          aria-label={"Print"}
          className={classes.fab}
          color={"primary"}
          onClick={() => window.print()}
        >
          <Print />
        </Fab>
      </Box>
    </Box>
  );
};

export default PrintReport;

const useStyles = makeStyles((theme) =>
  createStyles({
    fab2: {
      position: "absolute",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);
