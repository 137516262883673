import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import TriageDashboard from "src/triage/TriageDashboard";
import Account from "src/triage/account";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faHome,
  faUserInjured,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import LeaveForm from "src/triage/leave/LeaveForm";
import TriageNews from "src/triage/TriageNews";

const RouteTriage = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("triage/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/bmcdc/app/triage",
      subitem: [],
    },

    {
      name: "Patients List",
      show: true,
      icon: <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
      path: "/bmcdc/app/triage/patients",
      subitem: [],
    },

    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/bmcdc/app/triage/leave-application",
      subitem: [],
    },

    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/triage/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "triage",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"triage"}
          />

          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/bmcdc/app/triage" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/bmcdc/app/triage" />}
            />

            <Route
              exact
              path="/bmcdc/app"
              component={() => <Redirect to="/bmcdc/app/triage" />}
            />

            <Route exact path="/bmcdc/app/triage" component={TriageNews} />

            <Route
              exact
              path="/bmcdc/app/triage/patients"
              component={TriageDashboard}
            />

            <Route
              exact
              path="/bmcdc/app/triage/leave-application"
              component={LeaveForm}
            />

            <Route exact path="/bmcdc/app/triage/account" component={Account} />

            <Route exact path="/bmcdc/app/logout" component={Logout} />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteTriage;
