import React, { Fragment, useState } from "react";
import Container from "src/layout/Container";
import {
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Collapse,
  // Divider,
  Badge,
  makeStyles,
  // TextField,
  CardMedia,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AccountList from "./AccountList";
import axios from "axios";
import { UsersData, BranchListContext } from "src/ContextAPI";
import QrDialog from "./QrDialog";
import SelectStyle from "src/utils/SelectStyle";
import Select from "react-select";

const useStyles = makeStyles(() => ({
  badgeOnline: {
    backgroundColor: "green",
    color: "#fff",
    marginTop: -4,
  },
}));

const HRManpower = () => {
  const classes = useStyles();
  const [qrDialog, setQrDialog] = useState({
    open: false,
    data: null,
    type: "",
  });
  const [expanded, setExpanded] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchType, setSelectedBranchType] = useState(null);
  const { branchList } = React.useContext(BranchListContext);
  const selectStyle = SelectStyle();

  const employeeGroup = [
    {
      name: "accounting",
      hidden:
        selectedBranchType === "hq"
          ? false
          : selectedBranchType === "clinic"
          ? true
          : selectedBranchType === "van"
          ? true
          : false,
    },
    {
      name: "billing",
      hidden:
        selectedBranchType === "hq"
          ? false
          : selectedBranchType === "clinic"
          ? true
          : selectedBranchType === "van"
          ? true
          : false,
    },
    {
      name: "cashier",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? true
          : true,
    },
    {
      name: "doctor",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? false
          : false,
    },
    {
      name: "documentation",
      hidden:
        selectedBranchType === "hq"
          ? false
          : selectedBranchType === "clinic"
          ? true
          : selectedBranchType === "van"
          ? true
          : false,
    },
    {
      name: "endorsement",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? false
          : false,
    }, // clinic and van
    {
      name: "haptech",
      hidden:
        selectedBranchType === "hq"
          ? false
          : selectedBranchType === "clinic"
          ? true
          : selectedBranchType === "van"
          ? true
          : false,
    },
    {
      name: "hr",
      hidden:
        selectedBranchType === "hq"
          ? false
          : selectedBranchType === "clinic"
          ? true
          : selectedBranchType === "van"
          ? true
          : false,
    },
    {
      name: "imaging",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? false
          : false,
    }, // clinic and van
    {
      name: "laboratory",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? false
          : false,
    }, // clinic and van
    {
      name: "om",
      hidden:
        selectedBranchType === "hq"
          ? false
          : selectedBranchType === "clinic"
          ? true
          : selectedBranchType === "van"
          ? true
          : false,
    },
    {
      name: "nurse station",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? false
          : false,
    }, // clinic and van
    // { name: "pharmacy" },
    {
      name: "psychology",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? true
          : false,
    },
    {
      name: "radiologist",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? false
          : false,
    },
    {
      name: "receiving",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? false
          : false,
    }, // clinic and van
    {
      name: "registration",
      hidden:
        selectedBranchType === "hq"
          ? true
          : selectedBranchType === "clinic"
          ? false
          : selectedBranchType === "van"
          ? false
          : false,
    }, // clinic and van
    // { name: "stockroom" }, //wlaon
    // { name: "triage" }, //wlaon
    { name: "others", hidden: false },
    // { name: "warehouse" },
  ];
  const { users } = React.useContext(UsersData);
  const [accountActive, setAccountActive] = useState({
    data: [],
    ready: false,
  });

  const getAllUsersByBranch = React.useCallback(() => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      branch: selectedBranch,
      main_management_id: users.main_mgmt_id,
    };
    axios
      .get("hims/get-all/active-not-yet", { params })
      .then((res) => {
        const data = res.data;
        setAccountActive({ data: data, ready: true });
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  }, [users, selectedBranch]);

  // const handleGetUsersByBranch = (e) => {
  //   e.preventDefault();
  //   e.persist();
  //   var formdata = new FormData(e.target);
  //   formdata.set("main_management_id", users.main_mgmt_id);
  //   formdata.set("management_id", users.management_id);
  //   formdata.set("user_id", users.user_id);

  //   axios
  //     .post("hims/get-all/active-not-yet", formdata)
  //     .then((response) => {
  //       const data = response.data;
  //       setAccountActive({ data, ready: true });
  //     })
  //     .catch((err) => {
  //       console.log("error : ", err);
  //     });
  // };
  console.log("selectedBranchType", selectedBranchType);

  React.useEffect(() => {
    getAllUsersByBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllUsersByBranch]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "manpower",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="Manpower"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box component={Paper} variant="outlined">
              <Box display="flex" m={2} className="d-print-none">
                <Box flexGrow={1} className="d-print-none" />
                <Box className="d-print-none">
                  <Box width={250}>
                    <Select
                      options={branchList.data}
                      onChange={(value) => {
                        setSelectedBranch(value.management_id);
                        setSelectedBranchType(value.branch_type);
                      }}
                      placeholder="Select Branch"
                      styles={selectStyle}
                    />
                  </Box>

                  {/* <TextField
                    required
                    name="branch"
                    label="Branch"
                    variant="outlined"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    onClick={(e) => {
                      console.log(e.target)
                      setSelectedBranch(e.target.value)
                      // setSelectedBranchType(e.target.)
                    } }
                  >
                    <option value="All">All</option>
                    {branchList.data.length > 0 &&
                      branchList.data.map((data, index) => (
                        <option key={index} value={data.management_id}>
                          {data.name}
                        </option>
                      ))}
                  </TextField> */}
                </Box>
              </Box>
              <Box m={2}>
                {accountActive.ready ? (
                  employeeGroup.length > 0 ? (
                    employeeGroup.map((data, index) => {
                      return (
                        <Fragment key={index}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => {
                              setQrDialog({
                                ...qrDialog,
                                type: data.name,
                              });
                            }}
                            hidden={data.hidden}
                          >
                            <Box flexGrow={1}>
                              {accountActive.data.length > 0 ? (
                                <Badge
                                  badgeContent={
                                    data.name === "accounting"
                                      ? accountActive.data[0]
                                          .activeCountAccounting
                                      : data.name === "billing"
                                      ? accountActive.data[0].activeCountBilling
                                      : data.name === "cashier"
                                      ? accountActive.data[0].activeCountCashier
                                      : data.name === "doctor"
                                      ? accountActive.data[0].activeCountDoctor
                                      : data.name === "documentation"
                                      ? accountActive.data[0].activeCountEncoder
                                      : data.name === "haptech"
                                      ? accountActive.data[0].activeCountHaptech
                                      : data.name === "hr"
                                      ? accountActive.data[0].activeCountHr
                                      : data.name === "imaging"
                                      ? accountActive.data[0].activeCountImaging
                                      : data.name === "laboratory"
                                      ? accountActive.data[0]
                                          .activeCountLaboratory
                                      : data.name === "om"
                                      ? accountActive.data[0].activeCountOM
                                      : data.name === "pharmacy"
                                      ? accountActive.data[0]
                                          .activeCountPharmacy
                                      : data.name === "psychology"
                                      ? accountActive.data[0]
                                          .activeCountPsychology
                                      : data.name === "radiologist"
                                      ? accountActive.data[0]
                                          .activeCountRadiologist
                                      : data.name === "registration"
                                      ? accountActive.data[0]
                                          .activeCountRegistration
                                      : data.name === "stockroom"
                                      ? accountActive.data[0]
                                          .activeCountStockroom
                                      : data.name === "triage"
                                      ? accountActive.data[0].activeCountTriage
                                      : data.name === "warehouse"
                                      ? accountActive.data[0]
                                          .activeCountWarehouse
                                      : data.name === "others"
                                      ? accountActive.data[0].activeCountOthers
                                      : null
                                  }
                                  classes={{ badge: classes.badgeOnline }}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <Typography
                                    variant={"subtitle1"}
                                    color="primary"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    <strong> {data.name} </strong>
                                  </Typography>
                                </Badge>
                              ) : (
                                <Typography
                                  variant={"subtitle1"}
                                  color="primary"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  <strong> {data.name}</strong>
                                </Typography>
                              )}
                            </Box>

                            <Box>
                              <IconButton
                                variant="outlined"
                                color={"primary"}
                                onClick={() =>
                                  setExpanded(
                                    expanded === data.name ? null : data.name
                                  )
                                }
                              >
                                {expanded === data.name ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </IconButton>
                            </Box>
                          </Box>
                          <Collapse in={expanded === data.name}>
                            {expanded === data.name ? (
                              <AccountList
                                qrDialog={qrDialog}
                                setQrDialog={setQrDialog}
                                account={data.name}
                                accountActive={accountActive}
                                selectedBranch={selectedBranch}
                                selectedBranchType={selectedBranchType}
                              />
                            ) : null}
                          </Collapse>
                          {/* {parseFloat(employeeGroup.length) - 1 !== index && (
                            <Divider />
                          )} */}
                        </Fragment>
                      );
                    })
                  ) : null
                ) : (
                  <RenderSelectBranch />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {qrDialog.open && (
          <QrDialog
            open={qrDialog.open}
            close={() => setQrDialog({ ...qrDialog, open: false, data: null })}
            qrDialog={qrDialog}
            setQrDialog={setQrDialog}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default HRManpower;

const RenderSelectBranch = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />

    <Typography color="secondary" variant="h3">
      NO BRANCH SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select branch in the list to view details.
    </Typography>
  </Box>
);
