import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  Dialog,
  Box,
} from "@material-ui/core";
import React, { useState } from "react";
import { Edit } from "react-feather";
import { UsersHeader } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import ProcessToAddResult from "./ProcessToAddResult";

const ImagingToAddResult = () => {
  const [selectedResult, setSelectedResult] = useState({
    data: [],
    ready: false,
  });
  const [toAddResultDialog, setToAddResultDialog] = useState(false);

  const {
    queue: { patientToAddResult, getAllToAddResult },
  } = React.useContext(UsersHeader);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "To Edit Result",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>to Add Result</Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Doctor's reading" />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Patient</TableCell>
                      <TableCell>Result</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patientToAddResult.ready ? (
                      patientToAddResult.data.length > 0 ? (
                        patientToAddResult.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {Notify.createdAt(data.created_at)}
                            </TableCell>
                            <TableCell>
                              {data.lname}, {data.fname}
                            </TableCell>
                            <TableCell>
                              <Typography
                                color={
                                  data.imaging_result === null
                                    ? "secondary"
                                    : "primary"
                                }
                              >
                                {data.imaging_result === null
                                  ? "NEED TO ADD RESULT"
                                  : data.imaging_result}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setSelectedResult({ data, ready: true });
                                  setToAddResultDialog(true);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <Typography color="secondary">
                              No record to add result.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography color="primary">
                            Loading, please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={toAddResultDialog}
        onClose={() => {
          setToAddResultDialog(false);
          setSelectedResult({ data: [], ready: false });
        }}
        fullWidth
        maxWidth="xs"
      >
        <ProcessToAddResult
          selectedResult={selectedResult}
          getAllPatientAlreadyRead={() => getAllToAddResult()}
          close={() => {
            setSelectedResult({ data: [], ready: false });
            setToAddResultDialog(false);
          }}
        />
      </Dialog>
    </Container>
  );
};

export default ImagingToAddResult;
