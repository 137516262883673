import React, { useState, useContext } from "react";
import {
  Box,
  Grid,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import axios from "axios";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";

const BankRemoveAccount = ({
  selectedBankAddress,
  selectedBankName,
  selectedBankId,
  selectedBankAccountNo,
  getBankAccountList,
  close,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { users } = useContext(UsersData);

  const handleRemoveAccount = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("bank_id", selectedBankId);

    var error = [];

    if (
      formdata.get("reason").length === 0 ||
      formdata.get("reason").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Reason");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/remove/bank-account", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getBankAccountList();
            close();
            e.target.reset();
            Notify.successRequest("remove account");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };
  return (
    <>
      <form onSubmit={handleRemoveAccount}>
        <Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Box mb={1}>
                  <TextField
                    fullWidth
                    name="bank_name"
                    label="Bank Name"
                    variant="outlined"
                    defaultValue={
                      selectedBankName !== null ? selectedBankName : ""
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box mb={1}>
                  <TextField
                    fullWidth
                    name="bank_address"
                    label="Bank Address"
                    variant="outlined"
                    defaultValue={
                      selectedBankAddress !== null ? selectedBankAddress : ""
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box mb={1}>
                  <TextField
                    fullWidth
                    name="bank_account_no"
                    label="Account Number"
                    variant="outlined"
                    defaultValue={
                      selectedBankAccountNo !== null
                        ? selectedBankAccountNo
                        : ""
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box mb={1}>
                  <TextField
                    fullWidth
                    name="reason"
                    label="Reason of deletion"
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box my={1}>
            <TextField
              fullWidth
              name="password"
              type="password"
              label="Password"
              variant="outlined"
            />
          </Box>

          <Box mt={2} mb={1} display="flex">
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="default"
              type="reset"
              startIcon={<HighlightOff />}
              onClick={() => close()}
            >
              Close
            </Button>

            <Box ml={2}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
                disabled={isSubmitting}
              >
                Remove
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};
export default BankRemoveAccount;
