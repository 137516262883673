import {
  Box,
  CardContent,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import ThyroidProfileRef from "src/laboratory/references/Ref_ThyroidProfile";
import Footer from "src/laboratory/print/Footer";

const ThyroidProfileOrder = ({ order_id, patient_id, formheader }) => {
  const { users } = React.useContext(UsersData);

  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_thyroid_profile");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_hematology table error", error);
    }
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box>
            <Box>
              <Typography variant="h6" align="center">
                <strong>THYROID PROFILE</strong>
              </Typography>
            </Box>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">TEST</TableCell>
                      <TableCell align="center">RESULT</TableCell>
                      <TableCell align="center">REFERENCE VALUES</TableCell>
                      <TableCell align="center">REMARKS</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {details.map((data, index) => (
                      <Fragment key={index}>
                        {data.t3 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                T3
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{data.t3}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.t3()}
                            </TableCell>
                            <TableCell align="center">
                              {data.t3_remarks}
                            </TableCell>
                          </TableRow>
                        )}

                        {data.t4 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                T4
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{data.t4}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.t4()}
                            </TableCell>
                            <TableCell align="center">
                              {data.t4_remarks}
                            </TableCell>
                          </TableRow>
                        )}

                        {data.tsh && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                TSH
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{data.tsh}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.tsh()}
                            </TableCell>
                            <TableCell align="center">
                              {data.tsh_remarks}
                            </TableCell>
                          </TableRow>
                        )}

                        {data.ft4 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                FT4
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{data.ft4}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.ft4()}
                            </TableCell>
                            <TableCell align="center">
                              {data.ft4_remarks}
                            </TableCell>
                          </TableRow>
                        )}

                        {data.ft3 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                FT3
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{data.ft3}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.ft3()}
                            </TableCell>
                            <TableCell align="center">
                              {data.ft3_remarks}
                            </TableCell>
                          </TableRow>
                        )}

                        {data.t3t4 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                T3T4
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{data.t3t4}</Typography>
                            </TableCell>
                            <TableCell align="center">none</TableCell>
                            <TableCell align="center">
                              {data.t3t4_remarks}
                            </TableCell>
                          </TableRow>
                        )}

                        {data.fht && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                              >
                                FHT
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{data.fht}</Typography>
                            </TableCell>
                            <TableCell align="center">none</TableCell>
                            <TableCell align="center">
                              {data.fht_remarks}
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Box>

          <Footer formheader={formheader} />
        </>
      )}
    </>
  );
};

export default ThyroidProfileOrder;
