import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Grid,
  TextField,
  FormHelperText,
  Button,
  Divider,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const EditEmployeeInformation = ({
  close,
  selectedEmployee,
  selectedDepartmentType,
  getAllUsersAccount,
}) => {
  const { users } = useContext(UsersData);
  const [info, setInfo] = useState({ data: [], ready: false });

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getUsersInfo = useCallback(async () => {
    var params = {
      user_id: selectedEmployee,
      type: selectedDepartmentType,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("hr/get/for-edit/specific-info", {
        params,
      });
      const data = response.data;
      console.log("dasdta", data);
      setInfo({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, selectedDepartmentType, selectedEmployee]);

  useEffect(() => {
    getUsersInfo();
  }, [selectedEmployee, selectedDepartmentType, getUsersInfo]);
  return (
    <>
      {info.ready ? (
        info.data ? (
          <Formik
            enableReinitialize
            initialValues={{
              username: users.username,
              user_id: users.user_id,
              password: "",

              users_user_id: selectedEmployee,
              users_user_type: selectedDepartmentType,

              date_started:
                info.data.date_started !== null ? info.data.date_started : "",
              date_birth:
                info.data.date_birth !== null ? info.data.date_birth : "",
              status:
                info.data.employee_status !== null
                  ? info.data.employee_status
                  : "",
              civil_status:
                info.data.civil_status !== null ? info.data.civil_status : "",
              contact: info.data.contact !== null ? info.data.contact : "",
              email: info.data.email !== null ? info.data.email : "",
              fullname:
                info.data.user_fullname !== null ? info.data.user_fullname : "",
              address:
                info.data.user_address !== null ? info.data.user_address : "",
              gender: info.data.gender !== null ? info.data.gender : "",
              rate_classification:
                info.data.rate_classification !== null
                  ? info.data.rate_classification
                  : "",
              position: info.data.position !== null ? info.data.position : "",
              shared: info.data.shared !== null ? info.data.shared : "",
              sick_leave:
                info.data.sick_leave_orig !== null
                  ? info.data.sick_leave_orig
                  : "",
              vacation_leave:
                info.data.vacation_leave_orig !== null
                  ? info.data.vacation_leave_orig
                  : "",
              hazard_start_15:
                info.data.hazard_start_15 !== null
                  ? info.data.hazard_start_15
                  : "",
              hazard_16_end:
                info.data.hazard_16_end !== null ? info.data.hazard_16_end : "",
              daily_salary:
                info.data.daily_salary !== null ? info.data.daily_salary : "",
              monthly_salary:
                info.data.monthly_salary !== null
                  ? info.data.monthly_salary
                  : "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().trim().required("Password is required."),

              date_started: Yup.date().required("Date started  is required."),
              date_birth: Yup.date().required("Birth date is required."),
              status: Yup.string()
                .trim()
                .required("Employee status is required"),
              civil_status: Yup.string()
                .trim()
                .required("Civil status is required"),
              contact: Yup.string().trim().required("Contact # is required"),
              email: Yup.string().trim().required("Email is required"),
              fullname: Yup.string().trim().required("Fullname is required"),
              address: Yup.string().trim().required("Address is required"),
              gender: Yup.string().trim().required("Gender is required"),
              rate_classification: Yup.string()
                .trim()
                .required("Rate classification is required"),
              position: Yup.string()
                .trim()
                .required("Position - location is required"),
              shared: Yup.string().trim().required("Shared salary is required"),
              sick_leave: Yup.string().trim().required("sick leave credit"),
              vacation_leave: Yup.string()
                .trim()
                .required("vacation leave credit"),
              hazard_start_15: Yup.string()
                .trim()
                .required("Hazard pay 1-15 is required"),
              hazard_16_end: Yup.string()
                .trim()
                .required("Hazard pay 16-28/29/30/31 is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setSubmitting, resetForm }
            ) => {
              try {
                const request = await axios.post(
                  "hr/users/edit-users",
                  getFormData(values)
                );
                if (request.data === "success") {
                  getAllUsersAccount();
                  Notify.successRequest("update user's info");
                  resetForm();
                  close();
                }
                if (request.data === "pass-invalid") {
                  Notify.fieldInvalid("password");
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box mb={2}>
                  <Grid container spacing={2}>
                    {values.department !== "doctor" &&
                    values.department !== "radiologist" ? (
                      <Grid item xs={6} sm={6}>
                        <Box className={`labselect2`}>
                          <TextField
                            error={Boolean(
                              touched.date_started && errors.date_started
                            )}
                            helperText={
                              touched.date_started && errors.date_started
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.date_started}
                            fullWidth
                            required
                            name="date_started"
                            label="Date Started"
                            variant="outlined"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>
                      </Grid>
                    ) : null}

                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(
                            touched.date_birth && errors.date_birth
                          )}
                          helperText={touched.date_birth && errors.date_birth}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.date_birth}
                          fullWidth
                          required
                          name="date_birth"
                          label="Birth Date"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>

                    {values.department !== "doctor" &&
                    values.department !== "radiologist" ? (
                      <Grid item xs={6} sm={6}>
                        <Box className={`labselect2`}>
                          <TextField
                            error={Boolean(touched.status && errors.status)}
                            helperText={touched.status && errors.status}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.status}
                            fullWidth
                            required
                            name="status"
                            label="Employee status"
                            variant="outlined"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <option value={""}>Select</option>
                            <option value={"Regular"}>Regular</option>
                            <option value={"Probationary"}>Probationary</option>
                          </TextField>
                        </Box>
                      </Grid>
                    ) : null}

                    {values.department !== "doctor" &&
                    values.department !== "radiologist" ? (
                      <Grid item xs={6} sm={6}>
                        <Box className={`labselect2`}>
                          <TextField
                            error={Boolean(
                              touched.civil_status && errors.civil_status
                            )}
                            helperText={
                              touched.civil_status && errors.civil_status
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.civil_status}
                            fullWidth
                            required
                            name="civil_status"
                            label="Civil Status"
                            variant="outlined"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <option value={""}>Select</option>
                            <option value={"Single"}>Single</option>
                            <option value={"Married"}>Married</option>
                            <option value={"Divorced"}>Divorced</option>
                          </TextField>
                        </Box>
                      </Grid>
                    ) : null}

                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.contact && errors.contact)}
                          helperText={touched.contact && errors.contact}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contact}
                          fullWidth
                          required
                          name="contact"
                          label="Contact #"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={values.department === "doctor" ? 12 : 6}
                      sm={
                        values.department === "doctor" ||
                        values.department === "radiologist"
                          ? 12
                          : 6
                      }
                    >
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          fullWidth
                          required
                          name="email"
                          label="Email"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.fullname && errors.fullname)}
                          helperText={touched.fullname && errors.fullname}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.fullname}
                          fullWidth
                          required
                          name="fullname"
                          label="Fullname"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          fullWidth
                          required
                          name="address"
                          label="Address"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={
                        values.department === "doctor" ||
                        values.department === "radiologist"
                          ? 12
                          : 6
                      }
                      sm={
                        values.department === "doctor" ||
                        values.department === "radiologist"
                          ? 12
                          : 6
                      }
                    >
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gender}
                          fullWidth
                          required
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </TextField>
                      </Box>
                    </Grid>

                    {values.department !== "doctor" &&
                    values.department !== "radiologist" ? (
                      <Grid item xs={6} sm={6}>
                        <Box className={`labselect2`}>
                          <TextField
                            error={Boolean(
                              touched.rate_classification &&
                                errors.rate_classification
                            )}
                            helperText={
                              touched.rate_classification &&
                              errors.rate_classification
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.rate_classification}
                            fullWidth
                            required
                            name="rate_classification"
                            label="Rate Classification"
                            variant="outlined"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <option value={""}>Select</option>
                            <option value={"Daily"}>Daily</option>
                            <option value={"Monthly"}>Monthly</option>
                          </TextField>
                        </Box>
                      </Grid>
                    ) : null}

                    {values.rate_classification === "Daily" &&
                    values.department !== "doctor" &&
                    values.department !== "radiologist" ? (
                      <Grid item xs={12} sm={12}>
                        <Box className={`labselect2`}>
                          <TextField
                            error={Boolean(
                              touched.daily_salary && errors.daily_salary
                            )}
                            helperText={
                              touched.daily_salary && errors.daily_salary
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.daily_salary}
                            fullWidth
                            required
                            name="daily_salary"
                            label="Daily Salary"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 100000000000,
                                step: 0.01,
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                    ) : null}

                    {values.rate_classification === "Monthly" &&
                    values.department !== "doctor" &&
                    values.department !== "radiologist" ? (
                      <Grid item xs={12} sm={12}>
                        <Box className={`labselect2`}>
                          <TextField
                            error={Boolean(
                              touched.monthly_salary && errors.monthly_salary
                            )}
                            helperText={
                              touched.monthly_salary && errors.monthly_salary
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.monthly_salary}
                            fullWidth
                            required
                            name="monthly_salary"
                            label="Monthly Salary"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 100000000000,
                                step: 0.01,
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                    ) : null}

                    {values.department !== "doctor" &&
                    values.department !== "radiologist" ? (
                      <Grid item xs={6} sm={6}>
                        <Box className={`labselect2`}>
                          <TextField
                            error={Boolean(touched.position && errors.position)}
                            helperText={touched.position && errors.position}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.position}
                            fullWidth
                            required
                            name="position"
                            label="Position - location"
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    ) : null}

                    {values.department !== "doctor" &&
                    values.department !== "radiologist" ? (
                      <Grid item xs={6} sm={6}>
                        <Box className={`labselect2`}>
                          <TextField
                            error={Boolean(touched.shared && errors.shared)}
                            helperText={touched.shared && errors.shared}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.shared}
                            fullWidth
                            required
                            name="shared"
                            label="Shared salary"
                            variant="outlined"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <option value={""}>Select</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </TextField>
                        </Box>
                      </Grid>
                    ) : null}

                    {values.department !== "doctor" &&
                    values.department !== "radiologist" ? (
                      <>
                        <Grid item xs={6} sm={6}>
                          <Box className={`labselect2`}>
                            <TextField
                              error={Boolean(
                                touched.sick_leave && errors.sick_leave
                              )}
                              helperText={
                                touched.sick_leave && errors.sick_leave
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.sick_leave}
                              fullWidth
                              required
                              name="sick_leave"
                              label="Sick Leave Credit"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Box className={`labselect2`}>
                            <TextField
                              error={Boolean(
                                touched.vacation_leave && errors.vacation_leave
                              )}
                              helperText={
                                touched.vacation_leave && errors.vacation_leave
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.vacation_leave}
                              fullWidth
                              required
                              name="vacation_leave"
                              label="Vacation Leave Credit"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <Box className={`labselect2`}>
                            <TextField
                              error={Boolean(
                                touched.hazard_start_15 &&
                                  errors.hazard_start_15
                              )}
                              helperText={
                                touched.hazard_start_15 &&
                                errors.hazard_start_15
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.hazard_start_15}
                              fullWidth
                              required
                              name="hazard_start_15"
                              label="Hazard pay start-15"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Box className={`labselect2`}>
                            <TextField
                              error={Boolean(
                                touched.hazard_16_end && errors.hazard_16_end
                              )}
                              helperText={
                                touched.hazard_16_end && errors.hazard_16_end
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.hazard_16_end}
                              fullWidth
                              required
                              name="hazard_16_end"
                              label="Hazard pay 16-end"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Box>
                            <TextField
                              fullWidth
                              required
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              helperText={touched.password && errors.password}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              label="Enter your password"
                              variant="outlined"
                              type="password"
                            />
                          </Box>
                        </Grid>
                      </>
                    ) : null}

                    <Grid item xs={12} sm={12}>
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}

                      <Divider />

                      <Box my={2} display="flex">
                        <Box flexGrow={1} />

                        <Button
                          variant="contained"
                          color="default"
                          startIcon={<ClearIcon />}
                          onClick={() => close()}
                        >
                          Close
                        </Button>

                        <Box ml={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={<CheckIcon />}
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          Notify.noRecord()
        )
      ) : (
        Notify.loading()
      )}
    </>
  );
};

export default EditEmployeeInformation;
