import {
  Grid,
  Box,
  Paper,
  Card,
  CardHeader,
  CardContent,
  List,
  Typography,
  ListItemText,
  ListItem,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TextField,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import ReactToPrint from "react-to-print";

const IshiharaPatients = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [list, setList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  let printRef = React.useRef();

  const getPatientWithOrder = React.useCallback(async () => {
    let response = await axios.get("ishihara/patient/new-order", {
      params: {
        management_id: users.management_id,
      },
    });
    if (mounted.current) {
      setList(response.data);
    }
  }, [users, mounted]);

  useEffect(() => {
    getPatientWithOrder();
  }, [getPatientWithOrder]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Patients",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={`Patients`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardHeader title="Patient List" />
            <CardContent>
              {list.length > 0 ? (
                <List component="nav">
                  {list.map((data, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={() => {
                        setSelectedPatient(data);
                      }}
                    >
                      <ListItemText
                        primary={`${data.lastname}, ${data.firstname} ${
                          data.middle === null ? "" : data.middle
                        }`}
                        secondary={data.order_name}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box>
                  <Typography color={"secondary"}>No record found.</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={8}>
          {selectedPatient === null && (
            <Paper>
              <Box align="center" p={2}>
                <Typography color="secondary" variant="h4">
                  Select Patient
                </Typography>

                <Typography color="textSecondary" variant="h6">
                  Select patient to display order information
                </Typography>
              </Box>
            </Paper>
          )}

          {selectedPatient && (
            <RenderSelectedPatient data={selectedPatient} printRef={printRef} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default IshiharaPatients;

const RenderSelectedPatient = ({ data, printRef }) => (
  <Paper ref={printRef}>
    <Box p={2} align="center">
      <Box>
        <Typography variant="h5">BMCDC HEALTH CARE, INC.</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">
          DOOR 2 GIMENES BLDG., KM 5 BUHANGIN, DAVAO CITY
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">TEL. NO. (082) - 2854324</Typography>
      </Box>

      <Box mt={3}>
        <Typography variant="h6">
          <b> ISHIHARA TEST </b>
        </Typography>
      </Box>
    </Box>

    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box>
            <Typography>
              Name : {data.lastname}, {data.firstname}
            </Typography>
          </Box>
          <Box>
            <Typography>company : {data.company}</Typography>
          </Box>
          <Box>
            <Typography>
              company : {data.street} {data.barangay} {data.city}
            </Typography>
          </Box>
          <Box>
            <Typography>age : {Notify.calculateAge(data.birthday)}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box>
            <Typography>gender : {data.gender}</Typography>
          </Box>
          <Box>
            <Typography>Contact : {data.mobile}</Typography>
          </Box>
          <Box>
            <Typography>Referred By :</Typography>{" "}
          </Box>
          <Box>
            {" "}
            <Typography>Date : {data.order_date}</Typography>{" "}
          </Box>
        </Grid>
      </Grid>
    </Box>

    <Box p={2}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center"> Plate Nos. </TableCell>
              <TableCell align="center"> Normal Person </TableCell>
              <TableCell align="center">
                {" "}
                Person with red-green <br /> deficiencies{" "}
              </TableCell>
              <TableCell align="center">
                {" "}
                Person with total color <br /> blindness and weakness{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center"> 1 </TableCell>
              <TableCell align="center"> 12 </TableCell>
              <TableCell align="center"> 12</TableCell>
              <TableCell align="center"> 12 </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> 2 </TableCell>
              <TableCell align="center"> 8 </TableCell>
              <TableCell align="center"> 3</TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> 3 </TableCell>
              <TableCell align="center"> 6 </TableCell>
              <TableCell align="center"> 5</TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> 4 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 5 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 5 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 6 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 7 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 8 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 9 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 10 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 11 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 12 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 13 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 14 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 15 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 15 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 16 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 17 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 18 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 19 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"> 20 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> 21 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> </TableCell>
              <TableCell align="center"> </TableCell>
              <TableCell align="center">
                <TableRow>
                  <TableCell align="center">Protan</TableCell>
                  <TableCell align="center">Deutan</TableCell>
                </TableRow>
              </TableCell>
              <TableCell align="center"> </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> </TableCell>
              <TableCell align="center"> </TableCell>
              <TableCell align="center">
                <TableRow>
                  <TableCell align="center"> Strong </TableCell>
                  <TableCell align="center"> Mild </TableCell>
                  <TableCell align="center"> Strong </TableCell>
                  <TableCell align="center"> Mild </TableCell>
                </TableRow>
              </TableCell>
              <TableCell align="center"> </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> 22 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> 22 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> 23 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center"> 24 </TableCell>
              <TableCell align="center"> 29 </TableCell>
              <TableCell align="center"> 70 </TableCell>
              <TableCell align="center"> x </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    <Box p={2} mt={2} width={400}>
      <TextField label="Remarks" fullWidth />
    </Box>

    <Box p={2} mt={2} display="flex">
      <Box flexGrow={1}>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return (
              <Button
                className={"d-print-none"}
                variant="contained"
                color="primary"
              >
                Print
              </Button>
            );
          }}
          content={() => printRef.current}
        />
      </Box>
      <Box width={300} borderTop={1} align="center">
        SIGNATURE OF TECHNICIAN
      </Box>
    </Box>
  </Paper>
);
