import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";

const HepatitisEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/hepatitis-prof/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {info.ready && (
              <>
                <Box display="flex" my={2}>
                  <Box flexGrow={1}>
                    <Typography variant="body1" align="center">
                      <b> HEPATITIS PROFILE </b>
                    </Typography>
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />

                        <TableCell align="center">
                          <b> RESULT </b>
                        </TableCell>

                        <TableCell align="center">
                          <b> REFERENCE VALUES </b>
                        </TableCell>

                        <TableCell align="center">
                          <b> REMARKS </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hidden={info.data.hbsag_quali === null}>
                        <TableCell> HBsAG(Quali) </TableCell>

                        <TableCell>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="hbsag_quali"
                            defaultValue={
                              info.data.hbsag_quali === null
                                ? ""
                                : info.data.hbsag_quali
                            }
                          />
                        </TableCell>

                        <TableCell> Non Reactive </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hbsag_quali_remarks"
                              defaultValue={
                                info.data.hbsag_quali_remarks === null
                                  ? ""
                                  : info.data.hbsag_quali_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.antihbs_quali === null}>
                        <TableCell> Anti-HBs(Quali) </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbs_quali"
                              defaultValue={
                                info.data.antihbs_quali === null
                                  ? ""
                                  : info.data.antihbs_quali
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell> Reactive </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbs_quali_remarks"
                              defaultValue={
                                info.data.antihbs_quali_remarks === null
                                  ? ""
                                  : info.data.antihbs_quali_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.antihcv_quali === null}>
                        <TableCell> Anti-HCV(Quali) </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihcv_quali"
                              defaultValue={
                                info.data.antihcv_quali === null
                                  ? ""
                                  : info.data.antihcv_quali
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell> Non Reactive </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihcv_quali_remarks"
                              defaultValue={
                                info.data.antihcv_quali_remarks === null
                                  ? ""
                                  : info.data.antihcv_quali_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.hbsag_quanti === null}>
                        <TableCell> HBsAG(Quanti) </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hbsag_quanti"
                              defaultValue={
                                info.data.hbsag_quanti === null
                                  ? ""
                                  : info.data.hbsag_quanti
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell> {` > 2.0 (Reactive)`} </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hbsag_quanti_remarks"
                              defaultValue={
                                info.data.hbsag_quanti_remarks === null
                                  ? ""
                                  : info.data.hbsag_quanti_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.antihbs_quanti === null}>
                        <TableCell> Anti-HBs(Quanti) </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbs_quanti"
                              defaultValue={
                                info.data.antihbs_quanti === null
                                  ? ""
                                  : info.data.antihbs_quanti
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell> ABOVE 10 IU/L (Reactive) </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbs_quanti_remarks"
                              defaultValue={
                                info.data.antihbs_quanti_remarks === null
                                  ? ""
                                  : info.data.antihbs_quanti_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.hbeaag === null}>
                        <TableCell> HBeAg </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hbeaag"
                              defaultValue={
                                info.data.hbeaag === null
                                  ? ""
                                  : info.data.hbeaag
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell> Reactive : {` > 0.105`} </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hbeaag_remarks"
                              defaultValue={
                                info.data.hbeaag_remarks === null
                                  ? ""
                                  : info.data.hbeaag_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.antihbe === null}>
                        <TableCell> Anti-HBe </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbe"
                              defaultValue={
                                info.data.antihbe === null
                                  ? ""
                                  : info.data.antihbe
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell> Non Reactive : {` > 0.81`} </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbe_remarks"
                              defaultValue={
                                info.data.antihbe_remarks === null
                                  ? ""
                                  : info.data.antihbe_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.antihbc_igm === null}>
                        <TableCell> Anti-BHc(IgM) </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbc_igm"
                              defaultValue={
                                info.data.antihbc_igm === null
                                  ? ""
                                  : info.data.antihbc_igm
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell>
                          {" "}
                          Non Reactive :{" "}
                          {` > OR = 1.68; Reactive : < OR = 1.68 `}{" "}
                        </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbc_igm_remarks"
                              defaultValue={
                                info.data.antihbc_igm_remarks === null
                                  ? ""
                                  : info.data.antihbc_igm_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.antihav_igm === null}>
                        <TableCell> Anti-HAV(IgM) </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihav_igm"
                              defaultValue={
                                info.data.antihav_igm === null
                                  ? ""
                                  : info.data.antihav_igm
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell> Negative </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihav_igm_remarks"
                              defaultValue={
                                info.data.antihav_igm_remarks === null
                                  ? ""
                                  : info.data.antihav_igm_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.anti_havigm_igg === null}>
                        <TableCell> Anti-HAVIGM/IGG </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="anti_havigm_igg"
                              defaultValue={
                                info.data.anti_havigm_igg === null
                                  ? ""
                                  : info.data.anti_havigm_igg
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell> Negative </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="anti_havigm_igg_remarks"
                              defaultValue={
                                info.data.anti_havigm_igg_remarks === null
                                  ? ""
                                  : info.data.anti_havigm_igg_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.antihbc_iggtotal === null}>
                        <TableCell> Anti-HBc(IgG Total) </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbc_iggtotal"
                              defaultValue={
                                info.data.antihbc_iggtotal === null
                                  ? ""
                                  : info.data.antihbc_iggtotal
                              }
                            />
                          </Box>
                        </TableCell>

                        <TableCell> Reactive : {`<1.0`} </TableCell>

                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="antihbc_iggtotal_remarks"
                              defaultValue={
                                info.data.antihbc_iggtotal_remarks === null
                                  ? ""
                                  : info.data.antihbc_iggtotal_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default HepatitisEditForm;
