import React, { Fragment, useState, useEffect, useContext } from 'react';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import { Box, Typography, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { UsersData } from '../../ContextAPI';
import DeleteQtySpecificBatch from './DeleteQtySpecificBatch';
// import DeleteQtySpecificBatch from './DeleteQtySpecificBatch';

function DeleteProductBatches({ fetchInventoryList, selectedProdID, selectedProductName, selectedDescription }) {
    const [batches, setBatches] = useState([]);
    const [batchesReady, setBatchesReady] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [defaultView, setDefaultView] = useState('table-view-batches');
    const [selectedDataArray, setSelectedDataArray] = useState([]);
    const userData = useContext(UsersData);

    const getBatchesByProductID = () => {
        var params = { user_id: userData.users.user_id, management_id: userData.users.management_id, product_id: selectedProdID }
        Axios.get('pharmacy/get-batches-by-product-id', { params })
            .then((response) => {
                const data = response.data;
                setBatches(data);
                setBatchesReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleAddQtySpecId = (data) => {
        setSelectedDataArray(data);
        setDefaultView('add-qty-spec-id');
    }

    useEffect(() => {
        getBatchesByProductID()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            {
                defaultView === 'table-view-batches' ?
                    <TableContainer component={Box} >
                        <Box display="flex">
                            <Box mb={1}>
                                <Typography variant="h5" color="primary">
                                    {selectedProductName} - {selectedDescription}
                                </Typography>
                            </Box>
                        </Box>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"> Manufacture Date </TableCell>
                                    <TableCell align="center"> Batch Number </TableCell>
                                    <TableCell align="center"> Expiry Date </TableCell>
                                    <TableCell align="center"> Quantity </TableCell>
                                    <TableCell align="center"> Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    batchesReady ?
                                        batches.length > 0 ?
                                            batches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((data, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell > {data.manufacture_date} </TableCell>
                                                        <TableCell >{data.batch_no}</TableCell>
                                                        <TableCell align="right">{data.expiry_date}</TableCell>
                                                        <TableCell align="right">{data.quantity}</TableCell>
                                                        <TableCell align="center">
                                                            <DeleteIcon
                                                                color="secondary"
                                                                onClick={() => handleAddQtySpecId(data)}
                                                                className="pointer"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            : <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography variant="subtitle2" color="secondary"> No batch added </Typography>
                                                </TableCell>
                                            </TableRow>
                                        : <TableRow>
                                            <TableCell colSpan={5}>
                                                <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            component={"div"}
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={batches.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                    :
                    <Fragment>
                        <Box mb={2}>
                            <Typography variant="h5" color="primary">
                                {selectedProductName} - {selectedDescription}
                            </Typography>
                        </Box>
                        <DeleteQtySpecificBatch
                            selectedDataArray={selectedDataArray}
                            fetchInventoryList={() => fetchInventoryList()}
                            getBatchesByProductID={() => getBatchesByProductID()}
                            backDefaultView={() => setDefaultView('table-view-batches')}
                        />
                    </Fragment>
            }
        </Fragment>
    )

}

export default DeleteProductBatches;