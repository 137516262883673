import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Homepage from "../auth/Homepage";

const RouteHomepage = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/bmcdc/app" />}
          />
          <Route
            exact
            path="/bmcdc"
            component={() => <Redirect to="/bmcdc/app" />}
          />
          <Route exact path="/bmcdc/app" component={Homepage} />
          <Route render={() => <PageNotFound title="Page not found" />} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default RouteHomepage;
