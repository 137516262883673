import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Divider,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Send } from "react-feather";
import axios from "axios";
import { UsersData } from "src/ContextAPI";

const RadiologistPatientsUltraSoundDetails = ({ details, resetDisplay }) => {
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddUltraSoundResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("orderId", details.imaging_center_id);

    var error = [];

    if (
      formdata.get("result").trim() === "" ||
      formdata.get("result").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("result");
    }
    if (
      formdata.get("impression").trim() === "" ||
      formdata.get("impression").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("impression");
    }
    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsSubmitting(true);
      axios
        .post(
          "radiologist/patients/order-ultra-sound/order-saveresult",
          formdata
        )
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("new result");
            resetDisplay();
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {}, [details]);

  return (
    <form onSubmit={handleAddUltraSoundResult}>
      <Box className={`gtc-textfield-noborder`}>
        <Box mb={2}>
          <Typography variant="subtitle2" className={`gtc-uppercase`}>
            Order Details
          </Typography>
        </Box>
        <Divider light />
        <Box mt={2}>
          <Box mb={1}>
            <TextField
              fullWidth
              label="Patient"
              variant="outlined"
              value={details.patient_name}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={1}>
            <TextField
              fullWidth
              label="Gender"
              variant="outlined"
              value={
                details.patient_gender === null
                  ? "None"
                  : details.patient_gender
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={1}>
            <TextField
              fullWidth
              label="Birthday"
              variant="outlined"
              value={
                details.patient_birthday === null
                  ? "None"
                  : Notify.birthday(details.patient_birthday)
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={1}>
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              value={
                details.patient_address === null
                  ? "None"
                  : details.patient_address
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={1}>
            <TextField
              fullWidth
              label="Imaging Order"
              variant="outlined"
              value={details.imaging_order}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={1}>
            <TextField
              fullWidth
              label="Result"
              variant="outlined"
              name="result"
              rows={4}
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box mb={1}>
            <TextField
              fullWidth
              label="Impression"
              variant="outlined"
              name="impression"
              rows={4}
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box mb={1}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box flexGrow={1} />
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <Send />
                    )
                  }
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default RadiologistPatientsUltraSoundDetails;
