import React, { Fragment, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import axios from "axios";
import {
  KeyboardBackspace,
  SaveAltSharp,
  AddCircleOutline,
} from "@material-ui/icons";

const PatientTriageContactTracing = ({
  patient_id,
  getIncompleteReg,
  getPatientInformation,
  info,
}) => {
  const [sicknessArray, setSicknessArray] = useState([]);
  const [tempNew, setTempNew] = useState(null);

  const [addCTracing, setAddCTracing] = useState("latest-record");
  const [processSave, setProcessSave] = useState(false);

  const sickness = [
    "Dry cough",
    "Fever",
    "Shortness of breath",
    "Difficulty of breathing",
    "Sore throat",
    "Headache",
    "Nasal congestions",
    "Diarrhea",
    "Colds",
    "Fatigue or body malaise",
  ];

  const handleChangeSickness = (e) => {
    if (sicknessArray.includes(e.target.value)) {
      let removeOneArray = sicknessArray.filter(
        (item) => item !== e.target.value
      );
      setSicknessArray(removeOneArray);
    } else {
      setSicknessArray(sicknessArray.concat(e.target.value));
    }
  };

  const handleSaveNewCTracingForm = (e) => {
    e.preventDefault();
    e.persist();
    var error = [];
    var formdata = new FormData();
    formdata.set("patient_id", patient_id);
    formdata.append("sickness", sicknessArray);
    formdata.append("new_temp", tempNew);

    if (tempNew.length === 0) {
      error = "error";
      Notify.fieldRequired("temperature");
    }
    if (error.length > 0) {
      Notify.consoleLog("contract tracing form");
    } else {
      setProcessSave(true);
      axios
        .post("triage/add/contact-tracing/reviews", formdata)
        .then((response) => {
          const data = response.data;
          setProcessSave(false);
          if (data === "success") {
            setSicknessArray([]);
            setTempNew(null);
            setAddCTracing("latest-record");
            getPatientInformation();
            Notify.successRequest("add new contact tracing");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setProcessSave(false);
        });
    }
  };

  return (
    <Box component={Paper} variant="outlined" mt={2} p={2}>
      <Box>
        {info.ready ? (
          info.data ? (
            <Box>
              <Box my={3}>
                <Box mb={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box flexGrow={1}>
                      <Typography variant="h6" color="textSecondary">
                        Contact Tracing Form
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        startIcon={
                          addCTracing === "latest-record" ? (
                            <AddCircleOutline />
                          ) : (
                            <KeyboardBackspace />
                          )
                        }
                        color="primary"
                        variant="contained"
                        // disabled={info.data.latestCCRecord !== null}
                        onClick={() => {
                          setAddCTracing(
                            addCTracing === "add-ctracing"
                              ? "latest-record"
                              : "add-ctracing"
                          );
                          setSicknessArray([]);
                        }}
                      >
                        {addCTracing === "add-ctracing" ? "Back" : "New"}
                      </Button>
                    </Box>
                  </Box>
                </Box>

                {addCTracing === "latest-record" ? (
                  <Fragment>
                    {info.data.latestCCRecord !== null && (
                      <Box mb={1}>
                        <Typography variant="subtitle2" color="secondary">
                          This is the latest contact tracing recorded last{" "}
                          {Notify.createdAt(info.data.latestCCRecord)}
                        </Typography>
                      </Box>
                    )}
                    <Grid container spacing={1}>
                      {info.data.sickness !== null &&
                        sickness.map((data, index) =>
                          info.data.sickness.split(",").map(
                            (data2, index2) =>
                              Boolean(data2 === data) && (
                                <Grid key={index2} item xs={12} sm={6} md={4}>
                                  <Box>
                                    <FormControl component="fieldset">
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="gilad"
                                              defaultChecked={true}
                                            />
                                          }
                                          label={data}
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              )
                          )
                        )}
                      {info.data.temperature !== null && (
                        <Grid item xs={12} sm={12}>
                          <Box>
                            <TextField
                              defaultValue={info.data.temperature}
                              fullWidth
                              required
                              name="temp"
                              label="Temperature"
                              variant="outlined"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 10000,
                                  step: 0.01,
                                },
                              }}
                            />
                          </Box>
                        </Grid>
                      )}
                      {info.data.temperature === null &&
                        info.data.sickness === null && (
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {Notify.noRecord()}
                            </Box>
                          </Grid>
                        )}
                    </Grid>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Grid container spacing={1}>
                      {sickness.map((data, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4}>
                          <Box>
                            <FormControl component="fieldset">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="gilad"
                                      value={data}
                                      onChange={(e) => handleChangeSickness(e)}
                                    />
                                  }
                                  label={data}
                                />
                              </FormGroup>
                            </FormControl>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        required
                        name="temp"
                        label="Temperature"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 10000,
                            step: 0.01,
                          },
                        }}
                        onChange={(e) =>
                          setTempNew(
                            e.target.value === "" ? null : e.currentTarget.value
                          )
                        }
                      />
                    </Box>

                    <Box mb={2}>
                      <Box display="flex">
                        <Box flexGrow={1} />
                        <Box ml={1} hidden={addCTracing === "latest-record"}>
                          <Button
                            startIcon={<SaveAltSharp />}
                            color="primary"
                            variant="contained"
                            onClick={handleSaveNewCTracingForm}
                            disabled={tempNew === null || processSave}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Fragment>
                )}
              </Box>
            </Box>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>
    </Box>
  );
};

export default PatientTriageContactTracing;
