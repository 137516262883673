import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ThyroidProfileRef from "../references/Ref_ThyroidProfile";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ThyroidProfileOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Box flexGrow={1}>
              <Typography variant="h6" align="center">
                <b style={{ color: "#000" }}> THYROID PROFILE </b>
              </Typography>
            </Box>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        TEST
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        RESULT
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        REFERENCE VALUES
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        REMARKS
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {orderDetails.data.map((data, index) => (
                      <Fragment key={index}>
                        {data.t3 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                T3
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                {data.t3}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.t3()}
                            </TableCell>
                            <TableCell align="center">
                              <b style={{ color: "#000" }}>{data.t3_remarks}</b>
                            </TableCell>
                          </TableRow>
                        )}

                        {data.t4 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                T4
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                {data.t4}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.t4()}
                            </TableCell>
                            <TableCell align="center">
                              <b style={{ color: "#000" }}>{data.t4_remarks}</b>
                            </TableCell>
                          </TableRow>
                        )}

                        {data.tsh && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                TSH
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                {data.tsh}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.tsh()}
                            </TableCell>
                            <TableCell align="center">
                              <b style={{ color: "#000" }}>
                                {data.tsh_remarks}
                              </b>
                            </TableCell>
                          </TableRow>
                        )}

                        {data.ft4 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                FT4
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                {data.ft4}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.ft4()}
                            </TableCell>
                            <TableCell align="center">
                              <b style={{ color: "#000" }}>
                                {data.ft4_remarks}
                              </b>
                            </TableCell>
                          </TableRow>
                        )}

                        {data.ft3 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                FT3
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                {data.ft3}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {ThyroidProfileRef.ft3()}
                            </TableCell>
                            <TableCell align="center">
                              <b style={{ color: "#000" }}>
                                {data.ft3_remarks}
                              </b>
                            </TableCell>
                          </TableRow>
                        )}

                        {data.t3t4 && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                T3T4
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                {data.t3t4}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <b style={{ color: "#000" }}>none</b>
                            </TableCell>
                            <TableCell align="center">
                              <b style={{ color: "#000" }}>
                                {data.t3t4_remarks}
                              </b>
                            </TableCell>
                          </TableRow>
                        )}

                        {data.fht && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                className={`gtc-uppercase`}
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                FHT
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                style={{ color: "#000", fontWeight: "bolder" }}
                              >
                                {data.fht}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <b style={{ color: "#000" }}>none</b>
                            </TableCell>
                            <TableCell align="center">
                              <b style={{ color: "#000" }}>
                                {data.fht_remarks}
                              </b>
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box my={3} align="center">
                <Footer formheader={formheader} />
              </Box>
            </CardContent>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ThyroidProfileOrder;
