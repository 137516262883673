import React, { Fragment, useContext } from 'react';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import { Box, Grid, Button, FormHelperText, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import ClearIcon from '@material-ui/icons/HighlightOff';
import { PharmacyRoleAndId, UsersData } from '../../ContextAPI';

function AddProductModal({ fetchInventoryList, closeModal }) {
    const userData = useContext(UsersData);
    const userRAndI = useContext(PharmacyRoleAndId);

    const getFormData = (object) => {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    return (
        <Fragment>
            <Formik
                initialValues={{
                    user_id: userData.users.user_id,
                    username: userData.users.username,
                    management_id: userData.users.management_id,
                    pharmacy_id: userRAndI.userRoleAndId.pharmacy_id,
                    brand: '',
                    generic: '',
                    supplier: '',
                    invoice: '',
                    manufacture: '',
                    batch_no: '',
                    expiry: '',
                    unit: '',
                    qty: '',
                    msrp: '',
                    srp: '',
                    remarks: '',
                    password: '',
                }}
                validationSchema={Yup.object().shape({
                    brand: Yup.string().required(),
                    generic: Yup.string().required(),
                    supplier: Yup.string().required(),
                    invoice: Yup.string().required(),
                    batch_no: Yup.string().required(),
                    expiry: Yup.string().required(),
                    unit: Yup.string().required(),
                    qty: Yup.number().required(),
                    msrp: Yup.string().required(),
                    srp: Yup.string().required(),
                    remarks: Yup.string().required(),
                    password: Yup.string().required()
                })}
                onSubmit={async (values, {
                    setErrors,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        const request = await Axios.post('pharmacy/new-product-save', getFormData(values))
                        if (request.data === 'pass-invalid') {
                            setErrors({ password: "Password doesn't matched" });
                            Notify.customToast("Invalid Password", "Password doesn't matched")
                        }
                        if (request.data === 'product-exist') {
                            setErrors({ brand: 'Product is already added' });
                            Notify.customToast('Product Exist', 'Product is already added')
                        }
                        // if (request.data === 'invoice-exist') {
                        //     setErrors({ invoice: 'Invoice is already recorded' });
                        //     Notify.customToast('Invoice Exist', 'Invoice is already recorded')
                        // }
                        if (request.data === 'success') {
                            Notify.successRequest('new product')
                            fetchInventoryList();
                            resetForm();
                            setSubmitting(true);
                            closeModal();
                        }
                    } catch (error) {
                        const message = error.message || 'Something went wrong';
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    resetForm
                }) => (
                    <form
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Box flexGrow={1}>
                            <Grid container spacing={2}>
                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.brand && errors.brand)}
                                            helperText={touched.brand && errors.brand}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.brand}
                                            fullWidth
                                            required
                                            name="brand"
                                            label="Brand"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.generic && errors.generic)}
                                            helperText={touched.generic && errors.generic}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.generic}
                                            fullWidth
                                            required
                                            name="generic"
                                            label="Generic"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.supplier && errors.supplier)}
                                            helperText={touched.supplier && errors.supplier}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.supplier}
                                            fullWidth
                                            required
                                            name="supplier"
                                            label="Supplier"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.invoice && errors.invoice)}
                                            helperText={touched.invoice && errors.invoice}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.invoice}
                                            fullWidth
                                            required
                                            name="invoice"
                                            label="Invoice Number"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Manufacture Date"
                                            error={Boolean(touched.manufacture && errors.manufacture)}
                                            helperText={touched.manufacture && errors.manufacture}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.manufacture}
                                            name="manufacture"
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.batch_no && errors.batch_no)}
                                            helperText={touched.batch_no && errors.batch_no}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.batch_no}
                                            fullWidth
                                            required
                                            multiline
                                            name="batch_no"
                                            label="Batch Number"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Expiry Date"
                                            error={Boolean(touched.expiry && errors.expiry)}
                                            helperText={touched.expiry && errors.expiry}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.expiry}
                                            name="expiry"
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.unit && errors.unit)}
                                            helperText={touched.unit && errors.unit}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.unit}
                                            fullWidth
                                            required
                                            multiline
                                            name="unit"
                                            label="Unit"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={4} item>
                                    <Box mb={1}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={Boolean(touched.qty && errors.qty)}
                                            helperText={touched.qty && errors.qty}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.qty}
                                            name="qty"
                                            label="Quantity"
                                            variant="outlined"
                                            type="number"
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={4} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.msrp && errors.msrp)}
                                            helperText={touched.msrp && errors.msrp}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.msrp}
                                            fullWidth
                                            required
                                            type="number"
                                            name="msrp"
                                            label="MSRP"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={4} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.srp && errors.srp)}
                                            helperText={touched.srp && errors.srp}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.srp}
                                            fullWidth
                                            required
                                            type="number"
                                            name="srp"
                                            label="SRP"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={12} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.remarks && errors.remarks)}
                                            helperText={touched.remarks && errors.remarks}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.remarks}
                                            fullWidth
                                            required
                                            multiline
                                            name="remarks"
                                            label="Remarks"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={12} item>
                                    <Box mb={1}>
                                        <TextField
                                            fullWidth
                                            required
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            name="password"
                                            label="Enter your password"
                                            variant="outlined"
                                            type="password"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        {errors.submit && (
                            <Box mt={2} >
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}

                        <Box mt={2} mb={2} display="flex">
                            <Box flexGrow={1} />

                            <Button
                                variant="contained"
                                color="default"
                                onClick={() => closeModal()}
                                startIcon={<ClearIcon />}
                            >
                                Close
                            </Button>

                            <Box ml={2}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CheckIcon />}
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </Fragment>
    )

}

export default AddProductModal;