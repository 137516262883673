import { Box, Grid, Typography, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import Footer from "src/laboratory/print/Footer";

const PapsmearOrder = ({ order_id, patient_id, formheader }) => {
  const { users } = React.useContext(UsersData);

  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_papsmear");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_hematology table error", error);
    }
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box mb={2}>
            <Typography variant="h6" align="center">
              <strong> Cervical Vaginal Cytology Report </strong>
            </Typography>
          </Box>
          <Box>
            {details.map((data, index) => (
              <>
              <Box key={index}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ textDecoration: "underline" }}
                          >
                            SPECIMEN
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">
                            FIXED SLIDES FROM:
                          </Typography>
                        </Box>
                        <Box>
                          <Box align="left">
                            {parseFloat(data.cervix) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            CERVIX
                          </Box>
                          <Box align="left">
                            {parseFloat(data.vagina) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            VAGINA
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              style={{ textDecoration: "underline" }}
                            >
                              Adequacy of the specimen
                            </Typography>
                          </Box>

                          <Box align="left">
                            {parseFloat(data.satisfactory_evaluation) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Satisfactory for evaluation
                          </Box>
                          <Box align="left">
                            {parseFloat(data.satisfactory_no_lmp) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Satisfactory for evaluation but limited by no LMP
                          </Box>
                          <Box align="left">
                            {parseFloat(data.unsatisfactory) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Unsatisfactory for evaluation due to
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              style={{ textDecoration: "underline" }}
                            >
                              General Categorization
                            </Typography>
                          </Box>
                          <Box align="left">
                            {parseFloat(data.with_normal_limits) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Within Normal Limits
                          </Box>
                          <Box align="left">
                            {parseFloat(data.benign_cell_changes) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Benign Cellular Changes
                          </Box>
                          <Box align="left">
                            {parseFloat(data.epithelial_cell_abno) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Epithelial Cell Abnormalities
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder" }}
                            >
                              Benign Cellular Changes
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{ textDecoration: "underline" }}
                            >
                              Infection
                            </Typography>
                          </Box>
                          <Box align="left">
                            {parseFloat(data.infection_trichomonas) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Trichomonas vaginalis
                          </Box>
                          <Box align="left">
                            {parseFloat(data.infection_fungi) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Fungi consistent with Candida spp.
                          </Box>
                          <Box align="left">
                            {parseFloat(data.infection_predominance) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Predominance of cocobacilli consistent with shift of
                            vaginal flora
                          </Box>
                          <Box align="left">
                            {parseFloat(data.infection_cellular) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Cellular changes associated with hempes simples
                            virus
                          </Box>
                          <Box align="left">
                            {parseFloat(data.infection_others) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Others
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              style={{ textDecoration: "underline" }}
                            >
                              Reactive
                            </Typography>
                          </Box>
                          <Box align="left">
                            {parseFloat(data.reactive_inflammation) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Inflammation (includes typical repair)
                          </Box>
                          <Box align="left">
                            {parseFloat(data.reactive_atrophy) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Atrophy with inflammation
                          </Box>
                          <Box align="left">
                            {parseFloat(data.reactive_follicular) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Follicular cervicitis
                          </Box>
                          <Box align="left">
                            {parseFloat(data.reactive_radiation) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Radiation effect
                          </Box>
                          <Box align="left">
                            {parseFloat(data.reactive_iud) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            IUD effect
                          </Box>
                          <Box align="left">
                            {parseFloat(data.reactive_des) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            DES changes
                          </Box>
                          <Box align="left">
                            {parseFloat(data.reactive_others) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Other Presence of RBC
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            <Typography variant="subtitle2">
                              Maturation Index
                            </Typography>
                          </Box>
                          <Box>
                            <Box display="flex">
                              <Typography variant="subtitle2">
                                <b> Suggest: </b>
                              </Typography>
                              <Box ml={1}>
                                <Typography
                                  variant="subtitle2"
                                  style={{ textDecoration: "underline" }}
                                >
                                  {data.maturation_suggest}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ textDecoration: "underline" }}
                          >
                            CLINICAL DATE
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6}>
                            <Box display="flex">
                              <Typography variant="subtitle2">AGE:</Typography>
                              <Box ml={1}>
                                <Typography
                                  variant="subtitle2"
                                  style={{ textDecoration: "underline" }}
                                >
                                  {checkResult(data.age)}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <Box display="flex">
                              <Typography variant="subtitle2">LMP:</Typography>
                              <Box ml={1}>
                                <Typography
                                  variant="subtitle2"
                                  style={{ textDecoration: "underline" }}
                                >
                                  {checkResult(data.lmp)}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box display="flex">
                          <Typography variant="subtitle2">HORMONES:</Typography>
                          <Box ml={1}>
                            <Typography
                              variant="subtitle2"
                              style={{ textDecoration: "underline" }}
                            >
                              {checkResult(data.hormones)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Typography variant="subtitle2">IUD:</Typography>
                          <Box ml={1}>
                            <Typography
                              variant="subtitle2"
                              style={{ textDecoration: "underline" }}
                            >
                              {checkResult(data.iud)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Box mt={4} mb={1}>
                            <Typography variant="subtitle2">
                              Epithelial Cell Abnormalities
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder" }}
                            >
                              Aquamous Cells
                            </Typography>
                          </Box>
                          <Box align="left">
                            {parseFloat(data.squamous_typical) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            A typical squamous cells of undetermined
                            significance ASCUC: qualify favor reactive
                          </Box>
                          <Box align="left">
                            {parseFloat(data.squamous_low) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Low grade squamous intraepithelial lesion
                          </Box>
                          <Box ml={5}>
                            <Box align="left">
                              {parseFloat(data.squamous_low_hpv) === 1 ? (
                                <IconButton>
                                  <CheckBox color="primary" />
                                </IconButton>
                              ) : (
                                <IconButton>
                                  <CheckBoxOutlineBlank />
                                </IconButton>
                              )}
                              HPV associated changes
                            </Box>
                            <Box align="left">
                              {parseFloat(data.squamous_low_mild) === 1 ? (
                                <IconButton>
                                  <CheckBox color="primary" />
                                </IconButton>
                              ) : (
                                <IconButton>
                                  <CheckBoxOutlineBlank />
                                </IconButton>
                              )}
                              Mild Dysplasia
                            </Box>
                          </Box>
                          <Box align="left">
                            {parseFloat(data.squamous_high) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            High grade squamous intraepithelial lesion
                          </Box>
                          <Box ml={5}>
                            <Box align="left">
                              {parseFloat(data.squamous_high_moderate) === 1 ? (
                                <IconButton>
                                  <CheckBox color="primary" />
                                </IconButton>
                              ) : (
                                <IconButton>
                                  <CheckBoxOutlineBlank />
                                </IconButton>
                              )}
                              Moderate Dysplasia
                            </Box>
                            <Box align="left">
                              {parseFloat(data.squamous_high_severe) === 1 ? (
                                <IconButton>
                                  <CheckBox color="primary" />
                                </IconButton>
                              ) : (
                                <IconButton>
                                  <CheckBoxOutlineBlank />
                                </IconButton>
                              )}
                              Severe Dysplasia
                            </Box>
                            <Box align="left">
                              {parseFloat(data.squamous_high_carcinoma) ===
                              1 ? (
                                <IconButton>
                                  <CheckBox color="primary" />
                                </IconButton>
                              ) : (
                                <IconButton>
                                  <CheckBoxOutlineBlank />
                                </IconButton>
                              )}
                              Carcinoma in situ
                            </Box>
                          </Box>
                          <Box align="left">
                            {parseFloat(data.squamous_cell) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Squamous cell carcinoma
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder" }}
                            >
                              Giandulare cells
                            </Typography>
                          </Box>
                          <Box align="left">
                            {parseFloat(data.giandulare_endomentrial) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Endometrial cells cytologically benign in a
                            postmenopausal
                          </Box>
                          <Box align="left">
                            {parseFloat(data.giandulare_typical) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            A typical giandulae cells of undetermined
                            significance (AGUS): quality favor reactive or
                            pre-or malignant process
                          </Box>
                          <Box align="left">
                            {parseFloat(data.giandulare_endocervical) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Endocervical adenocarcinoma
                          </Box>
                          <Box align="left">
                            {parseFloat(data.giandulare_endometrial) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Endometrial adenocarcinoma
                          </Box>
                          <Box align="left">
                            {parseFloat(data.giandulare_extraiterine) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Extralterine adenocarcinoma
                          </Box>
                          <Box align="left">
                            {parseFloat(data.giandulare_adenocarcinoma) ===
                            1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Adenocarcinoma no otherwise specified :{" "}
                            <b style={{ textDecoration: "underline" }}>
                              {data.giandulare_adeno_specify !== null &&
                                data.giandulare_adeno_specify}
                            </b>
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            <Typography variant="subtitle2">
                              Other malignant neoplasms:{" "}
                              <b style={{ textDecoration: "underline" }}>
                                {" "}
                                {data.other_malignant !== null &&
                                  data.other_malignant}
                              </b>
                            </Typography>
                            <Box>
                              <Typography variant="subtitle2">
                                Hormonal evaluation (applies to vaginal smear
                                only)
                              </Typography>
                            </Box>
                          </Box>
                          <Box align="left">
                            {parseFloat(data.hormonal_compatible) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Compatible with age and history
                          </Box>
                          <Box align="left">
                            {parseFloat(data.hormonal_incompatible) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Incompatible with age and history:{" "}
                            <b style={{ textDecoration: "underline" }}>
                              {" "}
                              {data.hormonal_incompatible_spec !== null &&
                                data.hormonal_incompatible_spec}
                            </b>
                          </Box>
                          <Box align="left">
                            {parseFloat(data.hormonal_non_possible) === 1 ? (
                              <IconButton>
                                <CheckBox color="primary" />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <CheckBoxOutlineBlank />
                              </IconButton>
                            )}
                            Incompatible with age and history:{" "}
                            <b style={{ textDecoration: "underline" }}>
                              {" "}
                              {data.non_possible_specify !== null &&
                                data.non_possible_specify}
                            </b>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Box display="flex" my={3}>
                    <Box flexGrow={1} />
                    <Box>
                      <Box align="center" width={250}>
                        <Box mt={5}>
                          <Typography
                            className={`gtc-uppercase`}
                            variant="subtitle2"
                          >
                            OSCAR P. GRAGEDA, M.D, FPSP, APCP
                          </Typography>
                          <Box borderTop={1}>
                            <b> Anatomic and Clinic Pathologist </b>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Footer formheader={formheader} />
              </>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default PapsmearOrder;
