import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  TablePagination,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import IsMountedRef from "src/utils/IsMountedRef";

const ProductInventory = () => {
  const mounted = IsMountedRef();
  const [list, setList] = useState({ data: [], ready: false });
  const { users } = useContext(UsersData);

  const [search, setSearch] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getProductInventory = React.useCallback(async () => {
    let response = await axios.get("accounting/product/inventory-list", {
      params: {
        management_id: users.management_id,
      },
    });

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [users, mounted]);

  useEffect(() => {
    getProductInventory();
  }, [getProductInventory]);

  const searchable = list.data.filter((data) => {
    return (
      data.product_name.toLowerCase().indexOf(search.trim().toLowerCase()) !==
        -1 ||
      data.product_generic
        .toLowerCase()
        .indexOf(search.trim().toLowerCase()) !== -1
    );
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Inventory",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={"Product Inventory"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Paper elevation={0}>
            <Box p={2}>
              <Box display="flex">
                <Box flexGrow={1} />
                <Box>
                  <TextField
                    label="Search product"
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> Product ID </TableCell>
                      <TableCell> Product </TableCell>
                      <TableCell> Generic </TableCell>
                      <TableCell> Unit </TableCell>
                      <TableCell> In Qty </TableCell>
                      <TableCell> Out Qty</TableCell>
                      <TableCell> Rem. Qty </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.ready ? (
                      searchable.length > 0 ? (
                        searchable
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data, index) => (
                            <TableRow key={index}>
                              <TableCell> {data.product_id} </TableCell>
                              <TableCell> {data.product_name} </TableCell>
                              <TableCell> {data.product_generic} </TableCell>
                              <TableCell> {data.unit} </TableCell>
                              <TableCell align="right">
                                {data._qty_in}
                              </TableCell>
                              <TableCell align="right">
                                {data._qty_out}
                              </TableCell>
                              <TableCell align="right">
                                {data._qty_remaining}
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Typography color="primary"> loading...</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={searchable.length}
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </TableContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductInventory;
