import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, InputAdornment, Button, FormHelperText } from '@material-ui/core';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PharmacyRoleAndId, UsersData } from '../../ContextAPI';
import { CancelOutlined } from '@material-ui/icons';

const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

function SalesReportPrint({ close }) {
    const userData = useContext(UsersData);
    const userRAndId = useContext(PharmacyRoleAndId);
    const [totalSalesssuu, setTotalSalesssuu] = useState(0);
    const [sales, setSales] = useState([]);
    const [salesReady, setSalesReady] = useState(false);
    const [search, setSearch] = useState('');

    const calculateTotalSales = (data) => {
        var total = 0;
        for (let i = 0; i < data.length; i++) {
            total += parseFloat(data[i].sum_all_total_quantity) * parseFloat(data[i].productSrp);
        }
        return total;
    }

    const fetchSales = () => {
        var params = { user_id: userData.users.user_id, management_id: userData.users.management_id, pharmacy_id: userRAndId.userRoleAndId.pharmacy_id }
        Axios.get('pharmacy/get-sales-list', { params })
            .then((response) => {
                const data = response.data;
                setSales(data);
                setSalesReady(true);
                setTotalSalesssuu(calculateTotalSales(data))
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        fetchSales()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const searchable = sales.filter((data) => {
        return data.product.toLowerCase().indexOf(search.trim()) !== -1
    })

    return (
        <Fragment>
            <Grid item sm={12} xs={12}>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Box>
                            <TableContainer component={Box} >
                                <Box display="flex">
                                    <Box flexGrow={1}>
                                        <Formik
                                            initialValues={{
                                                user_id: userData.users.user_id,
                                                management_id: userData.users.management_id,
                                                date_from: '',
                                                date_to: '',
                                                sales_from: 'all',
                                            }}
                                            validationSchema={Yup.object().shape({ 
                                                date_from: Yup.string().required('Date from is required.'),
                                                date_to: Yup.string().required('Date to is required.'),
                                            })}
                                            onSubmit={async (values, {
                                                setErrors,
                                                setSubmitting,
                                                resetForm
                                            }) => {
                                                try {
                                                    const request = await Axios.post('pharmacy/get-filter-by-date', getFormData(values))
                                                    const data = request.data;
                                                    setSales(data);
                                                    setSalesReady(true);
                                                    // Notify.successRequest('filter')
                                                    setTotalSalesssuu(calculateTotalSales(data)) 
                                                } catch (error) {
                                                    const message = error.message || 'Something went wrong';
                                                    setErrors({ submit: message });
                                                    setSubmitting(false);
                                                }
                                            }}
                                        >
                                            {({
                                                errors,
                                                handleBlur,
                                                handleChange,
                                                handleSubmit,
                                                isSubmitting,
                                                touched,
                                                values
                                            }) => (
                                                <form
                                                    noValidate
                                                    onSubmit={handleSubmit}
                                                >
                                                    <Box display="flex" className={'d-print-none'} mt={2}>
                                                        <Box>
                                                            <TextField
                                                                required
                                                                label="Sales"
                                                                error={Boolean(touched.sales_from && errors.sales_from)}
                                                                helperText={touched.sales_from && errors.sales_from}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.sales_from}
                                                                name="sales_from" 
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                select
                                                                SelectProps={{
                                                                    native: true
                                                                }}
                                                            >
                                                                <option value="all"> All </option>
                                                                <option value="local"> Local </option>
                                                                <option value="online"> Online </option>
                                                            </TextField>
                                                        </Box>
                                                        
                                                        <Box mx={2}>
                                                            <TextField
                                                                required
                                                                label="Date From"
                                                                error={Boolean(touched.date_from && errors.date_from)}
                                                                helperText={touched.date_from && errors.date_from}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.date_from}
                                                                name="date_from"
                                                                variant="outlined"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </Box>

                                                        <Box ml={2}>
                                                            <TextField
                                                                required
                                                                label="Date To"
                                                                error={Boolean(touched.date_to && errors.date_to)}
                                                                helperText={touched.date_to && errors.date_to}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.date_to}
                                                                name="date_to"
                                                                variant="outlined"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </Box>

                                                        {errors.submit && (
                                                            <Box mt={3}>
                                                                <FormHelperText error>
                                                                    {errors.submit}
                                                                </FormHelperText>
                                                            </Box>
                                                        )}

                                                        <Box ml={2}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                style={{
                                                                    height: 50
                                                                }}
                                                            >
                                                                <SearchIcon style={{ fontSize : 30 }} />
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </form>
                                            )}
                                        </Formik>
                                    </Box>

                                    <Box mb={1}>
                                        <TextField
                                            className={'d-print-none'}
                                            label="Search brand"
                                            variant="outlined"
                                            margin="dense"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell> Date </TableCell>
                                            <TableCell> Brand </TableCell>
                                            <TableCell> Generic </TableCell>
                                            <TableCell> Sales From </TableCell>
                                            <TableCell> Qty </TableCell> 
                                            <TableCell> Amount </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            salesReady ?
                                                sales.length > 0 ?
                                                    searchable.map((data, index) => (
                                                        <TableRow key={index}> 
                                                            <TableCell>
                                                                {Notify.dateTimeConvert(data.created_at)}
                                                            </TableCell>
                                                            <TableCell> {data.product} </TableCell>
                                                            <TableCell>{data.description}</TableCell>
                                                            <TableCell align="center">{data.sales_from}</TableCell>
                                                            <TableCell align="right"> {data.sum_all_total_quantity} </TableCell>
                                                            <TableCell align="right">
                                                                {Notify.convertToNumber(data.sum_spec_total_quantity)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    : <TableRow>
                                                        <TableCell colSpan={6} align="center">
                                                            <Typography variant="subtitle2" color="secondary"> No sale found </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                : <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        <Typography variant="subtitle2" color="primary"> Loading... </Typography>
                                                    </TableCell>
                                                </TableRow>
                                        }
                                        {
                                            sales.length > 0 ?
                                                <TableRow>
                                                    <TableCell colSpan={5} align="right"> Total: </TableCell>
                                                    <TableCell align="right"> {Notify.convertToNumber(totalSalesssuu)} </TableCell>
                                                </TableRow>
                                                : null
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display="flex" mt={2}>
                                <Box flexGrow={1} />

                                <Box mr={2}>
                                    <Button
                                        variant="outlined"
                                        className={'d-print-none'} 
                                        startIcon={<CancelOutlined />}
                                        color="inherit"
                                        onClick={() => close()}
                                    >
                                        Cancel
                                    </Button>
                                </Box>

                                <Button
                                    variant="outlined"
                                    className={'d-print-none'}
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    onClick={() => window.print()}
                                >
                                    Print
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment >
    )

}

export default SalesReportPrint;