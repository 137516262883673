import {
  Box,
  Dialog,
  TextField,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  // CardMedia,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "../../notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import { Fragment } from "react";
import Specimen from "./Specimen";

// const imageLocation = process.env.REACT_APP_API_IMAGE;

const CBCOrderDetails = ({
  formheader,
  order,
  getLabHemaOrder,
  resetDisplay,
}) => {
  const { users } = React.useContext(UsersData);
  const history = useHistory();
  const [savebtnDisabled, setSavebtnDisabled] = useState(true);
  const [orderDetails, setOrderDetails] = useState({
    data: null,
    ready: false,
  });

  const [pendingDialog, setPendingDialog] = useState(false);
  const [processDialog, setProcessDialog] = useState({
    data: null,
    dialog: false,
  });

  const [pendingSubmitting, setPendingSubmitting] = useState(false);
  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const [orderItems, setOrderItems] = useState([]);

  const getLabOrderDetails = () => {
    var params = { user_id: users.user_id, trace_number: order.trace_number };
    Axios.get("laboratory/order/ordernew-cbc/details", { params })
      .then((response) => {
        const data = response.data;
        setOrderDetails({
          data: data,
          ready: true,
        });

        checkIfSaveBtnIsEnabled(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const checkIfSaveBtnIsEnabled = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        setSavebtnDisabled(false);
      }
    }
  };

  useEffect(() => {
    getLabOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", order.trace_number);

    var error = [];
    setResultSubmitting(true);
    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      Axios.post("laboratory/order/ordernew-cbc/save-process-result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("order result added. Redirect to receipt.");
            setTimeout(() => {
              history.push(
                `/bmcdc/app/laboratory/record/print/order/${order.trace_number}`
              );
            }, 5000);

            setSavebtnDisabled(true);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setResultSubmitting(false));
    }
  };

  const handlePendingOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("order_id", order.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setPendingSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-hemathology/save-setpending",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabHemaOrder();
            resetDisplay();
            setPendingDialog(false);
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setPendingSubmitting(false));
    }
  };

  const handleProcessOrder = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("order_id", processDialog.data.order_id);
    formdata.set("trace_number", processDialog.data.trace_number);

    var error = [];
    setProcessSubmitting(true);

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      let response = await Axios.post(
        "laboratory/cbc/order-setprocess/custom-qty",
        formdata
      );

      if (response.data.message === "reagent-error") {
        Notify.customToast("Reagent Not Error", "Reagent/items not available.");
      }

      if (response.data.message === "pass-invalid") {
        Notify.fieldInvalid("password");
      }

      if (response.data.message === "success") {
        Notify.successRequest("process");
        setProcessDialog({ data: null, dialog: false });
        getLabOrderDetails();
      }
    }

    setTimeout(() => {
      setProcessSubmitting(false);
    }, 2000);
  };

  const handleProcessSelectedOrder = async (data) => {
    let response = await Axios.get(
      "laboratory/items/laborder/list-available-items",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
          order_id: data.order_id,
        },
      }
    );

    setOrderItems(response.data);

    setProcessDialog({ data: data, dialog: true });
  };

  return (
    <>
      <form onSubmit={handleSaveResult}>
        <Card elevation={0}>
          <Box display="flex" justifyContent="center">
            <Box>
              <CardHeader
                component={Box}
                align="center"
                title={formheader && formheader.name}
                subheader={
                  <Box>
                    <Typography>{formheader && formheader.address}</Typography>
                    <Typography>
                      {formheader && formheader.contact_number}
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>

          <CardContent>
            {/* paitent information */}
            <Box display="flex">
              <Box flexGrow={1} mb={2}>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      PATIENT NAME:{" "}
                    </Typography>
                    {`${order.lastname}, ${order.firstname} ${
                      order.middle === null ? "" : order.middle
                    }`}
                  </Typography>
                </Box>

                <Box display="flex" mb={2}>
                  <Box>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        AGE:{" "}
                      </Typography>
                      {order.birthday === null
                        ? "none"
                        : Notify.calculateAge(order.birthday)}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        GENDER:
                      </Typography>
                      {order.gender === null ? "none" : order.gender}
                    </Typography>
                  </Box>
                </Box>

                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      PATIENT ADDRESS:{" "}
                    </Typography>
                    {`${order.street} ${order.barangay} ${order.city} `}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="caption" className="font-weight-bold">
                    PATIENT CONDITION:{" "}
                  </Typography>
                  {order.patient_condition === null
                    ? " none"
                    : order.patient_condition}
                </Box>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      DATE:{" "}
                    </Typography>
                    {Notify.createdAt(new Date().toLocaleString())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            <Specimen
              patient_id={order.patient_id}
              trace_number={order.trace_number}
            />
          </CardContent>

          <CardContent>
            {orderDetails.ready ? (
              orderDetails.data.length > 0 ? (
                orderDetails.data.map((data, index) => (
                  <Fragment key={index}>
                    <Box>
                      <TextField
                        margin="dense"
                        fullWidth
                        name="order_id[]"
                        label="order_id"
                        hidden
                        value={data.order_id}
                      />

                      <TextField
                        margin="dense"
                        fullWidth
                        name="patient_id"
                        label="Result"
                        hidden
                        value={data.patient_id}
                      />

                      <TextField
                        margin="dense"
                        fullWidth
                        name="doctors_id"
                        label="doctor_id"
                        hidden
                        value={data.doctor_id === null ? "" : data.doctor_id}
                      />
                    </Box>

                    {!Boolean(parseInt(data.cbc)) && (
                      <Box>
                        <Box display="flex" my={2}>
                          <Box flexGrow={1}>
                            <Typography variant="body1" align="center">
                              <b> HEMATOLOGY CBC </b>
                            </Typography>
                          </Box>

                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={Boolean(parseInt(data.is_processed))}
                              onClick={() => handleProcessSelectedOrder(data)}
                            >
                              Process
                            </Button>
                          </Box>
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.is_processed))}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="WBC"
                                  margin="dense"
                                  fullWidth
                                  name="wbc[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="LYM"
                                  margin="dense"
                                  fullWidth
                                  name="lym[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MID"
                                  margin="dense"
                                  fullWidth
                                  name="mid[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="NEUT"
                                  margin="dense"
                                  fullWidth
                                  name="neut[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RBC"
                                  margin="dense"
                                  fullWidth
                                  name="rbc[]"
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="HGB"
                                  margin="dense"
                                  fullWidth
                                  name="hgb[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="HTC"
                                  margin="dense"
                                  fullWidth
                                  name="hct[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCV"
                                  margin="dense"
                                  fullWidth
                                  name="mcv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCH"
                                  margin="dense"
                                  fullWidth
                                  name="mch[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCHC"
                                  margin="dense"
                                  fullWidth
                                  name="mchc[]"
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RDW-SD"
                                  margin="dense"
                                  fullWidth
                                  name="rdw_sd[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RDW-CV"
                                  margin="dense"
                                  fullWidth
                                  name="rdw_cv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MPV"
                                  margin="dense"
                                  fullWidth
                                  name="mpv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PDW"
                                  margin="dense"
                                  fullWidth
                                  name="pdw[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PCT"
                                  margin="dense"
                                  fullWidth
                                  name="pct[]"
                                />
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2}>
                            <TextField
                              variant="standard"
                              label="P-LCR"
                              margin="dense"
                              fullWidth
                              name="p_lcr[]"
                            />
                          </Box>

                          <Box mt={2}>
                            <TextField
                              variant="standard"
                              label="Remarks"
                              margin="dense"
                              fullWidth
                              name="remarks[]"
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {!Boolean(parseInt(data.cbc_platelet)) && (
                      <Box my={3}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography variant="body1" align="center">
                              <b> HEMATOLOGY CBC WITH PLATELET </b>
                            </Typography>
                          </Box>

                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={Boolean(parseInt(data.is_processed))}
                              onClick={() => handleProcessSelectedOrder(data)}
                            >
                              Process
                            </Button>
                          </Box>
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.is_processed))}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="WBC"
                                  margin="dense"
                                  fullWidth
                                  name="wbc[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="LYM"
                                  margin="dense"
                                  fullWidth
                                  name="lym[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MID"
                                  margin="dense"
                                  fullWidth
                                  name="mid[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="NEUT"
                                  margin="dense"
                                  fullWidth
                                  name="neut[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RBC"
                                  margin="dense"
                                  fullWidth
                                  name="rbc[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="HGB"
                                  margin="dense"
                                  fullWidth
                                  name="hgb[]"
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="HTC"
                                  margin="dense"
                                  fullWidth
                                  name="hct[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCV"
                                  margin="dense"
                                  fullWidth
                                  name="mcv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCH"
                                  margin="dense"
                                  fullWidth
                                  name="mch[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCHC"
                                  margin="dense"
                                  fullWidth
                                  name="mchc[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RDW-SD"
                                  margin="dense"
                                  fullWidth
                                  name="rdw_sd[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RDW-CV"
                                  margin="dense"
                                  fullWidth
                                  name="rdw_cv[]"
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MPV"
                                  margin="dense"
                                  fullWidth
                                  name="mpv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PDW"
                                  margin="dense"
                                  fullWidth
                                  name="pdw[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PCT"
                                  margin="dense"
                                  fullWidth
                                  name="pct[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PLT"
                                  margin="dense"
                                  fullWidth
                                  name="plt[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="P-LCR"
                                  margin="dense"
                                  fullWidth
                                  name="p_lcr[]"
                                />
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2}>
                            <TextField
                              variant="standard"
                              label="Remarks"
                              margin="dense"
                              fullWidth
                              name="remarks[]"
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Fragment>
                ))
              ) : (
                <Typography color="secondary" variant="subtitle2">
                  No record found.
                </Typography>
              )
            ) : (
              <Typography color="primary" variant="subtitle2">
                please wait...
              </Typography>
            )}
          </CardContent>

          <Box display="flex" m={1}>
            <Box flexGrow={1} />
            <Box>
              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={savebtnDisabled}
                  startIcon={
                    resultSubmitting && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                >
                  Save Result
                </Button>
              </CardActions>
            </Box>
          </Box>
        </Card>
      </form>

      {/* set as pending dialog */}
      <Dialog
        open={pendingDialog}
        onClose={() => setPendingDialog(false)}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as pending</DialogTitle>
        <form onSubmit={handlePendingOrder}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                rows={5}
                fullWidth
                name="reason"
                label={`Pending Reason`}
                variant="standard"
                margin="dense"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="standard"
                margin="dense"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setPendingDialog(false)}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={pendingSubmitting}
              startIcon={
                pendingSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
            >
              pending
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* set as process dialog */}
      <Dialog
        open={processDialog.dialog}
        onClose={() => setProcessDialog({ data: null, dialog: false })}
        disableBackdropClick
        PaperComponent={DraggableDialog}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="draggable-handle">Process order</DialogTitle>
        <form onSubmit={handleProcessOrder}>
          <DialogContent dividers>
            {orderItems.length > 0
              ? orderItems.map((data, index) => (
                  <Box key={index} mb={2}>
                    <Box>
                      <TextField
                        fullWidth
                        name="item_id[]"
                        value={data.item_id}
                        variant="outlined"
                        hidden
                      />
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        required
                        label={`${data.description} qty to process`}
                        name="qty[]"
                        variant="outlined"
                        defaultValue={1}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // InputProps={{
                        //   inputProps: {
                        //     min: 0,
                        //     max: data._total_qty_available,
                        //   },
                        // }}
                        type="number"
                      />
                    </Box>
                  </Box>
                ))
              : "No reagent/item found."}

            <Box mt={2}>
              <TextField
                fullWidth
                required
                defaultValue="ok"
                name="remarks"
                label={`Remarks`}
                variant="outlined"
              />
            </Box>

            <Box mt={2}>
              <TextField
                fullWidth
                required
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcessDialog({ data: null, dialog: false })}
              startIcon={<HighlightOffIcon />}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={processSubmitting}
              startIcon={
                processSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              process
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CBCOrderDetails;
