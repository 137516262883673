import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Zoom,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
} from "@material-ui/core";
import { CancelOutlined, HighlightOff, Search } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { PharmacyRoleAndId, UsersData } from "../../ContextAPI";
import Container from "../../layout/Container";
import Notify from "../../notification/Notify";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import MoreVert from "@material-ui/icons/MoreVert";
import PostAddIcon from "@material-ui/icons/PostAdd";
import DeleteIcon from "@material-ui/icons/Delete";
import AddProductModal from "./AddProductModal";
import AddProductBatches from "./AddProductBatches";
import DeleteProductBatches from "./DeleteProductBatches";

const Inventory = () => {
  const userRandI = useContext(PharmacyRoleAndId);
  const userData = useContext(UsersData);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [invetoryData, setInvetoryData] = useState({
    data: [],
    ready: false,
  });
  const [addProductdialog, setaddProductdialog] = useState(false);
  const [addStockDialog, setAddStockDialog] = useState(false);
  const [deleteStockDialog, setDeleteStockDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProdID, setSelectedProdID] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const fetchInventoryList = () => {
    var params = {
      user_id: userData.users.user_id,
      management_id: userData.users.management_id,
    };
    axios
      .get("pharmacy/get-product-list", { params })
      .then((response) => {
        const data = response.data;
        setInvetoryData({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    setSelectedProdID(event.currentTarget.id);
    setSelectedProductName(event.currentTarget.getAttribute("pname"));
    setSelectedUnit(event.currentTarget.getAttribute("unit"));
    setSelectedDescription(event.currentTarget.getAttribute("description"));
    setSelectedSupplier(event.currentTarget.getAttribute("supplier"));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchInventoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = invetoryData.data.filter((data) => {
    return (
      data.product.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.description.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "inventory",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="Inventory"
      >
        <Grid container>
          <Grid item sm={12} xs={12}>
            <Box display="flex">
              <Box flexGrow={1}>
                {userRandI.userRoleAndId.ready && (
                  <Box mt={1}>
                    <Button
                      hidden={userRandI.userRoleAndId.role !== "admin"}
                      color="primary"
                      onClick={() => setaddProductdialog(true)}
                      startIcon={<AddCircleOutline />}
                    >
                      Product
                    </Button>
                  </Box>
                )}

                {console.log(
                  ";cnsolelog in invernteoryg,",
                  userRandI.userRoleAndId
                )}
              </Box>
              <Box mb={1}>
                <TextField
                  label="Search brand/generic"
                  variant="outlined"
                  margin="dense"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Paper>
              <Box m={1}>
                <TableContainer component={Box}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"> BRAND </TableCell>
                        <TableCell align="center"> GENERIC </TableCell>
                        <TableCell align="center"> MSRP </TableCell>
                        <TableCell align="center"> SRP </TableCell>
                        <TableCell align="center"> RATE </TableCell>
                        <TableCell align="center"> AVAILABLE </TableCell>
                        <TableCell align="center"> ACTION </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invetoryData.ready ? (
                        invetoryData.data.length > 0 ? (
                          searchable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <TableRow key={index}>
                                <TableCell> {data.product} </TableCell>
                                <TableCell>{data.description}</TableCell>
                                <TableCell align="right">
                                  {data.unit_price}
                                </TableCell>
                                <TableCell align="right">{data.srp}</TableCell>
                                <TableCell align="right">
                                  {(
                                    100 -
                                    (parseFloat(data.unit_price) /
                                      parseFloat(data.srp)) *
                                      100
                                  ).toFixed(2)}
                                  %
                                </TableCell>
                                <TableCell align="right">
                                  {data.sum_all_quantity}
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    aria-label="delete"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    supplier={data.supplier}
                                    description={data.description}
                                    unit={data.unit}
                                    pname={data.product}
                                    id={data.product_id}
                                    onClick={handleClick}
                                  >
                                    <MoreVert fontSize="small" />
                                  </IconButton>
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                  >
                                    <MenuItem
                                      onClick={() => setAddStockDialog(true)}
                                    >
                                      {" "}
                                      <PostAddIcon color="primary" /> Add{" "}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => setDeleteStockDialog(true)}
                                    >
                                      {" "}
                                      <DeleteIcon color="secondary" /> Delete{" "}
                                    </MenuItem>
                                  </Menu>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Typography variant="subtitle2" color="secondary">
                                {" "}
                                No product added{" "}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            <Typography variant="subtitle2" color="primary">
                              {" "}
                              Loading...{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component={"div"}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={invetoryData.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          open={deleteStockDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Zoom}
          transitionDuration={1000}
          disableBackdropClick={true}
          maxWidth="md"
          fullWidth
        >
          <Box display="flex">
            <Box flexGrow={1}>
              <DialogTitle id="alert-dialog-title">
                {"Delete Product Batches"}
              </DialogTitle>
            </Box>
            <IconButton
              className={"d-print-none"}
              color="secondary"
              onClick={() => setDeleteStockDialog(false)}
            >
              <HighlightOff fontSize="large" />
            </IconButton>
          </Box>
          <DialogContent dividers>
            <DeleteProductBatches
              fetchInventoryList={() => fetchInventoryList()}
              closeModal={() => setDeleteStockDialog(false)}
              selectedProdID={selectedProdID}
              selectedProductName={selectedProductName}
              selectedUnit={selectedUnit}
              selectedDescription={selectedDescription}
              selectedSupplier={selectedSupplier}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={addStockDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Zoom}
          transitionDuration={1000}
          disableBackdropClick={true}
          maxWidth="md"
          fullWidth
        >
          <Box display="flex">
            <Box flexGrow={1}>
              <DialogTitle id="alert-dialog-title">
                {"Add Product Batches"}
              </DialogTitle>
            </Box>
            <IconButton
              color="secondary"
              onClick={() => setAddStockDialog(false)}
            >
              <HighlightOff fontSize="large" />
            </IconButton>
          </Box>

          <DialogContent dividers>
            <AddProductBatches
              fetchInventoryList={() => fetchInventoryList()}
              closeModal={() => setAddStockDialog(false)}
              selectedProdID={selectedProdID}
              selectedProductName={selectedProductName}
              selectedUnit={selectedUnit}
              selectedDescription={selectedDescription}
              selectedSupplier={selectedSupplier}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={addProductdialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Zoom}
          transitionDuration={1000}
          disableBackdropClick={true}
          maxWidth="md"
          fullWidth
        >
          <Box display="flex">
            <Box flexGrow={1}>
              <DialogTitle id="alert-dialog-title">
                {"Add New Product"}
              </DialogTitle>
            </Box>
            <Button onClick={() => setaddProductdialog(false)}>
              <CancelOutlined fontSize="large" color="secondary" />
            </Button>
          </Box>

          <DialogContent dividers>
            <AddProductModal
              fetchInventoryList={() => fetchInventoryList()}
              closeModal={() => setaddProductdialog(false)}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </Fragment>
  );
};

export default Inventory;
