import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Edit } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const BankContactList = ({ bankID }) => {
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contact, setContact] = useState({ data: [], ready: false });
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [editDialog, setEditDialog] = useState(false);

  const handleEditContact = (data) => {
    setEditDialog(true);
    setSelectedPerson(data.contact_person);
    setSelectedNumber(data.contact_number);
    setSelectedPosition(data.contact_position);
    setSelectedContactId(data.cbc_id);
  };

  const handleEmptySelectedContact = () => {
    setEditDialog(false);
    setSelectedPerson(null);
    setSelectedNumber(null);
    setSelectedPosition(null);
    setSelectedContactId(null);
  };

  const getAllContactListById = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      bank_id: bankID,
    };
    try {
      let response = await axios.get("accounting/get/contact-list", { params });
      const data = response.data;
      setContact({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users, bankID]);

  const handleEditSpecContact = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("cbc_id", selectedContactId);

    var error = [];

    if (
      formdata.get("contact_person").length === 0 ||
      formdata.get("contact_person").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Contact person");
    }
    if (
      formdata.get("contact_number").length === 0 ||
      formdata.get("contact_number").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Contact number");
    }
    if (
      formdata.get("contact_position").length === 0 ||
      formdata.get("contact_position").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Contact position");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/edit/contact-info", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getAllContactListById();
            handleEmptySelectedContact();
            e.target.reset();
            Notify.successRequest("edit contact");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    getAllContactListById();
  }, [getAllContactListById, bankID]);
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Contact Person</TableCell>
              <TableCell align="center">Contact #</TableCell>
              <TableCell align="center">Position</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contact.ready ? (
              contact.data.length > 0 ? (
                contact.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.contact_person}</TableCell>
                    <TableCell> {data.contact_number} </TableCell>
                    <TableCell>{data.contact_position}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        onClick={() => handleEditContact(data)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="secondary">No record found.</Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography color="primary">
                    Loading, please wait...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Dialog
          open={editDialog}
          fullWidth
          maxWidth={"xs"}
          onClose={handleEmptySelectedContact}
        >
          <DialogTitle>Edit Contact</DialogTitle>
          <DialogContent>
            <form onSubmit={handleEditSpecContact}>
              <Box>
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Box mb={1}>
                        <TextField
                          fullWidth
                          name="contact_person"
                          label="Contact Person"
                          variant="outlined"
                          defaultValue={
                            selectedPerson !== null ? selectedPerson : ""
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box mb={1}>
                        <TextField
                          fullWidth
                          name="contact_number"
                          label="Contact Number"
                          variant="outlined"
                          defaultValue={
                            selectedNumber !== null ? selectedNumber : ""
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box mb={1}>
                        <TextField
                          fullWidth
                          name="contact_position"
                          label="Contact Position"
                          variant="outlined"
                          defaultValue={
                            selectedPosition !== null ? selectedPosition : ""
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={2} mb={1} display="flex">
                  <Box flexGrow={1} />
                  <Button
                    variant="contained"
                    color="default"
                    type="reset"
                    startIcon={<HighlightOff />}
                    onClick={handleEmptySelectedContact}
                  >
                    Close
                  </Button>

                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <CheckCircleOutline />
                        )
                      }
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </TableContainer>
    </>
  );
};

export default BankContactList;
