import React, { Fragment, useState } from "react";
import Container from "src/layout/Container";
import {
  Grid,
  Paper,
  Divider,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  List,
  ListItem,
  Avatar,
  ListItemText,
  TablePagination,
  TextField,
  InputAdornment,
  Typography,
  ListItemIcon,
  makeStyles,
  Button,
  Dialog,
  Zoom,
  DialogTitle,
} from "@material-ui/core";
// import EditIcon from '@material-ui/icons/Edit';
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";
// import PersonIcon from '@material-ui/icons/Person';
import { Search, Sync } from "@material-ui/icons";
import PayrollEmployeesInfo from "./PayrollEmployeesInfo";
import PayrollPayslip from "./PayrollPayslip";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const PayrollDashboard = () => {
  const classes = useStyles();
  const [list, setList] = useState({ data: [], ready: false });
  const [employee, setEmployee] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState("all-payroll");
  const [selectedName, setSelectedName] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [payrollDialog, setPayrollDialog] = useState(false);

  const { users } = React.useContext(UsersData);

  const getAllActiveEmployee = React.useCallback(() => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("hmis/get-all-active", { params })
      .then((res) => {
        const data = res.data;
        setList({ data: data, ready: true });
      })
      .catch((err) => {
        console.log("error : ", err);
        // Notify.requestError(err);
      });
  }, [users]);

  const getAllEmployee = React.useCallback(() => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("hmis/get-all-employee", { params })
      .then((res) => {
        const data = res.data;
        setEmployee({ data: data, ready: true });
      })
      .catch((err) => {
        console.log("error : ", err);
        // Notify.requestError(err);
      });
  }, [users]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const searchable = employee.data.filter((data) => {
    return data._account_name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  React.useEffect(() => {
    getAllActiveEmployee();
    getAllEmployee();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllActiveEmployee, getAllEmployee]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "payroll",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="Payroll"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Box component={Paper} variant="outlined" borderRadius={4} p={2}>
              <Box mb={2}>
                <Typography color="primary" variant="subtitle2">
                  EMPLOYEE LIST
                </Typography>
              </Box>
              <Divider />
              <List component="div">
                <Box mb={1}>
                  <TextField
                    label="Search for employee"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {employee.ready
                  ? employee.data.length > 0
                    ? searchable
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                          <ListItem
                            key={index}
                            button
                            className="mb-2"
                            onClick={() => {
                              setSelectedName(data._account_name);
                              setSelectedEmployee(data.user_id);
                              setSelectedType(data.type);
                            }}
                            selected={selectedEmployee === data.user_id}
                          >
                            <ListItemIcon>
                              <Avatar
                                className={classes.primary}
                                src={
                                  data.type === "HIS-Accounting"
                                    ? data._image_accounting !== null
                                      ? `${imageLocation}accounting/${data._image_accounting}`
                                      : `No image`
                                    : data.type === "HIS-Billing"
                                    ? data._image_billing !== null
                                      ? `${imageLocation}billing/${data._image_billing}`
                                      : `No image`
                                    : data.type === "HIS-Cashier"
                                    ? data._image_cashier !== null
                                      ? `${imageLocation}cashier/${data._image_cashier}`
                                      : `No image`
                                    : data.type === "HIS-Doctor"
                                    ? data._image_doctors !== null
                                      ? `${imageLocation}doctor/${data._image_doctors}`
                                      : `No image`
                                    : data.type === "HIS-Haptech"
                                    ? data._image_haptech !== null
                                      ? `${imageLocation}haptech/${data._image_haptech}`
                                      : `No image`
                                    : data.type === "HIS-Hr"
                                    ? data._image_hr !== null
                                      ? `${imageLocation}hr/${data._image_hr}`
                                      : `No image`
                                    : data.type === "HIS-Imaging"
                                    ? data._image_imaging !== null
                                      ? `${imageLocation}imaging/${data._image_imaging}`
                                      : `No image`
                                    : data.type === "HIS-Ishihara"
                                    ? data._image_isihihara !== null
                                      ? `${imageLocation}isihihara/${data._image_isihihara}`
                                      : `No image`
                                    : data.type === "HIS-Laboratory"
                                    ? data._image_laboratory !== null
                                      ? `${imageLocation}laboratory/${data._image_laboratory}`
                                      : `No image`
                                    : data.type === "HIS-Pharmacy"
                                    ? data._image_pharmacy !== null
                                      ? `${imageLocation}pharmacy/${data._image_pharmacy}`
                                      : `No image`
                                    : data.type === "HIS-Radiologist"
                                    ? data._image_radiologist !== null
                                      ? `${imageLocation}radiologist/${data._image_radiologist}`
                                      : `No image`
                                    : data.type === "HIS-Registration"
                                    ? data._image_registration !== null
                                      ? `${imageLocation}registration/${data._image_registration}`
                                      : `No image`
                                    : data.type === "HIS-Stockroom"
                                    ? data._image_stockroom !== null
                                      ? `${imageLocation}stockroom/${data._image_stockroom}`
                                      : `No image`
                                    : data.type === "HIS-Triage"
                                    ? data._image_triage !== null
                                      ? `${imageLocation}triage/${data._image_triage}`
                                      : `No image`
                                    : data.type === "HIS-Warehouse"
                                    ? data._image_warehouse !== null
                                      ? `${imageLocation}warehouse/${data._image_warehouse}`
                                      : `No image`
                                    : "No image"
                                }
                                alt=""
                              />
                            </ListItemIcon>
                            <ListItemText
                              className={`gtc-capitalize`}
                              primary={`${data._account_name}`}
                            />
                          </ListItem>
                        ))
                    : Notify.noRecord()
                  : Notify.loading()}
              </List>
              <TablePagination
                component={"div"}
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={searchable.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Box component={Paper} variant="outlined" p={2}>
              {selectedEmployee === "all-payroll" && (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <strong> Name </strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong> Time In </strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong> Time Out </strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {list.ready &&
                          (list.data.length > 0 ? (
                            list.data
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((data, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell>{data._account_name}</TableCell>
                                    <TableCell align="center">
                                      {Notify.dateTimeConvert(data.timein)}
                                    </TableCell>
                                    <TableCell align="center">
                                      {data.timeout !== null
                                        ? Notify.dateTimeConvert(data.timeout)
                                        : ""}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          ) : (
                            <TableRow>
                              <TableCell align="center" colSpan={3}>
                                <Typography
                                  color="secondary"
                                  variant="subtitle2"
                                >
                                  No record found.
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component={"div"}
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={list.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              )}
              {selectedEmployee !== "all-payroll" && (
                <>
                  <Box mb={2} display="flex">
                    <Typography color="primary" variant="subtitle2">
                      {selectedName}'s Daily Time Record
                    </Typography>
                  </Box>
                  <Divider />
                  <PayrollEmployeesInfo
                    employee_id={selectedEmployee}
                    type={selectedType}
                  />
                </>
              )}
            </Box>
            {selectedEmployee !== "all-payroll" && (
              <Box
                mt={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box flexGrow={1} />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Sync />}
                  onClick={() => setPayrollDialog(true)}
                >
                  Generate Payslip
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
        <Dialog
          open={payrollDialog}
          onClose={() => setPayrollDialog(false)}
          fullScreen
          TransitionComponent={Zoom}
          transitionDuration={800}
        >
          <DialogTitle className="d-print-none">Payslip Generate</DialogTitle>
          <PayrollPayslip
            employee_id={selectedEmployee}
            selectedName={selectedName}
            close={() => setPayrollDialog(false)}
          />
        </Dialog>
      </Container>
    </Fragment>
  );
};

export default PayrollDashboard;
