import React, { useEffect, useState, useContext } from "react";
import {
  Paper,
  Box,
  Typography,
  Collapse,
  IconButton,
  TablePagination,
  useTheme,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import { MinusCircle, PlusCircle as PlusCircleIcon } from "react-feather";
import PsychologyAudiometric from "./graph/PsychologyAudiometric";
import PsychologyIshihara from "./graph/PsychologyIshihara";
import PsychologyNeurology from "./graph/PsychologyNeurology";

const PsychologyPaidList = ({ connection, patient_id }) => {
  const theme = useTheme();
  const [paid, setPaid] = useState({ data: [], ready: false });
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const { users } = useContext(UsersData);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const getPaidPsychologyOrder = () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    axios
      .post("doctor/patient/psychology/order/paid-list", formdata)
      .then((res) => {
        const data = res.data;
        setPaid({ data, ready: true });
      })
      .catch((er) => Notify.requestError(er));
  };

  const handleSelectedTraceNumber = (trace_number) => {
    if (selectedOrderId === trace_number) {
      console.log("trace number currently selected");
      return false;
    } else {
      setSelectedOrderId(trace_number);
    }
  };

  useEffect(() => {
    getPaidPsychologyOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  const applyPagination = (order, page, limit) => {
    return order.slice(page * limit, page * limit + limit);
  };

  const paginatedOrder = applyPagination(paid.data, page, rowsPerPage);

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" color="textSecondary">
          Paid Order List
        </Typography>
      </Box>

      {paid.ready
        ? paid.data.length > 0
          ? paginatedOrder.map((data, index) => (
              <Paper elevation={2} key={index} component={Box} mb={2} p={2}>
                <Box display="flex">
                  <Box flexGrow={1} mt={1}>
                    <Typography variant="h6">
                      {Notify.dateTimeConvert(data.created_at)}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() =>
                        handleSelectedTraceNumber(data.trace_number)
                      }
                    >
                      {selectedOrderId === data.trace_number ? (
                        <MinusCircle color={theme.palette.error.main} />
                      ) : (
                        <PlusCircleIcon color={theme.palette.primary.main} />
                      )}
                    </IconButton>
                  </Box>
                </Box>

                {selectedOrderId === data.trace_number && (
                  <Collapse in={selectedOrderId === data.trace_number}>
                    {selectedOrderId && (
                      <Box>
                        <PsychologyAudiometric
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <PsychologyIshihara
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <PsychologyNeurology
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                      </Box>
                    )}
                  </Collapse>
                )}
              </Paper>
            ))
          : Notify.noRecord()
        : Notify.loading()}

      <TablePagination
        labelRowsPerPage="List"
        rowsPerPageOptions={[5, 20, 50, 100]}
        component="div"
        count={paid.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {/* printable order by orderid and patient id */}
      {/* {Boolean(printableOrder.order_id) && Boolean(printableOrder.labmngId) && (
        <AdmissionPrintOrder
          labownerid={printableOrder.labmngId}
          order_id={printableOrder.order_id}
          patient_id={patient_id}
          close={() => setPrintableOrder({ order_id: null, labmngId: null })}
        />
      )} */}
    </>
  );
};

export default PsychologyPaidList;
