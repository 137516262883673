import {
  Grid,
  Box,
  Paper,
  List,
  Typography,
  ListItemText,
  ListItem,
  TextField,
  InputAdornment,
  Avatar,
  makeStyles,
  Collapse,
  ListItemIcon,
  CardMedia,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import { blue } from "@material-ui/core/colors";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SearchIcon from "@material-ui/icons/Search";
import AudiometryOrder from "../order/AudiometryOrder";
import IshiharaOrder from "../order/IshiharaOrder";
import NeurologyOrder from "../order/NeurologyOrder";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const PsychologyPatients = () => {
  const classes = useStyles();
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [list, setList] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [formheader, setFormHeader] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState({
    patient_id: null,
    order_count: null,
    category: "",
  });

  const getPatientWithOrder = React.useCallback(async () => {
    let response = await axios.get("psychology/patient/new-order", {
      params: {
        management_id: users.management_id,
      },
    });
    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [users, mounted]);

  const getLabFormHeader = React.useCallback(() => {
    var params = { management_id: users.management_id };
    axios
      .get("laboratory/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users]);

  useEffect(() => {
    getPatientWithOrder();
    getLabFormHeader();
  }, [getPatientWithOrder, getLabFormHeader]);

  const searchable = list.data.filter((data) => {
    return (
      data.patient_name.toLowerCase().indexOf(search.trim().toLowerCase()) !==
      -1
    );
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Patients",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={`Patients`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Paper component={Box} variant="outlined" p={2}>
            <Box mb={2}>
              <Typography color="textPrimary" variant="subtitle2">
                PATIENTS LIST
              </Typography>
            </Box>

            <Box my={2}>
              {list.ready ? (
                list.data.length > 0 ? (
                  <List component="div">
                    <>
                      <Box mb={1}>
                        <TextField
                          label="Search"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      {searchable.map((data, index) => {
                        return (
                          <Fragment key={index}>
                            <ListItem
                              button
                              hidden={!Boolean(parseInt(data.order_count))}
                              onClick={() => {
                                setSelectedPatient({
                                  ...selectedPatient,
                                  patient_id: data.pid,
                                  order_count: data.order_count,
                                });
                              }}
                              selected={selectedPatient.patient_id === data.pid}
                            >
                              <ListItemIcon>
                                {data.patient_image === null ? (
                                  <Avatar className={classes.primary}>
                                    {data.patient_name.charAt().toUpperCase()}
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    className={classes.primary}
                                    src={`${imageLocation}patients/${data.patient_image}`}
                                    alt=""
                                  />
                                )}
                              </ListItemIcon>

                              <ListItemText
                                className={`gtc-capitalize`}
                                primary={data.patient_name}
                                primaryTypographyProps={{
                                  color:
                                    selectedPatient.patient_id === data.pid
                                      ? "primary"
                                      : "inherit",
                                }}
                              />
                            </ListItem>

                            <Collapse
                              in={selectedPatient.patient_id === data.pid}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={!Boolean(parseInt(data.count_audio))}
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "audiometry",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category ===
                                        "audiometry"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>

                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category ===
                                        "audiometry"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Audiometry"}
                                  />
                                </ListItem>

                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={
                                    !Boolean(parseInt(data.count_ishihara))
                                  }
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "ishihara",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category === "ishihara"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>

                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category === "ishihara"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Ishihara"}
                                  />
                                </ListItem>

                                <ListItem
                                  button
                                  className={classes.nested}
                                  hidden={!Boolean(parseInt(data.count_neuro))}
                                  onClick={() =>
                                    setSelectedPatient({
                                      ...selectedPatient,
                                      category: "neuro-exam",
                                    })
                                  }
                                >
                                  <ListItemIcon>
                                    <DoubleArrowIcon
                                      color={
                                        selectedPatient.category ===
                                        "neuro-exam"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </ListItemIcon>

                                  <ListItemText
                                    style={{
                                      color:
                                        selectedPatient.category ===
                                        "neuro-exam"
                                          ? blue[500]
                                          : "",
                                    }}
                                    primary={"Neuro Examination"}
                                  />
                                </ListItem>
                              </List>
                            </Collapse>
                          </Fragment>
                        );
                      })}
                    </>
                  </List>
                ) : (
                  <Box>{Notify.noRecord()}</Box>
                )
              ) : (
                <Box>{Notify.loading()}</Box>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={9}>
          {selectedPatient.category === "" && <RenderSelectPatient />}
          {selectedPatient.patient_id !== null &&
            selectedPatient.category === "audiometry" && (
              <AudiometryOrder
                patient_id={selectedPatient.patient_id}
                formheader={formheader}
                getPatientWithOrder={getPatientWithOrder}
                order_count={selectedPatient.order_count}
              />
            )}
          {selectedPatient.patient_id !== null &&
            selectedPatient.category === "ishihara" && (
              <IshiharaOrder
                patient_id={selectedPatient.patient_id}
                formheader={formheader}
                getPatientWithOrder={getPatientWithOrder}
                order_count={selectedPatient.order_count}
              />
            )}

          {selectedPatient.patient_id !== null &&
            selectedPatient.category === "neuro-exam" && (
              <NeurologyOrder
                patient_id={selectedPatient.patient_id}
                formheader={formheader}
                getPatientWithOrder={getPatientWithOrder}
                order_count={selectedPatient.order_count}
              />
            )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PsychologyPatients;

const RenderSelectPatient = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 270 }}
    />
    <Typography color="secondary" variant="h3">
      NO PATIENT SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select patient in the list to view details.
    </Typography>
  </Box>
);

// const RenderSelectedPatient = ({ data, printRef }) => (
//   <Paper ref={printRef}>
//     <Box p={2} align="center">
//       <Box>
//         <Typography variant="h5">BMCDC HEALTH CARE, INC.</Typography>
//       </Box>
//       <Box>
//         <Typography variant="subtitle2">
//           DOOR 2 GIMENES BLDG., KM 5 BUHANGIN, DAVAO CITY
//         </Typography>
//       </Box>
//       <Box>
//         <Typography variant="subtitle2">TEL. NO. (082) - 2854324</Typography>
//       </Box>

//       <Box mt={3}>
//         <Typography variant="h6">
//           <b> ISHIHARA TEST </b>
//         </Typography>
//       </Box>
//     </Box>

//     <Box p={2}>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={6}>
//           <Box>
//             <Typography>
//               Name : {data.lastname}, {data.firstname}
//             </Typography>
//           </Box>
//           <Box>
//             <Typography>company : {data.company}</Typography>
//           </Box>
//           <Box>
//             <Typography>
//               company : {data.street} {data.barangay} {data.city}
//             </Typography>
//           </Box>
//           <Box>
//             <Typography>age : {Notify.calculateAge(data.birthday)}</Typography>
//           </Box>
//         </Grid>

//         <Grid item xs={12} sm={6}>
//           <Box>
//             <Typography>gender : {data.gender}</Typography>
//           </Box>
//           <Box>
//             <Typography>Contact : {data.mobile}</Typography>
//           </Box>
//           <Box>
//             <Typography>Referred By :</Typography>{" "}
//           </Box>
//           <Box>
//             {" "}
//             <Typography>Date : {data.order_date}</Typography>{" "}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>

//     <Box p={2}>
//       <TableContainer>
//         <Table size="small">
//           <TableHead>
//             <TableRow>
//               <TableCell align="center"> Plate Nos. </TableCell>
//               <TableCell align="center"> Normal Person </TableCell>
//               <TableCell align="center">
//                 {" "}
//                 Person with red-green <br /> deficiencies{" "}
//               </TableCell>
//               <TableCell align="center">
//                 {" "}
//                 Person with total color <br /> blindness and weakness{" "}
//               </TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <TableRow>
//               <TableCell align="center"> 1 </TableCell>
//               <TableCell align="center"> 12 </TableCell>
//               <TableCell align="center"> 12</TableCell>
//               <TableCell align="center"> 12 </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> 2 </TableCell>
//               <TableCell align="center"> 8 </TableCell>
//               <TableCell align="center"> 3</TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> 3 </TableCell>
//               <TableCell align="center"> 6 </TableCell>
//               <TableCell align="center"> 5</TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> 4 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 5 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 5 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 6 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 7 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 8 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 9 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 10 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 11 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 12 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 13 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 14 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 15 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 15 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 16 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 17 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 18 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 19 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell align="center"> 20 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> 21 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> </TableCell>
//               <TableCell align="center"> </TableCell>
//               <TableCell align="center">
//                 <TableRow>
//                   <TableCell align="center">Protan</TableCell>
//                   <TableCell align="center">Deutan</TableCell>
//                 </TableRow>
//               </TableCell>
//               <TableCell align="center"> </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> </TableCell>
//               <TableCell align="center"> </TableCell>
//               <TableCell align="center">
//                 <TableRow>
//                   <TableCell align="center"> Strong </TableCell>
//                   <TableCell align="center"> Mild </TableCell>
//                   <TableCell align="center"> Strong </TableCell>
//                   <TableCell align="center"> Mild </TableCell>
//                 </TableRow>
//               </TableCell>
//               <TableCell align="center"> </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> 22 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> 22 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> 23 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>

//             <TableRow>
//               <TableCell align="center"> 24 </TableCell>
//               <TableCell align="center"> 29 </TableCell>
//               <TableCell align="center"> 70 </TableCell>
//               <TableCell align="center"> x </TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>

//     <Box p={2} mt={2} width={400}>
//       <TextField label="Remarks" fullWidth />
//     </Box>

//     <Box p={2} mt={2} display="flex">
//       <Box flexGrow={1}>
//         <ReactToPrint
//           trigger={() => {
//             // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
//             // to the root node of the returned component as it will be overwritten.
//             return (
//               <Button
//                 className={"d-print-none"}
//                 variant="contained"
//                 color="primary"
//               >
//                 Print
//               </Button>
//             );
//           }}
//           content={() => printRef.current}
//         />
//       </Box>
//       <Box width={300} borderTop={1} align="center">
//         SIGNATURE OF TECHNICIAN
//       </Box>
//     </Box>
//   </Paper>
// );
