import React, { Fragment, useContext, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Button,
} from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const ProcessToAddResult = ({
  selectedResult,
  getAllPatientAlreadyRead,
  close,
}) => {
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEditSaveResult = (e) => {
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("management_id", users.management_id);
    formdata.append("imaging_center_id", selectedResult.data.imaging_center_id);

    var error = [];
    if (
      formdata.get("new_result").length === 0 ||
      formdata.get("new_result").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new result");
    }

    if (
      formdata.get("new_impression").length === 0 ||
      formdata.get("new_impression").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new impression");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("imaging/save/new-flow/edited-result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getAllPatientAlreadyRead();
            close();
            e.target.reset();
            Notify.successRequest("edit result");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <Fragment>
      <DialogTitle>Edit Result</DialogTitle>
      <DialogContent>
        {selectedResult.ready && (
          <form onSubmit={handleEditSaveResult}>
            <Box mb={2}>
              <TextField
                name="new_result"
                label="New Result"
                variant="outlined"
                fullWidth
                rows={4}
                multiline
              />
            </Box>

            <Box mb={2}>
              <TextField
                name="new_impression"
                label="New Impression"
                variant="outlined"
                fullWidth
                rows={4}
                multiline
              />
            </Box>

            <Box mb={3}>
              <TextField
                type="password"
                required
                name="password"
                label="Password"
                autoComplete="off"
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box my={2} display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                startIcon={<ClearIcon />}
                onClick={() => close()}
              >
                Cancel
              </Button>

              <Box ml={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </Fragment>
  );
};

export default ProcessToAddResult;
