import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';    
import ImagingUnprocess from './ImagingUnprocess';
import ImagingPending from './ImagingPending';
import ImagingProcessed from './ImagingProcessed';
import ImagingOngoing from './ImagingOngoing';
import ImagingSharedImages from './ImagingSharedImages'; 
import { Box, Tabs, Tab, Grid, makeStyles, Paper, Badge } from '@material-ui/core';
import TabPanel from '../../../utils/TabPanel';
// import ImagingOrderLocal from './ImagingOrderLocal';
import ImagingOrderVirtual from './ImagingOrderVirtual';
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import { PatientsUnRead, UsersData } from 'src/ContextAPI';
import CreateOrder from './hmis/CreateOrder';
 
const useStyle = makeStyles({
    indicatorLeft: {
      left: "0px"
    }
}); 

const Imaging = ({ patient_id, imagingType }) =>{

    const classes =  useStyle()
    const { users } = useContext(UsersData)
    const [category, setCategory] = useState(0) 

    const [counts, setCounts] = useState({
        processed: 0,
        ongoing: 0,
        pending: 0,
        unprocessed: 0
    })
  
    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, department ) =>{
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;
        let count = 0;
        if(imagingType === 'local-imaging'){
            for (let i = 0; i < xx.length; i++) {
                if( patient_id === xx[i].patient_id && category === xx[i].category && department === xx[i].department){
                    count += 1;
                }
            }
        }else{
            for (let i = 0; i < yy.length; i++) {
                if( patient_id === yy[i].patient_id && category === yy[i].category && department === yy[i].department){
                    count += 1;
                }
            }
        }
        
        return count;
    }

    const getCountImaging = useCallback(() => {
        var formdata = new FormData();   
        formdata.set('user_id', users.user_id);    
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', imagingType === 'virtual-imaging' ? 'online' : 'local')

        Axios.post('doctor/patient/imaging-counts', formdata )
        .then( (response) => { 
            const data = response.data;   
            if(data.length > 0){
                setCounts({
                    processed: data[0].count_processed,
                    ongoing: data[0].count_ongoing,
                    pending: data[0].count_pending,
                    unprocessed: data[0].count_unprocess
                })
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[users, patient_id, imagingType])

    const handleCategory = (e, newcategory) =>{
        e.persist() 
        setCategory(newcategory)
    }

    useEffect(() =>{ 

        getCountImaging()

    },[ getCountImaging, patient_id, imagingType ])

    return(
        <Fragment>
            <Grid container spacing={2}>
                <Box clone order={{ xs: 2, sm: 2 , md: 1 }}>
                    <Grid item xs={12} sm={12} md={9} lg={10}> 
                        <Box>
                            <TabPanel value={ category } index={0}>
                                { imagingType === 'virtual-imaging' && (
                                    <ImagingOrderVirtual
                                        getCountImaging = { getCountImaging } 
                                        patient_id={patient_id} 
                                    />
                                )}
                                { imagingType === 'local-imaging' && (
                                    // <ImagingOrderLocal 
                                    //     getCountImaging = { getCountImaging } 
                                    //     patient_id={patient_id} 
                                    // />
                                    <CreateOrder 
                                        getCountImaging = { getCountImaging } 
                                        patient_id={patient_id} 
                                    />
                                )}  
                            </TabPanel> 

                            <TabPanel value={ category } index={1}> 
                                <ImagingProcessed
                                    patient_id={patient_id}
                                    imagingType = { imagingType } 
                                /> 
                            </TabPanel>

                            <TabPanel value={ category } index={2}> 
                                <ImagingOngoing
                                    patient_id={patient_id}
                                    imagingType = { imagingType } 
                                /> 
                            </TabPanel>

                            <TabPanel value={ category } index={3}> 
                                <ImagingPending
                                    patient_id={patient_id}
                                    imagingType = { imagingType } 
                                /> 
                            </TabPanel>

                            <TabPanel value={ category } index={4}> 
                                <ImagingUnprocess
                                    patient_id={patient_id}
                                    imagingType = { imagingType } 
                                /> 
                            </TabPanel>
                            
                            <TabPanel value={ category } index={5}> 
                                <ImagingSharedImages
                                    patient_id={patient_id} 
                                /> 
                            </TabPanel>
                        </Box> 
                    </Grid>
                </Box>

                <Box clone order={{ xs: 1, sm: 1 , md: 2 }}>
                    <Grid item xs={12} sm={12} md={3} lg={2}> 
                        <Paper elevation={1}>
                            <Tabs   
                                value={ category }
                                onChange={ handleCategory }
                                indicatorColor="primary"
                                textColor="primary" 
                                scrollButtons="on"  
                                variant="scrollable"
                                orientation={window.innerWidth > 959 ? "vertical":"horizontal"} 
                                classes = {{
                                    indicator: classes.indicatorLeft
                                }}
                            >
                                <Tab wrapped label="Order" />  
                                
                                <Tab 
                                    wrapped 
                                    label={
                                        Boolean(parseInt(checkPatientUnreadNotif('imaging', 'processed'))) ?
                                            (<Badge color="secondary" badgeContent={<small> new </small>}> Processed </Badge>) // badge for unread
                                        : (<Badge color="primary" badgeContent={counts.processed}> Processed </Badge>) // badge for count
                                    } 
                                />
 
                                <Tab wrapped label={ 
                                    Boolean(parseInt(checkPatientUnreadNotif('imaging', 'ongoing'))) ?
                                        (<Badge color="secondary" badgeContent={<small> new </small>}> Ongoing </Badge>) // badge for unread
                                    : (<Badge color="primary" badgeContent={counts.ongoing}> Ongoing </Badge>) // badge for count
                                } />

                                <Tab wrapped label={
                                    Boolean(parseInt(checkPatientUnreadNotif('imaging', 'pending'))) ?
                                        (<Badge color="secondary" badgeContent={<small> new </small>}> Pending </Badge>) // badge for unread
                                    :   (<Badge color="primary" badgeContent={counts.pending}> Pending </Badge>) // badge for count 
                                } 
                                />

                                <Tab wrapped label={
                                    <Badge color="primary" badgeContent={counts.unprocessed}> 
                                        unprocessed
                                    </Badge>
                                } /> 
                                
                                { imagingType === 'local-imaging' && ( <Tab wrapped label="Shared Image" />  )}
                            </Tabs> 
                        </Paper> 
                    </Grid>  
                </Box>
            </Grid>
        </Fragment>
    )
}

export default Imaging;