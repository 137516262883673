import {
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Dialog,
  Zoom,
  DialogContent,
  TextField,
  Grid,
  DialogTitle,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import ArrowForward from "@material-ui/icons/ArrowForward";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const LeaveApproval = () => {
  const { users } = useContext(UsersData);
  const [forApprovalList, setForApprovalList] = useState({
    data: [],
    ready: false,
  });
  const [clickInfo, setClickInfo] = useState([]);
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [value, setValue] = useState(null);
  const [decision, setDecisiom] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getAllLeaveRequest = React.useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("accounting/get/leave-for-approval", {
        params,
      });
      const data = response.data;
      setForApprovalList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  const handleRadioPaymentChange = (event) => {
    setValue(event.target.value);
  };

  const handleRadioDecisionChange = (event) => {
    setDecisiom(event.target.value);
  };

  const handleGetSpecLeave = (e, data) => {
    setClickInfo(data);
    setOpenLeaveDialog(true);
    console.log("data", data);
  };

  const handleSubmitLeaveDes = (e) => {
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("management_id", users.management_id);

    var error = [];
    if (value === null) {
      error = "error";
      Notify.fieldRequired("payment type");
    }

    if (decision === null) {
      error = "error";
      Notify.fieldRequired("approve/disapprove");
    }

    if (decision === "disapprove") {
      if (
        formdata.get("disapprove_reason").length === 0 ||
        formdata.get("disapprove_reason").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("disapprove reason");
      }
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }

    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/save/leave-decision", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getAllLeaveRequest();
            Notify.successRequest("leave approval");
            e.target.reset();
            setOpenLeaveDialog(false);
            setClickInfo([]);
            setDecisiom(null);
            setValue(null);
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    getAllLeaveRequest();
  }, [getAllLeaveRequest]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Leave Approval",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title={"Leave Approval"}
      >
        <Box component={Paper} p={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b> Date </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Name </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Branch </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Actions </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {forApprovalList.ready ? (
                  forApprovalList.data.length > 0 ? (
                    forApprovalList.data.map((data, index) => (
                      <Fragment key={index}>
                        <TableRow>
                          <TableCell>
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell> {data.usersfname} </TableCell>
                          <TableCell> {data.branch_name} </TableCell>
                          <TableCell align="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => handleGetSpecLeave(e, data)}
                            >
                              <ArrowForward />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="primary"> Loading... </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
      <Dialog
        open={openLeaveDialog}
        onClose={() => {
          setOpenLeaveDialog(false);
          setClickInfo([]);
          setDecisiom(null);
          setValue(null);
        }}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Zoom}
        transitionDuration={800}
      >
        <DialogTitle> For Approval Leave Application </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmitLeaveDes}>
            <Box hidden>
              <TextField
                required
                name="cla_id"
                fullWidth
                value={clickInfo.cla_id}
                variant="outlined"
              />
            </Box>

            <Box hidden>
              <TextField
                required
                name="users_id"
                fullWidth
                value={clickInfo.user_id}
                variant="outlined"
              />
            </Box>

            <Box mb={2}>
              <TextField
                required
                name="employee_name"
                autoComplete="off"
                fullWidth
                label="Employee's Name"
                value={clickInfo.usersfname}
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Box>

            <Box mb={2}>
              <TextField
                required
                name="purpose"
                autoComplete="off"
                fullWidth
                label="Purpose of Leave"
                value={clickInfo.purpose}
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Box>

            <Box mb={2}>
              <TextField
                required
                name="leave_type"
                autoComplete="off"
                fullWidth
                label="Leave Type"
                value={clickInfo.leave_type}
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <TextField
                    value={clickInfo.date_from}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    required
                    name="date_from"
                    label="Date From"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <TextField
                    value={clickInfo.date_to}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    required
                    name="date_to"
                    label="Date To"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mb={2}>
              <TextField
                required
                name="no_days"
                autoComplete="off"
                fullWidth
                label="No. of days"
                value={clickInfo.no_days}
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Box>

            <Box border={1} mb={2} borderColor="#AEAEAE" borderRadius={4}>
              <RadioGroup
                aria-label="payment_type"
                name="payment_type"
                value={value}
                onChange={handleRadioPaymentChange}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <FormControlLabel
                    value="withpay"
                    control={<Radio />}
                    label="With Pay"
                  />
                  <FormControlLabel
                    value="withoutpay"
                    control={<Radio />}
                    label="Without Pay"
                  />
                </Box>
              </RadioGroup>
            </Box>

            <Box border={1} mb={2} borderColor="#AEAEAE" borderRadius={4}>
              <RadioGroup
                aria-label="noted_by_des"
                name="noted_by_des"
                value={decision}
                onChange={handleRadioDecisionChange}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <FormControlLabel
                    value="disapprove"
                    control={<Radio />}
                    label="Disapprove"
                  />
                  <FormControlLabel
                    value="approve"
                    control={<Radio />}
                    label="Approve"
                  />
                </Box>
              </RadioGroup>
            </Box>

            {decision === "disapprove" && (
              <Box mb={2}>
                <TextField
                  required
                  name="disapprove_reason"
                  autoComplete="off"
                  fullWidth
                  label="Disapprove Reason"
                  variant="outlined"
                />
              </Box>
            )}

            <Box mb={3}>
              <TextField
                type="password"
                required
                name="password"
                label="Password"
                autoComplete="off"
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box my={2} display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                startIcon={<ClearIcon />}
                onClick={() => {
                  setOpenLeaveDialog(false);
                  setClickInfo([]);
                  setDecisiom(null);
                  setValue(null);
                }}
              >
                Cancel
              </Button>

              <Box ml={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default LeaveApproval;
