import React, { Fragment, useState } from "react";
import Container from "src/layout/Container";
import { Paper, Box, Tabs, Tab } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import StockRoomProductOut from "./product-out";
import StockRoomProductIn from "./product-in";
import StockroomMonitoring from "./monitoring";
import StockroomInventory from "./inventory";

const StockRoomProducts = () => {
  const [category, setCategory] = useState("product-in");

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Products",
          items: [{ name: "Dashboard", path: "/bmcdc/app/stockroom" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Stockroom Products</Box>

            <Box>
              <Paper>
                <Tabs
                  value={category}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  variant="fullWidth"
                >
                  <Tab wrapped value={"product-in"} label="Product In" />
                  <Tab wrapped value={"product-out"} label="Product Out" />
                  <Tab wrapped value={"inventory"} label="Inventory" />
                  <Tab wrapped value={"montoring"} label="Monitoring" />
                </Tabs>
              </Paper>
            </Box>
          </Box>
        }
      >
        <Box>
          <TabPanel value={category} index={"product-in"}>
            <StockRoomProductIn />
          </TabPanel>
        </Box>
        <Box>
          <TabPanel value={category} index={"product-out"}>
            <StockRoomProductOut />
          </TabPanel>
        </Box>
        <Box>
          <TabPanel value={category} index={"inventory"}>
            <StockroomInventory />
          </TabPanel>
        </Box>
        <Box>
          <TabPanel value={category} index={"montoring"}>
            <StockroomMonitoring />
          </TabPanel>
        </Box>
      </Container>
    </Fragment>
  );
};

export default StockRoomProducts;
