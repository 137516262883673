import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import Notify from "src/notification/Notify";
import ChemistryRef from "src/laboratory/references/Ref_Chemistry";

const ClinicalChemistryEditForm = ({ info, close }) => {
  const { users } = useContext(UsersData);
  const [savebtnDisabled, setSavebtnDisabled] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", info.data.trace_number);
    formdata.set("order_id", info.data.order_id);
    setResultSubmitting(true);
    axios
      .post("laboratory/clinical-chemistry/edit-result", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("order result edit successful");
          close();
          setSavebtnDisabled(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setResultSubmitting(false));
  };

  return (
    <>
      <CardContent>
        <Fragment>
          <form onSubmit={handleSaveResult}>
            {info.ready && (
              <>
                <Box display="flex" my={2}>
                  <Box flexGrow={1}>
                    <Typography variant="body1" align="center">
                      <b> BLOOD CHEMISTRY REPORT </b>
                    </Typography>
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b> TEST REQUEST </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> RESULT </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> REFERENCE VALUES </b>
                        </TableCell>
                        <TableCell align="center">
                          <b> REMARKS </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hidden={info.data.fbs === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            FBS
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="fbs"
                              label="Result"
                              defaultValue={
                                info.data.fbs === null ? "" : info.data.fbs
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.fbs()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="fbs_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.fbs_remarks === null
                                  ? ""
                                  : info.data.fbs_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow hidden={info.data.glucose === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            GLUCOSE
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="glucose"
                              label="Result"
                              defaultValue={
                                info.data.glucose === null
                                  ? ""
                                  : info.data.glucose
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.glucose()}
                        </TableCell>

                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="glucose_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.glucose_remarks === null
                                  ? ""
                                  : info.data.glucose_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.creatinine === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            CREATININE
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="creatinine"
                              label="Result"
                              defaultValue={
                                info.data.creatinine === null
                                  ? ""
                                  : info.data.creatinine
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.creatinine()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="creatinine_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.creatinine_remarks === null
                                  ? ""
                                  : info.data.creatinine_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.uric_acid === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            URIC ACID
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="uric_acid"
                              label="Result"
                              defaultValue={
                                info.data.uric_acid === null
                                  ? ""
                                  : info.data.uric_acid
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.uric()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="uric_acid_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.uric_acid_remarks === null
                                  ? ""
                                  : info.data.uric_acid_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.cholesterol === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            CHOLESTEROL
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="cholesterol"
                              label="Result"
                              defaultValue={
                                info.data.cholesterol === null
                                  ? ""
                                  : info.data.cholesterol
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.cholesterol()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="cholesterol_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.cholesterol_remarks === null
                                  ? ""
                                  : info.data.cholesterol_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.triglyceride === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            TRIGLYCERIDE
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="triglyceride"
                              label="Result"
                              defaultValue={
                                info.data.triglyceride === null
                                  ? ""
                                  : info.data.triglyceride
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.triglyceride()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="triglyceride_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.triglyceride_remarks === null
                                  ? ""
                                  : info.data.triglyceride_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.hdl_cholesterol === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            HDL CHOLESTEROL
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hdl_cholesterol"
                              label="Result"
                              defaultValue={
                                info.data.hdl_cholesterol === null
                                  ? ""
                                  : info.data.hdl_cholesterol
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.hdlcholesterol()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hdl_cholesterol_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.hdl_cholesterol_remarks === null
                                  ? ""
                                  : info.data.hdl_cholesterol_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ldl_cholesterol === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            LDL CHOLESTEROL
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ldl_cholesterol"
                              label="Result"
                              defaultValue={
                                info.data.ldl_cholesterol === null
                                  ? ""
                                  : info.data.ldl_cholesterol
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.ldlcholesterol()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ldl_cholesterol_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.ldl_cholesterol_remarks === null
                                  ? ""
                                  : info.data.ldl_cholesterol_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.sgot === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            SGOT
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="sgot"
                              label="Result"
                              defaultValue={
                                info.data.sgot === null ? "" : info.data.sgot
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.sgot()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="sgot_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.sgot_remarks === null
                                  ? ""
                                  : info.data.sgot_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.sgpt === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            SGPT
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="sgpt"
                              label="Result"
                              defaultValue={
                                info.data.sgpt === null ? "" : info.data.sgpt
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {ChemistryRef.sgpt()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="sgpt_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.sgpt_remarks === null
                                  ? ""
                                  : info.data.sgpt_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.bun === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            bun
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="bun"
                              label="Result"
                              defaultValue={
                                info.data.bun === null ? "" : info.data.bun
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="bun_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.bun_remarks === null
                                  ? ""
                                  : info.data.bun_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.soduim === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            soduim
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="soduim"
                              label="Result"
                              defaultValue={
                                info.data.soduim === null
                                  ? ""
                                  : info.data.soduim
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="soduim_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.soduim_remarks === null
                                  ? ""
                                  : info.data.soduim_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.potassium === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            potassium
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="potassium"
                              label="Result"
                              defaultValue={
                                info.data.potassium === null
                                  ? ""
                                  : info.data.potassium
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="potassium_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.potassium_remarks === null
                                  ? ""
                                  : info.data.potassium_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.hba1c === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            hba1c
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hba1c"
                              label="Result"
                              defaultValue={
                                info.data.hba1c === null ? "" : info.data.hba1c
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="hba1c_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.hba1c_remarks === null
                                  ? ""
                                  : info.data.hba1c_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow
                        hidden={info.data.alkaline_phosphatase === null}
                      >
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            alkaline_phosphatase
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="alkaline_phosphatase"
                              label="Result"
                              defaultValue={
                                info.data.alkaline_phosphatase === null
                                  ? ""
                                  : info.data.alkaline_phosphatase
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="alkaline_phosphatase_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.alkaline_phosphatase_remarks === null
                                  ? ""
                                  : info.data.alkaline_phosphatase_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.albumin === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            albumin
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="albumin"
                              label="Result"
                              defaultValue={
                                info.data.albumin === null
                                  ? ""
                                  : info.data.albumin
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="albumin_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.albumin_remarks === null
                                  ? ""
                                  : info.data.albumin_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.calcium === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            calcium
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="calcium"
                              label="Result"
                              defaultValue={
                                info.data.calcium === null
                                  ? ""
                                  : info.data.calcium
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="calcium_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.calcium_remarks === null
                                  ? ""
                                  : info.data.calcium_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.magnesium === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            magnesium
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="magnesium"
                              label="Result"
                              defaultValue={
                                info.data.magnesium === null
                                  ? ""
                                  : info.data.magnesium
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="magnesium_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.magnesium_remarks === null
                                  ? ""
                                  : info.data.magnesium_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.chloride === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            chloride
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="chloride"
                              label="Result"
                              defaultValue={
                                info.data.chloride === null
                                  ? ""
                                  : info.data.chloride
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="chloride_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.chloride_remarks === null
                                  ? ""
                                  : info.data.chloride_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.serum_uric_acid === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            SERUM URIC ACID
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="serum_uric_acid"
                              label="Result"
                              defaultValue={
                                info.data.serum_uric_acid === null
                                  ? ""
                                  : info.data.serum_uric_acid
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="serum_uric_acid_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.serum_uric_acid_remarks === null
                                  ? ""
                                  : info.data.serum_uric_acid_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.lipid_profile === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            LIPID PROFILE
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="lipid_profile"
                              label="Result"
                              defaultValue={
                                info.data.lipid_profile === null
                                  ? ""
                                  : info.data.lipid_profile
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="lipid_profile_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.lipid_profile_remarks === null
                                  ? ""
                                  : info.data.lipid_profile_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ldh === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            LDH
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ldh"
                              label="Result"
                              defaultValue={
                                info.data.ldh === null ? "" : info.data.ldh
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ldh_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.ldh_remarks === null
                                  ? ""
                                  : info.data.ldh_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.tpag_ratio === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            TPAG RATIO
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="tpag_ratio"
                              label="Result"
                              defaultValue={
                                info.data.tpag_ratio === null
                                  ? ""
                                  : info.data.tpag_ratio
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="tpag_ratio_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.tpag_ratio_remarks === null
                                  ? ""
                                  : info.data.tpag_ratio_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.bilirubin === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            BILIRUBIN
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="bilirubin"
                              label="Result"
                              defaultValue={
                                info.data.bilirubin === null
                                  ? ""
                                  : info.data.bilirubin
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="bilirubin_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.bilirubin_remarks === null
                                  ? ""
                                  : info.data.bilirubin_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.total_protein === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            TOTAL PROTEIN
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="total_protein"
                              label="Result"
                              defaultValue={
                                info.data.total_protein === null
                                  ? ""
                                  : info.data.total_protein
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="total_protein_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.total_protein_remarks === null
                                  ? ""
                                  : info.data.total_protein_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.potassium_kplus === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            POTASSIUM K+
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="potassium_kplus"
                              label="Result"
                              defaultValue={
                                info.data.potassium_kplus === null
                                  ? ""
                                  : info.data.potassium_kplus
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="potassium_kplus_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.potassium_kplus_remarks === null
                                  ? ""
                                  : info.data.potassium_kplus_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.na_plus_kplus === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            NA+/K+
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="na_plus_kplus"
                              label="Result"
                              defaultValue={
                                info.data.na_plus_kplus === null
                                  ? ""
                                  : info.data.na_plus_kplus
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="na_plus_kplus_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.na_plus_kplus_remarks === null
                                  ? ""
                                  : info.data.na_plus_kplus_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ggt === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            GGT
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ggt"
                              label="Result"
                              defaultValue={
                                info.data.ggt === null ? "" : info.data.ggt
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ggt_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.ggt_remarks === null
                                  ? ""
                                  : info.data.ggt_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.cholinesterase === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            CHOLINESTERASE
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="cholinesterase"
                              label="Result"
                              defaultValue={
                                info.data.cholinesterase === null
                                  ? ""
                                  : info.data.cholinesterase
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="cholinesterase_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.cholinesterase_remarks === null
                                  ? ""
                                  : info.data.cholinesterase_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.phosphorous === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            PHOSPHOROUS
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="phosphorous"
                              label="Result"
                              defaultValue={
                                info.data.phosphorous === null
                                  ? ""
                                  : info.data.phosphorous
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="phosphorous_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.phosphorous_remarks === null
                                  ? ""
                                  : info.data.phosphorous_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.rbs === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            RBS
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="rbs"
                              label="Result"
                              defaultValue={
                                info.data.rbs === null ? "" : info.data.rbs
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="rbs_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.rbs_remarks === null
                                  ? ""
                                  : info.data.rbs_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.vldl === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            VLDL
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="vldl"
                              label="Result"
                              defaultValue={
                                info.data.vldl === null ? "" : info.data.vldl
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="vldl_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.vldl_remarks === null
                                  ? ""
                                  : info.data.vldl_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.rbc_cholinesterase === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            RBC CHOLINESTERASE
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="rbc_cholinesterase"
                              label="Result"
                              defaultValue={
                                info.data.rbc_cholinesterase === null
                                  ? ""
                                  : info.data.rbc_cholinesterase
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="rbc_cholinesterase_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.rbc_cholinesterase_remarks === null
                                  ? ""
                                  : info.data.rbc_cholinesterase_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.crp === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            CRP
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="crp"
                              label="Result"
                              defaultValue={
                                info.data.crp === null ? "" : info.data.crp
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="crp_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.crp_remarks === null
                                  ? ""
                                  : info.data.crp_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.pro_calcitonin === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            PRO CALCITONIN
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="pro_calcitonin"
                              label="Result"
                              defaultValue={
                                info.data.pro_calcitonin === null
                                  ? ""
                                  : info.data.pro_calcitonin
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="pro_calcitonin_crp_remarks"
                              label="Remarks"
                              defaultValue={
                                info.data.pro_calcitonin_crp_remarks === null
                                  ? ""
                                  : info.data.pro_calcitonin_crp_remarks
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ogct_take_one_50grm === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            OGCT TAKE 1 (50GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_one_50grm_baseline"
                              label="Baseline"
                              defaultValue={
                                info.data.ogct_take_one_50grm_baseline === null
                                  ? ""
                                  : info.data.ogct_take_one_50grm_baseline
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_one_50grm_first_hour"
                              label="First Hour"
                              defaultValue={
                                info.data.ogct_take_one_50grm_first_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_one_50grm_first_hour
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_one_50grm_second_hour"
                              label="Second Hour"
                              defaultValue={
                                info.data.ogct_take_one_50grm_second_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_one_50grm_second_hour
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ogct_take_one_75grm === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            OGCT TAKE 1 (75GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_one_75grm_baseline"
                              label="Baseline"
                              defaultValue={
                                info.data.ogct_take_one_75grm_baseline === null
                                  ? ""
                                  : info.data.ogct_take_one_75grm_baseline
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_one_75grm_first_hour"
                              label="First Hour"
                              defaultValue={
                                info.data.ogct_take_one_75grm_first_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_one_75grm_first_hour
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_one_75grm_second_hour"
                              label="Second Hour"
                              defaultValue={
                                info.data.ogct_take_one_75grm_second_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_one_75grm_second_hour
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow
                        hidden={info.data.ogct_take_two_100grm === null}
                      >
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            OGTT TAKES 2 (100GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_two_100grm_baseline"
                              label="Baseline"
                              defaultValue={
                                info.data.ogct_take_two_100grm_baseline === null
                                  ? ""
                                  : info.data.ogct_take_two_100grm_baseline
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_two_100grm_first_hour"
                              label="First Hour"
                              defaultValue={
                                info.data.ogct_take_two_100grm_first_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_two_100grm_first_hour
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_two_100grm_second_hour"
                              label="Second Hour"
                              defaultValue={
                                info.data.ogct_take_two_100grm_second_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_two_100grm_second_hour
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow hidden={info.data.ogct_take_two_75grm === null}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            OGTT TAKES 2 (75GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_two_75grm_baseline"
                              label="Baseline"
                              defaultValue={
                                info.data.ogct_take_two_75grm_baseline === null
                                  ? ""
                                  : info.data.ogct_take_two_75grm_baseline
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_two_75grm_first_hour"
                              label="First Hour"
                              defaultValue={
                                info.data.ogct_take_two_75grm_first_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_two_75grm_first_hour
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_two_75grm_second_hour"
                              label="Second Hour"
                              defaultValue={
                                info.data.ogct_take_two_75grm_second_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_two_75grm_second_hour
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow
                        hidden={info.data.ogct_take_three_100grm === null}
                      >
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            OGTT TAKES 3 (100GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_three_100grm_baseline"
                              label="Baseline"
                              defaultValue={
                                info.data.ogct_take_three_100grm_baseline ===
                                null
                                  ? ""
                                  : info.data.ogct_take_three_100grm_baseline
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_three_100grm_first_hour"
                              label="First Hour"
                              defaultValue={
                                info.data.ogct_take_three_100grm_first_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_three_100grm_first_hour
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_three_100grm_second_hour"
                              label="Second Hour"
                              defaultValue={
                                info.data.ogct_take_three_100grm_second_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_three_100grm_second_hour
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow
                        hidden={info.data.ogct_take_four_100grm === null}
                      >
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                          >
                            OGTT TAKES 4 (100GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_four_100grm_baseline"
                              label="Baseline"
                              defaultValue={
                                info.data.ogct_take_four_100grm_baseline ===
                                null
                                  ? ""
                                  : info.data.ogct_take_four_100grm_baseline
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_four_100grm_first_hour"
                              label="First Hour"
                              defaultValue={
                                info.data.ogct_take_four_100grm_first_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_four_100grm_first_hour
                              }
                            />
                          </Box>

                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="ogct_take_four_100grm_second_hour"
                              label="Second Hour"
                              defaultValue={
                                info.data.ogct_take_four_100grm_second_hour ===
                                null
                                  ? ""
                                  : info.data.ogct_take_four_100grm_second_hour
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            <Box display="flex">
              <Box flexGrow={1} />
              <Box>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={savebtnDisabled}
                    startIcon={
                      resultSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save Result
                  </Button>
                </CardActions>
              </Box>
            </Box>
          </form>
        </Fragment>
      </CardContent>
    </>
  );
};

export default ClinicalChemistryEditForm;
