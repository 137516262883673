import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, Typography, useTheme } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const BranchCardCollection = ({ selectedBranch }) => {
  const mounted = IsMountedRef();
  const [branchCollection, setBranchCollection] = useState(0);
  const theme = useTheme();

  const getAllCollection = React.useCallback(async () => {
    let response = await axios.get("accounting/branch/collection-totalamount", {
      params: {
        management_id: selectedBranch.management_id,
      },
    });

    if (mounted.current) {
      if (response.data.length > 0) {
        calculateTotal(response.data);
      } else {
        setBranchCollection(0);
      }
    }
  }, [mounted, selectedBranch]);

  const calculateTotal = (data) => {
    let x = 0;

    data.forEach((element) => {
      x += parseFloat(element.bill_amount);
    });

    setBranchCollection(x);
  };

  useEffect(() => {
    getAllCollection();
  }, [getAllCollection]);

  return (
    <Card elevation={5}>
      <CardHeader
        avatar={
          <FontAwesomeIcon
            icon={faFolderPlus}
            size={"5x"}
            style={{ color: `${theme.palette.primary.main}` }}
          />
        }
        title={
          <Typography variant="h4" color="textSecondary" noWrap>
            Collections
          </Typography>
        }
        subheader={
          <Typography variant="h4">
            <strong>&#8369; {Notify.convertToNumber(branchCollection)}</strong>
          </Typography>
        }
      />
    </Card>
  );
};

export default BranchCardCollection;
