import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { UsersData } from "src/ContextAPI";
import New from "./New";
import TempList from "./TempList";

const LaboratoryDelivery = () => {
  const { users } = useContext(UsersData);
  const [tempList, setTempList] = useState({ data: [], ready: false });

  const getItemLab = React.useCallback(async () => {
    let response = await axios.get("accounting/test/items/delivery-templist", {
      params: {
        management_id: users.management_id,
        user_id: users.user_id,
      },
    });

    setTempList({ data: response.data, ready: true });
  }, [users]);

  useEffect(() => {
    getItemLab();
  }, [getItemLab]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5} md={4}>
        <New rerender={getItemLab} />
      </Grid>
      <Grid item xs={12} sm={7} md={8}>
        <TempList list={tempList} rerender={getItemLab} />
      </Grid>
    </Grid>
  );
};

export default LaboratoryDelivery;
