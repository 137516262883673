import React, { Fragment, useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'  
import Box from '@material-ui/core/Box';
import Edit from '@material-ui/icons/Edit'
import HighlightOff from '@material-ui/icons/HighlightOff'
import Notify from '../../../notification/Notify';
import Axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { UsersData } from 'src/ContextAPI';


function TreatmentPlanEdit(props) {
 
    const { users } = useContext(UsersData)
    const [isprocess, setisprocess] = useState(false)

    const handleupdate = (e) =>{
        e.preventDefault()
        e.persist();

        var formdata = new FormData(e.target);   
        formdata.set('user_id', users.user_id);     
        formdata.set('username', users.username);     
        formdata.set('id', props.treatment_id);     
        
        var error = [];
        if(formdata.get('treatmentplan').length === 0 || formdata.get('treatmentplan').trim()===''){  
            Notify.fieldRequired('treatment plan')
            error = 'error';
        }
        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){  
            Notify.fieldRequired('password')
            error = 'error';
        }
        if(error.length > 0){
            console.warn("Unble to process , Form has an error.")
        }else{
            setisprocess(true)
            Axios.post('doctor/patient/patient-treatmentplanupdate', formdata )
            .then( (response) => { 
                const data = response.data;     
                if(data === 'success'){
                    props.closedialog() 
                    Notify.successRequest('treatment plan');
                }
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(() =>{
                setisprocess(false)
            })
        }
    }

    return (
        <Fragment>
           <form onSubmit={handleupdate}> 
                <DialogContent dividers>
                    <Box mb={2}>
                        <TextField fullWidth multiline variant="outlined"  defaultValue={props.tplan}  name="treatmentplan" label="Treatment Plan" /> 
                    </Box>
                    <Box mb={2}> 
                        <TextField type="password" variant="outlined" fullWidth  name="password" label="Password" /> 
                    </Box>  
                </DialogContent>
                <DialogActions> 
                    <Button variant="contained" color="default" startIcon={<HighlightOff />} onClick={props.closedialog}> No </Button>  
                    <Button variant="contained" color="primary" type="submit" disabled={ isprocess ? true : false } startIcon={ isprocess ? <CircularProgress color="inherit" size={15} /> : <Edit />}> Edit </Button>
                </DialogActions>
           </form>
        </Fragment>
    )
}

export default TreatmentPlanEdit;
