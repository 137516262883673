import React from "react";
import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faBrain,
  faCartPlus,
  // faVials,
  // faXRay,
} from "@fortawesome/free-solid-svg-icons";
// import LaboratoryAdmission from "./laboratory/LaboratoryAdmission";
// import ImagingAdmission from "./imaging/ImagingAdmission";
// import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
// import WalkinPackages from "./packages/WalkinPackages";
// import PsychologyTestDashboard from "./psychology";
import AdditionalQueueForOrder from "./additional";

const WalkInTab = ({ patient_id, info, close }) => {
  const [category, setCategory] = React.useState("additional");

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Box mt={2} m={0} p={0}>
      <Box component={Paper} elevation={1} mb={2}>
        <Tabs
          value={category}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          {/* <Tab
            wrapped
            value="laboratory"
            label="Laboratory"
            icon={<FontAwesomeIcon icon={faVials} style={{ fontSize: 18 }} />}
          />

          <Tab
            wrapped
            label="Imaging"
            value="imaging"
            icon={<FontAwesomeIcon icon={faXRay} style={{ fontSize: 18 }} />}
          />

          <Tab
            wrapped
            label="Psychology Test"
            value="ishihara"
            icon={<FontAwesomeIcon icon={faBrain} style={{ fontSize: 18 }} />}
          /> */}

          {/* <Tab
            wrapped
            label="Package"
            value="package"
            icon={<FontAwesomeIcon icon={faCubes} style={{ fontSize: 18 }} />}
          /> */}

          <Tab
            wrapped
            label="Send Queue for additional"
            value="additional"
            icon={
              <FontAwesomeIcon icon={faCartPlus} style={{ fontSize: 18 }} />
            }
          />
        </Tabs>
      </Box>

      {/* <TabPanel value={category} index={"laboratory"}>
        <LaboratoryAdmission patient_id={patient_id} />
      </TabPanel>

      <TabPanel value={category} index={"imaging"}>
        <ImagingAdmission patient_id={patient_id} />
      </TabPanel>

      <TabPanel value={category} index={"ishihara"}>
        <PsychologyTestDashboard patient_id={patient_id} />
      </TabPanel> */}

      {/* <TabPanel value={category} index={"package"}>
        <WalkinPackages patient_id={patient_id} />
      </TabPanel> */}

      <TabPanel value={category} index={"additional"}>
        <AdditionalQueueForOrder
          patient_id={patient_id}
          close={() => close()}
        />
      </TabPanel>
    </Box>
  );
};

export default WalkInTab;
