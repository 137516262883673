import { createContext } from "react";

export const ThemeContext = createContext(null);
export const UsersData = createContext(null);
export const UsersHeader = createContext(null);
export const BranchListContext = createContext(null);
export const FormInformation = createContext(null);
export const PharmacyRoleAndId = createContext(null);
export const PatientsUnRead = createContext(null);
export const WarehouseRoleAndId = createContext(null);
